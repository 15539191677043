import Typography from '@mui/material/Typography';

import UnauthenticatedLayout from '../UnauthenticatedLayout';

const OfflineWarning = () => (
  <UnauthenticatedLayout
    title="Device Offline"
    imageComponent={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        style={{ height: '75px', width: 'auto' }}
      >
        <path
          fill="#141f3d"
          d="M21.552 16.585V5.56l-3.189-1.865v5.767H5.643V3.696L2.448 5.56v11.024l3.195 1.865v-5.767h12.72v5.767z"
          style={{
            strokeWidth: 0.137143,
          }}
        />
        <path
          fill="#47a6b6"
          d="M12.003 3.667 6.516 6.823v-3.66L12.003 0l5.48 3.168v3.66l-5.48-3.156ZM6.528 15.253v3.678L15.303 24v-3.649zm6.419 2.646 3.118 1.807 1.39-.786v-3.65L12.94 17.9z"
          style={{
            strokeWidth: 0.137143,
          }}
        />
      </svg>
    }
    contentProps={{ sx: { width: 'auto !important' } }}
  >
    <Typography align="center">
      Please check your Internet connection.
    </Typography>
  </UnauthenticatedLayout>
);

export default OfflineWarning;
