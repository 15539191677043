import { createSelector } from 'reselect';

import { ROLES } from '../../configs';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';

export const selectAuthState = (state) => state.auth;

export const selectCurrentUser = createSelector(
  selectAuthState,
  (authState) => authState.currentUser
);

export const selectIsAuthenticated = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) return false;
    return !!currentUser.authenticationToken;
  }
);

export const selectCurrentUserProfileImage = createSelector(
  selectCurrentUser,
  (currentUser) =>
    !currentUser ? '' : getCachedProfileImageUrl(currentUser.user)
);

export const selectAuthenticationTokenExpiry = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) return null;
    return currentUser.authenticationTokenExpiry;
  }
);

export const selectRefreshToken = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) return null;
    return currentUser.cueRefreshToken;
  }
);

export const selectChangePassword = createSelector(
  selectAuthState,
  (authState) => authState.changePassword
);

export const selectCurrentUserUUID = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) return null;
    return currentUser.uuid;
  }
);

export const selectCurrentUserName = createSelector(
  selectCurrentUser,
  (currentUser) => {
    if (!currentUser) return null;
    return `${currentUser.user.firstName} ${currentUser.user.lastName}`;
  }
);

export const selectCurrentUserPhone = createSelector(
  selectCurrentUser,
  (currentUser) => (!currentUser ? '' : currentUser.user.phoneNumber)
);

export const selectCurrentUserHash = createSelector(
  selectCurrentUser,
  (user) => user?.userIdHash
);

export const selectLastLogin = createSelector(
  selectAuthState,
  (authState) => authState.lastLogin
);

export const selectTwoFactorData = createSelector(
  selectAuthState,
  (authState) => authState.twoFactorData
);

export const selectQRImage = createSelector(
  selectAuthState,
  (authState) => authState.qrCode
);

export const selectSecret = createSelector(
  selectAuthState,
  (authState) => authState.secret || ''
);

export const selectAuthError = createSelector(
  selectAuthState,
  (authState) => authState.error
);

export const selectCurrentUserEmail = createSelector(
  selectAuthState,
  (authState) => authState.currentUser?.user?.email
);

export const selectCurrentUserRole = createSelector(
  selectAuthState,
  (authState) => authState.currentUser?.user?.userProperties?.role
);

export const selectIsCurrentUserAdmin = createSelector(
  selectCurrentUserRole,
  (userRole) => userRole === ROLES.admin
);

export const selectUpdatingUserProperties = createSelector(
  selectAuthState,
  (authState) => authState.updatingUserProperties
);

export const selectFetchingUserProperties = createSelector(
  selectUpdatingUserProperties,
  (updatingUserProperties) =>
    updatingUserProperties === null || updatingUserProperties === true
);
