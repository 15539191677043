import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MuiSnackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

const Snackbar = styled(MuiSnackbar)`
  .MuiPaper-root {
    background-color: rgb(241 153 159) !important;
    align-items: center;
  }
`;

const ErrorToast = ({ open, onClose, message, onClickButton }) => (
  <Snackbar
    open={open}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    sx={{ width: '100%' }}
  >
    <Alert severity="error">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography>{message}</Typography>
        {!!onClickButton && (
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={onClickButton}
            sx={{ flexShrink: 0 }}
          >
            Fix Issue(s)
          </Button>
        )}
      </Stack>
    </Alert>
  </Snackbar>
);

export default ErrorToast;
