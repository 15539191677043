import SvgIcon from '@mui/material/SvgIcon';

const Close = (props) => (
  <SvgIcon {...props}>
    <path
      d="M3.219 4.281a.75.75 0 1 1 1.062-1.062L12 10.939l7.719-7.72a.75.75 0 1 1 1.062 1.062L13.061 12l7.72 7.719a.752.752 0 0 1-1.062 1.062L12 13.061l-7.719 7.72a.752.752 0 0 1-1.282-.531.751.751 0 0 1 .22-.531L10.939 12 3.22 4.281Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Close;
