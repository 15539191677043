import { v4 } from 'uuid';
import groupBy from 'lodash.groupby';
import { createSelector } from 'reselect';

import { PLAYBOOK_ITEM_TYPES } from './playbookTypes';
import { TASK_STATUS } from '../incident/incidentTypes';
import { labelCompare, playbookCompare } from '../../utils/sortUtils';

export const selectPlaybookState = (state) => state.playbooks;

export const selectPlaybooksFetching = createSelector(
  selectPlaybookState,
  (playbookState) => playbookState.fetching
);

export const selectPlaybooks = createSelector(
  selectPlaybookState,
  (playbookState) => {
    const { list, categories } = playbookState;
    const expandedList = list.map((playbook) => {
      const { activities } = playbook;
      const expandedActivities = activities
        .filter((i) => i.type === PLAYBOOK_ITEM_TYPES.TASK)
        .map((activity, index) => {
          return {
            ...activity,
            status: TASK_STATUS.TO_DO,
            progress: 0,
            id: index + 1,
          };
        });
      return {
        ...playbook,
        activities: expandedActivities,
        categoryName: categories.find((c) => c.id === playbook.categoryId)
          ?.name,
      };
    });
    return expandedList.sort(playbookCompare);
  }
);

export const selectPlaybookCount = createSelector(
  selectPlaybooks,
  (playbooks) => playbooks.length
);

export const selectPlaybooksByCategory = createSelector(
  selectPlaybookState,
  (playbookState) => {
    const { list, categories } = playbookState;
    const groupedPlaybooks = groupBy(list, 'categoryId');

    const categoryList = Object.keys(groupedPlaybooks).includes(null)
      ? [...categories, { id: null, name: 'Uncategorized' }]
      : categories;

    return categoryList.map((category) => ({
      ...category,
      playbooks: groupedPlaybooks?.[category?.id]?.sort(playbookCompare) || [],
    }));
  }
);

export const selectCategoryOptions = createSelector(
  selectPlaybookState,
  (playbookState) =>
    playbookState.categories
      .map((c) => ({
        label: c.name,
        value: c.id,
      }))
      .sort(labelCompare)
);

export const selectPlaybooksWithCategory = createSelector(
  selectPlaybookState,
  (playbookState) => {
    const { list, categories } = playbookState;
    return list
      .map((playbook) => ({
        label: playbook.name,
        value: playbook.id,
        categoryName: categories.find((i) => i.id === playbook.categoryId)
          ?.name,
      }))
      .sort(labelCompare);
  }
);

export const selectCategoryNames = createSelector(
  selectPlaybookState,
  (playbookState) => playbookState.categories.map((c) => c.name.toLowerCase())
);

export const selectActiveCategory = createSelector(
  selectPlaybookState,
  (playbookState) => {
    const { categoryId, categories } = playbookState;
    return !categoryId ? null : categories.find((c) => c.id === categoryId);
  }
);

export const selectActivePlaybook = createSelector(
  selectPlaybookState,
  (playbookState) => {
    const { playbookId, list } = playbookState;
    if (!playbookId) return null;

    const playbook = list.find((p) => p.id === playbookId);
    return {
      ...playbook,
      activities: (playbook.activities || []).map((item) => ({
        ...item,
        id: v4(), // Assign id for dnd purposes.
      })),
    };
  }
);
