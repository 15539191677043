import UsersStep from './UsersStep';
import WelcomeStep from './WelcomeStep';
import WarRoomsStep from './WarRoomsStep';
import FileFoldersStep from './FileFoldersStep';
import ConfirmationStep from './ConfirmationStep';

export const STEP_IDS = {
  INTRO: 'INTRO',
  USERS: 'USERS',
  WAR_ROOMS: 'WAR_ROOMS',
  FILE_FOLDERS: 'FILE_FOLDERS',
  CONFIRMATION: 'CONFIRMATION',
};

export const stepList = [
  {
    id: STEP_IDS.INTRO,
    prefix: 'Introduction',
    title: 'Welcome to ShadowHQ',
    shortTitle: 'Welcome',
    content: <WelcomeStep />,
  },
  {
    id: STEP_IDS.USERS,
    prefix: 'Step 1',
    title: 'Users',
    shortTitle: 'Users',
    content: <UsersStep />,
  },
  {
    id: STEP_IDS.WAR_ROOMS,
    prefix: 'Step 2',
    title: 'War Rooms',
    shortTitle: 'War Rooms',
    content: <WarRoomsStep />,
  },
  {
    id: STEP_IDS.FILE_FOLDERS,
    prefix: 'Step 3',
    title: 'File Folders',
    shortTitle: 'File Folders',
    content: <FileFoldersStep />,
  },
  {
    id: STEP_IDS.CONFIRMATION,
    prefix: 'Step 4',
    title: 'Confirmation',
    shortTitle: 'Confirmation',
    content: <ConfirmationStep />,
  },
];
