import { AUTH_TYPES } from '../auth/authReducer';

export const PLAYBOOK_TYPES = {
  FETCH: '@@playbook/FETCH',
  FETCH_ALL: '@@playbook/FETCH_ALL',
  FETCH_PLAYBOOK: '@@playbook/FETCH_PLAYBOOK',
  SET_CATEGORY_ID: '@@playbook/SET_CATEGORY_ID',
  CREATE_CATEGORY: '@@playbook/CREATE_CATEGORY',
  EDIT_CATEGORY: '@@playbook/EDIT_CATEGORY',
  DELETE_CATEGORY: '@@playbook/DELETE_CATEGORY',
  SET_PLAYBOOK_ID: '@@playbook/SET_PLAYBOOK_ID',
  CREATE_PLAYBOOK: '@@playbook/CREATE_PLAYBOOK',
  EDIT_PLAYBOOK: '@@playbook/EDIT_PLAYBOOK',
  DELETE_PLAYBOOK: '@@playbook/DELETE_PLAYBOOK',
  EXPORT_PLAYBOOK: '@@playbook/EXPORT_PLAYBOOK',
  SHARE_PLAYBOOK: '@@playbook/SHARE_PLAYBOOK',
};

const INITIAL_PLAYBOOK_STATE = {
  fetching: false,
  list: [],
  categories: [],
  categoryId: null,
  playbookId: null,
};

export default function playbooks(state = INITIAL_PLAYBOOK_STATE, action) {
  switch (action.type) {
    case `${PLAYBOOK_TYPES.FETCH_ALL}_PENDING`: {
      return {
        ...state,
        fetching: true,
      };
    }
    case `${PLAYBOOK_TYPES.FETCH_ALL}_REJECTED`: {
      return {
        ...state,
        fetching: false,
      };
    }
    case `${PLAYBOOK_TYPES.FETCH_ALL}_FULFILLED`: {
      const { list, categories } = action.payload;
      return {
        ...state,
        list,
        categories,
        fetching: false,
      };
    }
    case PLAYBOOK_TYPES.SET_CATEGORY_ID:
      return {
        ...state,
        categoryId: action.payload,
      };
    case `${PLAYBOOK_TYPES.CREATE_CATEGORY}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        categoryId: null,
        categories: [...state.categories, data],
      };
    }
    case `${PLAYBOOK_TYPES.EDIT_CATEGORY}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        categoryId: null,
        categories: state.categories.map((c) => (c.id === data.id ? data : c)),
      };
    }
    case `${PLAYBOOK_TYPES.DELETE_CATEGORY}_FULFILLED`: {
      const id = action.payload;
      return {
        ...state,
        categoryId: null,
        categories: state.categories.filter((c) => c.id !== id),
      };
    }
    case PLAYBOOK_TYPES.SET_PLAYBOOK_ID:
      return {
        ...state,
        playbookId: action.payload,
      };
    case `${PLAYBOOK_TYPES.CREATE_PLAYBOOK}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        playbookId: data.id,
        list: [...state.list, data],
      };
    }
    case `${PLAYBOOK_TYPES.EDIT_PLAYBOOK}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        list: state.list.map((p) => (p.id === data.id ? data : p)),
      };
    }
    case `${PLAYBOOK_TYPES.DELETE_PLAYBOOK}_FULFILLED`: {
      const id = action.payload;
      return {
        ...state,
        playbookId: null,
        list: state.list.filter((p) => p.id !== id),
      };
    }
    case AUTH_TYPES.CLEAR:
      return INITIAL_PLAYBOOK_STATE;
    default:
      return state;
  }
}
