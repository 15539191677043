import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import useToast from '../../lib/hooks/useToast';
import { formatTimestamp } from '../../lib/date';
import {
  selectGroups,
  selectActiveGroup,
  selectFetchingGroups,
} from '../../store/group-admin/groupAdminSelectors';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import GroupAdmin from '../../store/group-admin/groupAdminActions';

import Group from './Group';
import GroupEditor from './GroupEditor';
import CollapsibleList from '../CollapsibleList';
import SuspenseLoading from '../SuspenseLoading';
import { PulseLogoLoader } from '../loader/index';
import GroupIcon from '../../assets/icons/group.svg?react';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
`;

const Sidebar = styled.aside`
  flex-shrink: 0;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
`;

const SidebarContents = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const GroupsManager = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(selectFetchingGroups);
  const [confirmCancelEditing, openConfirmCancelEditing] = useConfirmAsync();
  const [isEditing, setIsEditing] = useState(false);
  const [toastElement, openToast] = useToast();
  const groups = useSelector(selectGroups);
  const activeGroup = useSelector(selectActiveGroup);

  const createNewGroup = () => {
    dispatch(GroupAdmin.clearGroupId());
    setIsEditing(true);
  };

  const handleClickGroup = (g) => {
    if (isEditing && g.id !== activeGroup?.id) {
      openConfirmCancelEditing({
        title: 'Discard Changes?',
        content: (
          <div style={{ textAlign: 'center' }}>
            Would you like to discard your changes and open this group?
          </div>
        ),
        buttonText: 'Discard',
        onConfirm: () => {
          setIsEditing(false);
          return dispatch(GroupAdmin.setGroupId(g.id));
        },
      });
    } else {
      dispatch(GroupAdmin.setGroupId(g.id));
    }
  };

  // Fetch effect.
  useEffect(() => {
    dispatch(GroupAdmin.clearGroupId());
    dispatch(GroupAdmin.fetch());
  }, [dispatch]);

  return (
    <Container>
      <Sidebar>
        <SidebarContents>
          {fetching ? (
            <SuspenseLoading
              sx={{ height: '150px', mt: 2 }}
              component={<PulseLogoLoader />}
            />
          ) : (
            <CollapsibleList
              iconStyles={{ mt: '-1px' }}
              title="Groups"
              items={groups}
              icon={GroupIcon}
              selectedItem={activeGroup}
              showExpandButton="true"
              onClickNew={createNewGroup}
              onClickItem={handleClickGroup}
              itemTitle={(item) => item.name}
              itemSubtitle={(item) =>
                `Updated at: ${formatTimestamp(item.updateTimestamp)}`
              }
              newButtonLabel="Create Group"
              emptyMessage="No groups created yet."
            />
          )}
        </SidebarContents>
      </Sidebar>
      {isEditing ? (
        <GroupEditor
          openToast={openToast}
          group={activeGroup}
          onClickSave={() => setIsEditing(false)}
          onClickCancel={() => setIsEditing(false)}
        />
      ) : !!activeGroup ? (
        <Group {...activeGroup} onClickEdit={() => setIsEditing(true)} />
      ) : null}
      {toastElement}
      {confirmCancelEditing}
    </Container>
  );
};

export default GroupsManager;
