export const CHAT_TYPES = {
  WAR_ROOM: 'WAR_ROOM',
  GROUP: 'GROUP',
  DIRECT_MESSAGE: 'DIRECT_MESSAGE',
};

export const getNewChatLabelByType = (type) => {
  switch (type) {
    case CHAT_TYPES.WAR_ROOM:
      return 'New War Room';
    case CHAT_TYPES.GROUP:
      return 'New Breakout Room';
    case CHAT_TYPES.DIRECT_MESSAGE:
      return 'New Direct Message';
    default:
      return 'New Chat';
  }
};
