import { useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import { formatTimestamp } from '../../lib/date';
import {
  selectChannel,
  selectChannels,
  selectFetchingMessagingAdmin,
} from '../../store/messaging-admin/messagingAdminSelectors';
import { getGroupChatLabel } from '../../utils/messagingUtils';
import { selectUserListById } from '../../store/user/userSelectors';

import ChannelTableToolbar from './ChannelTableToolbar';
import UsersIcon from '../../assets/icons/icTeam.svg?react';
import WarRoomIcon from '../../assets/icons/globe.svg?react';
import EditIcon from '../../assets/icons/edit-outline.svg?react';
import BreakoutRoomIcon from '../../assets/icons/people.svg?react';
import TrashIcon from '../../assets/icons/trash-2-outline.svg?react';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0 1rem;
`;

const DataGrid = styled(MuiDataGrid)`
  border: none;
  border-radius: 0;
`;

const ChannelTable = ({
  selection,
  onChangeSelection,
  onClickRefresh,
  onClickEdit,
  onClickManageMembers,
  onClickDelete,
}) => {
  const selectionCid = selection?.[0];
  const [contextMenu, setContextMenu] = useState(null);
  const [refreshDisabled, setRefreshDisabled] = useState(false);
  const channels = useSelector(selectChannels);
  const selectedChannel = useSelector((state) =>
    selectChannel(state, selectionCid)
  );
  const userListById = useSelector(selectUserListById);
  const fetching = useSelector(selectFetchingMessagingAdmin);

  const rows = channels.map((c) => ({ ...c, id: c.cid }));
  const channelType = getGroupChatLabel(selectedChannel?.isWarRoom);

  const handleClickRefresh = () => {
    onClickRefresh();
    setRefreshDisabled(true); // Disable refresh for a minute.
    setInterval(() => setRefreshDisabled(false), 60 * 1000);
  };

  const handleDoubleClickRow = ({ id }) => onClickEdit(undefined, id);

  const handleContextMenu = (e) => {
    e.preventDefault();
    const cid = e.currentTarget.getAttribute('data-id');

    // If right-clicking on Headquarters, prevent the context menu altogther.
    if (channels.find((c) => c.cid === cid)?.name === 'Headquarters') {
      onChangeSelection([]);
      return setContextMenu(null);
    }

    onChangeSelection([cid]);
    setContextMenu(!contextMenu ? { left: e.clientX, top: e.clientY } : null);
  };

  const handleCloseContextMenu = () => setContextMenu(null);

  const handleClickContextMenuItem = (callback) => (e) => {
    handleCloseContextMenu();
    callback(e);
  };

  return (
    <Container>
      <DataGrid
        rows={rows}
        columns={[
          {
            field: 'icon',
            headerName: '',
            flex: 0.5,
            align: 'center',
            sortable: false,
            renderCell: ({ row }) => (
              <SvgIcon
                size="small"
                component={
                  Boolean(row.isWarRoom) ? WarRoomIcon : BreakoutRoomIcon
                }
              />
            ),
          },
          {
            field: 'name',
            headerName: 'Name',
            flex: 3,
          },
          {
            field: 'type',
            headerName: 'Type',
            flex: 2,
            valueGetter: ({ row }) => getGroupChatLabel(row.isWarRoom),
          },
          {
            field: 'member_count',
            headerName: 'Members',
            flex: 1,
            valueGetter: ({ row }) =>
              row.members.filter((m) => !!userListById?.[m.user_id]).length,
          },
          {
            field: 'last_message_at',
            headerName: 'Last Message Sent',
            flex: 2,
            valueFormatter: ({ value }) =>
              !!value ? formatTimestamp(value) : 'Never',
          },
          {
            field: 'updated_at',
            headerName: 'Updated At',
            flex: 2,
            valueFormatter: ({ value }) => formatTimestamp(value),
          },
          {
            field: 'created_at',
            headerName: 'Created At',
            flex: 2,
            valueFormatter: ({ value }) => formatTimestamp(value),
          },
        ]}
        autoPageSize
        rowHeight={36}
        headerHeight={42}
        disableColumnMenu
        loading={fetching}
        selectionModel={selection}
        disableMultipleColumnsSorting
        onRowDoubleClick={handleDoubleClickRow}
        isRowSelectable={({ row }) => row.name !== 'Headquarters'}
        onSelectionModelChange={(selectionModel) =>
          onChangeSelection(
            selectionModel?.[0] !== selectionCid ? selectionModel : []
          )
        }
        components={{ Toolbar: ChannelTableToolbar }}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: 'context-menu' },
          },
          toolbar: {
            filterInputProps: {
              autoFocus: true,
              disabled: fetching,
              placeholder: 'Filter chats...',
            },
            refreshButtonProps: {
              disabled: refreshDisabled,
              onClick: handleClickRefresh,
            },
            showButtons: !!selectionCid,
            buttons: [
              {
                title: `Edit ${channelType}`,
                onClick: onClickEdit,
              },
              {
                title: 'Manage Members',
                onClick: onClickManageMembers,
              },
              {
                title: 'Delete',
                onClick: onClickDelete,
                color: 'error',
              },
            ],
          },
        }}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? contextMenu : undefined}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleCloseContextMenu();
            },
          },
        }}
      >
        <MenuItem onClick={handleClickContextMenuItem(onClickEdit)}>
          <ListItemIcon>
            <SvgIcon component={EditIcon} />
          </ListItemIcon>
          Edit {channelType}
        </MenuItem>
        <MenuItem onClick={handleClickContextMenuItem(onClickManageMembers)}>
          <ListItemIcon>
            <SvgIcon component={UsersIcon} />
          </ListItemIcon>
          Manage Members
        </MenuItem>
        <MenuItem onClick={handleClickContextMenuItem(onClickDelete)}>
          <ListItemIcon>
            <SvgIcon component={TrashIcon} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default ChannelTable;
