import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';

import { selectBadges } from '../store/org/orgSelectors';

const ExpirableBadge = ({ name, children }) => {
  const badgeList = useSelector(selectBadges);

  if (!name || !badgeList.length) {
    return children;
  }

  const badge = badgeList.find((b) => b.name === name);
  const isExpired = !!badge?.expiryTimestamp
    ? Date.now() >= badge.expiryTimestamp
    : true;

  return !badge || isExpired ? (
    children
  ) : (
    <Badge
      color="secondary"
      overlap="circular"
      badgeContent="New"
      sx={{
        '& .MuiBadge-badge': { fontSize: 12, height: 16 },
      }}
    >
      {children}
    </Badge>
  );
};

export default ExpirableBadge;
