import { useState, useEffect, useRef } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  KeyBindingUtil,
  CompositeDecorator,
  Modifier,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import RichTextButtons from './RichTextButtons';
import ActionButtons from './ActionButtons';
import EmojiPicker from '../../EmojiPicker';
import Suggestions from './Suggestions';
import Messages from '../../../store/chat/messageActions';
import {
  createRegExAllowedFileFormats,
  getFileExt,
  getTypeByExtension,
} from '../../../lib/files';
import Drafts from '../../../store/chat/draftActions';
import { selectUserList } from '../../../store/user/userSelectors';
import { selectSDKConnected } from '../../../store/sdk/sdkSelectors';
import { selectCurrentChat } from '../../../store/chat/chatSelectors';
import { selectCurrentUserUUID } from '../../../store/auth/authSelectors';
import { selectDraftByChannelId } from '../../../store/chat/draftSelectors';
import 'draft-js/dist/Draft.css';
import LinearLoader from '../../LinearLoader';
import {
  selectOrgConfigAllowedFormats,
  selectOrgConfigFileSize,
} from '../../../store/org/orgSelectors';
import { MAX_LONG_LENGTH } from '../../../configs/validation';

// Icons
import { FileIcon } from '../../file-storage/FileIcon';
import CloseIcon from '../../../assets/icons/close.svg?react';
import DownloadOutline from '../../../assets/icons/filetype-text.svg?react';

// Including this fixes the placeholder styling (without having to
// manage it ourselves). Without this, the styles will not apply;
// the placeholder text would show up on it's own line and not hide
// when content was entered. This also fixes the context menu issue
// where the placeholder text was denying the menu when hovered over
// the (placeholder) text.

const DEFAULT_PLACEHOLDER = 'Write your message and hit enter to send...';
const MAX_MESSAGE_LENGTH = MAX_LONG_LENGTH.value;

// @@ streamio allows custom notifications, that can even send a message to the chat
// @@ this may remove the neccessity for tags
export const tags = {
  text: 'Text',
  file: 'File',
  image: 'Image',
  callMissed: 'CallMissed',
  busyCall: 'CallBusy',
  promote: 'Promote',
  demote: 'Demote',
  remove: 'Remove',
  join: 'Join',
  leave: 'Leave',
  subject: 'Subject',
  gap: 'Gap',
  shred: 'Shred',
  clear: 'Clear',
  admin: 'Admin',
  delete: 'Delete',
};

const InputWrapper = styled.div`
  position: relative;
  padding: 1.5em;
  border-top: 1px solid #ddd;
`;

const InputContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 10px;
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  ${({ isDragArea }) =>
    isDragArea &&
    ` 
    height: 85px;
  `}
`;

const EditorWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  position: relative;
  width: 100%;
  min-height: 36px;
  word-break: break-word;
  border-bottom: 1px solid #ddd;
  ${({ isDragArea }) =>
    isDragArea &&
    `
    border-bottom: none;
  `}
  .public-DraftEditorPlaceholder-root {
    z-index: 0;
    height: 100%;
  }

  .DraftEditor-editorContainer {
    z-index: 0;
    height: 100%;
  }

  .DraftEditor-root {
    max-height: 120px;
    overflow-y: auto;
  }
`;

const Mention = styled.span`
  color: white;
  background: #5383ff;
  padding: 0 1px;
`;

const ImagePreviewWrapper = styled.div`
  height: 60px;
  width: 100px;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  position: relative;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const FilePreviewWrapper = styled.div`
  position: relative;
  height: 60px;
  max-width: 75%;
  width: min-content;
`;

const FilePreviewContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomSvgIcon = styled(SvgIcon)`
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 5px;
`;

const FilePreviewTextContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const RemoveAttachmentButton = styled(IconButton)`
  position: absolute;
  top: -11px;
  right: -11px;
  z-index: 1;
  padding: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

const RichTextButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(238, 238, 238);
  border-radius: 0 0 10px 10px;
`;

function handleMentions(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'mention'
    );
  }, callback);
}

function HandleMention({ entityKey, offsetKey, children }) {
  return (
    <Mention data-entity-key={entityKey} data-offset-key={offsetKey}>
      {children}
    </Mention>
  );
}

const compositeDecorator = new CompositeDecorator([
  { strategy: handleMentions, component: HandleMention },
]);

function filterSuggestions(suggestions, text) {
  return suggestions.reduce(
    (acc, s) =>
      s.name.toLowerCase().includes(text.toLowerCase()) ? [...acc, s] : acc,
    []
  );
}

const reader = new FileReader();

export default function ChatMessageInput({ scrollToBottom }) {
  const theme = useTheme();
  const store = useStore();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const dragTimeout = useRef(null);
  const ref = useRef();
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const currentChat = useSelector(selectCurrentChat);
  const users = useSelector(selectUserList);
  const sdkConnected = useSelector(selectSDKConnected);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(compositeDecorator)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFileName, setLoadingFileName] = useState(null);
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState({});
  const [openEmojis, setOpenEmojis] = useState(false);
  const [formats, setFormats] = useState('');
  const [isDragArea, setIsDragArea] = useState(false);
  const [isValidFileError, setIsValidFileError] = useState(false);

  const { cid: chatId } = currentChat;
  const contentState = editorState.getCurrentContent();
  const testRegex = /\B@([\w]+)?/g;
  const MAX_FILE_SIZE = useSelector(selectOrgConfigFileSize);
  const configAllowedFormats = useSelector(selectOrgConfigAllowedFormats);
  const [isMaxLengthMessage, setIsMaxLengthMessage] = useState(false);

  // If we don't have the allParticipants array for whatever reason,
  // default to all users in the organization.
  const suggestions = Object.keys(currentChat.state.members)
    .filter((m) => m !== currentUserUUID)
    .map((m) => {
      const defaultUser = {
        userProperties: { uuid: -1 },
        email: 'former.member@mailinator.ca',
        firstName: 'Former',
        lastName: 'Member',
        active: true,
      };
      const user =
        users.find((u) => m === u.userProperties.uuid) || defaultUser;
      return {
        id: user.userProperties.uuid,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        active: user.active,
      };
    })
    .filter((user) => user.active);
  const match = testRegex.exec(contentState.getPlainText());
  const filtered = filterSuggestions(
    suggestions,
    match ? match[0].replace('@', '') : ''
  );
  const canSend =
    (file && file.size <= MAX_FILE_SIZE && !isLoading) ||
    (editorState.getCurrentContent().getPlainText().length <=
      MAX_MESSAGE_LENGTH &&
      editorState.getCurrentContent().getPlainText() !== '');

  reader.addEventListener('load', () => setSrc(reader.result), false);

  useEffect(() => {
    // Logic for controlling updates to the mention suggestions box
    const regex = /\B@([\w]+)?/g;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const selectedBlock = content.getBlockForKey(selection.getAnchorKey());
    const matches = regex.exec(selectedBlock.getText());
    const windowSelection = window.getSelection();
    const isBetweenSelection =
      matches &&
      selection.getFocusOffset() <= matches.index + matches[0].length &&
      selection.getFocusOffset() > matches.index;

    if (matches && isBetweenSelection && !open) {
      setOpen(true);

      if (windowSelection.focusNode && !offset) {
        const range = windowSelection.getRangeAt(0).cloneRange();
        range.collapse(true);

        // Bounndry element of the whole window
        const clientRect = range.getClientRects()[0];
        // Boundry for the input containing element
        const boundingRect = ref.current.getBoundingClientRect();

        setOffset(clientRect.left - boundingRect.left);
      }
    }

    if (!isBetweenSelection && open) {
      setOpen(false);
    }

    if (!matches && offset) {
      setOffset(null);
    }
  }, [editorState, offset, open]);

  useEffect(() => {
    const currentDraft = selectDraftByChannelId(
      store.getState(),
      currentChat.cid
    );

    setEditorState(
      EditorState.moveFocusToEnd(
        !!currentDraft?.message
          ? EditorState.createWithContent(
              convertFromRaw(markdownToDraft(currentDraft.message)),
              compositeDecorator
            )
          : EditorState.createEmpty(compositeDecorator)
      )
    );

    const file = !!currentDraft?.file ? currentDraft.file : null;
    if (!!file && file instanceof Blob) {
      setFile(file);
      reader.readAsDataURL(file);
    } else {
      setFile(null);
    }

    setSending(false);
    setPlaceholder(DEFAULT_PLACEHOLDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const onChange = (nextState) => {
    const nextContent = nextState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();

    setEditorState(nextState);
    setIsValidFileError(false);

    // Only fire this on actual key presses; not on focus, blur, or selection
    // as DraftJS calls onChange for selection events as well as key presses.
    if (
      nextContent.getPlainText() !== oldContent.getPlainText() &&
      sdkConnected
    ) {
      if (!nextContent.getPlainText().trim() && !file) {
        dispatch(Drafts.delete(chatId));
      } else {
        const rawState = convertToRaw(nextContent);
        const rawMarkdown = draftToMarkdown(rawState, {
          escapeMarkdownCharacters: false,
          entityItems: {
            mention: { open: () => '@@', close: () => '@@' },
          },
        });
        const mentions = Object.values(rawState.entityMap)
          .filter((e) => e.type === 'mention')
          .map((e) => e.data.email);

        let messageText = rawMarkdown.trim();

        if (mentions.length > 0) {
          const matches = rawMarkdown.match(/\B(@@[\w\s]+@@)\B/g);
          if (matches) {
            messageText = matches.reduce(
              (acc, m, i) => acc.replace(m, mentions[i]),
              rawMarkdown
            );
          }
        }

        // Strip any unicode chracters for embedded objects, potentially added by voice dictation.
        messageText = messageText.replaceAll(/([\uFFFC])/g, '');

        dispatch(Drafts.update(chatId, { message: messageText, file }));
      }

      // Fire keystroke event on Stream chat.
      currentChat.keystroke();
    }
  };

  const handleEmojiSelect = (e, emoji) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      emoji.emoji
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );

    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );

    setOpenEmojis(false);
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength > MAX_MESSAGE_LENGTH) {
      return 'handled';
    }
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handlePastedText = (text, html, nextState) => {
    const currentContent = nextState.getCurrentContent();
    const currentSelection = nextState.getSelection();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (html) {
      const strippedText = text.replace(/(<([^>]+)>)/gi, '');
      const remainingSpace =
        MAX_MESSAGE_LENGTH + 1 - (currentContentLength - selectedTextLength);
      if (strippedText.length <= remainingSpace) {
        // If the pasted text fits within the remaining space, replace it
        const newContent = Modifier.replaceText(
          currentContent,
          currentSelection,
          strippedText
        );

        const newEditorState = EditorState.push(nextState, newContent);
        setEditorState(
          EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
          )
        );

        dispatch(
          Drafts.setMessage(
            chatId,
            newEditorState.getCurrentContent().getPlainText()
          )
        );

        return 'handled';
      } else {
        const trimmedText = strippedText.slice(0, remainingSpace);

        const newContent = Modifier.replaceText(
          currentContent,
          currentSelection,
          trimmedText
        );

        const newEditorState = EditorState.push(nextState, newContent);
        setEditorState(
          EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
          )
        );

        dispatch(
          Drafts.setMessage(
            chatId,
            newEditorState.getCurrentContent().getPlainText()
          )
        );

        return 'handled';
      }
      return 'not-handled';
    }
  };

  const handlePastedFiles = (paste) => {
    setFile(paste[0]);
    reader.readAsDataURL(paste[0]);
    dispatch(Drafts.setFile(chatId, paste[0]));
  };

  const sendMessage = async () => {
    setIsLoading(true);
    const content = editorState.getCurrentContent();
    const rawState = convertToRaw(content);
    const rawMarkdown = draftToMarkdown(rawState, {
      escapeMarkdownCharacters: false,
      entityItems: {
        mention: { open: () => '@@', close: () => '@@' },
      },
    });
    const mentions = Object.values(rawState.entityMap)
      .filter((e) => e.type === 'mention')
      .map((e) => e.data.email);

    // Trim message so no extra space at start or end.
    let messageText = rawMarkdown.trim();

    if (mentions.length > 0) {
      const matches = rawMarkdown.match(/\B(@@[\w\s]+@@)\B/g);
      if (matches) {
        messageText = matches.reduce(
          (acc, m, i) => acc.replace(m, mentions[i]),
          rawMarkdown
        );
      }
    }

    // Check message Length
    if (content.getPlainText().length > MAX_MESSAGE_LENGTH) {
      return;
    }

    // unicode for embedded obj (voice dictation found to add it to message)
    messageText = messageText.replaceAll(/([\uFFFC])/g, '');

    // determines if message without spaces is not empty
    const validText = Boolean(messageText.replaceAll(/([ ])/g, '').length > 0);

    if (chatId) {
      if (!validText && !file) {
        setIsLoading(false);
        setEditorState(EditorState.createEmpty());
        setPlaceholder('Cannot send empty message...');

        return;
      }
      let attachments;
      if (file && file.size <= MAX_FILE_SIZE) {
        let response = null;
        let type = 'File';
        setLoadingFileName(file.name);

        try {
          if (getTypeByExtension(getFileExt(file.name)) === 'Image') {
            type = 'Image';
            response = await currentChat.sendImage(file);
          } else {
            response = await currentChat.sendFile(file);
          }
        } catch (error) {
          if (error.status === 400) {
            console.log(error);
            setIsValidFileError(true);
            setFile(null);
            setIsLoading(false);
          }
        }

        if (response) {
          attachments = [
            {
              type,
              name: file.name,
              asset_url: response.file,
              thumb_url: response.file,
            },
          ];
        }
      }

      if (attachments && attachments.length > 0) {
        dispatch(
          Messages.sendMessage('', attachments, { hasAttachments: true })
        )
          .then((res) => {
            if (res) {
              setIsLoading(false);
              setLoadingFileName(null);
            }
          })
          .catch(() => {
            console.info('@@SHQ/Messaging - Queue message triggered...');
            dispatch(Messages.queueMessage('', attachments));
          });
      }

      if (messageText !== '') {
        setIsLoading(false);
        dispatch(Messages.sendMessage(messageText)).catch(() => {
          console.info('@@SHQ/Messaging - Queue message triggered...');
          dispatch(Messages.queueMessage(messageText));
        });
      }

      currentChat.stopTyping();

      setEditorState(EditorState.createEmpty());
      setFile(null);
      setSending(false);
      setPlaceholder(DEFAULT_PLACEHOLDER);
      setEditorState(
        EditorState.moveFocusToEnd(EditorState.createEmpty(compositeDecorator))
      );
    }

    scrollToBottom();
    setFormats('');
  };

  const addMention = (idx) => {
    const regex = /\B@([\w]+)?/g;
    const text = contentState.getPlainText();
    const matches = regex.exec(text);
    const mention = filtered[idx];

    if (matches && mention?.active !== undefined && mention.active) {
      const selection = editorState.getSelection();
      const range = selection.merge({
        anchorOffset: matches.index,
        focusOffset: matches.index + matches[0].length,
      });

      const contentStateWithEntity = contentState.createEntity(
        'mention',
        'IMMUTABLE',
        { email: mention?.email }
      );
      const key = contentStateWithEntity.getLastCreatedEntityKey();

      const contentWithRemovedText = Modifier.replaceText(
        contentStateWithEntity,
        range,
        mention?.name,
        null,
        key
      );

      const contentStateWithData = Modifier.setBlockData(
        contentWithRemovedText,
        range,
        { type: 'mention', email: mention?.email }
      );

      const nextEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithData,
      });

      setEditorState(EditorState.forceSelection(nextEditorState, range));

      const mentionRange = selection.merge({
        anchorOffset: matches.index + mention?.name.length,
        focusOffset: matches.index + mention?.name.length,
      });

      const addSpace = Modifier.insertText(
        contentWithRemovedText,
        mentionRange,
        ' '
      );

      const addSpaceEditorState = EditorState.push(
        editorState,
        addSpace,
        'insert-characters'
      );

      // Instead of setting editorState directly, call the onChange handler to also commit the mention in the message's draft.
      onChange(addSpaceEditorState);
    } else {
      setEditorState(EditorState.createWithText(matches[0]));
      const plainText = contentState.getPlainText();
      if (
        plainText.includes('@') &&
        plainText.charAt(0) === '@' &&
        plainText.charAt(1) !== ' '
      ) {
        sendMessage();
      }
    }
  };

  const keyBinding = (e) => {
    let command = '';

    switch (e.keyCode) {
      case 13:
        if (!e.nativeEvent.shiftKey) {
          command = 'send-message';
        }

        if (open) {
          command = 'commit-selection';
        }

        break;
      case 66:
        if (KeyBindingUtil.hasCommandModifier(e)) {
          command = 'bold';
        }

        break;
      case 38:
        if (open) {
          command = 'mention-up';
        }

        break;
      case 40:
        if (open) {
          command = 'mention-down';
        }

        break;
      default:
        command = null;
    }

    return command || getDefaultKeyBinding(e);
  };

  const handleCommand = (command, state) => {
    switch (command) {
      case 'backspace': {
        const currentContent = state.getCurrentContent();
        const type = currentContent.getFirstBlock().getType();
        const isList =
          type === 'unordered-list-item' || type === 'ordered-list-item';
        const isEmpty = currentContent.getPlainText().trim() === '';

        if (isEmpty) {
          setPlaceholder(DEFAULT_PLACEHOLDER);
        }

        if (isList && isEmpty) {
          setEditorState(RichUtils.toggleBlockType(editorState, 'unstyled'));

          return 'handled';
        }

        return 'not-handled';
      }
      case 'send-message': {
        if (
          editorState.getCurrentContent().getPlainText().trim() !== '' ||
          file
        ) {
          sendMessage();
        } else {
          // Needed to add this to reset editor when user does not enter text
          currentChat.stopTyping();
          setEditorState(EditorState.createEmpty());
          setFile(null);
          setSending(false);
          setPlaceholder(DEFAULT_PLACEHOLDER);
          setEditorState(
            EditorState.moveFocusToEnd(
              EditorState.createEmpty(compositeDecorator)
            )
          );
        }
        return 'handled';
      }
      case 'bold':
        setEditorState(RichUtils.toggleInlineStyle(state, 'BOLD'));

        return 'handled';
      case 'mention-up':
        setCursor((cursor - 1 + filtered.length) % filtered.length);

        return 'handled';
      case 'mention-down':
        setCursor((cursor + 1) % filtered.length);

        return 'handled';
      case 'commit-selection':
        addMention(cursor);
        setOffset(null);
        setOpen(false);

        return 'handled';
      default:
        return 'not-handled';
    }
  };

  const formatText = (type) => {
    const currentType = RichUtils.getCurrentBlockType(editorState);
    const isStyled = currentType !== 'unstyled';

    if (type === 'UL') {
      setEditorState(
        RichUtils.toggleBlockType(editorState, 'unordered-list-item')
      );
      setPlaceholder(
        isStyled && currentType === 'unordered-list-item'
          ? DEFAULT_PLACEHOLDER
          : ''
      );
    } else if (type === 'OL') {
      setEditorState(
        RichUtils.toggleBlockType(editorState, 'ordered-list-item')
      );
      setPlaceholder(
        isStyled && currentType === 'ordered-list-item'
          ? DEFAULT_PLACEHOLDER
          : ''
      );
    } else {
      setEditorState(RichUtils.toggleInlineStyle(editorState, type));
    }
  };

  const fileValidation = (fileToValidate) => {
    const allowedExtensions =
      createRegExAllowedFileFormats(configAllowedFormats);

    if (fileToValidate?.name && allowedExtensions.test(fileToValidate.name)) {
      setIsValidFileError(false);
      return true;
    }

    setIsValidFileError(true);
    return false;
  };

  const chooseFile = (e) => {
    const file = e.target.files[0];
    if (file && fileValidation(file)) {
      setFile(file);
      reader.readAsDataURL(file);
    } else {
      setFile(null);
    }
    dispatch(Drafts.setFile(chatId, file));
  };

  const handleFormatChange = (format) => {
    setFormats(format);
  };

  const clearFile = () => {
    setSrc(null);
    setFile(null);
    dispatch(Drafts.setFile(chatId, null));
  };

  let preview = null;

  if (file && file.size <= MAX_FILE_SIZE) {
    if (getTypeByExtension(getFileExt(file.name)) === 'Image') {
      preview = (
        <ImagePreviewWrapper>
          <ImagePreview src={src} alt={file.name} />
          <RemoveAttachmentButton
            size="small"
            color="white"
            onClick={clearFile}
          >
            <SvgIcon component={CloseIcon} />
          </RemoveAttachmentButton>
        </ImagePreviewWrapper>
      );
    } else {
      preview = (
        <FilePreviewWrapper title={file.name}>
          <FilePreviewContent>
            <CustomSvgIcon fontSize="large">
              <FileIcon extension={getFileExt(file.name)} />
            </CustomSvgIcon>
            <FilePreviewTextContainer title={file.name}>
              <span>{file.name}</span>
            </FilePreviewTextContainer>
          </FilePreviewContent>
          <RemoveAttachmentButton
            size="small"
            color="white"
            onClick={clearFile}
          >
            <SvgIcon component={CloseIcon} />
          </RemoveAttachmentButton>
        </FilePreviewWrapper>
      );
    }
  } else {
    preview = (
      <span style={{ color: theme.palette.error.main }}>
        File exceeds maximum size of 100 MB
      </span>
    );
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let hide = false;
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hide = true;
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(dragTimeout.current);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragArea(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(dragTimeout.current);
    setIsDragArea(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragTimeout.current = setTimeout(() => setIsDragArea(false), 50);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    clearTimeout(dragTimeout.current);
    setIsDragArea(false);

    const file = e.dataTransfer.files[0];

    if (file && fileValidation(file)) {
      setFile(file);
      reader.readAsDataURL(file);
      dispatch(Drafts.setFile(chatId, file));
    }
  };

  useEffect(() => {
    document.addEventListener('dragenter', handleDragEnter);
    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    // Clean up the event listeners
    return () => {
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
      clearTimeout(dragTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (
      editorState.getCurrentContent().getPlainText().length > MAX_MESSAGE_LENGTH
    ) {
      setIsMaxLengthMessage(true);
    } else {
      setIsMaxLengthMessage(false);
    }
  }, [editorState]);

  return (
    <InputWrapper>
      <InputContainer>
        <InputBox ref={ref} isDragArea={isDragArea}>
          {isLoading && !isValidFileError ? (
            <EditorWrapper>
              <LinearLoader label={`Sending: ${loadingFileName}`} />
            </EditorWrapper>
          ) : (
            <EditorWrapper
              isDragArea={isDragArea}
              onClick={() => inputRef.current.focus()}
              hide={hide}
            >
              <Editor
                ref={inputRef}
                placeholder={isDragArea ? 'Drop files' : placeholder}
                editorState={editorState}
                onChange={onChange}
                keyBindingFn={keyBinding}
                handleKeyCommand={handleCommand}
                handlePastedFiles={handlePastedFiles}
                handleBeforeInput={handleBeforeInput}
                handlePastedText={handlePastedText}
                stripPastedStyles
                spellCheck
              />
              {isDragArea ? (
                <IconButton
                  style={{
                    backgroundColor: '#2B4C82',
                    margin: '0.2em 0.2em',
                    height: '2.5em',
                    width: '2.5em',
                    position: 'absolute',
                    top: '2px',
                    left: '45%',
                  }}
                  size="large"
                >
                  <SvgIcon
                    component={DownloadOutline}
                    fill={theme.palette.white.main}
                    style={{
                      color: theme.palette.white.main,
                      margin: '0.2em 0.2em',
                      height: '3em',
                      width: '3em',
                      marginTop: '27px',
                      marginLeft: '29px',
                    }}
                  />
                </IconButton>
              ) : null}
              {file && file.name && !sending && preview}
              {isValidFileError && (
                <span style={{ color: theme.palette.error.main }}>
                  This file format is not supported
                </span>
              )}
            </EditorWrapper>
          )}
          <Suggestions
            list={filtered}
            open={open}
            offset={offset}
            cursor={cursor}
            handeleMention={addMention}
          />
        </InputBox>
        <RichTextButtonsContainer>
          {/* eslint-disable-next-line max-len */}
          <RichTextButtons
            handleFormat={formatText}
            formats={formats}
            handleFormatChange={handleFormatChange}
            aStyle={editorState.getCurrentInlineStyle()}
          />
          <ActionButtons
            hasFile={Boolean(file)}
            canSend={canSend}
            handleChoose={chooseFile}
            handleClear={clearFile}
            handleSend={sendMessage}
            handleEmoji={() => setOpenEmojis(!openEmojis)}
          />
          <EmojiPicker
            open={openEmojis}
            onClose={() => setOpenEmojis(false)}
            handleSelection={handleEmojiSelect}
          />
        </RichTextButtonsContainer>
      </InputContainer>
      {isMaxLengthMessage && (
        <Typography sx={{ fontSize: '12px', color: '#f83245' }}>
          {MAX_LONG_LENGTH.message}
        </Typography>
      )}
    </InputWrapper>
  );
}
