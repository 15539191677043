import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ConfigEditor from './ConfigEditor';

const ConfigGroup = ({ name, configs, ...props }) => (
  <Stack gap={1}>
    <Typography component="h2" variant="h5">
      {name}
    </Typography>
    <Divider />
    <Stack gap={2}>
      {!!configs.length &&
        configs.map((config) => (
          <ConfigEditor {...props} {...config} key={config.databaseKey} />
        ))}
    </Stack>
  </Stack>
);

export default ConfigGroup;
