import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import UnauthenticatedLayout from '../UnauthenticatedLayout';
import AppStore from '../../assets/images/app-store.svg?react';
import GooglePlayStore from '../../assets/images/google-play-store.svg?react';

const MobileDeviceWarning = () => {
  const appImageStyles = { width: '150px', height: 'auto' };

  return (
    <UnauthenticatedLayout
      title="We noticed you're using a mobile device."
      contentProps={{ sx: { width: '32rem !important' } }}
    >
      <Typography align="center" sx={{ lineHeight: 1.5 }}>
        For the best experience, please download our mobile app.
      </Typography>
      <Stack alignItems="center" gap={1}>
        <a
          href="https://apps.apple.com/app/unio/id1511299121"
          rel="noopener noreferrer"
          target="_blank"
        >
          <AppStore style={appImageStyles} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.cuecollabmobile"
          rel="noopener noreferrer"
          target="_blank"
        >
          <GooglePlayStore style={appImageStyles} />
        </a>
      </Stack>
    </UnauthenticatedLayout>
  );
};

export default MobileDeviceWarning;
