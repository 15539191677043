import {
  localStorageKeys,
  scheduleLocalStorageRemoval,
} from '../../utils/localStorageUtils';
import { AUTH_TYPES } from '../auth/authReducer';
import { STORAGE_TYPES } from '../storage/storageReducer';

export const INCIDENT_TYPES = {
  FETCH: '@@incidents/FETCH',
  SEARCH: '@@incidents/SEARCH',
  SYNC: '@@incidents/SYNC',
  SET_ITEM: '@@incidents/SET_ITEM',
  GET_ITEM: '@@incidents/GET_ITEM',
  CREATE_ITEM: '@@incidents/CREATE_ITEM',
  EDIT_ITEM: '@@incidents/EDIT_ITEM',
  RESOLVE_ITEM: '@@incidents/RESOLVE_ITEM',
  CHECK_IN: '@@incidents/CHECK_IN',
  GET_PDF: '@@incidents/GET_PDF',
  CREATE_ACTIVITY: '@@incidents/CREATE_ACTIVITY',
  EDIT_ACTIVITY: '@@incidents/EDIT_ACTIVITY',
  DELETE_ACTIVITY: '@@incidents/DELETE_ACTIVITY',
};

const INITIAL_INCIDENT_STATE = {
  fetching: false,
  items: [],
  currentPage: 1,
  hasMoreItems: true,
  currentItem: null,
  currentItemAttachments: [],
};

export default function incidents(state = INITIAL_INCIDENT_STATE, action) {
  switch (action.type) {
    case `${INCIDENT_TYPES.FETCH}_PENDING`:
    case `${INCIDENT_TYPES.SEARCH}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${INCIDENT_TYPES.FETCH}_REJECTED`:
    case `${INCIDENT_TYPES.SEARCH}_REJECTED`:
      return {
        ...state,
        fetching: false,
      };
    case `${INCIDENT_TYPES.FETCH}_FULFILLED`:
    case `${INCIDENT_TYPES.SEARCH}_FULFILLED`: {
      const { items, currentPage, hasMoreItems } = action.payload;
      const newItems = currentPage > 1 ? [...state.items, ...items] : items;

      // Store the incident list in localStorage.
      localStorage.setItem(
        localStorageKeys.INCIDENTS,
        JSON.stringify({
          items: newItems,
          hasMoreItems,
        })
      );

      // Set a timeout to delete the list from localStorage
      scheduleLocalStorageRemoval(localStorageKeys.INCIDENTS);

      return {
        ...state,
        fetching: false,
        items: newItems,
        hasMoreItems,
        currentPage,
      };
    }
    case INCIDENT_TYPES.SET_ITEM:
    case `${INCIDENT_TYPES.GET_ITEM}_FULFILLED`:
      return {
        ...state,
        currentItem: action.payload,
      };
    case `${INCIDENT_TYPES.CREATE_ITEM}_FULFILLED`:
      return {
        ...state,
        currentItem: action.payload.data,
      };
    case `${INCIDENT_TYPES.EDIT_ITEM}_FULFILLED`:
    case `${INCIDENT_TYPES.CHECK_IN}_FULFILLED`:
    case `${INCIDENT_TYPES.CREATE_ACTIVITY}_FULFILLED`:
    case `${INCIDENT_TYPES.EDIT_ACTIVITY}_FULFILLED`:
    case `${INCIDENT_TYPES.DELETE_ACTIVITY}_FULFILLED`: {
      const item = action.payload.data;

      return {
        ...state,
        currentItem: item,
        items: state.items.map((i) => {
          if (i.id === item.id) {
            return item;
          }
          return i;
        }),
      };
    }
    case `${INCIDENT_TYPES.RESOLVE_ITEM}_FULFILLED`: {
      const item = action.payload.data;

      return {
        ...state,
        currentItem: null,
        currentItemAttachments: [],
        items: state.items.map((i) => {
          if (i.id === item.id) {
            return item;
          }
          return i;
        }),
      };
    }
    case `${STORAGE_TYPES.FETCH_FOLDER}_PENDING`:
    case `${STORAGE_TYPES.FETCH_FOLDER}_REJECTED`:
      return {
        ...state,
        currentItemAttachments: [],
      };
    case `${STORAGE_TYPES.FETCH_FOLDER}_FULFILLED`:
      return {
        ...state,
        currentItemAttachments: action.payload.data.tree || [],
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_INCIDENT_STATE;
    default:
      return state;
  }
}
