import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  selectHasIncident,
  selectHasMultipleIncidents,
} from '../../store/dashboard/dashboardSelectors';
import { selectUnresolvedTabletops } from '../../store/incident/incidentSelectors';

import ArrowIcon from '../../assets/icons/arrow-ios-forward-outline.svg?react';

const BadgeContainer = styled.div`
  margin-bottom: ${(props) => props.marginBottom};
  height: auto;
  width: fit-content;
  text-align: center;
`;

const BadgeIcon = styled.img`
  margin: auto;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  object-fit: contain;
`;

const IncidentButton = styled(Button)`
  && {
    padding: 0.25rem 0.75rem;
    color: ${({ theme }) => theme.palette.white.main};

    .MuiButton-endIcon {
      margin-left: 0;
    }
  }
`;

const StyledIcon = styled(SvgIcon)`
  color: white;
  height: 20px;
  min-height: 20px;
`;

export default function DashBadge({
  icon,
  color,
  status,
  title,
  onClick,
  button = false,
  titleVariant = 'h2',
  width = '190px',
  height = '180px',
  fontSize = '29px',
  marginBottom = '40px',
  hasButton = false,
}) {
  const hasMultipleIncidents = useSelector(selectHasMultipleIncidents);
  const hasIncident = useSelector(selectHasIncident);
  const unresolvedTabletops = useSelector(selectUnresolvedTabletops);
  const hasMultipleTabletops = unresolvedTabletops.length > 1;
  const hasTabletop = unresolvedTabletops.length === 1;

  const buttonText = hasMultipleIncidents
    ? 'View Incidents'
    : hasIncident
      ? 'View Incident'
      : hasMultipleTabletops
        ? 'View Tabletop Exercises'
        : hasTabletop
          ? 'View Tabletop Exercise'
          : '';

  return (
    <BadgeContainer hasIncident={hasIncident} marginBottom={marginBottom}>
      <BadgeIcon src={icon} width={width} height={height} />
      <Typography variant="body1" display="block">
        {title}
      </Typography>
      <Box sx={hasButton ? { minHeight: 78 } : undefined}>
        <Typography
          style={{ color }}
          fontWeight={300}
          fontSize={fontSize}
          variant={titleVariant}
          display="block"
          gutterBottom
        >
          {status}
        </Typography>
        {button && (
          <IncidentButton
            variant="contained"
            color={hasMultipleIncidents || hasIncident ? 'error' : 'warning'}
            tabletop={
              hasMultipleIncidents || hasIncident
                ? 'false'
                : (hasMultipleTabletops || hasTabletop).toString()
            }
            endIcon={
              <StyledIcon color="primary">
                <ArrowIcon />
              </StyledIcon>
            }
            onClick={onClick}
          >
            {buttonText}
          </IncidentButton>
        )}
      </Box>
    </BadgeContainer>
  );
}
