import { useState } from 'react';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';

import { PulseLogoLoader } from './loader';
import SuspenseLoading from './SuspenseLoading';

import PlusIcon from '../assets/icons/plus.svg?react';
import MinusIcon from '../assets/icons/minus.svg?react';
import CloseIcon from '../assets/icons/close.svg?react';
import EditIcon from '../assets/icons/edit-outline.svg?react';
import UpIcon from '../assets/icons/arrow-ios-upward.svg?react';
import DownIcon from '../assets/icons/arrow-ios-downward.svg?react';

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0.5rem;
  gap: 0.25rem;
`;

const ListTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NewLabel = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme, color }) =>
    theme.palette?.[color]?.main || theme.palette.primary.main};
`;

const CollapsibleListHeader = ({
  title,
  open = false,
  expanded = true,
  showNewLabel = true,
  showEditButton = false,
  showExpandButton = true,
  showDeleteButton = false,
  newButtonLabel = 'Create',
  newButtonDisabled,
  onClickNew,
  onClickEdit,
  onClickDelete,
  onClickCollapse,
}) => {
  const theme = useTheme();
  const [mouseOver, setMouseOver] = useState(false);
  const editButtonVisible = mouseOver && showEditButton;

  return (
    <Header
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ flexGrow: 1, overflow: 'hidden' }}
      >
        {showExpandButton && (
          <IconButton onClick={onClickCollapse} size="small">
            <SvgIcon htmlColor={theme.palette.primary.main}>
              {expanded ? <DownIcon /> : <UpIcon />}
            </SvgIcon>
          </IconButton>
        )}
        {showDeleteButton && (
          <Tooltip arrow title="Delete" disableInteractive>
            <IconButton onClick={onClickDelete} size="small">
              <SvgIcon htmlColor={theme.palette.error.main}>
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        <ListTitle color="primary" component="h2">
          {title}
        </ListTitle>
        {editButtonVisible && (
          <Tooltip arrow title="Edit" disableInteractive>
            <IconButton id="edit-button" size="small" onClick={onClickEdit}>
              <SvgIcon htmlColor={theme.palette.primary.main} sx={{ p: 0.3 }}>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ flexShrink: 0 }}
      >
        {showNewLabel && (
          <NewLabel color={newButtonDisabled ? 'disabled' : 'primary'}>
            New
          </NewLabel>
        )}
        <Tooltip
          arrow
          placement="right"
          disableInteractive
          title={open ? 'Cancel' : newButtonLabel}
        >
          <span>
            <IconButton
              size="small"
              color="primary"
              onClick={onClickNew}
              disabled={newButtonDisabled}
            >
              <SvgIcon>{open ? <MinusIcon /> : <PlusIcon />}</SvgIcon>
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Header>
  );
};

const CollapsibleListItemButton = styled(ListItemButton)`
  gap: 0.75rem;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  background-color: ${({ active, theme }) =>
    active === 'true' && theme.palette.action.selected};
  border-left: ${({ active, theme }) =>
    `0.25rem solid ${
      active === 'true' ? theme.palette.primary.main : 'transparent'
    }`};

  &&:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.palette.action.selected : theme.palette.action.hover};
  }
`;

const itemTitleStyles = `
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ItemTitle = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.25;
  ${itemTitleStyles}
`;
const ItemSubtitle = styled.small`
  color: #aaa;
  line-height: 1.5;
  ${itemTitleStyles}
`;

const IconContainer = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const CollapsibleListItem = ({
  icon,
  isActive,
  title = '',
  subtitle = undefined,
  onClick,
  iconStyles,
}) => (
  <Tooltip arrow placement="right" title={title.length > 30 ? title : ''}>
    <CollapsibleListItemButton onClick={onClick} active={isActive.toString()}>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ flexShrink: 0 }}
      >
        <IconContainer>
          <SvgIcon
            component={icon}
            htmlColor="#fff"
            sx={{
              p: 0.1,
              ...iconStyles,
            }}
          />
        </IconContainer>
      </Stack>
      <Stack
        direction="column"
        alignItems="flex-start"
        gap={0.25}
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        <ItemTitle component="h3" color={isActive ? 'primary' : 'black'}>
          {title}
        </ItemTitle>
        {!!subtitle && <ItemSubtitle color="grey">{subtitle}</ItemSubtitle>}
      </Stack>
    </CollapsibleListItemButton>
  </Tooltip>
);

const CollapsibleList = ({
  loading,
  icon,
  iconStyles = {},
  title,
  items = [],
  selectedItem,
  newButtonLabel,
  newButtonDisabled = false,
  showNewLabel = true,
  showEditButton,
  showExpandButton,
  showDeleteButton,
  onClickNew,
  onClickItem,
  onClickEdit,
  onClickDelete,
  itemTitle = null,
  itemSubtitle = null,
  emptyMessage = 'No items to show.',
}) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <section>
      <CollapsibleListHeader
        title={title}
        expanded={expanded}
        showNewLabel={showNewLabel}
        newButtonLabel={newButtonLabel}
        newButtonDisabled={newButtonDisabled}
        showEditButton={showEditButton}
        showExpandButton={showExpandButton}
        showDeleteButton={showDeleteButton}
        onClickNew={onClickNew}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickCollapse={() => setExpanded((state) => !state)}
      />
      {loading ? (
        <SuspenseLoading
          style={{ height: '150px' }}
          component={<PulseLogoLoader />}
        />
      ) : (
        <Collapse in={expanded}>
          <List sx={{ py: 0 }}>
            {!items.length && (
              <Typography
                color="grey"
                component="p"
                align="center"
                variant="caption"
              >
                {emptyMessage}
              </Typography>
            )}
            {!!items.length &&
              items.map((item) => (
                <CollapsibleListItem
                  key={item.id}
                  icon={icon}
                  iconStyles={iconStyles}
                  onClick={() => onClickItem(item)}
                  title={!!itemTitle ? itemTitle(item) : undefined}
                  subtitle={!!itemSubtitle ? itemSubtitle(item) : undefined}
                  isActive={!!selectedItem && selectedItem.id === item.id}
                />
              ))}
          </List>
        </Collapse>
      )}
    </section>
  );
};

export default CollapsibleList;
