import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { selectUserList } from '../../store/user/userSelectors';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';
import GroupAdmin from '../../store/group-admin/groupAdminActions';

import Avatar from '../Avatar';
import GroupLayout from './GroupLayout';
import EditIcon from '../../assets/icons/edit-outline.svg?react';
import TrashIcon from '../../assets/icons/trash-2-outline.svg?react';

const Name = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionExternal = styled.span`
  color: #777;
  font-size: 0.75em;
  line-height: 1;
`;

const Group = ({
  id,
  name,
  description,
  userIds,
  createdBy,
  onClickEdit,
  onClickDelete,
}) => {
  const dispatch = useDispatch();
  const [confirmDeleteElement, openConfirmDelete] = useConfirmAsync();
  const userList = useSelector(selectUserList);
  const userFullName = userList.find(
    (user) => user.userId === createdBy
  )?.fullName;

  const handleClickDelete = () => {
    openConfirmDelete({
      title: 'Delete Group?',
      content: (
        <div style={{ textAlign: 'center' }}>
          This will delete <b>{name}</b> group.
        </div>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () => dispatch(GroupAdmin.delete(id)).then(onClickDelete),
    });
  };

  return (
    <>
      <GroupLayout
        nameComponent={
          <Typography variant="h3" component="h1">
            {name}
          </Typography>
        }
        toolbarButtons={[
          {
            icon: <EditIcon />,
            tooltip: 'Edit',
            onClick: onClickEdit,
          },
          {
            icon: <TrashIcon />,
            tooltip: 'Delete',
            onClick: handleClickDelete,
          },
        ]}
        descriptionComponent={
          <ReactMarkdown plugins={[remarkGfm]}>{description}</ReactMarkdown>
        }
        membersComponent={
          <Stack gap={1.5} alignItems="flex-start">
            {userList
              .filter((user) => userIds && userIds.includes(user.userId))
              .map((user, index) => (
                <Stack direction="row" gap={1} alignItems="center" key={index}>
                  <Avatar
                    profileImageUrl={getCachedProfileImageUrl(user)}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    fontSize="1rem"
                    size="smaller"
                  />
                  <Name>
                    {user.firstName} {user.lastName}
                  </Name>
                  {user?.userProperties.type !== 'internal' && (
                    <OptionExternal>(External)</OptionExternal>
                  )}
                </Stack>
              ))}
          </Stack>
        }
        createdBy={userFullName}
      />
      {confirmDeleteElement}
    </>
  );
};

export default Group;
