import Typography from '@mui/material/Typography';

import { TASK_PRIORITY } from '../../../store/incident/incidentTypes';

import TaskLayout from './TaskLayout';
import { Priority } from '../../PrioritySelect';
import EditItemControl from '../EditItemControl';

const Task = ({
  title,
  description,
  priority = TASK_PRIORITY.MEDIUM,
  onClickEdit,
  onClickDelete,
  isEditable = false,
  sx,
}) => {
  return (
    <TaskLayout
      isEditable={isEditable}
      headerComponent={
        <>
          <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Priority faded level={priority} sx={{ flexShrink: 0 }} />
        </>
      }
      bodyComponent={
        <Typography>{description || '(Nothing to show.)'}</Typography>
      }
      rightComponent={
        <EditItemControl
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      }
      sx={sx}
    />
  );
};

export default Task;
