import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import { selectOrgCode } from '../../../store/welcome/welcomeSelectors';

const WelcomeStep = () => {
  const orgCode = useSelector(selectOrgCode);

  return (
    <>
      <Typography paragraph>Congratulations!</Typography>
      <Typography paragraph>
        You’re about take your first steps in setting up ShadowHQ, your
        out-of-band virtual headquarters.
      </Typography>
      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>
      <Stack gap={1}>
        <Typography paragraph>
          The following information has already been setup for you:
        </Typography>
        <TextField
          readOnly
          size="small"
          value={orgCode}
          label="Your Org Code"
          sx={{
            width: '50%',
          }}
        />
      </Stack>
    </>
  );
};

export default WelcomeStep;
