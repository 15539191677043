import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

import LogoIcon from '../../assets/images/brandmark.svg?react';

const Pulse = styled.div`
  border-style: solid;
  border-width: 5px;
  border-radius: 50%;
  border-color: transparent;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  @keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(59, 62, 102, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(59, 62, 102, 0);
    }
    100% {
      transform: scale(1.25);
      -webkit-box-shadow: 0 0 0 0 rgba(59, 62, 102, 0);
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PulseLogoLoader = (props) => (
  <Container {...props}>
    <Pulse>
      <SvgIcon
        style={{
          width: '55px',
          height: '55px',
          marginBottom: '-5px',
          backgroundColor: 'transparent',
        }}
      >
        <LogoIcon />
      </SvgIcon>
    </Pulse>
  </Container>
);
