import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ClipLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  WELCOME_PACKAGE_ERRORS,
  WELCOME_PACKAGE_VALIDATION_ERRORS,
} from '../../configs/welcomePackage';
import {
  selectIsSaving,
  selectErrorCode,
  selectIsFetching,
  selectWasModified,
  selectErrorToastMessage,
  selectIsSubmittedOrCompleted,
} from '../../store/welcome/welcomeSelectors';
import WelcomePackage from '../../store/welcome/welcomeActions';

import Stepper from './Stepper';
import ErrorToast from './modals/ErrorToast';
import { STEP_IDS, stepList } from './steps';
import PackageNotFound from './PackageNotFound';

const Sidebar = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(3, 4)};
  flex-shrink: 0;
  overflow: auto;
  color: #fff;
`;

const StepContainer = styled(Stack)`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3, 4)};
`;

const Prefix = styled(Typography)`
  color: ${({ theme }) => theme.palette.disabled.main};
  text-transform: uppercase;
`;

const Content = styled(Box)`
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const FormWithSteps = () => {
  const dispatch = useDispatch();
  const isSaving = useSelector(selectIsSaving);
  const errorCode = useSelector(selectErrorCode);
  const isFetching = useSelector(selectIsFetching);
  const wasModified = useSelector(selectWasModified);
  const errorToastMessage = useSelector(selectErrorToastMessage);
  const isSubmittedOrCompleted = useSelector(selectIsSubmittedOrCompleted);
  const [searchParams, setSearchParams] = useSearchParams();

  const step = Number(searchParams.get('step')) || 0; // Default to 0.
  const currentStep = stepList?.[step];
  const hasBack = step > 0;
  const hasContinue = step < stepList.length - 1;
  const hasMarkComplete = step === stepList.length - 1;
  const loading = isSaving || isFetching;
  const buttonDisabled = loading || isSubmittedOrCompleted;

  const setStep = (step) =>
    setSearchParams((params) => {
      params.set('step', step);
      return params;
    });

  const handleStepChange = (callback) => {
    const { payload } = dispatch(WelcomePackage.validate());
    if (!!payload.errorCode) {
      return;
    }
    if (!wasModified) {
      return callback();
    }

    dispatch(WelcomePackage.save()).then(callback);
  };

  const handleClickGoBack = () => handleStepChange(() => setStep(step - 1));

  const handleClickContinue = () => handleStepChange(() => setStep(step + 1));

  const handleClickMarkComplete = () => {
    dispatch(WelcomePackage.markComplete());
    dispatch(WelcomePackage.save());
  };

  const handleClickFixButton = () => {
    let nextStep = -1;
    switch (errorCode) {
      case WELCOME_PACKAGE_ERRORS.TOO_MANY_USERS:
        nextStep = stepList.findIndex((s) => s.id === STEP_IDS.USERS);
        break;
      case WELCOME_PACKAGE_VALIDATION_ERRORS.INVALID_WAR_ROOMS:
        nextStep = stepList.findIndex((s) => s.id === STEP_IDS.WAR_ROOMS);
        break;
      case WELCOME_PACKAGE_ERRORS.ERROR_DURING_POPULATION:
        nextStep = stepList.findIndex((s) => s.id === STEP_IDS.CONFIRMATION);
        break;
      default:
        nextStep = -1;
    }

    dispatch(WelcomePackage.clearError());
    if (nextStep >= 0 && nextStep !== step) {
      setStep(nextStep);
    }
  };

  if (!currentStep) {
    return <PackageNotFound />;
  }

  return (
    <>
      <Sidebar>
        <Stepper activeStep={step} sx={{ margin: 'auto' }} />
      </Sidebar>
      <StepContainer>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Prefix>{currentStep.prefix}</Prefix>
          <Typography component="h2" variant="h3" sx={{ mt: 1, mb: 2 }}>
            {currentStep.title}
          </Typography>
          <Content>{currentStep.content}</Content>
        </Box>
        <Stack
          direction="row"
          justifyContent={
            !hasBack
              ? 'flex-end'
              : !hasContinue && !hasMarkComplete
                ? 'flex-start'
                : 'space-between'
          }
          sx={{ flexShrink: 0, pt: 1 }}
        >
          {hasBack && (
            <Button
              variant="contained"
              disabled={buttonDisabled}
              onClick={handleClickGoBack}
            >
              Go Back
            </Button>
          )}
          {hasContinue && (
            <Button
              color="secondary"
              variant="contained"
              disabled={buttonDisabled}
              onClick={handleClickContinue}
            >
              {step === 0 ? 'Get Started' : 'Continue'}
            </Button>
          )}
          {hasMarkComplete && (
            <Button
              color="success"
              variant="contained"
              disabled={buttonDisabled}
              onClick={handleClickMarkComplete}
            >
              {isSaving ? (
                <ClipLoader size={16} color="white" />
              ) : isSubmittedOrCompleted ? (
                'Marked Complete!'
              ) : (
                'Mark Complete'
              )}
            </Button>
          )}
        </Stack>
      </StepContainer>
      <ErrorToast
        open={!!errorToastMessage}
        message={errorToastMessage}
        onClickButton={handleClickFixButton}
      />
    </>
  );
};

export default FormWithSteps;
