import jwtDecode from 'jwt-decode';

export const getTokenDecoded = (token) => jwtDecode(token, { header: true });

export const getTokenSub = (token) => {
  if (!token) {
    return '';
  }

  try {
    return jwtDecode(token)?.sub || '';
  } catch (e) {
    console.error(e);
    return '';
  }
};

const getTokenExp = (token) => {
  if (!token) {
    return 0;
  }

  try {
    return jwtDecode(token)?.exp || 0;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const hasTokenExpired = (token) => {
  try {
    const expiration = getTokenExp(token);
    if (!expiration) {
      return true;
    }
    return expiration * 1000 <= Date.now();
  } catch (e) {
    console.error(e);
    return true;
  }
};

export const calculateTimeUntilExpiry = (token) => {
  const expiration = getTokenExp(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  const timeUntilExpiry = (expiration - currentTime) * 1000; // Convert back to milliseconds
  return Math.max(timeUntilExpiry, 0); // Ensure non-negative delay
};

export const isExpired = (timestamp) => {
  return Date.now() >= timestamp * 1000;
};

export const calculateRefreshTimeFromExpiry = (authTokenExpiry) => {
  const currentTime = Date.now() / 1000; // Convert to seconds
  const timeUntilExpiry = (authTokenExpiry - currentTime) * 1000; // Convert back to milliseconds
  const refreshMargin = 60 * 1000; // Refresh 1 minute before expiry

  return Math.max(timeUntilExpiry - refreshMargin, 0); // Ensure non-negative delay
};
