import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import {
  isListTypeConfig,
  VISIBILITY_LABELS,
  isStringTypeConfig,
  MODIFY_ACCESS_LABELS,
  getConfigDisplayValue,
} from '../../../configs/management';

import ConfigControl from './ConfigControl';
import LockIcon from '../../icons/Lock';
import UndoIcon from '../../icons/Undo';
import CloseIcon from '../../icons/Close';
import PencilIcon from '../../icons/Pencil';
import CheckmarkIcon from '../../icons/Checkmark';

const Chip = styled(MuiChip)`
  height: 36px;
  font-size: 1rem;
  border-radius: 36px;
  .MuiChip-label {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const SmallChip = styled(MuiChip)`
  height: 18px;
  font-size: 11px;
  border-radius: 18px;
  .MuiChip-label {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const ConfigEditor = ({
  type,
  databaseKey,
  description,
  visibility,
  modifyAccess,
  currentValue,
  defaultValue,
  globalOverriddenValue,
  isGlobalOverridden,
  isEnterpriseCustom,
  onSave,
}) => {
  const [value, setValue] = useState(currentValue);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const visibilityLabel = VISIBILITY_LABELS[visibility];
  const modifyAccessLabel = MODIFY_ACCESS_LABELS[modifyAccess];
  const isEditable = modifyAccess !== 'DEVELOPMENT_TEAM_ONLY';
  const cannotRevert = !isEnterpriseCustom && !isGlobalOverridden;
  const shouldAlignTop =
    (isListTypeConfig(type) && !!currentValue?.length) ||
    (isStringTypeConfig(type) && currentValue?.includes('\n'));
  const valueSource = isEnterpriseCustom
    ? { label: 'Enterprise', color: 'secondary' }
    : isGlobalOverridden
      ? { label: 'Global', color: 'primary' }
      : { label: 'Default', color: 'disabled' };

  const handleChange = (value) => setValue(value);

  const handleSave = () => {
    setIsSaving(true);
    onSave(databaseKey, type, value)
      .then(() => setIsEditing(false))
      .finally(() => setIsSaving(false));
  };

  const handleRevert = () => {
    setIsSaving(true);
    const newValue = isEnterpriseCustom
      ? globalOverriddenValue
      : isGlobalOverridden
        ? defaultValue
        : currentValue;
    onSave(databaseKey, type, newValue)
      .then(() => setIsEditing(false))
      .finally(() => setIsSaving(false));
  };

  const handleCancel = () => {
    setValue(currentValue);
    setIsEditing(false);
  };

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  return (
    <Stack
      direction="row"
      alignItems={shouldAlignTop ? 'flex-start' : 'center'}
      gap={3}
    >
      <Stack sx={{ flex: 5 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            component="h3"
            variant="body1"
            sx={{ fontSize: databaseKey.length > 30 ? '0.9375rem' : '1rem' }}
          >
            {databaseKey}
          </Typography>
          <SmallChip color="primary" label={visibilityLabel} />
          <SmallChip
            color="disabled"
            icon={
              <LockIcon
                sx={{
                  width: 10,
                  height: 10,
                  fontSize: 12,
                }}
              />
            }
            label={modifyAccessLabel}
          />
        </Stack>
        <Typography variant="caption" sx={{ color: '#888' }}>
          {description}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems={shouldAlignTop ? 'flex-start' : 'center'}
        gap={1.5}
        sx={{ flex: 4 }}
      >
        {isEditing ? (
          <>
            <ConfigControl
              autoFocus
              type={type}
              value={value}
              disabled={isSaving}
              onChange={handleChange}
              sx={{ flexGrow: 1, justifySelf: 'flex-start' }}
            />
            <Box sx={{ flexShrink: 0 }}>
              <Tooltip title="Save" disableInteractive>
                <span>
                  <IconButton
                    size="small"
                    color="success"
                    disabled={isSaving || value === null}
                    onClick={handleSave}
                  >
                    <CheckmarkIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={cannotRevert ? 'Unavailable' : 'Revert'}
                disableInteractive
              >
                <span>
                  <IconButton
                    size="small"
                    color="warning"
                    disabled={isSaving || cannotRevert}
                    onClick={handleRevert}
                  >
                    <UndoIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Cancel" disableInteractive>
                <span>
                  <IconButton
                    size="small"
                    color="error"
                    disabled={isSaving}
                    onClick={handleCancel}
                  >
                    <CloseIcon sx={{ width: '1rem', height: '1rem' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Typography
              sx={{
                overflowWrap: 'anywhere',
                color: isEditable ? 'inherit' : '#888',
              }}
            >
              {value === null
                ? 'No value.'
                : getConfigDisplayValue(type, value)}
            </Typography>
            {isEditable && (
              <Tooltip title="Edit" disableInteractive>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                >
                  <PencilIcon sx={{ width: '1rem', height: '1rem' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Chip color={valueSource.color} label={valueSource.label} />
      </Stack>
    </Stack>
  );
};

export default ConfigEditor;
