import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Logo from '../assets/images/logo.svg';
import Brandmark from '../assets/images/brandmark.svg';

const Page = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  overflow: auto;
  min-height: 100vh;
`;

const Content = styled(Paper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  max-width: 100%;
  width: 100%;

  @media only screen and (min-width: 720px) {
    width: 75%;

    p.MuiTypography-root {
      font-size: 1rem;
    }
  }
  @media only screen and (min-width: 960px) {
    width: 44rem;
  }
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const PageLogo = styled.img`
  height: auto;
  max-width: 100%;
`;

const UnauthenticatedLayout = ({
  title,
  buttons = [],
  imageComponent,
  useBrandmark = false,
  pageProps = {},
  contentProps = {},
  buttonListProps = {},
  children,
}) => (
  <Page {...pageProps}>
    <TitleContainer>
      {!!imageComponent ? (
        imageComponent
      ) : (
        <PageLogo
          alt="ShadowHQ"
          src={useBrandmark ? Brandmark : Logo}
          sx={{ width: useBrandmark ? '75px' : '300px' }}
        />
      )}
      {!!title && (
        <Typography align="center" variant="h3" component="h1">
          {title}
        </Typography>
      )}
    </TitleContainer>
    <Content {...contentProps} elevation={2}>
      {children}
      {!!buttons.length && (
        <ButtonContainer {...buttonListProps}>
          {buttons.map(({ title, children, ...props }) => (
            <Button {...props} key={title} size={props?.size || 'large'}>
              {!!children ? children : title}
            </Button>
          ))}
        </ButtonContainer>
      )}
    </Content>
  </Page>
);

export default UnauthenticatedLayout;
