import { createSelector } from 'reselect';

import { groupCompare } from '../../utils/sortUtils';

export const selectGroupState = (state) => state.groups;

export const selectGroupList = createSelector(selectGroupState, (groupState) =>
  groupState.all.sort(groupCompare)
);

export const selectMemberGroupList = createSelector(
  selectGroupState,
  (groupState) => groupState.memberOfList.sort(groupCompare)
);

export const selectAccessibleGroupList = createSelector(
  selectGroupState,
  (groupState) => groupState.accessibleList.sort(groupCompare)
);

export const selectAutocompleteGroupList = createSelector(
  selectGroupList,
  selectAccessibleGroupList,
  (all, accessibleList) => {
    const accessibleGroupIds = accessibleList.map((g) => g.id);
    return all.map((g) => ({
      ...g,
      canAccess: accessibleGroupIds.includes(g.id),
    }));
  }
);
