import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef } from 'react';

import Groups from '../store/group/groupActions';
import { selectAutocompleteGroupList } from '../store/group/groupSelectors';

import GroupIcon from '../assets/icons/group.svg?react';

const Chip = styled(MuiChip)`
  height: 1.75rem;
`;

const Avatar = styled(MuiAvatar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const GroupAutocomplete = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      autoFocus,
      helperText,
      error = false,
      multiple = true,
      textFieldProps = {
        size: 'small',
        label: 'Groups',
        InputProps: {},
      },
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const groupList = useSelector(selectAutocompleteGroupList);

    const getOptionLabel = (option) => option.name;

    // Initialization effect.
    useEffect(() => {
      setFetching(true);
      Promise.all([
        dispatch(Groups.fetchAll()),
        dispatch(Groups.fetchAccessible()),
      ]).finally(() => setFetching(false));
    }, [dispatch]);

    return (
      <Autocomplete
        {...props}
        ref={ref}
        autoHighlight
        value={value}
        options={groupList}
        multiple={multiple}
        autoFocus={autoFocus}
        disabled={disabled || fetching}
        getOptionLabel={getOptionLabel}
        onChange={(e, value) => onChange(value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              label={getOptionLabel(option)}
              avatar={
                <Avatar>
                  <SvgIcon
                    color="white"
                    fontSize="small"
                    component={GroupIcon}
                  />
                </Avatar>
              }
              disabled={!option.canAccess}
            />
          ))
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          const notFoundInValue = !value.some((v) => v.id === option.id);
          const canAccess = Boolean(option.canAccess);
          const showOption = multiple ? notFoundInValue && canAccess : true;

          return (
            showOption && (
              <Stack
                {...props}
                gap={1}
                component="li"
                key={option.id}
                direction="row"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                >
                  <SvgIcon component={GroupIcon} fontSize="small" />
                </Avatar>
                {getOptionLabel(option)}
              </Stack>
            )
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            error={error}
            autoFocus={autoFocus}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              ...textFieldProps?.InputProps,
            }}
            placeholder="Begin typing and press Enter to add..."
          />
        )}
      />
    );
  }
);

GroupAutocomplete.displayName = 'GroupAutocomplete';

export default GroupAutocomplete;
