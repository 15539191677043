import { createSelector } from 'reselect';

const selectNotificationState = (state) => state.notifications;

export const selectNotifications = createSelector(
  selectNotificationState,
  (notificationState) => notificationState.notifications
);

export const selectFetchingNotifications = createSelector(
  selectNotificationState,
  (notificationState) => notificationState.fetchingNotifications
);
