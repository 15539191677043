import { useState, useMemo, useCallback } from 'react';

import BasicModal from '../../components/BasicModal';

const useAlert = () => {
  const [open, setOpen] = useState(false);
  const [{ title, content, buttonText, buttonColor }, setProps] = useState({});

  const element = useMemo(() => {
    const handleClose = () => setOpen(false);
    return (
      <BasicModal
        open={open}
        title={title}
        onClose={handleClose}
        buttonText={buttonText}
        buttonColor={buttonColor}
        onClickButton={handleClose}
        showCancelButton={false}
        sx={{ maxWidth: '28rem' }}
      >
        {content}
      </BasicModal>
    );
  }, [open, title, content, buttonText, buttonColor]);

  const trigger = useCallback(
    ({ title, content, buttonText = 'Ok', buttonColor = 'primary' }) => {
      setProps({
        title,
        content,
        buttonText,
        buttonColor,
      });
      setOpen(true);
    },
    [setProps, setOpen]
  );
  return [element, trigger];
};

export default useAlert;
