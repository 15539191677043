import SvgIcon from '@mui/material/SvgIcon';

const Pencil = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#pencil)">
      <path
        d="M19.28.22a.75.75 0 0 0-1.06 0l-2.47 2.47 5.56 5.56 2.47-2.469a.749.749 0 0 0 0-1.062l-4.5-4.5Zm.97 9.09-5.56-5.56-9.75 9.75h.31a.75.75 0 0 1 .75.75V15h.75a.75.75 0 0 1 .75.75v.75h.75a.75.75 0 0 1 .75.75V18h.75a.75.75 0 0 1 .75.75v.31l9.75-9.75ZM9.048 20.514A.75.75 0 0 1 9 20.25v-.75h-.75a.75.75 0 0 1-.75-.75V18h-.75a.75.75 0 0 1-.75-.75v-.75h-.75a.75.75 0 0 1-.75-.75V15h-.75a.747.747 0 0 1-.263-.048l-.268.267a.75.75 0 0 0-.165.252l-3 7.5a.75.75 0 0 0 .975.975l7.5-3a.75.75 0 0 0 .252-.165l.267-.268Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="pencil">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Pencil;
