import { AUTH_TYPES } from '../auth/authReducer';
import { CHAT_TYPES } from '../chat/chatReducer';

const callStatus = {
  CALL_STARTING: 'STARTING',
  CALL_CONNECTED: 'CONNECTED',
  CALL_DISCONNECTED: 'DISCONNECTED',
};

const INIT_JITSI_STATE = {
  status: callStatus.CALL_DISCONNECTED,
  cid: '',
  onJitsiCall: false,
  showJitsiCall: false,
  videoMuted: false,
  jitsiParticipants: [],
  fetchingJwt: false,
  fetchingRoom: false,
  jwt: null,
  messageId: null,
  callDetails: {},
  callTitle: '',
  activeCalls: [],
  incomingCall: {},
  dialInOptions: [],
};

export const JITSI_TYPES = {
  FETCH_JWT: '@@jitsiCalls/FETCH_JWT',
  FETCH_ROOM_DETAILS: '@@jitsiCalls/FETCH_ROOM_DETAILS',
  JOIN_CALL: '@@jitsiCalls/JOIN_CALL',
  TOGGLE_SHOW_CALL: '@@jitsiCalls/TOGGLE_SHOW_CALL',
  LEAVE_CALL: '@@jitsiCalls/LEAVE_JITSI_CALL',
  UPDATE_PARTICIPANTS: '@@jitsiCalls/UPDATE_JISTI_PARTICIPANTS',
  SET_MESSAGE_ID: '@@jitsiCalls/SET_MESSAGE_ID',
  UPDATE_ACTIVE_CALLS: '@@jitsiCalls/UPDATE_ACTIVE_CALLS',
  START_CALL: '@@jitsiCalls/START_CALL',
  SET_INCOMING_CALL: '@@jitsiCalls/SET_INCOMING_CALL',
  SEND_PUSH: '@@jitsiCalls/SEND_PUSH',
  UPDATE_INCOMING_CALL: '@@jitsiCalls/UPDATE_INCOMING_CALL',
  HANG_UP: '@@jitsiCalls/HANG_UP',
  FETCH_DIAL_IN_OPTIONS: '@@jitsiCalls/FETCH_DIAL_IN_OPTIONS',
};

export default function jitsi(state = INIT_JITSI_STATE, action) {
  switch (action.type) {
    case `${JITSI_TYPES.FETCH_JWT}_PENDING`:
      return {
        ...state,
        fetchingJwt: true,
        status: callStatus.CALL_STARTING,
      };
    case `${JITSI_TYPES.FETCH_JWT}_REJECTED`:
      return {
        ...state,
        fetchingJwt: false,
      };
    case `${JITSI_TYPES.FETCH_JWT}_FULFILLED`:
      return {
        ...state,
        fetchingJwt: false,
        jwt: action.payload.token,
        cid: action.payload.chatId,
        callTitle: action.payload.callTitle,
      };
    case `${JITSI_TYPES.FETCH_DIAL_IN_OPTIONS}_FULFILLED`:
      return {
        ...state,
        dialInOptions: action.payload.data,
      };
    case `${JITSI_TYPES.FETCH_ROOM_DETAILS}_PENDING`:
      return {
        ...state,
        fetchingRoom: true,
      };
    case `${JITSI_TYPES.FETCH_ROOM_DETAILS}_REJECTED`:
      return {
        ...state,
        fetchingRoom: false,
      };
    case `${JITSI_TYPES.FETCH_ROOM_DETAILS}_FULFILLED`:
      return {
        ...state,
        fetchingRoom: false,
      };
    case JITSI_TYPES.JOIN_CALL: {
      const { videoMuted } = action.payload;
      const { incomingCall } = state;

      let nextIncomingState = { ...incomingCall };

      return {
        ...state,
        videoMuted,
        status: callStatus.CALL_CONNECTED,
        onJitsiCall: true,
        showJitsiCall: true,
        incomingCall: nextIncomingState,
      };
    }
    case JITSI_TYPES.TOGGLE_SHOW_CALL:
      return {
        ...state,
        showJitsiCall: !state.showJitsiCall,
      };
    case JITSI_TYPES.LEAVE_CALL:
      return {
        ...state,
        status: callStatus.CALL_DISCONNECTED,
        jwt: null,
        onJitsiCall: false,
        showJitsiCall: false,
        videoMuted: false,
        cid: '',
        jitsiParticipants: [],
        incomingCall: {},
      };
    case JITSI_TYPES.UPDATE_PARTICIPANTS:
      return {
        ...state,
        jitsiParticipants: action.payload.participants,
      };
    case JITSI_TYPES.UPDATE_ACTIVE_CALLS:
      return {
        ...state,
        activeCalls: action.payload,
      };
    case JITSI_TYPES.SET_MESSAGE_ID:
      return {
        ...state,
        messageId: action.payload,
      };
    case JITSI_TYPES.START_CALL:
      return {
        ...state,
      };
    case JITSI_TYPES.SET_INCOMING_CALL:
      return {
        ...state,
        incomingCall: action.payload,
      };
    case JITSI_TYPES.HANG_UP:
      return {
        ...state,
        incomingCall: {},
      };
    case `${CHAT_TYPES.FETCH_CHATS}_FULFILLED`: {
      const callDetails = action.payload.reduce((acc, chat) => {
        if (chat.data.callDetails) {
          return {
            ...acc,
            [chat.cid]: chat.data.callDetails,
          };
        }

        return acc;
      }, {});
      const activeCalls = Object.keys(callDetails).reduce(
        (acc, key) => (callDetails[key].isActive ? [...acc, key] : acc),
        []
      );

      return {
        ...state,
        callDetails,
        activeCalls,
      };
    }
    case CHAT_TYPES.UPDATE_CHAT:
      return {
        ...state,
        callDetails: {
          ...state.callDetails,
          [action.payload.cid]: action.payload.data.callDetails,
        },
      };
    case AUTH_TYPES.CLEAR:
      return INIT_JITSI_STATE;
    default:
      return state;
  }
}
