import { useCallback, useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import Chats from '../../store/chat/chatActions';
import JitsiCalls from '../../store/jitsi/jitsiActions';
import {
  selectWarRooms,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import { CHAT_TYPES } from '../../lib/string';
import Users from '../../store/user/userActions';
import { PulseLogoLoader } from '../loader';
import SuspenseLoading from '../SuspenseLoading';
import SectionTitle from './SectionTitle';
import GroupChatItem from './GroupChatItem';
import ConfirmModal from '../ConfirmModal';

// Icons
import Globe from '../../assets/icons/globe.svg?react';

const fallback = (
  <SuspenseLoading
    style={{ height: '150px' }}
    component={<PulseLogoLoader />}
  />
);

export default function WarRooms() {
  const dispatch = useDispatch();
  const warRoomChats = useSelector(selectWarRooms) || [];
  const { fetchingChats, fetchedChats } = useSelector((state) => state.chats);
  const { onJitsiCall } = useSelector((state) => state.jitsi);
  const [joinOpen, setJoinOpen] = useState(false);
  const [chatId, setChatId] = useState('');
  const [openCollapseList, setOpenCollapseList] = useState(true);
  const newChatFormType = useSelector(selectNewChatForm);

  // Debounce the chat click in order to make sure we do not call
  // the initial fetch too many times and end up with the wrong
  // messages in the chat.
  const onClickChat = debounce((chat) => {
    if (
      chat.data.callDetails &&
      chat.data.callDetails.isActive &&
      !onJitsiCall
    ) {
      setJoinOpen(true);
      setChatId(chat.cid);
    }

    dispatch(Chats.setCurrentChat(chat.type, chat.id));
    dispatch(Chats.fetchAttachments(chat.cid));
  }, 100);

  const joinCall = useCallback((id) => {
    dispatch(JitsiCalls.getJWT(id)).then(() => {
      setJoinOpen(false);
      dispatch(JitsiCalls.joinCall(true));
    });
  }, []);

  const onCollapseClick = () => {
    openCollapseList ? setOpenCollapseList(false) : setOpenCollapseList(true);
  };

  const createNewWarRoom = () => {
    if (!!newChatFormType) {
      dispatch(Chats.toggleNewChatForm(''));
    } else {
      dispatch(Users.fetchUsers());
      dispatch(Chats.toggleNewChatForm(CHAT_TYPES.WAR_ROOM));
    }
  };

  return (
    <div>
      <SectionTitle
        title="War Rooms"
        openCollapseList={openCollapseList}
        onClick={createNewWarRoom}
        onCollapseClick={onCollapseClick}
        open={newChatFormType === CHAT_TYPES.WAR_ROOM}
      />
      {fetchingChats && !fetchedChats ? (
        fallback
      ) : (
        <Collapse in={openCollapseList}>
          <List sx={{ py: 0 }}>
            {warRoomChats.map((chat) => (
              <GroupChatItem
                key={chat.id}
                chat={chat}
                onClickChat={() => onClickChat(chat)}
                icon={Globe}
              />
            ))}
          </List>
        </Collapse>
      )}
      <ConfirmModal
        open={joinOpen}
        onClose={() => setJoinOpen(false)}
        onConfirm={() => joinCall(chatId)}
        confirmText="Join"
      >
        <p>
          There is an on going call in this room. Would you like to join it now?
        </p>
      </ConfirmModal>
    </div>
  );
}
