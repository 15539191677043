import { useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { PulseLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import { selectChatId, selectHasCall } from '../../store/jitsi/jitsiSelectors';
import {
  selectTypingEvents,
  selectCurrentChat,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import { selectHasDraftByChannelId } from '../../store/chat/draftSelectors';
import { getTimeDiffString } from '../../lib/date';
import { Badge, ChatItem } from './ChatContent';
import Chats from '../../store/chat/chatActions';
import ConfirmModal from '../ConfirmModal';

// Icons
import PhoneCall from '../../assets/icons/phone-call-outline.svg?react';
import Phone from '../../assets/icons/phone-outline.svg?react';
import Draft from '../../assets/icons/draft.svg?react';

const DisplayNameWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 1em;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  small {
    color: #aaa;
  }
`;

const DisplayName = styled.span`
  display: block;
  width: ${({ hover }) => hover && '90%'};
  overflow: hidden;
  color: ${({ active, activeColor }) => active && activeColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.25;
  font-weight: 500;
  font-size: 15px;
`;

const GroupIconWrapper = styled.div`
  height: 35px;
  width: 35px;
`;

const GroupIcon = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
`;

export default function GroupChatItem({ chat, icon, onClickChat }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentChat = useSelector(selectCurrentChat);
  const typingEvents = useSelector(selectTypingEvents);
  const jitsiChatId = useSelector(selectChatId);
  const hasCallOngoing = useSelector((state) =>
    selectHasCall(state, { chatId: chat.cid })
  );
  const hasDraft = useSelector((state) =>
    selectHasDraftByChannelId(state, chat.cid)
  );

  const isCurrentCall = jitsiChatId === chat.cid;
  const active = chat.id === currentChat.id;
  const { unreadCount, last_message_at: lastMessageAt } = chat.state;
  const newChatForm = useSelector(selectNewChatForm);
  const isNewChatFormOpen = !!newChatForm;
  const [discardOpen, setDiscardOpen] = useState(false);

  let currentIcon = icon;
  let backgroundColor = theme.palette.primary.main;
  if (isCurrentCall) {
    currentIcon = PhoneCall;
    backgroundColor = theme.palette.success.main;
  }

  if (!isCurrentCall && hasCallOngoing) {
    currentIcon = Phone;
  }

  const handleChatContainerClick = () => {
    if (!discardOpen && isNewChatFormOpen) {
      setDiscardOpen(true);
    } else {
      setDiscardOpen(false);
    }
  };

  const discardCreateNewChat = () => dispatch(Chats.toggleNewChatForm(''));

  return (
    <Tooltip
      placement="right"
      title={chat.data.name.length > 30 ? chat.data.name : ''}
    >
      <ChatItem
        onClick={() => {
          onClickChat();
          handleChatContainerClick();
        }}
        button
        theme={theme}
        active={active.toString()}
      >
        <GroupIconWrapper>
          <Badge
            color="grey"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={hasDraft ? <SvgIcon component={Draft} /> : 0}
          >
            <Badge
              color="error"
              badgeContent={unreadCount < 100 ? unreadCount : '99+'}
            >
              <GroupIcon>
                {typingEvents[chat.cid] && typingEvents[chat.cid].length > 0 ? (
                  <PulseLoader size={5} color="#2b4c82" />
                ) : (
                  <SvgIcon
                    component={currentIcon}
                    style={{
                      backgroundColor,
                      fontSize: '35px',
                      padding: '5px',
                      borderRadius: '50%',
                      color: theme.palette.white.main,
                    }}
                    fill={theme.palette.white.main}
                  />
                )}
              </GroupIcon>
            </Badge>
          </Badge>
        </GroupIconWrapper>
        <DisplayNameWrapper>
          <DisplayName active={active} activeColor={theme.palette.primary}>
            {chat.data.name}
          </DisplayName>
          {lastMessageAt && (
            <small>{`last message ${getTimeDiffString(
              lastMessageAt.getTime()
            )}`}</small>
          )}
        </DisplayNameWrapper>
        <ConfirmModal
          open={discardOpen}
          confirmText="Discard"
          onClose={() => setDiscardOpen(false)}
          onConfirm={discardCreateNewChat}
          cancel={true}
        >
          <p>{`Are you sure you want to discard this new chat?`}</p>
          <p>You will need to re-enter the details if you leave this form.</p>
        </ConfirmModal>
      </ChatItem>
    </Tooltip>
  );
}
