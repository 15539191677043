import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { useState, useEffect, useCallback } from 'react';

import Storage from '../../store/storage/storageActions';

import BasicModal from '../BasicModal';

const RenameFileModal = ({ open, item, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(item?.name || '');

  const handleClose = useCallback(() => {
    setName('');
    onClose();
  }, [onClose]);

  const handleRename = useCallback(() => {
    setLoading(true);
    return dispatch(Storage.renameFile(item, name.trim()))
      .then(handleClose)
      .finally(() => setLoading(false));
  }, [item, name, dispatch, handleClose]);

  // Initialization effect.
  useEffect(() => {
    if (open) {
      setName(item?.name || '');
    }
  }, [open, item]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Rename File"
      buttonText="Rename"
      onClickButton={handleRename}
      buttonDisabled={name.trim().length < 3}
    >
      <TextField
        autoFocus
        size="small"
        label="Name"
        color="primary"
        value={name}
        disabled={loading}
        onChange={(e) => setName(e.target.value)}
      />
    </BasicModal>
  );
};

export default RenameFileModal;
