import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MAX_FILE_FOLDERS,
  SUGGESTED_FILE_FOLDERS,
} from '../../../configs/welcomePackage';
import WelcomePackage from '../../../store/welcome/welcomeActions';
import { selectFileFolders } from '../../../store/welcome/welcomeSelectors';

import Chip from './Chip';
import FolderChip from './FolderChip';

const FileFoldersStep = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const folders = useSelector(selectFileFolders);
  const [folderName, setFolderName] = useState('');
  const [examples, setExamples] = useState(
    SUGGESTED_FILE_FOLDERS.filter((e) => !folders.find((w) => w.label === e))
  );

  const handleChangeName = (e) => setFolderName(e.target.value);

  const handleToggleAdminOnly = (item) => (adminOnly) =>
    dispatch(WelcomePackage.editFileFolder({ ...item, adminOnly }));

  const handleAddFromExample = (item) => () => {
    if (!folders.find((f) => f.label === item)) {
      dispatch(
        WelcomePackage.addFileFolder({
          key: folders.length,
          label: item,
          adminOnly: false,
        })
      );
    }
    setExamples((examples) => examples.filter((e) => e !== item));
  };

  const handleAddAsCustom = (e) => {
    e.preventDefault();
    const folder = folderName.trim();
    if (!folders.find((f) => f.label === folder)) {
      dispatch(
        WelcomePackage.addFileFolder({
          key: folders.length,
          label: folder,
          adminOnly: false,
        })
      );
    }
    setFolderName('');
    setExamples((examples) => examples.filter((e) => e !== folder));
    inputRef.current.focus();
  };

  const handleDelete = (item) => () =>
    dispatch(WelcomePackage.removeFileFolder(item));

  return (
    <>
      <Typography paragraph>
        Securely store your emergency response manuals, press releases, vendor
        information, key customer contacts and all other types of documents for
        out-of-band access.
      </Typography>
      <Typography paragraph>
        Please provide the names of the folders for your organization. For
        restricted access, toggle the lock switch on the folder name.
      </Typography>
      <Stack gap={2} mt={2}>
        <Box>
          <Typography component="b">Quick Add: </Typography>
          {examples.map((value, index) => (
            <Chip
              key={index}
              label={value}
              variant="outlined"
              onClick={handleAddFromExample(value)}
              disabled={folders.length >= MAX_FILE_FOLDERS}
            />
          ))}
        </Box>
        <Stack
          component="form"
          direction="row"
          alignItems="center"
          onSubmit={handleAddAsCustom}
          gap={1}
        >
          <TextField
            autoFocus
            size="small"
            label="New Folder"
            autoComplete="off"
            value={folderName}
            inputRef={inputRef}
            onChange={handleChangeName}
            placeholder="Press Enter to add..."
            disabled={folders.length >= MAX_FILE_FOLDERS}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={handleAddAsCustom}
            disabled={!folderName || folders.length >= MAX_FILE_FOLDERS}
            sx={{ alignSelf: 'center' }}
          >
            Add
          </Button>
        </Stack>
        <Box>
          <Typography component="b">Include: </Typography>
          {!!folders?.length ? (
            folders.map((f, index) => (
              <FolderChip
                key={index}
                label={f.label}
                checked={f.adminOnly}
                onDelete={handleDelete(f)}
                onToggle={handleToggleAdminOnly(f)}
              />
            ))
          ) : (
            <Typography
              component="i"
              sx={{ display: 'inline-block', mt: '5px' }}
            >
              No file folders added.
            </Typography>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default FileFoldersStep;
