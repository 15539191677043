import { MAX_LENGTH } from '../configs/validation';

export const PASSWORD_RULES = {
  MIN_LENGTH: 'MIN_LENGTH',
  MAX_LENGTH: 'MAX_LENGTH',
  LOWERCASE: 'LOWERCASE',
  UPPERCASE: 'UPPERCASE',
  SPECIAL: 'SPECIAL',
  NUMBER: 'NUMBER',
  MATCHES: 'MATCHES',
};

const PASSWORD_ERRORS = {
  [PASSWORD_RULES.MIN_LENGTH]: 'Password must be at least 8 characters long.',
  [PASSWORD_RULES.MAX_LENGTH]: `Password cannot be more than ${MAX_LENGTH.value} characters long.`,
  [PASSWORD_RULES.LOWERCASE]:
    'Password must contain at least 1 lowercase letter.',
  [PASSWORD_RULES.UPPERCASE]:
    'Password must contain at least 1 uppercase letter.',
  [PASSWORD_RULES.SPECIAL]:
    'Password must contain at least 1 special character.',
  [PASSWORD_RULES.NUMBER]: 'Password must contain at least 1 number.',
  [PASSWORD_RULES.MATCHES]: 'Passwords must match.',
};

export const PASSWORD_VALIDATION_MESSAGES = [
  {
    rule: PASSWORD_RULES.LOWERCASE,
    text: 'Has at least one lowercase letter',
  },
  {
    rule: PASSWORD_RULES.UPPERCASE,
    text: 'Has at least one uppercase letter',
  },
  {
    rule: PASSWORD_RULES.NUMBER,
    text: 'Has at least one number',
  },
  {
    rule: PASSWORD_RULES.SPECIAL,
    text: 'Has at least one special character: @$!%*#?&.,+{}-',
  },
  {
    rule: PASSWORD_RULES.MIN_LENGTH,
    text: 'Has at least 8 characters',
  },
  {
    rule: PASSWORD_RULES.MATCHES,
    text: 'New password and confirm password match',
  },
];

export const validatePassword = (newPassword, confirmPassword) => {
  const results = {
    [PASSWORD_RULES.MIN_LENGTH]: newPassword.length >= 8,
    [PASSWORD_RULES.MAX_LENGTH]: newPassword.length <= MAX_LENGTH.value,
    [PASSWORD_RULES.LOWERCASE]: /[a-z]/.test(newPassword),
    [PASSWORD_RULES.UPPERCASE]: /[A-Z]/.test(newPassword),
    [PASSWORD_RULES.SPECIAL]: /[@$!%*#?&.,+{}-]/.test(newPassword),
    [PASSWORD_RULES.NUMBER]: /[0-9]/.test(newPassword),
    [PASSWORD_RULES.MATCHES]: !!newPassword && newPassword === confirmPassword,
  };

  const errors = {};
  for (const key in results) {
    if (!results[key]) {
      errors[key] = PASSWORD_ERRORS[key];
    }
  }
  return errors;
};

export const trimPhoneNumber = (phoneNumber) =>
  !phoneNumber ? '' : phoneNumber.replace(/\+1/g, '').replace(/\D/g, '');

export const formatPhoneNumber = (rawPhoneNumber) => {
  if (!rawPhoneNumber) {
    return '';
  }

  const phoneNumberMatches = trimPhoneNumber(rawPhoneNumber).match(
    /(\d{0,3})(\d{0,3})(\d{0,4})/
  );
  if (!phoneNumberMatches) {
    return '';
  }

  let formattedPhoneNumber = '';
  if (phoneNumberMatches[1]) {
    formattedPhoneNumber += '(' + phoneNumberMatches[1];
  }
  if (phoneNumberMatches[2]) {
    formattedPhoneNumber += ') ' + phoneNumberMatches[2];
  }
  if (phoneNumberMatches[3]) {
    formattedPhoneNumber += '-' + phoneNumberMatches[3];
  }
  return formattedPhoneNumber;
};

export const nameRegex = /^[a-zA-Z .]+$/;

export const validateName = (name) => nameRegex.test(name);

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmailAddress = (email) => emailRegex.test(email);
