import { HEARTBEAT_TYPES } from './heartbeatReducer';

const Heartbeat = {
  setIsActiveTab: (status) => ({
    type: HEARTBEAT_TYPES.SET_ACTIVE_TAB,
    payload: status,
  }),
  setIsReady: (status) => ({
    type: HEARTBEAT_TYPES.SET_IS_READY,
    payload: status,
  }),
};

export default Heartbeat;
