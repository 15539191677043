import { CHAT_TYPES } from './chatReducer';
import { AUTH_TYPES } from '../auth/authReducer';

const initState = {
  messages: [],
  fetchingMessages: false,
  fetchedMessages: false,
  queued: {},
  sending: false,
  lastMessage: {},
  read: {},
  isEditing: null,
  sendError: null,
};

export const MESSAGE_TYPES = {
  FETCH_MESSAGES: '@@messages/FETCH_MESSAGES',
  FETCH_INIT_MESSAGES: '@@messages/FETCH_INIT_MESSAGES',
  CLEAR_MESSAGES: '@@messages/CLEAR_MESSAGES',
  SEND_MESSAGE: '@@messages/SEND_MESSAGE',
  QUEUE_MESSAGE: '@@messages/QUEUE_MESSAGE',
  POP_MESSAGE: '@@messages/POP_MESSAGE',
  MESSAGE_ADDED: '@@messages/MESSAGE_ADDED',
  UPDATE_MESSAGE: '@@messages/UPDATE_MESSAGE',
  SET_UPDATED_MESSAGE: '@@messages/SET_UPDATED_MESSAGE',
  TOGGLE_EDITING: '@@messages/TOGGLE_EDITING',
  READ_MESSAGE: '@@messages/READ_MESSAGE',
  UPDATE_READ: '@@messages/UPDATE_READ',
};

export default function messages(state = initState, action) {
  switch (action.type) {
    case `${MESSAGE_TYPES.FETCH_MESSAGES}_PENDING`:
      return {
        ...state,
        fetchingMessages: true,
        fetchedMessages: false,
      };
    case `${MESSAGE_TYPES.FETCH_MESSAGES}_FULFILLED`:
      return {
        ...state,
        messages: [...action.payload.messages, ...state.messages],
        fetchingMessages: false,
        fetchedMessages: true,
      };
    case `${MESSAGE_TYPES.FETCH_INIT_MESSAGES}_PENDING`:
      return {
        ...state,
        fetchingMessages: true,
        fetchedMessages: false,
      };
    case `${MESSAGE_TYPES.FETCH_INIT_MESSAGES}_FULFILLED`: {
      let nextMessages = state.messages;

      if (action.payload) {
        nextMessages = [...action.payload.messages];
      }

      return {
        ...state,
        messages: nextMessages,
        fetchingMessages: false,
        fetchedMessages: true,
      };
    }
    case MESSAGE_TYPES.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case `${MESSAGE_TYPES.SEND_MESSAGE}_PENDING`:
      return {
        ...state,
        sending: true,
        sendError: null,
      };
    case `${MESSAGE_TYPES.SEND_MESSAGE}_FULFILLED`: {
      const { message } = action.payload;

      let sendError = null;
      if (message.type === 'error') {
        sendError = message.text;
      }

      return {
        ...state,
        sendError,
        sending: false,
      };
    }
    case `${MESSAGE_TYPES.SEND_MESSAGE}_REJECTED`:
      return {
        ...state,
        sending: false,
        sendError: action.payload.message,
      };
    case MESSAGE_TYPES.QUEUE_MESSAGE: {
      const { queued } = state;
      const { cid } = action.payload;
      const messageQueue = queued[cid] || [];

      return {
        ...state,
        sending: true,
        queued: {
          ...state.queued,
          [cid]: [...messageQueue, action.payload],
        },
      };
    }
    case MESSAGE_TYPES.POP_MESSAGE: {
      const { queued } = state;
      const { cid, message } = action.payload;

      const queuedChatMessages = queued[cid];
      const nextQueue = queuedChatMessages.filter((m) => m.cid !== message.cid);

      let next = {
        ...queued,
        [cid]: nextQueue,
      };

      if (nextQueue.length === 0) {
        next = Object.keys(queued)
          .filter((k) => k !== cid)
          .reduce((acc, key) => ({ ...acc, [key]: queued[key] }), {});
      }

      return {
        ...state,
        sending: true,
        queued: next,
      };
    }
    case MESSAGE_TYPES.SET_UPDATED_MESSAGE: {
      const idx = state.messages.findIndex(
        (m) => m.id === action.payload.message.id
      );

      if (idx === -1) {
        return state;
      }

      return {
        ...state,
        messages: [
          ...state.messages.slice(0, idx),
          action.payload.message,
          ...state.messages.slice(idx + 1),
        ],
      };
    }
    case MESSAGE_TYPES.MESSAGE_ADDED: {
      const nextMessage = action.payload;
      const idx = state.messages.findIndex((m) => m.id === nextMessage.id);

      let nextMessages = state.messages;
      if (idx === -1) {
        nextMessages = [...state.messages, nextMessage];
      }

      return {
        ...state,
        lastMessage: action.payload,
        messages: nextMessages,
      };
    }
    case MESSAGE_TYPES.UPDATE_READ:
      return {
        ...state,
        read: action.payload,
      };
    case MESSAGE_TYPES.TOGGLE_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    case CHAT_TYPES.NEW_CHAT:
      return {
        ...state,
        messages: [],
      };
    case CHAT_TYPES.SET_CURRENT_CHAT: {
      const nextRead = Object.keys(action.payload.state.read).reduce(
        (acc, key) => ({
          ...acc,
          [key]: new Date(action.payload.state.read[key].last_read),
        }),
        {}
      );

      return {
        ...state,
        messages: [...action.payload.state.messages],
        fetchingMessages: false,
        fetchedMessages: false,
        read: nextRead,
        isEditing: null,
      };
    }
    case AUTH_TYPES.CLEAR:
      return initState;
    default:
      return state;
  }
}
