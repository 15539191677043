import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import Plus from '../../assets/icons/plus.svg?react';
import Minus from '../../assets/icons/minus.svg?react';
import Down from '../../assets/icons/arrow-ios-downward.svg?react';
import Upward from '../../assets/icons/arrow-ios-upward.svg?react';
import { Stack } from '@mui/material';

const TitleWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  height: 56px;
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 0.95rem;
`;

const TitleSection = styled(Typography)`
  font-weight: 500;
  font-size: 0.75rem;
  text-align: right;
`;

const ExpandButton = styled.div`
  font-size: 0.73077rem;
`;

export default function SectionTitle({
  title,
  open,
  openCollapseList,
  isUnreadMessage,
  onClick,
  onCollapseClick,
}) {
  const theme = useTheme();

  return (
    <TitleWrapper color="primary" component="div">
      <Stack direction="row" alignItems="center">
        <IconButton onClick={onCollapseClick} size="large">
          <SvgIcon
            htmlColor={theme.palette.primary.main}
            style={{ fontSize: '1.3rem' }}
          >
            {openCollapseList || isUnreadMessage ? <Down /> : <Upward />}
          </SvgIcon>
        </IconButton>
        <Title>{title}</Title>
      </Stack>
      <Stack direction="row" alignItems="center">
        <TitleSection>New</TitleSection>
        <ExpandButton>
          <Tooltip
            arrow
            disableInteractive
            title={open ? 'Cancel' : 'Start new conversation'}
            placement="top"
          >
            <IconButton onClick={onClick} size="medium">
              <SvgIcon
                htmlColor={theme.palette.primary.main}
                style={{ fontSize: '1.3rem' }}
              >
                {open ? <Minus /> : <Plus />}
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </ExpandButton>
      </Stack>
    </TitleWrapper>
  );
}
