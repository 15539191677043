import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import useInterval from '../../../lib/hooks/useInterval';
import { managementApi } from '../../../configs/managementApi';
import { formatTimestamp, timeFromNow } from '../../../lib/date';
import { ACTIVITY_TYPES } from '../../../store/incident/incidentTypes';

import ManagementDataGrid from '../ManagementDataGrid';

const Container = styled(Box)`
  margin: 0 auto 1rem;
  background-color: #ffff;
  border-radius: 25px;
  padding: 20px 20px 0px 20px;
`;

const IncidentListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const IncidentListHeader = styled(Box)`
  height: 31px;
  border-bottom: 1px solid #e6e6e6;
`;

const IncidentListBody = styled(Box)`
  height: auto;
  display: flex;
  flex-direction: column;
`;

const IncidentList = ({ orgData, selectedRow, onSelectRow }) => {
  const { id } = useParams();
  const { maxUsers } = orgData;
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
  });

  const fetchIncidents = useCallback(() => {
    setLoading(true);
    managementApi
      .get(
        `/enterprise/${id}/incidents?page=${paginationModel.page}&size=${paginationModel.pageSize}&activityOnly=false`
      )
      .then(({ data }) => setIncidents(data))
      .catch((e) => console.error('Error fetching incident data:', e))
      .finally(() => setLoading(false));
  }, [id, paginationModel]);

  const handlePageChange = (newPage) => {
    const nextPage = Math.max(newPage, 1);
    setPaginationModel((prev) => ({ ...prev, page: nextPage }));
  };

  const handleRowClick = useCallback(
    (params) => {
      onSelectRow(params.row);
    },
    [onSelectRow]
  );

  // Fetch effect.
  useEffect(() => {
    if (!!id) {
      fetchIncidents();
    }
  }, [id, paginationModel.page, fetchIncidents]);

  // Page change effect.
  useEffect(() => {
    if (incidents.length > 0) {
      onSelectRow(incidents[0]);
    }
  }, [incidents, paginationModel.page, onSelectRow]);

  // Fetch incidents every minute.
  useInterval(fetchIncidents, id ? 60 * 1000 : null);

  return (
    <Container>
      <IncidentListContainer>
        <IncidentListHeader>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            Other Incidents
          </Typography>
        </IncidentListHeader>
        <IncidentListBody>
          <Box sx={{ width: '100%', height: 270, mt: 1 }}>
            {orgData.incidentsCount !== undefined && (
              <ManagementDataGrid
                rows={incidents}
                columns={[
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    valueGetter: ({ row }) =>
                      row.isActive !== true ? 'Resolved' : 'In Progress',
                  },
                  {
                    field: 'duration',
                    headerName: 'Duration',
                    flex: 1,
                    valueGetter: ({ row }) =>
                      timeFromNow(
                        row.resolveTimestamp !== null
                          ? dayjs(row.resolveTimestamp).diff(
                              row.createTimestamp
                            )
                          : dayjs().diff(row.createTimestamp)
                      ),
                  },
                  {
                    field: 'type',
                    headerName: 'Type',
                    flex: 1,
                    valueGetter: ({ row }) =>
                      row.isTabletop === true
                        ? 'Tabletop Exercise'
                        : 'Incident',
                  },
                  {
                    field: 'title',
                    headerName: 'Title',
                    flex: 2,
                  },
                  // {
                  //   field: 'createdAt',
                  //   headerName: 'Created At',
                  //   flex: 1.25,
                  //   valueGetter: ({ row }) =>
                  //     formatTimestamp(row.createTimestamp),
                  // },
                  {
                    field: 'resolvedAt',
                    headerName: 'Resolved At',
                    flex: 1.75,
                    valueGetter: ({ row }) =>
                      row.resolveTimestamp !== null
                        ? formatTimestamp(row.resolveTimestamp)
                        : 'N/A',
                  },
                  {
                    field: 'checkIns',
                    headerName: 'Check Ins',
                    flex: 1,
                    valueGetter: ({ row }) => {
                      const { checkIns, isTargeted, targetedUsers } = row;
                      const totalUsers = isTargeted
                        ? targetedUsers.length
                        : maxUsers;
                      return `${checkIns.length} / ${totalUsers}`;
                    },
                  },
                  {
                    field: 'updates',
                    headerName: 'Updates',
                    flex: 0.75,
                    valueGetter: ({ row }) =>
                      row.activities.filter(
                        (item) => item.type === ACTIVITY_TYPES.UPDATE
                      ).length,
                  },
                  {
                    field: 'tasks',
                    headerName: 'Tasks',
                    flex: 0.75,
                    valueGetter: ({ row }) =>
                      row.activities.filter(
                        (item) => item.type === ACTIVITY_TYPES.TASK
                      ).length,
                  },
                  {
                    field: 'attachments',
                    headerName: 'Attachments',
                    flex: 0.75,
                  },
                ]}
                pagination
                density="compact"
                loading={loading}
                disableColumnMenu
                paginationMode="server"
                disableSelectionOnClick
                rowsPerPageOptions={[3]}
                onRowClick={handleRowClick}
                pageSize={paginationModel.pageSize}
                rowClassName={(params) =>
                  params.isSelected ? 'selectedRow' : ''
                }
                selectionModel={selectedRow ? [selectedRow.id] : []}
                onPageChange={(params) => handlePageChange(params + 1)}
                rowCount={
                  orgData.incidentsCount > 0 ? orgData.incidentsCount : 0
                }
                localeText={{
                  noRowsLabel: 'No incident created yet.',
                }}
              />
            )}
          </Box>
        </IncidentListBody>
      </IncidentListContainer>
    </Container>
  );
};

export default IncidentList;
