import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

import CancelIcon from '../../assets/icons/close.svg?react';
import EditIcon from '../../assets/icons/edit-outline.svg?react';
import CheckmarkIcon from '../../assets/icons/checkmark-outline.svg?react';

const TextField = styled(MuiTextField)`
  & .MuiInputBase-root {
    border-radius: 6px;
  }
  & .MuiInputBase-input {
    padding: 2px 4px;
  }
`;

const QuickEditTextField = ({ initialValue, onSave, initialSx, sx }) => {
  const ref = useRef();
  const inputRef = useRef();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  // Editing effect.
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
    if (editing) {
      inputRef.current.select();
    }
  }, [initialValue, editing]);

  // Click outside effect.
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const iconButtonSx = {
    p: '2px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.125rem',
      minWidth: '1.125rem',
      minHeight: '1.125rem',
    },
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleSave();
    }
  };

  const handleSave = () => {
    const newValue = value.trim();

    onSave(newValue)
      .then(() => setEditing(false))
      .catch((e) => console.error(e));
  };

  return (
    <Box ref={ref} sx={{ display: 'inline-block', ...sx }}>
      <Stack direction="row" alignItems="center" gap="4px" mb="-2px">
        <TextField
          value={value}
          inputRef={inputRef}
          onKeyDown={handleKeyDown}
          onChange={(e) => setValue(e.target.value)}
          sx={{ display: editing ? 'block' : 'none' }}
        />
        <Typography
          component="span"
          sx={{ display: editing ? 'none' : 'block', ...initialSx }}
        >
          {initialValue}
        </Typography>
        <Tooltip
          arrow
          disableInteractive
          size="small"
          title={editing ? 'Save' : 'Edit'}
        >
          <IconButton
            size="small"
            color="primary"
            aria-label="Edit"
            onClick={() => (editing ? handleSave() : setEditing(true))}
            sx={iconButtonSx}
          >
            <SvgIcon
              size="small"
              component={editing ? CheckmarkIcon : EditIcon}
            />
          </IconButton>
        </Tooltip>
        {editing && (
          <Tooltip arrow disableInteractive size="small" title="Cancel">
            <IconButton
              size="small"
              color="primary"
              aria-label="Cancel"
              onClick={() => setEditing(false)}
              sx={iconButtonSx}
            >
              <SvgIcon size="small" component={CancelIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};

export default QuickEditTextField;
