import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import {
  selectAuthError,
  selectTwoFactorData,
} from '../../store/auth/authSelectors';

import Countdown from '../Countdown';
import TOTPConfiguration from './TOTPConfiguration';
import UnauthenticatedLayout from '../UnauthenticatedLayout';
import LockIcon from '../../assets/icons/lock-outline.svg?react';

const TOTPVerification = ({
  loading,
  onSubmit,
  onClickCancel,
  submitCountdown,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });
  const verificationError = useSelector(selectAuthError);
  const { initialized } = useSelector(selectTwoFactorData);

  const code = watch('code');
  const validationMessage = 'Enter a valid token.';
  const { isTicking: isSubmitCountdownTicking } = submitCountdown;

  return (
    <UnauthenticatedLayout
      title={
        initialized === false
          ? 'Enroll your One-Time Passcode (TOTP) Authenticator app'
          : 'Enter one-time passcode'
      }
      buttons={[
        {
          title: 'Cancel',
          onClick: onClickCancel,
        },
        {
          type: 'submit',
          color: 'primary',
          variant: 'contained',
          disabled: !code || loading || isSubmitCountdownTicking,
          title: initialized === false ? 'Verify' : 'Submit',
        },
      ]}
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
        sx:
          initialized === false
            ? { width: '100% !important', maxWidth: '60rem' }
            : undefined,
      }}
    >
      {initialized === false ? (
        <TOTPConfiguration />
      ) : (
        <Typography>
          Please enter the passcode from your TOTP Authenticator app.
        </Typography>
      )}
      <Controller
        name="code"
        control={control}
        rules={{
          required: validationMessage,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            variant="standard"
            autoComplete="off"
            disabled={loading}
            error={!!errors?.code}
            fullWidth={initialized === true}
            helperText={errors?.code?.message}
            inputProps={{ autoComplete: 'off' }}
            placeholder={initialized === false ? 'Token' : 'Passcode'}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="start">
                  <SvgIcon color="primary" component={LockIcon} />
                </InputAdornment>
              ),
            }}
            sx={
              initialized === false
                ? { width: '500px', maxWidth: '100%' }
                : undefined
            }
          />
        )}
      />
      <Countdown
        color="error"
        values={submitCountdown}
        templateString="Please wait %m minute%mp and %s second%sp before trying to submit again."
      />
      {!!verificationError && (
        <Typography color="error">{verificationError}</Typography>
      )}
    </UnauthenticatedLayout>
  );
};

export default TOTPVerification;
