import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import MuiList, { listClasses } from '@mui/material/List';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import useToast from '../../lib/hooks/useToast';
import { formatPhoneNumber } from '../../utils/formUtils';
import Authentication from '../../store/auth/authActions';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';
import { selectCurrentUser } from '../../store/auth/authSelectors';

import Avatar from '../Avatar';
import AvatarManager from './AvatarManager';
import ChangePhoneModal from './ChangePhoneModal';
import ChangePasswordModal from './ChangePasswordModal';
import Caret from '../../assets/icons/caret.svg?react';
import EditIcon from '../../assets/icons/edit-outline.svg?react';

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`;

const UserDropdown = styled.div`
  min-width: 15rem;
  padding: 0;
  overflow: hidden;
`;

const UserName = styled.div`
  font-weight: 700;
  text-align: center;
  padding-top: 0.5rem;
  font-size: 0.95rem;
  line-height: 1;
`;

const List = styled(MuiList)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-top: 0;
`;

export default function HeaderUserbox() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAvatarManager, setOpenAvatarManager] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [openPhoneNumberManager, setOpenPhoneNumberManager] = useState(false);
  const [toastElement, openToast] = useToast();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(Authentication.logout());
  }, [dispatch]);

  const handleAvatarManager = useCallback(() => {
    setOpenAvatarManager(true);
    setAnchorEl(null);
  }, []);

  const handleChangePassword = useCallback(() => {
    setChangePasswordOpen(true);
    setAnchorEl(null);
  }, []);

  const handleCloseChangePassword = useCallback(
    (showSuccess = false) => {
      if (showSuccess) {
        openToast('Password changed successfully.', 'success', 3000);
      }
      setChangePasswordOpen(false);
    },
    [openToast]
  );

  const handleChangePhoneNumber = useCallback(() => {
    setOpenPhoneNumberManager(true);
    setAnchorEl(null);
  }, []);

  const handleCloseChangePhone = useCallback(
    (showSuccess = false) => {
      if (showSuccess) {
        openToast('Phone number changed successfully.', 'success', 3000);
      }
      setOpenPhoneNumberManager(false);
    },
    [openToast]
  );

  if (!currentUser || !currentUser?.user) {
    return null;
  }

  const { firstName, lastName, phoneNumber } = currentUser.user;

  const avatar = (
    <Avatar
      profileImageUrl={getCachedProfileImageUrl(currentUser.user)}
      firstName={firstName}
      lastName={lastName}
      size="small"
    />
  );

  return (
    <>
      <Tooltip arrow disableInteractive title="Account">
        <Button
          color="inherit"
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            gap: 0.5,
            p: 0.75,
            pr: 0.25,
          }}
        >
          {!phoneNumber ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 },
              }}
              badgeContent=""
              overlap="circular"
              color="error"
            >
              {avatar}
            </Badge>
          ) : (
            avatar
          )}
          <Caret />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ [`.${listClasses.root}`]: { py: 0 } }}
      >
        <UserDropdown>
          <User>
            <Box sx={{ margin: '1em 0 0.5em' }}>
              <Avatar
                profileImageUrl={getCachedProfileImageUrl(currentUser.user)}
                firstName={firstName}
                lastName={lastName}
                size="small"
              />
            </Box>
            <UserName>{`${firstName} ${lastName}`}</UserName>
          </User>
          <List>
            <ListItemButton
              onClick={handleChangePhoneNumber}
              sx={{ width: '100%' }}
            >
              <Typography>
                {phoneNumber
                  ? formatPhoneNumber(phoneNumber)
                  : 'Change Phone Number'}
              </Typography>
              <SvgIcon size="small" component={EditIcon} sx={{ ml: '5px' }} />
            </ListItemButton>
            <ListItemButton
              onClick={handleAvatarManager}
              sx={{ width: '100%' }}
            >
              Manage Avatar
            </ListItemButton>
            <ListItemButton
              onClick={handleChangePassword}
              sx={{ width: '100%' }}
            >
              Change Password
            </ListItemButton>
            <Divider style={{ width: '100%' }} />
            <ListItemButton
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.shadowhq.io/privacy-policy/"
              sx={{
                width: '100%',
                '&:link, &:visited, &:active, &:hover': {
                  textDecoration: 'none',
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              }}
            >
              Privacy Policy
            </ListItemButton>
            <ListItemButton
              onClick={handleLogout}
              id="user-box-sign-out-btn"
              sx={{ width: '100%', py: 1.25 }}
            >
              Sign Out
            </ListItemButton>
          </List>
        </UserDropdown>
        <AvatarManager
          open={openAvatarManager}
          onClose={() => setOpenAvatarManager(false)}
        />
        <ChangePasswordModal
          open={changePasswordOpen}
          onClose={handleCloseChangePassword}
        />
        <ChangePhoneModal
          open={openPhoneNumberManager}
          onClose={handleCloseChangePhone}
        />
      </Menu>
      {toastElement}
    </>
  );
}
