import { useSelector } from 'react-redux';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

import { selectChatMembers } from '../../../store/chat/chatSelectors';

import EllipsisH from '../../../assets/icons/more-horizontal-outline.svg?react';
import CheckMark from '../../../assets/icons/checkmark-outline.svg?react';
import DoubleCheckMark from '../../../assets/icons/done-all-outline.svg?react';

export default function MessageState({ time }) {
  const theme = useTheme();
  const otherMembers = useSelector(selectChatMembers);
  const { sending, read } = useSelector((state) => state.messages);

  let state;
  if (otherMembers.length === 1) {
    const [otherUser] = otherMembers;

    if (otherUser.userProperties.uuid !== -1) {
      const unread = read[otherUser.userProperties.uuid];
      const creationDate = new Date(time);
      const lastReadDate = new Date(unread);

      if (sending) {
        state = 'Sending';
      } else if (creationDate.getTime() <= lastReadDate.getTime()) {
        state = 'Read';
      } else {
        state = 'Sent';
      }
    }
  }

  let messageState;
  switch (state) {
    case 'Sending':
      messageState = <EllipsisH />;
      break;
    case 'Sent':
      messageState = <CheckMark />;
      break;
    case 'Read':
      messageState = (
        <DoubleCheckMark color={theme.palette.secondary.oldMain} />
      );
      break;
    default:
      messageState = null;
  }

  return <SvgIcon fontSize="small">{messageState}</SvgIcon>;
}
