import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const MenuCustom = ({ open, onClose, anchorEl, readReceiptList }) => {
  if (!open || !anchorEl) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper
        style={{
          position: 'absolute',
          zIndex: 9999,
          top: anchorEl
            ? anchorEl.offsetTop +
              anchorEl.offsetHeight -
              90 -
              readReceiptList.length * 23
            : 0,
          right: anchorEl ? anchorEl.offsetLeft : 0,
          transform: 'translate(0, 10px)',
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '8px',
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
        }}
      >
        {readReceiptList.length > 0 ? (
          <div
            style={{
              width: '190px',
            }}
          >
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                paddingBottom: '5px',
              }}
            >
              Read by
            </Typography>
            {readReceiptList.map((u, index) => (
              <Typography
                key={`receipt-${index + 1}`}
                style={{ fontSize: '14px' }}
              >
                {u}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography
            variant="body1"
            style={{
              width: '130px',
            }}
          >
            No one has seen it
          </Typography>
        )}
      </Paper>
    </ClickAwayListener>
  );
};

export default MenuCustom;
