import axios from 'axios';

import { ORG_TYPES } from './orgReducer';
import { selectOrgCode } from './orgSelectors';
import { axiosInstance } from '../../lib/axiosInstance';
import { getServiceRegistryUrl } from '../../utils/networkUtils';

const Enterprise = {
  fetchRegistry: (orgCode) => ({
    type: ORG_TYPES.FETCH_REGISTRY,
    payload: axios.get(getServiceRegistryUrl(orgCode)),
  }),
  fetchConfig: () => (dispatch, getState) => {
    const orgCode = selectOrgCode(getState());

    return dispatch({
      type: ORG_TYPES.FETCH_CONFIG,
      payload: axiosInstance.get(`/config/public/${orgCode}`),
    });
  },
  fetchEnterprisePlatformConfig: () => ({
    type: ORG_TYPES.FETCH_ENTERPRISE_PLATFORM_CONFIG,
    payload: axiosInstance.get('/config'),
  }),
  fetchBadges: () => ({
    type: ORG_TYPES.FETCH_BADGES,
    payload: axiosInstance.get('/version/badges'),
  }),
  requestUpgrade: (featureName) => ({
    type: ORG_TYPES.REQUEST_UPGRADE,
    payload: axiosInstance.post(`/feature/upgrade?name=${featureName}`),
  }),
  resetDomain: () => ({
    type: ORG_TYPES.RESET_ORG,
  }),
  fetchChildOrgs: () => ({
    type: ORG_TYPES.FETCH_CHILD_ORGS,
    payload: axiosInstance.get('/crossEnterprises/enterprises'),
  }),
};

export default Enterprise;
