import { USER_TYPES } from './userReducer';
import { AUTH_TYPES } from '../auth/authReducer';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectCurrentUser } from '../auth/authSelectors';

const Users = {
  fetchUsers: () => ({
    type: USER_TYPES.FETCH_USERS,
    payload: axiosInstance.get('/authentication/users'),
  }),
  fetchLastActiveTimestamps: () => ({
    type: USER_TYPES.FETCH_LAST_ACTIVE_TIMESTAMPS,
    payload: axiosInstance.get('/dashboard/userActivityTimestamps'),
  }),
  sync: (users) => ({
    type: USER_TYPES.SYNC,
    payload: users,
  }),
  convertedProfileImg: (file) => async () => {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    };

    const response = await axiosInstance.post(
      '/profileImage/convertedImagePreview',
      formData,
      options
    );
    const src = URL.createObjectURL(response.data);

    return src;
  },
  setProfileImg: (file) => (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    const formData = new FormData();
    formData.append('file', file);

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return dispatch({
      type: USER_TYPES.SET_PROFILE_IMG,
      payload: axiosInstance
        .post('/profileImage', formData, options)
        .then(({ data }) => {
          const newMe = {
            ...currentUser,
            user: {
              ...currentUser.user,
              profileImageUrl: data.url,
              lastUpdateTimestamp: Date.now(),
            },
          };

          // This is done to avoid cyclical imports in between the users reducer
          // and auth reducers. It's also more explicit since this could be used
          // for the user admin section as well when updating ones own profile
          // from that page. However, this whole action could also be exported to
          // some profile actions instead.
          dispatch({
            type: AUTH_TYPES.UPDATE_CURRENT_USER,
            payload: newMe,
          });

          return { url: data.url, uuid: currentUser.uuid };
        }),
    });
  },
  setPhoneNumber: (phoneNumber) => (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    return dispatch({
      type: USER_TYPES.SET_PHONE_NUMBER,
      payload: axiosInstance
        .patch('/me', {
          phoneNumber,
        })
        .then(({ data }) => {
          const newMe = {
            ...currentUser,
            user: {
              ...currentUser.user,
              phoneNumber,
            },
          };

          // See matching comment in the action above.
          dispatch({
            type: AUTH_TYPES.UPDATE_CURRENT_USER,
            payload: newMe,
          });

          return { number: data.phoneNumber, uuid: currentUser.uuid };
        }),
    });
  },
};

export default Users;
