import { useState } from 'react';
import Button from '@mui/material/Button';
import MuiModal from '@mui/material/Modal';
import { ClipLoader } from 'react-spinners';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.aside`
  min-width: 28rem;
  max-height: calc(100vh - 2rem);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const ModalHeader = styled.header`
  padding: 1rem;
  gap: 0.5rem;
  flex-shrink: 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
`;

const ModalBody = styled.section`
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const ModalFooter = styled.footer`
  padding: 1rem;
  flex-shrink: 0;
`;

const ModalButtons = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BasicModal = ({
  open,
  title,
  onClose,
  onClickButton,
  onClickCancelButton,
  buttonText = 'Save',
  buttonColor = 'primary',
  buttonDisabled = false,
  showCancelButton = true,
  cancelButtonText = 'Cancel',
  headerStyles = {},
  bodyStyles = {},
  footerStyles = {},
  footerComponent = null,
  children,
  sx,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const hasOnClickCancelHandler = !!onClickCancelButton;
  const isButtonDisabled = hasOnClickCancelHandler
    ? buttonDisabled || cancelLoading || loading
    : buttonDisabled || loading;
  const cancelButtonDisabled = hasOnClickCancelHandler
    ? cancelLoading || loading
    : loading;

  const handleClickButton = async () => {
    setLoading(true);
    await onClickButton();
    setLoading(false);
  };

  const handleClickCancelButton = async () => {
    if (!hasOnClickCancelHandler) {
      return onClose();
    }

    setCancelLoading(true);
    await onClickCancelButton();
    setCancelLoading(false);
  };

  return (
    <Modal open={open} onClose={onClose} {...props}>
      <ModalContent sx={sx}>
        {!!title && (
          <ModalHeader sx={headerStyles}>
            <Typography component="h2" variant="h5">
              {title}
            </Typography>
          </ModalHeader>
        )}
        {!!children && <ModalBody sx={bodyStyles}>{children}</ModalBody>}
        <ModalFooter sx={footerStyles}>
          {!!footerComponent && footerComponent}
          {!footerComponent && (
            <ModalButtons>
              {showCancelButton && (
                <Button
                  disabled={cancelButtonDisabled}
                  onClick={handleClickCancelButton}
                >
                  {!hasOnClickCancelHandler ? (
                    cancelButtonText
                  ) : cancelLoading ? (
                    <ClipLoader size={16} color="white" />
                  ) : (
                    cancelButtonText
                  )}
                </Button>
              )}
              <Button
                color={buttonColor}
                variant="contained"
                onClick={handleClickButton}
                disabled={isButtonDisabled}
              >
                {loading ? <ClipLoader size={16} color="white" /> : buttonText}
              </Button>
            </ModalButtons>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BasicModal;
