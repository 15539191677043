export const WELCOME_PACKAGE_STATES = {
  CREATED: 'CREATED',
  SAVED: 'SAVED',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
};

// These errors can be returned from the API.
export const WELCOME_PACKAGE_ERRORS = {
  NOT_FOUND: 'NOT_FOUND',
  TOO_MANY_USERS: 'TOO_MANY_USERS',
  ERROR_DURING_POPULATION: 'ERROR_DURING_POPULATION',
};

// These errors can only occur while user data is validated.
export const WELCOME_PACKAGE_VALIDATION_ERRORS = {
  INVALID_WAR_ROOMS: 'INVALID_WAR_ROOMS',
};

export const WELCOME_PACKAGE_ERROR_MESSAGES = {
  [WELCOME_PACKAGE_ERRORS.NOT_FOUND]:
    'This Welcome Package does not exist, or has already been submitted.',
  [WELCOME_PACKAGE_ERRORS.TOO_MANY_USERS]:
    'You have exceeded the maximum number of users allowed for your organization.',
  [WELCOME_PACKAGE_ERRORS.ERROR_DURING_POPULATION]:
    "An error occurred while processing your information. But don't worry, your information has been saved. We'll resolve the issues and no further action is needed on your part.",
  [WELCOME_PACKAGE_VALIDATION_ERRORS.INVALID_WAR_ROOMS]:
    'Please assign a title and at least an admin user for each War Room.',
};

export const SUGGESTED_WAR_ROOMS = [
  'Administration',
  'Compliance',
  'Council',
  'Customer Relations',
  'Cyber Security Response',
  'Emergency Management',
  'Executive Management',
  'Facilities',
  'Finance',
  'Fire',
  'Government Relations',
  'Health & Safety',
  'Human Resources',
  'Insurance',
  'Investor Relations',
  'IT – Information Technology',
  'IT Security',
  'Legal',
  'Manufacturing',
  'Media Relations',
  'Paramedics',
  'Physical Security',
  'Police',
  'Procurement',
  'Public Relations',
  'Public Services',
  'Sales',
];

export const MAX_WAR_ROOMS = 15;

export const SUGGESTED_FILE_FOLDERS = [
  'Press & Media',
  'Response Playbooks',
  'Network Specs',
  'Vendor Information',
  'Customer Information',
];

export const MAX_FILE_FOLDERS = 50;
