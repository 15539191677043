export const caseInsensitiveCompare = (a, b) => a.localeCompare(b);

export const nameCompare = (a, b) => caseInsensitiveCompare(a.name, b.name);

export const labelCompare = (a, b) => caseInsensitiveCompare(a.label, b.label);

export const userCompare = (a, b) => {
  const firstNameA = a.firstName.toLowerCase();
  const firstNameB = b.firstName.toLowerCase();
  const lastNameA = a.lastName.toLowerCase();
  const lastNameB = b.lastName.toLowerCase();

  if (firstNameA > firstNameB) {
    return 1;
  }
  if (firstNameA < firstNameB) {
    return -1;
  }
  if (lastNameA > lastNameB) {
    return 1;
  }
  if (lastNameA < lastNameB) {
    return -1;
  }
  return 0;
};

export const playbookCompare = nameCompare;

export const groupCompare = nameCompare;
