import { useState, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import MuiAvatar from '@mui/material/Avatar';
import { PulseLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, styled } from '@mui/material/styles';
import { createSelector } from 'reselect';
import DialogModal from '../DialogModal';
import Avatar from '../Avatar';
import {
  selectTypingEvents,
  selectIsActive,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import { selectChatId } from '../../store/jitsi/jitsiSelectors';
import { Badge, ChatItem } from './ChatContent';
import { selectCurrentUserUUID } from '../../store/auth/authSelectors';
import { selectUserList } from '../../store/user/userSelectors';
import { selectHasDraftByChannelId } from '../../store/chat/draftSelectors';
import { getTimeDiffString } from '../../lib/date';
import ConfirmModal from '../ConfirmModal';
import Chats from '../../store/chat/chatActions';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';
import { caseInsensitiveCompare } from '../../utils/sortUtils';

// Icons
import Phone from '../../assets/icons/phone-outline.svg?react';
import PhoneCall from '../../assets/icons/phone-call-outline.svg?react';
import Close from '../../assets/icons/close.svg?react';
import Draft from '../../assets/icons/draft.svg?react';

// *************************************************
// Styled Components
// *************************************************
const Item = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`;

const DisplayNameWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 1em;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  small {
    color: #aaa;
  }
`;

const DisplayName = styled.span`
  display: block;
  width: ${({ hover }) => hover && '93%'};
  overflow: hidden;
  color: ${({ active, theme }) =>
    active === 'true' && theme.palette.primary.main};
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.25;
  font-weight: 500;
  font-size: 15px;
`;

const StyledIcon = styled(SvgIcon)`
  font-size: 1.5em;
  height: 1.5em;
  margin-right: 7px;
  color: #424242;
`;

const PhoneAvatar = styled(MuiAvatar)`
  height: 35px;
  width: 35px;
  padding: 5px;
`;

const IncomingCall = styled(PhoneAvatar)`
  background: ${({ theme }) => theme.palette.secondary.main};
  font-size: 35px;
`;

const OnCall = styled(PhoneAvatar)`
  background: ${({ theme }) => theme.palette.success.main};
  font-size: 35px;
`;

// *************************************************
// Component specific selectors
// *************************************************
const selectMembers = createSelector(
  selectUserList,
  selectCurrentUserUUID,
  (_, props) => props.chat,
  (list, currentUserUUID, chat) => {
    const memberIds = Object.keys(chat.state.members);
    const otherMembers = memberIds.filter((p) => p !== currentUserUUID);

    return otherMembers.map((p) => {
      const defaultUser = {
        userProperties: { uuid: -1 },
        firstName: 'Former',
        lastName: 'Member',
      };

      let user = list.find((u) => u.userProperties.uuid === p);

      if (user) {
        if (user.userProperties.type === 'external') {
          user = {
            ...user,
            lastName: `${user.lastName} (External)`,
          };
        }

        if (!user.active) {
          user = {
            ...user,
            lastName: `${user.lastName} (Inactive)`,
          };
        }
      }

      return user || defaultUser;
    });
  }
);

const selectMemberNames = createSelector(selectMembers, (members) =>
  members
    .map((u) => `${u.firstName} ${u.lastName}`)
    .sort(caseInsensitiveCompare)
    .join(', ')
);

// *************************************************
// Component
// *************************************************
export default function DirectChatItem({ chat, onClickChat }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const jitsiChatId = useSelector(selectChatId);
  const typingEvents = useSelector(selectTypingEvents);
  const members = useSelector((state) => selectMembers(state, { chat }));
  const name = useSelector((state) => selectMemberNames(state, { chat }));
  const active = useSelector((state) => selectIsActive(state, { chat }));
  const hasDraft = useSelector((state) =>
    selectHasDraftByChannelId(state, chat.cid)
  );
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [discardOpen, setDiscardOpen] = useState(false);
  const { unreadCount } = chat.state;
  const isOneToOne = members.length === 1;
  const { last_message_at: lastMessageAt } = chat.state;
  const newChatForm = useSelector(selectNewChatForm);
  const isNewChatFormOpen = !!newChatForm;

  let unread;
  let avatar = null;

  const onHover = useCallback((e) => {
    if (e.type === 'mouseenter') {
      setHover(true);
    }

    if (e.type === 'mouseleave') {
      setHover(false);
    }
  }, []);

  const onHideChat = () => {
    const allActive = members.filter((m) => m.active);

    if (allActive.length === 0 || allActive.length !== members.length) {
      setOpen(true);
    } else {
      chat.hide();
    }
  };

  const confirm = () => {
    chat.hide().then(() => setOpen(false));
  };

  const handleChatContainerClick = () => {
    if (!discardOpen && isNewChatFormOpen) {
      setDiscardOpen(true);
    } else {
      setDiscardOpen(false);
    }
  };

  const discardCreateNewChat = () => dispatch(Chats.toggleNewChatForm(''));

  if (typingEvents[chat.cid] && typingEvents[chat.cid].length > 0) {
    avatar = (
      <MuiAvatar style={{ height: '35px', width: '35px' }}>
        <IconButton
          style={{
            backgroundColor: theme.palette.secondary.main,
            height: '35px',
            width: '35px',
          }}
          size="large"
        >
          <PulseLoader size={5} color="white" />
        </IconButton>
      </MuiAvatar>
    );
  } else if (!isOneToOne) {
    avatar = <Avatar firstName={`${members.length}`} lastName="" />;
  } else {
    const [otherUser] = members;

    if (otherUser.userProperties.uuid !== -1) {
      const { firstName, lastName, profileImageUrl } = otherUser;

      if (jitsiChatId === chat.cid) {
        avatar = (
          <OnCall>
            <SvgIcon
              component={PhoneCall}
              style={{ borderRadius: '50%', fontSize: '25px' }}
              fill={theme.palette.white.main}
            />
          </OnCall>
        );
      } else if (jitsiChatId !== chat.cid && chat.data.callDetails?.isActive) {
        avatar = (
          <IncomingCall>
            <SvgIcon
              component={Phone}
              style={{ borderRadius: '50%', fontSize: '25px' }}
              fill={theme.palette.white.main}
            />
          </IncomingCall>
        );
      } else if (profileImageUrl) {
        avatar = (
          <Avatar profileImageUrl={getCachedProfileImageUrl(otherUser)} />
        );
      } else {
        avatar = <Avatar firstName={firstName} lastName={lastName} />;
      }
    } else {
      // If no other use is found, it's likely they were there but got deleted.
      // Therefore, just skip this chat
      return null;
    }
  }

  if (!active) {
    if (unreadCount < 100) {
      unread = unreadCount;
    } else {
      unread = '99+';
    }
  }

  return (
    <Tooltip placement="right" title={name.length > 20 ? name : ''}>
      <ChatItem
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        button
        theme={theme}
        active={active.toString()}
        onClick={handleChatContainerClick}
      >
        <Item onClick={() => onClickChat()}>
          <Badge
            color="grey"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={hasDraft ? <SvgIcon component={Draft} /> : 0}
          >
            <Badge color="error" badgeContent={unread}>
              {avatar}
            </Badge>
          </Badge>
          <DisplayNameWrapper>
            <DisplayName
              hover={hover}
              active={active.toString()}
              activeColor={theme.palette.secondary.main}
            >
              {name}
            </DisplayName>
            {lastMessageAt && (
              <small>{`last message ${getTimeDiffString(
                lastMessageAt.getTime()
              )}`}</small>
            )}
          </DisplayNameWrapper>
        </Item>
        {unreadCount === 0 && (
          <StyledIcon className="cancel-icon hover" onClick={onHideChat}>
            <Close />
          </StyledIcon>
        )}
        <DialogModal
          open={open}
          message="If you close this chat, you will not be able to recover it due to some users being inactive. Are you sure you want to close?"
          onCancel={() => setOpen(false)}
          onConfirm={confirm}
        />
        <ConfirmModal
          open={discardOpen}
          confirmText="Discard"
          onClose={() => setDiscardOpen(false)}
          onConfirm={discardCreateNewChat}
          cancel={true}
        >
          <p>{`Are you sure you want to discard this new chat?`}</p>
          <p>You will need to re-enter the details if you leave this form.</p>
        </ConfirmModal>
      </ChatItem>
    </Tooltip>
  );
}
