import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';

import { handlePaste } from '../../lib/input';
import { emailRegex } from '../../utils/formUtils';
import Enterprise from '../../store/org/orgActions';
import { MAX_LENGTH } from '../../configs/validation';
import { selectAuthError } from '../../store/auth/authSelectors';

import PasswordInput from '../PasswordInput';
import PersonIcon from '../../assets/icons/person.svg?react';
import UnauthenticatedLayout from '../UnauthenticatedLayout';

const EmailPasswordForm = ({ loading, onSubmit }) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      rememberEmail: false,
    },
  });
  const emailRef = useRef();
  const passwordRef = useRef();
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);

  const emailError = 'Please enter a valid email address.';

  const handleClickChangeOrg = () => dispatch(Enterprise.resetDomain());

  // Remember email effect.
  useEffect(() => {
    const storedEmail = window.localStorage.getItem('username');
    setValue('email', storedEmail || '');
    setValue('rememberEmail', !!storedEmail);

    if (!storedEmail) {
      emailRef.current.focus();
    } else {
      passwordRef.current.focus();
    }
  }, [setValue]);

  return (
    <UnauthenticatedLayout
      buttons={[
        {
          type: 'submit',
          title: 'Sign In',
          disabled: loading,
          variant: 'contained',
        },
      ]}
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
        sx: { gap: 1.5 },
      }}
    >
      <Controller
        name="email"
        control={control}
        rules={{
          required: emailError,
          pattern: {
            value: emailRegex,
            message: emailError,
          },
          validate: (value) =>
            value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              autoFocus
              fullWidth
              variant="standard"
              disabled={loading}
              inputRef={emailRef}
              error={!!errors?.email}
              autoComplete="username"
              placeholder="Email Address"
              helperText={errors?.email?.message}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="primary" component={PersonIcon} />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: 'Please enter your password.',
          validate: (value) =>
            value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <PasswordInput
              {...field}
              fullWidth
              color="primary"
              variant="standard"
              iconProps={{
                color: 'primary',
              }}
              disabled={loading}
              outlineIcon={false}
              inputRef={passwordRef}
              placeholder="Password"
              visibilityEdge="start"
              error={!!errors?.password}
              autoComplete="current-password"
              helperText={errors?.password?.message}
              onPaste={(e) => handlePaste(e, onChange)}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="rememberEmail"
        render={({ field }) => {
          const { value } = field;
          return (
            <FormControlLabel
              label="Remember my email"
              control={
                <Checkbox
                  {...field}
                  color="primary"
                  checked={value}
                  disabled={loading}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ py: 0 }}
                />
              }
              sx={{ alignSelf: 'flex-start' }}
            />
          );
        }}
      />
      {!!error && (
        <Typography variant="body" color="error">
          {error}
        </Typography>
      )}
      <Stack gap={0.5} alignSelf="flex-start">
        <Link to="/setup" onClick={handleClickChangeOrg}>
          Change Organization
        </Link>
        <Link to="/forgot">Forgot Password</Link>
      </Stack>
    </UnauthenticatedLayout>
  );
};

export default EmailPasswordForm;
