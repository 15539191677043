import { useRef, useEffect } from 'react';
import Picker from 'emoji-picker-react';
import { styled } from '@mui/material/styles';

const PickerContainer = styled.div`
  position: absolute;
  right: 21px;
  bottom: 60px;
  border-radius: 5px;
  border: 1px solid #aaa;
  display: ${({ open }) => !open && 'none'};
`;

export default function EmojiPicker({ open, onClose, handleSelection }) {
  const pickerRef = useRef();

  // Effect to bind mouse click handler for a blur like effect
  // on the picker.
  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef, onClose]);

  return (
    <PickerContainer ref={pickerRef} open={open}>
      <Picker
        preload
        native
        disableSkinTonePicker
        onEmojiClick={handleSelection}
      />
    </PickerContainer>
  );
}
