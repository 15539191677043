import { DASH_TYPES } from './dashboardReducer';

import { axiosInstance } from '../../lib/axiosInstance';

const Dashboard = {
  getDashboard: () => ({
    type: DASH_TYPES.FETCH_DASHBOARD,
    payload: axiosInstance.get('/dashboard'),
  }),
  setDashboard: (dashboard) => ({
    type: DASH_TYPES.SET_DASHBOARD,
    payload: dashboard,
  }),
};

export default Dashboard;
