import remarkGfm from 'remark-gfm';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  ACTIVITY_TYPES,
  ACTIVITY_TYPE_LABELS,
} from '../../store/incident/incidentTypes';
import Incidents from '../../store/incident/incidentActions';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';

import DetailList from './DetailList';
import CheckInTable from './CheckInTable';
import Task from './activities/Task';
import Update from './activities/Update';
import TaskForm from './activities/TaskForm';
import UpdateForm from './activities/UpdateForm';

const EventTitle = styled(Typography)`
  position: relative;
  z-index: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Description = styled.div`
  p {
    font-size: 14px;
  }
`;

const StickyContainer = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 2;
  background: ${({ theme }) => theme.palette.white.main};
`;

const ToggleButton = styled(MuiToggleButton)`
  padding: 0 0.5em;
  font-size: 12px;
  text-transform: none;
`;

const PageContent = ({
  title,
  fullText,
  activities,
  checkIns,
  isActive,
  postmortemDetails = null,
  ...data
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activitySectionRef = useRef();
  const activityRef = useRef();
  const [filter, setFilter] = useState('all');
  const [editingId, setEditingId] = useState(null);
  const [newActivityForm, setNewActivityForm] = useState('');
  const [confirmDeleteActivityElement, openConfirmDeleteActivity] =
    useConfirmAsync();

  const focusNewActivity = location.state?.focusNewActivity;
  const focusActivity = location.state?.focusActivity;
  const filteredActivities =
    filter === 'all'
      ? activities
      : activities.filter((item) => item.type === filter);

  const scrollToActivitySection = () => {
    activitySectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const scrollToActivity = () => {
    if (!activityRef.current) {
      return scrollToActivitySection();
    }
    activityRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  // Scroll effect for updating incident.
  useEffect(() => {
    if (!!focusNewActivity) {
      scrollToActivitySection();
    }
  }, [focusNewActivity]);

  // Scroll effect for task notification.
  useEffect(() => {
    if (!!focusActivity) {
      scrollToActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusActivity, activityRef.current]);

  const handleClickFilter = (e, value) => {
    setFilter(value);
    scrollToActivitySection();
  };

  const handleAddUpdate = () => {
    setNewActivityForm('update');
    scrollToActivitySection();
  };

  const handleAddTask = () => {
    setNewActivityForm('task');
    scrollToActivitySection();
  };

  const handleCancel = () => {
    setNewActivityForm('');
    setEditingId(null);
  };

  const handleClickEdit = (itemId) => setEditingId(itemId);

  const handleClickDelete = (itemId, itemType) => {
    const itemLabel = ACTIVITY_TYPE_LABELS[itemType];
    openConfirmDeleteActivity({
      title: `Delete ${itemLabel}`,
      content: (
        <div style={{ textAlign: 'center' }}>
          Are you sure you want to delete this {itemLabel.toLowerCase()}?
        </div>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () => dispatch(Incidents.deleteActivity(itemId)),
    });
  };

  return (
    <>
      <EventTitle component="h1" variant="h3">
        {title}
      </EventTitle>
      <DetailList {...data} isActive={isActive} />
      <Divider sx={{ margin: '1.25rem 0' }} />
      <Description>
        <ReactMarkdown plugins={[remarkGfm]}>{fullText}</ReactMarkdown>
        {!isActive && !!postmortemDetails && (
          <>
            <Divider sx={{ margin: '1.25rem 0' }} />
            <Typography component="h2" variant="h3">
              Post-Incident Anaylsis
            </Typography>
            <ReactMarkdown plugins={[remarkGfm]}>
              {postmortemDetails}
            </ReactMarkdown>
          </>
        )}
      </Description>
      <Divider sx={{ margin: '1.25rem 0 0' }} />
      <StickyContainer py={2.5}>
        <Stack
          component="section"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
        >
          <Stack
            direction={isActive ? 'column' : 'row'}
            justifyContent={isActive ? 'center' : 'space-between'}
            width={isActive ? 'auto' : '100%'}
            gap={1}
          >
            <Typography component="h2" variant="h3">
              Activity
            </Typography>
            {!newActivityForm && !editingId && (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography lineHeight={1}>Filter:</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={filter}
                  exclusive
                  aria-label="Filter"
                  onChange={handleClickFilter}
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value={ACTIVITY_TYPES.TASK}>Tasks</ToggleButton>
                  <ToggleButton value={ACTIVITY_TYPES.UPDATE}>
                    Updates
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            )}
          </Stack>
          {isActive && (
            <Stack direction="row" alignItems="center" gap={1.5}>
              {!newActivityForm && !editingId ? (
                <>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleAddUpdate}
                    sx={{ color: '#fff' }}
                  >
                    Add Update
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleAddTask}
                    sx={{ color: '#fff' }}
                  >
                    Create Task
                  </Button>
                </>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleCancel}
                  sx={{ color: '#fff' }}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </StickyContainer>
      <Box ref={activitySectionRef} sx={{ scrollMarginTop: '7rem' }}>
        {newActivityForm === 'update' && (
          <UpdateForm key={0} onSubmit={handleCancel} />
        )}
        {newActivityForm === 'task' && (
          <TaskForm key={0} onSubmit={handleCancel} />
        )}
      </Box>
      {!!filteredActivities.length && !newActivityForm && (
        <Stack spacing={3.5} mb={2.5}>
          {filteredActivities.map((item) => {
            if (item.type === ACTIVITY_TYPES.TASK) {
              return editingId === item.id ? (
                <TaskForm
                  key={item.id}
                  task={item}
                  onSubmit={() => setEditingId(null)}
                />
              ) : (
                <Task
                  {...item}
                  key={item.id}
                  ref={item.id === focusActivity ? activityRef : null}
                  isResolved={!isActive}
                  onClickEdit={() => handleClickEdit(item.id)}
                  onClickDelete={() => handleClickDelete(item.id, item.type)}
                />
              );
            } else {
              return editingId === item.id ? (
                <UpdateForm
                  key={item.id}
                  update={item}
                  onSubmit={() => setEditingId(null)}
                />
              ) : (
                <Update
                  {...item}
                  key={item.id}
                  ref={item.id === focusActivity ? activityRef : null}
                  isResolved={!isActive}
                  onClickEdit={() => handleClickEdit(item.id)}
                  onClickDelete={() => handleClickDelete(item.id, item.type)}
                />
              );
            }
          })}
        </Stack>
      )}
      {!filteredActivities.length && !newActivityForm && (
        <Typography align="center">No activity to show.</Typography>
      )}
      <Divider sx={{ margin: '1.25rem 0' }} />
      <Stack spacing={0.75} mb={2} sx={{ position: 'relative', zIndex: 0 }}>
        <Typography component="h2" variant="h3">
          {isActive ? 'Team Status' : 'Responded Team Members'}
        </Typography>
        <CheckInTable rows={checkIns} />
      </Stack>
      {confirmDeleteActivityElement}
    </>
  );
};

export default PageContent;
