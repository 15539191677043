import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  ejectAxiosInterceptors,
  setupAxiosInterceptors,
} from '../lib/axiosInstance';
import { store } from '../store';
import JitsiCalls from '../store/jitsi/jitsiActions';
import { selectOnCall } from '../store/jitsi/jitsiSelectors';
import { selectIsAuthenticated } from '../store/auth/authSelectors';

import Sidebar from './Sidebar';
import PageTabs from './PageTabs';
import Header from './header/Header';
import JitsiCall from './calling/JitsiCall';
import PollingManager from './core/PollingManager';
import TabSyncManager from './core/TabSyncManager';
import OfflineWarning from './warnings/OfflineWarning';
import ReadinessManager from './core/ReadinessManager';
import HeartbeatManager from './core/HeartbeatManager';
import NavigationHelper from './core/NavigationHelper';
import ChatClientManager from './core/ChatClientManager';
import SentryUserManager from './core/SentryUserManager';
import MessageStatusManager from './core/MessageStatusManager';
import TokenRefreshScheduler from './core/TokenRefreshScheduler';
import TwoFactorVerificationModal from './file-storage/TwoFactorVerificationModal';

const AppWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const AppMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
// Note: Tooltip scrolling issue fixed here.

const AppContent = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export default function AuthenticatedLayout() {
  const dispatch = useDispatch();
  const [isOffline, setIsOffline] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    const onlineListener = () => {
      setIsOffline((prevIsOffline) => {
        const onCall = selectOnCall(store.getState());
        if (prevIsOffline && onCall) {
          console.log('@@SHQ/Jitsi - Leaving call after returning online...');
          dispatch(JitsiCalls.leaveCall());
        }
        return false;
      });
    };
    const offlineListener = () => setIsOffline(true);

    // console.log('AuthenticatedLayout mount');
    setupAxiosInterceptors();
    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    return () => {
      //  console.log('AuthenticatedLayout unmount');
      ejectAxiosInterceptors();
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, [dispatch]);

  if (!isAuthenticated) {
    return <Navigate to="/setup" replace />;
  }

  return (
    <>
      <ReadinessManager />
      <HeartbeatManager />
      <TokenRefreshScheduler />
      <ChatClientManager />
      <TabSyncManager />
      <PollingManager />
      <NavigationHelper />
      <SentryUserManager />
      <MessageStatusManager />
      {isOffline ? (
        <OfflineWarning />
      ) : (
        <>
          <JitsiCall />
          <AppWrapper>
            <Sidebar />
            <AppMain>
              <Header />
              <PageTabs />
              <AppContent>
                {/* Outlet takes the component of the matched route and renders it. */}
                {/* https://reactrouter.com/docs/en/v6/api#outlet */}
                <Outlet />
              </AppContent>
            </AppMain>
            <TwoFactorVerificationModal />
          </AppWrapper>
        </>
      )}
    </>
  );
}
