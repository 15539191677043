import { FOLDER_TYPES, FOLDER_TYPE_LABELS } from '../configs';

export function getFileExt(fileName) {
  if (fileName) {
    const dot = fileName.lastIndexOf('.');
    if (dot >= 0) {
      return fileName.substr(dot + 1).toLowerCase();
    }
  }

  return '';
}

export const getFilenameWithoutExt = (filename) => {
  if (!filename) {
    return '';
  }

  const dot = filename.lastIndexOf('.');
  if (!dot) {
    return filename;
  }
  return filename.substr(0, dot);
};

export function getTypeByExtension(extension) {
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'dot':
    case 'rtf':
      return 'MsWord';
    case 'xls':
    case 'xlsx':
    case 'xlb':
    case 'xlt':
      return 'MsExcel';
    case 'ppt':
    case 'pps':
    case 'pptx':
    case 'ppsx':
      return 'MsPowerPoint';
    case 'pdf':
      return 'AdobeReader';
    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
    case 'svgz':
      return 'Image';
    case 'amr':
      return 'VoiceNote';
    case 'mid':
    case 'midi':
    case 'm3u':
    case 'wma':
    case 'wav':
    case 'mp3':
    case 'ogg':
      return 'Audio';
    case '3gp':
    case '3gpp':
    case '3g2':
    case '3gpp2':
    case 'mp4':
    case 'mpg':
    case 'mpeg':
    case 'qt':
    case 'mov':
    case 'wmv':
    case 'avi':
      return 'Video';
    case 'html':
    case 'xhtml':
    case 'txt':
    case 'xml':
      return 'Text';
    case 'vcf':
      return 'ContactCard';
    case 'vcs':
      return 'Calendar';
    default:
      return '';
  }
}

export const formatFileSize = (bytes, decimalPlaces) => {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 1000;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedNumber = parseFloat(
    (bytes / Math.pow(k, i)).toFixed(decimalPlaces)
  );

  return `${formattedNumber} ${sizes[i]}`;
};

export function convertSizetoMb(bytes, digits = 2) {
  return (bytes / (1024 * 1024)).toFixed(digits);
}

// create path string from array of files with parent id
// for a given input id contruct up the path to find its parent id
// recursively call this function until the parent id is null
export function getPathFromId(files, id) {
  const file = files.find((f) => f.id === id);
  if (!file) {
    return '';
  }

  if (file.parentFolderId) {
    return `${getPathFromId(files, file.parentFolderId)}/${file.name}`;
  }

  if (file.folder) {
    return `/${file.name}`;
  }
  return '';
}

/**
 * This function recursively assembles an array of breadcrumbs from the current folder back up to the root folder.
 * @param {Array} tree The SFS file tree returned by the API.
 * @param {Number} id The id of the current folder.
 * @returns {Array} The generated list of breadcrumbs.
 */
export const getBreadcrumbsFromId = (tree, id) => {
  if (id === 0) {
    return [];
  }

  const item = tree.find((f) => f.id === id);
  if (!item) {
    return [];
  }

  return [...getBreadcrumbsFromId(tree, Number(item.parentFolderId)), item];
};

// take a path string, files array and return a selected ordered array of folders matching the path
export function getFoldersFromPath(files, path = '/') {
  const pathArray = path.split('/');
  const foldersArray = [];

  for (let i = 0; i < pathArray.length; i += 1) {
    const folder = files.find((f) => f.name === pathArray[i]);
    if (folder) {
      foldersArray.push(folder);
    }
  }

  return foldersArray;
}

// take an array with allowedFileFormats and create a RegEx
export function createRegExAllowedFileFormats(allowedFileFormats) {
  const lowercaseFormats = allowedFileFormats.map((format) =>
    String(format).toLowerCase().split(',')
  );
  const transformedFormats = lowercaseFormats.flatMap((format) =>
    format.join(',').split(',')
  );
  const transformedFormatsWithAddition = transformedFormats.map(
    (i) => `\\.${i}`
  );
  const allowedExtensionsPattern = `(${transformedFormatsWithAddition.join(
    '|'
  )})$`;
  const allowedExtensions = new RegExp(allowedExtensionsPattern);

  return allowedExtensions;
}

export const getFolderAccessLabel = (type) => {
  switch (type) {
    case FOLDER_TYPES.PUBLIC:
      return 'All Users';
    case FOLDER_TYPES.PRIVATE:
      return 'Group Users';
    case FOLDER_TYPES.ADMIN:
      return 'Admin Users';
    default:
      return 'All Users';
  }
};

export const getItemTypeLabel = (item, inheritedFolderType = undefined) => {
  if (item?.isFolder) {
    const folderType = !!inheritedFolderType
      ? inheritedFolderType?.folderType
      : item?.folderType;
    return FOLDER_TYPE_LABELS?.[folderType] || 'Folder';
  }
  if (item?.extension) {
    return item.extension.toUpperCase();
  }
  return 'File';
};
