import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
  selectVerified,
  selectVerificationExpiry,
} from '../../store/storage/storageSelectors';
import Users from '../../store/user/userActions';
import useDebounce from '../../lib/hooks/useDebounce';
import { downloadFile } from '../../utils/networkUtils';
import Storage from '../../store/storage/storageActions';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { selectEnable2faOnSfs } from '../../store/org/orgSelectors';

import Header from './Header';
import ItemList from './ItemList';
import ItemInfo from './ItemInfo';
import Breadcrumbs from './Breadcrumbs';
import ContextMenu from './ContextMenu';
import FolderModal from './FolderModal';
import DownloadModal from './DownloadModal';
import RenameFileModal from './RenameFileModal';
import FileUploadModal from './FileUploadModal';
import FileViewer from '../file-viewer/FileViewer';
import DeleteFolderModal from './DeleteFolderModal';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

const FileListContainer = styled.div`
  height: 100%;
  width: ${({ preview }) => (preview ? '66%' : '100%')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FilePreview = styled.aside`
  height: 100%;
  width: ${({ open }) => (open ? '300px' : '0')};
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: ${({ hasItem }) => (!hasItem ? 'center' : 'flex-start')};
  flex-direction: column;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  flex-shrink: 0;
`;

const DEBOUNCE_TIME_DELAY = 150;

const FileStorage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const folderId = Number(searchParams.get('folder')) || 0;
  const [modal, setModal] = useState('');
  const [xPos, setXPos] = useState(null);
  const [yPos, setYPos] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemInFocus, setItemInFocus] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [itemViewerUrl, setItemViewerUrl] = useState('');
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [confirmDeleteFileElement, openConfirmDeleteFile] = useConfirmAsync();
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_TIME_DELAY);
  const verificationExpiry = useSelector(selectVerificationExpiry);
  const enable2faOnSfs = useSelector(selectEnable2faOnSfs);
  const verified = useSelector(selectVerified);

  const downloadLabel =
    itemInFocus && !itemInFocus?.isFolder
      ? `${itemInFocus.name}.${itemInFocus.extension}`
      : '';

  const handleOpenContextMenu = useCallback((x, y, item) => {
    setContextMenuOpen(true);
    setXPos(x);
    setYPos(y);
    setItemInFocus(item);
  }, []);

  const closeContextMenu = useCallback(() => setContextMenuOpen(false), []);

  const clearPreview = useCallback(() => {
    setItemInFocus(null);
    setPreviewOpen(false);
  }, []);

  const handleClearSearch = useCallback(() => {
    searchInputRef.current.value = '';
    setSearchTerm('');
  }, []);

  const handleDownloadProgress = useCallback((progressEvent, item) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded / item.size) * 100
    );
    setDownloadProgress(percentCompleted);
  }, []);

  const handleDownload = useCallback(() => {
    closeContextMenu();
    setModal('download');

    return dispatch(
      Storage.downloadFile(itemInFocus, (progressEvent) =>
        handleDownloadProgress(progressEvent, itemInFocus)
      )
    ).then(({ value: objectUrl }) => {
      downloadFile({
        name: downloadLabel,
        objectUrl,
      });
      setModal('');
      setDownloadProgress(0);
    });
  }, [
    closeContextMenu,
    dispatch,
    downloadLabel,
    handleDownloadProgress,
    itemInFocus,
  ]);

  const handleView = useCallback(
    (item) => {
      closeContextMenu();
      setPreviewOpen(false);
      setModal('view-file');
      setItemViewerUrl(null);
      dispatch(
        Storage.downloadFile(item, (progressEvent) =>
          handleDownloadProgress(progressEvent, item)
        )
      ).then(({ value: objectUrl }) => {
        setItemViewerUrl(objectUrl);
      });
    },
    [closeContextMenu, dispatch, handleDownloadProgress]
  );

  const handleCloseView = useCallback(() => {
    setModal('');
    window.URL.revokeObjectURL(itemViewerUrl);
    setItemViewerUrl('');
    setDownloadProgress(0);
  }, [itemViewerUrl]);

  const handleClickItem = useCallback(
    (item) => {
      if (!item.isFolder) {
        setItemInFocus(item);
        return handleView(item);
      }

      handleClearSearch();
      setSearchParams({ folder: item.id });
      clearPreview();
    },
    [handleClearSearch, clearPreview, handleView, setSearchParams]
  );

  const handleClickInfo = useCallback((item) => {
    setModal('');
    setPreviewOpen(true);
    setItemInFocus(item);
  }, []);

  const handleDelete = () => {
    closeContextMenu();

    if (itemInFocus.isFolder) {
      setModal('delete-folder');
    } else {
      openConfirmDeleteFile({
        title: `Delete ${itemInFocus?.name}`,
        content: (
          <div style={{ textAlign: 'center' }}>
            Are you sure you want to delete this{' '}
            {`${itemInFocus?.isFolder ? 'folder' : 'file'}`}?
          </div>
        ),
        buttonText: 'Delete',
        buttonColor: 'error',
        onConfirm: handleConfirmDelete,
      });
    }
  };

  const handleCreateFolder = useCallback(() => {
    closeContextMenu();
    clearPreview();
    setModal('folder');
  }, [closeContextMenu, clearPreview]);

  const handleEditFolder = useCallback(() => {
    closeContextMenu();
    setPreviewOpen(false);
    setModal('folder');
  }, [closeContextMenu]);

  const handleConfirmDelete = useCallback(
    () =>
      dispatch(Storage.deleteItem(itemInFocus)).then(() => {
        setModal('');
        clearPreview();
      }),
    [dispatch, clearPreview, itemInFocus]
  );

  const handleRename = useCallback(() => {
    closeContextMenu();
    setPreviewOpen(false);
    setModal('rename');
  }, [closeContextMenu]);

  const handlePreview = useCallback(() => {
    closeContextMenu();
    setPreviewOpen(true);
  }, [closeContextMenu]);

  // Main effect.
  useEffect(() => {
    dispatch(Users.fetchUsers());
    if (debouncedSearchTerm) {
      dispatch(Storage.search(debouncedSearchTerm));
    } else {
      dispatch(Storage.fetchTree()).then(() => {
        dispatch(Storage.updateBreadcrumbs(folderId));
      });
    }
  }, [debouncedSearchTerm, folderId, dispatch]);

  // Direct route verification checking effect.
  useEffect(() => {
    if (!verified) {
      dispatch(Storage.requestVerification());
    }
  }, [verified, dispatch]);

  // Page visibility effect for when returning and its been more than 8 hours.
  useEffect(() => {
    const handleVisibilityChange = () => {
      const isExpired = !verificationExpiry || Date.now() >= verificationExpiry;

      if (isExpired && !document.hidden) {
        navigate('/', { replace: true });
        dispatch(Storage.requestVerification());
      }
    };

    if (enable2faOnSfs) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      if (enable2faOnSfs) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
    };
  }, [verificationExpiry, enable2faOnSfs, dispatch, navigate]);

  if (!verified) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container>
      <Header
        searchInputRef={searchInputRef}
        onChangeSearch={setSearchTerm}
        onClearSearch={handleClearSearch}
        onClickUploadFile={() => setModal('upload-file')}
        onClickCreateFolder={handleCreateFolder}
      />
      <Content>
        <FileListContainer>
          <Breadcrumbs
            searchTerm={debouncedSearchTerm.trim()}
            previewOpen={previewOpen}
          />
          <ItemList
            onClickItem={handleClickItem}
            onClickInfo={handleClickInfo}
            onContextMenu={handleOpenContextMenu}
            onClickSeeAll={handleClearSearch}
          />
          <ContextMenu
            open={contextMenuOpen}
            xPos={xPos}
            yPos={yPos}
            item={itemInFocus}
            onClose={closeContextMenu}
            onView={() => handleView(itemInFocus)}
            onEditAccess={handleEditFolder}
            onDownload={handleDownload}
            onRename={handleRename}
            onPreview={handlePreview}
            onDelete={handleDelete}
          />
        </FileListContainer>
        <FilePreview open={previewOpen} hasItem={Boolean(itemInFocus)}>
          {!!itemInFocus ? (
            <ItemInfo
              item={itemInFocus}
              isDownloading={modal === 'download'}
              onView={() => handleView(itemInFocus)}
              onEditAccess={handleEditFolder}
              onDelete={handleDelete}
              onDownload={handleDownload}
              onClose={clearPreview}
            />
          ) : (
            <Typography style={{ alignSelf: 'center' }} variant="h4">
              Select a file or folder.
            </Typography>
          )}
        </FilePreview>
      </Content>
      <FolderModal
        open={modal === 'folder'}
        onClose={() => setModal('')}
        item={itemInFocus}
      />
      <FileUploadModal
        open={modal === 'upload-file'}
        onClose={() => setModal('')}
        folderId={folderId}
      />
      {confirmDeleteFileElement}
      <DeleteFolderModal
        open={modal === 'delete-folder'}
        folderName={itemInFocus?.name}
        onConfirm={handleConfirmDelete}
        onClose={() => setModal('')}
      />
      <RenameFileModal
        open={modal === 'rename'}
        item={itemInFocus}
        onClose={() => setModal('')}
      />
      <DownloadModal
        open={modal === 'download'}
        onClose={() => setModal('')}
        label={downloadLabel}
        progress={downloadProgress}
      />
      <FileViewer
        open={modal === 'view-file'}
        onClose={handleCloseView}
        url={itemViewerUrl}
        item={itemInFocus}
        progress={downloadProgress}
      />
    </Container>
  );
};

export default FileStorage;
