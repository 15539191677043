import { useRef, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import PlusIcon from '../icons/Plus';

const Control = styled(Stack)`
  background-color: #fff;
  border-style: solid;
  border-radius: 6px;
  border-width: ${({ focus }) => (focus === 'true' ? '2px' : '1px')};
  border-color: ${({ theme, focus }) =>
    focus === 'true' ? theme.palette.primary.main : 'rgba(0,0,0,0.23)'};
  margin: ${({ focus }) => (focus === 'true' ? '-1px' : 0)};
  padding: ${({ theme }) => theme.spacing(1, 1.25)};
  line-height: 1;
  cursor: text;
  gap: 0.15rem;

  .MuiChip-root {
    height: 1.65rem;
  }

  .MuiInputBase-root {
    flex: 1;
    min-width: 175px;

    .MuiInputBase-input {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      padding: ${({ theme }) => theme.spacing(0.25, 0.5, 0.25, 0.5)};
    }
  }

  .MuiIconButton-root {
    padding: 1px;
    cursor: pointer;
  }
`;

const ChipListInput = ({ value, onChange, type = 'string', ...props }) => {
  const ref = useRef();
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAddItem = () => {
    onChange([...value, inputValue]);
    setInputValue('');
  };

  const handleDeleteItem = (item) => () => {
    onChange(value.filter((i) => i !== item));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(type === 'number' ? value.replace(/\D/g, '') : value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      handleAddItem();
    }
  };

  return (
    <Control
      direction="row"
      flexWrap="wrap"
      onClick={() => ref.current.select()}
      focus={focus.toString()}
      {...props}
    >
      {value.map((item) => (
        <Chip
          key={item}
          color="primary"
          label={item}
          onDelete={handleDeleteItem(item)}
        />
      ))}
      <InputBase
        size="small"
        inputRef={ref}
        value={inputValue}
        placeholder="Type and press Enter..."
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <Tooltip title="Add" disableInteractive>
        <span>
          <IconButton
            size="small"
            color="primary"
            disabled={!inputValue}
            onClick={handleAddItem}
          >
            <PlusIcon sx={{ width: '1rem', height: '1rem' }} />
          </IconButton>
        </span>
      </Tooltip>
    </Control>
  );
};

export default ChipListInput;
