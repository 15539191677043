import MuiAvatar from '@mui/material/Avatar';

import { avatarBackgroundColors } from '../lib/colors';
import defaultProfileImage from '../assets/icons/default-profile.svg';

const Avatar = ({
  firstName = '',
  lastName = '',
  profileImageUrl,
  showDefaultImage = false,
  fontSize = '1.2rem',
  size = 'smaller',
  sx = {},
  ...props
}) => {
  let dimensions = { width: '35px', height: '35px' };

  if (size === 'smallest') {
    dimensions = { width: '25px', height: '25px' };
  }

  if (size === 'small') {
    dimensions = { width: '40px', height: '40px' };
  }

  if (size === 'medium') {
    dimensions = { width: '150px', height: '150px' };
  }

  if (size === 'large') {
    dimensions = { width: '225px', height: '225px' };
  }

  const isNameless = !firstName && !lastName;
  const width = sx?.width || dimensions.width;
  const height = sx?.height || dimensions.height;
  const initials = isNameless
    ? undefined
    : `${firstName.substring(0, 1).toUpperCase()}${lastName
        .substring(0, 1)
        .toUpperCase()}`;
  const color =
    (firstName?.length + lastName?.length) % avatarBackgroundColors.length;

  return (
    <MuiAvatar
      src={
        showDefaultImage && isNameless ? defaultProfileImage : profileImageUrl
      }
      sx={{
        ...sx,
        width,
        height,
        fontSize,
        bgcolor:
          showDefaultImage && isNameless
            ? '#fff'
            : avatarBackgroundColors?.[color] || '#eee',
      }}
      {...props}
    >
      {initials}
    </MuiAvatar>
  );
};

export default Avatar;
