import SvgIcon from '@mui/material/SvgIcon';

const Trash = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M3.75 1.5A1.5 1.5 0 0 0 2.25 3v1.5A1.5 1.5 0 0 0 3.75 6h.75v13.5a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V6h.75a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5H15A1.5 1.5 0 0 0 13.5 0h-3A1.5 1.5 0 0 0 9 1.5H3.75Zm4.5 6a.75.75 0 0 1 .75.75v10.5a.75.75 0 1 1-1.5 0V8.25a.75.75 0 0 1 .75-.75Zm3.75 0a.75.75 0 0 1 .75.75v10.5a.75.75 0 1 1-1.5 0V8.25A.75.75 0 0 1 12 7.5Zm4.5.75v10.5a.75.75 0 1 1-1.5 0V8.25a.75.75 0 1 1 1.5 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Trash;
