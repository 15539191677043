import { createSelector } from 'reselect';

const selectMessagingAdminState = (state) => state.messagingAdmin;

export const selectFetchingMessagingAdmin = createSelector(
  selectMessagingAdminState,
  (messagingAdmin) => messagingAdmin.fetching
);

export const selectToastMessage = createSelector(
  selectMessagingAdminState,
  (messagingAdmin) => messagingAdmin.toastMessage
);

export const selectChannels = createSelector(
  selectMessagingAdminState,
  (messagingAdmin) =>
    messagingAdmin.channels.map((c) => ({
      ...c.channel,
      members: c.members,
    }))
);

export const selectChannel = createSelector(
  selectChannels,
  (_, cid) => cid,
  (channels, cid) => channels.find((c) => c.cid === cid)
);
