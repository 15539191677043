import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import {
  selectPopulationError,
  selectIsSubmittedOrCompleted,
} from '../../../store/welcome/welcomeSelectors';

const ConfirmationStep = () => {
  const populationError = useSelector(selectPopulationError);
  const isSubmittedOrCompleted = useSelector(selectIsSubmittedOrCompleted);

  return (
    <>
      {!!populationError && (
        <Typography paragraph color="error">
          {populationError}
        </Typography>
      )}
      {isSubmittedOrCompleted ? (
        <>
          <Typography paragraph>
            <b>Congratulations!</b> You have completed your Welcome Package.
          </Typography>
          <Typography paragraph>
            You can expect to receive a confirmation email shortly with a link
            to set your password.
          </Typography>
          <Typography paragraph>
            Please note that you will need to manually activate the newly
            created users from within ShadowHQ.
          </Typography>
        </>
      ) : (
        <>
          <Typography paragraph>You&apos;re all set!</Typography>
          <Typography paragraph>
            Please review your entries, as once the Welcome Package is
            submitted, it can no longer be changed.
          </Typography>
          <Typography paragraph>
            Click <b>Mark Complete</b> when you&apos;re ready.
          </Typography>
        </>
      )}
    </>
  );
};

export default ConfirmationStep;
