import { StreamChat } from 'stream-chat';

let clientInstance = null;

export const initializeStreamChat = (apiKey) => {
  if (StreamChat._instance) {
    delete StreamChat._instance;
  }

  clientInstance = StreamChat.getInstance(apiKey, {
    timeout: 60000,
  });

  return clientInstance;
};

export const resetStreamChatClient = () => {
  if (clientInstance) {
    // console.log('Resetting StreamChat client...');
    clientInstance.disconnectUser();
    clientInstance = null;
  }
};

export const getStreamChatClient = () => {
  if (!clientInstance) {
    console.error('StreamChat client has not been initialized.');
  }
  return clientInstance;
};
