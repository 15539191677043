export const getGroupChatLabel = (isWarRoom) =>
  Boolean(isWarRoom) ? 'War Room' : 'Breakout Room';

export const getChannelPrivilegesForMember = (member) => {
  const isOwner = member?.role === 'owner';
  const isMod = member?.channel_role === 'channel_moderator';
  const hasAdminRole = member?.channel_role === 'channel_admin';

  return {
    isOwner,
    isMod,
    isAdmin: isOwner || isMod || hasAdminRole,
  };
};
