import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  initializeStreamChat,
  resetStreamChatClient,
} from '../../lib/streamChatClient';
import {
  selectCurrentUserName,
  selectCurrentUserUUID,
} from '../../store/auth/authSelectors';
import SDK from '../../store/sdk/sdkActions';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectStreamApiKey } from '../../store/org/orgSelectors';
import { selectIsReady } from '../../store/heartbeat/heartbeatSelectors';
import * as streamChatEventHandlers from '../../lib/streamChatEventHandlers';

/**
 * Check the documentation for this component in the project README.
 */
const ChatClientManager = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(selectIsReady);
  const streamApiKey = useSelector(selectStreamApiKey);
  const uuid = useSelector(selectCurrentUserUUID);
  const name = useSelector(selectCurrentUserName);

  useEffect(() => {
    if (isReady && streamApiKey && uuid && name) {
      console.log('ChatClientManager mount: initialize');
      const client = initializeStreamChat(streamApiKey);
      dispatch(SDK.initialize());

      // Connect the user to the chat.
      console.log('ChatClientManager: connect');
      client.connectUser({ id: uuid, name }, async () => {
        const { data } = await axiosInstance.get('/stream/accessToken');
        return data.accessToken;
      });
      dispatch(SDK.setConnected(true));

      // Subscribe to events.
      client.on(
        'connection.changed',
        streamChatEventHandlers.onConnectionChanged
      );
      client.on('channel.updated', streamChatEventHandlers.onChannelUpdated);
      client.on('channel.visible', streamChatEventHandlers.onChannelVisible);
      client.on('channel.hidden', streamChatEventHandlers.onChannelHidden);
      client.on('channel.deleted', streamChatEventHandlers.onChannelDeleted);
      client.on('member.added', streamChatEventHandlers.onMemberAdded);
      client.on('member.removed', streamChatEventHandlers.onMemberRemoved);
      client.on('message.new', streamChatEventHandlers.onMessageNew);
      client.on('message.updated', streamChatEventHandlers.onMessageUpdated);
      client.on('message.read', streamChatEventHandlers.onMessageRead);
      client.on('typing.start', streamChatEventHandlers.onTypingStart);
      client.on('typing.stop', streamChatEventHandlers.onTypingStop);
      client.on(
        'notification.added_to_channel',
        streamChatEventHandlers.onNotificationAddedToChannel
      );
      client.on(
        'notification.removed_from_channel',
        streamChatEventHandlers.onNotificationRemovedFromChannel
      );
      client.on(
        'notification.message_new',
        streamChatEventHandlers.onNotificationMessageNew
      );

      return () => {
        console.log('ChatClientManager unmount: disconnect');

        // Unsubscribe from events.
        client.off(
          'connection.changed',
          streamChatEventHandlers.onConnectionChanged
        );
        client.off('channel.updated', streamChatEventHandlers.onChannelUpdated);
        client.off('channel.visible', streamChatEventHandlers.onChannelVisible);
        client.off('channel.hidden', streamChatEventHandlers.onChannelHidden);
        client.off('channel.deleted', streamChatEventHandlers.onChannelDeleted);
        client.off('member.added', streamChatEventHandlers.onMemberAdded);
        client.off('member.removed', streamChatEventHandlers.onMemberRemoved);
        client.off('message.new', streamChatEventHandlers.onMessageNew);
        client.off('message.updated', streamChatEventHandlers.onMessageUpdated);
        client.off('message.read', streamChatEventHandlers.onMessageRead);
        client.off('typing.start', streamChatEventHandlers.onTypingStart);
        client.off('typing.stop', streamChatEventHandlers.onTypingStop);
        client.off(
          'notification.added_to_channel',
          streamChatEventHandlers.onNotificationAddedToChannel
        );
        client.off(
          'notification.removed_from_channel',
          streamChatEventHandlers.onNotificationRemovedFromChannel
        );
        client.off(
          'notification.message_new',
          streamChatEventHandlers.onNotificationMessageNew
        );

        // Disconnect the user, and reset the client instance.
        resetStreamChatClient();
        dispatch(SDK.setConnected(false));
      };
    }
  }, [isReady, streamApiKey, uuid, name, dispatch]);

  return null;
};

export default ChatClientManager;
