import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import PlusIcon from '../../icons/Plus';
import DataGridFilter from '../../DataGridFilter';

const Toolbar = ({ onClickCreate, onClickAddStreamCreds, ...props }) => (
  <Stack {...props} direction="row" justifyContent="space-between" gap={1}>
    <Stack direction="row" gap={1}>
      <Button
        variant="contained"
        startIcon={<PlusIcon />}
        onClick={onClickCreate}
      >
        Create Organization
      </Button>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<PlusIcon />}
        onClick={onClickAddStreamCreds}
      >
        Add Stream Creds
      </Button>
    </Stack>
    <DataGridFilter
      autoFocus
      placeholder="Filter organizations..."
      sx={{
        alignSelf: 'center',
        '.MuiInputBase-root': { backgroundColor: '#fff' },
      }}
    />
  </Stack>
);

export default Toolbar;
