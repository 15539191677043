import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';

import PaperClip from '../../../assets/icons/attach-outline.svg?react';
import Close from '../../../assets/icons/close.svg?react';
import PaperPlane from '../../../assets/icons/paper-plane-outline.svg?react';

const ChooseFile = styled.label`
  display: flex;
  color: #222;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  width: 38px;
  margin-bottom: 0;

  svg {
    color: rgba(0, 0, 0, 0.54);
  }

  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const RichTextButton = styled(Button)`
  height: 38px;
  width: 38px;

  svg {
    color: ${({ disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.54)'};
  }
`;

export default function ActionButtons({
  hasFile,
  canSend,
  handleChoose,
  handleClear,
  handleSend,
  handleCloseEditor,
  handleEmoji,
  isEdit,
}) {
  return (
    <ButtonGroup size="small" style={{ float: 'right' }}>
      <RichTextButton variant="text" onClick={handleEmoji}>
        &#x1F600;
      </RichTextButton>
      {!isEdit &&
        (hasFile ? (
          <RichTextButton variant="text" onClick={handleClear}>
            <SvgIcon fontSize="small">
              <Close />
            </SvgIcon>
          </RichTextButton>
        ) : (
          <ChooseFile htmlFor="choose-file">
            <SvgIcon fontSize="small">
              <PaperClip />
            </SvgIcon>
            <input
              type="file"
              id="choose-file"
              onChange={handleChoose}
              style={{ display: 'none' }}
            />
          </ChooseFile>
        ))}
      <RichTextButton variant="text" onClick={handleSend} disabled={!canSend}>
        <SvgIcon fontSize="small">
          <PaperPlane />
        </SvgIcon>
      </RichTextButton>
      {isEdit && handleCloseEditor && (
        <RichTextButton variant="text" onClick={handleCloseEditor}>
          <SvgIcon fontSize="small">
            <Close />
          </SvgIcon>
        </RichTextButton>
      )}
    </ButtonGroup>
  );
}
