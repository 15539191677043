import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { timeFromNow } from '../../../lib/date';
import useInterval from '../../../lib/hooks/useInterval';
import { managementApi } from '../../../configs/managementApi';

import DataGrid from '../ManagementDataGrid';
import DataGridFilter from '../../DataGridFilter';
import FireDrillIcon from '../../../assets/icons/cast-outline.svg?react';
import ResolvedIcon from '../../../assets/icons/success-circle.svg?react';
import OngoingIncidentIcon from '../../../assets/icons/warning-triangle.svg?react';

const getColor = ({ theme, status }) =>
  status === 'SAFE'
    ? theme.palette.success.light
    : status === 'INCIDENT_IN_PROGRESS'
      ? theme.palette.error.light
      : status === 'TABLETOP_IN_PROGRESS'
        ? theme.palette.warning.main
        : theme.palette.success.light;

const Icon = styled(SvgIcon)`
  color: ${({ theme, status }) => getColor({ theme, status })};
`;

const StatusLabel = styled(Typography)`
  line-height: 1;
  color: ${({ theme, status }) => getColor({ theme, status })};
`;

const IncidentsByOrg = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    rows: [],
    loading: false,
  });

  const isOnPage = location.pathname === '/management/incidents';

  const handleRowClick = (row) => navigate(`/management/incidents/${row.id}`);

  // Fetch incidents by organization.
  const fetchData = useCallback(() => {
    setData((prev) => ({ ...prev, loading: true }));
    managementApi.get('/enterprises/incidents').then(({ data }) => {
      setData({
        loading: false,
        rows: data.map((o) => ({
          ...o,
          id: o.enterprise.id,
          name: o.enterprise.displayName,
        })),
      });
    });
  }, []);

  // Data fetching effect.
  useEffect(() => {
    if (isOnPage) {
      fetchData();
    }
  }, [isOnPage, fetchData]);

  // Fetch data every minute.
  useInterval(fetchData, isOnPage ? 60 * 1000 : null);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <DataGrid
        rows={data.rows}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
          },
          {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: ({ row }) => {
              const { status } = row;
              const icon =
                status === 'SAFE'
                  ? ResolvedIcon
                  : status === 'INCIDENT_IN_PROGRESS'
                    ? OngoingIncidentIcon
                    : status === 'TABLETOP_IN_PROGRESS'
                      ? FireDrillIcon
                      : ResolvedIcon;

              return (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Icon
                    component={icon}
                    status={status}
                    sx={{
                      width: '1.25rem',
                      height: '1.25rem',
                    }}
                  />
                  <StatusLabel component="span" status={status}>
                    {status === 'SAFE' ? (
                      'Safe'
                    ) : status === 'INCIDENT_IN_PROGRESS' ? (
                      'Incident In Progress'
                    ) : status === 'TABLETOP_IN_PROGRESS' ? (
                      <>
                        Tabletop Exercise
                        <br />
                        In Progress
                      </>
                    ) : (
                      'Safe'
                    )}
                  </StatusLabel>
                </Stack>
              );
            },
          },
          {
            field: 'duration',
            headerName: 'Duration',
            flex: 1,
            valueGetter: ({ row }) => {
              const { lastIncidentTimestamp } = row;
              return !lastIncidentTimestamp
                ? 'N/A'
                : timeFromNow(dayjs().diff(lastIncidentTimestamp));
            },
          },
          {
            field: 'title',
            headerName: 'Title',
            flex: 2,
            valueGetter: ({ row }) => {
              const { title } = row;
              return !title ? 'N/A' : title;
            },
          },
          {
            field: 'checkIns',
            headerName: 'Check-Ins',
            flex: 1,
            valueGetter: ({ row }) => {
              const { status, checkIns, currentUsers } = row;
              return status === 'SAFE' ? 'N/A' : `${checkIns}/${currentUsers}`;
            },
          },
          {
            field: 'updates',
            headerName: 'Updates',
            flex: 1,
            valueGetter: ({ row }) => {
              const { status, updates } = row;
              return status === 'SAFE' ? 'N/A' : updates;
            },
          },
          {
            field: 'tasks',
            headerName: 'Tasks',
            flex: 1,
            valueGetter: ({ row }) => {
              const { status, tasks } = row;
              return status === 'SAFE' ? 'N/A' : tasks;
            },
          },
          {
            field: 'attachments',
            headerName: 'Attachments',
            flex: 1.25,
            valueGetter: ({ row }) => {
              const { status, attachments } = row;
              return status === 'SAFE' ? 'N/A' : attachments;
            },
          },
        ]}
        pagination
        autoPageSize
        rowHeight={42}
        headerHeight={42}
        disableColumnMenu
        disableColumnFilter
        loading={data.loading}
        disableSelectionOnClick
        disableMultipleColumnsSorting
        onRowClick={({ row }) => handleRowClick(row)}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'status',
                direction: 'asc',
              },
            ],
          },
        }}
        localeText={{
          noRowsLabel: 'No organizations to show.',
          footerRowSelected: () => '',
        }}
        components={{
          Toolbar: () => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={0.5}
              sx={{ mb: 2 }}
            >
              <Typography variant="h5" lineHeight={1}>
                Incidents by Organization
              </Typography>
              <DataGridFilter
                autoFocus
                debounceMs={500}
                placeholder="Filter organizations..."
                sx={{
                  alignSelf: 'center',
                  '.MuiInputBase-root': { backgroundColor: '#fff' },
                }}
              />
            </Stack>
          ),
        }}
      />
    </Box>
  );
};

export default IncidentsByOrg;
