import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectPageTabs } from '../store/org/orgSelectors';

import NavigationTabs from './Tabs';

const PageTabs = () => {
  const { pathname } = useLocation();
  const tabs = useSelector((state) => selectPageTabs(state, pathname));

  if (!tabs.length) {
    return null;
  }

  return (
    <NavigationTabs
      tabs={tabs}
      color="secondary"
      textColor="secondary"
      indicatorColor="secondary"
      sx={{ pb: 1 }}
    />
  );
};

export default PageTabs;
