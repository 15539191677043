import { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';

import { handlePaste } from '../../../lib/input';
import { TASK_PRIORITY } from '../../../store/incident/incidentTypes';
import { MAX_LENGTH, MAX_LONG_LENGTH } from '../../../configs/validation';

import TaskLayout from './TaskLayout';
import EditItemControl from '../EditItemControl';
import PrioritySelect from '../../PrioritySelect';

const TaskForm = ({
  title,
  description,
  priority = TASK_PRIORITY.MEDIUM,
  errors,
  onChange,
  onClickSave,
  onClickCancel,
}) => {
  const ref = useRef();

  // Scrolling effect.
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <TaskLayout
      ref={ref}
      isEditable
      headerComponent={
        <>
          <TextField
            autoFocus
            value={title}
            variant="outlined"
            placeholder="Task Title"
            onChange={(e) => onChange('title', e.target.value)}
            error={!!errors?.title}
            helperText={errors?.title}
            inputProps={{ maxLength: MAX_LENGTH.value }}
            onPaste={(e) =>
              handlePaste(
                e,
                (value) => onChange('title', value),
                MAX_LENGTH.value
              )
            }
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-input': {
                p: 0,
                fontSize: '1rem',
              },
              '& .MuiOutlinedInput-notchedOutline': { border: 'none ' },
              '& .Mui-error': {
                mx: 0,
              },
            }}
          />
          <PrioritySelect
            value={priority}
            onChange={(e) => onChange('priority', e.target.value)}
            sx={{ flexShrink: 0 }}
          />
        </>
      }
      bodyComponent={
        <TextField
          multiline
          minRows={3}
          value={description}
          variant="outlined"
          placeholder="Enter task description..."
          onChange={(e) => onChange('description', e.target.value)}
          inputProps={{ maxLength: MAX_LONG_LENGTH.value }}
          onPaste={(e) =>
            handlePaste(
              e,
              (value) => onChange('description', value),
              MAX_LONG_LENGTH.value
            )
          }
          sx={{
            '& .MuiInputBase-root': {
              p: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 'none ' },
          }}
        />
      }
      rightComponent={
        <EditItemControl
          onClickSave={onClickSave}
          onClickCancel={onClickCancel}
        />
      }
    />
  );
};

export default TaskForm;
