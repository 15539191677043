import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUsers,
  selectMaxUsers,
} from '../../../store/welcome/welcomeSelectors';
import useToast from '../../../lib/hooks/useToast';
import { capitalize } from '../../../utils/stringUtils';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';
import WelcomePackage from '../../../store/welcome/welcomeActions';

import TrashIcon from '../../icons/Trash';
import PencilIcon from '../../icons/Pencil';
import UserModal from '../../user-admin/UserModal';
import ImportUsersModal from '../../user-admin/ImportUsersModal';
import ManagementDataGrid from '../../management-console/ManagementDataGrid';

const MODALS = {
  EDIT: 'EDIT',
  IMPORT: 'IMPORT',
};

const UsersStep = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const maxUsers = useSelector(selectMaxUsers);
  const [modal, setModal] = useState('');
  const [toastElement, openToast] = useToast();
  const [userInFocus, setUserInFocus] = useState(null);
  const [confirmDeleteElement, openConfirmDelete] = useConfirmAsync();

  const userAllowance = maxUsers - users.length;

  const handleClickEdit = (user) => () => {
    if (!!user) {
      setUserInFocus(user);
    }
    setModal(MODALS.EDIT);
  };

  const handleClickImport = () => setModal(MODALS.IMPORT);

  const handleClickDelete = (user) => () => {
    openConfirmDelete({
      title: 'Delete User',
      content: (
        <Typography align="center">
          Are you sure you want to delete{' '}
          <b>{`${user.firstName} ${user.lastName}`}</b>?
        </Typography>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () => dispatch(WelcomePackage.removeUser(user)),
    });
  };

  const handleCloseModal = useCallback(() => {
    setUserInFocus(null);
    setModal('');
  }, []);

  const handleImport = useCallback(
    (users) => Promise.resolve(dispatch(WelcomePackage.importUsers(users))),
    [dispatch]
  );

  /**
   * @param {object} data - The user data to save.
   * @returns {boolean} - Representing if the save was successfully.
   */
  const handleSave = useCallback(
    (data) => {
      if (userInFocus) {
        return Promise.resolve(dispatch(WelcomePackage.editUser(data)));
      }

      if (!!users.find((u) => u.email === data.email)) {
        return Promise.reject({
          response: {
            data: 'A user with this email address already exists.',
          },
        });
      }

      return Promise.resolve(dispatch(WelcomePackage.addUser(data)));
    },
    [dispatch, userInFocus, users]
  );

  const handleSaveRejected = useCallback(
    (data) => openToast(data, 'error'),
    [openToast]
  );

  return (
    <>
      <Typography paragraph>
        Please setup your ShadowHQ team. You can use{' '}
        <a
          href="/shadowhq-user-list-template.csv"
          download="ShadowHQ-User-List-Template.csv"
          target="_blank"
          rel="noreferrer"
        >
          this CSV template
        </a>{' '}
        to import your team members.
      </Typography>

      <Box sx={{ flex: 1, height: 330, mt: 1 }}>
        <ManagementDataGrid
          rows={users}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 1.25,
              valueGetter: ({ row }) =>
                `${row.firstName || ''} ${row.lastName || ''}`,
            },
            {
              field: 'email',
              headerName: 'Email',
              flex: 1.75,
            },
            {
              field: 'role',
              headerName: 'Role',
              flex: 0.5,
              valueFormatter: ({ value }) => capitalize(value),
            },
            {
              field: 'type',
              headerName: 'Type',
              flex: 0.5,
              valueFormatter: ({ value }) => capitalize(value),
            },
            {
              field: 'actions',
              headerName: 'Actions',
              headerAlign: 'center',
              align: 'center',
              flex: 0.5,
              sortable: false,
              renderCell: ({ row }) => {
                const disabled = Boolean(row?.isMainAdminUser);

                return (
                  <Stack direction="row" gap={0.5}>
                    <Tooltip arrow title="Edit" disableInteractive>
                      <span>
                        <IconButton
                          disabled={disabled}
                          onClick={handleClickEdit(row)}
                        >
                          <PencilIcon
                            fontSize="small"
                            color={disabled ? 'default' : 'primary'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Delete" disableInteractive>
                      <span>
                        <IconButton
                          disabled={disabled}
                          onClick={handleClickDelete(row)}
                        >
                          <TrashIcon
                            fontSize="small"
                            color={disabled ? 'default' : 'error'}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                );
              },
            },
          ]}
          autoPageSize
          disableFilter
          density="compact"
          disableColumnMenu
          disableSelectionOnClick
          components={{
            Toolbar: () => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                sx={{ mb: 1 }}
              >
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    onClick={handleClickEdit()}
                    disabled={users.length - 1 >= maxUsers}
                  >
                    Create User
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClickImport}
                    disabled={users.length - 1 >= maxUsers}
                  >
                    Import Users
                  </Button>
                </Stack>
                <Typography variant="body" color="grey">
                  Maximum Number of Users: {maxUsers}
                </Typography>
              </Stack>
            ),
          }}
          localeText={{
            noRowsLabel: 'No users created yet.',
          }}
        />
      </Box>
      <ImportUsersModal
        open={modal === MODALS.IMPORT}
        userAllowance={userAllowance}
        onClose={handleCloseModal}
        onImport={handleImport}
      >
        <Typography color="error">
          <b>WARNING:</b> This import will cause all previously created users to
          be overwritten.
        </Typography>
      </ImportUsersModal>
      <UserModal
        open={modal === MODALS.EDIT}
        user={userInFocus}
        onSave={handleSave}
        onClose={handleCloseModal}
        onSaveRejected={handleSaveRejected}
      />
      {toastElement}
      {confirmDeleteElement}
    </>
  );
};

export default UsersStep;
