import { v4 as uuidv4 } from 'uuid';
import { AUTH_TYPES } from '../auth/authReducer';

const initState = {
  chatList: [],
  hiddenChatList: [],
  fetchingChats: false,
  fetchedChats: false,
  currentChat: {},
  currentMembers: {},
  attachments: [],
  typingEvents: {},
  currentTop: 1,
  exportData: {},
  error: null,
  newChatForm: '',
  readReceiptList: [],
};

export const CHAT_TYPES = {
  FETCH_CHATS: '@@chats/FETCH_CHATS',
  FETCH_ATTACHMENTS: '@@chats/FETCH_ATTACHMENTS',
  REFRESH_CHATS: '@@chats/REFRESH_CHATS',
  NEW_CHAT: '@@chats/NEW_CHAT',
  ADD_CHAT: '@@chats/ADD_CHAT',
  ADD_ATTACHEMENT: '@@chats/ADD_ATTACHEMENT',
  UPDATE_CHAT: '@@chats/UPDATE_CHAT',
  UPDATE_MEMBERS: '@@chats/UPDATE_CHAT_MEMBERS',
  PROMOTE_USER: '@@chats/PROMOTE_MEMBER',
  DEMOTE_USER: '@@chats/DEMOTE_USER',
  REMOVE_CHAT: '@@chats/REMOVE_CHAT',
  SET_CURRENT_CHAT: '@@chats/SET_CURRENT_CHAT',
  ADD_USERS: '@@chats/ADD_USERS',
  REMOVE_USER: '@@chats/REMOVE_USER',
  SET_CURRENT_TOP: '@@chats/SET_CURRENT_TOP',
  START_TYPING: '@@chats/START_TYPING',
  STOP_TYPING: '@@chats/STOP_TYPING',
  EXPORT: '@@chats/EXPORT',
  CLEAR_EXPORT_DATA: '@@chats/CLEAR_EXPORT_DATA',
  ERROR: '@@chats/ERROR',
  TOGGLE_NEW_CHAT_FORM: '@@chats/TOGGLE_NEW_CHAT_FORM',
  READ_RECEIPT_LIST: '@@chats/READ_RECEIPT_LIST',
  REMOVE_RECEIPT_LIST: '@@chats/REMOVE_RECEIPT_LIST',
};

export default function chats(state = initState, action) {
  switch (action.type) {
    case `${CHAT_TYPES.FETCH_CHATS}_PENDING`:
      return {
        ...state,
        fetchingChats: true,
      };
    case `${CHAT_TYPES.FETCH_CHATS}_FULFILLED`:
      return {
        ...state,
        chatList: action.payload,
        fetchingChats: false,
        fetchedChats: true,
      };
    case `${CHAT_TYPES.FETCH_CHATS}_REJECTED`:
      return {
        ...state,
        fetchingChats: false,
        fetchedChats: true,
        error: action.payload,
      };
    case `${CHAT_TYPES.FETCH_ATTACHMENTS}_PENDING`:
      return {
        ...state,
        attachments: [],
      };
    case `${CHAT_TYPES.FETCH_ATTACHMENTS}_FULFILLED`: {
      const { results } = action.payload;

      return {
        ...state,
        attachments: results.map((r) => ({
          ...r.message.attachments[0],
          id: uuidv4(),
          date: r.message.created_at,
        })),
      };
    }
    case `${CHAT_TYPES.FETCH_ATTACHMENTS}_REJECTED`:
      return {
        ...state,
        error: action.payload,
      };
    case CHAT_TYPES.ADD_ATTACHEMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
      };
    case CHAT_TYPES.TOGGLE_NEW_CHAT_FORM:
      return {
        ...state,
        newChatForm: action.payload,
      };
    case CHAT_TYPES.NEW_CHAT:
    case CHAT_TYPES.ADD_CHAT:
      return {
        ...state,
        chatList: [...state.chatList, action.payload],
        attachments: [],
      };
    case CHAT_TYPES.REMOVE_CHAT:
      return {
        ...state,
        chatList: state.chatList.filter((c) => c.cid !== action.payload),
        currentChat:
          state.currentChat.cid === action.payload ? {} : state.currentChat,
        currentMembers:
          state.currentChat.cid === action.payload ? [] : state.currentMembers,
      };
    case CHAT_TYPES.UPDATE_CHAT: {
      const nextChat = action.payload;
      const { currentChat, chatList } = state;
      const idx = chatList.findIndex((c) => nextChat.cid === c.cid);

      return {
        ...state,
        chatList: [
          ...state.chatList.slice(0, idx),
          nextChat,
          ...state.chatList.slice(idx + 1),
        ],
        currentChat: nextChat.cid === currentChat.cid ? nextChat : currentChat,
      };
    }
    case CHAT_TYPES.UPDATE_MEMBERS:
      return {
        ...state,
        currentMembers: { ...action.payload },
      };
    case CHAT_TYPES.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.payload,
        currentMembers: { ...action.payload.state.members },
        readReceiptList: [
          ...Object.values(action.payload.state.read)
            .filter((m) => m.unread_messages === 0)
            .map((data) => `${data.user.name}`),
        ],
      };
    case `${CHAT_TYPES.ADD_USERS}_PENDING`:
      return {
        ...state,
      };
    case `${CHAT_TYPES.ADD_USERS}_FULFILLED`:
      return {
        ...state,
      };
    case `${CHAT_TYPES.REMOVE_USER}_PENDING`:
      return {
        ...state,
      };
    case `${CHAT_TYPES.REMOVE_USER}_FULFILLED`:
      return {
        ...state,
      };
    case CHAT_TYPES.SET_CURRENT_TOP:
      return {
        ...state,
        currentTop: action.payload,
      };
    case CHAT_TYPES.START_TYPING:
      return {
        ...state,
        typingEvents: {
          ...state.typingEvents,
          [action.payload.cid]: action.payload.typing,
        },
      };
    case CHAT_TYPES.STOP_TYPING: {
      const { typingEvents } = state;
      const { cid, typing } = action.payload;

      let nextTypingEvents = { ...typingEvents };
      if (typing.length === 0) {
        nextTypingEvents = Object.keys(typingEvents)
          .filter((k) => k !== cid)
          .reduce((acc, k) => ({ ...acc, [k]: typingEvents[k] }), {});
      } else {
        nextTypingEvents = {
          ...typingEvents,
          [cid]: typing,
        };
      }

      return {
        ...state,
        typingEvents: nextTypingEvents,
      };
    }
    case `${CHAT_TYPES.EXPORT}_PENDING`:
      return {
        ...state,
        exportData: {},
      };
    case `${CHAT_TYPES.EXPORT}_FULFILLED`: {
      const { receivedFromStream } = action.payload.data;

      return {
        ...state,
        exportData: { ...receivedFromStream[0] },
      };
    }
    case CHAT_TYPES.CLEAR_EXPORT_DATA:
      return {
        ...state,
        exportData: {},
      };
    case CHAT_TYPES.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CHAT_TYPES.READ_RECEIPT_LIST: {
      return {
        ...state,
        readReceiptList: [...state.readReceiptList, action.payload],
      };
    }
    case CHAT_TYPES.REMOVE_RECEIPT_LIST: {
      return {
        ...state,
        readReceiptList: [],
      };
    }
    case AUTH_TYPES.CLEAR:
      return initState;
    default:
      return state;
  }
}
