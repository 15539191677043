import { createSelector } from 'reselect';

import { formattedDate } from '../../lib/date';
import { userCompare } from '../../utils/sortUtils';
import { selectUserList } from '../user/userSelectors';
import { selectCurrentUser } from '../auth/authSelectors';

// Just here to make refactoring easier
const TYPES = {
  DM: 'messaging',
  GROUP: 'team',
};

function groupByDay(messages) {
  // const pages = [...messages].reverse();
  const dayGroups = {};
  const pages = messages.reverse();
  let group = [];
  let lastMessage = pages[0];
  pages.forEach((message, idx) => {
    const lastDate = formattedDate(lastMessage.created_at);
    const currentDate = formattedDate(message.created_at);

    if (idx === 0) {
      group = [pages[idx]];
    } else if (lastDate === currentDate) {
      group = [...group, message];
    } else {
      dayGroups[lastDate] = group;
      group = [message];
    }

    if (idx === pages.length - 1) {
      dayGroups[currentDate] = group;
    }

    lastMessage = message;
  });

  return dayGroups;
}

function groupByUser(messagesByDay) {
  const groupByDayAndUser = {};

  Object.keys(messagesByDay).forEach((day) => {
    const dayGroup = messagesByDay[day];

    let userGroups = [];
    let group = [];
    let lastMessage = null;
    dayGroup.forEach((message, idx) => {
      if (idx === 0) {
        group = [message];
      } else if (lastMessage.user.id === message.user.id) {
        group = [message, ...group];
      } else {
        userGroups = [...userGroups, group];
        group = [message];
      }

      if (idx === dayGroup.length - 1) {
        userGroups = [...userGroups, group];
      }

      lastMessage = message;
    });

    groupByDayAndUser[day] = userGroups;
  });

  return groupByDayAndUser;
}

const selectChatIdProp = (_, props) => props.chatId;

export const selectChatsState = (state) => state.chats;

export const selectChatsList = createSelector(
  selectChatsState,
  (state) => state.chatList
);

export const selectIsFetching = createSelector(
  selectChatsState,
  (state) => !state.fetchedChats && state.fetchingChats
);

export const selectDirectChats = createSelector(selectChatsList, (chats) =>
  chats
    .filter((c) => c.type === TYPES.DM)
    .sort((a, b) => {
      const aDate = new Date(a.state.last_message_at);
      const bDate = new Date(b.state.last_message_at);

      if (aDate.getTime() < bDate.getTime()) {
        return 1;
      }
      if (aDate.getTime() > bDate.getTime()) {
        return -1;
      }
      return 0;
    })
);

export const selectWarRooms = createSelector(selectChatsList, (chats) =>
  chats
    ?.filter((c) => c.type === TYPES.GROUP && c.data.isWarRoom)
    .sort((a, b) => {
      if (a.data.name > b.data.name) {
        return 1;
      }
      if (a.data.name < b.data.name) {
        return -1;
      }
      return 0;
    })
    .reduce(
      (acc, c) => (c.data.name === 'Headquarters' ? [c, ...acc] : [...acc, c]),
      []
    )
);

export const selectGroupChats = createSelector(selectChatsList, (chats) =>
  chats
    ?.filter((c) => c.type === TYPES.GROUP && !c.data.isWarRoom)
    .sort((a, b) => {
      if (a.data.name > b.data.name) {
        return 1;
      }
      if (a.data.name < b.data.name) {
        return -1;
      }
      return 0;
    })
);

export const selectCurrentChat = createSelector(
  selectChatsState,
  (state) => state?.currentChat
);

export const selectCurrentChatState = createSelector(
  selectChatsState,
  (state) => state?.currentChatState
);

export const selectMessages = createSelector(
  selectCurrentChat,
  (c) => c.messages
);

export const selectGroupMessagesByDay = createSelector(
  selectMessages,
  (messages) => groupByUser(groupByDay(messages))
);

export const selectTypingEvents = createSelector(
  selectChatsState,
  (state) => state.typingEvents
);

export const selectTypingEvent = createSelector(
  selectTypingEvents,
  selectChatIdProp,
  (typingEvents, chatId) => typingEvents[chatId]
);

export const selectIsTyping = createSelector(
  selectTypingEvents,
  selectCurrentChat,
  (typingEvents, currentChat) => Boolean(typingEvents[currentChat.chatId])
);

export const selectCurrentChatMembers = createSelector(
  selectChatsState,
  (chatState) => chatState.currentMembers || []
);

export const selectIsOwner = createSelector(
  selectCurrentChatMembers,
  (_, props) => props.uuid,
  (members, uuid) => {
    const member = members[uuid];

    return member?.role === 'owner';
  }
);

export const selectIsMod = createSelector(
  selectCurrentChatMembers,
  (_, props) => props.uuid,
  (members, uuid) => {
    const member = members[uuid];

    return member?.channel_role === 'channel_moderator';
  }
);

export const selectAttachments = createSelector(
  selectChatsState,
  (chatState) => chatState.attachments || []
);

export const selectGroupMembers = createSelector(
  selectCurrentChatMembers,
  selectUserList,
  (members, users) =>
    Object.keys(members)
      .map((m) => {
        // Look for the user in the Cue list
        const user = users.find((u) => u.userProperties.uuid === m);

        // If they're not found, likely they were removed but still
        // have an account in Stream. Use that information instead.
        if (!user) {
          const splitName = members[m].user.name.split(' ');

          return {
            userProperties: { uuid: m },
            firstName: splitName[0] || 'Former', // In case the name isn't even in Stream
            lastName: splitName[1] || 'Member',
            active: false, // Flag as not in Cue any more.
          };
        }

        const admin = ['channel_moderator', 'channel_admin'].includes(
          members[m].channel_role
        );
        const owner = ['owner'].includes(members[m].role);

        return { ...user, isAdmin: admin || owner };
      })
      .sort(userCompare)
      .sort((a, b) => Number(b.active) - Number(a.active))
);

export const selectCurrentChatMembersArray = createSelector(
  selectCurrentChatMembers,
  (m) => Object.values(m)
);

export const selectCurrentChatUsers = createSelector(
  selectCurrentChatMembers,
  selectUserList,
  (members, users) =>
    Object.values(members).map((p) =>
      users.find((u) => p.user_id === u.userProperties.uuid)
    )
);

export const selectIsOneToOne = createSelector(
  selectCurrentChat,
  (chat) =>
    Object.keys(chat.state.members).length === 2 && chat.type === 'messaging'
);

export const selectLastOutgoing = createSelector(
  selectMessages,
  selectCurrentUser,
  (messages, currentUser) =>
    [...messages].reverse().find((m) => m.user.id === currentUser.uuid)
);

export const selectFetchChats = createSelector(
  selectChatsState,
  (state) => !state.fetchingChats && !state.fetchedChats
);

export const selectChatMembers = createSelector(
  selectCurrentChat,
  selectUserList,
  selectCurrentUser,
  (chat, users, currentUser) => {
    const memberIds = Object.keys(chat.state.members);

    return memberIds
      .filter((m) => m !== currentUser.uuid)
      .map((p) => {
        const defaultUser = {
          userProperties: { uuid: -1 },
          firstName: 'Former',
          lastName: 'Member',
          active: false,
        };
        let user = users.find((u) => u.userProperties.uuid === p);

        if (user) {
          if (user.userProperties.type === 'external') {
            user = {
              ...user,
              lastName: `${user.lastName} (External)`,
            };
          }

          if (!user.active) {
            user = {
              ...user,
              lastName: `${user.lastName} (Inactive)`,
            };
          }
        }

        return user || defaultUser;
      });
  }
);

export const selectChatMemberNames = createSelector(
  selectChatMembers,
  (members) =>
    members.sort(userCompare).map((m) => `${m.firstName} ${m.lastName}`)
);

export const selectReadReceipt = createSelector(
  selectChatsState,
  selectCurrentUser,
  (state, currentUser) =>
    state.readReceiptList.filter(
      (u) => u !== `${currentUser.user.firstName} ${currentUser.user.lastName}`
    )
);

export const selectIsActive = createSelector(
  selectCurrentChat,
  (_, props) => props.chat,
  (currentChat, chat) => currentChat.cid === chat.cid
);

export const selectUnreadCounts = createSelector(selectChatsList, (chats) =>
  chats.reduce(
    (acc, chat) =>
      chat.state.unreadCount > 0
        ? { ...acc, [chat.cid]: chat.state.unreadCount }
        : acc,
    {}
  )
);

export const selectChatError = createSelector(
  selectChatsState,
  (state) => state.error
);

export const selectCallTitleByChatId = createSelector(
  selectUserList,
  selectChatsList,
  (_, cid) => cid,
  (users, chats, cid) => {
    const callChat = chats.find((c) => c.cid === cid);
    if (!callChat) {
      return '';
    }

    if (callChat.data.type === 'team') {
      return callChat.data.name;
    }

    return Object.keys(callChat.state.members)
      .map((memberId) => {
        const user = users.find((u) => u.userProperties.uuid === memberId);
        return !!user ? user.firstName : null;
      })
      .filter((u) => !!u)
      .join(', ');
  }
);

export const selectNewChatForm = createSelector(
  selectChatsState,
  (state) => state.newChatForm
);
