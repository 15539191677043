import { AUTH_TYPES } from '../auth/authReducer';

const INITIAL_STATE = {
  to: undefined,
  options: undefined,
};

export const NAVIGATION_TYPES = {
  NAVIGATE: '@@navigation/NAVIGATE',
  CLEAR: '@@navigation/CLEAR',
};

export default function navigation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NAVIGATION_TYPES.NAVIGATE: {
      const { to, options } = action.payload;
      return {
        ...state,
        to,
        options,
      };
    }
    case NAVIGATION_TYPES.CLEAR:
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
