import orderBy from 'lodash.orderby';
import isString from 'lodash.isstring';
import { createSelector } from 'reselect';

import {
  selectCurrentUserEmail,
  selectIsCurrentUserAdmin,
} from '../auth/authSelectors';
import {
  selectUserList,
  selectActiveUserList,
  selectLastActiveUserMap,
} from '../user/userSelectors';
import UserFinder from './UserFinder';
import { formattedDate } from '../../lib/date';
import { EVENT_TYPES, ACTIVITY_TYPES } from './incidentTypes';

export const selectIncidentState = (state) => state.incidents;

export const selectIncidentFetching = createSelector(
  selectIncidentState,
  (incidentState) => incidentState.fetching
);

export const selectIncidentList = createSelector(
  selectIncidentState,
  selectActiveUserList,
  (incidentState, activeUsers) => {
    const userFinder = new UserFinder(activeUsers);

    return incidentState.items.map((item) => ({
      ...item,
      type: item.isTabletop
        ? EVENT_TYPES.TABLETOP_EXERCISE
        : EVENT_TYPES.INCIDENT,
      createdBy: userFinder.findByEmailOrFullName(item?.createdBy),
      resolvedBy: userFinder.findByEmailOrFullName(item?.resolvedBy),
    }));
  }
);

export const selectCurrentPage = createSelector(
  selectIncidentState,
  (incidentState) => incidentState.currentPage
);

export const selectHasMoreItems = createSelector(
  selectIncidentState,
  (incidentState) => incidentState.hasMoreItems
);

export const selectUnresolvedTabletops = createSelector(
  selectIncidentState,
  (incidentState) =>
    incidentState.items.filter((item) => item.isActive && item.isTabletop)
);

export const selectHasActiveEvent = createSelector(
  selectIncidentState,
  (incidentState) => incidentState.items.some((i) => i.isActive)
);

export const selectCurrentItem = createSelector(
  selectIncidentState,
  selectActiveUserList,
  selectLastActiveUserMap,
  selectCurrentUserEmail,
  selectIsCurrentUserAdmin,
  (
    incidentState,
    activeUsers,
    lastActiveUserMap,
    currentUserEmail,
    isCurrentUserAdmin
  ) => {
    const { currentItem } = incidentState;
    if (!currentItem) {
      return null;
    }

    const { createdBy, resolvedBy } = currentItem;
    const userFinder = new UserFinder(activeUsers);
    const checkInMap = new Map(
      currentItem.checkIns.map((c) => [c.userId, c.timestamp])
    );
    const checkInUsers = !!currentItem.targetedUsers.length
      ? activeUsers.filter((u) =>
          currentItem.targetedUsers.some(
            (targetedUser) => targetedUser === u.email
          )
        )
      : activeUsers;
    const hasCurrentUserCheckedIn = currentItem.checkIns.some(
      (c) => c.userId === currentUserEmail
    );
    const hasIncompleteTasks = currentItem.activities.some(
      (a) => a.type === ACTIVITY_TYPES.TASK && a.progress < 100
    );

    return {
      ...currentItem,
      type: currentItem.isTabletop
        ? EVENT_TYPES.TABLETOP_EXERCISE
        : EVENT_TYPES.INCIDENT,
      createdBy: isString(createdBy)
        ? userFinder.findByEmailOrFullName(createdBy)
        : createdBy,
      resolvedBy: isString(resolvedBy)
        ? userFinder.findByEmailOrFullName(resolvedBy)
        : resolvedBy,
      checkIns: orderBy(
        checkInUsers.map((u) => ({
          user: u,
          checkInTimestamp: checkInMap.get(u.email) || 0,
          lastActiveTimestamp: lastActiveUserMap.get(u.email) || 0,
        })),
        ['checkInTimestamp'],
        ['desc']
      ),
      activities: currentItem.activities.map((a) => ({
        ...a,
        createdBy: userFinder.findByEmailOrFullName(a.createdBy),
        assignedTo: !!a.assignedTo
          ? userFinder.findByEmailOrFullName(a.assignedTo)
          : null,
        // An activity can be edited by admins, the creator, the assignee, or by anyone when it is unassigned.
        canEdit: currentItem.isActive
          ? currentUserEmail === a.createdBy ||
            currentUserEmail === a.assignedTo ||
            (a.type === ACTIVITY_TYPES.TASK && !a.assignedTo) ||
            isCurrentUserAdmin
          : false,
        // An activity can be deleted by admins or by the creator.
        canDelete: currentItem.isActive
          ? currentUserEmail === a.createdBy || isCurrentUserAdmin
          : false,
      })),
      targetedUsers: currentItem.targetedUsers.map((id) =>
        userFinder.findByEmailOrFullName(id)
      ),
      hasCurrentUserCheckedIn,
      hasIncompleteTasks,
    };
  }
);

export const selectAttachments = createSelector(
  selectIncidentState,
  (incidentState) => incidentState.currentItemAttachments
);

export const selectAttachmentsTableData = createSelector(
  selectAttachments,
  selectUserList,
  (attachments, userList) =>
    attachments.map((f) => {
      const createdByUser = userList.find((u) => f.createdBy === u.email) || {
        firstName: 'Former',
        lastName: 'Member',
      };
      const lastUpdated =
        formattedDate(f.updateTimestamp) || formattedDate(f.createTimestamp);

      return {
        ...f,
        lastUpdated,
        type: f.extension,
        size: f.size || '',
        createdByName: `${createdByUser.firstName} ${createdByUser.lastName}`,
      };
    })
);
