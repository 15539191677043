import { createSelector } from 'reselect';

import { groupCompare } from '../../utils/sortUtils';

export const selectGroupAdminState = (state) => state.groupAdmin;

export const selectGroups = createSelector(
  selectGroupAdminState,
  (groupState) => groupState.list.sort(groupCompare)
);

export const selectFetchingGroups = createSelector(
  selectGroupAdminState,
  (groupState) => groupState.fetching
);

export const selectActiveGroup = createSelector(
  selectGroupAdminState,
  (groupState) => {
    const { groupId, list } = groupState;
    if (!groupId) {
      return null;
    }
    return list.find((p) => p.id === groupId);
  }
);
