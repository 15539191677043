import { useState } from 'react';
import remarkGfm from 'remark-gfm';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/LocalOfferOutlined';

import useAccess from '../../../hooks/useAccess';
import { downloadFile } from '../../../utils/networkUtils';
import Playbooks from '../../../store/playbook/playbookActions';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';
import { PLAYBOOK_ITEM_TYPES } from '../../../store/playbook/playbookTypes';
import { FEATURES, FILE_FORMAT, DEPENDENT_FEATURES } from '../../../configs';

import Task from '../task/Task';
import Heading from '../heading/Heading';
import PlaybookLayout from './PlaybookLayout';
import DownloadModal from '../../file-storage/DownloadModal';
import EditPlaybookCategoryModal from '../EditPlaybookCategoryModal';
import PdfIcon from '../../../assets/icons/export-pdf.svg?react';
import DocxIcon from '../../../assets/icons/export-docx.svg?react';
import CopyIcon from '../../../assets/icons/copy-outline.svg?react';
import EditIcon from '../../../assets/icons/edit-outline.svg?react';
import TrashIcon from '../../../assets/icons/trash-2-outline.svg?react';

const Playbook = ({
  id,
  name,
  description,
  activities,
  onClickEdit,
  onClickDelete,
  onClickClone,
}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState('');
  const [exportProgress, setExportProgress] = useState(0);
  const [confirmDeleteElement, openConfirmDelete] = useConfirmAsync();
  const canManage = useAccess({
    permission: FEATURES.playbookManager,
    dependentFeature: DEPENDENT_FEATURES.playbooks,
  });

  const handleClickChangeCategory = () => setModal('category');

  const handleExportPlaybook = (type) => {
    setModal('download');

    dispatch(
      Playbooks.exportPlaybook(id, type, (progressEvent) => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setExportProgress(percentCompleted);
      })
    ).then((res) => {
      downloadFile({
        name: `${name}.${type.toLowerCase()}`,
        objectUrl: res.value,
      });
      setModal('');
      setExportProgress(0);
    });
  };

  const handleClickDelete = () => {
    openConfirmDelete({
      title: 'Delete Playbook?',
      content: (
        <div style={{ textAlign: 'center' }}>
          This will delete <b>{name}</b>. Any past incidents created with this
          playbook will be unaffected.
        </div>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () => dispatch(Playbooks.delete(id)).then(onClickDelete),
    });
  };

  return (
    <>
      <PlaybookLayout
        nameComponent={
          <Typography variant="h3" component="h1">
            {name}
          </Typography>
        }
        toolbarButtons={[
          {
            icon: <EditIcon />,
            tooltip: 'Edit',
            onClick: onClickEdit,
            hide: !canManage,
          },
          {
            icon: <CopyIcon />,
            tooltip: 'Clone',
            onClick: onClickClone,
            hide: !canManage,
          },
          {
            icon: <CategoryIcon />,
            tooltip: 'Change Category',
            onClick: handleClickChangeCategory,
            hide: !canManage,
          },
          {
            icon: <PdfIcon />,
            tooltip: 'Export PDF',
            onClick: () => handleExportPlaybook(FILE_FORMAT.PDF),
          },
          {
            icon: <DocxIcon />,
            tooltip: 'Export DOCX',
            onClick: () => handleExportPlaybook(FILE_FORMAT.DOCX),
          },
          {
            icon: <TrashIcon />,
            tooltip: 'Delete',
            onClick: handleClickDelete,
            hide: !canManage,
          },
        ]}
        descriptionHelperComponent={
          <Typography color="grey" fontStyle="italic">
            The default brief of the incident.
          </Typography>
        }
        descriptionComponent={
          <ReactMarkdown plugins={[remarkGfm]}>{description}</ReactMarkdown>
        }
        itemsHelperComponent={
          <Typography color="grey" fontStyle="italic">
            These default tasks to be created for the incident.
          </Typography>
        }
        itemsComponent={
          <>
            {!activities.length && (
              <Typography color="grey">No tasks added yet.</Typography>
            )}
            {!!activities.length &&
              activities.map((a) => {
                if (a.type === PLAYBOOK_ITEM_TYPES.HEADING) {
                  return <Heading {...a} key={a.id} />;
                } else if (a.type === PLAYBOOK_ITEM_TYPES.TASK) {
                  return <Task {...a} key={a.id} sx={{ mx: 4 }} />;
                }
              })}
          </>
        }
      />
      <EditPlaybookCategoryModal
        open={modal === 'category'}
        onClose={() => setModal('')}
      />
      <DownloadModal
        open={modal === 'download'}
        onClose={() => setModal('')}
        label={`Downloading ${name} Playbook...`}
        progress={exportProgress}
      />
      {confirmDeleteElement}
    </>
  );
};

export default Playbook;
