import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useAlert from '../lib/hooks/useAlert';
import useToast from '../lib/hooks/useToast';
import Enterprise from '../store/org/orgActions';

const Link = styled(Typography)`
  cursor: pointer;
`;

const RequestUpgradeLink = ({ name, onClick, children, ...props }) => {
  const dispatch = useDispatch();
  const [alertElement, openAlert] = useAlert();
  const [toastElement, openToast] = useToast();

  const handleClick = (e) => {
    e.preventDefault();

    if (!!onClick) {
      onClick(name); // Optional onClick event handler.
    }

    return dispatch(Enterprise.requestUpgrade(name))
      .then(() =>
        openAlert({
          title: 'Request Sent',
          content:
            'Thank you for your request, we will be in touch with your organization’s administrator.',
        })
      )
      .catch((e) => {
        console.error(e);
        const { message, response } = e;
        openToast(
          response.status === 429
            ? 'You already requested this feature recently.'
            : message,
          'error',
          3000
        );
      });
  };

  return (
    <>
      <Link {...props} component="a" onClick={handleClick}>
        {children}
      </Link>
      {alertElement}
      {toastElement}
    </>
  );
};

export default RequestUpgradeLink;
