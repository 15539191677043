import axios from 'axios';

import {
  selectChatsList,
  selectCallTitleByChatId,
} from '../chat/chatSelectors';
import Chats from '../chat/chatActions';
import { JITSI_TYPES } from './jitsiReducer';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectEnabledDialIn } from '../org/orgSelectors';
import { getStreamChatClient } from '../../lib/streamChatClient';
import { selectHasIncomingCall, selectOnCall } from './jitsiSelectors';

// setting on a constant until we implement the creation of jwt
const JitsiCalls = {
  getJWT: (chatId) => (dispatch, getState) => {
    // Make call title independent of currentChat, and set at initialization.
    const callTitle = selectCallTitleByChatId(getState(), chatId);

    return dispatch({
      type: JITSI_TYPES.FETCH_JWT,
      payload: axiosInstance
        .get('/jitsi/accessToken')
        .then((res) => ({ token: res.data.accessToken, chatId, callTitle })),
    });
  },
  joinCall: (videoMuted) => ({
    type: JITSI_TYPES.JOIN_CALL,
    payload: { videoMuted },
  }),
  toggleCallWindow: () => ({
    type: JITSI_TYPES.TOGGLE_SHOW_CALL,
  }),
  leaveCall: () => ({
    type: JITSI_TYPES.LEAVE_CALL,
  }),
  updateParticipants: (participants) => ({
    type: JITSI_TYPES.UPDATE_PARTICIPANTS,
    payload: { participants },
  }),
  updateActiveCalls: (callDetails, chatId) => (dispatch, getState) => {
    const { activeCalls } = getState().jitsi;
    const idx = activeCalls.indexOf(chatId);

    if (callDetails) {
      if (callDetails.isActive && idx === -1) {
        dispatch({
          type: JITSI_TYPES.UPDATE_ACTIVE_CALLS,
          payload: [...activeCalls, chatId],
        });
      }

      if (!callDetails.isActive && idx !== -1) {
        dispatch({
          type: JITSI_TYPES.UPDATE_ACTIVE_CALLS,
          payload: activeCalls.filter((id) => id !== chatId),
        });
      }
    }
  },
  setMessageId: (messageId) => ({
    type: JITSI_TYPES.SET_MESSAGE_ID,
    payload: messageId,
  }),
  setIncomingCall:
    (details, callerId, messageId, cid) => async (dispatch, getState) => {
      const hasIncoming = selectHasIncomingCall(getState());
      const onCall = selectOnCall(getState());

      if (!hasIncoming && !onCall) {
        const chats = selectChatsList(getState());
        let callChat = chats.find((c) => c.cid === cid);
        if (!callChat) {
          const client = getStreamChatClient();
          const response = await client.queryChannels({
            cid: { $eq: cid },
          });
          callChat = response[0];
          dispatch(Chats.addChat(callChat));
        }

        dispatch({
          type: JITSI_TYPES.SET_INCOMING_CALL,
          payload: {
            ...details,
            messageId,
            callerId,
            members: callChat.state.members,
            type: callChat.type,
            id: callChat.id,
          },
        });
      }
    },
  hangUp: () => ({ type: JITSI_TYPES.HANG_UP }),
  fetchDialInOptions: () => (dispatch, getState) => {
    if (!selectEnabledDialIn(getState())) {
      return;
    }

    return dispatch({
      type: JITSI_TYPES.FETCH_DIAL_IN_OPTIONS,
      payload: axios.get(
        'https://api-vo.jitsi.net/vmms-conference-mapper/access/v1/dids'
      ),
    });
  },
};

export default JitsiCalls;
