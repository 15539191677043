import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { downloadFile } from '../../utils/networkUtils';
import Storage from '../../store/storage/storageActions';

import BasicModal from '../BasicModal';
import AttachmentsTable from './AttachmentsTable';

const AttachmentsModal = ({ folderId, open, onClose, openAdd }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleDownload = () => {
    if (!selected) {
      return;
    }

    dispatch(Storage.downloadFile(selected)).then((res) =>
      downloadFile({
        name: `${selected.name}.${selected.extension}`,
        objectUrl: res.value,
      })
    );
  };

  useEffect(() => {
    if (!open) {
      setSelected(null);
    } else {
      setLoading(true);
      dispatch(Storage.fetchFolder(folderId)).finally(() => setLoading(false));
    }
  }, [open, folderId, dispatch]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      title="Attachments"
      footerComponent={
        <Stack gap={1.5} direction="row" justifyContent="flex-end">
          <Button onClick={onClose}>Close</Button>
          <Button variant="contained" onClick={openAdd}>
            Upload
          </Button>
          <Button
            variant="contained"
            disabled={!selected}
            onClick={handleDownload}
          >
            Download
          </Button>
        </Stack>
      }
      bodyStyles={{
        p: 0,
        height: '325px',
      }}
      sx={{
        width: '54rem',
      }}
    >
      <AttachmentsTable
        selected={selected}
        loading={loading}
        handleSingleClick={(f) => setSelected(f)}
      />
    </BasicModal>
  );
};

export default AttachmentsModal;
