import { AUTH_TYPES } from '../auth/authReducer';

const INITIAL_STATE = {
  isActiveTab: false,
  isReady: false,
};

export const HEARTBEAT_TYPES = {
  SET_ACTIVE_TAB: '@@heartbeat/SET_ACTIVE_TAB',
  SET_IS_READY: '@@heartbeat/SET_IS_READY',
};

export default function heartbeat(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEARTBEAT_TYPES.SET_ACTIVE_TAB:
      return {
        ...state,
        isActiveTab: action.payload,
      };
    case HEARTBEAT_TYPES.SET_IS_READY:
      return {
        ...state,
        isReady: action.payload,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
