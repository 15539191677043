import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import CancelIcon from '../../assets/icons/close.svg?react';
import EditIcon from '../../assets/icons/edit-outline.svg?react';
import TrashIcon from '../../assets/icons/trash-2-outline.svg?react';
import SaveIcon from '../../assets/icons/checkmark-outline.svg?react';

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EditItemControl = ({
  onClickEdit,
  onClickDelete,
  onClickSave,
  onClickCancel,
}) => (
  <Controls>
    {!!onClickSave && !!onClickCancel ? (
      <>
        <Tooltip arrow title="Save" placement="right" disableInteractive>
          <IconButton size="small" color="success" onClick={onClickSave}>
            <SvgIcon component={SaveIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Cancel" placement="right" disableInteractive>
          <IconButton size="small" onClick={onClickCancel}>
            <SvgIcon component={CancelIcon} />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip arrow title="Edit" placement="right" disableInteractive>
          <IconButton size="small" color="primary" onClick={onClickEdit}>
            <SvgIcon component={EditIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete" placement="right" disableInteractive>
          <IconButton size="small" color="error" onClick={onClickDelete}>
            <SvgIcon component={TrashIcon} />
          </IconButton>
        </Tooltip>
      </>
    )}
  </Controls>
);

export default EditItemControl;
