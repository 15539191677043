import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { MAX_LENGTH } from '../../configs/validation';
import { handlePaste } from '../../lib/input';
import UserAutocomplete from '../UserAutocomplete';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { selectUserList } from '../../store/user/userSelectors';
import { selectCurrentUser } from '../../store/auth/authSelectors';
import GroupAdmin from '../../store/group-admin/groupAdminActions';

import GroupLayout from './GroupLayout';
import RichTextEditor from '../RichTextEditor';
import SavingModal from '../playbook-manager/SavingModal';
import CancelIcon from '../../assets/icons/close.svg?react';
import SaveIcon from '../../assets/icons/checkmark-outline.svg?react';

const TextField = styled(MuiTextField)`
  flex-grow: 1;
  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(0.25, 0)};
    line-height: 1.5;
    font-size: ${({ sx }) => sx};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const GroupEditor = ({ group, openToast, onClickSave, onClickCancel }) => {
  const dispatch = useDispatch();
  const userList = useSelector(selectUserList);
  const [errors, setErrors] = useState(null);
  const [modal, setModal] = useState('');
  const isEditing = !!group;
  const [name, setName] = useState(isEditing ? group?.name : '');
  const [getContent, setContentGetter] = useState(null);
  const [confirmCancelElement, openConfirmCancel] = useConfirmAsync();
  const handleRichTextEditorMount = useCallback((getter) => {
    setContentGetter(() => getter);
  }, []);
  const currentUser = useSelector(selectCurrentUser);
  const userFullName = isEditing
    ? userList.find((user) => user.userId === group?.createdBy)?.fullName
    : currentUser.user.fullName;

  const { control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      members: isEditing
        ? userList.filter((user) => group?.userIds.includes(user.userId))
        : [userList.find((u) => u.userProperties.uuid === currentUser.uuid)],
    },
  });
  const selectedMembers = watch('members');

  const handleMaxLengthReached = (show) => {
    if (show) {
      openToast('You have reached the maximum character limit.', 'error', 3000);
    }
  };

  const handleClickSave = () => {
    if (!name.trim()) {
      return setErrors({ name: 'Name is required.' });
    }

    const data = {
      name: name.trim(),
      description: getContent(),
      userIds: selectedMembers.map((user) => user.userId),
    };

    setModal('saving');
    dispatch(
      isEditing ? GroupAdmin.edit(group?.id, data) : GroupAdmin.create(data)
    )
      .then(() => {
        openToast(
          isEditing ? 'Saved changes.' : 'Created group.',
          'success',
          3000
        );
        onClickSave();
      })
      .catch((e) => openToast(`Error: ${e.message}`, 'error', 3000))
      .finally(() => setModal(''));
  };

  const handleClickCancel = () => {
    openConfirmCancel({
      title: 'Discard Changes?',
      content: (
        <div style={{ textAlign: 'center' }}>
          Any unsaved changes will be lost.
        </div>
      ),
      buttonText: 'Discard',
      onConfirm: () => {
        openToast('Discarded changes.', 'info', 3000);
        onClickCancel();
      },
    });
  };

  useEffect(() => {
    if (errors?.name) {
      openToast('Group name is required.', 'error', 3000);
    }
  }, [errors, openToast]);

  return (
    <>
      <GroupLayout
        nameComponent={
          <TextField
            autoFocus
            variant="outlined"
            placeholder="Group Name"
            sx="24px"
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{ maxLength: MAX_LENGTH.value }}
            onPaste={(e) => handlePaste(e, setName, MAX_LENGTH.value)}
          />
        }
        nameHelperComponent={
          <Typography color="grey" fontStyle="italic" sx={{ mr: 1 }}>
            Edit the group name.
          </Typography>
        }
        toolbarButtons={[
          {
            icon: <SaveIcon />,
            tooltip: 'Save',
            onClick: handleClickSave,
          },
          {
            icon: <CancelIcon />,
            tooltip: 'Cancel',
            onClick: handleClickCancel,
          },
        ]}
        descriptionHelperComponent={
          <Typography color="grey" fontStyle="italic">
            Edit the group description.
          </Typography>
        }
        descriptionComponent={
          <RichTextEditor
            height="100px"
            placeholder="What this group is all about..."
            onMount={handleRichTextEditorMount}
            handleMaxChar={handleMaxLengthReached}
            initialContent={isEditing ? group?.description : ''}
            style={{ margin: '0.25rem 0' }}
          />
        }
        membersHelperComponent={
          <Typography color="grey" fontStyle="italic">
            Add or remove group members.
          </Typography>
        }
        membersComponent={
          <Controller
            name="members"
            control={control}
            render={({ field }) => (
              <UserAutocomplete {...field} sx={{ flexGrow: 1 }} />
            )}
          />
        }
        createdBy={userFullName}
      />
      <SavingModal open={modal === 'saving'} />
      {confirmCancelElement}
    </>
  );
};

export default GroupEditor;
