import { useEffect } from 'react';

export default function useBeforeUnload() {
  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);
}
