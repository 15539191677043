import dayjs from 'dayjs';
import remarkGfm from 'remark-gfm';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ReactMarkdown from 'react-markdown';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  TASK_STATUS,
  ACTIVITY_TYPES,
} from '../../../store/incident/incidentTypes';
import { formatTimestamp, timeFromNow } from '../../../lib/date';

import PieChart from './PieChart';

const Container = styled.div`
  background-color: #fff;
  border-radius: 25px;
  padding: 20px 20px;
`;

const IncidentDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const IncidentDetailsHeader = styled(Box)`
  min-height: 33px;
  border-bottom: 1px solid #e6e6e6;
  z-index: 9999;
`;

const IncidentDetailsBody = styled(Box)`
  min-height: 320px;
`;

const IncidentDetailsData = styled(Box)`
  max-height: 200px;
  margin-left: 10px;
  overflow: auto;
  font-size: 14px;
`;

const IncidentDetailsTable = styled(Box)`
  min-height: 230px;
  border-top: 1px solid #e6e6e6;
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled(Grid)`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
`;

const TypographyNumbers = styled(Box)`
  font-weight: bold;
  font-size: 26px;
  margin-top: 30px;
  margin-left: -53px;
`;

const TypographyText = styled(Box)`
  font-weight: bold;
  font-size: 26px;
  margin-top: 5px;
`;

const TypographyBold = styled(Box)`
  font-weight: bold;
  font-size: 16px;
`;

const PieChartContainer = styled(Box)`
  height: 150px;
  margin-left: 90px;
`;

const IncidentTitle = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const IncidentHeaderContainer = styled(Box)`
  width: 50%;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const NormalText = styled.span`
  font-weight: normal;
`;

const IncidentDetails = ({ selectedRow, orgData }) => {
  if (!selectedRow) {
    return null;
  }
  const {
    title,
    createTimestamp,
    createdBy,
    previewText,
    checkIns,
    attachments,
    activities,
    isTargeted,
    targetedUsers,
  } = selectedRow;

  const { maxUsers, users } = orgData;
  const totalUsers = isTargeted ? targetedUsers.length : maxUsers;
  const percentageCheckedIn =
    checkIns && totalUsers && ((checkIns.length / totalUsers) * 100).toFixed(0);
  const percentageNotCheckedIn =
    checkIns &&
    totalUsers &&
    (((totalUsers - checkIns.length) / totalUsers) * 100).toFixed(0);
  const fullName = users && users.find((n) => n.email === createdBy);
  const dataCheckIn = [
    {
      id: 0,
      value: percentageCheckedIn,
      label: 'Checked In',
    },
    {
      id: 1,
      value: percentageNotCheckedIn,
      label: 'Not Checked In',
    },
  ];
  const dataTasks = [
    {
      id: 0,
      value:
        activities &&
        activities.filter(
          (i) =>
            i.type === ACTIVITY_TYPES.TASK && i.status === TASK_STATUS.COMPLETE
        ).length,
      label: 'Completed',
    },
    {
      id: 1,
      value:
        activities &&
        activities.filter(
          (i) =>
            i.type === ACTIVITY_TYPES.TASK &&
            i.status === TASK_STATUS.IN_PROGRESS
        ).length,
      label: 'In Progress',
    },
    {
      id: 2,
      value:
        activities &&
        activities.filter(
          (i) =>
            i.type === ACTIVITY_TYPES.TASK && i.status === TASK_STATUS.TO_DO
        ).length,
      label: 'To Do',
    },
  ];
  const dataTasksAssignment = [
    {
      id: 0,
      value:
        activities.filter((i) => i.type === ACTIVITY_TYPES.TASK).length > 0
          ? (
              (activities.filter(
                (i) => i.type === ACTIVITY_TYPES.TASK && !!i.assignedTo
              ).length /
                activities.filter((i) => i.type === ACTIVITY_TYPES.TASK)
                  .length) *
              100
            ).toFixed(0)
          : '0',
      label: 'Assigned',
    },
    {
      id: 1,
      value:
        activities.filter((i) => i.type === ACTIVITY_TYPES.TASK).length > 0
          ? (
              (activities.filter(
                (i) => i.type === ACTIVITY_TYPES.TASK && !i.assignedTo
              ).length /
                activities.filter((i) => i.type === ACTIVITY_TYPES.TASK)
                  .length) *
              100
            ).toFixed(0)
          : '0',
      label: 'Unassigned',
    },
  ];

  return (
    <Container>
      <IncidentDetailsContainer>
        <IncidentDetailsHeader>
          <IncidentTitle>
            <IncidentHeaderContainer
              sx={{
                textAlign: 'left',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  marginLeft: '10px',
                  marginTop: '-7px',
                }}
              >
                Incident: <BoldText>{title}</BoldText>
              </Typography>
            </IncidentHeaderContainer>
            <IncidentHeaderContainer
              sx={{
                textAlign: 'right',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              >
                Created by:
                {fullName && (
                  <NormalText>{' ' + fullName.fullName + ' - '}</NormalText>
                )}
                <Tooltip
                  placement="bottom"
                  disableInteractive
                  title={formatTimestamp(createTimestamp)}
                >
                  <NormalText>
                    {timeFromNow(dayjs().diff(createTimestamp)) + ' ago'}
                  </NormalText>
                </Tooltip>
              </Typography>
            </IncidentHeaderContainer>
          </IncidentTitle>
        </IncidentDetailsHeader>
        <IncidentDetailsBody>
          <IncidentDetailsData>
            <ReactMarkdown plugins={[remarkGfm]}>{previewText}</ReactMarkdown>
          </IncidentDetailsData>
          <IncidentDetailsTable>
            <Row>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyBold>Check Ins:</TypographyBold>
                <TypographyText>
                  {checkIns && (
                    <>
                      {checkIns.length} / {totalUsers}
                    </>
                  )}
                </TypographyText>
              </Column>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyBold>Updates:</TypographyBold>
                <TypographyText>
                  {activities &&
                    activities.filter((i) => i.type === ACTIVITY_TYPES.UPDATE)
                      .length}
                </TypographyText>
              </Column>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyBold>Attachments:</TypographyBold>
                <TypographyText>{attachments}</TypographyText>
              </Column>
            </Row>
            <Row sx={{ marginTop: '10px' }}>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyBold>Check Ins:</TypographyBold>
                <PieChartContainer>
                  {percentageCheckedIn && percentageCheckedIn.length === 0 ? (
                    <TypographyText>{checkIns.length}</TypographyText>
                  ) : (
                    <PieChart
                      colors={['#7BC1A8', '#909090']}
                      data={dataCheckIn}
                      present
                      first="true"
                    />
                  )}
                </PieChartContainer>
              </Column>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TypographyBold>Status of Tasks:</TypographyBold>
                <PieChartContainer>
                  {activities &&
                  activities.filter((i) => i.type === ACTIVITY_TYPES.TASK)
                    .length > 0 ? (
                    <PieChart
                      colors={['#7BC1A8', '#F7C144', '#E4464C']}
                      data={dataTasks}
                    />
                  ) : (
                    <TypographyNumbers>0</TypographyNumbers>
                  )}
                </PieChartContainer>
              </Column>
              <Column
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {' '}
                <TypographyBold>Assignment of Tasks:</TypographyBold>
                <PieChartContainer>
                  {activities &&
                  activities.filter((i) => i.type === ACTIVITY_TYPES.TASK)
                    .length > 0 ? (
                    <PieChart
                      colors={['#7BC1A8', '#909090']}
                      present
                      data={dataTasksAssignment}
                    />
                  ) : (
                    <TypographyNumbers>0</TypographyNumbers>
                  )}
                </PieChartContainer>
              </Column>
            </Row>
          </IncidentDetailsTable>
        </IncidentDetailsBody>
      </IncidentDetailsContainer>
    </Container>
  );
};

export default IncidentDetails;
