import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useToast from '../../lib/hooks/useToast';
import Users from '../../store/user/userActions';
import { trimPhoneNumber } from '../../utils/formUtils';
import Authentication from '../../store/auth/authActions';
import { selectCurrentUserPhone } from '../../store/auth/authSelectors';

import BasicModal from '../BasicModal';
import PhoneInput from '../PhoneInput';

const ChangePhoneModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [toastElement, openToast] = useToast();
  const currentPhoneNumber = useSelector(selectCurrentUserPhone);
  const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const editing = !!currentPhoneNumber;

  const handleClose = (showSuccess = false) => {
    onClose(showSuccess);
    setPhoneNumber('');
    setError('');
  };

  const handleSave = () => {
    const newPhoneNumber = trimPhoneNumber(phoneNumber);
    if (!newPhoneNumber || newPhoneNumber.length < 10) {
      return setError('Please enter a valid phone number.');
    }

    setError('');
    setLoading(true);
    return dispatch(Users.setPhoneNumber(newPhoneNumber))
      .then(() => {
        dispatch(Authentication.updateUserProperties());
        handleClose(true);
      })
      .catch((e) => {
        console.error(e);
        openToast('Something went wrong.', 'error', 3000);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open) {
      setPhoneNumber(currentPhoneNumber);
    }
  }, [open, currentPhoneNumber]);

  return (
    <BasicModal
      open={open}
      onClickButton={handleSave}
      onClose={() => handleClose()}
      title={`${editing ? 'Change' : 'Set'} Phone Number`}
      sx={{ minWidth: '24rem' }}
    >
      <PhoneInput
        autoFocus
        error={!!error}
        disabled={loading}
        helperText={error}
        value={phoneNumber}
        onChange={setPhoneNumber}
        sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
      />
      {toastElement}
    </BasicModal>
  );
};

export default ChangePhoneModal;
