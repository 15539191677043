import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectQRImage,
  selectSecret,
  selectTwoFactorData,
} from '../../store/auth/authSelectors';
import Authentication from '../../store/auth/authActions';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';

import Copy from '../../assets/icons/copy-outline.svg?react';

const List = styled.ol`
  gap: 0.5em;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-inline-start: 1.5rem;
`;

const SecretField = styled(TextField)`
  flex-grow: 1;

  .MuiInputBase-root {
    padding: 0.25rem;
  }
  .MuiInputBase-input {
    text-align: center;
    font-family: monospace;
    overflow: auto !important;
  }
`;

const CONFIGURATION_STEPS = [
  'Download and install your chosen TOTP Authenticator app on your mobile device.',
  'Open the installed TOTP Authenticator app and set up a new account by scanning the provided QR code.',
  'Verify the configuration by entering the token generated by your chosen TOTP Authenticator app.',
  'Click the Verify button before the token expires to finalize the configuration process.',
];

const TOTPConfiguration = () => {
  const dispatch = useDispatch();
  const secret = useSelector(selectSecret);
  const qrImage = useSelector(selectQRImage);
  const twoFactorData = useSelector(selectTwoFactorData);
  const [copyPromptText, copyText] = useCopyToClipboard('Copy Secret Token');

  const { initialized } = twoFactorData;

  const handleClickCopy = () => copyText(secret);

  // Initialization effect.
  useEffect(() => {
    if (initialized === false) {
      dispatch(Authentication.get2FAQRCode());
    }
  }, [initialized, dispatch]);

  return (
    <Stack direction="row" gap={4}>
      <Stack gap={2} sx={{ flex: 1 }}>
        <Typography align="center" component="h2" variant="h4">
          Getting Started
        </Typography>
        <Typography align="left">
          This will provide two-factor authentication using a token generated by
          an TOTP Authenticator app (e.g. Google or Microsoft Authenticator,
          etc.).
        </Typography>
        <Typography align="center" component="h3" variant="h6">
          Configuration Steps
        </Typography>
        <List>
          {CONFIGURATION_STEPS.map((s, i) => (
            <li key={i}>{s}</li>
          ))}
        </List>
      </Stack>
      <Divider orientation="vertical" flexItem />
      <Stack gap={1} sx={{ flex: 1 }}>
        <Typography align="center" component="h2" variant="h4">
          Configuration QR Code
        </Typography>
        <Typography align="center">
          Scan the QR code using your TOTP Authenticator app.
        </Typography>
        <Stack direction="row" justifyContent="center">
          <img src={qrImage} style={{ width: 'auto', height: '175px' }} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <SecretField
            rows={2}
            multiline
            value={secret}
            variant="filled"
            InputProps={{ disableUnderline: true }}
          />
          <Tooltip arrow disableInteractive title={copyPromptText}>
            <IconButton onClick={handleClickCopy} sx={{ flexShrink: 0 }}>
              <SvgIcon component={Copy} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography align="center">
          <b>Cannot scan the QR code?</b> Copy the secret token above and
          manually add it to your TOTP Authenticator app.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TOTPConfiguration;
