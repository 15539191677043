import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PulseLogoLoader } from './loader';

const LoadingScreen = ({ text = 'Loading...' }) => (
  <Stack
    gap={2}
    alignItems="center"
    justifyContent="center"
    sx={{ width: '100%', height: '100%' }}
  >
    <PulseLogoLoader />
    <Typography>{text}</Typography>
  </Stack>
);

export default LoadingScreen;
