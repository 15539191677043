import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { MAX_LENGTH } from '../../configs/validation';
import { handlePaste } from '../../lib/input';
import Chats from '../../store/chat/chatActions';
import { selectNewChatForm } from '../../store/chat/chatSelectors';
import { CHAT_TYPES, getNewChatLabelByType } from '../../lib/string';

import UserAutocomplete from '../UserAutocomplete';
import { ContentMain as Container } from './ChatContent';

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  padding: 1em 1.5em;
  border-bottom: 1px solid #ddd;
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const NewChatLabel = styled.span`
  font-weight: bold;
  line-height: 1.75;
  color: #777;
`;

const FieldLabel = styled(Typography)`
  font-weight: bold;
  min-width: 55px;
  flex-shrink: 0;
`;

const SpaceBelow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  gap: 1rem;
`;

const NewChatForm = () => {
  const dispatch = useDispatch();
  const chatType = useSelector(selectNewChatForm);
  const [alreadyExistsError, setAlreadyExistsError] = useState('');

  const {
    control,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      recipients: [],
    },
  });

  const name = watch('name');
  const description = watch('description');
  const recipients = watch('recipients');

  const newChatLabel = getNewChatLabelByType(chatType);
  const hasName = [CHAT_TYPES.WAR_ROOM, CHAT_TYPES.GROUP].includes(chatType);
  const isWarRoom = chatType === CHAT_TYPES.WAR_ROOM;
  const startChatDisabled =
    chatType === CHAT_TYPES.DIRECT_MESSAGE
      ? recipients.length === 0
      : (!name && hasName) ||
        name.length >= MAX_LENGTH.value ||
        description.length >= MAX_LENGTH.value;

  const handleClose = () => {
    dispatch(Chats.toggleNewChatForm(''));
    reset();
  };

  const handleClickStart = (data) => {
    const recipients = data.recipients;
    if (hasName) {
      const name = data.name.trim();
      const description = data.description.trim();

      return dispatch(
        Chats.createGroupChat(
          name,
          recipients.map((r) => r.userProperties.uuid),
          isWarRoom,
          description
        )
      )
        .then(handleClose)
        .catch((errorMessage) => setAlreadyExistsError(errorMessage));
    }

    return dispatch(
      Chats.createDirectChat(recipients.map((r) => r.userProperties.uuid))
    ).then(handleClose);
  };

  return (
    <Container component="form" onSubmit={handleSubmit(handleClickStart)}>
      <TopBar>
        <Row justifyContent="space-between">
          <NewChatLabel>{newChatLabel}</NewChatLabel>
        </Row>
        {hasName && (
          <Row>
            <Controller
              name="name"
              control={control}
              rules={{
                required:
                  chatType === CHAT_TYPES.WAR_ROOM
                    ? 'Please enter a War Room name.'
                    : ' Please enter a Breakout Room name.',
                validate: (value) => {
                  if (value.length >= MAX_LENGTH.value) {
                    return MAX_LENGTH.message;
                  } else if (value.length < 3) {
                    return 'Please enter a name that is at least 3 characters in length.';
                  }
                  return true;
                },
              }}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <>
                    <FieldLabel component="label" id="chat-name">
                      Name:{' '}
                    </FieldLabel>
                    <TextField
                      {...field}
                      onChange={(e) => {
                        setAlreadyExistsError('');
                        onChange(e.target.value);
                      }}
                      aria-labelledby="chat-name"
                      autoFocus={hasName}
                      variant="standard"
                      error={!!errors?.name || !!alreadyExistsError}
                      helperText={errors?.name?.message || alreadyExistsError}
                      InputProps={{ disableUnderline: true }}
                      onPaste={(e) => {
                        handlePaste(e, onChange);
                      }}
                      inputProps={{ maxLength: MAX_LENGTH.value }}
                      placeholder={
                        chatType === CHAT_TYPES.WAR_ROOM
                          ? 'War Room name...'
                          : 'Breakout Room name...'
                      }
                      sx={{ flexGrow: 1 }}
                    />
                  </>
                );
              }}
            />
          </Row>
        )}
        {chatType !== CHAT_TYPES.DIRECT_MESSAGE && (
          <Row>
            <Controller
              name="description"
              control={control}
              rules={{
                validate: (value) =>
                  value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
              }}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <>
                    <FieldLabel component="label" id="chat-description">
                      Description (Optional):{' '}
                    </FieldLabel>
                    <TextField
                      {...field}
                      aria-labelledby="chat-description"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      onPaste={(e) => {
                        handlePaste(e, onChange);
                      }}
                      inputProps={{ maxLength: MAX_LENGTH.value }}
                      error={!!errors?.description}
                      helperText={errors?.description?.message}
                      placeholder={
                        chatType === CHAT_TYPES.WAR_ROOM
                          ? "What's this War Room about?"
                          : "What's this Breakout Room about?"
                      }
                      sx={{ paddingLeft: '15px', flexGrow: 1 }}
                    />
                  </>
                );
              }}
            />
          </Row>
        )}
        <Row>
          <FieldLabel
            component="label"
            id="chat-recipients"
            alignSelf="flex-start"
            paddingTop={0.4}
          >
            To:{' '}
          </FieldLabel>
          <Controller
            name="recipients"
            control={control}
            render={({ field }) => (
              <UserAutocomplete
                {...field}
                excludeCurrentUser
                autoFocus={!hasName}
                sx={{ flexGrow: 1 }}
              />
            )}
          />
        </Row>
      </TopBar>
      <SpaceBelow>
        <Button type="submit" variant="contained" disabled={startChatDisabled}>
          Start Chat
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </SpaceBelow>
    </Container>
  );
};

export default NewChatForm;
