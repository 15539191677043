import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const Progress = ({ value, ...props }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress variant="determinate" value={value} {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        component="div"
        variant="caption"
        color="text.secondary"
        sx={{ fontSize: '0.6875rem' }}
      >{`${Math.round(value)}%`}</Typography>
    </Box>
  </Box>
);

export default Progress;
