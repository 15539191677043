import {
  PieChart as MuiPieChart,
  pieArcLabelClasses,
} from '@mui/x-charts/PieChart';

const PieChart = ({ colors, data, present, first }) => (
  <MuiPieChart
    colors={colors}
    tooltip
    series={[
      {
        arcLabel: (item) => `${item.value} ${present ? '%' : ''}`,
        arcLabelMinAngle: 45,
        data: data.map((item, index) => ({
          ...item,
          id: `${item.id}-${index}`,
        })),
        innerRadius: 24,
        paddingAngle: 1,
        cornerRadius: 5,
        highlightScope: { faded: 'none', highlighted: 'none' },
      },
    ]}
    slotProps={{
      legend: {
        direction: 'column',
        itemMarkWidth: 20,
        itemMarkHeight: 20,
        markGap: 7,
        itemGap: 6,
        padding: first ? -20 : 3,
        labelStyle: {
          fontSize: 11,
          fill: 'black',
        },
      },
    }}
    sx={{
      [`& .${pieArcLabelClasses.root}`]: {
        fill: 'white',
        fontWeight: 'bold',
      },
    }}
    width={250}
    height={180}
  />
);

export default PieChart;
