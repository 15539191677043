import {
  selectUid,
  selectWarRooms,
  selectWelcomeState,
  selectMainAdminUser,
} from './welcomeSelectors';
import {
  welcomePackageApi,
  updateWelcomePackageApiConfig,
} from '../../configs/welcomePackageApi';
import { WELCOME_TYPES } from './welcomeReducer';
import { WELCOME_PACKAGE_VALIDATION_ERRORS } from '../../configs/welcomePackage';

const WelcomePackage = {
  initialize: (token) => {
    try {
      const data = JSON.parse(atob(token));
      const { uid, apiAddress } = data;

      updateWelcomePackageApiConfig(apiAddress);

      return {
        type: WELCOME_TYPES.INITIALIZE,
        payload: {
          uid,
          apiAddress,
        },
      };
    } catch (e) {
      console.error('Error parsing welcome package token:', e);
    }
  },
  fetch: () => (dispatch, getState) => {
    const uid = selectUid(getState());

    return dispatch({
      type: WELCOME_TYPES.FETCH,
      payload: welcomePackageApi.get(`/${uid}`),
    });
  },
  validate: () => (dispatch, getState) => {
    const warRooms = selectWarRooms(getState());

    const hasInvalidWarRooms = warRooms.some((wr) => {
      const isEmpty =
        (!!wr.admin ? wr.members.length + 1 : wr.members.length) === 0;
      const isUntitled = !wr.label;
      return isEmpty || isUntitled;
    });

    return dispatch({
      type: WELCOME_TYPES.VALIDATE,
      payload: {
        errorCode: hasInvalidWarRooms
          ? WELCOME_PACKAGE_VALIDATION_ERRORS.INVALID_WAR_ROOMS
          : '',
      },
    });
  },
  save: () => (dispatch, getState) => {
    const {
      uid,
      status,
      maxUsers,
      mainAdminUser,
      orgCode: enterpriseCode,
      users,
      warRooms,
      fileFolders,
    } = selectWelcomeState(getState());

    const lastUpdateTimestamp = new Date().valueOf();

    const data = {
      status,
      maxUsers,
      lastUpdateTimestamp,
      mainAdminUser,
      enterpriseCode,
      users: users
        .filter((u) => !u.isMainAdminUser)
        .map((u) => ({
          ...u,
          phoneNumber: (u.phoneNumber || '').replace(/[^0-9]/g, ''),
        })),
      warRooms: warRooms.map((w) => ({
        name: w.label.trim(),
        usersEmails: w.admin
          ? [w.admin.email, ...w.members.map((m) => m.email)]
          : w.members.map((m) => m.email),
      })),
      fileFolders: fileFolders.map((f) => ({
        name: f.label,
        admin: f.adminOnly,
      })),
      lastUpdateBrowserData: window.navigator.userAgent,
      errorCode: null,
    };

    return dispatch({
      type: WELCOME_TYPES.SAVE,
      payload: welcomePackageApi.post(`/${uid}`, data),
    });
  },
  importUsers: (users) => (dispatch, getState) => {
    const mainAdminUser = selectMainAdminUser(getState());

    return dispatch({
      type: WELCOME_TYPES.IMPORT_USERS,
      payload: [mainAdminUser, ...users],
    });
  },
  addUser: (user) => ({
    type: WELCOME_TYPES.ADD_USER,
    payload: user,
  }),
  editUser: (user) => ({
    type: WELCOME_TYPES.EDIT_USER,
    payload: user,
  }),
  removeUser: (user) => ({
    type: WELCOME_TYPES.REMOVE_USER,
    payload: user,
  }),
  addWarRoom: (warRoom) => ({
    type: WELCOME_TYPES.ADD_WAR_ROOM,
    payload: warRoom,
  }),
  editWarRoom: (warRoom) => ({
    type: WELCOME_TYPES.EDIT_WAR_ROOM,
    payload: warRoom,
  }),
  removeWarRoom: (warRoom) => ({
    type: WELCOME_TYPES.REMOVE_WAR_ROOM,
    payload: warRoom,
  }),
  addFileFolder: (fileFolder) => ({
    type: WELCOME_TYPES.ADD_FILE_FOLDER,
    payload: fileFolder,
  }),
  editFileFolder: (fileFolder) => ({
    type: WELCOME_TYPES.EDIT_FILE_FOLDER,
    payload: fileFolder,
  }),
  removeFileFolder: (fileFolder) => ({
    type: WELCOME_TYPES.REMOVE_FILE_FOLDER,
    payload: fileFolder,
  }),
  markComplete: () => ({
    type: WELCOME_TYPES.MARK_COMPLETE,
  }),
  clearError: () => ({
    type: WELCOME_TYPES.CLEAR_ERROR,
  }),
};

export default WelcomePackage;
