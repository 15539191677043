import { useSelector } from 'react-redux';

import MessageGroup from './MessageGroup';
import { selectUserList } from '../../../store/user/userSelectors';
import { selectGroupMessagesByDay } from '../../../store/chat/messageSelectors';

export default function Messages() {
  const usersList = useSelector(selectUserList);
  const groupByDay = useSelector(selectGroupMessagesByDay);

  const startConvoMsg =
    'You have no messages here. This the beginning of this conversation.';

  if (Object.keys(groupByDay).length === 0) {
    return (
      <p>
        <strong>{startConvoMsg}</strong>
      </p>
    );
  }

  return Object.keys(groupByDay).map((day) =>
    groupByDay[day].map((m, i) => {
      const user = usersList.find(
        (u) => m[0].user.id === u.userProperties.uuid
      );
      const key = day + i;

      return <MessageGroup key={key} messages={m} sender={user} />;
    })
  );
}
