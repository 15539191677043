import Step from '@mui/material/Step';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import MuiStepper from '@mui/material/Stepper';
import MuiStepLabel from '@mui/material/StepLabel';
import MuiStepButton from '@mui/material/StepButton';

import { stepList } from './steps';

const StyledStepper = styled(MuiStepper)`
  .MuiStepConnector-line {
    min-height: 50px;
  }

  .MuiStepConnector-root {
    margin-left: 14px;
    &.Mui-completed,
    &.Mui-active {
      .MuiStepConnector-line {
        border-color: ${({ theme }) => theme.palette.secondary.light};
      }
    }
  }
`;

const StepButton = styled(MuiStepButton)`
  cursor: initial;
`;

const StepLabel = styled(MuiStepLabel)`
  .MuiStepIcon-root {
    width: 1.75rem;
    height: 1.75rem;

    &.Mui-active,
    &.Mui-completed {
      color: ${({ theme }) => theme.palette.secondary.light};
      .MuiStepIcon-text {
        fill: #fff;
      }
    }
    .MuiStepIcon-text {
      fill: ${({ theme }) => theme.palette.black.main};
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  .MuiStepLabel-label {
    font-weight: 400;
    font-size: 1.125rem;
    letter-spacing: 0.4;
    color: #fff;

    &.Mui-active,
    &.Mui-completed {
      color: ${({ theme }) => theme.palette.secondary.light};
    }
  }
`;

const StepIcon = ({ content, active, completed }) => {
  let classNames = 'MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root';
  if (completed) {
    classNames += ' Mui-completed';
  }
  if (active) {
    classNames += ' Mui-active';
  }

  return (
    <SvgIcon className={classNames}>
      {completed ? (
        <>
          <g clipPath="url(#a)">
            <circle cx={12.5} cy={12} r={12} />
            <path
              fill="#fff"
              d="M17.328 7.466a.825.825 0 0 1 1.178 0 .85.85 0 0 1 .011 1.181l-6.652 7.864a.823.823 0 0 1-1.198.023l-4.048-4.102a.852.852 0 0 1 .27-1.376.824.824 0 0 1 .908.184l3.433 3.48 6.075-7.229a.276.276 0 0 1 .023-.025Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <rect width={24} height={24} x={0.5} fill="#fff" rx={12} />
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <circle cx={12} cy={12} r={12} fill="currentColor" />
          <text
            x={12}
            y={12}
            className="MuiStepIcon-text"
            dominantBaseline="central"
            textAnchor="middle"
          >
            {content}
          </text>
        </>
      )}
    </SvgIcon>
  );
};

const Stepper = ({ activeStep, ...props }) => (
  <StyledStepper {...props} activeStep={activeStep} orientation="vertical">
    {stepList.map((step, index) => (
      <Step key={step.shortTitle}>
        <StepButton>
          <StepLabel
            StepIconComponent={StepIcon}
            StepIconProps={{ content: index === 0 ? 'W' : index }}
          >
            {step.shortTitle}
          </StepLabel>
        </StepButton>
      </Step>
    ))}
  </StyledStepper>
);

export default Stepper;
