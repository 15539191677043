import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import DirectChatContent from './DirectChatContent';
import GroupChatContent from './GroupChatContent';
import { selectConfig } from '../../store/org/orgSelectors';
import {
  selectCurrentChat,
  selectDirectChats,
  selectGroupChats,
  selectWarRooms,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import WarRooms from './WarRooms';
import Groups from './Groups';
import DirectMessages from './DirectMessages';
import NewChatForm from './NewChatForm';

const MainChatContent = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
`;

const ChatSideBar = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background: white;
  border-right: 1px solid #ddd;
  overflow: auto;
`;

export default function Chat() {
  const scrollRef = useRef();
  const enterpriseConfig = useSelector(selectConfig);
  const currentChat = useSelector(selectCurrentChat);
  const warRooms = useSelector(selectWarRooms);
  const groupChats = useSelector(selectGroupChats);
  const directChats = useSelector(selectDirectChats);
  const newChatForm = useSelector(selectNewChatForm);
  const isNewChatFormOpen = !!newChatForm;

  useEffect(() => {
    if (scrollRef.current && Object.keys(currentChat).length !== 0) {
      const HEADER_HEIGHT = 56; // pixel height of the list headers
      const ITEM_HEIGHT = 51; // pixel height of the list items
      const SPACING_HEIGHT = 8; // pixel padding between each list

      const { scrollTop, clientHeight } = scrollRef.current.getValues();
      const chatList = [...warRooms, ...groupChats, ...directChats];
      const index = chatList.findIndex((c) => currentChat.cid === c.cid);
      const isMessaging = currentChat.type === 'messaging';
      const isGroup =
        currentChat.type === 'team' &&
        !currentChat.data.isWarRoom &&
        enterpriseConfig.hasGroups;

      let chatPosition = 0;
      let headers; // number of headers to account for;
      let spacing; // number of spaces to account for

      if (isMessaging && enterpriseConfig.hasGroups) {
        headers = 3;
        spacing = 2;
      } else if (isMessaging || isGroup) {
        headers = 2;
        spacing = 1;
      } else {
        headers = 1;
        spacing = 0;
      }

      // Calculate the pixel position for the top of currentChat item in rendered list.
      // The assumed order of render is (from top to bottom):
      //     War Rooms -> Groups (if enabled) -> DMs
      chatPosition =
        ITEM_HEIGHT * (index + 1) +
        HEADER_HEIGHT * headers +
        SPACING_HEIGHT * spacing;

      // Check if position is in the currently visable range (accounting for itself).
      const inRange =
        chatPosition - ITEM_HEIGHT > scrollTop &&
        chatPosition < scrollTop + clientHeight;
      if (!inRange) {
        if (chatPosition - ITEM_HEIGHT < scrollTop) {
          // If the currentChat above the current view
          scrollRef.current.scrollTop(chatPosition - clientHeight / 2);
        }

        if (chatPosition > scrollTop + clientHeight) {
          // If the currentChat below the current view
          scrollRef.current.scrollTop(
            chatPosition - clientHeight + clientHeight / 2
          );
        }
      }
    }
  }, [currentChat]);

  return (
    <MainChatContent>
      {/* Fix overflow issue when tooltips are scrolled out of the viewport. */}
      <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
      <ChatSideBar>
        <WarRooms />
        {enterpriseConfig.hasGroups && <Groups />}
        <DirectMessages />
      </ChatSideBar>
      {!isNewChatFormOpen &&
        Object.keys(currentChat).length !== 0 &&
        (currentChat.type === 'team' ? (
          <GroupChatContent />
        ) : (
          <DirectChatContent />
        ))}
      {isNewChatFormOpen && <NewChatForm />}
    </MainChatContent>
  );
}
