import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const Chip = styled(MuiChip)`
  height: 24px;
  font-size: 12px;
  margin: 2.5px 5px 2.5px 0;

  .MuiChip-label {
    padding-left: 10px;
    padding-right: 10px;
  }
  .MuiChip-avatar {
    background-color: ${({ theme, invalid }) =>
      invalid === 'true' ? '#fff' : theme.palette.secondary.main};
    text-align: center;
    padding: 0 3px;
    width: min-content;
    min-width: 18px;
    height: 18px;
  }
`;

export default Chip;
