import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Navigation from '../../store/navigation/navigationActions';
import { selectNavigationState } from '../../store/navigation/navigationSelectors';

/**
 * Check the documentation for this component in the project README.
 */
const NavigationHelper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { to, options = undefined } = useSelector(selectNavigationState);

  useEffect(() => {
    if (to) {
      navigate(to, options);
      dispatch(Navigation.clear()); // Reset the state after call to navigate.
    }
  }, [to, options, navigate, dispatch]);

  return null;
};

export default NavigationHelper;
