import { AUTH_TYPES } from '../auth/authReducer';
import { generateIdFromUuid } from '../../utils/userUtils';

const INIT_USER_ADMIN_STATE = {
  fetchingUserAdmin: false,
  fetchedUserAdmin: false,
  adminList: [],
  toast: {
    message: '',
    severity: '',
  },
  error: null,
};

export const USER_ADMIN_TYPES = {
  FETCH_USERS: '@@userAdmin/FETCH_USERS',
  CREATE_USER: '@@userAdmin/CREATE_USER',
  UPDATE_USER: '@@userAdmin/UPDATE_USER',
  IMPORT_USERS: '@@userAdmin/IMPORT_USERS',
  DEACTIVATE_USER: '@@userAdmin/DEACTIVATE_USER',
  REACTIVATE_USER: '@@userAdmin/REACTIVATE_USER',
  SEND_EMAIL: '@@userAdmin/SEND_EMAIL',
  RESET_TOTP_ENROLMENT: '@@userAdmin/RESET_TOTP_ENROLMENT',
  SET_TOAST: '@@userAdmin/SET_TOAST',
  RESET_TOAST: '@@userAdmin/RESET_TOAST',
};

export default function userAdmin(state = INIT_USER_ADMIN_STATE, action) {
  switch (action.type) {
    case `${USER_ADMIN_TYPES.FETCH_USERS}_PENDING`:
    case `${USER_ADMIN_TYPES.CREATE_USER}_PENDING`:
    case `${USER_ADMIN_TYPES.UPDATE_USER}_PENDING`:
    case `${USER_ADMIN_TYPES.DEACTIVATE_USER}_PENDING`:
    case `${USER_ADMIN_TYPES.REACTIVATE_USER}_PENDING`:
      return {
        ...state,
        fetchingUserAdmin: true,
        fetchedUserAdmin: false,
      };
    case `${USER_ADMIN_TYPES.FETCH_USERS}_REJECTED`:
    case `${USER_ADMIN_TYPES.CREATE_USER}_REJECTED`:
    case `${USER_ADMIN_TYPES.UPDATE_USER}_REJECTED`:
    case `${USER_ADMIN_TYPES.DEACTIVATE_USER}_REJECTED`:
    case `${USER_ADMIN_TYPES.REACTIVATE_USER}_REJECTED`:
      return {
        ...state,
        fetchingUserAdmin: false,
        fetchedUserAdmin: true,
        error: action.payload,
      };
    case `${USER_ADMIN_TYPES.FETCH_USERS}_FULFILLED`: {
      const { adminList: prevList } = state;
      const list = action.payload.data.reduce((acc, user) => {
        // Find out if we already have this user.
        const existingUser = prevList.find(
          (u) => user.userProperties.uuid === u.userProperties.uuid
        );
        const userWithTopLevelFields = {
          ...user,
          id: generateIdFromUuid(user.userProperties.uuid),
          role: user.userProperties.role || '',
          type: user.userProperties.type || '',
          location: user.userProperties.location || '',
          department: user.userProperties.department || '',
        };

        // If we already have this user, then keep them but shallow merge
        // any changes made to the object...
        if (existingUser) {
          return [...acc, { ...existingUser, ...userWithTopLevelFields }];
        }

        // ... otherwijse, add them to the list and get the regId in the
        // next step.
        return [...acc, userWithTopLevelFields];
      }, []);

      return {
        ...state,
        adminList: list,
        fetchingUserAdmin: false,
        fetchedUserAdmin: true,
      };
    }
    case `${USER_ADMIN_TYPES.CREATE_USER}_FULFILLED`:
    case `${USER_ADMIN_TYPES.UPDATE_USER}_FULFILLED`:
    case `${USER_ADMIN_TYPES.DEACTIVATE_USER}_FULFILLED`:
    case `${USER_ADMIN_TYPES.REACTIVATE_USER}_FULFILLED`: {
      return {
        ...state,
        fetchingUserAdmin: false,
        fetchedUserAdmin: true,
      };
    }
    case `${USER_ADMIN_TYPES.SET_TOAST}`:
      return {
        ...state,
        toast: action.payload,
      };
    case `${USER_ADMIN_TYPES.RESET_TOAST}`:
      return {
        ...state,
        toast: {
          message: '',
          severity: '',
        },
      };
    case AUTH_TYPES.CLEAR:
      return INIT_USER_ADMIN_STATE;
    default:
      return state;
  }
}
