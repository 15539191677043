import SvgIcon from '@mui/material/SvgIcon';

const Undo = (props) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4.5a7.5 7.5 0 1 1-6.819 4.371.75.75 0 0 0-1.362-.626A9 9 0 1 0 12 3v1.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M12 6.699V.801a.374.374 0 0 0-.615-.288l-3.54 2.949a.375.375 0 0 0 0 .576l3.54 2.949A.375.375 0 0 0 12 6.699Z"
    />
  </SvgIcon>
);
export default Undo;
