import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, Navigate, useSearchParams } from 'react-router-dom';

import {
  updateManagementApiConfig,
  setupManagementApiInterceptors,
} from '../../configs/managementApi';
import { MANAGEMENT_NAVIGATION } from '../../configs/management';
import useManagementTimeout from '../../hooks/useManagementTimeout';
import ManagementConsole from '../../store/management/managementActions';
import { selectIsAuthenticated } from '../../store/management/managementSelectors';

import LogoutIcon from '../icons/Logout';
import LogoImage from '../../assets/images/logo.svg?react';

const Page = styled(Stack)`
  background-color: #ececec;
  height: 100vh;
  gap: 1rem;
`;

const AppBar = styled(MuiAppBar)`
  flex-shrink: 0;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
`;

const Logo = styled(LogoImage)`
  width: 150px;
  height: auto;
  justify-self: center;
`;

const ManagementAuthLayout = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(selectIsAuthenticated);
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get('id');

  const handleLogout = useCallback(
    () => dispatch(ManagementConsole.logout()),
    [dispatch]
  );

  // Initialization effect.
  useEffect(() => {
    if (!authenticated && !id) {
      handleLogout();
    }
    if (!!id) {
      dispatch(ManagementConsole.login(id));
      setSearchParams({});
    }
    if (authenticated) {
      setupManagementApiInterceptors();
      updateManagementApiConfig();
    }
  }, [authenticated, id, handleLogout, dispatch, setSearchParams]);

  useManagementTimeout();

  if (!authenticated) {
    return <Navigate to="/management-login" replace />;
  }

  return (
    <Page>
      <AppBar color="white" position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ gap: 4 }}>
            <Logo />
            <Stack
              gap={1}
              direction="row"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              {MANAGEMENT_NAVIGATION.map((link) => (
                <Button
                  to={link.to}
                  key={link.title}
                  component={Link}
                  color="black"
                  sx={{ textTransform: 'none' }}
                >
                  {link.title}
                </Button>
              ))}
            </Stack>
            <Button
              size="small"
              variant="contained"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{ flexShrink: 0 }}
            >
              Sign Out
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Outlet />
      </Container>
    </Page>
  );
};

export default ManagementAuthLayout;
