import { useState, useEffect } from 'react';

import useInterval from './useInterval';

const calculateCountdownValues = (countdown) => {
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
    isTicking: days + hours + minutes + seconds > 0,
  };
};

const useCountdown = (milliseconds = 0) => {
  const [countdown, setCountdown] = useState(milliseconds);

  const handleSetCountdown = (value) => {
    if (typeof value === 'number') {
      return setCountdown(value);
    }
  };

  useEffect(() => {
    if (milliseconds > 0) {
      setCountdown(milliseconds);
    }
  }, [milliseconds]);

  useInterval(
    () => {
      if (countdown > 0) {
        setCountdown((prev) => prev - 1000);
      }
    },
    countdown === 0 ? null : 1000
  );

  return [calculateCountdownValues(countdown), handleSetCountdown];
};

export default useCountdown;
