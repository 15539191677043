import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  selectHasConfig,
  selectHasRegistry,
} from '../../store/org/orgSelectors';
import {
  selectTwoFactorData,
  selectIsAuthenticated,
} from '../../store/auth/authSelectors';
import useCountdown from '../../lib/hooks/useCountdown';
import Authentication from '../../store/auth/authActions';
import { TWO_FACTOR_VERIFICATION_TYPES } from '../../configs';
import { promptForNotifications } from '../../utils/browserUtils';

import TOTPVerification from './TOTPVerification';
import EmailPasswordForm from './EmailPasswordForm';
import EmailCodeVerification from './EmailCodeVerification';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const hasConfig = useSelector(selectHasConfig);
  const hasRegistry = useSelector(selectHasRegistry);
  const twoFactorData = useSelector(selectTwoFactorData);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [nextSubmitCountdown, setNextSubmitCountdown] = useCountdown();

  const inTwoFactorMode = !!twoFactorData;

  const handleClickCancel = useCallback(
    () => dispatch(Authentication.cancelLogin()),
    [dispatch]
  );

  const handleSubmitLogin = useCallback(
    (data) => {
      const email = data.email.trim();
      const password = data.password.trim();
      const rememberEmail = data.rememberEmail;

      promptForNotifications();

      setLoading(true);
      dispatch(Authentication.login(email, password))
        .then(() => {
          if (rememberEmail) {
            window.localStorage.setItem('username', email);
          } else {
            window.localStorage.removeItem('username');
          }
        })
        .finally(() => setLoading(false));
    },
    [dispatch]
  );

  const handleSubmitTwoFactor = useCallback(
    (data) => {
      const code = data.code.trim();

      promptForNotifications();

      setLoading(true);
      dispatch(Authentication.confirm2FA(code))
        .then(() => navigate('/'))
        .catch((e) => {
          const { response } = e;
          if (response.status === 429) {
            return setNextSubmitCountdown(response.data.msUntilNextAttempt);
          }
        })
        .finally(() => setLoading(false));
    },
    [dispatch, navigate, setNextSubmitCountdown]
  );

  if (!hasRegistry && !hasConfig) {
    return <Navigate to="/setup" replace />;
  }

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!inTwoFactorMode) {
    return <EmailPasswordForm loading={loading} onSubmit={handleSubmitLogin} />;
  }

  return twoFactorData.secondFactorType ===
    TWO_FACTOR_VERIFICATION_TYPES.TOTP ? (
    <TOTPVerification
      loading={loading}
      onSubmit={handleSubmitTwoFactor}
      onClickCancel={handleClickCancel}
      submitCountdown={nextSubmitCountdown}
    />
  ) : (
    <EmailCodeVerification
      loading={loading}
      setLoading={setLoading}
      onSubmit={handleSubmitTwoFactor}
      onClickCancel={handleClickCancel}
      submitCountdown={nextSubmitCountdown}
    />
  );
};

export default Login;
