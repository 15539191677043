import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import SvgIcon from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiFormControl from '@mui/material/FormControl';

import {
  TASK_PRIORITY,
  TASK_PRIORITY_LABELS,
  TASK_PRIORITY_OPTIONS,
} from '../store/incident/incidentTypes';

import LowPriorityIcon from '../assets/icons/priority-low.svg?react';
import HighPriorityIcon from '../assets/icons/priority-high.svg?react';
import MediumPriorityIcon from '../assets/icons/priority-medium.svg?react';

const FormControl = styled(MuiFormControl)`
  .MuiInputBase-input {
    padding: 0.25rem 0.5rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-select {
    min-height: auto !important;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${({ theme }) => theme.palette.grey.main};
    }
  }
  .MuiSelect-icon {
    top: calc(50% - 0.75rem);
  }
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

const getIconForPriority = (value) => {
  switch (value) {
    case TASK_PRIORITY.LOW:
      return LowPriorityIcon;
    case TASK_PRIORITY.MEDIUM:
      return MediumPriorityIcon;
    case TASK_PRIORITY.HIGH:
      return HighPriorityIcon;
    default:
      return MediumPriorityIcon;
  }
};

const getColorForPriority = (value) => {
  switch (value) {
    case TASK_PRIORITY.LOW:
      return 'warning';
    case TASK_PRIORITY.MEDIUM:
      return 'orange';
    case TASK_PRIORITY.HIGH:
      return 'error';
    default:
      return 'orange';
  }
};

export const Priority = ({ level, faded = false, sx }) => (
  <Stack direction="row" alignItems="center" gap={1} sx={sx}>
    <SvgIcon
      fontSize="1rem"
      color={getColorForPriority(level)}
      component={getIconForPriority(level)}
    />
    <Typography color={faded ? 'grey' : 'black'} lineHeight={1}>
      {TASK_PRIORITY_LABELS[level]}
    </Typography>
  </Stack>
);

const PrioritySelect = ({ value, onChange, disabled = false, sx }) => (
  <FormControl sx={sx}>
    <Select value={value} onChange={onChange} disabled={disabled}>
      {TASK_PRIORITY_OPTIONS.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          <Priority level={i.value} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default PrioritySelect;
