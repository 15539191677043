import { styled } from '@mui/material/styles';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;
`;

const AudioRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <Container>
      <audio controls src={currentDocument.fileData}>
        Audio playback is not supported by your browser.
      </audio>
    </Container>
  );
};

AudioRenderer.fileTypes = [
  'mp3',
  'audio/mpeg',
  'ogg',
  'audio/ogg',
  'wav',
  'audio/wav',
];
AudioRenderer.weight = 1;

export default AudioRenderer;
