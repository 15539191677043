import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { parseCsvFile } from '../../utils/fileUtils';
import { mapUsersFromCsv } from '../../utils/userUtils';

import BasicModal from '../BasicModal';
import FileInputButton from '../FileInputButton';

const ImportUsersModal = ({
  open,
  onClose,
  onImport,
  onImportFulfilled,
  userAllowance = 0,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (!['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
      return setError('Only valid CSV files are allowed.');
    }
    setFilename(file.name);

    parseCsvFile(file)
      .then((csvUsers) => {
        const newUsers = mapUsersFromCsv(csvUsers);
        if (newUsers.length > userAllowance) {
          return setError(
            `This file contains more than ${userAllowance} users.`
          );
        }
        setUsers(newUsers);
      })
      .catch(() => {
        setError('There was an issue processing the CSV file.');
      });
  };

  const handleClose = (showSuccess = false) => {
    onClose(showSuccess);
    setFilename('');
    setUsers([]);
    setError('');
  };

  const handleImport = () => {
    setLoading(true);
    return onImport(users)
      .then((result) => {
        const nullCount = result.value?.data?.filter((u) => !u)?.length || 0;
        if (nullCount > 0) {
          setError(`Note: ${nullCount} users could not be created.`);
        }
        if (!!onImportFulfilled) {
          onImportFulfilled();
        }
        handleClose(true);
      })
      .catch((e) => {
        console.error(e);
        setError('Something went wrong.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      buttonText="Import"
      title="Import Users"
      onClickButton={handleImport}
      onClose={() => handleClose()}
      buttonDisabled={loading || !users.length}
      sx={{ width: '34rem' }}
    >
      <section>
        <Typography paragraph>
          Begin by{' '}
          <a
            href="/shadowhq-user-list-template.csv"
            download="ShadowHQ-User-List-Template.csv"
            target="_blank"
            rel="noreferrer"
          >
            downloading this CSV template
          </a>{' '}
          to import your users.
        </Typography>
        <Typography paragraph mb={0}>
          Please ensure that the completed CSV file follows these conditions:
        </Typography>
        <ul style={{ margin: 0 }}>
          <li>The column headings are not modified in any way.</li>
          <li>
            Admin and External values should only be <b>YES</b> or <b>NO</b>.
          </li>
          <li>
            Phone numbers must contain 10 digits. Other characters will be
            stripped and ignored.
          </li>
          <li>
            Do not use dummy or fake phone numbers, these will be ignored.
          </li>
        </ul>
      </section>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
      >
        <FileInputButton
          size="small"
          color="secondary"
          disabled={loading}
          variant="contained"
          onChange={handleChangeFile}
          inputProps={{ accept: 'text/csv,application/vnd.ms-excel' }}
        >
          Choose File
        </FileInputButton>
        {!error && (
          <Typography>
            {!!filename && !!users.length ? (
              <>
                <b>File:</b> {filename}
                <br />
                <b>Users:</b> {users.length}
              </>
            ) : (
              'No file chosen.'
            )}
          </Typography>
        )}
        {!!error && <Typography color="error">{error}</Typography>}
      </Stack>
      <Typography>
        <b>Note:</b> There are <b>{userAllowance}</b> more users that can be
        created for this subscription.
      </Typography>
      {!!children && children}
    </BasicModal>
  );
};

export default ImportUsersModal;
