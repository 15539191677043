import { AUTH_TYPES } from '../auth/authReducer';

const INIT_NOTIFICATIONS_STATE = {
  fetchedNotifications: false,
  fetchingNotifications: false,
  markLatestRead: true,
  notifications: [],
};

export const NOTIFICATIONS_TYPES = {
  FETCH_NOTIFICATIONS: '@@notifications/FETCH_NOTIFICATIONS',
  MARK_READ: '@@notifications/MARK_READ',
};

export default function notification(state = INIT_NOTIFICATIONS_STATE, action) {
  switch (action.type) {
    case `${NOTIFICATIONS_TYPES.FETCH_NOTIFICATIONS}_PENDING`:
      return {
        ...state,
        fetchingNotifications: true,
      };
    case `${NOTIFICATIONS_TYPES.FETCH_NOTIFICATIONS}_FULFILLED`:
      return {
        ...state,
        notifications: action.payload.data,
        fetchingNotifications: false,
        fetchedNotifications: true,
      };
    case `${NOTIFICATIONS_TYPES.FETCH_NOTIFICATIONS}_REJECTED`:
      return {
        ...state,
        error: 'Something went wrong fetching',
        fetchingNotifications: false,
      };
    case `${NOTIFICATIONS_TYPES.MARK_READ}_PENDING`:
      return {
        ...state,
        markLatestRead: false,
      };
    case `${NOTIFICATIONS_TYPES.MARK_READ}_FULFILLED`:
      return {
        ...state,
        markLatestRead: true,
      };
    case `${NOTIFICATIONS_TYPES.MARK_READ}_REJECTED`:
      return {
        ...state,
        error: 'Something went wrong reading notification',
      };
    case AUTH_TYPES.CLEAR:
      return INIT_NOTIFICATIONS_STATE;
    default:
      return state;
  }
}
