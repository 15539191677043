import orderBy from 'lodash.orderby';
import { createSelector } from 'reselect';

import {
  selectCurrentUserEmail,
  selectIsCurrentUserAdmin,
} from '../auth/authSelectors';
import { PAGES } from '../../configs';
import { formatTimestamp } from '../../lib/date';
import { selectEnable2faOnSfs } from '../org/orgSelectors';
import { STORAGE_FILTERS, VERIFICATION_STATUSES } from './storageReducer';
import { selectFetchingUsers, selectUserList } from '../user/userSelectors';

export const selectStorageState = (state) => state.storage;

export const selectFetchingItems = createSelector(
  selectStorageState,
  selectFetchingUsers,
  (storageState, fetchingUsers) => storageState.fetching || fetchingUsers
);

export const selectTree = createSelector(
  selectStorageState,
  (state) => state.tree || []
);

export const selectBreadcrumbs = createSelector(
  selectStorageState,
  (state) => state.breadcrumbs
);

export const selectBreadcrumbLinks = createSelector(
  selectBreadcrumbs,
  (breadcrumbs) =>
    breadcrumbs.map((b) => ({
      id: b.id,
      title: b.name,
      parentFolderId: b.parentFolderId,
      to: `${PAGES.fileStorage.pathname}?folder=${b.id}`,
    }))
);

export const selectCurrentFolder = createSelector(
  selectBreadcrumbs,
  (breadcrumbs) =>
    breadcrumbs.length === 0 ? null : breadcrumbs[breadcrumbs.length - 1]
);

export const selectCurrentFolderIsRoot = createSelector(
  selectBreadcrumbs,
  (breadcrumbs) => breadcrumbs.length === 0
);

export const selectCanEditFolderAccess = createSelector(
  selectCurrentFolderIsRoot,
  (isRoot) => isRoot
);

export const selectInheritedFolderProperties = createSelector(
  selectTree,
  selectBreadcrumbs,
  selectCurrentFolderIsRoot,
  (tree, breadcrumbs, isRoot) => {
    if (isRoot) {
      return undefined;
    }
    const folder = tree.find((item) => item.id === breadcrumbs?.[0].id);
    const { folderType, groupIds } = folder; // Select from tree to get the latest.
    return {
      folderType,
      groupIds,
    };
  }
);

export const selectCurrentFolderId = createSelector(
  selectCurrentFolder,
  (folder) => folder?.id || 0
);

export const selectFilter = createSelector(
  selectStorageState,
  (state) => state.filter
);

export const selectFilterIsCreatedByMe = createSelector(
  selectFilter,
  (filter) => filter === STORAGE_FILTERS.CREATED_BY_ME
);

const selectFoldersSource = createSelector(
  selectTree,
  selectCurrentFolderId,
  (tree, currentFolderId) =>
    tree.filter(
      (item) => !!item.folder && item.parentFolderId === currentFolderId
    ) || []
);

export const selectFolders = createSelector(
  selectFoldersSource,
  selectUserList,
  selectCurrentUserEmail,
  selectIsCurrentUserAdmin,
  selectFilterIsCreatedByMe,
  (
    folders,
    userList,
    currentUserEmail,
    isCurrentUserAdmin,
    filterIsCreatedByMe
  ) => {
    if (!userList?.length) {
      return [];
    }

    return folders
      .filter((f) =>
        filterIsCreatedByMe ? f.createdBy === currentUserEmail : true
      )
      .map((f) => {
        const { createTimestamp, updateTimestamp, createdBy } = f;
        const user = userList.find((u) => u.email === createdBy) || {
          firstName: 'Former',
          lastName: 'Member',
        };

        return {
          ...f,
          createdAt: formatTimestamp(createTimestamp),
          updatedAt: formatTimestamp(updateTimestamp),
          createdByName: `${user.firstName} ${user.lastName}`,
          isFolder: true,
          isEditable: isCurrentUserAdmin || createdBy === currentUserEmail,
        };
      });
  }
);

export const selectFilesSource = createSelector(
  selectTree,
  selectCurrentFolderId,
  (tree, currentFolderId) =>
    tree.filter(
      (item) => !item.folder && item.parentFolderId === currentFolderId
    )
);

export const selectFiles = createSelector(
  selectFilesSource,
  selectUserList,
  selectCurrentUserEmail,
  selectIsCurrentUserAdmin,
  selectFilterIsCreatedByMe,
  (
    files,
    userList,
    currentUserEmail,
    isCurrentUserAdmin,
    filterIsCreatedByMe
  ) => {
    if (!userList?.length) {
      return [];
    }

    return files
      .filter((f) =>
        filterIsCreatedByMe ? f.createdBy === currentUserEmail : true
      )
      .map((f) => {
        const { createTimestamp, updateTimestamp, createdBy, extension } = f;
        const user = userList.find((u) => u.email === createdBy) || {
          firstName: 'Former',
          lastName: 'Member',
        };

        return {
          ...f,
          createdAt: formatTimestamp(createTimestamp),
          updatedAt: formatTimestamp(updateTimestamp),
          createdByName: `${user.firstName} ${user.lastName}`,
          isFolder: false,
          isEditable: isCurrentUserAdmin || createdBy === currentUserEmail,
          extension: String(extension).toLowerCase(),
        };
      });
  }
);

export const selectItemList = createSelector(
  selectFolders,
  selectFiles,
  (folders, files) => [
    ...orderBy(folders, ['folderType', 'name'], ['asc', 'asc']),
    ...orderBy(files, ['name'], ['asc']),
  ]
);

export const selectVerification = createSelector(
  selectStorageState,
  (storageState) => storageState.verification
);

export const selectVerificationStatus = createSelector(
  selectVerification,
  (verification) => verification.status
);

export const selectVerificationExpiry = createSelector(
  selectVerification,
  (verification) => verification.expiry
);

export const selectVerificationMsUntilNextAttempt = createSelector(
  selectVerification,
  (verification) => verification.msUntilNextAttempt
);

export const selectShowVerification = createSelector(
  selectVerification,
  selectVerificationStatus,
  (verification, status) =>
    verification.loading ||
    [
      VERIFICATION_STATUSES.REQUESTED,
      VERIFICATION_STATUSES.WAITING,
      VERIFICATION_STATUSES.SENT,
      VERIFICATION_STATUSES.ERROR,
    ].includes(status)
);

export const selectVerificationLoading = createSelector(
  selectVerification,
  (verification) => verification.loading
);

export const selectVerificationRequested = createSelector(
  selectVerification,
  selectVerificationStatus,
  (verification, status) => verification.loading && !status
);

export const selectVerificationSent = createSelector(
  selectVerification,
  selectVerificationStatus,
  (verification, status) =>
    verification.loading && status === VERIFICATION_STATUSES.SENT
);

export const selectVerificationWaiting = createSelector(
  selectVerificationStatus,
  (status) => status === VERIFICATION_STATUSES.WAITING
);

export const selectVerificationError = createSelector(
  selectVerificationStatus,
  (status) => status === VERIFICATION_STATUSES.ERROR
);

export const selectVerified = createSelector(
  selectEnable2faOnSfs,
  selectVerificationExpiry,
  (enable2faOnSfs, expiry) => {
    if (!enable2faOnSfs) {
      return true;
    }
    return !!expiry && Date.now() < expiry;
  }
);
