import { useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import SectionTitle from './SectionTitle';
import GroupChatItem from './GroupChatItem';
import Chats from '../../store/chat/chatActions';
import Users from '../../store/user/userActions';
import SuspenseLoading from '../SuspenseLoading';
import { PulseLogoLoader } from '../loader';
import {
  selectGroupChats,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import { CHAT_TYPES } from '../../lib/string';

// Icons
import People from '../../assets/icons/people.svg?react';

const fallback = (
  <SuspenseLoading
    style={{ height: '150px' }}
    component={<PulseLogoLoader />}
  />
);

export default function Groups() {
  const dispatch = useDispatch();
  const groupChats = useSelector(selectGroupChats) || [];
  const newChatFormType = useSelector(selectNewChatForm);
  const { fetchingChats, fetchedChats } = useSelector((state) => state.chats);
  const [openCollapseList, setOpenCollapseList] = useState(true);

  // Debounce the chat click in order to make sure we do not call
  // the initial fetch too many times and end up with the wrong
  // messages in the chat.
  const onClickChat = debounce((type, id, cid) => {
    dispatch(Chats.setCurrentChat(type, id));
    dispatch(Chats.fetchAttachments(cid));
  }, 100);

  const onCollapseClick = () => {
    openCollapseList ? setOpenCollapseList(false) : setOpenCollapseList(true);
  };

  const createNewGroup = () => {
    if (!!newChatFormType) {
      dispatch(Chats.toggleNewChatForm(''));
    } else {
      dispatch(Users.fetchUsers());
      dispatch(Chats.toggleNewChatForm(CHAT_TYPES.GROUP));
    }
  };

  return (
    <div>
      <SectionTitle
        title="Breakout Rooms"
        open={newChatFormType === CHAT_TYPES.GROUP}
        openCollapseList={openCollapseList}
        onClick={createNewGroup}
        onCollapseClick={onCollapseClick}
      />
      {fetchingChats && !fetchedChats ? (
        fallback
      ) : (
        <Collapse in={openCollapseList}>
          <List sx={{ py: 0 }}>
            {groupChats.map((chat) => (
              <GroupChatItem
                key={chat.id}
                chat={chat}
                onClickChat={() => onClickChat(chat.type, chat.id, chat.cid)}
                icon={People}
              />
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
}
