import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom';
import { v4 } from 'uuid';
import { useEffect } from 'react';

import { store } from './store';
import { isUserAgentMobile } from './utils/browserUtils';
import { removeLeadingSlash } from './utils/stringUtils';
import { useCacheBuster } from './components/CacheBuster';
import { selectOnCall } from './store/jitsi/jitsiSelectors';
import { PAGES, FEATURES, DEPENDENT_FEATURES } from './configs';
import { selectIsRefreshing } from './store/token/tokenSelectors';

import Access from './components/Access';
import Chat from './components/chat/Chat';
import Login from './components/login/Login';
import FileStorage from './components/file-storage';
import IncidentFeed from './components/incidents/Feed';
import IncidentForm from './components/incidents/Form';
import IncidentPage from './components/incidents/Page';
import SetPassword from './components/setup/SetPassword';
import UserAdministration from './components/user-admin';
import MessagingAdmin from './components/messaging-admin';
import ErrorScreen from './components/warnings/ErrorScreen';
import GroupsManager from './components/groups/GroupsManager';
import ForgotPassword from './components/setup/ForgotPassword';
import EnterpriseSetup from './components/setup/EnterpriseSetup';
import OrgCodeReminder from './components/setup/OrgCodeReminder';
import AuthenticatedLayout from './components/AuthenticatedLayout';
import DashboardScreen from './components/dashboard/DashboardScreen';
import PlaybookManager from './components/playbook-manager/PlaybookManager';
import MobileDeviceWarning from './components/warnings/MobileDeviceWarning';
import PostIncidentAnalysisForm from './components/incidents/PostIncidentAnalysisForm';

import ManagementLogin from './components/management-console/ManagementLogin';
import ManagementAuthLayout from './components/management-console/ManagementAuthLayout';
import ManagementOrgs from './components/management-console/orgs';
import ManagementIncidentsByOrg from './components/management-console/incidents/IncidentsByOrg';
import ManagementIncidentDashboard from './components/management-console/incidents/IncidentDashboard';
import ManagementGlobalConfigs from './components/management-console/config/GlobalConfigs';
import ManagementEnterpriseConfigs from './components/management-console/config/EnterpriseConfigs';
import ManagementUsers from './components/management-console/users';
import WelcomePackage from './components/welcome-package';

const App = () => {
  const appId = localStorage.getItem('appId');
  const { checkCacheStatus } = useCacheBuster();

  const pathnames = Object.fromEntries(
    Object.entries(PAGES).map(([pageName, properties]) => [
      pageName,
      removeLeadingSlash(properties.pathname),
    ])
  );

  if (!appId) {
    localStorage.setItem('appId', v4());
  }

  // Utility to show mobile device warning instead of component passed in parameter.
  const hideOnMobile = (component) =>
    isUserAgentMobile() ? <MobileDeviceWarning /> : component;

  useEffect(() => {
    // Event listener to check cache status when the page becomes visible.
    const visibilityChangeListener = () => {
      if (document.visibilityState === 'visible') {
        const onCall = selectOnCall(store.getState());
        const isRefreshingToken = selectIsRefreshing(store.getState());
        // Only check and refresh page if conditions are met.
        if (!onCall && !isRefreshingToken) {
          checkCacheStatus();
        }
      }
    };

    window.addEventListener('visibilitychange', visibilityChangeListener);

    return () => {
      window.removeEventListener('visibilitychange', visibilityChangeListener);
    };
  }, [checkCacheStatus]);

  return (
    <Router>
      <Routes>
        {/* Main App */}
        <Route
          path="/setup"
          element={hideOnMobile(<EnterpriseSetup />)}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/forgot-org-code"
          element={<OrgCodeReminder />}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/login"
          element={hideOnMobile(<Login />)}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/forgot"
          element={<ForgotPassword />}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/set-password"
          element={<SetPassword />}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/"
          element={hideOnMobile(<AuthenticatedLayout />)}
          errorElement={<ErrorScreen />}
        >
          <Route path={pathnames.dashboard} element={<DashboardScreen />} />
          <Route path={pathnames.incidents} element={<IncidentFeed />} />
          <Route
            path={`${pathnames.incidents}/create`}
            element={<IncidentForm />}
          />
          <Route
            path={`${pathnames.incidents}/:id`}
            element={<IncidentPage />}
          />
          <Route
            path={`${pathnames.incidents}/:id/edit`}
            element={<IncidentForm />}
          />
          <Route
            path={`${pathnames.incidents}/:id/post-incident-analysis`}
            element={<PostIncidentAnalysisForm />}
          />
          <Route path={pathnames.chat} element={<Chat />} />
          <Route path={pathnames.fileStorage} element={<FileStorage />} />
          <Route
            path={pathnames.groupAdmin}
            element={
              <Access
                isPage
                permission={FEATURES.groupAdmin}
                dependentFeature={DEPENDENT_FEATURES.groupAdmin}
              >
                <GroupsManager />
              </Access>
            }
          />
          <Route
            path={pathnames.playbooks}
            element={
              <Access isPage dependentFeature={DEPENDENT_FEATURES.playbooks}>
                <PlaybookManager />
              </Access>
            }
          />
          <Route
            path={pathnames.userAdmin}
            element={
              <Access isPage permission={FEATURES.userAdmin}>
                <UserAdministration />
              </Access>
            }
          />
          <Route
            path={pathnames.messagingAdmin}
            element={
              <Access isPage permission={FEATURES.messagingAdmin}>
                <MessagingAdmin />
              </Access>
            }
          />
        </Route>
        {/* Management Console */}
        <Route
          path="/management-login"
          element={hideOnMobile(<ManagementLogin />)}
          errorElement={<ErrorScreen />}
        />
        <Route
          path="/management"
          element={hideOnMobile(<ManagementAuthLayout />)}
          errorElement={<ErrorScreen />}
        >
          <Route path="" element={<ManagementOrgs />} />
          <Route path="organizations" element={<ManagementOrgs />} />
          <Route path="users/:id" element={<ManagementUsers />} />
          <Route path="incidents" element={<ManagementIncidentsByOrg />} />
          <Route
            path="incidents/:id"
            element={<ManagementIncidentDashboard />}
          />
          <Route path="config" element={<ManagementGlobalConfigs />} />
          <Route
            path="config/:enterpriseId"
            element={<ManagementEnterpriseConfigs />}
          />
        </Route>
        {/* Welcome Package */}
        <Route
          path="/welcome"
          element={hideOnMobile(<WelcomePackage />)}
          errorElement={<ErrorScreen />}
        />
        {/* Catch All */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
