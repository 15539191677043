import * as React from "react";
const SvgAppStore = (props) => /* @__PURE__ */ React.createElement("svg", { width: "217.60936pt", height: "64.050781pt", viewBox: "0 0 217.60935 64.050783", id: "svg158", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs47" }, /* @__PURE__ */ React.createElement("clipPath", { id: "clip14" }, /* @__PURE__ */ React.createElement("path", { d: "M 0,0 H 217.60937 V 65 H 0 Z m 0,0", id: "path44" }))), /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#000000",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "M 209.42578,63.242188 H 8.191406 c -4.078125,0 -7.382812,-3.304688 -7.382812,-7.382813 V 8.1875 c 0,-4.078125 3.304687,-7.382812 7.382812,-7.382812 H 209.42578 c 4.07813,0 7.38281,3.304687 7.38281,7.382812 v 47.671875 c 0,4.078125 -3.30468,7.382813 -7.38281,7.382813", id: "path103" }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip14)", clipRule: "nonzero", id: "g107" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#a6a8ab",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 8.1875,1.613281 c -3.625,0 -6.574219,2.949219 -6.574219,6.574219 v 47.671875 c 0,3.625 2.949219,6.574219 6.574219,6.574219 h 201.23828 c 3.625,0 6.57422,-2.949219 6.57422,-6.574219 V 8.1875 c 0,-3.625 -2.94922,-6.574219 -6.57422,-6.574219 z m 201.23828,62.4375 H 8.1875 C 3.671875,64.050781 0,60.375 0,55.859375 V 8.1875 C 0,3.671875 3.671875,-0.00390625 8.1875,-0.00390625 h 201.23828 c 4.51563,0 8.1875,3.67578125 8.1875,8.19140625 v 47.671875 c 0,4.515625 -3.67187,8.191406 -8.1875,8.191406", id: "path105" })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 75.460938,16.761719 -0.828125,-2.59375 c -0.203125,-0.640625 -0.375,-1.355469 -0.53125,-1.964844 h -0.03125 c -0.152344,0.609375 -0.3125,1.339844 -0.496094,1.964844 l -0.8125,2.59375 z m -3.011719,1.449219 -0.90625,2.984375 h -1.984375 l 3.375,-10.523438 h 2.449219 l 3.417968,10.523438 h -2.058593 l -0.953125,-2.984375", id: "path109" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 81.671875,13.59375 1.234375,3.851563 c 0.21875,0.671875 0.371094,1.28125 0.511719,1.90625 h 0.04687 c 0.140625,-0.625 0.3125,-1.21875 0.515625,-1.90625 l 1.21875,-3.851563 h 2.011719 l -2.902344,7.601563 H 82.421875 L 79.59375,13.59375", id: "path111" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 93.003906,17.476563 c -1.359375,-0.01563 -2.65625,0.265625 -2.65625,1.421875 0,0.75 0.484375,1.09375 1.09375,1.09375 0.777344,0 1.328125,-0.5 1.511719,-1.046875 0.05078,-0.140625 0.05078,-0.28125 0.05078,-0.421875 z M 94.875,19.367188 c 0,0.6875 0.03125,1.359375 0.125,1.824218 h -1.734375 l -0.136719,-0.839843 h -0.05078 c -0.449219,0.589843 -1.246094,1.011718 -2.246094,1.011718 -1.53125,0 -2.386718,-1.105468 -2.386718,-2.261718 0,-1.90625 1.699218,-2.886719 4.507812,-2.871094 v -0.125 c 0,-0.5 -0.199219,-1.328125 -1.542969,-1.328125 -0.75,0 -1.53125,0.234375 -2.046875,0.5625 l -0.375,-1.25 c 0.5625,-0.34375 1.546875,-0.667969 2.75,-0.667969 2.433594,0 3.136719,1.542969 3.136719,3.214844", id: "path113" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 97.589844,13.59375 h 1.917969 v 7.601563 H 97.589844 Z M 98.539063,12.5 c -0.636719,0 -1.058594,-0.46875 -1.058594,-1.03125 0,-0.59375 0.433594,-1.046875 1.078125,-1.046875 0.652344,0 1.058594,0.453125 1.074219,1.046875 0,0.5625 -0.421875,1.03125 -1.074219,1.03125", id: "path115" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 102.26953,21.195313 h 1.92188 V 10.09375 h -1.92188 z m 0,0", id: "path117" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 110.99609,17.476563 c -1.35937,-0.01563 -2.65625,0.265625 -2.65625,1.421875 0,0.75 0.48438,1.09375 1.09375,1.09375 0.78125,0 1.32813,-0.5 1.51563,-1.046875 0.0469,-0.140625 0.0469,-0.28125 0.0469,-0.421875 z m 1.8711,1.890625 c 0,0.6875 0.0312,1.359375 0.125,1.824218 h -1.73047 l -0.14063,-0.839843 h -0.0469 c -0.45313,0.589843 -1.25,1.011718 -2.25,1.011718 -1.52734,0 -2.38672,-1.105468 -2.38672,-2.261718 0,-1.90625 1.70313,-2.886719 4.51172,-2.871094 v -0.125 c 0,-0.5 -0.20313,-1.328125 -1.54688,-1.328125 -0.75,0 -1.53125,0.234375 -2.04296,0.5625 l -0.375,-1.25 c 0.5625,-0.34375 1.54296,-0.667969 2.74609,-0.667969 2.43359,0 3.13672,1.542969 3.13672,3.214844", id: "path119" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 117.50391,18.054688 c 0,0.15625 0.0156,0.3125 0.0469,0.453125 0.20313,0.78125 0.875,1.34375 1.69922,1.34375 1.20313,0 1.9375,-0.953125 1.9375,-2.496094 0,-1.34375 -0.64062,-2.4375 -1.92187,-2.4375 -0.78125,0 -1.4961,0.5625 -1.69922,1.40625 -0.0312,0.140625 -0.0625,0.3125 -0.0625,0.5 z m -1.92188,-7.960938 h 1.92188 v 4.542969 h 0.0312 c 0.46875,-0.734375 1.29297,-1.214844 2.43359,-1.214844 1.85938,0 3.18359,1.542969 3.16797,3.855469 0,2.730469 -1.73047,4.089844 -3.44922,4.089844 -0.98437,0 -1.85547,-0.375 -2.40234,-1.3125 h -0.0312 l -0.0937,1.140625 h -1.64063 c 0.0312,-0.515625 0.0625,-1.359375 0.0625,-2.125", id: "path121" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 125.39844,21.195313 h 1.91797 V 10.09375 h -1.91797 z m 0,0", id: "path123" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 134.79297,16.589844 c 0.0156,-0.703125 -0.29688,-1.859375 -1.57422,-1.859375 -1.1875,0 -1.6875,1.078125 -1.76563,1.859375 z m -3.33985,1.339844 c 0.0469,1.375 1.125,1.96875 2.34375,1.96875 0.88672,0 1.51172,-0.140625 2.10547,-0.34375 l 0.28125,1.308593 c -0.65625,0.285157 -1.5625,0.484375 -2.65234,0.484375 -2.46875,0 -3.92188,-1.511718 -3.92188,-3.835937 0,-2.109375 1.28125,-4.089844 3.71875,-4.089844 2.46485,0 3.27735,2.027344 3.27735,3.699219 0,0.359375 -0.0312,0.640625 -0.0625,0.808594", id: "path125" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 146.14062,19.976563 c 1.08985,0 1.85547,-1.0625 1.85547,-2.605469 0,-1.1875 -0.53125,-2.5625 -1.83984,-2.5625 -1.35938,0 -1.90625,1.328125 -1.90625,2.59375 0,1.464844 0.73437,2.574219 1.87109,2.574219 z m -0.0625,1.390625 c -2.14062,0 -3.80859,-1.46875 -3.80859,-3.917969 0,-2.5 1.63672,-4.027344 3.93359,-4.027344 2.26172,0 3.77735,1.589844 3.77735,3.902344 0,2.792969 -1.96875,4.042969 -3.88672,4.042969", id: "path127" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 152.23828,15.855469 c 0,-0.875 -0.0273,-1.609375 -0.0586,-2.265625 h 1.66797 l 0.0937,1.140625 h 0.0469 c 0.34375,-0.59375 1.17188,-1.308594 2.4375,-1.308594 1.32422,0 2.69922,0.855469 2.69922,3.261719 v 4.507812 h -1.92188 v -4.289062 c 0,-1.09375 -0.40625,-1.921875 -1.44921,-1.921875 -0.76563,0 -1.29688,0.546875 -1.5,1.125 -0.0625,0.15625 -0.0937,0.390625 -0.0937,0.609375 v 4.476562 h -1.92188", id: "path129" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 167.97266,11.640625 v 1.949219 h 1.82421 v 1.4375 h -1.82421 v 3.339844 c 0,0.9375 0.25,1.40625 0.98046,1.40625 0.34375,0 0.53125,-0.01563 0.75,-0.07813 l 0.0312,1.449218 c -0.28125,0.113282 -0.79687,0.203125 -1.39062,0.203125 -0.71484,0 -1.29297,-0.234375 -1.65234,-0.621093 -0.42188,-0.4375 -0.60938,-1.140625 -0.60938,-2.140625 v -3.558594 h -1.07812 v -1.4375 h 1.07812 V 12.17187", id: "path131" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 171.93359,10.09375 h 1.92188 v 4.527344 h 0.0312 c 0.23437,-0.359375 0.5625,-0.65625 0.95312,-0.859375 0.39063,-0.214844 0.83985,-0.339844 1.32422,-0.339844 1.29688,0 2.65235,0.855469 2.65235,3.292969 v 4.480469 h -1.91797 v -4.277344 c 0,-1.09375 -0.40625,-1.9375 -1.46875,-1.9375 -0.7461,0 -1.27735,0.5 -1.4961,1.078125 -0.0625,0.171875 -0.0781,0.375 -0.0781,0.578125 v 4.558594 h -1.92188", id: "path133" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 186.24609,16.589844 c 0.0156,-0.703125 -0.29687,-1.859375 -1.57812,-1.859375 -1.1875,0 -1.6836,1.078125 -1.76172,1.859375 z m -3.33984,1.339844 c 0.0469,1.375 1.12109,1.96875 2.33984,1.96875 0.89063,0 1.51563,-0.140625 2.10547,-0.34375 l 0.28516,1.308593 c -0.65625,0.285157 -1.5625,0.484375 -2.65625,0.484375 -2.46485,0 -3.91797,-1.511718 -3.91797,-3.835937 0,-2.109375 1.28125,-4.089844 3.71484,-4.089844 2.46485,0 3.27735,2.027344 3.27735,3.699219 0,0.359375 -0.0312,0.640625 -0.0625,0.808594", id: "path135" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 48.566406,31.398438 c -0.05078,-5.207032 4.261719,-7.742188 4.460938,-7.859375 -2.441406,-3.5625 -6.226563,-4.046875 -7.558594,-4.085938 -3.175781,-0.332031 -6.257812,1.90625 -7.875,1.90625 -1.652344,0 -4.148437,-1.871094 -6.832031,-1.816406 -3.460938,0.05469 -6.695313,2.058594 -8.46875,5.164062 -3.65625,6.339844 -0.929688,15.652344 2.578125,20.777344 1.757812,2.507813 3.804687,5.3125 6.488281,5.210938 2.625,-0.109375 3.605469,-1.671875 6.773438,-1.671875 3.140625,0 4.0625,1.671875 6.800781,1.609375 2.816406,-0.04687 4.589844,-2.519532 6.28125,-5.054688 2.03125,-2.875 2.84375,-5.707031 2.875,-5.851562 -0.06641,-0.02344 -5.46875,-2.085938 -5.523438,-8.328125", id: "path137" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 43.394531,16.085938 c 1.410157,-1.765625 2.382813,-4.171875 2.109375,-6.609375 -2.042968,0.09375 -4.597656,1.414062 -6.070312,3.140625 -1.300781,1.523437 -2.46875,4.019531 -2.164063,6.363281 2.296875,0.171875 4.652344,-1.15625 6.125,-2.894531", id: "path139" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 80.246094,41.339844 -1.8125,-5.597656 c -0.191406,-0.570313 -0.550781,-1.917969 -1.082031,-4.035157 h -0.0625 c -0.210938,0.910157 -0.554688,2.257813 -1.019532,4.035157 l -1.78125,5.597656 z m 6.265625,8.941406 h -3.660156 l -2.003907,-6.296875 h -6.960937 l -1.910156,6.296875 h -3.5625 l 6.90625,-21.4375 h 4.257812", id: "path141" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 100.76172,42.488281 c 0,-1.507812 -0.33984,-2.746093 -1.019532,-3.722656 C 99,37.75 98.003906,37.238281 96.753906,37.238281 c -0.847656,0 -1.621093,0.285157 -2.304687,0.84375 -0.6875,0.566407 -1.140625,1.300782 -1.355469,2.214844 -0.105469,0.421875 -0.15625,0.769531 -0.15625,1.042969 v 2.578125 c 0,1.125 0.34375,2.074219 1.03125,2.847656 0.691406,0.777344 1.585938,1.160156 2.6875,1.160156 1.296875,0 2.304688,-0.496093 3.019531,-1.496093 0.726559,-0.996094 1.085939,-2.308594 1.085939,-3.941407 m 3.5,-0.128906 c 0,2.628906 -0.71094,4.710938 -2.13281,6.234375 -1.27344,1.359375 -2.855472,2.035156 -4.738285,2.035156 -2.039062,0 -3.5,-0.734375 -4.390625,-2.195312 h -0.0625 v 8.144531 H 89.5 V 39.910156 c 0,-1.652343 -0.03906,-3.347656 -0.125,-5.089843 h 3.019531 l 0.191407,2.453125 h 0.06641 c 1.144531,-1.847657 2.882812,-2.769532 5.214844,-2.769532 1.820312,0 3.347648,0.722657 4.562498,2.164063 1.21875,1.445312 1.83203,3.339844 1.83203,5.691406", id: "path143" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 118.53906,42.488281 c 0,-1.507812 -0.33984,-2.746093 -1.01953,-3.722656 -0.74219,-1.015625 -1.73828,-1.527344 -2.98828,-1.527344 -0.84766,0 -1.61719,0.285157 -2.30469,0.84375 -0.6914,0.566407 -1.13672,1.300782 -1.34765,2.214844 -0.10938,0.421875 -0.16016,0.769531 -0.16016,1.042969 v 2.578125 c 0,1.125 0.33984,2.074219 1.02734,2.847656 0.69141,0.777344 1.58594,1.160156 2.69141,1.160156 1.29297,0 2.30078,-0.496093 3.01953,-1.496093 0.71875,-0.996094 1.08203,-2.308594 1.08203,-3.941407 m 3.4961,-0.128906 c 0,2.628906 -0.70703,4.710938 -2.12891,6.234375 -1.27344,1.359375 -2.85156,2.035156 -4.73828,2.035156 -2.03516,0 -3.49609,-0.734375 -4.39063,-2.195312 h -0.0586 v 8.144531 h -3.4375 V 39.910156 c 0,-1.652343 -0.043,-3.347656 -0.125,-5.089843 h 3.01953 l 0.19141,2.453125 h 0.0664 c 1.14063,-1.847657 2.87891,-2.769532 5.21094,-2.769532 1.82422,0 3.34375,0.722657 4.56641,2.164063 1.21875,1.445312 1.82422,3.339844 1.82422,5.691406", id: "path145" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 141.91797,44.269531 c 0,1.820313 -0.63281,3.304688 -1.90625,4.453125 -1.39844,1.25 -3.33985,1.875 -5.83594,1.875 -2.30859,0 -4.16016,-0.445312 -5.5625,-1.335937 l 0.79688,-2.859375 c 1.51171,0.910156 3.16406,1.363281 4.96875,1.363281 1.29296,0 2.30078,-0.289062 3.02343,-0.871094 0.71875,-0.585937 1.07813,-1.367187 1.07813,-2.34375 0,-0.867187 -0.29297,-1.605468 -0.88672,-2.199218 -0.59375,-0.59375 -1.58203,-1.144532 -2.96094,-1.65625 -3.7539,-1.402344 -5.6289,-3.453125 -5.6289,-6.148438 0,-1.761719 0.65625,-3.207031 1.97265,-4.332031 1.3125,-1.125 3.0625,-1.691406 5.2461,-1.691406 1.95312,0 3.57421,0.34375 4.86718,1.019531 l -0.85937,2.800781 c -1.20703,-0.65625 -2.57422,-0.988281 -4.10156,-0.988281 -1.21094,0 -2.15235,0.296875 -2.82813,0.890625 -0.57031,0.53125 -0.85937,1.179687 -0.85937,1.941406 0,0.847656 0.32421,1.546875 0.98437,2.097656 0.57031,0.507813 1.60938,1.058594 3.11719,1.65625 1.84375,0.742188 3.19922,1.609375 4.07031,2.605469 0.86719,0.996094 1.30469,2.238281 1.30469,3.722656", id: "path147" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 153.26953,37.398438 h -3.78516 v 7.503906 c 0,1.910156 0.66797,2.863281 2.00782,2.863281 0.60937,0 1.12109,-0.05078 1.52343,-0.15625 l 0.0937,2.605469 c -0.67187,0.257812 -1.5664,0.382812 -2.66796,0.382812 -1.35547,0 -2.41797,-0.414062 -3.1836,-1.238281 -0.76172,-0.828125 -1.14453,-2.21875 -1.14453,-4.167969 v -7.792968 h -2.25781 v -2.578125 h 2.25781 v -2.828125 l 3.37109,-1.019532 v 3.847657 h 3.78516", id: "path149" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 166.78906,42.535156 c 0,-1.425781 -0.30859,-2.644531 -0.92578,-3.667968 -0.71484,-1.234375 -1.74609,-1.851563 -3.08203,-1.851563 -1.37891,0 -2.42969,0.617188 -3.14844,1.851563 -0.61719,1.023437 -0.91797,2.265625 -0.91797,3.734375 0,1.425781 0.30078,2.644531 0.91797,3.664062 0.74219,1.234375 1.78125,1.851563 3.1211,1.851563 1.30859,0 2.33984,-0.625 3.08203,-1.882813 0.63672,-1.039062 0.95312,-2.273437 0.95312,-3.699219 m 3.5625,-0.109375 c 0,2.375 -0.67578,4.324219 -2.03515,5.851563 -1.42188,1.570312 -3.3086,2.351562 -5.66016,2.351562 -2.27344,0 -4.07813,-0.753906 -5.42578,-2.257812 -1.34375,-1.503906 -2.01563,-3.402344 -2.01563,-5.691406 0,-2.398438 0.69141,-4.359375 2.08203,-5.882813 1.38672,-1.53125 3.25782,-2.292969 5.60938,-2.292969 2.26953,0 4.09766,0.753907 5.47266,2.257813 1.3164,1.46875 1.97265,3.351562 1.97265,5.664062", id: "path151" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 181.51562,37.84375 c -0.33984,-0.0625 -0.69921,-0.09375 -1.08203,-0.09375 -1.20703,0 -2.14453,0.457031 -2.80078,1.367188 -0.57031,0.808593 -0.85547,1.824218 -0.85547,3.050781 v 8.113281 h -3.43359 l 0.0273,-10.589844 c 0,-1.785156 -0.043,-3.40625 -0.125,-4.871093 h 2.99219 l 0.125,2.960937 h 0.0898 c 0.36329,-1.015625 0.9375,-1.835937 1.71875,-2.449219 0.76563,-0.550781 1.59375,-0.828125 2.48438,-0.828125 0.31641,0 0.60547,0.02344 0.85937,0.0625", id: "path153" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ffffff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m 193.59766,40.929688 c 0.0234,-1.015625 -0.19922,-1.898438 -0.66407,-2.640625 -0.59375,-0.953125 -1.51172,-1.433594 -2.73828,-1.433594 -1.12109,0 -2.03515,0.46875 -2.73437,1.402344 -0.57032,0.742187 -0.91407,1.632812 -1.01172,2.671875 z m 3.27343,0.890625 c 0,0.617187 -0.0391,1.132812 -0.12109,1.558593 h -10.30078 c 0.0352,1.527344 0.53515,2.695313 1.49219,3.5 0.86718,0.722657 1.99218,1.078125 3.37109,1.078125 1.52344,0 2.91406,-0.242187 4.16797,-0.730468 l 0.53906,2.386718 c -1.46484,0.632813 -3.19141,0.957032 -5.1875,0.957032 -2.39453,0 -4.27734,-0.707032 -5.64453,-2.121094 -1.36328,-1.40625 -2.05078,-3.300781 -2.05078,-5.675781 0,-2.332032 0.63281,-4.273438 1.91015,-5.820313 1.33204,-1.65625 3.13672,-2.480469 5.40235,-2.480469 2.22656,0 3.91797,0.824219 5.0625,2.480469 0.91015,1.3125 1.35937,2.9375 1.35937,4.867188", id: "path155" })));
export default SvgAppStore;
