import { createSelector } from 'reselect';

import {
  selectCurrentUser,
  selectCurrentUserUUID,
} from '../auth/authSelectors';
import { userCompare } from '../../utils/sortUtils';
import { getChannelPrivilegesForMember } from '../../utils/messagingUtils';

const selectUsersState = (state) => state.users;

export const selectUserList = createSelector(
  selectUsersState,
  (userState) => userState.list
);

export const selectUserListById = createSelector(
  selectUsersState,
  (userState) =>
    Object.fromEntries(userState.list.map((u) => [u.userProperties.uuid, u]))
);

export const selectUsersByMemberIds = createSelector(
  selectUserListById,
  (_, memberIds) => memberIds,
  (userListById, memberIds) =>
    memberIds
      .filter((m) => !!userListById?.[m.user_id])
      .map((m) => ({
        ...m,
        ...userListById[m.user_id],
        ...getChannelPrivilegesForMember(m),
      }))
      .sort(userCompare)
      .sort((a, b) => Number(b.active) - Number(a.active))
);

export const selectLoadingUsers = createSelector(
  selectUsersState,
  (userState) => !userState.fetchingRegIds
);

export const selectUserListLoaded = createSelector(
  selectUsersState,
  (userState) => userState.fetchedUsers
);

export const selectFetchingUsers = createSelector(
  selectUsersState,
  (userState) => userState.fetchingUsers
);

export const selectLastActiveTimestamps = createSelector(
  selectUsersState,
  (userState) => userState.lastActiveTimestamps
);

export const selectLastActiveUserMap = createSelector(
  selectLastActiveTimestamps,
  (lastActiveTimestamps) =>
    new Map(
      (lastActiveTimestamps || []).map((item) => [
        item.email,
        item.lastActivityTimestamp,
      ])
    )
);

export const selectFetchUsers = createSelector(
  selectUsersState,
  (userState) => !userState.fetchingUsers && !userState.fetchedUsers
);

export const selectUsersError = createSelector(
  selectUsersState,
  (userState) => userState.error
);

export const selectActiveUserList = createSelector(selectUserList, (userList) =>
  userList.filter((u) => u.active)
);

export const selectSortedUsers = createSelector(selectUserList, (userList) =>
  userList.filter((u) => u.active).sort(userCompare)
);

export const selectAutocompleteUserList = createSelector(
  selectUserList,
  selectCurrentUserUUID,
  (_, { excludedUUIDs = [], excludeCurrentUser = false }) => ({
    excludedUUIDs,
    excludeCurrentUser,
  }),
  (userList, currentUserUUID, { excludedUUIDs, excludeCurrentUser }) =>
    userList
      .filter((u) => {
        const currentUserCondition = excludeCurrentUser
          ? u.userProperties.uuid !== currentUserUUID
          : true;

        return (
          u.active &&
          !!u.email &&
          !u.banned &&
          !u.dashboard_user &&
          currentUserCondition &&
          !excludedUUIDs.includes(u.userProperties.uuid)
        );
      })
      .sort(userCompare)
);

export const selectFilteredAndSortedUserListWithoutMembers = createSelector(
  selectUserList,
  selectCurrentUser,
  (_, filters, groupMembers) => ({ filters, groupMembers }),
  (userList, currentUser, { filters, groupMembers }) =>
    userList
      .filter(
        (u) =>
          !u.banned &&
          !u.dashboard_user &&
          !!u.email &&
          u.id !== currentUser.uuid
      )
      .filter((u) => {
        if (filters.external) {
          return true;
        }
        return u.userProperties.type === 'internal';
      })
      .filter(
        (e) =>
          !groupMembers.some(
            (member) => member.userProperties.uuid === e.userProperties.uuid
          )
      )
      .sort(userCompare)
);

export const selectFilteredAndSortedUserList = createSelector(
  selectUserList,
  selectCurrentUser,
  (_, filters) => filters,
  (userList, currentUser, filters) =>
    userList
      .filter(
        (u) =>
          !u.banned &&
          !u.dashboard_user &&
          !!u.email &&
          u.userProperties.uuid !== currentUser.uuid
      )
      .filter((u) => {
        if (filters.external) {
          return true;
        }
        return u.userProperties.type === 'internal';
      })
      .sort(userCompare)
);

export const selectFilteredUserList = createSelector(
  selectSortedUsers,
  selectCurrentUser,
  (userList, currentUser) =>
    // Make sure you can't select yourself as well as any users that
    // have not logged in yet
    userList.filter((u) => u.userProperties.uuid !== currentUser.uuid)
);
