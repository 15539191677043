import { useCallback, useState } from 'react';

/**
 * @param {String} initialCopyPrompt - The prompt text the user will see.
 * @returns {Array} - [copyPrompt, copy]
 */
const useCopyToClipboard = (initialCopyPrompt = 'Copy') => {
  const [copyPrompt, setCopyPrompt] = useState(initialCopyPrompt);

  const copy = useCallback(
    async (text) => {
      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported.');
        return false;
      }

      try {
        await navigator.clipboard.writeText(text);
        setCopyPrompt('Copied!');
        setTimeout(() => setCopyPrompt(initialCopyPrompt), 3000);
        return true;
      } catch (error) {
        console.warn('Copy failed.', error);
        setCopyPrompt('Copy failed.');
        return false;
      }
    },
    [initialCopyPrompt]
  );

  return [copyPrompt, copy];
};

export default useCopyToClipboard;
