import { TOKEN_TYPES } from './tokenReducer';

const Token = {
  setIsRefreshing: (status) => ({
    type: TOKEN_TYPES.SET_IS_REFRESHING,
    payload: status,
  }),
};

export default Token;
