import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentUrl } from '../../../configs/management';
import { managementApi } from '../../../configs/managementApi';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';
import ManagementConsole from '../../../store/management/managementActions';

import Toolbar from './Toolbar';
import GearIcon from '../../icons/Gear';
import MailIcon from '../../icons/Mail';
import PencilIcon from '../../icons/Pencil';
import DeleteIcon from '../../icons/CircleX';
import EditOrgModal from './EditOrgModal';
import CreateOrgModal from './CreateOrgModal';
import DeleteOrgModal from './DeleteOrgModal';
import ManagementDataGrid from '../ManagementDataGrid';
import AddStreamCredsModal from './AddStreamCredsModal';
import UserIcon from '../../../assets/icons/people.svg?react';

const MODALS = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  STREAM_CREDS: 'STREAM_CREDS',
  CANCEL: 'CANCEL',
};

const ManagementOrganizations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    rows: [],
    loading: false,
  });
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState('');
  const [pageSize, setPageSize] = useState(0);
  const [enterprise, setEnterprise] = useState(null);
  const [goToLastPage, setGoToLastPage] = useState(false);
  const [confirmSendWelcomeElement, openConfirmSendWelcome] = useConfirmAsync();

  const fetchData = useCallback(() => {
    setData((prev) => ({ ...prev, loading: true }));
    managementApi.get('/enterprises').then(({ data }) => {
      setData({
        rows: data.map((r) => ({
          ...r.enterprise,
          name: r.enterprise.displayName,
          domain: r.enterprise.enterpriseDomain,
          adminName: `${r.adminUser.firstName} ${r.adminUser.lastName}`,
          adminEmail: r.adminUser.email,
          contractNumber: r.subscription.contractNumber,
          subscriptionLevel: r.subscription.level,
          subscriptionMaxUsers: r.subscription.maxUser,
          welcomePackageStatus: r.welcomePackageStatus,
        })),
      });
      if (goToLastPage) {
        setPage(Math.floor(data.length / pageSize));
      }

      // Fetch dashboard data.
      dispatch(ManagementConsole.fetchDashboard()).then(() => {
        setData((prev) => ({
          ...prev,
          loading: false,
        }));
      });
    });
  }, [goToLastPage, pageSize, dispatch]);

  const handleClickRow = (row) => navigate(`/management/incidents/${row.id}`);

  const handleClickAction = (callback) => (e) => {
    e.stopPropagation();
    callback();
  };

  const handleClickEdit = (enterprise) => {
    setEnterprise(enterprise);
    setModal(MODALS.EDIT);
  };

  const handleClickUsers = (enterprise) =>
    navigate(`/management/users/${enterprise.id}`);

  const handleClickConfigure = (enterprise) =>
    navigate(`/management/config/${enterprise.id}`);

  const handleClickDelete = (enterprise) => {
    setEnterprise(enterprise);
    setModal(MODALS.DELETE);
  };

  const handleClickSendWelcome = (enterprise) => {
    const { name, adminEmail } = enterprise;

    openConfirmSendWelcome({
      title: 'Send Welcome Package Invite',
      content: (
        <Typography align="center">
          An email will be sent to <b>{adminEmail}</b> at <b>{name}</b> to
          invite them to complete the ShadowHQ Welcome Package and setup their
          organization.
        </Typography>
      ),
      buttonText: 'Send Invite',
      onConfirm: () =>
        managementApi
          .post(
            `/enterprise/${
              enterprise.id
            }/sendWelcomeEmail?feUrl=${getCurrentUrl()}`
          )
          .then(fetchData),
    });
  };

  const handleCloseModal = (isCancel = false) => {
    setGoToLastPage(isCancel ? false : modal === MODALS.CREATE);
    setEnterprise(null);
    setModal(isCancel ? MODALS.CANCEL : '');
  };

  // Fetch effect.
  useEffect(() => {
    const { CREATE, EDIT, DELETE, CANCEL } = MODALS;
    if (![CREATE, EDIT, DELETE, CANCEL].includes(modal)) {
      fetchData();
    }
  }, [location.pathname, modal, fetchData]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ManagementDataGrid
        rows={data.rows}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'domain',
            headerName: 'Domain',
            flex: 1,
          },
          {
            field: 'adminName',
            headerName: 'Contact Name',
            flex: 1,
          },
          {
            field: 'adminEmail',
            headerName: 'Contact Email',
            flex: 1.25,
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: 1.05,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row: enterprise }) => {
              const { welcomePackageStatus: wps } = enterprise;

              const welcomePackageButtonDisabled = [
                'NOT_APPLICABLE',
                'SUBMITTED',
                'COMPLETED',
                'ERROR',
              ].includes(wps);

              const welcomePackageTooltipText =
                wps === 'NOT_APPLICABLE'
                  ? 'Not Applicable'
                  : ['CREATED', 'SAVED'].includes(wps)
                    ? 'Send Welcome Package Again'
                    : ['SUBMITTED', 'COMPLETED', 'ERROR'].includes(wps)
                      ? 'Welcome Package Completed'
                      : 'Send Welcome Package';

              return (
                <Stack direction="row" gap={0.25}>
                  <Tooltip disableInteractive title={welcomePackageTooltipText}>
                    <span>
                      <IconButton
                        disabled={welcomePackageButtonDisabled}
                        onClick={handleClickAction(() =>
                          handleClickSendWelcome(enterprise)
                        )}
                      >
                        <MailIcon
                          color={
                            welcomePackageButtonDisabled ? 'default' : 'warning'
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Edit" disableInteractive>
                    <span>
                      <IconButton
                        onClick={handleClickAction(() =>
                          handleClickEdit(enterprise)
                        )}
                      >
                        <PencilIcon color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Manage Users" disableInteractive>
                    <span>
                      <IconButton
                        onClick={handleClickAction(() =>
                          handleClickUsers(enterprise)
                        )}
                      >
                        <SvgIcon color="primary" component={UserIcon} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Configure" disableInteractive>
                    <span>
                      <IconButton
                        onClick={handleClickAction(() =>
                          handleClickConfigure(enterprise)
                        )}
                      >
                        <GearIcon color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete" disableInteractive>
                    <span>
                      <IconButton
                        onClick={handleClickAction(() =>
                          handleClickDelete(enterprise)
                        )}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              );
            },
          },
        ]}
        pagination
        page={page}
        autoPageSize
        rowHeight={42}
        headerHeight={42}
        disableColumnMenu
        disableColumnFilter
        loading={data.loading}
        disableSelectionOnClick
        onPageChange={(newPage) => setPage(newPage)}
        onRowClick={({ row }) => handleClickRow(row)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        components={{ Toolbar }}
        componentsProps={{
          toolbar: {
            onClickCreate: () => setModal(MODALS.CREATE),
            onClickAddStreamCreds: () => setModal(MODALS.STREAM_CREDS),
            sx: { mb: 2 },
          },
        }}
        localeText={{
          noRowsLabel: 'No organizations to show.',
          footerRowSelected: () => '',
        }}
      />
      <CreateOrgModal
        open={modal === MODALS.CREATE}
        onClose={handleCloseModal}
      />
      <EditOrgModal
        open={modal === MODALS.EDIT}
        onClose={handleCloseModal}
        enterprise={enterprise}
      />
      <DeleteOrgModal
        open={modal === MODALS.DELETE}
        onClose={handleCloseModal}
        enterprise={enterprise}
      />
      <AddStreamCredsModal
        open={modal === MODALS.STREAM_CREDS}
        onClose={handleCloseModal}
      />
      {confirmSendWelcomeElement}
    </Box>
  );
};

export default ManagementOrganizations;
