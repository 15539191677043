import { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import Chats from '../../store/chat/chatActions';
import { formatDateAsMMDDYY } from '../../lib/date';
import { downloadFile } from '../../utils/networkUtils';
import { caseInsensitiveCompare } from '../../utils/sortUtils';

const ExportBar = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  display: flex;
  width: 100%;
  height: ${({ open }) => (!open ? '0' : '64px')};
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 1em 1.5em;
  border-bottom: 1px solid #ddd;
  z-index: 1;

  opacity: ${({ open }) => (open ? '100%' : '0')};
  visibility: ${({ open }) => !open && 'hidden'};

  transition:
    height 0.5s linear,
    opacity 0.75s linear,
    visibility 0.1s ease-in;
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
`;

const DateField = styled(TextField)`
  > .MuiOutlinedInput-root {
    border-radius: 4px;
  }
`;

export default function ExportChat({ open, onClose }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.chats.exportData);
  const startRef = useRef();
  const [start, setStart] = useState(dayjs());
  const [end, setEnd] = useState(dayjs());
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const { channel, members, messages } = data;
    if (messages && messages.length > 0) {
      const groupName =
        channel.type === 'team'
          ? channel.name
          : members
              .map((m) => m.user.name)
              .sort(caseInsensitiveCompare)
              .join(', ');

      const headers = `Chat: ${groupName}\nStart Date: ${start.toISOString()}\nEnd Date: ${end.toISOString()}\n`;

      const content = messages.reduce((acc, m) => {
        const attachmentString = m.attachments
          .map((a) => `[Attachment: ${a?.title || a?.name || a?.fallback}]`)
          .join('\n');
        return `${acc}\n${m.user.name} [${m.updated_at}]:\n${m.text}${
          !!m.attachments.length ? attachmentString : ''
        }\n`;
      }, headers);

      downloadFile({
        name: `Chat - ${groupName} - ${formatDateAsMMDDYY(
          start
        )}-to-${formatDateAsMMDDYY(end)}`,
        data: content,
        options: { type: 'text/plain' },
      });

      dispatch(Chats.clearExportData());
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setStart(dayjs());
      setEnd(dayjs());
    }
  }, [open]);

  const handleExport = () => {
    let sDate = start;
    let eDate = end;

    if (start.isSame(end)) {
      sDate = start.subtract(1, 'day');
    } else {
      eDate = end.add(1, 'day');
    }

    setFetching(true);
    dispatch(Chats.exportChat(sDate.toISOString(), eDate.toISOString()))
      .then(() => {
        setFetching(false);

        onClose();
      })
      .catch(() => setFetching(false));
  };

  const setDate = (date, type) => {
    if (type === 'start') {
      // Make sure start date doesn't go past end date
      if (date.valueOf() > end.valueOf()) {
        setStart(end);
      } else {
        setStart(date);
      }
    }

    // Make sure end date doesn't go before start date.
    if (type === 'end') {
      if (date.valueOf() < start.valueOf()) {
        setEnd(start);
      } else {
        setEnd(date);
      }
    }
  };

  return (
    <ExportBar open={open}>
      <DateRange open={open}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            ref={startRef}
            label="Start Date"
            inputFormat="DD/MM/YYYY"
            value={start}
            onChange={(value) => setDate(value, 'start')}
            renderInput={(params) => (
              <DateField {...params} error={false} size="small" />
            )}
          />
          <span style={{ margin: '0 5px' }}>to</span>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={end}
            onChange={(value) => setDate(value, 'end')}
            renderInput={(params) => (
              <DateField {...params} error={false} size="small" />
            )}
          />
        </LocalizationProvider>
      </DateRange>
      <div style={{ visibility: open ? 'visible' : 'hidden' }}>
        <Button variant="contained" onClick={handleExport} disabled={fetching}>
          Export
        </Button>
        <span style={{ margin: '0 5px' }} />
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </ExportBar>
  );
}
