import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import HeadingLayout from './HeadingLayout';
import EditItemControl from '../EditItemControl';

const Heading = ({
  description,
  onClickEdit,
  onClickDelete,
  isEditable = false,
}) => (
  <HeadingLayout
    isEditable={isEditable}
    rightComponent={
      <EditItemControl
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />
    }
  >
    <ReactMarkdown plugins={[remarkGfm]}>{description}</ReactMarkdown>
  </HeadingLayout>
);

export default Heading;
