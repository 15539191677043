import { Link } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { handlePaste } from '../../lib/input';
import useToast from '../../lib/hooks/useToast';
import { MAX_LENGTH } from '../../configs/validation';
import Storage from '../../store/storage/storageActions';
import { PAGES, FEATURES, FOLDER_TYPES } from '../../configs';
import { selectAutocompleteGroupList } from '../../store/group/groupSelectors';
import { selectCurrentFolderIsRoot } from '../../store/storage/storageSelectors';
import { selectIsCurrentUserAdmin } from '../../store/auth/authSelectors';

import Access from '../Access';
import BasicModal from '../BasicModal';
import FolderTypeSelect from './FolderTypeSelect';
import GroupAutocomplete from '../GroupAutocomplete';
import FolderIcon from '../../assets/icons/filetype-folder.svg?react';

const FolderModal = ({ open, item, onClose }) => {
  const editing = !!item;
  const dispatch = useDispatch();
  const [toastElement, openToast] = useToast();
  const [loading, setLoading] = useState(false);
  const isUserAdmin = useSelector(selectIsCurrentUserAdmin);
  const groupList = useSelector(selectAutocompleteGroupList);
  const isCurrentFolderRoot = useSelector(selectCurrentFolderIsRoot);

  const initialValues = {
    name: editing ? item?.name : '',
    folderType: editing
      ? item?.folderType || FOLDER_TYPES.PUBLIC
      : FOLDER_TYPES.PUBLIC,
    groups: editing
      ? groupList.filter((g) => (item?.groupIds || []).includes(g.id))
      : [],
  };

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: initialValues,
    values: initialValues,
  });

  const name = watch('name');
  const folderType = watch('folderType');
  const isPrivate = folderType === FOLDER_TYPES.PRIVATE;
  const buttonDisabled = name.length < 3 || name.length >= MAX_LENGTH.value;

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleCreateOrEdit = useCallback(
    (formData) => {
      const data = {
        name: formData.name.trim(),
        folderType: formData.folderType,
        groupIds: formData.groups.map((g) => g.id),
      };

      setLoading(true);
      return dispatch(
        editing
          ? Storage.editFolder(item?.id, data)
          : Storage.createFolder(data)
      )
        .then(handleClose)
        .catch((e) => {
          const { message, response } = e;
          openToast(
            response.status === 403
              ? 'Cannot remove all groups giving you access to this folder.'
              : message,
            'error',
            3000
          );
          reset();
        })
        .finally(() => setLoading(false));
    },
    [dispatch, editing, handleClose, item?.id, openToast, reset]
  );

  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      buttonDisabled={buttonDisabled}
      title={`${editing ? 'Edit' : 'Create'} Folder`}
      onClickButton={handleSubmit(handleCreateOrEdit)}
      sx={{ width: '32rem' }}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          validate: (value) => {
            if (value.trim().length >= MAX_LENGTH.value) {
              return MAX_LENGTH.message;
            }
            if (value.trim().length < 3) {
              return 'Folder name must contain at least 3 characters.';
            }
            return true;
          },
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              autoFocus
              size="small"
              label="Name"
              color="primary"
              autoComplete="off"
              disabled={loading}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              InputProps={{
                endAdornment: (
                  <SvgIcon color="primary" component={FolderIcon} />
                ),
              }}
            />
          );
        }}
      />
      {isCurrentFolderRoot && (
        <Stack gap={2}>
          <Typography>Options</Typography>
          <Controller
            name="folderType"
            control={control}
            render={({ field }) => <FolderTypeSelect {...field} />}
          />
          {isPrivate && (
            <Stack gap={1}>
              <Controller
                name="groups"
                control={control}
                rules={{
                  validate: (value) => {
                    return isUserAdmin
                      ? true
                      : value.filter((g) => g.canAccess).length === 0
                        ? 'At least one of your groups must be selected.'
                        : true;
                  },
                }}
                render={({ field }) => (
                  <GroupAutocomplete
                    {...field}
                    disableClearable
                    error={!!errors?.groups}
                    helperText={
                      !!errors?.groups
                        ? errors.groups.message
                        : 'Users in the groups selected will have read and write access to this folder.'
                    }
                  />
                )}
              />
              <Access
                permission={FEATURES.groupAdmin}
                dependentFeature={FEATURES.groupAdmin}
              >
                <Link
                  to={PAGES.groupAdmin.pathname}
                  style={{ alignSelf: 'flex-start' }}
                >
                  Manage Groups
                </Link>
              </Access>
            </Stack>
          )}
        </Stack>
      )}
      {toastElement}
    </BasicModal>
  );
};

export default FolderModal;
