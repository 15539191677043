import { createSelector } from 'reselect';

const selectUserAdminState = (state) => state.userAdmin;

export const selectUserAdminList = createSelector(
  selectUserAdminState,
  (userAdminState) =>
    userAdminState.adminList.map((user) => ({
      ...user,
      id: user.email,
    }))
);

export const selectUserAdminFetching = createSelector(
  selectUserAdminState,
  (userAdminState) => userAdminState.fetchingUserAdmin
);

export const selectUserAdminFetched = createSelector(
  selectUserAdminState,
  (userAdminState) => userAdminState.fetchedUserAdmin
);

export const selectToast = createSelector(
  selectUserAdminState,
  (userAdminState) => userAdminState.toast
);

export const selectModal = createSelector(
  selectUserAdminState,
  (userAdminState) => userAdminState.modal
);
