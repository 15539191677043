import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  ContentMain,
  ContentTitleBar,
  ChatTitle,
  ChatActions,
} from './ChatContent';
import ChatActionButton from './ChatActionButton';
import TextChat from './TextChat';
import ChatMessageInput from './input/ChatMessageInput';
import ConfirmModal from '../ConfirmModal';
import GroupInfoPanel from './GroupInfoPanel';
import ExportChat from './ExportChat';
import JitsiCalls from '../../store/jitsi/jitsiActions';
import Users from '../../store/user/userActions';
import Chats from '../../store/chat/chatActions';
import {
  selectJwt,
  selectFetchingJwt,
  selectOnCall,
  selectChatId,
  selectHasCall,
} from '../../store/jitsi/jitsiSelectors';
import {
  selectCurrentChat,
  selectGroupMembers,
  selectIsOwner,
  selectIsMod,
} from '../../store/chat/chatSelectors';
import { selectCurrentUserUUID } from '../../store/auth/authSelectors';

import QuickEditTextField from './QuickEditTextField';
import Leave from '../../assets/icons/log-out-outline.svg?react';
import Info from '../../assets/icons/info-outline.svg?react';
import Phone from '../../assets/icons/phone-outline.svg?react';
import Video from '../../assets/icons/video-outline.svg?react';
import Clipboard from '../../assets/icons/clipboard-outline.svg?react';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`;

export default function ChatContent() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const currentChat = useSelector(selectCurrentChat);
  const groupMembers = useSelector(selectGroupMembers);
  const isOwner = useSelector((state) =>
    selectIsOwner(state, { uuid: currentUserUUID })
  );
  const isMod = useSelector((state) =>
    selectIsMod(state, { uuid: currentUserUUID })
  );
  const onJitsiCall = useSelector(selectOnCall);
  const jwt = useSelector(selectJwt);
  const fetching = useSelector(selectFetchingJwt);
  const jitsiChatId = useSelector(selectChatId);
  const [infoOpen, setInfoOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const { id, name, isWarRoom, cid } = currentChat.data;
  const hasCallOngoing = useSelector((state) =>
    selectHasCall(state, { chatId: cid })
  );

  const canCall = !jwt && !fetching && !onJitsiCall;

  useEffect(() => {
    if (hasCallOngoing) {
      dispatch(JitsiCalls.fetchDialInOptions());
    }
  }, [hasCallOngoing, dispatch]);

  useEffect(() => {
    setInfoOpen(false);
    setExportOpen(false);
  }, [id]);

  const leaveGroup = () => {
    let canLeave = true;

    if (isOwner || isMod) {
      let otherAdmin = null;

      groupMembers
        .filter((m) => m.userProperties.uuid !== currentUserUUID)
        .forEach((m) => {
          if (m.isAdmin && !otherAdmin) {
            otherAdmin = m;
          }
        });

      if (!otherAdmin) {
        canLeave = false;
      }
    }

    if (canLeave) {
      setLeaveOpen(true);
    } else {
      setErrorOpen(true);
    }
  };

  const confirmLeaveGroup = useCallback(() => {
    dispatch(Chats.leaveGroup());

    setLeaveOpen(false);
  }, []);

  const openInfo = useCallback(() => {
    if (!infoOpen) {
      dispatch(Users.fetchUsers());
    }

    setInfoOpen((prevInfoOpen) => !prevInfoOpen);
  }, []);

  const makeCall = (videoMuted) => {
    dispatch(JitsiCalls.getJWT(currentChat.cid)).then(() =>
      dispatch(JitsiCalls.joinCall(videoMuted))
    );
  };

  const makeWarRoom = () => {
    currentChat.updatePartial({ set: { isWarRoom: true } });
  };

  const handleRename = (name) => currentChat.updatePartial({ set: { name } });

  return (
    <Container>
      <ContentMain>
        <ContentTitleBar>
          <ChatTitle>
            <small>Messenger</small>
            <span>
              Talking in{' '}
              <QuickEditTextField
                initialValue={name}
                onSave={handleRename}
                initialSx={{ fontWeight: 500 }}
                sx={{ display: 'inline-block' }}
              />
            </span>
          </ChatTitle>
          <ChatActions>
            {!onJitsiCall && (
              <>
                <ChatActionButton
                  tooltip="Join Conference with Video Off"
                  disabled={!canCall}
                  icon={<Phone />}
                  action={() => makeCall(true)}
                />
                <ChatActionButton
                  tooltip="Join Conference with Video On"
                  disabled={!canCall}
                  icon={<Video />}
                  action={() => makeCall(false)}
                />
              </>
            )}
            <ChatActionButton
              tooltip="Export Conversation"
              icon={<Clipboard />}
              action={() => setExportOpen(true)}
            />
            <ChatActionButton
              tooltip="Leave Breakout Room"
              icon={<Leave />}
              action={leaveGroup}
            />
            <ChatActionButton
              tooltip="Info"
              icon={<Info />}
              action={openInfo}
            />
            <ConfirmModal
              open={errorOpen}
              headerText="Leave Breakout Room"
              confirmText="Ok"
              onConfirm={() => setErrorOpen(false)}
            >
              <p>
                You cannot leave the breakout room because you are the only
                admin.
              </p>
              <p>
                Please promote a member before you leave or delete the breakout
                room.
              </p>
            </ConfirmModal>
            <ConfirmModal
              open={leaveOpen}
              headerText="Leave Breakout Room"
              confirmText="Leave"
              onClose={() => setLeaveOpen(false)}
              onConfirm={confirmLeaveGroup}
              showClose
            >
              <p>Are you sure you want to leave this breakout room?</p>
              <p>If you leave, you will have to be reinvited by an admin.</p>
            </ConfirmModal>
          </ChatActions>
          <ExportChat open={exportOpen} onClose={() => setExportOpen(false)} />
        </ContentTitleBar>
        <TextChat scrollRef={scrollRef} />
        <ChatMessageInput
          scrollToBottom={() => scrollRef.current.scrollToBottom()}
        />
      </ContentMain>
      <GroupInfoPanel
        chat={currentChat}
        open={infoOpen}
        isAdmin={isMod || isOwner}
        isOwner={isOwner}
        isWarRoom={isWarRoom}
        isCurrentCall={onJitsiCall && jitsiChatId === currentChat.cid}
        onClose={() => setInfoOpen(false)}
        leaveGroup={leaveGroup}
        makeWarRoom={makeWarRoom}
      />
    </Container>
  );
}
