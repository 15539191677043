import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { MAX_LENGTH } from '../../configs/validation';
import MessagingAdmin from '../../store/messaging-admin/messagingAdminActions';
import { selectChannel } from '../../store/messaging-admin/messagingAdminSelectors';

import BasicModal from '../BasicModal';

const EditChannelModal = ({ open, cid, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const channel = useSelector((state) => selectChannel(state, cid));

  const initialValues = {
    name: !!channel ? channel?.name : '',
    description: !!channel ? channel?.description : '',
    isWarRoom: !!channel ? channel?.isWarRoom : false,
  };
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values: initialValues,
    defaultValues: initialValues,
  });

  const title = `Edit '${channel?.name}'`;

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (data) => {
    setLoading(true);
    return dispatch(MessagingAdmin.editChannel(cid, data))
      .then(() => {
        dispatch(
          MessagingAdmin.setToastMessage({
            message: 'Channel updated successfully.',
            severity: 'success',
          })
        );
        handleClose();
      })
      .catch(({ response }) => {
        console.error(response);
        dispatch(
          MessagingAdmin.setToastMessage({
            message: !!response?.data ? response.data : 'Something went wrong.',
            severity: 'error',
          })
        );
      })
      .finally(() => setLoading(false));
  };

  if (!cid || !open) {
    return null;
  }

  return (
    <BasicModal
      open={open}
      title={title}
      onClose={handleClose}
      onClickButton={handleSubmit(handleSave)}
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'Required',
          minLength: 3,
          maxLength: MAX_LENGTH,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            size="small"
            label="Name"
            disabled={loading}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            inputProps={{ maxLength: MAX_LENGTH.value }}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          maxLength: MAX_LENGTH,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            minRows={3}
            size="small"
            label="Description"
            disabled={loading}
            error={!!errors?.description}
            helperText={errors?.description?.message}
            inputProps={{ maxLength: MAX_LENGTH.value }}
          />
        )}
      />
      <Controller
        name="isWarRoom"
        control={control}
        render={({ field }) => {
          const { value } = field;
          return (
            <FormControl disabled={loading}>
              <FormControlLabel
                label="War Room"
                labelPlacement="end"
                control={
                  <Checkbox {...field} checked={value} sx={{ px: 0, py: 0 }} />
                }
                sx={{ ml: 0, mr: 0, gap: 1 }}
              />
            </FormControl>
          );
        }}
      />
    </BasicModal>
  );
};

export default EditChannelModal;
