import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PersistGate } from 'redux-persist/integration/react';

import '@fontsource/lexend/300.css';
import '@fontsource/lexend/400.css';
import '@fontsource/lexend/500.css';
import '@fontsource/lexend/600.css';
import '@fontsource/lexend/700.css';
import './lib/theme/scrollbars.css';

import packageInfo from '../package.json';
import { store, persistor } from './store';
import { setAxiosConfig } from './lib/axiosConfig';

import App from './App';
import CacheBuster from './components/CacheBuster';
import SuspenseLoading from './components/SuspenseLoading';
import AppThemeProvider from './components/AppThemeProvider';
import { PulseLogoLoader } from './components/loader/Loader';
import { updateManagementApiConfig } from './configs/managementApi';

const packageVersion = packageInfo.version;

if (!!window._env_.VITE_SENTRY_DSN && window._env_.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: window._env_.VITE_SENTRY_DSN,
    environment: window._env_.VITE_SENTRY_ENVIRONMENT || 'unknown',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: ['AxiosError', 'Network Error'],
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <CacheBuster isEnabled currentVersion={packageVersion}>
    <Provider store={store}>
      <PersistGate
        loading={<SuspenseLoading component={<PulseLogoLoader />} />}
        persistor={persistor}
        onBeforeLift={() => {
          // console.log('Setting Axios config on before lift...');
          const state = store.getState();
          setAxiosConfig(
            state.org.registry?.apiUrl,
            state.auth.currentUser?.authenticationToken,
            state.org.registry?.code
          );
          updateManagementApiConfig(
            state.management?.baseURL,
            state.management?.authToken
          );
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <AppThemeProvider>
            <App />
          </AppThemeProvider>
        </DndProvider>
      </PersistGate>
    </Provider>
  </CacheBuster>
);
