import { useDrop } from 'react-dnd';
import Stack from '@mui/material/Stack';
import update from 'immutability-helper';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';

import { handlePaste } from '../../../lib/input';
import Playbooks from '../../../store/playbook/playbookActions';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';
import { PLAYBOOK_ITEM_TYPES } from '../../../store/playbook/playbookTypes';
import { MAX_LENGTH } from '../../../configs/validation';
import { DEFAULT_PLAYBOOK_DESCRIPTION } from '../../../configs';

import SavingModal from '../SavingModal';
import DraggableItem from '../DraggableItem';
import PlaybookLayout from './PlaybookLayout';
import AddItemControl from '../AddItemControl';
import RichTextEditor from '../../RichTextEditor';
import CancelIcon from '../../../assets/icons/close.svg?react';
import SaveIcon from '../../../assets/icons/checkmark-outline.svg?react';

const TextField = styled(MuiTextField)`
  flex-grow: 1;
  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(0.25, 0)};
    line-height: 1.5;
    font-size: 1.5rem;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const PlaybookEditor = ({
  playbook,
  openToast,
  isCloning,
  isEditing,
  onClickSave,
  onClickCancel,
}) => {
  const editing = isEditing && playbook ? true : false;
  const dispatch = useDispatch();
  const [, drop] = useDrop(() => ({
    accept: Object.values(PLAYBOOK_ITEM_TYPES),
  }));
  const [name, setName] = useState(
    isCloning ? `(Clone) ${playbook?.name}` : editing ? playbook?.name : ''
  );
  const [items, setItems] = useState(
    editing || isCloning ? playbook?.activities : []
  );
  const [getContent, setContentGetter] = useState(null);
  const [errors, setErrors] = useState(null);
  const [modal, setModal] = useState('');
  const [confirmCancelElement, openConfirmCancel] = useConfirmAsync();

  const handleMaxLengthReached = (show) => {
    if (show) {
      openToast('You have reached the maximum character limit.', 'error', 3000);
    }
  };

  const handleRichTextEditorMount = useCallback((getter) => {
    setContentGetter(() => getter);
  }, []);

  const findItem = useCallback(
    (id) => {
      const item = items.find((i) => `${i.id}` === id);
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items]
  );

  const moveItem = useCallback(
    (id, atIndex) => {
      const { item, index } = findItem(id);
      setItems(
        update(items, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        })
      );
    },
    [findItem, items, setItems]
  );

  const addItem = useCallback(
    (index, newItem) => {
      setItems((items) => {
        const newArray = [...items];
        newArray.splice(index, 0, newItem);
        return newArray;
      });
    },
    [setItems]
  );

  const editItem = useCallback(
    (id, itemChanges) => {
      const { item, index } = findItem(id);
      const newArray = [...items];
      newArray[index] = {
        ...item,
        ...itemChanges,
      };
      setItems(newArray);
    },
    [findItem, items, setItems]
  );

  const removeItem = useCallback(
    (id) => setItems((items) => items.filter((i) => `${i.id}` !== id)),
    [setItems]
  );

  const handleClickSave = () => {
    if (!name.trim()) {
      return setErrors({ name: 'Name is required.' });
    }

    const data = {
      name: name.trim(),
      categoryId: playbook?.categoryId || null,
      description: getContent(),
      items,
    };

    setModal('saving');
    dispatch(
      editing ? Playbooks.edit(playbook?.id, data) : Playbooks.create(data)
    )
      .then(() => {
        openToast(
          editing ? 'Saved changes.' : 'Created playbook.',
          'success',
          3000
        );
        onClickSave();
      })
      .catch((e) => openToast(`Error: ${e.message}`, 'error', 3000))
      .finally(() => setModal(''));
  };

  const handleClickCancel = () => {
    openConfirmCancel({
      title: 'Discard Changes?',
      content: (
        <div style={{ textAlign: 'center' }}>
          Any unsaved changes will be lost.
        </div>
      ),
      buttonText: 'Discard',
      onConfirm: () => {
        openToast('Discarded changes.', 'info', 3000);
        onClickCancel();
      },
    });
  };

  useEffect(() => {
    if (errors?.name) {
      openToast('Playbook name is required.', 'error', 3000);
    }
  }, [errors, openToast]);

  return (
    <>
      <PlaybookLayout
        nameComponent={
          <TextField
            autoFocus
            variant="outlined"
            placeholder="Playbook Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{ maxLength: MAX_LENGTH.value }}
            onPaste={(e) => handlePaste(e, setName, MAX_LENGTH.value)}
          />
        }
        nameHelperComponent={
          <Typography color="grey" fontStyle="italic" sx={{ mr: 1 }}>
            Edit the playbook name.
          </Typography>
        }
        toolbarButtons={[
          { icon: <SaveIcon />, tooltip: 'Save', onClick: handleClickSave },
          {
            icon: <CancelIcon />,
            tooltip: 'Cancel',
            onClick: handleClickCancel,
          },
        ]}
        descriptionHelperComponent={
          <Typography color="grey" fontStyle="italic">
            Edit the default brief of the incident.
          </Typography>
        }
        descriptionComponent={
          <RichTextEditor
            height="150px"
            placeholder="Enter description..."
            onMount={handleRichTextEditorMount}
            handleMaxChar={handleMaxLengthReached}
            initialContent={
              editing || isCloning
                ? playbook?.description
                : DEFAULT_PLAYBOOK_DESCRIPTION
            }
            style={{ margin: '0.25rem 0' }}
          />
        }
        itemsHelperComponent={
          <Typography color="grey" fontStyle="italic">
            Add, edit, delete, or drag items into each other to re-order them.
          </Typography>
        }
        itemsComponent={
          <Stack ref={drop} gap={1.5} alignItems="center">
            {items.map((item, index) => (
              <React.Fragment key={item.id}>
                <AddItemControl index={index} onClickItem={addItem} />
                <DraggableItem
                  {...item}
                  id={`${item.id}`}
                  moveItem={moveItem}
                  findItem={findItem}
                  editItem={editItem}
                  removeItem={removeItem}
                />
              </React.Fragment>
            ))}
            <AddItemControl index={items.length} onClickItem={addItem} />
          </Stack>
        }
      />
      <SavingModal open={modal === 'saving'} />
      {confirmCancelElement}
    </>
  );
};

export default PlaybookEditor;
