import sortBy from 'lodash.sortby';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import SvgIcon from '@mui/material/SvgIcon';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import MuiTableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

import { formatFileSize } from '../../lib/files';
import { selectAttachmentsTableData } from '../../store/incident/incidentSelectors';

import FileIcon from '../../assets/icons/file-text-outline.svg?react';

const TableCell = styled(MuiTableCell)`
  padding: 0.75rem 1rem;
`;

const TableRowStyled = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`;

export default function AttachmentsTable({
  loading,
  selected,
  handleSingleClick,
}) {
  const tableData = useSelector(selectAttachmentsTableData);

  const files = sortBy(
    tableData.filter((item) => !item.folder),
    'name'
  );

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Size</TableCell>
            <TableCell align="center">Created By</TableCell>
            <TableCell align="center">Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: 'auto' }}>
          {!loading &&
            files.map((f) => (
              <TableRowStyled
                key={f.id}
                hover
                sx={
                  selected?.id === f.id
                    ? {
                        bgcolor: '#ebeff0',
                      }
                    : undefined
                }
                onClick={() => handleSingleClick(f)}
              >
                <TableCell align="right">
                  <SvgIcon
                    component={FileIcon}
                    sx={{ fontSize: '1.25rem', mt: '0.25em' }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Typography>{f.name}</Typography>
                </TableCell>
                <TableCell align="center">
                  {f.type ? f.type.toUpperCase() : '\u2015'}
                </TableCell>
                <TableCell align="center">
                  {f.size ? formatFileSize(f.size) : '\u2015'}
                </TableCell>
                <TableCell align="center">{f.createdByName}</TableCell>
                <TableCell align="center">{f.lastUpdated}</TableCell>
              </TableRowStyled>
            ))}
        </TableBody>
      </Table>
      {loading && (
        <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
          <CircularProgress />
        </Stack>
      )}
      {!loading && files.length === 0 && (
        <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
          <Typography>No attachments to show.</Typography>
        </Stack>
      )}
    </>
  );
}
