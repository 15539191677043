import DocViewer, {
  BMPRenderer,
  CSVRenderer,
  GIFRenderer,
  HTMLRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  WebPRenderer,
  VideoRenderer,
} from '@cyntler/react-doc-viewer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiModal from '@mui/material/Modal';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AudioRenderer from './drivers/AudioRenderer';
import DOCXRenderer from './drivers/DOCXRenderer';
import TXTRenderer from './drivers/TXTRenderer';
import XLSXRenderer from './drivers/XLSXRenderer';
import { downloadFile } from '../../utils/networkUtils';

import ProgressBar from '../ProgressBar';
import CloseIcon from '../../assets/icons/close.svg?react';
import DownloadIcon from '../../assets/icons/download-outline.svg?react';

const Modal = styled(MuiModal)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled(Box)`
  overflow-y: auto;
  border-radius: 1rem;
  background-color: white;
`;

const ModalContent = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  & table {
    border-collapse: collapse;
  }
  & table,
  th,
  td {
    border: 1px solid #eee;
  }
`;

const TextContent = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 500px) {
    width: 85%;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 50%;
  height: 100%;
  margin: 0 auto;
`;

const ButtonContainer = styled(Stack)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
`;

const FileViewer = ({ open, onClose, url, progress, item }) => {
  const loading = !item?.name || !url || progress < 100;

  const canView =
    !item?.isFolder &&
    fileExtensionIsSupported(item?.extension) &&
    fileSizeIsSupported(item?.extension, item?.size);

  const downloadLabel = !item?.isFolder
    ? `${item?.name}.${item?.extension}`
    : '';

  const handleDownload = () =>
    downloadFile({
      name: downloadLabel,
      objectUrl: url,
    });

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer
        sx={{
          width: !canView ? '40%' : '80%',
          height: !canView ? '200px' : '90%',
        }}
      >
        <ModalContent>
          {loading ? (
            <Loading>
              <Typography>Loading...</Typography>
              <ProgressBar value={progress} />
            </Loading>
          ) : canView ? (
            <DocViewer
              documents={[{ uri: url, fileName: item.name }]}
              pluginRenderers={[
                AudioRenderer,
                BMPRenderer,
                CSVRenderer,
                DOCXRenderer,
                GIFRenderer,
                HTMLRenderer,
                JPGRenderer,
                PDFRenderer,
                PNGRenderer,
                TIFFRenderer,
                TXTRenderer,
                VideoRenderer,
                WebPRenderer,
                XLSXRenderer,
              ]}
              theme={{
                primary: '#eee',
              }}
            />
          ) : (
            <TextContent>
              <Typography mt="20px">
                Sorry, this file type cannot be previewed. Please download the
                file to view it.
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDownload}
              >
                Download File
              </Button>
            </TextContent>
          )}
          <ButtonContainer direction="row" gap={1}>
            <Tooltip arrow disableInteractive placement="left" title="Download">
              <span>
                <IconButton
                  size="medium"
                  disabled={loading}
                  onClick={handleDownload}
                >
                  <SvgIcon component={DownloadIcon} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip arrow disableInteractive placement="right" title="Close">
              <span>
                <IconButton size="medium" onClick={onClose}>
                  <SvgIcon component={CloseIcon} />
                </IconButton>
              </span>
            </Tooltip>
          </ButtonContainer>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const fileExtensionsSupportedByViewer = [
  'bmp',
  'csv',
  'docx',
  'gif',
  'jpg',
  'jpeg',
  'mov',
  'mp3',
  'mp4',
  'ogg',
  'pdf',
  'png',
  'tif',
  'tiff',
  'txt',
  'wav',
  'webp',
  'xls',
  'xlsx',
];

export const fileExtensionIsSupported = (extension) =>
  !!extension
    ? fileExtensionsSupportedByViewer.includes(extension.toLowerCase())
    : false;

export const fileSizeIsSupported = (extension, size) =>
  extension.toLowerCase() === 'txt' ? size < 600000 : true;

export default FileViewer;
