import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import CaretLeftIcon from '../assets/icons/caret-left.svg?react';
import CaretRightIcon from '../assets/icons/caret-right.svg?react';

const ListContainer = styled(Paper)`
  width: 250px;
  height: 230px;
  margin-bottom: 1px;
  border-radius: 6px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
`;

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a, b) => {
  return [...a, ...not(b, a)];
};

const TransferList = ({
  items = [],
  leftListTitle,
  rightListTitle,
  disabled = false,
  itemFilter = () => true,
  value: right, // Aliased to right to keep logic easy to understand.
  onChange: setRight, // Aliased to setRight to keep logic easy to understand.
}) => {
  const [left, setLeft] = useState(items.filter((i) => itemFilter(i)));
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    setLeft(items.filter((i) => itemFilter(i) && !right.includes(i)));
  }, [items, itemFilter, right]);

  if (!right || !setRight) {
    console.error('Values must be provided for value and onChange props.');
    return 'Error: see console.';
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getNumberOfChecked = (items) => intersection(checked, items).length;

  const handleToggle = (item) => () => {
    const currentIndex = checked.indexOf(item);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = (items) => () => {
    setChecked(union(checked, items));
  };

  const handleDeselectAll = (items) => () => {
    setChecked(not(checked, items));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = ({ title = '', items }) => (
    <ListContainer elevation={0}>
      <Typography align="center" fontWeight="bold" sx={{ px: 1, py: 0.5 }}>
        {title}
      </Typography>
      <Divider />
      <Stack direction="row" gap={0.5}>
        <Button
          size="small"
          color="black"
          disabled={disabled}
          onClick={handleSelectAll(items)}
          sx={{ flex: 1, p: 0.75 }}
        >
          Select All
        </Button>
        <Button
          size="small"
          color="black"
          disabled={disabled}
          onClick={handleDeselectAll(items)}
          sx={{ flex: 1, p: 0.75 }}
        >
          Deselect All
        </Button>
      </Stack>
      <Divider />
      <Typography
        color="grey"
        textAlign="center"
        sx={{ px: 1, py: 0.5, fontSize: '12px' }}
      >
        {getNumberOfChecked(items)}/{items.length} selected
      </Typography>
      <Divider />
      <List dense component="div" role="list" sx={{ py: 0, overflow: 'auto' }}>
        {items.map((item) => {
          const { value, label } = item;
          return (
            <ListItemButton
              key={value}
              role="listitem"
              disabled={disabled}
              onClick={handleToggle(item)}
              sx={{
                px: 1.5,
                bgcolor: checked.includes(item) ? '#ebeff0' : 'transparent',
              }}
            >
              {label}
            </ListItemButton>
          );
        })}
      </List>
    </ListContainer>
  );

  return (
    <Grid container spacing={1.5} justifyContent="center" alignItems="center">
      <Grid item>{customList({ title: leftListTitle, items: left })}</Grid>
      <Grid item>
        <Grid container gap={1.5} direction="column" alignItems="center">
          <Button
            size="small"
            variant="contained"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="Move selected right"
            sx={{ p: 0.5 }}
          >
            <SvgIcon component={CaretRightIcon} />
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="Move selected left"
            sx={{ p: 0.5 }}
          >
            <SvgIcon component={CaretLeftIcon} />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList({ title: rightListTitle, items: right })}</Grid>
    </Grid>
  );
};

export default TransferList;
