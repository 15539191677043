import axios from 'axios';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from '@mui/material/Select';
import SvgIcon from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

import {
  ENVIRONMENTS,
  getCurrentUrl,
  getDocumentTitleByPathName,
} from '../../configs/management';
import {
  resetManagementApiConfig,
  ejectManagementApiInterceptors,
} from '../../configs/managementApi';
import { emailRegex } from '../../utils/formUtils';
import { MAX_LENGTH } from '../../configs/validation';

import PersonIcon from '../../assets/icons/person.svg?react';
import UnauthenticatedLayout from '../UnauthenticatedLayout';

const ManagementLogin = () => {
  const location = useLocation();
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      apiUrl: ENVIRONMENTS?.[0].value,
    },
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const email = watch('email');
  const emailError = 'Please enter a valid email address.';

  const handleSubmitEmail = (data) => {
    const { email, apiUrl } = data;

    setLoading(true);
    setError('');
    axios
      .post(
        '/management/login',
        JSON.stringify({ email, feUrl: getCurrentUrl() }),
        {
          baseURL: apiUrl,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((r) => setSuccess(r.status === 200))
      .catch((err) => {
        console.error(err);
        setError('An unexpected error occurred. Please try again later.');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = getDocumentTitleByPathName(location.pathname);

    ejectManagementApiInterceptors();
    resetManagementApiConfig();
  }, [location]);

  if (success) {
    return (
      <UnauthenticatedLayout
        title="Check your inbox!"
        contentProps={{ sx: { width: 'auto !important' } }}
      >
        <Typography align="center">You can now close this tab.</Typography>
      </UnauthenticatedLayout>
    );
  }

  return (
    <UnauthenticatedLayout
      title="Management Console"
      buttons={[
        {
          type: 'submit',
          variant: 'contained',
          title: 'Send Magic Link',
          disabled: !email || loading || success,
        },
      ]}
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(handleSubmitEmail),
        sx: { gap: 1.5 },
      }}
    >
      <Controller
        name="email"
        control={control}
        rules={{
          required: emailError,
          pattern: {
            value: emailRegex,
            message: emailError,
          },
          validate: (value) =>
            value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            size="small"
            variant="standard"
            error={!!errors?.email}
            autoComplete="username"
            placeholder="Email Address"
            disabled={loading || success}
            helperText={errors?.email?.message}
            inputProps={{ maxLength: MAX_LENGTH.value }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SvgIcon color="primary" component={PersonIcon} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Controller
        name="apiUrl"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="environment-label">Environment</InputLabel>
            <Select
              {...field}
              label="Environment"
              labelId="environment-label"
              disabled={loading || success}
            >
              {ENVIRONMENTS.map((e) => (
                <MenuItem key={e.label} value={e.value}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {!!error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </UnauthenticatedLayout>
  );
};

export default ManagementLogin;
