import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import { v4 as uuid } from 'uuid';

import Dropdown from './Dropdown';

// Icons
import Bold from '../assets/icons/bold.svg?react';
import Italic from '../assets/icons/italic.svg?react';
//import Strikethrough from '../assets/icons/strikethrough.svg?react';
import CodeSlash from '../assets/icons/code-s-slash-fill.svg?react';
import ListUnordered from '../assets/icons/list-unordered.svg?react';
import ListOrdered from '../assets/icons/list-ordered.svg?react';

const FormatToggleButton = styled(Button)`
  height: 38px;
  width: 38px;
  path:nth-of-type(2) {
    fill: #0008;
  }
  &.active {
    path:nth-of-type(2) {
      fill: #000;
    }
  }
`;

const TypeDropdown = styled(Dropdown)`
  height: 38px;
  min-width: 120px;
  overflow: hidden;
  padding: 0 5px;
  margin: 0 5px;
  fieldset {
    border: none;
  }
  &::before,
  &::after {
    display: none !important;
  }
  .MuiSelect-root {
    padding-left: 5px;
  }
`;

const toggles = [
  ['S:BOLD', <Bold />],
  ['S:ITALIC', <Italic />],
  // ['S:STRIKETHROUGH', <Strikethrough />],
  ['S:CODE', <CodeSlash />],
  ['B:unordered-list-item', <ListUnordered />],
  ['B:ordered-list-item', <ListOrdered />],
];

const types = [
  ['B:unstyled', 'Normal'],
  ['B:header-one', 'Heading 1'],
  ['B:header-two', 'Heading 2'],
  ['B:header-three', 'Heading 3'],
  ['B:header-four', 'Heading 4'],
];

const options = types.map((t) => ({ value: t[0], label: t[1] }));

export default function FormatToolbar({ checkFormat, handleFormat }) {
  return (
    <>
      <ButtonGroup size="small">
        {toggles.map((t) => (
          <FormatToggleButton
            variant="text"
            className={checkFormat(t[0]) ? 'active' : ''}
            onClick={() => {
              handleFormat(t[0]);
            }}
            onMouseDown={(e) => e.preventDefault()}
            key={uuid()}
          >
            <SvgIcon fontSize="small" color="red">
              {t[1]}
            </SvgIcon>
          </FormatToggleButton>
        ))}
      </ButtonGroup>
      <TypeDropdown
        value={checkFormat()}
        options={options}
        onChange={(e) => handleFormat(e.target.value)}
      />
    </>
  );
}
