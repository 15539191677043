import icon from '/apple-touch-icon.png?url';

export const promptForNotifications = () => {
  try {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notifications.');
    } else if (Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, create a notification.
        if (permission === 'granted') {
          new Notification('ShadowHQ', {
            body: 'Notifications enabled.',
            icon: icon,
          });
        }
      });
    }
  } catch (e) {
    console.error('Something went wrong while requesting permission...', e);
  }
};

/**
 * Source: MDN Web Docs | Mobile device detection
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
 */
export const isUserAgentMobile = () =>
  /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(navigator.userAgent) ||
  /\b(Android|Windows Phone|iPad|iPod)\b/i.test(navigator.userAgent);
