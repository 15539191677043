import {
  localStorageKeys,
  scheduleLocalStorageRemoval,
} from '../../utils/localStorageUtils';
import { AUTH_TYPES } from '../auth/authReducer';

const INIT_USERS_STATE = {
  list: [],
  error: null,
  fetchingUsers: false,
  fetchedUsers: false,
  fetchingLastActiveTimestamps: false,
  lastActiveTimestamps: [],
};

export const USER_TYPES = {
  FETCH_USERS: '@@users/FETCH_USERS',
  SET_PROFILE_IMG: '@@users/SET_PROFILE_IMG',
  SET_PHONE_NUMBER: '@@users/SET_PHONE_NUMBER',
  FETCH_LAST_ACTIVE_TIMESTAMPS: '@@users/FETCH_LAST_ACTIVE_TIMESTAMPS',
  SYNC: '@@users/SYNC',
};

export default function users(state = INIT_USERS_STATE, action) {
  switch (action.type) {
    case `${USER_TYPES.FETCH_USERS}_PENDING`:
      return {
        ...state,
        fetchingUsers: true,
        fetchedUsers: false,
        list: state.list,
      };
    case `${USER_TYPES.FETCH_USERS}_FULFILLED`: {
      const { list: prevList } = state;
      const list = action.payload.data.reduce((acc, user) => {
        // Find out if we already have this user.
        const existingUser = prevList.find(
          (u) => user.userProperties.uuid === u.userProperties.uuid
        );

        // If we already have this user, then keep them but shallow merge
        // any changes made to the object...
        if (existingUser) {
          return [...acc, { ...existingUser, ...user }];
        }

        // ... otherwise, add them to the list and get the regId in the
        // next step.
        return [...acc, user];
      }, []);

      // Store the user list in localStorage.
      localStorage.setItem(localStorageKeys.USERS_LIST, JSON.stringify(list));

      // Set a timeout to delete the list from localStorage
      scheduleLocalStorageRemoval(localStorageKeys.USERS_LIST);

      return {
        ...state,
        list,
        fetchingUsers: false,
        fetchedUsers: true,
      };
    }
    case `${USER_TYPES.FETCH_USERS}_REJECTED`:
      return {
        ...state,
        fetchingUsers: false,
        fetchedUsers: true,
        error: action.payload,
      };
    case USER_TYPES.SYNC:
      return {
        ...state,
        list: action.payload,
      };
    case `${USER_TYPES.FETCH_LAST_ACTIVE_TIMESTAMPS}_PENDING`:
      return {
        ...state,
        fetchingLastActiveTimestamps: true,
      };
    case `${USER_TYPES.FETCH_LAST_ACTIVE_TIMESTAMPS}_REJECTED`:
      return {
        ...state,
        fetchingLastActiveTimestamps: false,
        error: action.payload,
      };
    case `${USER_TYPES.FETCH_LAST_ACTIVE_TIMESTAMPS}_FULFILLED`:
      return {
        ...state,
        fetchingLastActiveTimestamps: false,
        lastActiveTimestamps: action.payload.data,
      };
    case `${USER_TYPES.SET_PROFILE_IMG}_FULFILLED`: {
      const { list } = state;
      const { url, uuid } = action.payload;
      const idx = list.findIndex((u) => u.userProperties.uuid === uuid);
      const me = list.find((u) => u.userProperties.uuid === uuid);
      const newMe = {
        ...me,
        profileImageUrl: url,
        lastUpdateTimestamp: Date.now(),
      };

      return {
        ...state,
        list: [...list.slice(0, idx), newMe, ...list.slice(idx + 1)],
      };
    }
    case `${USER_TYPES.SET_PROFILE_IMG}_REJECTED`:
      return {
        ...state,
        error: action.payload.data,
      };

    case `${USER_TYPES.SET_PHONE_NUMBER}_FULFILLED`: {
      const { list } = state;
      const { number, uuid } = action.payload;
      const idx = list.findIndex((u) => u.userProperties.uuid === uuid);
      const me = list.find((u) => u.userProperties.uuid === uuid);
      const newMe = {
        ...me,
        phoneNumber: number,
      };
      return {
        ...state,
        list: [...list.slice(0, idx), newMe, ...list.slice(idx + 1)],
      };
    }
    case `${USER_TYPES.SET_PHONE_NUMBER}_REJECTED`:
      return {
        ...state,
        error: action.payload.data,
      };
    case AUTH_TYPES.CLEAR:
      return INIT_USERS_STATE;
    default:
      return state;
  }
}
