import { navigateHelper } from '..';
import Incidents from '../incident/incidentActions';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectLastLogin } from '../auth/authSelectors';
import { NOTIFICATIONS_TYPES } from './notificationReducer';
import { selectNotifications } from './notificationSelectors';

const Notifications = {
  fetchNotifications: () => ({
    type: NOTIFICATIONS_TYPES.FETCH_NOTIFICATIONS,
    payload: axiosInstance.get(`/notifications`),
  }),
  showNotification: () => (dispatch, getState) => {
    const state = getState();
    const lastLogin = selectLastLogin(state);
    const notifications = selectNotifications(state);

    const n = notifications[notifications.length - 1];
    if (!n) {
      return console.error('@@SHQ/Notifications - Item not found.');
    }

    if (!!n.incidentId) {
      dispatch(Incidents.getItem(n.incidentId));
    }

    // Don't show the notification if it's stale relative to the user's last login time.
    if (lastLogin < n.createTimestamp && Notification !== undefined) {
      const notification = new Notification(n.title, {
        tag: n.id,
        body: n.details,
      });
      notification.onclick = () => {
        window.focus();
        navigateHelper(`/incidents/${n.incidentId}`, {
          state: { focusActivity: n.activityId },
        });
        notification.close();
      };
    }

    return dispatch({
      type: NOTIFICATIONS_TYPES.MARK_READ,
      payload: axiosInstance.post(`/notifications/read/${n.id}`, undefined),
    });
  },
};

export default Notifications;
