import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Storage from '../../store/storage/storageActions';
import { selectFilter } from '../../store/storage/storageSelectors';
import { STORAGE_FILTERS } from '../../store/storage/storageReducer';

import SearchInput from '../SearchInput';
import PlusIcon from '../../assets/icons/plus.svg?react';
import UploadIcon from '../../assets/icons/upload.svg?react';

const HeaderContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled(Box)`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LeftContainer = styled(Box)`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const RightContainer = styled(Box)`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ToggleButton = styled(MuiToggleButton)`
  text-transform: none;
  padding: 0.5rem 0.75rem;
`;

const Header = ({
  searchInputRef,
  onChangeSearch,
  onClearSearch,
  onClickUploadFile,
  onClickCreateFolder,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);

  const handleFilterChange = useCallback(
    (_, newFilter) => {
      dispatch(Storage.setFilter(newFilter));
    },
    [dispatch]
  );

  return (
    <HeaderContainer>
      <LeftContainer>
        <ToggleButtonGroup
          exclusive
          color="primary"
          value={filter}
          onChange={handleFilterChange}
          aria-label="Filter Files"
        >
          <ToggleButton value={STORAGE_FILTERS.ALL}>
            <Typography>All</Typography>
          </ToggleButton>
          <ToggleButton value={STORAGE_FILTERS.CREATED_BY_ME}>
            <Typography>Created By Me</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </LeftContainer>
      <SearchContainer>
        <SearchInput
          aria-label="Find files or folders..."
          placeholder="Find files or folders..."
          inputRef={searchInputRef}
          onChange={onChangeSearch}
          onClear={onClearSearch}
        />
      </SearchContainer>
      <RightContainer>
        <Stack direction="row" gap="12px">
          <Button
            color="success"
            variant="contained"
            onClick={onClickUploadFile}
            startIcon={<SvgIcon component={UploadIcon} />}
            sx={{ color: '#fff' }}
          >
            Upload File
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClickCreateFolder}
            startIcon={<SvgIcon component={PlusIcon} />}
            sx={{ color: '#fff' }}
          >
            Create Folder
          </Button>
        </Stack>
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;
