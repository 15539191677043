import { USER_ADMIN_TYPES } from './userAdminReducer';
import { axiosInstance } from '../../lib/axiosInstance';
import { trimPhoneNumber } from '../../utils/formUtils';

const UserAdmin = {
  fetchUsers: () => ({
    type: USER_ADMIN_TYPES.FETCH_USERS,
    payload: axiosInstance.get('/useradmin/users'),
  }),
  createUser: (userData) => {
    const body = {
      ...userData,
      active: true,
      passwordChangeRequired: false,
      sendWelcomeSetPasswordEmail: true,
      phoneNumber: trimPhoneNumber(userData.phoneNumber) || null,
    };

    return {
      type: USER_ADMIN_TYPES.CREATE_USER,
      payload: axiosInstance.post('/useradmin/user', body),
    };
  },
  updateUser: (userData, uuid) => {
    const body = {
      ...userData,
      active: true,
      passwordChangeRequired: false,
      phoneNumber: trimPhoneNumber(userData.phoneNumber) || null,
    };

    return {
      type: USER_ADMIN_TYPES.UPDATE_USER,
      payload: axiosInstance.put(`/useradmin/user/${uuid}`, body),
    };
  },
  deactivateUser: (uuid) => ({
    type: USER_ADMIN_TYPES.DEACTIVATE_USER,
    payload: axiosInstance.delete(`/useradmin/user/${uuid}`),
  }),
  reactivateUser: (uuid) => ({
    type: USER_ADMIN_TYPES.REACTIVATE_USER,
    payload: axiosInstance.post(`/useradmin/user/${uuid}`, undefined),
  }),
  importUsers: (users) => ({
    type: USER_ADMIN_TYPES.IMPORT_USERS,
    payload: axiosInstance.post('/useradmin/users', users),
  }),
  sendPasswordResetEmail: (userId) => ({
    type: USER_ADMIN_TYPES.SEND_EMAIL,
    payload: axiosInstance.get(`/useradmin/user/forgotPassword/${userId}`),
  }),
  sendActivationEmail: (userId) => ({
    type: USER_ADMIN_TYPES.SEND_EMAIL,
    payload: axiosInstance.get(`/useradmin/user/sendWelcomeEmail/${userId}`),
  }),
  resetTOTPEnrolment: (userId) => ({
    type: USER_ADMIN_TYPES.RESET_TOTP_ENROLMENT,
    payload: axiosInstance.delete(
      `/useradmin/user/removeAuthenticatorInitialization/${userId}`
    ),
  }),
  setToast: ({ message, severity }) => ({
    type: USER_ADMIN_TYPES.SET_TOAST,
    payload: {
      message,
      severity,
    },
  }),
  resetToast: () => ({
    type: USER_ADMIN_TYPES.RESET_TOAST,
  }),
};

export default UserAdmin;
