import { useState, useMemo, useCallback } from 'react';

import useToast from '../../lib/hooks/useToast';

import BasicModal from '../../components/BasicModal';

const useConfirmAsync = () => {
  const [open, setOpen] = useState(false);
  const [toastElement, openToast] = useToast();
  const [{ title, content, buttonText, buttonColor, onConfirm }, setProps] =
    useState({});

  const element = useMemo(() => {
    const handleClose = () => setOpen(false);
    return (
      <BasicModal
        open={open}
        title={title}
        onClose={handleClose}
        buttonText={buttonText}
        buttonColor={buttonColor}
        onClickButton={async () => {
          try {
            await onConfirm();
            handleClose();
          } catch (e) {
            console.error('error', e);
            openToast(e.message, 'error');
          }
        }}
        sx={{ maxWidth: '28rem' }}
      >
        {content}
        {toastElement}
      </BasicModal>
    );
  }, [
    open,
    title,
    content,
    buttonText,
    buttonColor,
    onConfirm,
    openToast,
    toastElement,
  ]);

  const trigger = useCallback(
    ({ title, content, buttonText, buttonColor = 'primary', onConfirm }) => {
      setProps({
        title,
        content,
        buttonText,
        buttonColor,
        onConfirm,
      });
      setOpen(true);
    },
    [setProps, setOpen]
  );
  return [element, trigger];
};

export default useConfirmAsync;
