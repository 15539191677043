import { useLocation } from 'react-router';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';

import JitsiCalls from '../../store/jitsi/jitsiActions';
import { getPageForPathname } from '../../utils/configUtils';

import HeaderUserbox from './HeaderUserbox';
import logo from '../../assets/images/logo.svg';
import PhoneCall from '../../assets/icons/phone-call-outline.svg?react';

const TopBar = styled.header`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  position: relative;
  padding: 0 1rem;
  height: 72px;
`;

const LOGO_WIDTH = '200px';

const Logo = styled.img`
  width: ${LOGO_WIDTH};
  height: auto;
  max-width: 100%;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageTitle = styled(Typography)`
  position: absolute;
  bottom: 2px;
  text-align: center;
  font-size: 14px;
`;

const CallButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.success.main};
  &&:hover {
    background-color: ${({ theme }) => theme.palette.success.dark};
  }
`;

export default function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { onJitsiCall } = useSelector((state) => state.jitsi);

  const showPageTitle = location.pathname !== '/';
  const page = getPageForPathname(location.pathname);

  const isLongName = page?.name?.length > 18;
  const pageTitleStyles = isLongName
    ? { left: `calc(50% - calc(${LOGO_WIDTH} * 0.26))` }
    : undefined;

  return (
    <TopBar>
      <LogoContainer>
        <Logo src={logo} alt="ShadowHQ" />
        {showPageTitle && (
          <PageTitle variant="h6" component="h1" sx={pageTitleStyles}>
            {page?.name}
          </PageTitle>
        )}
      </LogoContainer>
      {onJitsiCall && (
        <Tooltip arrow title="Call In Progress">
          <CallButton
            size="medium"
            onClick={() => dispatch(JitsiCalls.toggleCallWindow(true))}
          >
            <SvgIcon color="white" component={PhoneCall} />
          </CallButton>
        </Tooltip>
      )}
      <HeaderUserbox />
    </TopBar>
  );
}
