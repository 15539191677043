import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';
import { selectUsersByMemberIds } from '../../store/user/userSelectors';
import MessagingAdmin from '../../store/messaging-admin/messagingAdminActions';
import { selectChannel } from '../../store/messaging-admin/messagingAdminSelectors';

import Avatar from '../Avatar';
import BasicModal from '../BasicModal';
import DataGridFilter from '../DataGridFilter';
import UserAutocomplete from '../UserAutocomplete';

const DataGridContainer = styled(Box)`
  width: 100%;
  height: 284px;
`;

const DataGrid = styled(MuiDataGrid)`
  border: none;
  border-radius: 0;

  .MuiDataGrid-footerContainer {
    border-top: none;
  }
`;

const ManageMembersModal = ({ open, cid, onClose }) => {
  const dispatch = useDispatch();
  const [newMembers, setNewMembers] = useState([]);
  const channel = useSelector((state) => selectChannel(state, cid));
  const memberUsers = useSelector((state) =>
    selectUsersByMemberIds(state, Object.values(channel?.members || {}))
  );
  const [confirmRemoveElement, openConfirmRemove] = useConfirmAsync();

  const title = `Manage Members of '${channel?.name}'`;
  const rows = memberUsers.map((m) => ({ ...m, id: m.user_id }));
  const memberUUIDs = rows.map((r) => r.id);

  const dispatchSuccessMessage = (message) =>
    dispatch(
      MessagingAdmin.setToastMessage({
        message,
        severity: 'success',
      })
    );

  const handleAddMembers = (e) => {
    e.preventDefault();
    if (!newMembers.length) {
      return;
    }

    const newUUIDs = newMembers.map((u) => u.userProperties.uuid);
    dispatch(MessagingAdmin.addMembers(cid, newUUIDs)).then(() => {
      setNewMembers([]);
      dispatchSuccessMessage(
        `Added ${newUUIDs.length} member${newUUIDs.length === 1 ? '' : 's'}`
      );
    });
  };

  const handlePromoteMember = (row) =>
    dispatch(MessagingAdmin.promoteMembers(cid, [row.id])).then(() =>
      dispatchSuccessMessage(`Made ${row.email} an admin.`)
    );

  const handleDemoteMember = (row) =>
    dispatch(MessagingAdmin.demoteMembers(cid, [row.id])).then(() =>
      dispatchSuccessMessage(`Removed ${row.email} as an admin.`)
    );

  const handleRemoveMember = (row) => {
    openConfirmRemove({
      title: 'Remove Member',
      content: (
        <Typography align="center">
          Are you sure you want to remove <b>{row.fullName}</b>?
        </Typography>
      ),
      buttonText: 'Remove',
      buttonColor: 'error',
      onConfirm: () =>
        dispatch(MessagingAdmin.removeMembers(cid, [row.id])).then(() =>
          dispatchSuccessMessage(`Removed ${row.email}.`)
        ),
    });
  };

  const handleClose = () => {
    onClose();
    setNewMembers([]);
  };

  if (!cid || !open) {
    return null;
  }

  return (
    <BasicModal
      open={open}
      title={title}
      buttonText="Done"
      onClose={handleClose}
      onClickButton={handleClose}
      footerStyles={{ pt: 0 }}
      sx={{ width: '60rem' }}
    >
      <Stack gap={1}>
        <Typography component="h3" variant="h4">
          Add Members
        </Typography>
        <Stack
          direction="row"
          component="form"
          alignItems="center"
          onSubmit={handleAddMembers}
          gap={2}
        >
          <UserAutocomplete
            value={newMembers}
            excludedUUIDs={memberUUIDs}
            onChange={(value) => setNewMembers(value)}
            sx={{ flexGrow: 1 }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!newMembers.length}
            sx={{ flexShrink: 0 }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <DataGridContainer>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'avatar',
              headerName: '',
              flex: 0.25,
              align: 'center',
              sortable: false,
              renderCell: ({ row }) => (
                <Avatar
                  firstName={row.firstName}
                  lastName={row.lastName}
                  profileImageUrl={getCachedProfileImageUrl(row)}
                  fontSize="0.9rem"
                  sx={{ width: '30px', height: '30px' }}
                />
              ),
            },
            {
              field: 'firstName',
              headerName: 'First Name',
              flex: 0.9,
            },
            {
              field: 'lastName',
              headerName: 'Last Name',
              flex: 0.9,
            },
            {
              field: 'email',
              headerName: 'Email',
              flex: 1.25,
            },
            {
              field: 'active',
              headerName: 'Active',
              flex: 0.5,
              type: 'boolean',
            },
            {
              field: 'role',
              headerName: 'Role',
              flex: 0.5,
              headerAlign: 'center',
              align: 'center',
              valueGetter: ({ row }) =>
                row.isOwner ? 'Owner' : row.isAdmin ? 'Admin' : 'Member',
            },
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1.5,
              sortable: false,
              headerAlign: 'center',
              align: 'center',
              renderCell: ({ row }) => {
                const { active, isOwner, isAdmin } = row;
                const promoteOrDemoteDisabled = !active || isOwner;

                return (
                  <Stack
                    gap={0.5}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() =>
                        isAdmin
                          ? handleDemoteMember(row)
                          : handlePromoteMember(row)
                      }
                      disabled={promoteOrDemoteDisabled}
                      sx={{ minWidth: '124px' }}
                    >
                      {isAdmin ? 'Remove Admin' : 'Make Admin'}
                    </Button>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => handleRemoveMember(row)}
                    >
                      Remove
                    </Button>
                  </Stack>
                );
              },
            },
          ]}
          autoPageSize
          rowHeight={38}
          headerHeight={38}
          disableColumnMenu
          disableSelectionOnClick
          disableMultipleColumnsSorting
          components={{
            Toolbar: () => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 0.5 }}
              >
                <Typography component="h3" variant="h4">
                  Edit Members
                </Typography>
                <DataGridFilter autoFocus debounceMs={500} />
              </Stack>
            ),
          }}
          localeText={{
            noRowsLabel: 'No members added yet.',
            footerRowSelected: (count) =>
              `${count.toLocaleString()} member${
                count === 1 ? '' : 's'
              } selected`,
          }}
        />
      </DataGridContainer>
      {confirmRemoveElement}
    </BasicModal>
  );
};

export default ManageMembersModal;
