import { memo } from 'react';

import { FOLDER_TYPES } from '../../configs';

import DefaultFileIcon from '../../assets/icons/filetype-default.svg?react';
import DefaultFolderIcon from '../../assets/icons/filetype-folder.svg?react';
import AdminFolderIcon from '../../assets/icons/filetype-folder-admin.svg?react';
import GroupFolderIcon from '../../assets/icons/filetype-folder-group.svg?react';
import ZipIcon from '../../assets/icons/filetype-zip.svg?react';
import TextIcon from '../../assets/icons/filetype-text.svg?react';
import ImageIcon from '../../assets/icons/filetype-image.svg?react';
import DocIcon from '../../assets/icons/filetype-doc.svg?react';
import DocxIcon from '../../assets/icons/filetype-docx.svg?react';
import XlsIcon from '../../assets/icons/filetype-xls.svg?react';
import XlsxIcon from '../../assets/icons/filetype-xlsx.svg?react';
import PptIcon from '../../assets/icons/filetype-ppt.svg?react';
import PptxIcon from '../../assets/icons/filetype-pptx.svg?react';
import PdfIcon from '../../assets/icons/filetype-pdf.svg?react';
import MovIcon from '../../assets/icons/filetype-mov.svg?react';
import Mp3Icon from '../../assets/icons/filetype-mp3.svg?react';
import Mp4Icon from '../../assets/icons/filetype-mp4.svg?react';

const BaseFileIcon = ({ extension }) => {
  switch (extension) {
    case 'folder':
      return <DefaultFolderIcon />;
    case 'zip':
      return <ZipIcon />;
    case 'txt':
      return <TextIcon />;
    case 'doc':
      return <DocIcon />;
    case 'docx':
      return <DocxIcon />;
    case 'xls':
      return <XlsIcon />;
    case 'xlsx':
      return <XlsxIcon />;
    case 'ppt':
      return <PptIcon />;
    case 'pptx':
      return <PptxIcon />;
    case 'pdf':
      return <PdfIcon />;
    case 'png':
      return <ImageIcon />;
    case 'jpg':
      return <ImageIcon />;
    case 'mov':
      return <MovIcon />;
    case 'mp3':
      return <Mp3Icon />;
    case 'mp4':
      return <Mp4Icon />;
    case 'webp':
      return <ImageIcon />;
    default:
      return <DefaultFileIcon />;
  }
};

BaseFileIcon.displayName = 'FileIcon';

export const FileIcon = memo(BaseFileIcon);

const BaseFolderIcon = ({ type }) => {
  switch (type) {
    case FOLDER_TYPES.PUBLIC:
      return <DefaultFolderIcon />;
    case FOLDER_TYPES.PRIVATE:
      return <GroupFolderIcon />;
    case FOLDER_TYPES.ADMIN:
      return <AdminFolderIcon />;
    default:
      return <DefaultFolderIcon />;
  }
};

BaseFolderIcon.displayName = 'FolderIcon';

export const FolderIcon = memo(BaseFolderIcon);
