import { localStorageKeys } from './localStorageUtils';

const tabId = Date.now().toString();
const takeoverThreshold = 10000; // 10 seconds

export const isActiveTab = () => {
  const lastHeartbeat = JSON.parse(
    localStorage.getItem(localStorageKeys.HEARTBEAT)
  );
  return (
    !lastHeartbeat ||
    Date.now() - lastHeartbeat.timestamp > takeoverThreshold ||
    lastHeartbeat.tabId === tabId
  );
};

export const updateHeartbeat = () => {
  localStorage.setItem(
    localStorageKeys.HEARTBEAT,
    JSON.stringify({ tabId, timestamp: Date.now() })
  );
};
