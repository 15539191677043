import { styled } from '@mui/material/styles';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useToast from '../../lib/hooks/useToast';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import useBeforeUnload from '../../lib/hooks/useBeforeUnload';
import Incidents from '../../store/incident/incidentActions';
import { selectCurrentItem } from '../../store/incident/incidentSelectors';

import BackButton from './BackButton';
import RichTextEditor from '../RichTextEditor';

const Main = styled.main`
  position: relative;
  border-top: 1px solid #ddd;
  height: 100%;
`;

const Container = styled.div`
  width: 60%;
  margin: 1.25rem auto 0;
  max-width: 1200px;
`;

const FloatingBackButton = styled(BackButton)`
  position: absolute;
  z-index: 1;
  padding: 1.25rem;
`;

const PostIncidentAnalysisForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const item = useSelector(selectCurrentItem);
  const [toastElement, openToast] = useToast();
  const [confirmDiscardElement, openConfirmDiscard] = useConfirmAsync();
  const [submitting, setSubmitting] = useState(false);
  const [getContent, setContentGetter] = useState(null);

  useBeforeUnload();

  const handleRichTextEditorMount = useCallback((getter) => {
    setContentGetter(() => getter);
  }, []);

  const handleMaxLengthReached = (show) => {
    if (show) {
      openToast('You have reached the maximum character limit.', 'error', 3000);
    }
  };

  const hasUnsavedChanges = () => {
    const content = getContent();
    if (content === '') {
      return false;
    }
    return item?.postmortemDetails !== content.trim();
  };

  const handleClickBack = () => {
    if (!hasUnsavedChanges()) {
      navigate(`/incidents/${id}`);
    } else {
      openConfirmDiscard({
        title: 'Discard changes?',
        content: (
          <div style={{ textAlign: 'center' }}>
            You have unsaved changes.
            <br />
            Are you sure you want to leave this page?
          </div>
        ),
        buttonText: 'Discard',
        onConfirm: () => navigate(`/incidents/${id}`),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const postmortemDetails = getContent().trim();

    dispatch(Incidents.editItem(id, { postmortemDetails }))
      .then(() => {
        navigate(`/incidents/${id}`, { replace: true });
        setSubmitting(false);
      })
      .catch(() => {
        openToast('Something went wrong.', 'error', 3000);
        setSubmitting(false);
      });
  };

  return (
    <Main>
      <FloatingBackButton onClick={handleClickBack} />
      <Container>
        <Stack component="form" onSubmit={handleSubmit} gap={1} mb={2}>
          <Typography variant="h3">Post-Incident Analysis</Typography>
          <RichTextEditor
            autoFocus
            height="275px"
            placeholder="Enter Post-Incident Analysis here..."
            initialContent={item?.postmortemDetails || ''}
            onMount={handleRichTextEditorMount}
            handleMaxChar={handleMaxLengthReached}
            style={{ margin: '0.5rem 0' }}
          />
          <Button
            disabled={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {submitting ? 'Saving...' : 'Submit'}
          </Button>
        </Stack>
      </Container>
      {toastElement}
      {confirmDiscardElement}
    </Main>
  );
};

export default PostIncidentAnalysisForm;
