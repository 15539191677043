import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  selectIsReady,
  selectIsActiveTab,
} from '../../store/heartbeat/heartbeatSelectors';
import Users from '../../store/user/userActions';
import Incidents from '../../store/incident/incidentActions';
import Dashboard from '../../store/dashboard/dashboardActions';
import Notifications from '../../store/notification/notificationActions';

/**
 * Check the documentation for this component in the project README.
 */
const PollingManager = () => {
  const dispatch = useDispatch();
  const pollingIntervals = useRef({});
  const isReady = useSelector(selectIsReady);
  const isActiveTab = useSelector(selectIsActiveTab);

  // Define the polling functions.
  const pollingFunctions = useMemo(
    () => [
      {
        id: 'usersAndIncidents',
        func: () => {
          dispatch(Users.fetchUsers());
          dispatch(Incidents.fetch());
        },
        interval: 15 * 60 * 1000, // 15 minutes
      },
      {
        id: 'dashboard',
        func: () => {
          dispatch(Dashboard.getDashboard()).then(({ value }) => {
            if (value.activeIncident) {
              dispatch(Incidents.fetch());
            }

            dispatch(Notifications.fetchNotifications()).then(({ value }) => {
              if (!value?.data || !value?.data?.length) {
                return;
              }

              const latest = value.data[value.data.length - 1];
              if (latest && !latest.hasBeenRead) {
                dispatch(Notifications.showNotification());
              }
            });
          });
        },
        interval: 60 * 1000, // 1 minute
      },
    ],
    [dispatch]
  );

  const startPolling = useCallback((pollingFunc) => {
    if (!pollingIntervals.current[pollingFunc.id]) {
      console.log(`PollingManager: starting ${pollingFunc.id}...`);
      pollingIntervals.current[pollingFunc.id] = setInterval(
        pollingFunc.func,
        pollingFunc.interval
      );
    }
  }, []);

  const stopPolling = useCallback(
    (pollingFunc) => {
      if (pollingIntervals.current[pollingFunc.id]) {
        if (isActiveTab) {
          console.log(`PollingManager: stopping: ${pollingFunc.id}...`);
        }
        clearInterval(pollingIntervals.current[pollingFunc.id]);
        delete pollingIntervals.current[pollingFunc.id];
      }
    },
    [isActiveTab]
  );

  const startAllPolling = useCallback(() => {
    pollingFunctions.forEach(startPolling);
  }, [pollingFunctions, startPolling]);

  const stopAllPolling = useCallback(() => {
    pollingFunctions.forEach(stopPolling);
  }, [pollingFunctions, stopPolling]);

  useEffect(() => {
    // console.log('PollingManager mount');

    // Online/offline event handlers
    const handleOnline = () => {
      console.log('Browser is online, checking active tab for polling.');
      if (isActiveTab && isReady) {
        startAllPolling();
      }
    };

    const handleOffline = () => {
      console.log('Browser is offline, stopping polling.');
      stopAllPolling();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Start polling if online and this is the active tab
    if (navigator.onLine && isActiveTab && isReady) {
      startAllPolling();
    } else {
      stopAllPolling();
    }

    return () => {
      // console.log('PollingManager unmount');
      stopAllPolling();
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isActiveTab, isReady, startAllPolling, stopAllPolling]);

  return null;
};

export default PollingManager;
