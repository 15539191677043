import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import {
  selectJwt,
  selectCallTitle,
  selectCallDetails,
} from '../../store/jitsi/jitsiSelectors';
import Messages from '../../store/chat/messageActions';
import JitsiCalls from '../../store/jitsi/jitsiActions';
import { selectOrgCode } from '../../store/org/orgSelectors';
import { selectCurrentChat } from '../../store/chat/chatSelectors';

import BasicModal from '../BasicModal';
import DialInOptions from './DialInOptions';
import Minus from '../../assets/icons/minus.svg?react';
import DialInIcon from '../../assets/icons/phone-call-dial-in.svg?react';

const CallWindow = styled.div`
  position: absolute;
  visibility: ${({ show }) => !show && 'hidden'};
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #333;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 1000;
`;

const JitsiContainer = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  margin: 0;
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-left: 90%;
  background-color: #141414;
`;

const IconButtonCustom = styled(IconButton)`
  color: rgb(51, 51, 51);
  margin: -2.8px 7px;
  height: 48px;
  width: 48px;
  float: right;
  border-radius: 5px;
  background: transparent;
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    background-color: #525252;
  }
`;

function JitsiMeeting({ videoMuted }) {
  const JITSI_CONTAINER_ID = 'jitsi-container-id';

  const dispatch = useDispatch();
  const userJwt = useSelector(selectJwt);
  const currentChat = useSelector(selectCurrentChat);
  const subject = useSelector(selectCallTitle);
  const orgCode = useSelector(selectOrgCode);
  const [jitsi, setJitsi] = useState({});

  const initialiseJitsi = () => {
    const meeting = new window.JitsiMeetExternalAPI('8x8.vc', {
      parentNode: document.getElementById(JITSI_CONTAINER_ID),
      roomName: `vpaas-magic-cookie-fc5dd46e90314c97b472972d80152bd9/${orgCode}-${currentChat.id}`,
      jwt: userJwt,
      configOverwrite: {
        startWithVideoMuted: videoMuted,
        disableInviteFunctions: true,
        subject: subject || 'Call',
        toolbarButtons: [
          'camera',
          'closedcaptions',
          'desktop',
          'download',
          'etherpad',
          'filmstrip',
          'fullscreen',
          'hangup',
          'help',
          'microphone',
          'mute-everyone',
          'participants-pane',
          'raisehand',
          'select-background',
          'settings',
          'sharedvideo',
          'shortcuts',
          'stats',
          'tileview',
          'videoquality',
        ],
        remoteVideoMenu: {
          disableKick: true,
          disableGrantModerator: true,
        },
        SETTINGS_SECTIONS: ['devices', 'language'],
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        hideLobbyButton: true,
        readOnlyName: true,
        disableSelfView: false,
        breakoutRooms: {
          hideAddRoomButton: true,
        },
      },
    });

    // api - The Jitsi Meet iframe api object.
    if (window.jitsiNodeAPI) {
      window.jitsiNodeAPI.jitsiMeetElectronUtils.setupScreenSharingRender(
        meeting
      );
    }

    meeting.addEventListeners({
      videoConferenceJoined: function onJoin() {
        const jitsiParticipants = meeting.getParticipantsInfo();

        // First person on the call sends this message.
        if (jitsiParticipants.length === 1) {
          dispatch(
            Messages.sendMessage('Started a call', [], { isCall: true })
          );
        }

        dispatch(JitsiCalls.updateParticipants(jitsiParticipants));
      },
      videoConferenceLeft: function onLeave() {
        dispatch(JitsiCalls.leaveCall());
      },
      participantJoined: () => {
        const jitsiParticipants = meeting.getParticipantsInfo();

        // Actively, we can see who is in the call
        dispatch(JitsiCalls.updateParticipants(jitsiParticipants));
      },
      participantLeft: () => {
        const jitsiParticipants = meeting.getParticipantsInfo();

        // Actively, we can see who is in the call
        dispatch(JitsiCalls.updateParticipants(jitsiParticipants));
      },
      errorOccurred: ({ message, type, isFatal }) => {
        console.log(message);

        if (type === 'CONNECTION' && isFatal) {
          jitsi?.dispose?.();
        }
      },
    });

    setJitsi(meeting);
  };

  useEffect(() => {
    initialiseJitsi();

    return () => jitsi?.dispose?.();
  }, []);

  return <JitsiContainer id={JITSI_CONTAINER_ID} />;
}

export default function JitsiCall() {
  const { jwt, onJitsiCall, showJitsiCall, videoMuted, cid } = useSelector(
    (state) => state.jitsi
  );
  const dispatch = useDispatch();
  const [dialInOpen, setDialInOpen] = useState(false);
  const callDetails = useSelector((state) => selectCallDetails(state, { cid }));

  const { meetingId } = callDetails;

  const handleOpenDialIn = () => setDialInOpen(true);

  const handleCloseDialIn = () => setDialInOpen(false);

  const handleClickMinimize = useCallback(
    () => dispatch(JitsiCalls.toggleCallWindow(false)),
    [dispatch]
  );

  return (
    jwt &&
    onJitsiCall && (
      <CallWindow show={showJitsiCall}>
        <Container>
          <Tooltip arrow title="View Dial-In Details" disableInteractive>
            <IconButtonCustom onClick={handleOpenDialIn} size="large">
              <SvgIcon color="white" fontSize="24px" component={DialInIcon} />
            </IconButtonCustom>
          </Tooltip>
          <Tooltip arrow title="Minimize" disableInteractive>
            <IconButtonCustom onClick={handleClickMinimize} size="large">
              <SvgIcon color="white" fontSize="24px" component={Minus} />
            </IconButtonCustom>
          </Tooltip>
        </Container>
        <JitsiMeeting videoMuted={videoMuted} />
        <BasicModal
          open={dialInOpen}
          buttonText="Close"
          title="Dial-In Details"
          showCancelButton={false}
          onClose={handleCloseDialIn}
          onClickButton={handleCloseDialIn}
          bodyStyles={{ textAlign: 'center' }}
          sx={{ minWidth: '14rem' }}
        >
          <DialInOptions meetingId={meetingId} showTitle={false} />
        </BasicModal>
      </CallWindow>
    )
  );
}
