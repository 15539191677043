import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { MAX_LENGTH } from '../../../configs/validation';
import { managementApi } from '../../../configs/managementApi';
import { MAX_STREAM_CREDS_AT_ONCE } from '../../../configs/management';

import BasicModal from '../../BasicModal';

const AddStreamCredsModal = ({ open, onClose }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      creds: [
        {
          publicKey: '',
          secretKey: '',
        },
      ],
    },
  });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'creds',
    rules: { minLength: 1, maxLength: MAX_STREAM_CREDS_AT_ONCE },
  });

  const handleAppendSet = () =>
    append({
      publicKey: '',
      secretKey: '',
    });

  const handleClose = (isCancel = false) => {
    reset();
    setSuccess('');
    onClose(isCancel);
  };

  const handleAddCreds = (data) => {
    const { creds } = data;

    setLoading(true);
    return managementApi
      .post('/credentials', creds)
      .then(() => {
        setSuccess('Credentials added!');
        setTimeout(handleClose, 1000);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      title="Add Stream Credentials"
      onClose={() => handleClose(true)}
      onClickButton={handleSubmit(handleAddCreds)}
      bodyStyles={{ gap: 2 }}
      sx={{ width: '44.5rem' }}
      footerComponent={
        <Stack
          direction="row"
          component="footer"
          justifyContent="space-between"
          gap={1}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleAppendSet}
            disabled={fields.length === MAX_STREAM_CREDS_AT_ONCE}
          >
            Add Another Set
          </Button>
          <Stack direction="row" gap={1}>
            <Button
              disabled={!!success || loading}
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!!success || loading || fields.length < 1}
            >
              {success || 'Submit'}
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Typography>
        The credentials will be added to the pool of available Stream
        credentials and then be automatically assigned to new organizations.
      </Typography>
      <Stack component="ul" gap={1} sx={{ p: 0, m: 0 }}>
        {fields.map((item, index) => (
          <Stack component="li" direction="row" gap={1} key={item.id}>
            <Controller
              name={`creds.${index}.publicKey`}
              control={control}
              rules={{
                required: 'Please enter an API key.',
                maxLength: MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="API Key"
                  autoFocus={index === 0}
                  disabled={!!success || loading}
                  error={!!errors?.creds?.[index]?.publicKey}
                  helperText={errors?.creds?.[index]?.publicKey?.message}
                  inputProps={{
                    autoComplete: 'off',
                    maxLength: MAX_LENGTH.value,
                  }}
                  sx={{
                    flex: 1,
                  }}
                />
              )}
            />
            <Controller
              name={`creds.${index}.secretKey`}
              control={control}
              rules={{
                required: 'Please enter a secret key.',
                maxLength: MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Secret Key"
                  disabled={!!success || loading}
                  error={!!errors?.creds?.[index]?.secretKey}
                  helperText={errors?.creds?.[index]?.secretKey?.message}
                  inputProps={{
                    autoComplete: 'off',
                    maxLength: MAX_LENGTH.value,
                  }}
                  sx={{
                    flex: 1,
                  }}
                />
              )}
            />
            <Button
              color="error"
              onClick={() => remove(index)}
              disabled={!!success || loading}
              sx={{ alignSelf: 'flex-start' }}
            >
              Delete
            </Button>
          </Stack>
        ))}
      </Stack>
    </BasicModal>
  );
};

export default AddStreamCredsModal;
