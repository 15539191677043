import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import useToast from '../../lib/hooks/useToast';
import Users from '../../store/user/userActions';
import {
  selectToast,
  selectUserAdminList,
  selectUserAdminFetching,
} from '../../store/user-admin/userAdminSelectors';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import UserAdmin from '../../store/user-admin/userAdminActions';
import { selectTOTPEnabled } from '../../store/org/orgSelectors';
import { selectRemainingUsers } from '../../store/dashboard/dashboardSelectors';

import UserModal from './UserModal';
import ImportUsersModal from './ImportUsersModal';
import UserAdminDataGrid from './UserAdminDataGrid';

const MODALS = {
  USER: 'USER',
  IMPORT_USERS: 'IMPORT_USERS',
};

const UserAdministration = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState('');
  const [selection, setSelection] = useState([]);
  const toast = useSelector(selectToast);
  const isTOTPEnabled = useSelector(selectTOTPEnabled);
  const userAdminList = useSelector(selectUserAdminList);
  const remainingUsers = useSelector(selectRemainingUsers);
  const fetchingUserAdmin = useSelector(selectUserAdminFetching);
  const [toastElement, openToast] = useToast();
  const [confirmElement, openConfirm] = useConfirmAsync();

  const selectedUser = userAdminList.find((u) => u.id === selection?.[0]);
  const selectedEmail = selectedUser?.email;
  const selectedUUID = selectedUser?.userProperties?.uuid;

  const dispatchSuccessMessage = useCallback(
    (message) =>
      dispatch(
        UserAdmin.setToast({
          message,
          severity: 'success',
        })
      ),
    [dispatch]
  );

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch(
        UserAdmin.setToast({
          message,
          severity: 'error',
        })
      ),
    [dispatch]
  );

  const handleAdd = useCallback(() => {
    setSelection([]);
    setModal(MODALS.USER);
  }, []);

  const handleEdit = useCallback((e, id) => {
    if (!!id) {
      setSelection([id]);
    }
    setModal(MODALS.USER);
  }, []);

  const handleImport = useCallback(() => {
    setSelection([]);
    setModal(MODALS.IMPORT_USERS);
  }, []);

  const handleDeactivate = useCallback(
    () =>
      openConfirm({
        title: 'Deactivate User',
        content: (
          <Typography align="center">
            Are you sure you want to deactivate {selectedEmail}?
          </Typography>
        ),
        buttonColor: 'error',
        buttonText: 'Deactivate',
        onConfirm: () =>
          dispatch(UserAdmin.deactivateUser(selectedUUID))
            .then(() => {
              dispatchSuccessMessage(`${selectedEmail} has been deactivated.`);
              dispatch(UserAdmin.fetchUsers());
            })
            .catch(() =>
              dispatchErrorMessage(`Error deactivating ${selectedEmail}.`)
            ),
      }),
    [
      dispatch,
      dispatchErrorMessage,
      dispatchSuccessMessage,
      openConfirm,
      selectedEmail,
      selectedUUID,
    ]
  );

  const handleReactivate = useCallback(
    () =>
      openConfirm({
        title: 'Reactivate User',
        content: (
          <Typography align="center">
            Are you sure you want to reactivate {selectedEmail}?
          </Typography>
        ),
        buttonColor: 'secondary',
        buttonText: 'Reactivate',
        onConfirm: () =>
          dispatch(UserAdmin.reactivateUser(selectedUUID))
            .then(() => {
              dispatchSuccessMessage(`${selectedEmail} has been reactivated.`);
              dispatch(UserAdmin.fetchUsers());
            })
            .catch(() =>
              dispatchErrorMessage(`Error reactivating ${selectedEmail}.`)
            ),
      }),
    [
      dispatch,
      dispatchErrorMessage,
      dispatchSuccessMessage,
      openConfirm,
      selectedEmail,
      selectedUUID,
    ]
  );

  const handleSendActivation = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Send Activation Email',
        content: (
          <Typography align="center">
            Are you sure you want to send an activation email to {email}?
          </Typography>
        ),
        buttonText: 'Send',
        onConfirm: () =>
          dispatch(UserAdmin.sendActivationEmail(uuid))
            .then(() =>
              dispatchSuccessMessage(`Activation email sent to ${email}.`)
            )
            .catch((e) => dispatchErrorMessage(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorMessage,
      dispatchSuccessMessage,
      openConfirm,
      selectedEmail,
      selectedUUID,
    ]
  );

  const handleResetTOTPEnrolment = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Reset TOTP Enrolment',
        content: (
          <Typography align="center">
            Are you sure you want to reset the TOTP enrolment for {email}? They
            will need to complete the enrolment process again to login.
          </Typography>
        ),
        buttonText: 'Reset',
        onConfirm: () =>
          dispatch(UserAdmin.resetTOTPEnrolment(uuid))
            .then(() =>
              dispatchSuccessMessage(
                `TOTP enrolment has been reset for ${email}.`
              )
            )
            .catch((e) => dispatchErrorMessage(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorMessage,
      dispatchSuccessMessage,
      openConfirm,
      selectedEmail,
      selectedUUID,
    ]
  );

  const handleResetPassword = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Reset Password',
        content: (
          <Typography align="center">
            Are you sure you want to reset the password for {email}?
          </Typography>
        ),
        buttonText: 'Reset',
        onConfirm: () =>
          dispatch(UserAdmin.sendPasswordResetEmail(uuid))
            .then(() =>
              dispatchSuccessMessage(`Password reset email sent to ${email}.`)
            )
            .catch((e) => dispatchErrorMessage(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorMessage,
      dispatchSuccessMessage,
      openConfirm,
      selectedEmail,
      selectedUUID,
    ]
  );

  const handleImportUsers = useCallback(
    (users) => dispatch(UserAdmin.importUsers(users)),
    [dispatch]
  );

  const handleImportFulfilled = useCallback(() => {
    dispatch(UserAdmin.fetchUsers());
    dispatch(Users.fetchUsers());
  }, [dispatch]);

  const handleSaveUser = useCallback(
    (data) =>
      dispatch(
        !!selectedUser
          ? UserAdmin.updateUser(data, selectedUser?.userProperties?.uuid)
          : UserAdmin.createUser(data)
      ),
    [dispatch, selectedUser]
  );

  const handleSaveUserFulfilled = useCallback(() => {
    const editing = !!selectedUser;
    if (!editing) {
      dispatch(Users.fetchUsers());
    }
    dispatch(UserAdmin.fetchUsers());
    dispatch(
      UserAdmin.setToast({
        message: `User ${editing ? 'updated' : 'created'} successfully.`,
        severity: 'success',
      })
    );
  }, [dispatch, selectedUser]);

  const handleSaveUserRejected = useCallback(
    (data) =>
      dispatch(
        UserAdmin.setToast({
          message: !!data ? data : 'Something went wrong.',
          severity: 'error',
        })
      ),
    [dispatch]
  );

  const handleCloseModal = useCallback(() => setModal(''), []);

  const handleCloseImportUsersModal = useCallback(
    (showSuccess = false) => {
      if (showSuccess) {
        openToast('Imported users successfully.', 'success');
      }
      handleCloseModal();
    },
    [handleCloseModal, openToast]
  );

  // Initialization effect.
  useEffect(() => {
    dispatch(UserAdmin.fetchUsers());
    dispatch(UserAdmin.resetToast());

    return () => dispatch(UserAdmin.resetToast());
  }, [dispatch]);

  // Toast effect.
  useEffect(() => {
    if (!!toast.message) {
      openToast(toast.message, toast.severity);
    }
  }, [toast, openToast]);

  return (
    <>
      <UserAdminDataGrid
        rows={userAdminList}
        loading={fetchingUserAdmin}
        selection={selection}
        setSelection={setSelection}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onImport={handleImport}
        onDeactivate={handleDeactivate}
        onReactivate={handleReactivate}
        onResetPassword={handleResetPassword}
        onSendActivation={handleSendActivation}
        onResetTOTPEnrolment={handleResetTOTPEnrolment}
        isTOTPEnabled={isTOTPEnabled}
      />
      <UserModal
        open={modal === MODALS.USER}
        onClose={handleCloseModal}
        user={selectedUser}
        onSave={handleSaveUser}
        onSaveFulfilled={handleSaveUserFulfilled}
        onSaveRejected={handleSaveUserRejected}
      />
      <ImportUsersModal
        open={modal === MODALS.IMPORT_USERS}
        onClose={handleCloseImportUsersModal}
        userAllowance={remainingUsers}
        onImport={handleImportUsers}
        onImportFulfilled={handleImportFulfilled}
      />
      {confirmElement}
      {toastElement}
    </>
  );
};

export default UserAdministration;
