import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActivePlaybook,
  selectCategoryOptions,
} from '../../store/playbook/playbookSelectors';
import Playbooks from '../../store/playbook/playbookActions';

import BasicModal from '../BasicModal';

const EditPlaybookCategoryModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const options = useSelector(selectCategoryOptions);
  const playbook = useSelector(selectActivePlaybook);
  const [category, setCategory] = useState(playbook?.categoryId);

  const handleClick = () => {
    return dispatch(
      Playbooks.edit(playbook?.id, {
        ...playbook,
        items: playbook.activities,
        categoryId: category,
      })
    ).then(() => {
      onClose();
    });
  };

  // Open effect.
  useEffect(() => {
    if (open) {
      setCategory(playbook?.categoryId);
    }
  }, [open, playbook]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      buttonText="Save"
      onClickButton={handleClick}
      title="Edit Playbook Category"
    >
      <Select
        value={category}
        placeholder="Playbook Category"
        onChange={(e) => setCategory(e.target.value)}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </BasicModal>
  );
};

export default EditPlaybookCategoryModal;
