import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../lib/theme';
import { fontFamily } from '../lib/theme/typography';

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      '.MuiDataGrid-cell:focus': {
        outline: 'none !important',
      },
      '.MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
      '.MuiDataGrid-columnHeader:focus': {
        outline: 'none !important',
      },
      '.MuiDataGrid-columnHeader--sortable:focus-within': {
        outline: 'none !important',
      },
      '.emoji-picker-react': {
        zIndex: 6,
      },
      a: {
        color: '#47a6b6',
        textDecoration: 'none',
        ':hover': {
          color: '#1a4f6c',
          textDecoration: 'underline',
        },
        ':focus': {
          color: '#1a4f6c',
          textDecoration: 'underline',
        },
        fontFamily,
      },
      div: {
        whiteSpace: 'normal',
      },
      code: {
        border: '1px solid #dadada',
        backgroundColor: '#f6f6f6',
        borderRadius: '5px',
      },
      ul: {
        margin: '3px 0 3px 0',
        lineHeight: '1.4em',
        whiteSpace: 'normal',
      },
      ol: {
        margin: '3px 0 3px 0',
        lineHeight: '1.4em',
        whiteSpace: 'normal',
      },
    }}
  />
);

const AppThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {inputGlobalStyles}
    {children}
  </ThemeProvider>
);

export default AppThemeProvider;
