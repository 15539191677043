import { createSelector } from '@reduxjs/toolkit';

export const selectHeartbeatState = (state) => state.heartbeat;

export const selectIsActiveTab = createSelector(
  selectHeartbeatState,
  (heartbeat) => heartbeat.isActiveTab
);

export const selectIsReady = createSelector(
  selectHeartbeatState,
  (heartbeat) => heartbeat.isReady
);
