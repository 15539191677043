import { createSelector } from 'reselect';

export const selectDraftState = (state) => state.drafts;

const selectChannelId = (state, channelId) => channelId;

export const selectDraftByChannelId = createSelector(
  [selectDraftState, selectChannelId],
  (draftState, channelId) => draftState[channelId] || undefined
);

export const selectHasDraftByChannelId = createSelector(
  [selectDraftState, selectChannelId],
  (draftState, channelId) =>
    !!draftState[channelId]?.message ||
    (!!draftState[channelId]?.file &&
      draftState[channelId]?.file instanceof Blob)
);
