import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { TASK_PRIORITY } from '../../../store/incident/incidentTypes';

import PrioritySelect from '../../PrioritySelect';
import TaskLayout from './../../playbook-manager/task/TaskLayout';
import TrashIcon from '../../../assets/icons/trash-2-outline.svg?react';

const TaskPreview = ({
  title,
  description,
  onClickDelete,
  onChangePriority,
  priority = TASK_PRIORITY.MEDIUM,
}) => (
  <TaskLayout
    isEditable={true}
    headerComponent={
      <>
        <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <PrioritySelect
          value={priority}
          onChange={onChangePriority}
          sx={{ flexShrink: 0 }}
        />
      </>
    }
    bodyComponent={
      <Typography>{description || '(Nothing to show.)'}</Typography>
    }
    rightComponent={
      <Tooltip arrow title="Delete" placement="right" disableInteractive>
        <IconButton size="small" color="error" onClick={onClickDelete}>
          <SvgIcon component={TrashIcon} />
        </IconButton>
      </Tooltip>
    }
  />
);

export default TaskPreview;
