import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiLinearProgress from '@mui/material/LinearProgress';

const Container = styled(Box)`
  position: relative;
`;

const LinearProgress = styled(MuiLinearProgress)`
  height: 1.5rem;
  border-radius: 1.5rem;
  background-color: #ddd;
  & .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const LabelContainer = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ProgressBar = ({ value, ...props }) => (
  <Container {...props}>
    <LinearProgress variant="determinate" value={value} />
    <LabelContainer>
      <Typography variant="caption" textAlign="center">{`${Math.round(
        value
      )}%`}</Typography>
    </LabelContainer>
  </Container>
);

export default ProgressBar;
