import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    font-size: 16px;
    font-weight: bold;
  }
`;

const DialogModal = ({ open, title, message, onConfirm, onCancel }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogWrapper>
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
      <DialogContent sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ gap: '25px' }}>
        <Button onClick={onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </DialogWrapper>
  </Dialog>
);

export default DialogModal;
