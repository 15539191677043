import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Messages from '../../store/chat/messageActions';
import { selectUnreadCounts } from '../../store/chat/chatSelectors';

/**
 * Check the documentation for this component in the project README.
 */
const MessageStatusManager = () => {
  const dispatch = useDispatch();
  const unreadCounts = useSelector(selectUnreadCounts);

  useEffect(() => {
    // console.log('MessageStatusManager mount');
    const favicon180 = document.querySelector("link[sizes~='180x180']");
    const favicon32 = document.querySelector("link[sizes~='32x32']");
    const favicon16 = document.querySelector("link[sizes~='16x16']");
    const faviconIco = document.querySelector("link[type~='image/x-icon']");

    if (Object.keys(unreadCounts).length > 0) {
      favicon180.href = '/apple-touch-icon-dot.png';
      favicon32.href = '/favicon-dot-32x32.png';
      favicon16.href = '/favicon-dot-16x16.png';
      faviconIco.href = '/favicon-dot.ico';
    } else {
      favicon180.href = '/apple-touch-icon.png';
      favicon32.href = '/favicon-32x32.png';
      favicon16.href = '/favicon-16x16.png';
      faviconIco.href = '/favicon.ico';
    }

    const focusListener = () => dispatch(Messages.read());

    window.addEventListener('focus', focusListener);

    return () => {
      // console.log('MessageStatusManager unmount');
      window.removeEventListener('focus', focusListener);
    };
  }, [unreadCounts, dispatch]);

  return null; // This component does not render anything.
};

export default MessageStatusManager;
