import { useState } from 'react';
import remarkGfm from 'remark-gfm';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled, useTheme } from '@mui/material/styles';

import Avatar from '../../Avatar';
import { getCachedProfileImageUrl } from '../../../utils/imageUtils';
import { selectCurrentChatUsers } from '../../../store/chat/chatSelectors';

// Simple email regex pulled from know-parser email plugin
// (https://github.com/george-hm/know-parser). Using it manually so
// that we can match ALL occurances and not consolidate the same
// matches like know-parser does by default.
const emailRegex =
  /(([^<>()[\]\\.,;:\s@"'/?=#{}%]+(?!png|jpg|jpeg|zvg)(\.[^<>()[\]\\.,;:\s@"/=?]+)*(?!png|jpg|jpeg|zvg)))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+((?!png|jpg|jpeg|zvg)[a-zA-Z]{2,})))/gi;
const emojiRegex =
  /[\x2A\x30-\x39\x41-\x5A\x61-\x7A\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+/g;

const Wrapper = styled.div`
  padding-left: 4px;

  p:last-of-type {
    display: inline;
  }
`;

const MentionName = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  cursor: pointer;
`;

const PopoverProfileImg = styled.div`
  display: flex;
  height: 18em;
  width: 18em;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 18em;
  height: 18em;
  object-fit: cover;
`;

function Mention({ user = { firstName: 'Former', lastName: 'Member' } }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const { firstName, lastName, profileImageUrl } = user;

  const name = `${firstName} ${lastName}`;

  let profileImage = null;

  if (profileImageUrl) {
    profileImage = <Image alt={name} src={getCachedProfileImageUrl(user)} />;
  } else {
    profileImage = (
      <Avatar
        firstName={firstName}
        lastName={lastName}
        fontSize="8rem"
        size="large"
      />
    );
  }

  const anchor = { vertical: 'top', horizontal: 'left' };
  const transform = { vertical: 'center', horizontal: 'right' };

  return (
    <>
      <Tooltip title={name} placement="top" arrow>
        <MentionName
          theme={theme}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {name}
        </MentionName>
      </Tooltip>
      {user && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={anchor}
          transformOrigin={transform}
        >
          <Card>
            <CardMedia>
              <PopoverProfileImg>{profileImage}</PopoverProfileImg>
            </CardMedia>
            <CardContent>
              <div>{name}</div>
              <div>
                <small>{user.email}</small>
              </div>
            </CardContent>
          </Card>
        </Popover>
      )}
    </>
  );
}

const makeRenderer = (users) => ({
  p: ({ children }) => {
    const [value] = children;

    if (typeof value === 'string') {
      const mentionsRegex = /@/;
      const stringValue = value.split('');
      const isNotEmoji = stringValue.some((chr) => emojiRegex.test(chr));
      const isMentions = stringValue.some((chr) => mentionsRegex.test(chr));

      if (isNotEmoji) {
        return <p>{children}</p>;
      }

      if (children.length === 1 && !emojiRegex.test(children)) {
        return <p style={{ fontSize: '25px' }}>{children}</p>;
      }

      if (isMentions) {
        return <p>{children}</p>;
      }

      return <p>{children}</p>;
    }

    return <p>{children}</p>;
  },
  a: ({ children, href }) => {
    const [value] = children;
    const isEmail = value.match(emailRegex);
    // Handle email input and display mention or default email.
    if (isEmail && isEmail.length > 0) {
      const user = users.find((u) => u?.email === value);
      if (user && user.active) {
        return <Mention user={user} />;
      }

      return (
        <a target="_blank" rel="noreferrer" href={`mailto: ${children}`}>
          {children}
        </a>
      );
    }

    return (
      <a target="_blank" rel="noreferrer" href={href}>
        {href}
      </a>
    );
  },
  li: ({ children }) => <li>{children}</li>,
});

export default function TextContent({
  text,
  created_at: createdAt,
  updated_at: updatedAt,
  isCall,
}) {
  const users = useSelector(selectCurrentChatUsers);
  const renderers = makeRenderer(users);
  const create = new Date(createdAt);
  const updated = new Date(updatedAt);
  const isUpdated = create.getTime() < updated.getTime() && !isCall;

  return (
    <Wrapper>
      <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
        {text.replaceAll(/([\uFFFC])/g, '')}
      </ReactMarkdown>
      {isUpdated && (
        <small style={{ marginLeft: '4px', color: '#aaa' }}>(Edited)</small>
      )}
    </Wrapper>
  );
}
