import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import promise from 'redux-promise-middleware';
import storageMiddleware from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import org from './org/orgReducer';
import sdk from './sdk/sdkReducer';
import auth from './auth/authReducer';
import token from './token/tokenReducer';
import users from './user/userReducer';
import heartbeat from './heartbeat/heartbeatReducer';
import navigation from './navigation/navigationReducer';
import Navigation from './navigation/navigationActions';
import dashboard from './dashboard/dashboardReducer';
import incidents from './incident/incidentReducer';
import chats from './chat/chatReducer';
import messages from './chat/messageReducer';
import drafts from './chat/draftReducer';
import jitsi from './jitsi/jitsiReducer';
import storage from './storage/storageReducer';
import notifications from './notification/notificationReducer';
import userAdmin from './user-admin/userAdminReducer';
import playbooks from './playbook/playbookReducer';
import welcome from './welcome/welcomeReducer';
import management from './management/managementReducer';
import groups from './group/groupReducer';
import groupAdmin from './group-admin/groupAdminReducer';
import messagingAdmin from './messaging-admin/messagingAdminReducer';

const persistConfig = {
  key: 'root',
  storage: storageMiddleware,
  whitelist: ['org', 'auth', 'drafts', 'storage', 'welcome', 'management'],
};

const rootReducer = combineReducers({
  org,
  sdk,
  auth,
  token,
  users,
  heartbeat,
  navigation,
  dashboard,
  incidents,
  chats,
  messages,
  drafts,
  jitsi,
  storage,
  notifications,
  userAdmin,
  playbooks,
  welcome,
  management,
  groups,
  groupAdmin,
  messagingAdmin,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [promise, thunk],
  devTools: window._env_.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

/**
 * A helper function to leverage the useNavigate hook from outside of a React component.
 * @param {String} to - The path where to navigate.
 * @param {Object} options - Specified options, such as replace, etc.
 * @returns {Object}
 */
export const navigateHelper = (to, options = undefined) =>
  store.dispatch(Navigation.navigate(to, options));
