import { v4 } from 'uuid';

import Drafts from './draftActions';
import { MESSAGE_TYPES } from './messageReducer';
import { selectMessages } from './messageSelectors';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectSDKConnected } from '../sdk/sdkSelectors';
import { selectCurrentUser } from '../auth/authSelectors';
import { selectChatsList, selectCurrentChat } from './chatSelectors';

const Messages = {
  fetchMessages: (channel, limit) => (dispatch, getState) => {
    const messages = selectMessages(getState());

    const filterValue = !messages.length
      ? { limit }
      : { limit, id_lt: messages[0]?.id };

    return dispatch({
      type: MESSAGE_TYPES.FETCH_MESSAGES,
      payload: channel.query({ messages: filterValue }),
    });
  },
  fetchInitMessages: (channel) => (dispatch, getState) => {
    dispatch({
      type: MESSAGE_TYPES.FETCH_INIT_MESSAGES,
      payload: channel.query({ messages: { limit: 100 } }).then((res) => {
        const currentChat = selectCurrentChat(getState());

        if (currentChat.id !== channel.id) {
          return null;
        }

        return res;
      }),
    });
  },
  sendMessage:
    (text, attachments = null, custom = {}) =>
    (dispatch, getState) => {
      const currentChat = selectCurrentChat(getState());
      console.info('@@SHQ/Messaging - Sending message...');

      // Clear any draft for this chat.
      dispatch(Drafts.delete(currentChat.cid));

      return dispatch({
        type: MESSAGE_TYPES.SEND_MESSAGE,
        payload: currentChat.sendMessage({ text, attachments, ...custom }),
      });
    },
  queueMessage:
    (text, attachments = null, custom = {}) =>
    (dispatch, getState) => {
      const currentChat = selectCurrentChat(getState());
      const message = {
        text,
        attachments,
        id: v4(),
        cid: currentChat.cid,
        created_at: new Date(Date.now()).toISOString(),
        user: currentChat.state.membership.user,
        ...custom,
      };

      return dispatch({
        type: MESSAGE_TYPES.QUEUE_MESSAGE,
        payload: message,
      });
    },
  sendQueuedMessage: (message) => (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());
    const { text, attachments, cid } = message;

    dispatch({
      type: MESSAGE_TYPES.SEND_MESSAGE,
      payload: currentChat.sendMessage({ text, attachments }),
    });

    return dispatch({
      type: MESSAGE_TYPES.POP_MESSAGE,
      payload: { cid, message },
    });
  },
  addMessage: (event) => (dispatch, getState) => {
    const { currentTop } = getState().chats;
    const currentChat = selectCurrentChat(getState());
    const currentUser = selectCurrentUser(getState());
    const sdkConnected = selectSDKConnected(getState());

    const { cid, message } = event;

    if (cid === currentChat.cid) {
      dispatch({
        type: MESSAGE_TYPES.MESSAGE_ADDED,
        payload: message,
      });

      const readMessage =
        message.user.id !== currentUser.uuid &&
        currentTop >= 0.95 &&
        window.location.href.includes('/chat') &&
        document.hasFocus();

      if (readMessage && sdkConnected) {
        currentChat.markRead();
      }
    }
  },
  updateMessage: (details, messageId) => (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    return dispatch({
      type: MESSAGE_TYPES.UPDATE_MESSAGE,
      payload: axiosInstance.patch(`/stream/updateMessage/${messageId}`, {
        ...details,
        user_id: currentUser.uuid,
      }),
    });
  },
  setUpdatedMessage: (message) => ({
    type: MESSAGE_TYPES.SET_UPDATED_MESSAGE,
    payload: { message },
  }),
  replyToMessage: (participants) => (dispatch, getState) => {
    const { messageId, cid } = getState().jitsi;
    const chatList = selectChatsList(getState());

    const channel = chatList.find((c) => c.cid === cid);

    return dispatch({
      type: MESSAGE_TYPES.UPDATE_MESSAGE,
      payload: channel.sendMessage({
        participants,
        parent_id: messageId,
        show_in_channel: false,
      }),
    });
  },
  read: () => (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());
    const hasUnread =
      currentChat &&
      Object.keys(currentChat).length !== 0 &&
      currentChat.state.unreadCount > 0;

    if (hasUnread) {
      dispatch({
        type: MESSAGE_TYPES.READ_MESSAGE,
        payload: currentChat.markRead(),
      });
    }
  },
  updateRead: (cid, read) => (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());

    if (cid === currentChat.cid) {
      dispatch({
        type: MESSAGE_TYPES.UPDATE_READ,
        payload: read,
      });
    }
  },
  toggleEditing: (messageId) => (dispatch) => {
    dispatch({
      type: MESSAGE_TYPES.TOGGLE_EDITING,
      payload: messageId,
    });
  },
};

export default Messages;
