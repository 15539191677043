import { useState, useMemo, useCallback } from 'react';

import useToast from './useToast';

import BasicModal from '../../components/BasicModal';

const usePrompt = () => {
  const [open, setOpen] = useState(false);
  const [toastElement, openToast] = useToast();
  const [{ title, content, onYes, onNo }, setProps] = useState({});

  const element = useMemo(() => {
    const handleClose = () => setOpen(false);
    return (
      <BasicModal
        open={open}
        title={title}
        buttonText="Yes"
        cancelButtonText="No"
        onClose={async () => {
          try {
            await onNo();
            handleClose();
          } catch (e) {
            console.error('error', e);
            openToast(e.message, 'error');
          }
        }}
        onClickButton={async () => {
          try {
            await onYes();
            handleClose();
          } catch (e) {
            console.error('error', e);
            openToast(e.message, 'error');
          }
        }}
        sx={{ maxWidth: '28rem' }}
      >
        {content}
        {toastElement}
      </BasicModal>
    );
  }, [open, title, content, onYes, onNo, openToast, toastElement]);

  const trigger = useCallback(
    ({ title, content, onYes, onNo = () => {} }) => {
      setProps({
        title,
        content,
        onYes,
        onNo,
      });
      setOpen(true);
    },
    [setProps, setOpen]
  );
  return [element, trigger];
};

export default usePrompt;
