import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  validatePassword,
  PASSWORD_VALIDATION_MESSAGES,
} from '../../utils/formUtils';
import { handlePaste } from '../../lib/input';
import usePrompt from '../../lib/hooks/usePrompt';
import Enterprise from '../../store/org/orgActions';
import { MAX_LENGTH } from '../../configs/validation';
import Authentication from '../../store/auth/authActions';
import { selectIsAuthenticated } from '../../store/auth/authSelectors';

import PasswordInput from '../PasswordInput';
import UnauthenticatedLayout from '../UnauthenticatedLayout';
import XIcon from '../../assets/icons/close.svg?react';
import CheckmarkIcon from '../../assets/icons/checkmark-outline.svg?react';

const ValidationMessage = styled(Typography)`
  color: ${({ theme, color }) => theme.palette[color].main};
`;

export default function SetPassword() {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      new: '',
      confirm: '',
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState('initial');
  const [passwordErrors, setPasswordErrors] = useState({});
  const sessionExists = useSelector(selectIsAuthenticated);
  const [signOutPromptElement, openSignOutPrompt] = usePrompt();

  const userId = params.get('u');
  const enterpriseCode = params.get('e');
  const oneTimePasscode = params.get('t');
  const baseURL = atob(params.get('a') || '');
  const isMissingParams =
    !userId || !enterpriseCode || !oneTimePasscode || !baseURL;

  const passwordNew = watch('new');
  const passwordConfirm = watch('confirm');
  const submitDisabled =
    !passwordNew || !passwordConfirm || !!Object.keys(passwordErrors).length;

  const navigateToApp = () => navigate('/setup');

  const handleClickGoToApp = () => {
    if (!sessionExists) {
      return navigateToApp();
    }

    openSignOutPrompt({
      title: 'Already Signed In',
      content: (
        <Typography align="center">
          It looks like you are currently signed into ShadowHQ, would you like
          to sign out?
        </Typography>
      ),
      onYes: () => {
        dispatch(Authentication.logout(true));
        navigateToApp();
      },
      onNo: () => navigateToApp(),
    });
  };

  const handleSetPassword = (data) => {
    const urlParams = {
      userId,
      baseURL,
      enterpriseCode,
      oneTimePasscode,
    };
    const newPassword = data.new.trim();

    setLoading(true);
    dispatch(Authentication.changePasswordOutside(urlParams, newPassword))
      .then(() => setFormState('success'))
      .catch(() => setFormState('error'))
      .finally(() => setLoading(false));
  };

  // Initialization effect.
  useEffect(() => {
    if (isMissingParams) {
      navigate('/');
    } else {
      dispatch(Enterprise.fetchRegistry(enterpriseCode));
    }
  }, [isMissingParams, navigate, dispatch, enterpriseCode]);

  // Validation effect.
  useEffect(() => {
    setPasswordErrors(validatePassword(passwordNew, passwordConfirm));
  }, [passwordNew, passwordConfirm]);

  if (formState === 'error') {
    return (
      <UnauthenticatedLayout title="Your password was not set!">
        <Typography color="error">
          Your authorization token has expired.
        </Typography>
        <Typography>
          Please contact your administrator to request a password reset.
        </Typography>
      </UnauthenticatedLayout>
    );
  }

  if (formState === 'success') {
    return (
      <UnauthenticatedLayout
        title="Password successfully updated!"
        buttons={[
          {
            variant: 'contained',
            title: 'Go To ShadowHQ',
            onClick: handleClickGoToApp,
          },
        ]}
      >
        <Typography>Click the button to log into your account.</Typography>
        {signOutPromptElement}
      </UnauthenticatedLayout>
    );
  }

  return (
    <UnauthenticatedLayout
      title="Set Password"
      contentProps={{
        sx: {
          gap: 2,
          width: '100%',
          '@media only screen and (min-width: 720px)': {
            gap: 4,
            width: '100%',
            flexDirection: 'row',
          },
          '@media only screen and (min-width: 960px)': {
            width: '865px',
          },
        },
      }}
    >
      <Stack
        component="form"
        alignItems="center"
        justifyContent="space-between"
        onSubmit={handleSubmit(handleSetPassword)}
        sx={{
          gap: 2,
          flex: 1,
          width: '100%',
          '@media only screen and (min-width: 720px)': {
            gap: 3,
            height: '100%',
          },
        }}
      >
        <Controller
          name="new"
          control={control}
          rules={{
            required: 'Please enter a new password.',
            validate: (value) =>
              value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PasswordInput
                {...field}
                autoFocus
                fullWidth
                disabled={loading}
                label="New Password"
                error={!!errors?.new}
                helperText={errors?.new?.message}
                onPaste={(e) => handlePaste(e, onChange)}
                inputProps={{ maxLength: MAX_LENGTH.value }}
              />
            );
          }}
        />
        <Controller
          name="confirm"
          control={control}
          rules={{
            required: 'Please confirm the new password.',
            validate: (value) =>
              value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PasswordInput
                {...field}
                fullWidth
                disabled={loading}
                label="Confirm New Password"
                error={!!errors?.confirm}
                helperText={errors?.confirm?.message}
                onPaste={(e) => handlePaste(e, onChange)}
                inputProps={{ maxLength: MAX_LENGTH.value }}
              />
            );
          }}
        />
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading || submitDisabled}
        >
          Set Password
        </Button>
      </Stack>
      <Stack gap={1} sx={{ flex: 1 }}>
        {PASSWORD_VALIDATION_MESSAGES.map((m) => {
          const ruleMet = !passwordErrors?.[m.rule];
          const icon = ruleMet ? CheckmarkIcon : XIcon;
          const color = ruleMet ? 'success' : 'disabled';
          return (
            <Stack key={m.rule} gap={1} direction="row" alignItems="center">
              <SvgIcon color={color} component={icon} />
              <ValidationMessage color={color} component="span">
                {m.text}
              </ValidationMessage>
            </Stack>
          );
        })}
      </Stack>
    </UnauthenticatedLayout>
  );
}
