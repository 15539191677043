import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';

import TestIcon from '../../assets/icons/home-3-line.svg?react';

const Panel = styled(Paper)`
  && {
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    border-radius: 10px;
    transition: background 0.2s ease-in-out;
  }
  &&:hover {
    background: ${({ theme, disabled }) =>
      disabled ? '' : theme.palette.action.hover};
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
  ${(props) =>
    props.disabled &&
    `
    > * {
      opacity: 0.2;
    }
    &&:hover{
    cursor: default;
    }
`}
`;

export default function DashPanel({
  icon = <TestIcon />,
  title,
  size,
  disabled = false,
  route = '/',
  state = undefined,
  onClick = undefined,
}) {
  const theme = useTheme();

  return (
    <Panel
      elevation={1}
      to={route}
      state={state}
      component={Link}
      onClick={disabled ? (e) => e.preventDefault() : onClick}
      disabled={disabled}
    >
      <SvgIcon
        fontSize={size}
        htmlColor={theme.palette.primary.main}
        style={{ width: '2rem', height: '2rem' }}
      >
        {icon}
      </SvgIcon>
      <Typography
        variant="body1"
        display="block"
        sx={{ lineHeight: 1, mt: '12px' }}
      >
        {title}
      </Typography>
    </Panel>
  );
}
