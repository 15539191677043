import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectOrgConfigFileSize,
  selectOrgConfigAllowedFormats,
} from '../../store/org/orgSelectors';
import Storage from '../../store/storage/storageActions';
import { createRegExAllowedFileFormats, formatFileSize } from '../../lib/files';

import BasicModal from '../BasicModal';
import FileIcon from '../../assets/icons/file-text-outline.svg?react';

const FileArea = styled(Stack)`
  cursor: pointer;
  min-height: 10em;
  border: ${({ theme, isDragActive }) =>
    isDragActive
      ? `2px dashed ${theme.palette.primary.main}`
      : '1px solid #ccc'};
  border-radius: 8px;

  svg {
    color: rgba(0, 0, 0, 0.54);
  }
  p {
    margin-bottom: 0;
  }
`;

const FileUploadModal = ({ open, onClose, folderId }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [dragOverModal, setDragOverModal] = useState(false);
  const MAX_FILE_SIZE = useSelector(selectOrgConfigFileSize);
  const configAllowedFormats = useSelector(selectOrgConfigAllowedFormats);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      const allowedFileExtensions =
        createRegExAllowedFileFormats(configAllowedFormats);

      if (!allowedFileExtensions.test(newFile?.name)) {
        return setError('Sorry, this file format is not supported.');
      }

      if (newFile && newFile?.size > MAX_FILE_SIZE) {
        return setError(
          `Sorry, this file exceeds the maximum size of ${formatFileSize(
            MAX_FILE_SIZE
          )}.`
        );
      }

      setFile(newFile);
      setError('');
    },
    [configAllowedFormats, MAX_FILE_SIZE]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const isDragging = isDragActive || dragOverModal;

  const handleClose = useCallback(() => {
    if (uploading) {
      return;
    }
    setFile(null);
    setError('');
    setUploading(false);
    setDragOverModal(false);
    onClose();
  }, [uploading, onClose]);

  const handleUpload = useCallback(() => {
    setUploading(true);
    return dispatch(Storage.uploadFile(file, folderId))
      .then(handleClose)
      .catch(() => setError('Something went wrong.'))
      .finally(() => setUploading(false));
  }, [dispatch, file, folderId, handleClose]);

  return (
    <BasicModal
      open={open}
      title="Upload File"
      onClose={handleClose}
      onDragOver={() => {
        if (!dragOverModal) {
          setDragOverModal(true);
        }
      }}
      onClickButton={handleUpload}
      buttonDisabled={!file || !!error}
      onDrop={() => setDragOverModal(false)}
      onDragLeave={() => setDragOverModal(false)}
      bodyStyles={{ gap: 0.5 }}
      sx={{ maxWidth: '30rem' }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <FileArea
          gap={1}
          alignItems="center"
          justifyContent="center"
          isDragActive={isDragging}
        >
          <SvgIcon component={FileIcon} fontSize="large" />
          {!file && isDragging && (
            <Typography align="center">Drag and drop the file here.</Typography>
          )}
          {!file && !isDragging && (
            <Typography align="center">
              Drop a file here, or click to select a file.
            </Typography>
          )}
          {file && <Typography>{file.name}</Typography>}
          {!!error && <Typography color="error">{error}</Typography>}
        </FileArea>
      </div>
      <Typography
        color="grey"
        align="center"
        variant="caption"
        component="small"
      >
        Files supported: {configAllowedFormats.sort().join(', ')}
      </Typography>
    </BasicModal>
  );
};

export default FileUploadModal;
