import React, { useState, useCallback, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import TextContent from './TextContent';
import MessageState from './MessageState';
import Messages from '../../../store/chat/messageActions';
import { formatTime, minutesSinceTimestamp } from '../../../lib/date';
import {
  selectIsOneToOne,
  selectReadReceipt,
} from '../../../store/chat/chatSelectors';
import {
  selectIsEditing,
  selectLastOutgoing,
} from '../../../store/chat/messageSelectors';
import { selectCurrentUserUUID } from '../../../store/auth/authSelectors';
import { selectMessageHistoryEditTimeout } from '../../../store/org/orgSelectors';
import ImageContent from './ImageContent';
import FileContent from './FileContent';
import CallContent from './CallContent';
import UpdateMessageInput from '../input/UpdateMessageInput';
import TooltipCustom from './TooltipCustom';
import MenuCustom from './MenuCustom';

// Icons
import EditIcon from '../../../assets/icons/edit-outline.svg?react';
import ReadIcon from '../../../assets/icons/eye-outline.svg?react';

const MessageWrapper = styled.div`
  position: relative;
  width: auto;
  padding: 0;
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: transparent;

  :hover {
    background-color: #eeeeee;
    border-radius: 5px;
  }

  p {
    white-space: pre-wrap;
    font-size: 15px;
    margin: 0;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: -20px;
  right: -5px;
`;

const Read = styled.span`
  z-index: 4;
  display: none;
  margin-left: 5px;
  margin-right: 5px;

  ${MessageWrapper}:hover & {
    display: block;
  }

  ${MessageWrapper}:active & {
    display: block;
  }
`;

const Edit = styled.span`
  z-index: 4;
  display: none;
  margin-left: 5px;
  margin-right: 5px;

  ${MessageWrapper}:hover & {
    display: block;
  }

  ${MessageWrapper}:active & {
    display: block;
  }
`;

const Timestamp = styled.span`
  position: absolute;
  top: -3px;
  left: -57.5px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #555;
`;

function MessageContent({
  timestamp,
  firstInGroup,
  isEditable,
  handleClickEditButton,
  children,
}) {
  const theme = useTheme();
  const time = new Date(timestamp);
  const [showTime, setShowTime] = useState(false);
  const [withinEditableTimeLimit, setWithinEditableTimeLimit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRead = useRef(null);
  const buttonReadNotEdit = useRef(null);
  const isOneToOne = useSelector(selectIsOneToOne);
  const readReceiptList = useSelector(selectReadReceipt);
  const editableTimeLimit = useSelector(selectMessageHistoryEditTimeout);

  const handleClickReadButton = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
    }
  }, [isMenuOpen]);
  const handleClickReadButtonNotEdit = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
    }
  }, [isMenuOpen]);

  const handleOpen = () => {
    setWithinEditableTimeLimit(
      minutesSinceTimestamp(timestamp) < editableTimeLimit
    );
    if (!firstInGroup) {
      setShowTime(true);
    }
  };

  const handleClose = () => {
    setShowTime(false);
    setIsMenuOpen(false);
  };

  return (
    <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      {children}
      {isEditable && withinEditableTimeLimit ? (
        <TooltipWrapper>
          <TooltipCustom content="Edit message" isMenuOpen={isMenuOpen}>
            <Edit>
              <IconButton
                onClick={handleClickEditButton}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: '1.3em',
                  width: '1.3em',
                  display: 'flex',
                }}
                size="medium"
                disableRipple
              >
                <SvgIcon
                  component={EditIcon}
                  fill={theme.palette.white.main}
                  style={{
                    color: theme.palette.white.main,
                    padding: '2px',
                  }}
                >
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Edit>
          </TooltipCustom>
          {!isOneToOne && (
            <TooltipCustom content="Read receipt" isMenuOpen={isMenuOpen}>
              <Read>
                <IconButton
                  ref={buttonRead}
                  onClick={handleClickReadButton}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    height: '1.3em',
                    width: '1.3em',
                    display: 'flex',
                  }}
                  size="medium"
                  disableRipple
                >
                  <SvgIcon
                    component={ReadIcon}
                    fill={theme.palette.white.main}
                    style={{
                      color: theme.palette.white.main,
                      padding: '2px',
                    }}
                  >
                    <ReadIcon />
                  </SvgIcon>
                </IconButton>
                <MenuCustom
                  open={isMenuOpen}
                  onClose={() => setIsMenuOpen(false)}
                  anchorEl={buttonRead.current}
                  readReceiptList={readReceiptList}
                />
              </Read>
            </TooltipCustom>
          )}
        </TooltipWrapper>
      ) : (
        !isOneToOne &&
        isEditable && (
          <TooltipWrapper>
            <TooltipCustom content="Read receipts" isMenuOpen={isMenuOpen}>
              <Read>
                <IconButton
                  ref={buttonReadNotEdit}
                  onClick={handleClickReadButtonNotEdit}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    height: '1.3em',
                    width: '1.3em',
                    display: 'flex',
                  }}
                  size="medium"
                  disableRipple
                >
                  <SvgIcon
                    component={ReadIcon}
                    fill={theme.palette.white.main}
                    style={{
                      color: theme.palette.white.main,
                      padding: '2px',
                    }}
                  >
                    <ReadIcon />
                  </SvgIcon>
                </IconButton>
                <MenuCustom
                  open={isMenuOpen}
                  onClose={() => setIsMenuOpen(false)}
                  anchorEl={buttonReadNotEdit.current}
                  readReceiptList={readReceiptList}
                />
              </Read>
            </TooltipCustom>
          </TooltipWrapper>
        )
      )}
      {showTime && <Timestamp>{formatTime(time)}</Timestamp>}
    </div>
  );
}

function MessageItem({ message, index }) {
  const dispatch = useDispatch();
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const isOneToOne = useSelector(selectIsOneToOne);
  const lastOutgoing = useSelector(selectLastOutgoing);
  const isEditing = useSelector(selectIsEditing);
  const { created_at: timestamp, user } = message;
  const showReceipt =
    isOneToOne && currentUserUUID === user.id && lastOutgoing.id === message.id;
  const isEdit = message.id === isEditing;

  const handleClick = () => {
    dispatch(Messages.toggleEditing(message.id));
  };

  const handleCloseEditor = () => {
    dispatch(Messages.toggleEditing(null));
  };

  let textContent = null;

  if (message.text.replaceAll(/([\uFFFC || ])/g, '').length !== 0) {
    textContent = <TextContent {...message} />;
  }

  let attachmentContent = null;
  if (!!message.attachments && message.attachments.length > 0) {
    const [attachment] = message.attachments;

    if (attachment.type === 'Image') {
      attachmentContent = <ImageContent {...message} />;
    } else {
      attachmentContent = <FileContent {...message} />;
    }
  }

  const isEditableUser = currentUserUUID === textContent?.props.user.id;
  textContent = <TextContent {...message} />;

  let callContent = null;
  if (message.isCall) {
    callContent = <CallContent {...message} />;
  }

  return (
    (textContent !== null ||
      attachmentContent !== null ||
      callContent !== null) && (
      <>
        <MessageWrapper>
          <MessageContent
            timestamp={timestamp}
            firstInGroup={index === 0}
            isEditable={isEditableUser && !isEditing}
            handleClickEditButton={handleClick}
            message={message}
          >
            {isEdit ? (
              <UpdateMessageInput
                editValue={textContent?.props.text}
                editId={textContent?.props.id}
                handleCloseEditor={handleCloseEditor}
              />
            ) : (
              textContent
            )}
            {attachmentContent}
            {callContent}
          </MessageContent>
        </MessageWrapper>
        {showReceipt && <MessageState time={message.created_at} />}
      </>
    )
  );
}

export default React.memo(MessageItem);
