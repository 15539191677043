import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';

import { MAX_LENGTH } from '../../../configs/validation';
import { managementApi } from '../../../configs/managementApi';

import BasicModal from '../../BasicModal';

const DeleteOrgModal = ({ open, onClose, enterprise }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const currentName = enterprise?.name;

  const handleClose = (isCancel = false) => {
    reset();
    setSuccess('');
    onClose(isCancel);
  };

  const handleDelete = () => {
    setLoading(true);
    return managementApi
      .delete(`/enterprise/${enterprise.id}`)
      .then(() => {
        setSuccess('Organization deleted!');
        setTimeout(handleClose, 1000);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      buttonColor="error"
      title="Delete Organization"
      buttonText={success || 'Delete'}
      onClose={() => handleClose(true)}
      buttonDisabled={!!success || loading}
      onClickButton={handleSubmit(handleDelete)}
    >
      <Typography textAlign="center">
        To delete the organization, type <b>{currentName}</b> in the field
        below:
      </Typography>
      <Controller
        name="name"
        control={control}
        rules={{
          maxLength: MAX_LENGTH,
          validate: (v) =>
            v.trim() === currentName.trim()
              ? true
              : 'The text must match the name of the organization.',
          required: "Please enter the organization's name to confirm.",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            size="small"
            error={!!errors.name}
            label="Organization Name"
            disabled={!!success || loading}
            helperText={errors?.name?.message}
            inputProps={{ maxLength: MAX_LENGTH.value }}
          />
        )}
      />
    </BasicModal>
  );
};

export default DeleteOrgModal;
