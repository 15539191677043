import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUsers,
  selectWarRoomByKey,
} from '../../../store/welcome/welcomeSelectors';
import Welcome from '../../../store/welcome/welcomeActions';

import BasicModal from '../../BasicModal';

const Autocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-tag {
    height: 26px;
    margin: 8px 4px;
  }
`;

const EditWarRoomModal = ({ open, onClose, warRoom }) => {
  const key = warRoom?.key;
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const { label, admin, members } = useSelector((state) =>
    selectWarRoomByKey(state, key)
  );
  const optionsListForAdmin = !admin
    ? users.filter((u) => !members.find((m) => m.id === u.id))
    : users;
  const optionsListForMembers = users.filter((m) => m.id !== admin?.id);

  const handleChangeLabel = (event) => {
    const label = event.target.value;
    dispatch(Welcome.editWarRoom({ key, label, isCustom: true }));
  };

  const handleChangeAdminMember = (event, adminValue) => {
    const admin = adminValue?.[0] || null;
    dispatch(Welcome.editWarRoom({ key, admin }));
  };

  const handleChangeMembers = (event, members) =>
    dispatch(Welcome.editWarRoom({ key, members }));

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      buttonText="Close"
      title="Edit War Room"
      onClickButton={onClose}
      showCancelButton={false}
    >
      <TextField
        size="small"
        label="Title"
        value={label}
        onChange={handleChangeLabel}
      />
      <Autocomplete
        multiple
        autoFocus
        autoHighlight
        limitTags={1}
        size="small"
        options={optionsListForAdmin}
        value={!!admin ? [admin] : []}
        onChange={handleChangeAdminMember}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              color="primary"
              label={`${option.firstName} ${option.lastName}`}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Admin / Moderator"
            placeholder="Type name and press Enter to add..."
            helperText="If you cannot find a particular user, ensure it is not added as a member below."
          />
        )}
      />
      <Autocomplete
        multiple
        autoFocus
        autoHighlight
        size="small"
        value={members}
        onChange={handleChangeMembers}
        options={optionsListForMembers}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              color="primary"
              label={`${option.firstName} ${option.lastName}`}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label="Members"
            placeholder="Type names and press Enter to add..."
            helperText="If an Admin/Moderator is not selected, the first member of this list will be used."
          />
        )}
      />
    </BasicModal>
  );
};

export default EditWarRoomModal;
