import { useSelector } from 'react-redux';

import useUserRole from './useUserRole';
import { determineAccess } from '../utils/configUtils';
import { selectEnabledFeatures } from '../store/org/orgSelectors';

/**
 * Hook for checking if a user can access a resource without the use of a component.
 * @param {object} accessRequirements - A set of requirements required for access.
 * @param {string[]} accessRequirements.roles - Give access to a set of roles.
 * @param {string} accessRequirements.permission - Give access to a named feature, which is mapped to a set of roles.
 * @param {string} accessRequirements.dependentFeature - Give access only if a named feature is enabled for the org.
 */
const useAccess = ({ roles, permission, dependentFeature }) => {
  const { role, isLoading } = useUserRole();
  const enabledFeatures = useSelector(selectEnabledFeatures);

  const isAllowed = determineAccess(role, enabledFeatures, {
    roles,
    permission,
    dependentFeature,
  });

  return !isLoading && isAllowed;
};

export default useAccess;
