import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { compare } from 'compare-versions';

import { VERSION_CHANGE_WAIT_TIME } from '../configs';
import { localStorageKeys } from '../utils/localStorageUtils';

const CacheBusterContext = createContext({ checkCacheStatus: () => {} });

const CacheBuster = ({
  children = null,
  currentVersion,
  isEnabled = false,
  isVerboseMode = true,
  metaFileDirectory = null,
  reloadOnDowngrade = true,
  onCacheClear,
}) => {
  const [cacheStatus, setCacheStatus] = useState({
    isLatestVersion: true,
  });

  const log = (message, isError) => {
    isVerboseMode && (isError ? console.error(message) : console.log(message));
  };

  useEffect(() => {
    isEnabled ? checkCacheStatus(true) : log('React Cache Buster is disabled.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMetaFileDirectory = () => {
    return !metaFileDirectory || metaFileDirectory === '.'
      ? ''
      : metaFileDirectory;
  };

  const checkCacheStatus = useCallback(
    async (shouldSkipWait = false) => {
      try {
        const request = new Request(`${getMetaFileDirectory()}/meta.json`, {
          cache: 'no-store',
        });
        const res = await fetch(request);
        const { version: metaVersion } = await res.json();

        const shouldForceRefresh = isThereNewVersion(
          metaVersion,
          currentVersion
        );

        if (shouldForceRefresh) {
          if (shouldSkipWait) {
            log(`Forcing refresh for new version (v${metaVersion}).`);
            return setCacheStatus({
              isLatestVersion: false,
            });
          }

          const versionChangeTimestamp = Number(
            localStorage.getItem(localStorageKeys.VERSION_CHANGE) || 0
          );

          if (!versionChangeTimestamp) {
            localStorage.setItem(localStorageKeys.VERSION_CHANGE, Date.now());
            log(
              `There is a new version, refresh will be available in ${
                VERSION_CHANGE_WAIT_TIME / 60 / 1000
              } minutes.`
            );
          } else {
            const timeSinceVersionChange = Math.abs(
              Date.now() - versionChangeTimestamp
            );

            if (timeSinceVersionChange >= VERSION_CHANGE_WAIT_TIME) {
              localStorage.removeItem(localStorageKeys.VERSION_CHANGE);
              log(`Forcing refresh for new version (v${metaVersion}).`);
              setCacheStatus({
                isLatestVersion: false,
              });
            }
          }
        } else {
          log('There is no new version. No cache refresh needed.');
        }
      } catch (error) {
        log('An error occurred while checking cache status.', true);
        log(error, true);

        // Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
        !isVerboseMode &&
          setCacheStatus({
            isLatestVersion: true,
          });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVersion, isVerboseMode, metaFileDirectory]
  );

  const isThereNewVersion = (metaVersion, currentVersion) => {
    if (reloadOnDowngrade) {
      return !compare(metaVersion, currentVersion, '=');
    }
    return compare(metaVersion, currentVersion, '>');
  };

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

        await Promise.all(cacheDeletionPromises);
        log('The cache has been deleted.');

        window.location.reload(true);
      }
    } catch (error) {
      log('An error occurred while deleting the cache.', true);
      log(error, true);
    }
  };

  if (!isEnabled) {
    return children;
  } else {
    if (!cacheStatus.isLatestVersion) {
      if (onCacheClear) {
        onCacheClear(refreshCacheAndReload);
      } else {
        refreshCacheAndReload();
      }
      return null;
    }

    return React.createElement(
      CacheBusterContext.Provider,
      {
        value: { checkCacheStatus },
      },
      children
    );
  }
};

export const useCacheBuster = () => {
  const context = useContext(CacheBusterContext);
  if (context === undefined || context === null) {
    throw new Error(
      'useCacheBuster must be used within a CacheBuster component.'
    );
  }
  return context;
};

export default CacheBuster;
