import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiLinearProgress from '@mui/material/LinearProgress';

const LinearProgress = styled(MuiLinearProgress)`
  height: 5px;
  border-radius: 5px;
  background-color: #ddd;
  & .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export default function LinearLoader({ label }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        width: '100%',
      }}
    >
      <Box
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Box>
      <Box width="100%" mr={1} ml={1}>
        <LinearProgress />
      </Box>
    </Box>
  );
}
