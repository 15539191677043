import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import {
  selectAuthError,
  selectTwoFactorData,
} from '../../store/auth/authSelectors';
import { MAX_LENGTH } from '../../configs/validation';
import useCountdown from '../../lib/hooks/useCountdown';
import Authentication from '../../store/auth/authActions';

import Countdown from '../Countdown';
import UnauthenticatedLayout from '../UnauthenticatedLayout';
import LockIcon from '../../assets/icons/lock-outline.svg?react';

const EmailCodeVerification = ({
  loading,
  setLoading,
  onSubmit,
  onClickCancel,
  submitCountdown,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });
  const dispatch = useDispatch();
  const [resendError, setResendError] = useState('');
  const verificationError = useSelector(selectAuthError);
  const [codeExpiryCountdown, setCodeExpiryCountdown] = useCountdown();
  const [nextAttemptCountdown, setNextAttemptCountdown] = useCountdown();
  const { confirmationCodeExpiryTimestamp } = useSelector(selectTwoFactorData);

  const code = watch('code');
  const validationMessage = 'Enter a valid confirmation code.';
  const { isTicking: isSubmitCountdownTicking } = submitCountdown;
  const { isTicking: isNextAttemptTicking } = nextAttemptCountdown;

  const handleResend = () => {
    setResendError('');
    setLoading(true);
    dispatch(Authentication.resendCode())
      .catch((e) => {
        const { response } = e;
        if (response.status === 429) {
          return setNextAttemptCountdown(response.data.msUntilNextAttempt);
        }
        console.error(e);
        setResendError('An unexpected error occurred.');
      })
      .finally(() => setLoading(false));
  };

  // Confirmation code expiry countdown effect.
  useEffect(() => {
    if (!!confirmationCodeExpiryTimestamp) {
      setCodeExpiryCountdown(confirmationCodeExpiryTimestamp - Date.now());
    }
  }, [confirmationCodeExpiryTimestamp, setCodeExpiryCountdown]);

  return (
    <UnauthenticatedLayout
      title="Enter confirmation code"
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
      buttons={[
        {
          title: 'Cancel',
          onClick: onClickCancel,
        },
        {
          title: 'Resend',
          onClick: handleResend,
          disabled: loading || isNextAttemptTicking,
        },
        {
          type: 'submit',
          title: 'Submit',
          color: 'primary',
          variant: 'contained',
          disabled: loading || !code || isSubmitCountdownTicking,
        },
      ]}
    >
      <Typography>
        Please enter the confirmation code which was sent to your email.
      </Typography>
      <Controller
        name="code"
        control={control}
        rules={{
          required: validationMessage,
          pattern: {
            value: /^[0-9]*$/,
            message: validationMessage,
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            variant="standard"
            disabled={loading}
            autoComplete="off"
            error={!!errors?.code}
            placeholder="Confirmation Code"
            helperText={errors?.code?.message}
            inputProps={{ autoComplete: 'off', maxLength: MAX_LENGTH.value }}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="start">
                  <SvgIcon color="primary" component={LockIcon} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {!isNextAttemptTicking && !isSubmitCountdownTicking && (
        <Countdown
          color="grey"
          values={codeExpiryCountdown}
          templateString="Confirmation code expires in %m minute%mp and %s second%sp..."
        />
      )}
      <Countdown
        color="error"
        values={submitCountdown}
        templateString="Please wait %m minute%mp and %s second%sp before trying to submit again."
      />
      <Countdown color="error" values={nextAttemptCountdown} />
      {!!resendError && <Typography color="error">{resendError}</Typography>}
      {!!verificationError && (
        <Typography color="error">{verificationError}</Typography>
      )}
    </UnauthenticatedLayout>
  );
};

export default EmailCodeVerification;
