export const localStorageKeys = {
  HEARTBEAT: 'lastPollingHeartbeat',
  USERS_LIST: 'usersList',
  INCIDENTS: 'incidents',
  DASHBOARD: 'dashboard',
  CURRENT_USER: 'currentUser',
  REFRESHING_TOKEN: 'isRefreshingToken',
  VERSION_CHANGE: 'versionChange',
};

export const clearLocalStorage = () => {
  // console.log('Clearing application-specific localStorage data...');
  Object.values(localStorageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const scheduleLocalStorageRemoval = (key) => {
  setTimeout(() => {
    localStorage.removeItem(key);
  }, 5000); // 5 seconds
};
