import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import {
  fileSizeIsSupported,
  fileExtensionIsSupported,
} from '../file-viewer/FileViewer';
import { selectCanEditFolderAccess } from '../../store/storage/storageSelectors';

import ViewIcon from '../../assets/icons/eye-outline.svg?react';
import EditIcon from '../../assets/icons/edit-outline.svg?react';
import LockIcon from '../../assets/icons/lock-outline.svg?react';
import InfoIcon from '../../assets/icons/info-outline.svg?react';
import TrashIcon from '../../assets/icons/trash-2-outline.svg?react';
import DownloadIcon from '../../assets/icons/download-outline.svg?react';

const ContextMenu = ({
  xPos,
  yPos,
  item,
  open,
  onClose,
  onView,
  onEditAccess,
  onDownload,
  onRename,
  onPreview,
  onDelete,
}) => {
  const canEditFolderAccess = useSelector(selectCanEditFolderAccess);

  const canEdit = Boolean(item?.isEditable);
  const canView =
    !!onView &&
    !item?.isFolder &&
    fileExtensionIsSupported(item?.extension) &&
    fileSizeIsSupported(item?.extension, item?.size);

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: yPos - 2, left: xPos - 4 }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (open) {
          onClose();
        }
      }}
    >
      {canView && (
        <MenuItem onClick={onView}>
          <ListItemIcon>
            <SvgIcon component={ViewIcon} size="small" />
          </ListItemIcon>
          View File
        </MenuItem>
      )}
      {!!onDownload && !item?.isFolder && (
        <MenuItem onClick={onDownload}>
          <ListItemIcon>
            <SvgIcon component={DownloadIcon} size="small" />
          </ListItemIcon>
          Download
        </MenuItem>
      )}
      {canEdit && canEditFolderAccess && item?.isFolder && (
        <MenuItem onClick={onEditAccess}>
          <ListItemIcon>
            <SvgIcon component={LockIcon} size="small" />
          </ListItemIcon>
          Edit Access
        </MenuItem>
      )}
      {canEdit && (
        <MenuItem onClick={item?.isFolder ? onEditAccess : onRename}>
          <ListItemIcon>
            <SvgIcon component={EditIcon} size="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
      )}
      {!!onPreview && (
        <MenuItem onClick={onPreview}>
          <ListItemIcon>
            <SvgIcon component={InfoIcon} size="small" />
          </ListItemIcon>
          Info
        </MenuItem>
      )}
      {canEdit && (
        <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <SvgIcon component={TrashIcon} size="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      )}
    </Menu>
  );
};

export default ContextMenu;
