import { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Popover from '@mui/material/Popover';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import { getCachedProfileImageUrl } from '../../utils/imageUtils';
import { selectCurrentUserUUID } from '../../store/auth/authSelectors';
import { selectIsMod, selectIsOwner } from '../../store/chat/chatSelectors';

import Avatar from '../Avatar';
import ConfirmModal from '../ConfirmModal';
import Chats from '../../store/chat/chatActions';
import Close from '../../assets/icons/close.svg?react';

const MemberListItem = styled.li`
  cursor: pointer;
  padding: 0.2em 1em;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.palette.secondary.hover};
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  .cancel-icon {
    display: none;
    text-align: right;
  }

  &:hover .cancel-icon {
    display: block;
  }
`;

const PopoverImgWrapper = styled.div`
  display: flex;
  height: 18em;
  width: 18em;
  justify-content: center;
  align-items: center;
`;

export default function GroupMember({ user, isAdmin }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isOwner = useSelector((state) =>
    selectIsOwner(state, { uuid: user.userProperties.uuid })
  );
  const isMod = useSelector((state) =>
    selectIsMod(state, { uuid: user.userProperties.uuid })
  );
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const [anchorEl, setAnchorEl] = useState(null);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [promoteOpen, setPromoteOpen] = useState(false);
  const [demoteOpen, setDemoteOpen] = useState(false);

  const name = `${user.firstName} ${user.lastName}`;
  const isUser = currentUserUUID === user.userProperties.uuid;

  let title = '';

  const promoteUser = useCallback((u) => {
    dispatch(Chats.promoteUser(u)).then(() => setPromoteOpen(false));
  }, []);

  const demoteUser = useCallback((u) => {
    dispatch(Chats.demoteUser(u)).then(() => setDemoteOpen(false));
  }, []);

  const removeUser = useCallback(() => {
    dispatch(Chats.removeUser(user));

    setRemoveOpen(false);
  }, []);

  if (isOwner) {
    title = '(Owner)';
  } else if (isMod) {
    title = '(Admin)';
  } else if (!user.active) {
    title = '(Inactive)';
  } else if (user.userProperties && user.userProperties.type === 'external') {
    title = '(External)';
  } else {
    title = '';
  }

  return (
    <MemberListItem theme={theme}>
      <Grid container>
        <Grid
          style={{ alignItems: 'center' }}
          theme={theme}
          container
          item
          xs={11}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Grid item xs={2}>
            <Avatar
              profileImageUrl={getCachedProfileImageUrl(user)}
              firstName={user.firstName}
              lastName={user.lastName}
              fontSize="0.8rem"
              size="smallest"
            />
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              marginLeft: '5px',
              color: !user.active ? '#ccc' : 'inherit',
            }}
          >
            {`${name} ${title}`}
          </Grid>
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          {isAdmin && !isUser ? (
            <SvgIcon
              onClick={() => setRemoveOpen(true)}
              className="cancel-icon hover"
            >
              <Close />
            </SvgIcon>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardMedia>
            <PopoverImgWrapper>
              <Avatar
                profileImageUrl={getCachedProfileImageUrl(user)}
                firstName={user.firstName}
                lastName={user.lastName}
                fontSize="5rem"
                size="large"
              />
            </PopoverImgWrapper>
          </CardMedia>
          <CardContent>
            <div>{name}</div>
            <div>
              <small>{user.email}</small>
            </div>
          </CardContent>
          <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
            {isAdmin && !isUser && (
              <>
                {user.active &&
                  (!user.isAdmin ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setPromoteOpen(true)}
                      >
                        Make Admin
                      </Button>
                      <ConfirmModal
                        open={promoteOpen}
                        confirmText="Promote"
                        onClose={() => setPromoteOpen(false)}
                        onConfirm={() => promoteUser(user)}
                      >
                        <p>
                          {`Are you sure you want to give ${user.firstName} ${user.lastName} admin?`}
                        </p>
                        <p>
                          The user will have access to admin controls within
                          this breakout room.
                        </p>
                      </ConfirmModal>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setDemoteOpen(true)}
                        disabled={isOwner}
                      >
                        Remove Admin
                      </Button>
                      <ConfirmModal
                        open={demoteOpen}
                        confirmText="Remove"
                        onClose={() => setDemoteOpen(false)}
                        onConfirm={() => demoteUser(user)}
                      >
                        <p>
                          {`Are you sure you want to remove admin from ${user.firstName} ${user.lastName}?`}
                        </p>
                        <p>
                          The user will lose access to admin controls within
                          this breakout room.
                        </p>
                      </ConfirmModal>
                    </>
                  ))}
                <span style={{ margin: '0 0.25em' }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setRemoveOpen(true)}
                  disabled={isOwner}
                >
                  Remove
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </Popover>
      <ConfirmModal
        open={removeOpen}
        confirmText="Remove"
        onClose={() => setRemoveOpen(false)}
        onConfirm={removeUser}
      >
        <p>
          {`Are you sure you want to remove ${user.firstName} ${user.lastName} from this breakout room?`}
        </p>
        <p>
          The user will need to be reinvited by an admin to access this chat.
        </p>
      </ConfirmModal>
    </MemberListItem>
  );
}
