import md5 from 'md5';

import Drafts from './draftActions';
import Messages from './messageActions';
import { CHAT_TYPES } from './chatReducer';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectCurrentUser } from '../auth/authSelectors';
import { selectSDKConnected } from '../sdk/sdkSelectors';
import { caseInsensitiveCompare } from '../../utils/sortUtils';
import { getStreamChatClient } from '../../lib/streamChatClient';
import { selectChatsList, selectCurrentChat } from './chatSelectors';

let attempts = 0;

const Chats = {
  fetchChats: () => async (dispatch, getState) => {
    const client = getStreamChatClient();
    const currentUser = selectCurrentUser(getState());
    const currentChat = selectCurrentChat(getState());

    const limit = 10;

    let offset = 0;
    let page = 0;
    let response = [];
    let chats = [];

    dispatch({ type: `${CHAT_TYPES.FETCH_CHATS}_PENDING` });

    try {
      do {
        response = await client.queryChannels(
          {
            members: { $in: [currentUser.uuid] },
          },
          { last_message_at: -1 },
          { limit, offset, watch: true }
        );

        chats = [...chats, ...response];
        page += 1;
        offset = page * limit;
      } while (response.length >= limit);
    } catch (error) {
      console.log(error, error.code);

      if (error.code === 'ECONNABORTED' && attempts === 0) {
        attempts = 1;

        return dispatch(Chats.fetchChats());
      }

      return dispatch({
        type: `${CHAT_TYPES.FETCH_CHATS}_REJECTED`,
        payload: error,
      });
    }

    attempts = 0;

    if (currentChat && Object.keys(currentChat).length !== 0) {
      dispatch(Messages.fetchInitMessages(currentChat));
    }

    return dispatch({
      type: `${CHAT_TYPES.FETCH_CHATS}_FULFILLED`,
      payload: chats,
    });
  },
  fetchAttachments: (cid) => (dispatch) => {
    const client = getStreamChatClient();
    const channelFilters = { cid };
    const messageFilters = { attachments: { $exists: true } };

    return dispatch({
      type: CHAT_TYPES.FETCH_ATTACHMENTS,
      payload: client.search(channelFilters, messageFilters),
    });
  },
  refreshChats: () => (dispatch, getState) => {
    const client = getStreamChatClient();
    const currentUser = selectCurrentUser(getState());

    return dispatch({
      type: CHAT_TYPES.REFRESH_CHATS,
      payload: client.queryChannels(
        {
          members: { $in: [currentUser.uuid] },
        },
        { last_message_at: -1 },
        { limit: 30 } // needs pagination later on
      ),
    });
  },
  createDirectChat: (otherUsers) => async (dispatch, getState) => {
    const client = getStreamChatClient();
    const currentUser = selectCurrentUser(getState());
    const chatList = selectChatsList(getState());

    const members = [...otherUsers, currentUser.uuid];
    const channelId = members.sort(caseInsensitiveCompare).join('');
    const newChannel = client.channel(
      'messaging',
      `members-${md5(channelId)}`,
      { members }
    );
    const alreadyExists =
      chatList.findIndex((c) => c.cid === newChannel.cid) !== -1;

    try {
      await newChannel.watch();

      if (!alreadyExists) {
        if (!newChannel.state.last_message_at) {
          // last_message_at is set to null if the chat is new; therefore
          // it's net new, so add it.
          dispatch({
            type: CHAT_TYPES.NEW_CHAT,
            payload: newChannel,
          });
        } else {
          // Otherwise, show it. That will take care of adding it for us.
          newChannel.show();
        }
      }
    } catch (e) {
      console.log(e.message);
      throw e;
    }

    return dispatch({
      type: CHAT_TYPES.SET_CURRENT_CHAT,
      payload: newChannel,
    });
  },
  createGroupChat:
    (name, otherUsers = [], isWarRoom = false, description) =>
    async (dispatch, getState) => {
      const client = getStreamChatClient();
      const currentUser = selectCurrentUser(getState());
      const chatList = selectChatsList(getState());

      const chatOfSameName = chatList
        .filter((c) => c.type === 'team')
        .find(
          (c) =>
            c?.data?.name?.trim()?.toLowerCase() === name.trim().toLowerCase()
        );

      if (!!chatOfSameName) {
        return dispatch({
          type: CHAT_TYPES.SET_CURRENT_CHAT,
          payload: chatOfSameName,
        });
      }

      const members = [...otherUsers, currentUser.uuid];
      const newChannel = client.channel('team', md5(name.toLowerCase()), {
        members,
        name,
        isWarRoom,
        description,
      });
      await newChannel.create();
      const alreadyExistsInChatList =
        chatList.findIndex((c) => c.cid === newChannel.cid) !== -1;
      const alreadyExists = !!newChannel.data.last_message_at;
      const alreadyIsMember = Object.keys(newChannel.state.members).includes(
        currentUser.uuid
      );

      if (!alreadyExistsInChatList && alreadyExists && !alreadyIsMember) {
        return Promise.reject(
          `A ${
            isWarRoom ? 'War Room' : 'Breakout Room'
          } with that name already exists.`
        );
      }

      try {
        await newChannel.watch();

        if (!alreadyExistsInChatList && !alreadyExists) {
          // last_message_at is set to null if the chat is new; therefore
          // it's net new, so add it.
          dispatch({
            type: CHAT_TYPES.NEW_CHAT,
            payload: newChannel,
          });
        }
      } catch (e) {
        console.error(e);
        return Promise.reject(
          `A ${
            isWarRoom ? 'War Room' : 'Breakout Room'
          } with that name already exists.`
        );
      }

      return dispatch({
        type: CHAT_TYPES.SET_CURRENT_CHAT,
        payload: newChannel,
      });
    },
  addChat: (channel) => ({
    type: CHAT_TYPES.ADD_CHAT,
    payload: channel,
  }),
  removeChat: (cid) => (dispatch) => {
    // Clear any draft for this chat.
    dispatch(Drafts.delete(cid));

    return dispatch({
      type: CHAT_TYPES.REMOVE_CHAT,
      payload: cid,
    });
  },
  updateChat: (channel) => (dispatch, getState) => {
    const chatList = selectChatsList(getState());

    const exists = chatList.findIndex((c) => channel.cid === c.cid) !== -1;

    if (!exists) {
      dispatch({
        type: CHAT_TYPES.ADD_CHAT,
        payload: channel,
      });
    } else {
      dispatch({
        type: CHAT_TYPES.UPDATE_CHAT,
        payload: channel,
      });
    }
  },
  updateMembers: (members) => ({
    type: CHAT_TYPES.UPDATE_MEMBERS,
    payload: members,
  }),
  promoteUser: (user) => (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());
    const { uuid } = user.userProperties;

    return dispatch({
      type: CHAT_TYPES.PROMOTE_USER,
      payload: axiosInstance.post('/stream/addModerator', {
        channelId: currentChat.data.id,
        moderatorIds: [uuid],
      }),
    });
  },
  demoteUser: (user) => (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());
    const { uuid } = user.userProperties;

    return dispatch({
      type: CHAT_TYPES.DEMOTE_USER,
      payload: axiosInstance.post('/stream/demoteModerator', {
        channelId: currentChat.data.id,
        moderatorIds: [uuid],
      }),
    });
  },
  setCurrentChat: (type, id) => async (dispatch, getState) => {
    const client = getStreamChatClient();
    const sdkConnected = selectSDKConnected(getState());
    const channel = client.getChannelById(type, id, {});

    dispatch({
      type: CHAT_TYPES.SET_CURRENT_CHAT,
      payload: channel,
    });

    if (channel.state.unreadCount > 0 && sdkConnected) {
      await channel.markRead();
    }

    // Fetch an initial 100 messages. The channel state caches
    // messages already fetched. Therefore, in the message reducer,
    // simply load the cached messages instead of making another
    // fetch call.
    if (channel.state.messages.length < 100) {
      dispatch(Messages.fetchInitMessages(channel));
    }
  },
  addUsers: (users) => (dispatch, getState) => {
    const { currentChat } = getState().chats;

    return dispatch({
      type: CHAT_TYPES.ADD_USERS,
      payload: currentChat.addMembers(users),
    });
  },
  removeUser: (user) => (dispatch, getState) => {
    const { currentChat } = getState().chats;

    return dispatch({
      type: CHAT_TYPES.REMOVE_USER,
      payload: currentChat.removeMembers([user.userProperties.uuid]),
    });
  },
  leaveGroup: () => (dispatch, getState) => {
    const { currentChat } = getState().chats;
    const currentUser = selectCurrentUser(getState());

    // Clear any draft for this chat.
    dispatch(Drafts.delete(currentChat.cid));

    return dispatch({
      type: CHAT_TYPES.REMOVE_USER,
      payload: currentChat.removeMembers([currentUser.uuid]).then(() => {
        if (Object.keys(currentChat.state.members).length <= 1) {
          currentChat.delete();
        } else {
          dispatch({
            type: CHAT_TYPES.REMOVE_CHAT,
            payload: currentChat.cid,
          });
        }
      }),
    });
  },
  setCurrentTop: (top) => ({
    type: CHAT_TYPES.SET_CURRENT_TOP,
    payload: top,
  }),
  startTyping: (cid, typing) => (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    const notCurrentUser = Object.keys(typing).filter(
      (u) => u !== currentUser.uuid
    );

    return dispatch({
      type: CHAT_TYPES.START_TYPING,
      payload: { cid, typing: notCurrentUser },
    });
  },
  stopTyping: (cid, typing) => (dispatch, getState) => {
    const currentUser = selectCurrentUser(getState());

    const notCurrentUser = Object.keys(typing).filter(
      (u) => u !== currentUser.uuid
    );

    return dispatch({
      type: CHAT_TYPES.STOP_TYPING,
      payload: { cid, typing: notCurrentUser },
    });
  },
  addAttachment: (attachment) => ({
    type: CHAT_TYPES.ADD_ATTACHEMENT,
    payload: attachment,
  }),
  exportChat: (startDate, endDate) => async (dispatch, getState) => {
    const currentChat = selectCurrentChat(getState());

    return dispatch({
      type: CHAT_TYPES.EXPORT,
      payload: axiosInstance.post('/stream/exportChannel', {
        id: currentChat.id,
        type: currentChat.type,
        messagesSince: startDate,
        messagesUntil: endDate,
      }),
    });
  },
  clearExportData: () => ({ type: CHAT_TYPES.CLEAR_EXPORT_DATA }),
  setError: (error) => ({
    type: CHAT_TYPES.ERROR,
    payload: error,
  }),
  toggleNewChatForm: (chatType) => ({
    type: CHAT_TYPES.TOGGLE_NEW_CHAT_FORM,
    payload: chatType,
  }),
  readReceiptList: (user) => (dispatch) => {
    dispatch({
      type: CHAT_TYPES.READ_RECEIPT_LIST,
      payload: user,
    });
  },
  removeReceiptList: () => (dispatch) => {
    dispatch({
      type: CHAT_TYPES.REMOVE_RECEIPT_LIST,
    });
  },
};

export default Chats;
