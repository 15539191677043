import axios from 'axios';

import { store } from '../store';
import ManagementConsole from '../store/management/managementActions';

const managementInterceptors = {
  request: null,
  response: null,
};

export const managementApi = axios.create({
  headers: {
    common: {
      'Content-Type': 'application/json',
      'X-App-Version': window._env_.VITE_APP_VERSION,
    },
  },
});

// Notice that management has been added to the path for convenience.
export const updateManagementApiConfig = (baseURL, authToken) => {
  if (baseURL) {
    managementApi.defaults.baseURL = baseURL + 'management';
  }

  if (authToken) {
    managementApi.defaults.headers.common['Authorization'] =
      `Bearer ${authToken}`;
  }
};

export const resetManagementApiConfig = () => {
  // console.log('Resetting Axios config...');
  delete managementApi.defaults.baseURL;
  delete managementApi.defaults.headers.common['Authorization'];
};

export const setupManagementApiInterceptors = () => {
  // console.log('Setup Axios interceptors.');

  managementInterceptors.response = managementApi.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      const status = response?.status;

      // If the request is bad, then throw the error, as we want to surface it.
      // If the response is a 500-level, this could be a bad request, or perhaps a general server issue.
      if ([400, 404, 500].includes(status) || status >= 500) {
        console.error('Bad request.');
        throw error;
      }

      // If the response is unauthorized, the token has expired, so log the user out.
      if ([401, 403].includes(status)) {
        console.error('Token has expired.');
        store.dispatch(ManagementConsole.logout());
      }

      return Promise.reject(error);
    }
  );
};

export const ejectManagementApiInterceptors = () => {
  if (managementInterceptors.response !== null) {
    // console.log('Eject Axios interceptors.');
    managementApi.interceptors.response.eject(managementInterceptors.response);
    managementInterceptors.response = null;
  }
};
