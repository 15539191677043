import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DataGridFilter from '../DataGridFilter';

import RefreshIcon from '../../assets/icons/refresh.svg?react';

const ChannelTableToolbar = ({
  filterInputProps = {},
  refreshButtonProps = {},
  showButtons = false,
  buttons = [],
}) => {
  if (buttons.some((b) => !b.title || !b.onClick)) {
    console.error(
      'ChannelTableToolbar: All buttons must have title and onClick properties.'
    );
    return <Typography color="error">Check console.</Typography>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{ mb: 0.5 }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <DataGridFilter {...filterInputProps} />
        <Tooltip
          arrow
          placement="right"
          disableInteractive
          title={
            refreshButtonProps?.disabled
              ? 'Please wait before refreshing again.'
              : ''
          }
        >
          <span>
            <Button
              {...refreshButtonProps}
              size="small"
              startIcon={<SvgIcon component={RefreshIcon} />}
            >
              Refresh
            </Button>
          </span>
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        {showButtons &&
          !!buttons.length &&
          buttons.map((b) => (
            <Button
              size="small"
              key={b.title}
              onClick={b.onClick}
              variant="contained"
              color={b?.color || 'primary'}
            >
              {b.title}
            </Button>
          ))}
      </Stack>
    </Stack>
  );
};

export default ChannelTableToolbar;
