import { useMemo } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Dropdown = ({ options = [], ...otherProps }) => {
  const items = useMemo(() =>
    options.map(
      (o) => (
        <MenuItem key={o.value} value={o.value || o}>
          {o.label || o}
        </MenuItem>
      ),
      [options]
    )
  );
  return <Select {...otherProps}>{items}</Select>;
};

export default Dropdown;
