import { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import VpnKey from '@mui/icons-material/VpnKey';
import IconButton from '@mui/material/IconButton';
import GlobalStyles from '@mui/material/GlobalStyles';
import EmailOutlined from '@mui/icons-material/EmailOutlined';

import { capitalize } from '../../utils/stringUtils';
import { formatPhoneNumber } from '../../utils/formUtils';

import UserAdminToolbar from './UserAdminToolbar';
import LockOutlined from '../../assets/icons/lock-refresh.svg?react';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const UserAdminDataGrid = ({
  rows,
  loading,
  selection,
  setSelection,
  onAdd,
  onEdit,
  onImport,
  onDeactivate,
  onReactivate,
  onResetPassword,
  onSendActivation,
  onResetTOTPEnrolment,
  isTOTPEnabled = false,
  toolbarSx = {},
  sx = {},
}) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'firstName',
      sort: 'asc',
    },
  ]);
  const [contextMenu, setContextMenu] = useState(null);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);

  const currentRows = showInactiveUsers ? rows : rows.filter((u) => u.active);
  const selectedUser = rows.find((u) => u.id === selection?.[0]);

  const handleDoubleClickRow = ({ id }) => onEdit(undefined, id);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setSelection([e.currentTarget.getAttribute('data-id')]);
    setContextMenu(!contextMenu ? { left: e.clientX, top: e.clientY } : null);
  };

  const handleCloseContextMenu = () => setContextMenu(null);

  const handleClickContextMenuItem = (callback) => (e) => {
    e.stopPropagation();
    handleCloseContextMenu();
    callback();
  };

  const handleClickAction = (callback) => (e) => {
    e.stopPropagation();
    callback();
  };

  return (
    <Container>
      <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
      <DataGrid
        rows={currentRows}
        columns={[
          {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
          },
          {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1.75,
          },
          {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            flex: 1.15,
            valueFormatter: ({ value }) =>
              !!value ? formatPhoneNumber(value) : '',
          },
          {
            field: 'role',
            headerName: 'Role',
            flex: 0.65,
            valueFormatter: ({ value }) => capitalize(value),
          },
          {
            field: 'type',
            headerName: 'Type',
            flex: 0.75,
            valueFormatter: ({ value }) => capitalize(value),
          },
          {
            field: 'location',
            headerName: 'Location',
            flex: 1,
          },
          {
            field: 'department',
            headerName: 'Department',
            flex: 1,
          },
          {
            field: 'active',
            headerName: 'Active',
            flex: 0.65,
            type: 'boolean',
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: isTOTPEnabled ? 1.15 : 0.75,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <Tooltip
                  arrow
                  disableInteractive
                  title="Send Password Reset Email"
                >
                  <IconButton
                    color="primary"
                    onClick={handleClickAction(() => onResetPassword(row))}
                  >
                    <VpnKey fontSize="small" />
                  </IconButton>
                </Tooltip>
                {isTOTPEnabled && (
                  <Tooltip
                    arrow
                    disableInteractive
                    title="Reset TOTP Enrolment"
                  >
                    <IconButton
                      color="primary"
                      onClick={handleClickAction(() =>
                        onResetTOTPEnrolment(row)
                      )}
                    >
                      <LockOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip arrow disableInteractive title="Send Activation Email">
                  <IconButton
                    color="primary"
                    onClick={handleClickAction(() => onSendActivation(row))}
                  >
                    <EmailOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
        ]}
        autoPageSize
        rowHeight={42}
        headerHeight={48}
        disableColumnMenu
        loading={loading}
        sortModel={sortModel}
        selectionModel={selection}
        disableMultipleColumnsSorting
        onRowDoubleClick={handleDoubleClickRow}
        onSortModelChange={(model) => setSortModel(model)}
        onSelectionModelChange={(model) =>
          setSelection(model?.[0] !== selection?.[0] ? model : [])
        }
        components={{ Toolbar: UserAdminToolbar }}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
          },
          toolbar: {
            selection: selectedUser,
            onClickAdd: onAdd,
            onClickEdit: onEdit,
            onClickImport: onImport,
            onClickDeactivate: onDeactivate,
            onClickReactivate: onReactivate,
            showInactiveUsers,
            onToggleShowInactiveUsers: () =>
              setShowInactiveUsers(!showInactiveUsers),
            sx: toolbarSx,
          },
        }}
        localeText={{
          noRowsLabel: 'No users created yet.',
          footerRowSelected: (count) =>
            `${count.toLocaleString()} user${count === 1 ? '' : 's'} selected`,
        }}
        sx={{
          border: 'none',
          ...sx,
        }}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? contextMenu : undefined}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleCloseContextMenu();
            },
          },
        }}
      >
        <MenuItem onClick={handleClickContextMenuItem(onEdit)}>
          Edit User
        </MenuItem>
        <MenuItem onClick={handleClickContextMenuItem(onResetPassword)}>
          Send Password Reset Email
        </MenuItem>
        <MenuItem onClick={handleClickContextMenuItem(onSendActivation)}>
          Send Activation Email
        </MenuItem>
        {isTOTPEnabled && (
          <MenuItem onClick={handleClickContextMenuItem(onResetTOTPEnrolment)}>
            Reset TOTP Enrolment
          </MenuItem>
        )}
      </Menu>
    </Container>
  );
};

export default UserAdminDataGrid;
