import { createSelector } from 'reselect';

const getState = (state) => state.sdk;

export const selectIsInitialized = createSelector(
  getState,
  (state) => state.isInitialized
);

export const selectSDKConnected = createSelector(
  getState,
  (state) => state.isConnected
);
