import axios from 'axios';

import {
  selectCurrentUser,
  selectRefreshToken,
  selectTwoFactorData,
} from './authSelectors';
import { AUTH_TYPES } from './authReducer';
import { logBehavior } from '../../lib/log';
import { wait } from '../../utils/asyncUtils';
import { axiosInstance } from '../../lib/axiosInstance';
import { selectIsActiveTab } from '../heartbeat/heartbeatSelectors';
import { selectOrgCode, selectRegistry } from '../org/orgSelectors';

const Authentication = {
  login: (email, password) => async (dispatch, getState) => {
    const enterpriseCode = selectOrgCode(getState());

    return dispatch({
      type: AUTH_TYPES.LOGIN,
      payload: axiosInstance.post('/authentication/upass', {
        email,
        password,
        enterpriseCode,
      }),
    });
  },
  cancelLogin: () => ({
    type: AUTH_TYPES.CANCEL_LOGIN,
  }),
  confirm2FA: (confirmationCode) => (dispatch, getState) => {
    const { initialized, temporaryAuthenticationToken } =
      selectTwoFactorData(getState());

    const confirmationType =
      initialized === false ? '?initConfirmation=true' : '';

    return dispatch({
      type: AUTH_TYPES.VALIDATE_2FA,
      payload: axiosInstance.post(
        `/authentication/confirm${confirmationType}`,
        {
          confirmationCode,
          temporaryAuthenticationToken,
        }
      ),
    });
  },
  resendCode: () => (dispatch, getState) => {
    const { temporaryAuthenticationToken } = selectTwoFactorData(getState());

    return dispatch({
      type: AUTH_TYPES.RESEND_CODE,
      payload: axiosInstance.post('/authentication/resend', {
        temporaryAuthenticationToken,
      }),
    });
  },
  clear: () => ({
    type: AUTH_TYPES.CLEAR,
  }),
  logout:
    (forceLogoutRequest = false) =>
    (dispatch, getState) => {
      const isActiveTab = selectIsActiveTab(getState());

      if (isActiveTab || forceLogoutRequest) {
        axiosInstance
          .post('/authentication/logout', undefined)
          .then(() => console.log('Signed out of session.'))
          .catch((error) =>
            console.error('Failed to logout on server:', error)
          );
      }

      // Update the Redux state to reflect the logout.
      dispatch(Authentication.clear());
    },
  get2FAQRCode: () => async (dispatch, getState) => {
    const { temporaryAuthenticationToken } = selectTwoFactorData(getState());

    dispatch({
      type: AUTH_TYPES.SET_QR_CODE_IMAGE,
      payload: axiosInstance.post('/authentication/initQR', {
        temporaryAuthenticationToken,
      }),
    });
  },
  refreshToken:
    ({ source }) =>
    (dispatch, getState) => {
      const state = getState();
      const registry = selectRegistry(state);
      const refreshToken = selectRefreshToken(state);

      if (!refreshToken) {
        console.info('@@SHQ/Token - Cannot refresh.');
        return Promise.reject('Cannot refresh.');
      }

      console.info(`@@SHQ/Token - Refreshing now from ${source}...`);

      const attemptRefresh = (action, delay, retries) =>
        new Promise((resolve, reject) => {
          return action()
            .then(resolve)
            .catch((error) => {
              // const containsHarmlessCode = [
              //   'ECONNABORTED',
              //   'ERR_NETWORK',
              //   'ERR_NETWORK_CHANGED',
              // ].includes(error.code);
              // const isCancelled = axios.isCancel(error);
              // const isMissingStatus =
              //   !error.response || !error.response?.status;
              const isNotUnauthorized = ![400, 401, 403].includes(
                error?.response?.status
              );

              if (retries > 0 && isNotUnauthorized) {
                console.warn(
                  `${source} - Refresh failed, will try again. - ${error.message}.`
                );
                return wait(delay)
                  .then(attemptRefresh.bind(null, action, delay, retries - 1))
                  .then(resolve)
                  .catch(reject);
              }

              logBehavior(
                `${source} - Refresh failed, logging out. - ${error.message}.`,
                'fatal'
              );
              dispatch(Authentication.logout());
              return reject(error);
            });
        });

      return dispatch({
        type: AUTH_TYPES.REFRESH_AUTH_TOKEN,
        payload: attemptRefresh(
          () =>
            axios.post(
              '/authentication/refreshToken',
              {
                refreshToken,
              },
              {
                baseURL: registry.apiUrl,
                headers: {
                  'Content-Type': 'application/json',
                  'X-Enterprise-Code': registry.code,
                },
              }
            ),
          3000,
          1
        ),
      });
    },
  setToken: ({ authenticationToken, authenticationTokenExpiry }) => ({
    type: AUTH_TYPES.SET_TOKEN,
    payload: { authenticationToken, authenticationTokenExpiry },
  }),
  sendResetEmail: (userId) => (dispatch, getState) => {
    const enterpriseCode = selectOrgCode(getState());

    return dispatch({
      type: AUTH_TYPES.SEND_RESET_EMAIL,
      payload: axiosInstance.post('/authentication/forgotPassword', {
        enterpriseCode,
        userId,
      }),
    });
  },
  changePassword: (newPassword) => (dispatch, getState) => {
    const state = getState();
    const enterpriseCode = selectOrgCode(state);
    const currentUser = selectCurrentUser(state);

    return dispatch({
      type: AUTH_TYPES.CHANGE_PASSWORD,
      payload: axiosInstance.post('/authentication/changePassword', {
        userId: currentUser.user.userProperties.userId,
        enterpriseCode,
        newPassword,
      }),
    });
  },
  changePasswordOutside: (urlParams, newPassword) => (dispatch) => {
    const enterpriseCode = urlParams.enterpriseCode;
    const baseURL = urlParams.baseURL;

    const body = {
      newPassword: newPassword,
      enterpriseCode: urlParams.enterpriseCode,
      userId: urlParams.userId,
      oneTimePasscode: urlParams.oneTimePasscode,
    };

    return dispatch({
      type: AUTH_TYPES.CHANGE_PASSWORD,
      payload: axios.post('/authentication/changePassword', body, {
        baseURL,
        headers: {
          'Content-Type': 'application/json',
          'X-Enterprise-Code': enterpriseCode,
          'X-App-Version': window._env_.VITE_APP_VERSION,
        },
      }),
    });
  },
  updateUserProperties: () => ({
    type: AUTH_TYPES.UPDATE_CURRENT_USER_PROPERTIES,
    payload: axiosInstance.get('/me/v2'),
  }),
};

export default Authentication;
