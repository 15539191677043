import axios from 'axios';
import { styled } from '@mui/material/styles';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  background-color: white;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;

  .doc-container {
    width: 80%;
    height: 100%;
    padding: 3rem;
    margin: 0 auto;
    overflow-y: auto;
    overflow-wrap: break-word;
    background-color: white;
    box-shadow: 0px 2px 1rem rgba(0, 0, 0, 0.4);
  }
`;

const TXTRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: `<div class='doc-container'>${currentDocument.fileData}</div>`,
      }}
    />
  );
};

TXTRenderer.fileLoader = ({ documentURI, fileLoaderComplete }) => {
  axios.get(documentURI, { responseType: 'text' }).then(({ data }) => {
    fileLoaderComplete({ result: data });
  });
};
TXTRenderer.fileTypes = ['txt', 'text/plain'];
TXTRenderer.weight = 1;

export default TXTRenderer;
