import { styled } from '@mui/material/styles';

const SuggestionBox = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 28px;
  left: ${({ offset }) => offset || 0}px;
  display: ${({ open }) => !open && 'none'};
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 20em;
  overflow-y: auto;
`;

const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 25px;
  max-height: 200px;
  overflow: auto;
`;

const SuggestionListItem = styled.li`
  cursor: pointer;
  padding: 2.5px 5px;
  background: ${({ active }) => active && '#5383ff'};
  color: ${({ active }) => active && 'white'};

  :hover {
    background: #5383ff;
    color: white;
  }
`;

export default function Suggestions({
  open,
  list,
  offset,
  cursor,
  handeleMention,
}) {
  return (
    <SuggestionBox tabIndex="-1" open={open} offset={offset}>
      <SuggestionList>
        {list.map((s, i) => (
          <SuggestionListItem
            key={s.id}
            active={i === cursor}
            onClick={() => handeleMention(i)}
          >
            {s.name}
          </SuggestionListItem>
        ))}
      </SuggestionList>
    </SuggestionBox>
  );
}
