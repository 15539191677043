import { GROUP_TYPES } from './groupReducer';
import { axiosInstance } from '../../lib/axiosInstance';

const Groups = {
  fetchAll: () => ({
    type: GROUP_TYPES.FETCH_ALL,
    payload: axiosInstance.get('/group/all'), // List of all groups in an anonymized form.
  }),
  fetchMemberOf: () => ({
    type: GROUP_TYPES.FETCH_MEMBER_OF,
    payload: axiosInstance.get('/group/me'), // List of groups of which the current user is a member.
  }),
  fetchAccessible: () => ({
    type: GROUP_TYPES.FETCH_ACCESSIBLE,
    payload: axiosInstance.get('/group'), // List of groups of which the current user can access.
  }),
};

export default Groups;
