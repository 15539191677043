import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default function ChatActionButton({
  disabled = false,
  tooltip,
  icon,
  action,
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <IconButton onClick={action} disabled={disabled} size="large">
          <SvgIcon style={{ width: '2rem', height: '2rem' }}>{icon}</SvgIcon>
        </IconButton>
      </span>
    </Tooltip>
  );
}
