import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { formatTimestamp, getElapsedTimeSinceTimestamp } from '../../lib/date';

const ElapsedTime = ({
  timestamp,
  showTimestamp,
  tooltipPlacement = 'right',
  ...props
}) => {
  return showTimestamp ? (
    <Typography component="time">{formatTimestamp(timestamp)}</Typography>
  ) : (
    <Tooltip
      arrow
      disableInteractive
      placement={tooltipPlacement}
      title={formatTimestamp(timestamp)}
    >
      <Typography {...props} component="time">
        {getElapsedTimeSinceTimestamp(timestamp, true)}
      </Typography>
    </Tooltip>
  );
};

export default ElapsedTime;
