import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

const Container = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
`;

const NameContainer = styled.div`
  flex-grow: 1;
  gap: 1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
`;

const ToolbarButtons = styled.div`
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const ToolbarIconButton = styled(IconButton)`
  height: 100%;
  border-radius: 0;
  border-left: 1px solid #ddd;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 0 1rem;
  overflow: auto;

  p {
    margin: 0;
  }
`;

const ContentContainer = styled(Stack)`
  width: 75%;
  margin: 1.5rem auto;
`;

const ToolbarButton = ({ icon, tooltip, onClick }) => {
  return (
    <Tooltip arrow title={tooltip} disableInteractive>
      <span style={{ height: '100%' }}>
        <ToolbarIconButton size="large" onClick={onClick}>
          <SvgIcon style={{ width: '2rem', height: '2rem' }}>{icon}</SvgIcon>
        </ToolbarIconButton>
      </span>
    </Tooltip>
  );
};

const PlaybookLayout = ({
  nameComponent,
  nameHelperComponent,
  toolbarButtons = [],
  descriptionHelperComponent,
  descriptionComponent,
  itemsHelperComponent,
  itemsComponent,
}) => {
  return (
    <Container>
      <Toolbar>
        <NameContainer>
          {nameComponent}
          {nameHelperComponent}
        </NameContainer>
        <ToolbarButtons>
          {toolbarButtons
            .filter((tb) => !tb?.hide)
            .map((tb) => (
              <ToolbarButton
                key={tb.tooltip}
                icon={tb.icon}
                tooltip={tb.tooltip}
                onClick={tb.onClick}
              />
            ))}
        </ToolbarButtons>
      </Toolbar>
      <Content>
        <ContentContainer gap={2.5}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" component="h2">
              Contents
            </Typography>
            {itemsHelperComponent}
          </Stack>
          {itemsComponent}
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" component="h2">
              Incident Brief
            </Typography>
            {descriptionHelperComponent}
          </Stack>
          {descriptionComponent}
        </ContentContainer>
      </Content>
    </Container>
  );
};

export default PlaybookLayout;
