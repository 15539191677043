import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileInputButton = ({ children, onChange, inputProps, ...props }) => (
  <Button {...props} tabIndex={-1} role={undefined} component="label">
    {children}
    <VisuallyHiddenInput type="file" onChange={onChange} {...inputProps} />
  </Button>
);

export default FileInputButton;
