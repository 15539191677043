import Box from '@mui/material/Box';
import MuiBadge from '@mui/material/Badge';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as Link, useLocation } from 'react-router-dom';

import {
  selectCurrentChat,
  selectUnreadCounts,
} from '../store/chat/chatSelectors';
import Storage from '../store/storage/storageActions';
import { promptForNotifications } from '../utils/browserUtils';
import { selectVerified } from '../store/storage/storageSelectors';
import { PAGES, ROLES, DEPENDENT_FEATURES } from '../configs';
import { selectHasIncident } from '../store/dashboard/dashboardSelectors';

import Access from './Access';
import ExpirableBadge from './ExpirableBadge';
import Brandmark from '../assets/images/brandmark.svg';
import DashIcon from '../assets/icons/icHome.svg?react';
import PlaybookIcon from '../assets/icons/book.svg?react';
import MessageIcon from '../assets/icons/icMessage.svg?react';
import NewsIcon from '../assets/icons/inbox-outline.svg?react';
import DocIcon from '../assets/icons/archive-outline.svg?react';
import AdminIcon from '../assets/icons/administration.svg?react';

const Container = styled(Box)`
  width: 95px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: relative;
  padding: 1rem;
`;

const LogoWrapper = styled(Box)`
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.white.main};
`;

const Logo = styled.img`
  width: auto;
  height: 35px;
`;

const SideBarMenuList = styled.ul`
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 50px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
`;

const IconButton = styled(MuiIconButton)`
  background-color: ${({ active, theme }) =>
    active === 'true' ? theme.palette.primary.main : 'transparent'};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.white.main};
    opacity: ${({ active }) => (active === 'true' ? 1 : 0.5)};
  }

  &&:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    min-width: 14px;
    height: 14px;
  }
`;

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const verified = useSelector(selectVerified);
  const currentChat = useSelector(selectCurrentChat);
  const hasIncident = useSelector(selectHasIncident);
  const unreadCounts = useSelector(selectUnreadCounts);

  const hasUnread = Object.keys(unreadCounts).length > 0;
  const path = location.pathname;

  const handleClickChat = () => {
    promptForNotifications();

    if (
      Object.keys(currentChat).length !== 0 &&
      currentChat.state.unreadCount > 0
    ) {
      currentChat.markRead();
    }
  };

  const handleClickFileStorage = (e) => {
    promptForNotifications();

    if (!verified) {
      e.preventDefault();
      dispatch(Storage.requestVerification());
    }
  };

  return (
    <Container>
      <LogoWrapper>
        <Logo src={Brandmark} alt="ShadowHQ" />
      </LogoWrapper>
      <SideBarMenuList>
        <li>
          <Tooltip arrow disableInteractive placement="right" title="Dashboard">
            <IconButton
              size="large"
              color="white"
              component={Link}
              onClick={promptForNotifications}
              active={(path === PAGES.dashboard.pathname).toString()}
              to={{
                pathname: PAGES.dashboard.pathname,
                state: { fromSideMenu: true },
              }}
            >
              <Badge color="error" badgeContent=" " invisible={!hasIncident}>
                <SvgIcon>
                  <DashIcon />
                </SvgIcon>
              </Badge>
            </IconButton>
          </Tooltip>
        </li>
        <li>
          <Tooltip arrow disableInteractive placement="right" title="Incidents">
            <IconButton
              size="large"
              color="white"
              component={Link}
              onClick={promptForNotifications}
              active={path.includes(PAGES.incidents.pathname).toString()}
              to={PAGES.incidents.pathname}
            >
              <SvgIcon>
                <NewsIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </li>
        <Access dependentFeature={DEPENDENT_FEATURES.playbooks}>
          <li>
            <ExpirableBadge name="playbookTemplate">
              <Tooltip
                arrow
                disableInteractive
                placement="right"
                title="Playbooks"
              >
                <IconButton
                  size="large"
                  color="white"
                  component={Link}
                  onClick={promptForNotifications}
                  active={(path === PAGES.playbooks.pathname).toString()}
                  to={PAGES.playbooks.pathname}
                  sx={{ width: '48px', height: '48px' }}
                >
                  <SvgIcon sx={{ width: '22px', height: '22px' }}>
                    <PlaybookIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </ExpirableBadge>
          </li>
        </Access>
        <li>
          <Tooltip arrow disableInteractive placement="right" title="Messaging">
            <IconButton
              size="large"
              color="white"
              component={Link}
              onClick={handleClickChat}
              active={(path === PAGES.chat.pathname).toString()}
              to={PAGES.chat.pathname}
              sx={{ width: '48px', height: '48px' }}
            >
              <Badge color="error" badgeContent=" " invisible={!hasUnread}>
                <SvgIcon>
                  <MessageIcon />
                </SvgIcon>
              </Badge>
            </IconButton>
          </Tooltip>
        </li>
        <li>
          <Tooltip arrow disableInteractive placement="right" title="Files">
            <IconButton
              size="large"
              color="white"
              component={Link}
              active={path.includes(PAGES.fileStorage.pathname).toString()}
              to={{
                pathname: PAGES.fileStorage.pathname,
                state: { fromSideMenu: true },
              }}
              onClick={handleClickFileStorage}
              sx={{ width: '48px', height: '48px' }}
            >
              <SvgIcon sx={{ width: '20px', height: '20px' }}>
                <DocIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </li>
        <Access roles={[ROLES.admin]}>
          <li>
            <Tooltip
              arrow
              disableInteractive
              placement="right"
              title="Administration"
            >
              <IconButton
                size="large"
                color="white"
                component={Link}
                onClick={promptForNotifications}
                active={path.includes(PAGES.userAdmin.pathname).toString()}
                to={{
                  pathname: PAGES.userAdmin.pathname,
                  state: { fromSideMenu: true },
                }}
                sx={{ width: '48px', height: '48px' }}
              >
                <SvgIcon>
                  <AdminIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </li>
        </Access>
      </SideBarMenuList>
    </Container>
  );
}
