import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import useToast from '../../lib/hooks/useToast';
import {
  selectChannel,
  selectToastMessage,
} from '../../store/messaging-admin/messagingAdminSelectors';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { getGroupChatLabel } from '../../utils/messagingUtils';
import { selectSDKConnected } from '../../store/sdk/sdkSelectors';
import MessagingAdmin from '../../store/messaging-admin/messagingAdminActions';

import ChannelTable from './ChannelTable';
import EditChannelModal from './EditChannelModal';
import ManageMembersModal from './ManageMembersModal';

const MODALS = {
  EDIT: 'EDIT',
  MANAGE_MEMBERS: 'MANAGE_MEMBERS',
};

const MessagingAdministration = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState('');
  const [selection, setSelection] = useState([]);
  const cid = selection?.[0];

  const [toastElement, openToast] = useToast();
  const [confirmDeleteElement, openConfirmDelete] = useConfirmAsync();
  const sdkConnected = useSelector(selectSDKConnected);
  const toastMessage = useSelector(selectToastMessage);
  const selectedChannel = useSelector((state) => selectChannel(state, cid));

  const handleFetch = useCallback(() => {
    if (sdkConnected) {
      setSelection([]);
      dispatch(MessagingAdmin.fetch());
      dispatch(MessagingAdmin.resetToastMessage());
    }
  }, [sdkConnected, dispatch]);

  const handleClickEdit = useCallback((e, id) => {
    if (!!id) {
      setSelection([id]);
    }
    setModal(MODALS.EDIT);
  }, []);

  const handleClickManageMembers = () => setModal(MODALS.MANAGE_MEMBERS);

  const handleClickDelete = () => {
    openConfirmDelete({
      title: `Delete ${getGroupChatLabel(selectedChannel.isWarRoom)}?`,
      content: (
        <Typography align="center">
          Are you sure you want to delete{' '}
          <b>{selectedChannel.name || 'this chat'}</b>?
        </Typography>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () =>
        dispatch(MessagingAdmin.deleteChannel(cid))
          .then(() =>
            dispatch(
              MessagingAdmin.setToastMessage({
                message: `${selectedChannel.name} has been deleted.`,
                severity: 'success',
              })
            )
          )
          .catch(() =>
            dispatch(
              MessagingAdmin.setToastMessage({
                message: `Error: ${selectedChannel.name} could not be deleted.`,
                severity: 'error',
              })
            )
          ),
    });
  };

  const handleCloseModal = () => setModal('');

  // Initialization effect.
  useEffect(() => {
    handleFetch();
    return () => dispatch(MessagingAdmin.resetToastMessage());
  }, [handleFetch, dispatch]);

  // Toast effect.
  useEffect(() => {
    if (!!toastMessage) {
      openToast(toastMessage.message, toastMessage.severity);
    }
  }, [toastMessage, openToast]);

  return (
    <>
      <ChannelTable
        selection={selection}
        onChangeSelection={setSelection}
        onClickRefresh={handleFetch}
        onClickEdit={handleClickEdit}
        onClickManageMembers={handleClickManageMembers}
        onClickDelete={handleClickDelete}
      />
      {!!cid && (
        <EditChannelModal
          open={modal === MODALS.EDIT}
          onClose={handleCloseModal}
          cid={cid}
        />
      )}
      {!!cid && (
        <ManageMembersModal
          open={modal === MODALS.MANAGE_MEMBERS}
          onClose={handleCloseModal}
          cid={cid}
        />
      )}
      {!!cid && confirmDeleteElement}
      {toastElement}
    </>
  );
};

export default MessagingAdministration;
