import Typography from '@mui/material/Typography';
import { useRef, useState, useEffect, useCallback } from 'react';

import useToast from '../../../lib/hooks/useToast';

import HeadingLayout from './HeadingLayout';
import EditItemControl from '../EditItemControl';
import RichTextEditor from '../../RichTextEditor';

const HeadingForm = ({
  description,
  errors,
  onChange,
  onClickSave,
  onClickCancel,
}) => {
  const ref = useRef();
  const [getContent, setContentGetter] = useState(null);
  const [toastElement, openToast] = useToast();

  const handleChange = () => onChange('description', getContent());

  const handleMaxLengthReached = (show) => {
    if (show) {
      openToast('You have reached the maximum character limit.', 'error', 3000);
    }
  };

  const handleRichTextEditorMount = useCallback((getter) => {
    setContentGetter(() => getter);
  }, []);

  // Scrolling effect.
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <>
      <HeadingLayout
        ref={ref}
        isEditable
        rightComponent={
          <EditItemControl
            onClickSave={onClickSave}
            onClickCancel={onClickCancel}
          />
        }
      >
        <RichTextEditor
          height="100px"
          hasBorder={false}
          onChange={handleChange}
          onMount={handleRichTextEditorMount}
          handleMaxChar={handleMaxLengthReached}
          placeholder="Enter heading text..."
          initialContent={description}
          style={{ margin: '0', overflow: 'hidden' }}
        />
        {!!errors?.description && (
          <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
            {errors?.description}
          </Typography>
        )}
      </HeadingLayout>
      {toastElement}
    </>
  );
};

export default HeadingForm;
