import * as React from "react";
const SvgPhoneCallDialIn = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 24 24", id: "svg4", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs4" }), /* @__PURE__ */ React.createElement("g", { "data-name": "Layer 2", id: "g4" }, /* @__PURE__ */ React.createElement("g", { "data-name": "phone-call", id: "g3" }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, opacity: 0, id: "rect1" }), /* @__PURE__ */ React.createElement("path", { d: "M21.75 15.91a1 1 0 0 0-.72-.65l-6-1.37a1 1 0 0 0-.92.26c-.14.13-.15.14-.8 1.38a9.91 9.91 0 0 1-4.87-4.89C9.71 10 9.72 10 9.85 9.85a1 1 0 0 0 .26-.92L8.74 3a1 1 0 0 0-.65-.72 3.79 3.79 0 0 0-.72-.18A3.94 3.94 0 0 0 6.6 2 4.6 4.6 0 0 0 2 6.6 15.42 15.42 0 0 0 17.4 22a4.6 4.6 0 0 0 4.6-4.6 4.77 4.77 0 0 0-.06-.76 4.34 4.34 0 0 0-.19-.73zM17.4 20A13.41 13.41 0 0 1 4 6.6 2.61 2.61 0 0 1 6.6 4h.33L8 8.64l-.54.28c-.86.45-1.54.81-1.18 1.59a11.85 11.85 0 0 0 7.18 7.21c.84.34 1.17-.29 1.62-1.16l.29-.55L20 17.07v.33a2.61 2.61 0 0 1-2.6 2.6z", id: "path3" }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "path4", cx: 13.047231, cy: 4.3358598, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle4", cx: 16.408161, cy: 4.3358598, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle5", cx: 19.769094, cy: 4.3358598, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle6", cx: 13.047231, cy: 7.6204071, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle7", cx: 16.408161, cy: 7.6204071, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle8", cx: 19.769094, cy: 7.6204071, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle9", cx: 13.047231, cy: 10.904956, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle10", cx: 16.408161, cy: 10.904956, r: 1.2221575 }), /* @__PURE__ */ React.createElement("circle", { style: {
  strokeWidth: 0.873333
}, id: "circle11", cx: 19.769094, cy: 10.904956, r: 1.2221575 }))));
export default SvgPhoneCallDialIn;
