import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useCallback } from 'react';

import { MAX_LENGTH } from '../../configs/validation';

import BasicModal from '../BasicModal';

const RedText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.error.main};
`;

const DeleteFolderModal = ({ open, folderName, onConfirm, onClose }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(() => {
    if (name.trim() !== folderName) {
      return setError('Typed name must match the folder name exactly.');
    }
    setLoading(true);
    return onConfirm();
  }, [folderName, name, onConfirm]);

  // Reset effect after closing.
  useEffect(() => {
    if (!open) {
      setName('');
      setError('');
      setLoading(false);
    }
  }, [open]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      buttonText="Delete"
      buttonColor="error"
      onClickButton={handleConfirm}
      title={`Delete '${folderName}'`}
      buttonDisabled={name.trim() !== folderName}
      sx={{ maxWidth: '28rem' }}
    >
      <Typography>
        <RedText>Warning:</RedText> Deleting a folder will also delete all of
        its contained files and folders.
      </Typography>
      <Typography>
        To confirm, please type the name of the folder:
        <br />
        <b>{folderName}</b>
      </Typography>
      <TextField
        autoFocus
        autoComplete="off"
        label="Folder Name"
        size="small"
        value={name}
        onChange={(e) => setName(e.target.value)}
        helperText={error}
        error={!!error}
        disabled={loading}
        inputProps={{ maxLength: MAX_LENGTH.value }}
      />
    </BasicModal>
  );
};

export default DeleteFolderModal;
