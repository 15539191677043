import axios from 'axios';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';

import { downloadFile } from '../../../utils/networkUtils';

import DocIcon from '../../../assets/icons/icDoc.svg?react';

const File = styled.div`
  border: 1px solid #555;
  border-radius: 5px;
  margin: 0.5em 0;
  width: 32.5em;
  max-width: 600px;
`;

const FileText = styled.div`
  margin: 15px 10px 5px;
  font-size: 18px;
  color: #444;
  display: flex;
`;

const Icon = styled.span`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 7px;
  padding-bottom: 9px !important;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  fill: white;
  height: 40px;
  width: 40px;
  margin-right: 15px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const FileActions = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #555;
  padding: 10px 5px;
  background: #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 57px;
`;

const FileName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function FileContent({ id, attachments }) {
  const theme = useTheme();

  const [attachment] = attachments;

  const downloadToComputer = useCallback(
    (file) => {
      // Create a hidden link to the blob from which to download.
      axios
        .get(file.asset_url, { responseType: 'arraybuffer' })
        .then(({ data, headers }) => {
          downloadFile({
            name: file.name || `image-${id}`,
            data,
            options: { type: headers['content-type'] },
          });
        });
    },
    [id]
  );

  return (
    <File>
      <FileText>
        <Icon theme={theme}>
          <DocIcon />
        </Icon>
        <FileName>
          <span>{attachment.name}</span>
        </FileName>
      </FileText>
      <FileActions>
        <Button
          variant="contained"
          onClick={() => downloadToComputer(attachment)}
        >
          Download
        </Button>
      </FileActions>
    </File>
  );
}
