import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import SectionTitle from './SectionTitle';
import DirectChatItem from './DirectChatItem';
import SuspenseLoading from '../SuspenseLoading';
import Chats from '../../store/chat/chatActions';
import Users from '../../store/user/userActions';
import { PulseLogoLoader } from '../loader';
import {
  selectDirectChats,
  selectIsFetching,
  selectNewChatForm,
} from '../../store/chat/chatSelectors';
import { CHAT_TYPES } from '../../lib/string';

const fallback = (
  <SuspenseLoading
    style={{ height: '150px' }}
    component={<PulseLogoLoader />}
  />
);

export default function DirectMessages() {
  const dispatch = useDispatch();
  const directChats = useSelector(selectDirectChats);
  const isFetching = useSelector(selectIsFetching);
  const newChatFormType = useSelector(selectNewChatForm);
  const [openCollapseList, setOpenCollapseList] = useState(true);
  const [isUnreadMessage, setIsUnreadMessage] = useState(false);

  useEffect(() => {
    setIsUnreadMessage(directChats.some((chat) => chat.state.unreadCount > 0));
  }, [directChats, isUnreadMessage]);

  // Debounce the chat click in order to make sure we do not call
  // the initial fetch too many times and end up with the wrong
  // messages in the chat.
  const onClickChat = debounce(
    (type, id) => dispatch(Chats.setCurrentChat(type, id)),
    100
  );

  const onCollapseClick = () => {
    !openCollapseList ? setOpenCollapseList(true) : setOpenCollapseList(false);
  };

  const createNewDirectMessage = () => {
    if (!!newChatFormType) {
      dispatch(Chats.toggleNewChatForm(''));
    } else {
      dispatch(Users.fetchUsers());
      dispatch(Chats.toggleNewChatForm(CHAT_TYPES.DIRECT_MESSAGE));
    }
  };

  return (
    <div>
      <SectionTitle
        title="Direct Messages"
        open={newChatFormType === CHAT_TYPES.DIRECT_MESSAGE}
        openCollapseList={openCollapseList}
        isUnreadMessage={isUnreadMessage}
        onClick={createNewDirectMessage}
        onCollapseClick={onCollapseClick}
      />
      {isFetching ? (
        fallback
      ) : (
        <Collapse in={openCollapseList || isUnreadMessage}>
          <List sx={{ py: 0 }}>
            {directChats.map((chat) => (
              <DirectChatItem
                key={chat.id}
                chat={chat}
                onClickChat={() => onClickChat(chat.type, chat.id)}
              />
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
}
