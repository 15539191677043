import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, useForm } from 'react-hook-form';

import {
  SUBSCRIPTION_LEVELS,
  SUBSCRIPTION_LEVEL_OPTIONS,
} from '../../../configs/management';
import { MAX_LENGTH } from '../../../configs/validation';
import { managementApi } from '../../../configs/managementApi';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';

import BasicModal from '../../BasicModal';

const EditOrgModal = ({ open, onClose, enterprise }) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      maxUser: 0,
      contractNumber: '',
      level: SUBSCRIPTION_LEVELS.BASIC,
    },
    values: {
      contractNumber: enterprise?.contractNumber || '',
      maxUser: enterprise?.subscriptionMaxUsers || 0,
      level: enterprise?.subscriptionLevel || SUBSCRIPTION_LEVELS.BASIC,
    },
  });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmClearElement, openConfirmClear] = useConfirmAsync();

  const matchesShqTrial = /shqtrial/i.test(enterprise?.name);
  const isLessThanSevenDaysOld =
    Date.now() - enterprise?.createTimestamp < 7 * 24 * 60 * 60 * 1000;
  const canClear = matchesShqTrial || isLessThanSevenDaysOld;
  const clearText = !canClear
    ? 'Only shqtrial and organizations less than 7 days old can be cleared.'
    : '';

  const handleClose = (isCancel = false) => {
    reset();
    setSuccess('');
    onClose(isCancel);
  };

  const handleClickClear = () =>
    openConfirmClear({
      title: 'Clear Organization?',
      content: (
        <Typography align="center">
          Are you sure you want to clear this organization&apos;s data? <br />
          <br />
          <b>Warning: </b> This cannot be undone.
        </Typography>
      ),
      buttonText: 'Clear',
      onConfirm: () =>
        managementApi.delete(`/enterprise/${enterprise?.id}/clear`),
    });

  const handleEdit = (data) => {
    setLoading(true);
    return managementApi
      .put(`/enterprise/${enterprise.id}`, data)
      .then(() => {
        setSuccess('Organization updated!');
        setTimeout(handleClose, 1000);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      onClose={() => handleClose(true)}
      title={`Edit "${enterprise?.name}"`}
      onClickButton={handleSubmit(handleEdit)}
      sx={{ width: '36rem' }}
      footerComponent={
        <Stack
          direction="row"
          component="footer"
          justifyContent="space-between"
          gap={1}
        >
          <Tooltip title={clearText} disableInteractive>
            <span>
              <Button
                color="error"
                variant="contained"
                disabled={!canClear}
                onClick={handleClickClear}
              >
                Clear Organization
              </Button>
            </span>
          </Tooltip>
          <Stack direction="row" gap={1}>
            <Button
              disabled={!!success || loading}
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!!success || loading}
              onClick={handleSubmit(handleEdit)}
            >
              {success || 'Update'}
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Controller
        name="contractNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            size="small"
            label="Contract Number"
            disabled={!!success || loading}
          />
        )}
      />
      <Stack direction="row" gap={2}>
        <Controller
          name="level"
          control={control}
          rules={{
            required: 'Please select a subscription level.',
          }}
          render={({ field }) => (
            <FormControl size="small" error={errors.level} sx={{ flex: 1 }}>
              <InputLabel id="subscription-level-label">
                Subscription Level
              </InputLabel>
              <Select
                {...field}
                label="Subscription Level"
                labelId="subscription-level-label"
                disabled={!!success || loading}
              >
                {SUBSCRIPTION_LEVEL_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors?.level?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="maxUser"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: 'Please enter a valid number.',
            },
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              error={!!errors.maxUser}
              label="Subscription Max Users"
              disabled={!!success || loading}
              helperText={errors?.maxUser?.message}
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                maxLength: MAX_LENGTH.value,
              }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
      </Stack>
      <Typography>
        <b>Note: </b> If you change any <b>subscription</b> details while a
        Welcome Package is <b>in progress</b>, send the email invitation again
        after saving the changes to ensure all changes are propagated.
      </Typography>
      {confirmClearElement}
    </BasicModal>
  );
};

export default EditOrgModal;
