import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCategoryNames,
  selectActiveCategory,
} from '../../store/playbook/playbookSelectors';
import Playbooks from '../../store/playbook/playbookActions';

import BasicModal from '../BasicModal';

const CategoryModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const category = useSelector(selectActiveCategory);
  const categoryNames = useSelector(selectCategoryNames);
  const [name, setName] = useState(category?.name || '');
  const [error, setError] = useState('');

  const isEditing = !!category;
  const buttonText = isEditing ? 'Save' : 'Create';
  const title = `${isEditing ? 'Edit' : 'Create'} Category`;

  const handleClick = () => {
    if (categoryNames.includes(name.trim().toLowerCase())) {
      return setError('This category name is already in use.');
    }

    const data = {
      name: name.trim(),
    };

    return dispatch(
      isEditing
        ? Playbooks.editCategory(category?.id, data)
        : Playbooks.createCategory(data)
    ).then(() => {
      setName('');
      setError('');
      onClose();
    });
  };

  // Open effect.
  useEffect(() => {
    if (open) {
      setName(category?.name || '');
      setError('');
    }
  }, [open, category]);

  return (
    <BasicModal
      open={open}
      title={title}
      onClose={onClose}
      buttonText={buttonText}
      onClickButton={handleClick}
      buttonDisabled={name.trim().length < 3}
    >
      <TextField
        autoFocus
        label="Name"
        value={name}
        color="primary"
        error={!!error}
        helperText={error}
        onChange={(e) => setName(e.target.value)}
      />
    </BasicModal>
  );
};

export default CategoryModal;
