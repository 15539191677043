import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {
  ACTIVITY_TYPES,
  ACTIVITY_TYPE_LABELS,
} from '../../../store/incident/incidentTypes';

import EditIcon from '../../../assets/icons/edit-outline.svg?react';
import TrashIcon from '../../../assets/icons/trash-2-outline.svg?react';

const getColorFromTheme = ({ theme, color }) => {
  switch (color) {
    case 'error':
      return theme.palette.error.main;
    case 'success':
      return theme.palette.success.light;
    case 'warning':
      return theme.palette.warning.main;
    case 'secondary':
    default:
      return theme.palette.secondary.main;
  }
};

const Container = styled.article`
  color: #333;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  border-left: 0.4rem solid
    ${({ theme, color }) => getColorFromTheme({ theme, color })};
  scroll-margin-top: 7rem;
  address {
    font-size: 0.875rem;
    font-style: normal;
  }
`;

const Title = styled(Typography)`
  font-weight: normal;
  b {
    font-weight: bold;
  }
`;

const Description = styled.div`
  margin: 1rem 0.75rem;
`;

const Layout = React.forwardRef(
  (
    {
      title,
      description,
      type = ACTIVITY_TYPES.UPDATE,
      borderColor = 'secondary',
      topRightContent = null,
      bottomLeftContent = null,
      afterTitleContent = null,
      canEdit,
      canDelete,
      onClickEdit,
      onClickDelete,
    },
    ref
  ) => {
    const titlePrefix = `${
      ACTIVITY_TYPE_LABELS?.[type] || ACTIVITY_TYPES.UPDATE
    }: `;

    return (
      <Container ref={ref} color={borderColor}>
        <Stack
          component="header"
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          m="0.75rem"
        >
          <Stack>
            <Title component="h3" variant="h5">
              {titlePrefix}
              <b>{title}</b>
            </Title>
            {afterTitleContent}
          </Stack>
          {topRightContent}
        </Stack>
        <Description>{description}</Description>
        <Stack
          component="footer"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m="0.75rem"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {bottomLeftContent}
          </Stack>
          {canEdit && (
            <Stack direction="row" spacing={0.5}>
              <Tooltip title="Edit" placement="left" disableInteractive arrow>
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="Edit"
                    disabled={!canEdit}
                    onClick={onClickEdit}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: '1.5rem' } }}
                  >
                    <SvgIcon component={EditIcon} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title="Delete"
                placement="right"
                disableInteractive
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="Delete"
                    disabled={!canDelete}
                    onClick={onClickDelete}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: '1.5rem' } }}
                  >
                    <SvgIcon component={TrashIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Container>
    );
  }
);

Layout.displayName = 'Layout';

export default Layout;
