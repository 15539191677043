import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useCallback } from 'react';

const useToast = () => {
  const [isOpen, setOpen] = useState(false);
  const [
    { severity: sev, message: msg, autoHideDuration: time, ...props },
    setProps,
  ] = useState({
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  });

  const element = useMemo(() => {
    const handleClose = () => setOpen(false);
    return (
      <Snackbar
        {...props}
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={time || 3000}
      >
        <Alert onClose={handleClose} severity={sev}>
          {msg}
        </Alert>
      </Snackbar>
    );
  }, [isOpen, props]);

  const trigger = useCallback(
    (message, severity, autoHideDuration, other) => {
      setProps({
        ...props,
        message,
        severity,
        autoHideDuration,
        ...other,
      });
      setOpen(true);
    },
    [setProps, setOpen]
  );
  return [element, trigger];
};

export default useToast;
