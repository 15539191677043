import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Controller, useForm } from 'react-hook-form';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { handlePaste } from '../../lib/input';
import { emailRegex } from '../../utils/formUtils';
import { MAX_LENGTH } from '../../configs/validation';
import useCountdown from '../../lib/hooks/useCountdown';
import { getServiceRegistryUrl } from '../../utils/networkUtils';

import Countdown from '../Countdown';
import PersonIcon from '../../assets/icons/person.svg?react';
import UnauthenticatedLayout from '../UnauthenticatedLayout';

const OrgCodeReminder = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [countdownValues, setNextAttemptCountdown] = useCountdown();

  const { isTicking } = countdownValues;
  const emailError = 'Please enter a valid email address.';

  const handleSend = (data) => {
    const email = data.email.trim().toLowerCase();

    setLoading(true);
    setError('');
    axios
      .get(getServiceRegistryUrl('shadowhq'))
      .then(({ data }) => {
        axios
          .post(`/authentication/remindOrgCode?email=${email}`, undefined, {
            baseURL: data.apiUrl,
          })
          .then(() => setSuccess('Thank You'))
          .catch(({ response }) => {
            console.error(response);
            if (response.status === 400) {
              setError(emailError);
            } else if (response.status === 429) {
              setNextAttemptCountdown(response.data.msUntilNextAttempt);
            } else {
              setError('An unexpected error occurred.');
            }
          })
          .finally(() => setLoading(false));
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError('An unexpected error occurred.');
      });
  };

  if (!!success) {
    return (
      <UnauthenticatedLayout
        useBrandmark
        title={success}
        buttons={[
          {
            to: '/setup',
            component: Link,
            variant: 'contained',
            title: 'Back to Login',
          },
        ]}
      >
        <Typography>
          You should receive an email at the provided address shortly.
        </Typography>
      </UnauthenticatedLayout>
    );
  }

  return (
    <UnauthenticatedLayout
      useBrandmark
      title="Enter your credentials to be sent a reminder email"
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(handleSend),
      }}
      buttons={[
        {
          to: '/setup',
          title: 'Cancel',
          component: Link,
          disabled: loading,
        },
        {
          type: 'submit',
          title: 'Send',
          variant: 'contained',
          disabled: loading || isTicking,
          children: loading ? <ClipLoader size={16} color="white" /> : 'Send',
        },
      ]}
    >
      <Controller
        name="email"
        control={control}
        rules={{
          required: emailError,
          pattern: {
            value: emailRegex,
            message: emailError,
          },
          validate: (value) =>
            value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              autoFocus
              fullWidth
              variant="standard"
              autoComplete="username"
              placeholder="Email Address"
              disabled={loading || isTicking}
              error={!!error || !!errors?.email}
              onPaste={(e) => handlePaste(e, onChange)}
              helperText={error || errors?.email?.message}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="primary" component={PersonIcon} />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
      <Countdown color="error" component="span" values={countdownValues} />
    </UnauthenticatedLayout>
  );
};

export default OrgCodeReminder;
