import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Users from '../../store/user/userActions';
import Token from '../../store/token/tokenActions';
import Authentication from '../../store/auth/authActions';
import Incidents from '../../store/incident/incidentActions';
import Dashboard from '../../store/dashboard/dashboardActions';
import { localStorageKeys } from '../../utils/localStorageUtils';

/**
 * This component is responsible for managing a storage event listener that
 * will check for various localStorage keys and propagate the changes to all
 * other tabs when changes are made.
 *
 * This gives a high level of control over which data is synced across tabs.
 * The current data being synced is the auth token, the refreshing state, and
 * polling function data. This allows the "active" tab to manage these processes
 * and all other tabs to receive the updates.
 */
const TabSyncManager = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('TabSyncManager mount');

    const handleStorageChange = (event) => {
      if (event.key === 'persist:root') {
        const persistedState = JSON.parse(event.newValue);
        const authState =
          persistedState && persistedState.auth
            ? JSON.parse(persistedState.auth)
            : null;

        if (!authState || !authState.currentUser) {
          // If currentUser is null, trigger logout.
          console.log('TabSyncManager: logout');
          dispatch(Authentication.logout());
        }
      }

      if (event.key === localStorageKeys.CURRENT_USER) {
        const currentUser = JSON.parse(event.newValue);
        if (currentUser) {
          const { authenticationToken, authenticationTokenExpiry } =
            currentUser;
          console.log('TabSyncManager: updating tokens');
          dispatch(
            Authentication.setToken({
              authenticationToken,
              authenticationTokenExpiry,
            })
          );
        }
      }

      if (event.key === localStorageKeys.REFRESHING_TOKEN) {
        const isRefreshing = JSON.parse(event.newValue);
        if (isRefreshing === true || isRefreshing === false) {
          console.log('TabSyncManager: updating refresh state', isRefreshing);
          dispatch(Token.setIsRefreshing(isRefreshing));
        }
      }

      if (event.key === localStorageKeys.DASHBOARD) {
        const dashboard = JSON.parse(event.newValue);
        if (dashboard) {
          console.log('TabSyncManager: updating dashboard');
          dispatch(Dashboard.setDashboard(dashboard));
        }
      }

      if (event.key === localStorageKeys.USERS_LIST) {
        const usersList = JSON.parse(event.newValue);
        if (usersList) {
          console.log('TabSyncManager: updating user list');
          dispatch(Users.sync(usersList));
        }
      }

      if (event.key === localStorageKeys.INCIDENTS) {
        const incidents = JSON.parse(event.newValue);
        if (incidents) {
          console.log('TabSyncManager: updating incidents');
          dispatch(Incidents.sync(incidents));
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      // console.log('TabSyncManager unmount');
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  return null;
};

export default TabSyncManager;
