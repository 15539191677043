import { FEED_PAGE_SIZE } from '../../configs';
import { INCIDENT_TYPES } from './incidentReducer';
import { selectCurrentItem } from './incidentSelectors';
import { axiosInstance } from '../../lib/axiosInstance';

const Incidents = {
  fetch: (page = 1) => ({
    type: INCIDENT_TYPES.FETCH,
    payload: axiosInstance
      .get(
        `/incident?page=${page}&size=${FEED_PAGE_SIZE}&populateActivitiesAndCheckInsAndTargetedUsers=true`
      )
      .then(({ data }) => ({
        items: data,
        currentPage: page,
        hasMoreItems: !!data.length,
      })),
  }),
  search: (searchTerm, page = 1) => ({
    type: INCIDENT_TYPES.SEARCH,
    payload: axiosInstance
      .get(
        `/incident?page=${page}&size=${FEED_PAGE_SIZE}&populateActivitiesAndCheckInsAndTargetedUsers=true&searchWord=${searchTerm}`
      )
      .then(({ data }) => ({
        items: data,
        currentPage: page,
        hasMoreItems: !!data.length,
      })),
  }),
  sync: ({ items, hasMoreItems }) => ({
    type: INCIDENT_TYPES.SYNC,
    payload: {
      items,
      hasMoreItems,
    },
  }),
  setItem: (incident) => ({
    type: INCIDENT_TYPES.SET_ITEM,
    payload: incident,
  }),
  getItem: (id) => ({
    type: INCIDENT_TYPES.GET_ITEM,
    payload: axiosInstance.get(`/incident/${id}`).then(({ data }) => data),
  }),
  createItem: (data, withActivities) => ({
    type: INCIDENT_TYPES.CREATE_ITEM,
    payload: axiosInstance.post(
      withActivities ? '/incident/withActivities' : '/incident',
      data
    ),
  }),
  editItem: (id, data) => ({
    type: INCIDENT_TYPES.EDIT_ITEM,
    payload: axiosInstance.patch(`/incident/${id}`, data),
  }),
  resolveItem: (id) => ({
    type: INCIDENT_TYPES.RESOLVE_ITEM,
    payload: axiosInstance.patch(`/incident/${id}`, { isActive: false }),
  }),
  checkIn: (id) => ({
    type: INCIDENT_TYPES.CHECK_IN,
    payload: axiosInstance.post(`/incident/${id}/checkIn`, undefined),
  }),
  getPdf: (id, onDownloadProgress) => ({
    type: INCIDENT_TYPES.GET_PDF,
    payload: axiosInstance
      .get(`/incident/file/${id}?type=PDF`, {
        responseType: 'blob',
        onDownloadProgress,
      })
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        return window.URL.createObjectURL(blob);
      }),
  }),
  createActivity: (data) => (dispatch, getState) => {
    const currentItem = selectCurrentItem(getState());

    return dispatch({
      type: INCIDENT_TYPES.CREATE_ACTIVITY,
      payload: axiosInstance.post(`/incident/${currentItem.id}/activity`, data),
    });
  },
  editActivity: (id, data) => (dispatch, getState) => {
    const currentItem = selectCurrentItem(getState());

    return dispatch({
      type: INCIDENT_TYPES.EDIT_ACTIVITY,
      payload: axiosInstance.patch(`/incident/activity/${id}`, {
        ...data,
        incidentId: currentItem.id,
      }),
    });
  },
  deleteActivity: (id) => ({
    type: INCIDENT_TYPES.DELETE_ACTIVITY,
    payload: axiosInstance.delete(`/incident/activity/${id}`),
  }),
};

export default Incidents;
