import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);

export const formattedDate = (value) => dayjs(value).format('ll');
export const formatTime = (value) => dayjs(value).format('LT');
export const formatTimestamp = (value) => dayjs(value).format('lll');

export const getArticleDate = (value) => {
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(value);
  const day = date.getDate();
  const monthNum = date.getMonth();
  return `${month[monthNum]} ${day}`;
};

export function getTimeDiffString(timestamp) {
  const now = Date.now();
  const diff = now - timestamp;

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  let string = '';
  let period = 0;
  if (diff >= day) {
    period = Math.floor(diff / day);

    if (period === 1) {
      string = 'a day ago';
    } else {
      string = `${period} days ago`;
    }
  } else if (diff >= hour) {
    period = Math.floor(diff / hour);

    if (period >= 20) {
      string = 'a day ago';
    } else if (period === 1) {
      string = 'an hour ago';
    } else {
      string = `${period} hours ago`;
    }
  } else if (diff >= minute) {
    period = Math.floor(diff / minute);

    if (period === 1) {
      string = 'a minute ago';
    } else {
      string = `${period} minutes ago`;
    }
  } else if (diff >= second) {
    period = Math.floor(diff / second);
    string = 'a few seconds ago';
  } else {
    string = 'Just now';
  }

  return string;
}
export default getArticleDate;

// Calculate the duration of the incident and display in 00:00:00 format
export const clockTimerFormat = (timeInMillis) => {
  const days = Math.floor(timeInMillis / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeInMillis % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeInMillis % (1000 * 60)) / 1000);

  const daysString = days < 10 ? `0${days}` : days;
  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;

  if (days === 0) {
    return `${hoursString}:${minutesString}:${secondsString}`;
  }
  return `${daysString}:${hoursString}:${minutesString}:${secondsString}`;
};

export const useDate = (currentDate) => {
  const [timer, setTimer] = React.useState(currentDate);
  const locale = 'en';

  const tick = () => {
    setTimer(new Date());
  };

  React.useEffect(() => {
    const interval = setInterval(() => tick(), 1000);
    return () => clearInterval(interval);
  }, []);

  const date = `${timer.toLocaleDateString(locale, {
    month: 'short',
  })} ${timer.getDate()}-${timer.getFullYear()}`;
  const time = `${timer.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    ampm: true,
  })}`;

  return { date, time, timer };
};

export const timeFromNow = (differenceInMillis) => {
  const now = Date.now();
  const diff = now - differenceInMillis;
  dayjs.extend(relativeTime);
  const time = dayjs(diff);
  return dayjs(time).fromNow(true);
};

export const formatDateAsMMDDYY = (date) => {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
    date
  );
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${month}-${day}-${year}`;
};

export const minutesSinceTimestamp = (timestamp) => {
  const now = Date.now();
  const date = new Date(timestamp).valueOf();
  return (now - date) / 1000 / 60; // Convert milliseconds to minutes.
};

export const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return { minutes, seconds };
};

export const isTimestampPast = (timestamp) => Date.now() >= timestamp * 1000;

export const getElapsedTimeSinceTimestamp = (timestamp, showAgo = false) => {
  const milliseconds = Date.now() - timestamp;

  const timeUnits = [
    { label: 'year', duration: 31536000000 },
    { label: 'month', duration: 2592000000 },
    { label: 'day', duration: 86400000 },
    { label: 'hour', duration: 3600000 },
    { label: 'minute', duration: 60000 },
    { label: 'second', duration: 1000 },
  ];

  for (const unit of timeUnits) {
    if (milliseconds >= unit.duration) {
      const count = Math.floor(milliseconds / unit.duration);
      return `${count} ${unit.label}${count > 1 ? 's' : ''}${
        showAgo ? ' ago' : ''
      }`;
    }
  }

  return 'Just now';
};
