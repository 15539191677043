import { AUTH_TYPES } from '../auth/authReducer';

const INITIAL_STATE = {
  channels: [],
  fetching: false,
  toastMessage: null,
};

export const MESSAGING_ADMIN_TYPES = {
  FETCH_CHANNELS: '@@messagingAdmin/FETCH_CHANNELS',
  EDIT_CHANNEL: '@@messagingAdmin/EDIT_CHANNEL',
  ADD_MEMBERS: '@@messagingAdmin/ADD_MEMBERS',
  REMOVE_MEMBERS: '@@messagingAdmin/REMOVE_MEMBERS',
  PROMOTE_MEMBERS: '@@messagingAdmin/PROMOTE_MEMBERS',
  DEMOTE_MEMBERS: '@@messagingAdmin/DEMOTE_MEMBERS',
  DELETE_CHANNEL: '@@messagingAdmin/DELETE_CHANNEL',
  SET_TOAST_MESSAGE: '@@messagingAdmin/SET_TOAST_MESSAGE',
  RESET_TOAST_MESSAGE: '@@messagingAdmin/RESET_TOAST_MESSAGE',
};

export default function messagingAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `${MESSAGING_ADMIN_TYPES.FETCH_CHANNELS}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${MESSAGING_ADMIN_TYPES.FETCH_CHANNELS}_REJECTED`:
      return {
        ...state,
        fetching: false,
      };
    case `${MESSAGING_ADMIN_TYPES.FETCH_CHANNELS}_FULFILLED`:
      return {
        ...state,
        fetching: false,
        channels: action.payload,
      };
    case `${MESSAGING_ADMIN_TYPES.EDIT_CHANNEL}_FULFILLED`:
    case `${MESSAGING_ADMIN_TYPES.ADD_MEMBERS}_FULFILLED`:
    case `${MESSAGING_ADMIN_TYPES.REMOVE_MEMBERS}_FULFILLED`:
    case `${MESSAGING_ADMIN_TYPES.PROMOTE_MEMBERS}_FULFILLED`:
    case `${MESSAGING_ADMIN_TYPES.DEMOTE_MEMBERS}_FULFILLED`: {
      const { channel, members } = action.payload.data;
      return {
        ...state,
        channels: state.channels.map((c) =>
          c.channel.cid === channel.cid
            ? {
                ...c,
                channel,
                members,
              }
            : c
        ),
      };
    }
    case `${MESSAGING_ADMIN_TYPES.DELETE_CHANNEL}_FULFILLED`: {
      const { channel } = action.payload.data;
      return {
        ...state,
        channels: state.channels.filter((c) => c.channel.cid !== channel.cid),
      };
    }
    case `${MESSAGING_ADMIN_TYPES.SET_TOAST_MESSAGE}`:
      return {
        ...state,
        toastMessage: action.payload,
      };
    case `${MESSAGING_ADMIN_TYPES.RESET_TOAST_MESSAGE}`:
      return {
        ...state,
        toastMessage: INITIAL_STATE.toastMessage,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
