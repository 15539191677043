import { useSelector } from 'react-redux';

import { ROLES } from '../configs';
import {
  selectCurrentUserRole,
  selectFetchingUserProperties,
} from '../store/auth/authSelectors';

const useUserRole = () => {
  const role = useSelector(selectCurrentUserRole);
  const fetchingUserProperties = useSelector(selectFetchingUserProperties);

  return {
    role,
    isUser: role === ROLES.user,
    isAdmin: role === ROLES.admin,
    isLoading: !role && fetchingUserProperties,
  };
};

export default useUserRole;
