import Typography from '@mui/material/Typography';

const DEFAULT_TEMPLATE_STRING =
  'Please wait %m minute%mp and %s second%sp before trying again.';

/**
 * Injects a total number of seconds into a template string.
 * @param {Object} configValues - The configuration object.
 * @param {Object} configValues.values - The object containing the countdown values.
 * @param {number} configValues.values.days - Value injected into %d and %dp (for pluralizing).
 * @param {number} configValues.values.hours - Value injected into %h and %hp (for pluralizing).
 * @param {number} configValues.values.minutes - Value injected into %m and %mp (for pluralizing).
 * @param {number} configValues.values.seconds - Value injected into %s and %sp (for pluralizing).
 * @param {boolean} [configValues.padValues] - A boolean indicating if minutes and seconds should contain a leading zero.
 * @param {string} [configValues.templateString] - The template string for the countdown text.
 * @returns {String} The string complete with values.
 */
const injectValuesIntoTemplateString = ({
  values,
  padValues = false,
  templateString = DEFAULT_TEMPLATE_STRING,
}) => {
  const { days: d, hours: h, minutes: m, seconds: s } = values;
  const replaceValues = (
    string,
    value,
    valuePlaceholder,
    pluralValuePlaceholder
  ) => {
    let result = string;
    if (result.includes(valuePlaceholder)) {
      result = result.replace(
        valuePlaceholder,
        padValues ? String(value).padStart(2, '0') : String(value)
      );
    }
    if (result.includes(pluralValuePlaceholder)) {
      result = result.replace(pluralValuePlaceholder, value === 1 ? '' : 's');
    }
    return result;
  };

  let string = templateString;
  string = replaceValues(string, d, '%d', '%dp');
  string = replaceValues(string, h, '%h', '%hp');
  string = replaceValues(string, m, '%m', '%mp');
  string = replaceValues(string, s, '%s', '%sp');
  return string;
};

const Countdown = ({
  values,
  padValues = false,
  templateString = DEFAULT_TEMPLATE_STRING,
  ...props
}) => {
  const { isTicking } = values;
  const text = injectValuesIntoTemplateString({
    values,
    padValues,
    templateString,
  });

  return isTicking ? <Typography {...props}>{text}</Typography> : null;
};

export default Countdown;
