import { generateIdFromUuid } from '../../utils/userUtils';

export const MANAGEMENT_TYPES = {
  LOGIN: '@@managment/LOGIN',
  FETCH_DASHBOARD: '@@management/FETCH_DASHBOARD',
  FETCH_USERS: '@@management/FETCH_USERS',
  CREATE_USER: '@@management/CREATE_USER',
  UPDATE_USER: '@@management/UPDATE_USER',
  IMPORT_USERS: '@@management/IMPORT_USERS',
  DEACTIVATE_USER: '@@management/DEACTIVATE_USER',
  REACTIVATE_USER: '@@management/REACTIVATE_USER',
  SEND_USER_EMAIL: '@@management/SEND_USER_EMAIL',
  RESET_TOTP_ENROLMENT: '@@management/RESET_TOTP_ENROLMENT',
  SET_TOAST: '@@management/SET_TOAST',
  RESET_TOAST: '@@management/RESET_TOAST',
  LOGOUT: '@@managment/LOGOUT',
};

const INITIAL_STATE = {
  email: '',
  baseURL: '',
  authToken: '',
  streamCredsAvailable: 0,
  userList: [],
  fetchingUsers: false,
  toast: {
    message: '',
    severity: '',
  },
};

const managementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MANAGEMENT_TYPES.LOGIN: {
      const { jwt, apiAddress, email } = action.payload;

      return {
        ...state,
        email,
        authToken: jwt,
        baseURL: apiAddress,
      };
    }
    case `${MANAGEMENT_TYPES.FETCH_DASHBOARD}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        streamCredsAvailable: data.credentialsInThePool,
      };
    }
    case `${MANAGEMENT_TYPES.FETCH_USERS}_PENDING`:
      return {
        ...state,
        fetchingUsers: true,
      };
    case `${MANAGEMENT_TYPES.FETCH_USERS}_REJECTED`:
      return {
        ...state,
        userList: [],
        fetchingUsers: false,
      };
    case `${MANAGEMENT_TYPES.FETCH_USERS}_FULFILLED`: {
      const userList = action.payload.data.reduce(
        (acc, user) => [
          ...acc,
          {
            ...user,
            id: generateIdFromUuid(user.userProperties.uuid),
            role: user.userProperties.role || '',
            type: user.userProperties.type || '',
            location: user.userProperties.location || '',
            department: user.userProperties.department || '',
          },
        ],
        []
      );

      return {
        ...state,
        userList,
        fetchingUsers: false,
      };
    }
    case MANAGEMENT_TYPES.SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case MANAGEMENT_TYPES.RESET_TOAST:
      return {
        ...state,
        toast: {
          message: '',
          severity: '',
        },
      };
    case MANAGEMENT_TYPES.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default managementReducer;
