import { useState, forwardRef } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { MAX_LENGTH } from '../configs/validation';

import EyeIcon from '../assets/icons/eye.svg?react';
import EyeOffIcon from '../assets/icons/eye-off.svg?react';
import EyeOutlineIcon from '../assets/icons/eye-outline.svg?react';
import EyeOffOutlineIcon from '../assets/icons/eye-off-outline.svg?react';

const PasswordInput = forwardRef(
  (
    {
      size = 'small',
      variant = 'outlined',
      visibilityEdge = 'end',
      inputProps = {},
      InputProps = {},
      outlineIcon = true,
      iconProps = { color: '#555' },
      ...props
    },
    ref
  ) => {
    const [show, setShow] = useState(false);

    const VisibleIcon = outlineIcon ? EyeOutlineIcon : EyeIcon;
    const InvisibleIcon = outlineIcon ? EyeOffOutlineIcon : EyeOffIcon;

    return (
      <TextField
        {...props}
        ref={ref}
        size={size}
        variant={variant}
        type={show ? 'text' : 'password'}
        inputProps={{
          ...inputProps,
          maxLength: MAX_LENGTH.value,
        }}
        InputProps={{
          ...InputProps,
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge={visibilityEdge}
                aria-label="toggle password visibility"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setShow((prev) => !prev)}
              >
                <SvgIcon
                  {...iconProps}
                  component={show ? InvisibleIcon : VisibleIcon}
                >
                  <EyeOutlineIcon />
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
