import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { selectCurrentUserEmail } from '../../store/auth/authSelectors';
import { selectOrgCode } from '../../store/org/orgSelectors';
import packageInfo from '../../../package.json';

/**
 * Check the documentation for this component in the project README.
 */
const SentryUserManager = () => {
  const email = useSelector(selectCurrentUserEmail);
  const orgCode = useSelector(selectOrgCode);

  useEffect(() => {
    if (email && orgCode) {
      // console.log('SentryUserManager mount');
      Sentry.setUser({
        email,
        Organization: orgCode,
        Version: packageInfo.version,
      });
    }

    return () => {
      // console.log('SentryUserManager unmount');
      Sentry.setUser(null);
    };
  }, [email, orgCode]);

  return null;
};

export default SentryUserManager;
