/**
 * Using a Promise, pause for some time before taking some next action.
 * @param {Number} ms - The amount of time before resolving the Promise.
 * @returns {Promise}
 */
export const wait = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 * Retry a asynchronous action a number of times at fixed intervals.
 * @param {Function} action - A function that returns a Promise.
 * @param {Number} delay - The amount of time between retries in ms.
 * @param {Number} retries - The number of retries after the initial rejection.
 * @returns {Promise}
 */
export const retryAction = (action, delay, retries) =>
  new Promise((resolve, reject) => {
    return action()
      .then(resolve)
      .catch((reason) => {
        if (retries > 0) {
          return wait(delay)
            .then(retryAction.bind(null, action, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
