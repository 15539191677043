import { axiosInstance } from '../../lib/axiosInstance';

import {
  SANITIZED_ITEM_KEYS_FOR_CREATE,
  SANITIZED_ITEM_KEYS_FOR_EDIT,
} from './playbookTypes';
import { PLAYBOOK_TYPES } from './playbookReducer';
import { selectActiveCategory } from './playbookSelectors';

const sanitizeItem = (keys, item) => {
  keys.forEach((key) => {
    delete item[key];
  });
};

const Playbooks = {
  fetchAll: () => (dispatch) => {
    dispatch({ type: `${PLAYBOOK_TYPES.FETCH_ALL}_PENDING` });

    Promise.all([
      axiosInstance.get('/playbook/templates'),
      axiosInstance.get('/playbook/categories'),
    ])
      .then(([{ data: list }, { data: categories }]) =>
        dispatch({
          type: `${PLAYBOOK_TYPES.FETCH_ALL}_FULFILLED`,
          payload: {
            list,
            categories,
          },
        })
      )
      .catch((e) =>
        dispatch({
          type: `${PLAYBOOK_TYPES.FETCH_ALL}_REJECTED`,
          payload: e,
        })
      );
  },
  fetchPlaybook: (id) => ({
    type: PLAYBOOK_TYPES.FETCH_PLAYBOOK,
    payload: axiosInstance.get(`/playbook/template/${id}`),
  }),
  setCategoryId: (id) => ({
    type: PLAYBOOK_TYPES.SET_CATEGORY_ID,
    payload: id,
  }),
  createCategory: (data) => ({
    type: PLAYBOOK_TYPES.CREATE_CATEGORY,
    payload: axiosInstance.post('/playbook/category', data),
  }),
  editCategory: (id, data) => ({
    type: PLAYBOOK_TYPES.EDIT_CATEGORY,
    payload: axiosInstance.put(`/playbook/category/${id}`, data),
  }),
  deleteCategory: (id) => ({
    type: PLAYBOOK_TYPES.DELETE_CATEGORY,
    payload: axiosInstance.delete(`/playbook/category/${id}`).then(() => id),
  }),
  setPlaybookId: (id) => ({
    type: PLAYBOOK_TYPES.SET_PLAYBOOK_ID,
    payload: id,
  }),
  create: (initialData) => (dispatch, getState) => {
    const category = selectActiveCategory(getState());

    const data = {
      ...initialData,
      categoryId: initialData.categoryId ? initialData.categoryId : category.id,
      activities: initialData.items.map((item, index) => {
        sanitizeItem(SANITIZED_ITEM_KEYS_FOR_CREATE, item);
        return {
          ...item,
          index,
        };
      }),
    };

    return dispatch({
      type: PLAYBOOK_TYPES.CREATE_PLAYBOOK,
      payload: axiosInstance.post('/playbook/template', data),
    });
  },
  edit: (id, initialData) => (dispatch) => {
    const data = {
      ...initialData,
      activities: initialData.items.map((item, index) => {
        sanitizeItem(SANITIZED_ITEM_KEYS_FOR_EDIT, item);
        return {
          ...item,
          index,
        };
      }),
    };

    return dispatch({
      type: PLAYBOOK_TYPES.EDIT_PLAYBOOK,
      payload: axiosInstance.put(`/playbook/template/${id}`, data),
    });
  },
  delete: (id) => ({
    type: PLAYBOOK_TYPES.DELETE_PLAYBOOK,
    payload: axiosInstance.delete(`/playbook/template/${id}`).then(() => id),
  }),
  exportPlaybook: (id, type, onDownloadProgress) => ({
    type: PLAYBOOK_TYPES.EXPORT_PLAYBOOK,
    payload: axiosInstance
      .get(`/playbook/template/file/${id}?type=${type}`, {
        responseType: 'blob',
        onDownloadProgress,
      })
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: `application/${type.toLowerCase()}`,
        });
        return window.URL.createObjectURL(blob);
      }),
  }),
  exportMultiplePlaybooks: (ids = [], type) => ({
    type: PLAYBOOK_TYPES.EXPORT_PLAYBOOK,
    payload: axiosInstance
      .get(`/playbook/templates/archived?ids=${ids.join(',')}&type=${type}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: 'application/zip',
        });
        return window.URL.createObjectURL(blob);
      }),
  }),
  share: (playbookIds, childEnterpriseIds) => ({
    type: PLAYBOOK_TYPES.SHARE_PLAYBOOK,
    payload: axiosInstance.post('/crossEnterprises/playbook/share', {
      playbookIds,
      childEnterpriseIds,
    }),
  }),
};

export default Playbooks;
