import { useRef, useEffect } from 'react';

export default function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(intervalRef.current);
    }
  }, [delay]);

  return intervalRef;
}
