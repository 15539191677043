import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { timeFromNow } from '../../../lib/date';

import DashBadge from '../../../components/dashboard/DashBadge';
import TimeBadge from '../../../assets/icons/DB_time.svg';
import UserBadge from '../../../assets/icons/UserBadge.svg';
import FilesGreenBadge from '../../../assets/icons/DB_file.svg';
import ShieldRedBadge from '../../../assets/icons/DB_ShieldRed.svg';
import ShieldGreenBadge from '../../../assets/icons/db_ShieldGreen.svg';
import ShieldYellowBadge from '../../../assets/icons/DB_ShieldYellow.svg';

const Container = styled.div`
  margin: 20px auto 1rem;
  height: auto;
  background-color: #ffff;
  border-radius: 25px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const OrgContainersHeader = styled(Box)`
  font-size: 21px;
  font-weight: bold;
  width: 300px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const COLORS = {
  RED: '#D03C49',
  PURPLE: '#725BB8',
  BLUE: '#47B2E2',
  GREEN: '#3CB694',
  YELLOW: '#FFC736',
};

const OrgDetails = ({ orgData, incidents }) => {
  const { activeUsers, maxUsers, filesCount, enterprise } = orgData;
  const hasIncident = incidents.some(
    (i) => i.isActive === true && i.isTabletop === false
  );
  const hasTabletop = incidents.some(
    (i) => i.isActive === true && i.isTabletop === true
  );
  const formattedOrgName =
    enterprise && enterprise.displayName
      ? enterprise.displayName.charAt(0)?.toUpperCase() +
        enterprise.displayName.slice(1)
      : 'N/A';
  const statusWithoutIncidents =
    incidents.length > 0 && incidents[0].resolveTimestamp === null
      ? timeFromNow(
          dayjs().diff(
            incidents[0].resolveTimestamp || incidents[0].createTimestamp
          )
        )
      : 'N/A';
  const incidentBadge = hasIncident
    ? ShieldRedBadge
    : hasTabletop
      ? ShieldYellowBadge
      : ShieldGreenBadge;

  const incidentBadgeColor = hasIncident
    ? COLORS.RED
    : hasTabletop
      ? COLORS.YELLOW
      : COLORS.GREEN;

  const incidentBadgeStatus =
    hasIncident.length > 1
      ? `${hasIncident.length} Incidents In Progress`
      : hasIncident
        ? 'Incident In Progress'
        : hasTabletop.length > 1
          ? `${hasTabletop.length} Tabletop Exercises In Progress`
          : hasTabletop
            ? 'Tabletop Exercise In Progress'
            : 'Safe';

  return (
    <Container>
      <OrgContainersHeader>{formattedOrgName}</OrgContainersHeader>
      <Box sx={{ maxWidth: '170px' }}>
        <DashBadge
          icon={incidentBadge}
          color={incidentBadgeColor}
          status={incidentBadgeStatus}
          title="Current State"
          fontSize="20px"
          width="95px"
          height="90px"
          marginBottom="0px"
        />
      </Box>
      <Box>
        <DashBadge
          icon={TimeBadge}
          color={COLORS.PURPLE}
          status={statusWithoutIncidents}
          title={hasIncident ? 'Time In Incident' : 'Time Incident-Free'}
          fontSize="20px"
          width="95px"
          height="90px"
          marginBottom="0px"
        />
      </Box>
      <Box>
        <DashBadge
          icon={UserBadge}
          color={COLORS.BLUE}
          status={`${activeUsers} of ${maxUsers}`}
          title="Number of Users"
          fontSize="20px"
          width="95px"
          height="90px"
          marginBottom="0px"
        />
      </Box>
      <Box>
        <DashBadge
          icon={FilesGreenBadge}
          color={COLORS.GREEN}
          status={filesCount}
          title="Number of Files"
          fontSize="20px"
          width="95px"
          height="90px"
          marginBottom="0px"
        />
      </Box>
    </Container>
  );
};

export default OrgDetails;
