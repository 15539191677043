import { PAGES, ROLES, PERMISSIONS } from '../configs';

/**
 * Returns the relevant page object for a given pathname.
 * @param {string} pathname - The pathname, usually coming from useLocation.
 * @returns {object} The relevant page object.
 */
export const getPageForPathname = (pathname) => {
  if (pathname === '/') {
    return PAGES.dashboard; // Special case for dashboard page.
  }

  const pages = Object.values(PAGES).filter((p) => p.pathname !== '/');
  const pageMatchingPath = pages.find((p) => pathname === p.pathname);
  const pageIncludingPath = pages.find((p) =>
    location.pathname.includes(p.pathname)
  );

  // If there is no matching path, but one that includes it, this means it's likely to be
  // a child page, like a specific incident for example. (e.g. /incidents/876)
  if (!pageMatchingPath && !!pageIncludingPath) {
    return pageIncludingPath;
  }
  return pageMatchingPath;
};

/**
 * Determines if a user can access a resource, given a set of requirements and depending on their role, as well as the enabled features for the org.
 * @param {string} userRole - The current user's role.
 * @param {string[]} enabledFeatures - A set of enabled features for the org.
 * @param {object} accessRequirements - A set of requirements required for access.
 * @param {string[]} accessRequirements.roles - Give access to a set of roles.
 * @param {string} accessRequirements.permission - Give access to a named feature, which is mapped to a set of roles.
 * @param {string} accessRequirements.dependentFeature - Give access only if a named feature is enabled for the org.
 * @returns {boolean} - Returns if user can access the resource.
 */
export const determineAccess = (
  userRole,
  enabledFeatures = [],
  { roles = [], permission, dependentFeature }
) => {
  // If this is a dependent feature, and it is not enabled, reject access.
  if (!!dependentFeature && !enabledFeatures.includes(dependentFeature)) {
    return false;
  }

  // If no roles or permissions are provided, allow access.
  if (!roles.length && !permission) {
    return true;
  }

  // If a role has not been configured, reject access.
  if (!!roles.length && roles.some((r) => !Object.keys(ROLES).includes(r))) {
    console.error('One or more provided roles does not exist.');
    return false;
  }
  // If a permission has not been configured, reject access.
  if (!!permission && !Object.keys(PERMISSIONS).includes(permission)) {
    console.error('The provided permission does not exist.');
    return false;
  }

  // If a set of roles is provided, compare to current user role.
  if (!!roles.length) {
    return !!userRole && roles.includes(userRole);
  }

  // If a permission is provide, compare to current user role.
  if (!!permission) {
    return !!userRole && PERMISSIONS[permission].includes(userRole);
  }

  return false;
};
