import { AUTH_TYPES } from '../auth/authReducer';
import { updateAxiosConfig } from '../../lib/axiosConfig';
import { DEFAULT_EDITABLE_TIME_LIMIT } from '../../configs';

const INIT_ORG_STATE = {
  registry: {},
  fetchingRegistry: false,
  config: {},
  badges: [],
  fetchingConfig: false,
  fetchingAdminToolId: false,
  updatingConfig: false,
  maxFileSize: 100000000,
  allowedFileExtensions: [
    'PDF',
    'DOC',
    'DOCX',
    'TXT',
    'JPG',
    'JPEG',
    'PNG',
    'WEBP',
    'HEIC',
    'XLS',
    'XLSX',
    'PPT',
    'PPTX',
    'MOV',
    'MP3',
    'MP4',
  ],
  auth2fa: false,
  auth2faType: '',
  messageHistoryEditTimeout: DEFAULT_EDITABLE_TIME_LIMIT,
  enable2faOnSfs: false,
  dialInEnabled: false,
  playbooksEnabled: false,
  maxPlaybooksAllowed: 3,
  groupsEnabled: false,
  playbooksSharingEnabled: false,
  childOrgs: [],
};

export const ORG_TYPES = {
  FETCH_REGISTRY: '@@enterprise/FETCH_REGISTRY',
  FETCH_CONFIG: '@@enterprise/FETCH_CONFIG',
  FETCH_BADGES: '@@enterprise/FETCH_BADGES',
  RESET_ORG: '@@enterprise/RESET_ORG',
  FETCH_ENTERPRISE_PLATFORM_CONFIG:
    '@@enterprise/FETCH_ENTERPRISE_PLATFORM_CONFIG',
  REQUEST_UPGRADE: '@@enterprise/REQUEST_UPGRADE',
  FETCH_CHILD_ORGS: '@@enterprise/FETCH_CHILD_ORGS',
};

export default function org(state = INIT_ORG_STATE, action) {
  switch (action.type) {
    case `${ORG_TYPES.FETCH_REGISTRY}_PENDING`:
      return {
        ...state,
        fetchingRegistry: true,
      };
    case `${ORG_TYPES.FETCH_REGISTRY}_FULFILLED`: {
      const registry = action.payload.data;

      updateAxiosConfig(registry.apiUrl, null, registry.code);

      return {
        ...state,
        registry,
        fetchingRegistry: false,
      };
    }
    case `${ORG_TYPES.FETCH_CONFIG}_PENDING`:
      return {
        ...state,
        config: {},
        fetchingConfig: true,
      };
    case `${ORG_TYPES.FETCH_CONFIG}_FULFILLED`:
      return {
        ...state,
        config: action.payload.data,
        fetchingConfig: false,
      };
    case `${ORG_TYPES.FETCH_CONFIG}_REJECTED`:
      return {
        ...state,
        fetchingConfig: false,
        error: action.payload.data,
      };
    case `${ORG_TYPES.FETCH_ENTERPRISE_PLATFORM_CONFIG}_PENDING`:
      return {
        ...state,
        updatingConfig: true,
      };
    case `${ORG_TYPES.FETCH_ENTERPRISE_PLATFORM_CONFIG}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        updatingConfig: false,
        auth2fa: Boolean(data?.auth2fa),
        auth2faType: data?.auth2faType,
        maxFileSize: !!data?.maxFileSize
          ? data.maxFileSize * 1000000
          : state.maxFileSize,
        allowedFileExtensions: !!data?.allowedFileExtensions
          ? data.allowedFileExtensions
          : state.allowedFileExtensions,
        enable2faOnSfs: Boolean(data?.sfs2fa),
        messageHistoryEditTimeout: !!data?.messageHistoryEditTimeout
          ? data?.messageHistoryEditTimeout
          : state.messageHistoryEditTimeout,
        dialInEnabled: !!data?.dialInEnabled
          ? data?.dialInEnabled
          : state.dialInEnabled,
        playbooksEnabled: Boolean(data?.playbookEnabled),
        maxPlaybooksAllowed:
          data?.playbookMaxTemplates !== undefined
            ? data.playbookMaxTemplates
            : 3,
        groupsEnabled: Boolean(data?.groupsEnabled),
        playbooksSharingEnabled: Boolean(data?.playbooksSharingEnabled),
      };
    }
    case `${ORG_TYPES.FETCH_BADGES}_FULFILLED`:
      return {
        ...state,
        badges: action.payload.data,
      };
    case `${ORG_TYPES.FETCH_ENTERPRISE_PLATFORM_CONFIG}_REJECTED`:
      return {
        ...state,
        updatingConfig: false,
        error: action.payload.data,
      };
    case `${ORG_TYPES.FETCH_CHILD_ORGS}_FULFILLED`:
      return {
        ...state,
        childOrgs: action.payload.data,
      };
    case ORG_TYPES.RESET_ORG:
    case AUTH_TYPES.CLEAR:
      return INIT_ORG_STATE;
    default:
      return state;
  }
}
