import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';

import Messages from '../../store/chat/messageActions';
import GroupMember from './GroupMember';
import Attachment from './Attachment';
import ConfirmModal from '../ConfirmModal';
import Chats from '../../store/chat/chatActions';
import {
  selectGroupMembers,
  selectAttachments,
} from '../../store/chat/chatSelectors';
import { selectHasCall } from '../../store/jitsi/jitsiSelectors';
import { getStreamChatClient } from '../../lib/streamChatClient';

import UserAutocomplete from '../UserAutocomplete';

// Icons
import ChevronUp from '../../assets/icons/chevron-up.svg?react';
import ChevronDown from '../../assets/icons/chevron-down.svg?react';
import Close from '../../assets/icons/close.svg?react';

const Panel = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${({ open }) => (open ? '20em' : '0em')};
  border-left: ${({ open }) => open && '1px solid #ddd'};
  background: white;
  box-shadow: -2px 0px 5px #efefef;
  overflow: hidden;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.4em;
  right: 0.35em;
  align-items: center;
  vertical-align: middle;

  .cancel-icon {
    color: #707070;
  }

  &:hover .cancel-icon {
    cursor: pointer;
  }
`;

const Header = styled(Grid)`
  min-height: 61px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  gap: 0.25rem;
  flex-shrink: 0;
`;

const InfoHeading = styled(Typography)`
  text-align: center;
  line-height: 1.1;
  margin: 0;
`;

const InfoTitle = styled(Typography)`
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
`;

const GroupAdminList = styled.ul`
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  list-style: none;
  flex-grow: 1;
  overflow: auto;
`;

const GroupAdminListItem = styled.li`
  border-bottom: 1px solid #ddd;
  padding: 1em;
  min-height: 64px;

  .danger: hover {
    color: #ca0b00;
  }
`;

const ItemTitle = styled.span`
  color: ${({ color }) => color || 'black'};
  min-height: 35px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-weight: 500;
  font-size: 16px;
`;

const MemberList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -1em;
`;

const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -1em;
`;

export default function GroupInfoPanel({
  chat,
  open,
  isAdmin,
  isOwner,
  isWarRoom,
  isCurrentCall,
  onClose,
  leaveGroup,
  makeWarRoom,
}) {
  const dispatch = useDispatch();
  const client = getStreamChatClient();
  const groupMembers = useSelector(selectGroupMembers);
  const attachments = useSelector(selectAttachments);
  const hasCall = useSelector((state) =>
    selectHasCall(state, { chatId: chat.cid })
  );
  const [membersOpen, setMembersOpen] = useState(true);
  const [attchmentsOpen, setAttchmentsOpen] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const groupMemberUUIDs = groupMembers.map((u) => u.userProperties.uuid);
  const [recipients, setRecipients] = useState([]);
  const disableBtn = Boolean(recipients.length < 1);

  const handleChangeRecipients = (value) => {
    setRecipients(value);
  };

  const addUsers = () => {
    if (recipients) {
      dispatch(
        Chats.addUsers(recipients.map((u) => u.userProperties.uuid))
      ).then(() => {
        setAddUserOpen(false);
        setRecipients([]);
      });
      const userTags = recipients.map((u) => u.email);
      const userTagsString = userTags.join(' ');
      if (userTags.length === 1) {
        dispatch(
          Messages.sendMessage(
            `${userTagsString} has been added to the chat`,
            [],
            {}
          )
        );
      } else {
        dispatch(
          Messages.sendMessage(
            `${userTagsString} are now added to the chat`,
            [],
            {}
          )
        );
      }
    }
  };

  const deleteGroup = () => {
    if (isAdmin) {
      client.deleteChannels([chat.cid]);
    }
  };

  return (
    <Panel open={open}>
      <Header container>
        <InfoHeading variant="h5" component="h2">
          Info
        </InfoHeading>
        <InfoTitle variant="body" color="grey">
          {chat.data.name}
        </InfoTitle>
        <CloseButton onClick={onClose}>
          <SvgIcon>
            <Close />
          </SvgIcon>
        </CloseButton>
      </Header>
      <GroupAdminList>
        {chat.data.description && (
          <GroupAdminListItem>
            <ItemTitle>Description</ItemTitle>
            <div>{chat.data.description}</div>
          </GroupAdminListItem>
        )}
        <GroupAdminListItem>
          <ItemTitle onClick={() => setMembersOpen(!membersOpen)}>
            Members
            <span style={{ textAlign: 'right' }}>
              <SvgIcon>{membersOpen ? <ChevronUp /> : <ChevronDown />}</SvgIcon>
            </span>
          </ItemTitle>
          {membersOpen && (
            <div>
              <MemberList>
                {groupMembers.map((gm) => (
                  <GroupMember
                    key={gm.userProperties.uuid}
                    user={gm}
                    isAdmin={isAdmin}
                  />
                ))}
              </MemberList>
            </div>
          )}
        </GroupAdminListItem>
        {isAdmin && (
          <GroupAdminListItem>
            <ItemTitle onClick={() => setAddUserOpen(!addUserOpen)}>
              Add Users
              <SvgIcon sx={{ width: '24px' }}>
                {addUserOpen ? <ChevronUp /> : <ChevronDown />}
              </SvgIcon>
            </ItemTitle>
            {addUserOpen && (
              <div>
                <UserAutocomplete
                  value={recipients}
                  excludeCurrentUser
                  excludedUUIDs={groupMemberUUIDs}
                  onChange={handleChangeRecipients}
                  sx={{ flexGrow: 1 }}
                />
                <div style={{ textAlign: 'right', marginTop: '0.5em' }}>
                  <Tooltip
                    arrow
                    title={
                      disableBtn
                        ? 'Select at least one user to add to chat'
                        : `Add user${recipients.length > 1 ? 's' : ''}`
                    }
                    placement="left"
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addUsers}
                        disabled={disableBtn}
                      >
                        Add
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
          </GroupAdminListItem>
        )}
        <GroupAdminListItem>
          <ItemTitle onClick={() => setAttchmentsOpen(!attchmentsOpen)}>
            Files
            <span style={{ textAlign: 'right' }}>
              <SvgIcon>
                {attchmentsOpen ? <ChevronUp /> : <ChevronDown />}
              </SvgIcon>
            </span>
          </ItemTitle>
          {attchmentsOpen && (
            <FileList>
              {attachments.map((a) => (
                <Attachment key={a.id} file={a} />
              ))}
            </FileList>
          )}
        </GroupAdminListItem>
        {isAdmin && !isWarRoom && (
          <GroupAdminListItem>
            <ItemTitle onClick={makeWarRoom}>Make War Room</ItemTitle>
          </GroupAdminListItem>
        )}
        {chat.data.name !== 'Headquarters' && isOwner && (
          <GroupAdminListItem>
            <ItemTitle
              color={hasCall ? '#aaa' : '#d32f2f'}
              disabled={hasCall}
              onClick={() => {
                if (!hasCall) {
                  setDeleteOpen(true);
                }
              }}
            >
              Delete
            </ItemTitle>
            <ConfirmModal
              open={deleteOpen}
              confirmText="Delete"
              onClose={() => setDeleteOpen(false)}
              onConfirm={deleteGroup}
            >
              <p>{`Are you sure you want to delete this ${
                isWarRoom ? 'war room' : 'breakout room'
              }?`}</p>
              <p>
                This action will lose all messages and you will not be able to
                recover them.
              </p>
            </ConfirmModal>
          </GroupAdminListItem>
        )}
        {chat.data.name !== 'Headquarters' && (
          <GroupAdminListItem className="lastElement">
            <ItemTitle
              color={isCurrentCall ? '#aaa' : 'black'}
              disabled={hasCall}
              onClick={() => {
                if (!isCurrentCall) {
                  leaveGroup();
                }
              }}
            >
              Leave
            </ItemTitle>
          </GroupAdminListItem>
        )}
      </GroupAdminList>
    </Panel>
  );
}
