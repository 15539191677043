import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

import Enterprise from '../../store/org/orgActions';
import Playbooks from '../../store/playbook/playbookActions';
import { selectOrgsForSharePlaybooks } from '../../store/org/orgSelectors';
import { selectPlaybooksWithCategory } from '../../store/playbook/playbookSelectors';

import BasicModal from '../BasicModal';
import TransferList from '../TransferList';

const Chip = styled(MuiChip)`
  height: 22px;
`;

const ALL_CATEGORIES = 'All';

const SharePlaybooksModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const orgs = useSelector(selectOrgsForSharePlaybooks);
  const playbooks = useSelector(selectPlaybooksWithCategory);
  const [error, setError] = useState('');
  const [orgIds, setOrgIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(ALL_CATEGORIES);
  const [selectedPlaybooks, setSelectedPlaybooks] = useState([]);

  const orgsById = orgs.reduce(
    (acc, org) => ({
      ...acc,
      [org.value]: org.label,
    }),
    {}
  );
  const playbookCategories = Array.from(
    new Set(playbooks.map((item) => item.categoryName))
  ).sort();
  const shareDisabled = loading || !selectedPlaybooks.length || !orgIds.length;

  const handleClose = (result) => {
    onClose(result);
    setOrgIds([]);
    setSelectedPlaybooks([]);
  };

  const handleChangeOrganization = (e) => {
    const { value } = e.target;
    setOrgIds(typeof value === 'string' ? value.split(',') : value);
  };

  const handleShare = () => {
    const playbookCount = selectedPlaybooks.length;
    const invalidOrgs = orgs.filter(
      (o) => orgIds.includes(o.value) && !o.validate(playbookCount)
    );
    if (!!invalidOrgs.length) {
      return setError(
        `The ${invalidOrgs.map((o) => o.label).join('/')} organization${
          invalidOrgs.length === 1 ? '' : 's'
        } cannot receive ${playbookCount} playbook${
          playbookCount === 1 ? '' : 's'
        }.`
      );
    }

    const playbookIds = selectedPlaybooks.map((p) => p.value);

    setError('');
    setLoading(true);
    return dispatch(Playbooks.share(playbookIds, orgIds))
      .then(() =>
        handleClose({
          severity: 'success',
          message: `Playbook${
            playbookIds.length === 1 ? '' : 's'
          } shared successfully.`,
        })
      )
      .catch(() =>
        handleClose({
          severity: 'error',
          message: 'Some errors occurred while sharing playbooks.',
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      dispatch(Enterprise.fetchChildOrgs()).finally(() => setLoading(false));
    }
  }, [open, dispatch]);

  return (
    <BasicModal
      open={open}
      buttonText="Share"
      title="Share Playbooks"
      onClickButton={handleShare}
      onClose={() => handleClose()}
      buttonDisabled={shareDisabled}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        <Typography sx={{ lineHeight: 1 }}>Category:</Typography>
        <Select
          displayEmpty
          value={filter}
          variant="standard"
          defaultValue={ALL_CATEGORIES}
          onChange={(e) => setFilter(e.target.value)}
          sx={{ minWidth: 175, '& .MuiSelect-select': { py: 0.25 } }}
        >
          <MenuItem value={ALL_CATEGORIES}>{ALL_CATEGORIES}</MenuItem>
          {playbookCategories.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <TransferList
        items={playbooks}
        value={selectedPlaybooks}
        leftListTitle="Available Playbooks"
        rightListTitle="Selected Playbooks"
        onChange={(items) => setSelectedPlaybooks(items)}
        itemFilter={(item) =>
          filter === 'All' ? true : item.categoryName === filter
        }
      />
      <Divider />
      <Typography>
        Select the organization(s) that will receive the playbooks:
      </Typography>
      <FormControl>
        <InputLabel size="small" id="organizations-label">
          Organizations
        </InputLabel>
        <Select
          multiple
          size="small"
          value={orgIds}
          disabled={loading}
          id="organizations"
          labelId="organizations-label"
          onChange={handleChangeOrganization}
          input={
            <OutlinedInput id="select-organizations" label="Organizations" />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={orgsById[value]} />
              ))}
            </Box>
          )}
        >
          {orgs.map(({ label, value, remainingCount }) => (
            <MenuItem
              key={value}
              value={value}
              sx={{
                fontWeight: orgIds.includes(value) ? 'bold' : 'medium',
              }}
            >
              {label}
              <Typography
                color="grey"
                variant="caption"
                sx={{ ml: 1, lineHeight: 1 }}
              >
                ({remainingCount} playbook{remainingCount === 1 ? '' : 's'}{' '}
                available)
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          The organizations shown have playbooks available within their
          subscription.
        </FormHelperText>
      </FormControl>
      {!!error && <Typography color="error">{error}</Typography>}
    </BasicModal>
  );
};

export default SharePlaybooksModal;
