import Papa from 'papaparse';

/**
 * Parse the CSV file and handle the results.
 * @param {File} file - The file to parse, obtained by input type=file.
 * @returns {Promise} - A promise that resolves to the parsed CSV data.
 */
export const parseCsvFile = (file) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => resolve(results.data),
      error: (err) => reject(err),
    });
  });
