import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { handlePaste } from '../../lib/input';
import { MAX_LENGTH } from '../../configs/validation';
import { nameRegex, emailRegex } from '../../utils/formUtils';

import BasicModal from '../BasicModal';
import PhoneInput from '../PhoneInput';

const UserModal = ({
  open,
  onClose,
  user,
  onSave,
  onSaveFulfilled,
  onSaveRejected,
}) => {
  const editing = !!user;
  const [loading, setLoading] = useState(false);

  const initialValues = {
    firstName: editing ? user?.firstName : '',
    lastName: editing ? user?.lastName : '',
    email: editing ? user?.email : '',
    phoneNumber: editing ? user?.phoneNumber : '',
    location: editing ? user?.location : '',
    department: editing ? user?.department : '',
    role: editing ? user?.role?.toLowerCase() : 'user',
    type: editing ? user?.type?.toLowerCase() : 'internal',
  };
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values: initialValues,
    defaultValues: initialValues,
  });

  const title = `${editing ? 'Edit' : 'Add'} User`;

  const createAlphabeticalRules = (required = false) => ({
    required: required ? 'Required' : undefined,
    pattern: {
      value: nameRegex,
      message: 'Only alphabetical characters and spaces are allowed.',
    },
    validate: (value) =>
      value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSave = (data) => {
    setLoading(true);
    return onSave(data)
      .then(() => {
        if (!!onSaveFulfilled) {
          onSaveFulfilled();
        }
        handleClose();
      })
      .catch(({ response }) => {
        console.error(response);
        if (!!onSaveRejected) {
          onSaveRejected(response?.data);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      title={title}
      buttonText={title}
      onClose={handleClose}
      onClickButton={handleSubmit(handleSave)}
      sx={{ minWidth: '32rem' }}
    >
      <Controller
        name="firstName"
        control={control}
        rules={createAlphabeticalRules(true)}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              autoFocus
              size="small"
              label="First Name"
              disabled={loading}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
            />
          );
        }}
      />
      <Controller
        name="lastName"
        control={control}
        rules={createAlphabeticalRules(true)}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              size="small"
              label="Last Name"
              disabled={loading}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
            />
          );
        }}
      />
      <Controller
        name="email"
        control={control}
        rules={
          editing
            ? undefined
            : {
                required: 'Required',
                pattern: {
                  value: emailRegex,
                  message: 'Enter a valid email address.',
                },
                validate: (value) =>
                  value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
              }
        }
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              size="small"
              disabled={loading || editing}
              label="Email Address"
              error={!!errors?.email}
              helperText={errors?.email?.message}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
            />
          );
        }}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <PhoneInput
            {...field}
            size="small"
            variant="outlined"
            disabled={loading}
            label="Phone Number"
          />
        )}
      />
      <Stack direction="row" gap={1}>
        <Controller
          name="location"
          control={control}
          rules={createAlphabeticalRules()}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                {...field}
                size="small"
                label="Location"
                disabled={loading}
                error={!!errors?.location}
                helperText={errors?.location?.message}
                onPaste={(e) => handlePaste(e, onChange)}
                inputProps={{ maxLength: MAX_LENGTH.value }}
                sx={{ flex: 1 }}
              />
            );
          }}
        />
        <Controller
          name="department"
          control={control}
          rules={createAlphabeticalRules()}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                {...field}
                size="small"
                label="Department"
                disabled={loading}
                error={!!errors?.department}
                helperText={errors?.department?.message}
                onPaste={(e) => handlePaste(e, onChange)}
                inputProps={{ maxLength: MAX_LENGTH.value }}
                sx={{ flex: 1 }}
              />
            );
          }}
        />
      </Stack>
      <Stack direction="row" gap={1}>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <FormControl size="small" disabled={loading} sx={{ flex: 1 }}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                {...field}
                label="Role"
                autoComplete="off"
                labelId="role-label"
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl size="small" disabled={loading} sx={{ flex: 1 }}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                {...field}
                label="Type"
                autoComplete="off"
                labelId="type-label"
              >
                <MenuItem value="internal">Internal</MenuItem>
                <MenuItem value="external">External</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    </BasicModal>
  );
};

export default UserModal;
