import BasicModal from '../BasicModal';
import ProgressBar from '../ProgressBar';

const DownloadModal = ({ open, label, progress, onClose }) => (
  <BasicModal
    open={open}
    title={label}
    onClose={onClose}
    footerComponent={<></>}
    footerStyles={{ pb: 0.5 }}
  >
    <ProgressBar value={progress} />
  </BasicModal>
);

export default DownloadModal;
