import { TASK_PRIORITY } from '../incident/incidentTypes';

export const PLAYBOOK_ITEM_TYPES = {
  TASK: 'TASK',
  HEADING: 'HEADING',
};

export const TEMPORARY_PLAYBOOK_ITEM_KEYS = {
  id: 'id', // This is temporary in the case of an id generated for the dnd of a newly created item.
  isNew: 'isNew', // This is temporary for flagging specific handling of a newly created item.
};

export const SANITIZED_ITEM_KEYS_FOR_CREATE = [
  TEMPORARY_PLAYBOOK_ITEM_KEYS.id,
  TEMPORARY_PLAYBOOK_ITEM_KEYS.isNew,
];

export const SANITIZED_ITEM_KEYS_FOR_EDIT = [
  TEMPORARY_PLAYBOOK_ITEM_KEYS.isNew,
];

export const PLAYBOOK_ITEM_TEMPLATES = {
  [PLAYBOOK_ITEM_TYPES.TASK]: {
    type: PLAYBOOK_ITEM_TYPES.TASK,
    title: '',
    description: '',
    priority: TASK_PRIORITY.MEDIUM,
    [TEMPORARY_PLAYBOOK_ITEM_KEYS.isNew]: true,
  },
  [PLAYBOOK_ITEM_TYPES.HEADING]: {
    type: PLAYBOOK_ITEM_TYPES.HEADING,
    title: null,
    description: '',
    [TEMPORARY_PLAYBOOK_ITEM_KEYS.isNew]: true,
  },
};

export const shouldTrimProperty = (key, value) =>
  (key === 'title' || key === 'description') && value !== null;

const validateHeading = (values) =>
  !values?.description ? { description: 'Heading cannot be empty.' } : null;

const validateTask = (values) =>
  !values?.title ? { title: 'Title is required.' } : null;

export const validatePlaybookItem = (type, values) => {
  if (type === PLAYBOOK_ITEM_TYPES.HEADING) {
    return validateHeading(values);
  } else if (type === PLAYBOOK_ITEM_TYPES.TASK) {
    return validateTask(values);
  }
};
