import { createSelector } from 'reselect';

import {
  PAGES_WITH_TABS,
  DEPENDENT_FEATURES,
  DEFAULT_EDITABLE_TIME_LIMIT,
  TWO_FACTOR_VERIFICATION_TYPES,
} from '../../configs';
import { determineAccess } from '../../utils/configUtils';
import { selectCurrentUserRole } from '../auth/authSelectors';

const selectOrgState = (state) => state.org;

export const selectRegistry = createSelector(
  selectOrgState,
  (orgState) => orgState.registry
);

export const selectConfig = createSelector(
  selectOrgState,
  (orgState) => orgState.config || {}
);

export const selectStreamApiKey = createSelector(selectOrgState, (orgState) => {
  if (!orgState.config) return null;
  return orgState.config.streamApiKey;
});

export const selectToolId = createSelector(
  selectOrgState,
  (orgState) => orgState.manageAdminToolId
);

export const selectHasRegistry = createSelector(
  selectOrgState,
  (orgState) =>
    Object.keys(orgState.registry).length !== 0 && orgState.registry.code
);

export const selectHasConfig = createSelector(
  selectOrgState,
  (orgState) => orgState.config && Object.keys(orgState.config).length > 0
);

export const selectOrgCode = createSelector(
  selectRegistry,
  (registry) => registry.code
);

export const selectTOTPEnabled = createSelector(
  selectOrgState,
  (orgState) =>
    orgState.auth2fa &&
    orgState.auth2faType === TWO_FACTOR_VERIFICATION_TYPES.TOTP
);

export const selectOrgConfigFileSize = createSelector(
  selectOrgState,
  (orgState) => orgState.maxFileSize
);

export const selectOrgConfigAllowedFormats = createSelector(
  selectOrgState,
  (orgState) => orgState.allowedFileExtensions
);

export const selectEnable2faOnSfs = createSelector(
  selectOrgState,
  (orgState) => orgState.enable2faOnSfs
);

export const selectAllowMultipleOngoingEvents = createSelector(
  selectOrgState,
  (orgState) => orgState.config.multiIncident
);

export const selectMessageHistoryEditTimeout = createSelector(
  selectOrgState,
  (orgState) =>
    orgState.messageHistoryEditTimeout || DEFAULT_EDITABLE_TIME_LIMIT
);

export const selectEnabledDialIn = createSelector(
  selectOrgState,
  (orgState) => orgState.dialInEnabled
);

export const selectPlaybooksEnabled = createSelector(
  selectOrgState,
  (orgState) => orgState.playbooksEnabled
);

export const selectMaxPlaybooksAllowed = createSelector(
  selectOrgState,
  (orgState) => orgState.maxPlaybooksAllowed
);

export const selectBadges = createSelector(
  selectOrgState,
  (orgState) => orgState.badges || []
);

export const selectGroupsEnabled = createSelector(
  selectOrgState,
  (orgState) => orgState.groupsEnabled
);

export const selectPlaybooksSharingEnabled = createSelector(
  selectOrgState,
  (orgState) => orgState.playbooksSharingEnabled
);

export const selectChildOrgs = createSelector(
  selectOrgState,
  (orgState) => orgState.childOrgs || []
);

export const selectOrgsForSharePlaybooks = createSelector(
  selectChildOrgs,
  selectRegistry,
  (childOrgs, registry) => {
    const getPlaybooksRemaining = (o) =>
      Math.abs(Number(o.maxTemplates) - Number(o.currentTemplates));

    return childOrgs
      .filter(
        (o) =>
          o.enterpriseCode !== registry.code &&
          o.playbookEnabled &&
          getPlaybooksRemaining(o) > 0
      )
      .map((o) => {
        const remainingCount = getPlaybooksRemaining(o);
        return {
          remainingCount,
          value: o.enterpriseId,
          label: o.enterpriseCode,
          validate: (playbookCount) => playbookCount <= remainingCount,
        };
      });
  }
);

export const selectDependentFeatureAccess = createSelector(
  selectPlaybooksEnabled,
  selectGroupsEnabled,
  (playbooksEnabled, groupsEnabled) => ({
    [DEPENDENT_FEATURES.playbooks]: playbooksEnabled,
    [DEPENDENT_FEATURES.groupAdmin]: groupsEnabled,
  })
);

export const selectEnabledFeatures = createSelector(
  selectDependentFeatureAccess,
  (dependentFeatureAccess) =>
    Object.entries(dependentFeatureAccess)
      .filter(([, enabled]) => enabled)
      .map(([feature]) => feature)
);

export const selectPageTabs = createSelector(
  (_, pathname) => pathname,
  selectCurrentUserRole,
  selectEnabledFeatures,
  (pathname, role, enabledFeatures) => {
    if (!pathname || !PAGES_WITH_TABS?.[pathname]) {
      return [];
    }

    return PAGES_WITH_TABS[pathname]
      .map((t) => ({
        ...t,
        enabled: determineAccess(role, enabledFeatures, t?.access || {}),
      }))
      .filter((t) => t.enabled);
  }
);
