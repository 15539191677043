import MuiChip from '@mui/material/Chip';
import MuiSwitch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const Chip = styled(MuiChip)`
  height: 28px;
  font-size: 14px;
  margin: 5px 10px 5px 0;

  .MuiChip-label {
    padding-left: 6px;
  }
  .MuiChip-avatar {
    width: 46px;
    height: 24px;
    margin-left: 0;
    min-width: 46px;
    padding: 6px 8px;
    background-color: transparent;
  }
`;

const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: 46,
  height: 24,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(16px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="12" height="12" fill="${encodeURIComponent(
          '#fff'
        )}" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path d="m 0.85714245,6.8571423 c 0,-0.9467741 0.76751205,-1.714286 1.71428585,-1.714286 V 3.4285705 c 0,-4.5714273 6.8571433,-4.5714273 6.8571433,0 v 1.7142859 c 0.9467754,0 1.7142864,0.7675119 1.7142864,1.7142859 V 10.285714 C 11.142858,11.232488 10.375347,12 9.4285715,12 H 2.5714283 C 1.6246545,12 0.85714245,11.232488 0.85714245,10.285714 Z M 6.0000001,1.7142845 c -0.9467742,0 -1.7142859,0.7675115 -1.7142859,1.714286 V 5.1428564 H 7.714286 V 3.4285705 c 0,-0.9467745 -0.7675117,-1.714286 -1.7142859,-1.714286 z" style="stroke-width:1.71429" /></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey.light,
    width: 22,
    height: 22,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg width="12" height="12" fill="${encodeURIComponent(
        theme.palette.grey.dark
      )}" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path d="m 0.85714245,6.8571423 c 0,-0.9467741 0.76751205,-1.714286 1.71428585,-1.714286 V 3.4285705 c 0,-4.5714273 6.8571433,-4.5714273 6.8571433,0 v 1.7142859 c 0.9467754,0 1.7142864,0.7675119 1.7142864,1.7142859 V 10.285714 C 11.142858,11.232488 10.375347,12 9.4285715,12 H 2.5714283 C 1.6246545,12 0.85714245,11.232488 0.85714245,10.285714 Z M 6.0000001,1.7142845 c -0.9467742,0 -1.7142859,0.7675115 -1.7142859,1.714286 V 5.1428564 H 7.714286 V 3.4285705 c 0,-0.9467745 -0.7675117,-1.714286 -1.7142859,-1.714286 z" style="stroke-width:1.71429" /></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
  },
}));

const SwitchChip = ({ label, checked, onToggle, onDelete }) => (
  <Chip
    label={label}
    color="primary"
    onDelete={onDelete}
    avatar={
      <Switch
        checked={checked}
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={(e) => onToggle(e.target.checked)}
      />
    }
  />
);

export default SwitchChip;
