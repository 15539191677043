import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import { MAX_LENGTH } from '../configs/validation';

import SearchIcon from '../assets/icons/search.svg?react';
import ClearIcon from '../assets/icons/close.svg?react';

const TextField = styled(MuiTextField)`
  width: 100%;
  margin: 0 auto;
  & .MuiInputBase-root {
    padding-right: 0;
  }
  & .MuiInputBase-input {
    padding: 10px 0 10px 14px;
  }
  & .MuiDivider-root {
    margin: 4px;
    height: 24px;
  }
  & .MuiIconButton-root {
    width: 1.5em;
    height: 1.5em;
    padding: 8px;
  }
`;

const SearchInput = ({
  inputRef,
  onChange,
  onClear,
  initialValue = '',
  ...props
}) => (
  <TextField
    inputRef={inputRef}
    autoFocus
    size="small"
    autoComplete="off"
    aria-label="Search..."
    placeholder="Search..."
    defaultValue={initialValue}
    onChange={(e) => onChange(e.target.value)}
    inputProps={{ maxLength: MAX_LENGTH.value }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          {!!inputRef?.current?.value && (
            <IconButton
              size="large"
              color="primary"
              aria-label="directions"
              onClick={onClear}
            >
              <SvgIcon component={ClearIcon} />
            </IconButton>
          )}
          <Divider orientation="vertical" />
          <IconButton
            size="large"
            color="primary"
            disabled={true}
            aria-label="directions"
          >
            <SvgIcon component={SearchIcon} />
          </IconButton>
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export default SearchInput;
