import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import MuiDivider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { NavLink as Link } from 'react-router-dom';

import {
  EVENT_TYPES,
  EVENT_TYPE_LABELS,
} from '../../store/incident/incidentTypes';
import { formatTimestamp } from '../../lib/date';
import { getCachedProfileImageUrl } from '../../utils/imageUtils';

import Avatar from '../Avatar';
import FireDrillIcon from '../../assets/icons/cast-outline.svg?react';
import ResolvedIcon from '../../assets/icons/success-circle.svg?react';
import OngoingIncidentIcon from '../../assets/icons/warning-triangle.svg?react';

const getColor = ({ theme, type, resolved }) =>
  resolved === 'true'
    ? theme.palette.success.light
    : type === EVENT_TYPES.INCIDENT
      ? theme.palette.error.main
      : type === EVENT_TYPES.TABLETOP_EXERCISE
        ? theme.palette.warning.main
        : '#d9d9d9';

const Item = styled(Link)`
  color: #333;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  border-top: 0.25rem solid
    ${({ theme, type, resolved }) => getColor({ theme, type, resolved })};
  transition: background-color 0.2s ease-in-out 0s;
  overflow: hidden;
  &:hover,
  &:active {
    color: #333;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const Divider = styled(MuiDivider)`
  margin: 0 0.75rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: 0.75rem;
`;

const TitleContainer = styled.div`
  flex-grow: 1;
`;

const HeaderLabels = styled(Stack)`
  flex-shrink: 0;
`;

const EventType = styled(Chip)`
  height: 24px;
  span {
    line-height: 1;
  }
`;

const Icon = styled(SvgIcon)`
  color: ${({ theme, type, resolved }) => getColor({ theme, type, resolved })};
`;

const Body = styled.div`
  padding: 0.5rem 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  div {
    min-height: 60px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    & > * {
      font-size: 1rem;
      line-height: 1.25;
      font-weight: 300;
    }
  }
`;

const Footer = styled(Stack)`
  padding: 0.5rem 0.75rem;
  address {
    font-size: 1rem;
    font-style: normal;
  }
  time,
  span {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const FeedItem = ({
  id,
  type,
  title,
  fullText,
  previewText,
  createTimestamp,
  createdBy,
  resolveTimestamp,
  activities,
  checkIns,
  onClick,
}) => {
  const resolved = !!resolveTimestamp;
  const icon = resolved
    ? ResolvedIcon
    : type === EVENT_TYPES.INCIDENT
      ? OngoingIncidentIcon
      : type === EVENT_TYPES.TABLETOP_EXERCISE
        ? FireDrillIcon
        : ResolvedIcon;
  const typeColor = type === EVENT_TYPES.INCIDENT ? 'error' : 'warning';
  const activityCountLabel = `${activities.length} Activit${
    activities.length === 1 ? 'y' : 'ies'
  }`;
  const checkInCountLabel = `${checkIns.length} Check In${
    checkIns.length === 1 ? '' : 's'
  }`;

  return (
    <Item
      to={`/incidents/${id}`}
      type={type}
      resolved={resolved.toString()}
      onClick={onClick}
    >
      <Header>
        <TitleContainer>
          <Typography component="h2" variant="h4">
            {title}
          </Typography>
        </TitleContainer>
        <HeaderLabels direction="row" alignItems="center" spacing={1.5}>
          <EventType color={typeColor} label={EVENT_TYPE_LABELS[type]} />
          <Icon
            component={icon}
            type={type}
            resolved={resolved.toString()}
            sx={{ '& .MuiSvgIcon-root': { fontSize: '1.75rem' } }}
          />
        </HeaderLabels>
      </Header>
      <Divider />
      <Body>
        <div>
          <Typography paragraph mb={0}>
            {!!previewText
              ? previewText
              : !!fullText
                ? fullText
                : 'No description to show.'}
          </Typography>
        </div>
      </Body>
      <Divider />
      <Footer
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Avatar
            profileImageUrl={getCachedProfileImageUrl(createdBy)}
            firstName={createdBy.firstName}
            lastName={createdBy.lastName}
            fontSize="1rem"
            sx={{ width: '2rem', height: '2rem' }}
          />
          <Typography component="address">
            {createdBy.firstName} {createdBy.lastName}
          </Typography>
          <Typography component="time">
            {formatTimestamp(createTimestamp)}
          </Typography>
        </Stack>
        <Typography component="span">
          {type === EVENT_TYPES.TABLETOP_EXERCISE
            ? checkInCountLabel
            : activityCountLabel}
        </Typography>
      </Footer>
    </Item>
  );
};

export default FeedItem;
