import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';

import { PAGES } from '../../configs';
import {
  selectItemList,
  selectFetchingItems,
  selectInheritedFolderProperties,
} from '../../store/storage/storageSelectors';
import { formatFileSize, getItemTypeLabel } from '../../lib/files';

import { FileIcon, FolderIcon } from './FileIcon';
import InfoIcon from '../../assets/icons/info-outline.svg?react';
import RootFolderIcon from '../../assets/icons/filetype-folder.svg?react';

const DataGrid = styled(MuiDataGrid)`
  border: none !important;
  .MuiDataGrid-columnHeaders {
    background-color: #fafafa;
  }
  .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
  .MuiDataGrid-row {
    cursor: pointer;
  }
  .MuiDataGrid-cell--textCenter .MuiDataGrid-cellContent {
    text-align: center;
  }
`;

const common = `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledLoadingOverlay = styled(Box)`
  ${common}
  background-color: ${({ theme }) => theme.palette.white.main};
  position: relative;
  z-index: 1;
`;
const StyledNoRowsOverlay = styled(Box)`
  ${common}
  flex-direction: column;
  gap: 1rem;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 999;
`;

const ItemList = ({
  onClickItem,
  onClickInfo,
  onContextMenu,
  onClickSeeAll,
}) => {
  const rows = useSelector(selectItemList);
  const inheritedFolderProperties = useSelector(
    selectInheritedFolderProperties
  );
  const fetchingItems = useSelector(selectFetchingItems);

  const handleClickRow = useCallback(
    ({ row }) => onClickItem(row),
    [onClickItem]
  );

  const handleClickInfo = useCallback(
    (item) => (e) => {
      e.stopPropagation();
      onClickInfo(item);
    },
    [onClickInfo]
  );

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      const id = e.currentTarget.getAttribute('data-id');
      onContextMenu(
        e.clientX,
        e.clientY,
        rows.find((r) => r.id === Number(id))
      );
    },
    [onContextMenu, rows]
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'icon',
              align: 'center',
              headerName: '',
              sortable: false,
              flex: 0.75,
              renderCell: ({ row: item }) => (
                <SvgIcon sx={{ fontSize: '1.75em' }}>
                  {item.isFolder ? (
                    <FolderIcon
                      type={
                        !!inheritedFolderProperties
                          ? inheritedFolderProperties.folderType
                          : item.folderType
                      }
                    />
                  ) : (
                    <FileIcon extension={item.extension} />
                  )}
                </SvgIcon>
              ),
            },
            {
              field: 'name',
              headerName: 'Name',
              headerAlign: 'left',
              align: 'left',
              flex: 3,
            },
            {
              field: 'type',
              headerName: 'Type',
              headerAlign: 'center',
              align: 'center',
              flex: 1,
              valueGetter: ({ row }) =>
                getItemTypeLabel(row, inheritedFolderProperties),
            },
            {
              field: 'size',
              headerName: 'Size',
              headerAlign: 'center',
              align: 'center',
              flex: 1,
              valueFormatter: ({ value }) =>
                value ? formatFileSize(value, 1) : '\u2015',
            },
            {
              field: 'createdByName',
              headerName: 'Created By',
              headerAlign: 'center',
              align: 'center',
              flex: 1.5,
            },
            {
              field: 'updatedAt',
              headerName: 'Last Updated',
              headerAlign: 'center',
              align: 'center',
              flex: 1.5,
            },
            {
              field: 'info',
              headerName: 'Info',
              headerAlign: 'center',
              align: 'center',
              flex: 0.75,
              renderCell: ({ row: item }) => (
                <IconButton onClick={handleClickInfo(item)}>
                  <SvgIcon size="small" component={InfoIcon} />
                </IconButton>
              ),
            },
          ]}
          pageSize={100} // This is the max allowed by the free DataGrid component.
          rowHeight={45}
          headerHeight={45}
          loading={fetchingItems}
          rowsPerPageOptions={[100]}
          onRowClick={handleClickRow}
          hideFooter={rows.length <= 100}
          disableColumnMenu
          disableColumnFilter
          disableMultipleColumnsSorting
          components={{
            LoadingOverlay: () => (
              <StyledLoadingOverlay>
                <CircularProgress />
              </StyledLoadingOverlay>
            ),
            NoRowsOverlay: () => (
              <StyledNoRowsOverlay>
                <Typography variant="h5">
                  No files or folders to show.
                </Typography>
                <Link
                  underline="hover"
                  textAlign="center"
                  component={RouterLink}
                  onClick={onClickSeeAll}
                  to={PAGES.fileStorage.pathname}
                  sx={{
                    gap: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SvgIcon component={RootFolderIcon} fontSize="small" /> See
                  All
                </Link>
              </StyledNoRowsOverlay>
            ),
          }}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ItemList;
