import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { styled } from '@mui/material/styles';
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  selectIncidentList,
  selectIncidentFetching,
  selectHasActiveEvent,
  selectCurrentPage,
  selectHasMoreItems,
} from '../../store/incident/incidentSelectors';
import useDebounce from '../../lib/hooks/useDebounce';
import Incidents from '../../store/incident/incidentActions';
import { selectAllowMultipleOngoingEvents } from '../../store/org/orgSelectors';

import FeedItem from './FeedItem';
import SearchInput from '../SearchInput';
import Plus from '../../assets/icons/plus.svg?react';

const Screen = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #ddd;
`;

const SearchBarContainer = styled.header`
  flex-shrink: 0;
  width: 70%;
  max-width: 1200px;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.palette.white.main};
`;

const CreateButtonContainer = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  & > .MuiFab-root:hover {
    color: #fff;
  }
`;

const CreateButton = styled(Fab)`
  &:hover {
    color: ${({ theme }) => theme.palette.background.default};
  }
`;

const FeedSection = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

const FeedContainer = styled(Box)`
  width: 70%;
  max-width: 1200px;
`;

const FeedItemGrid = styled.div`
  display: grid;
  gap: 1.75rem;
`;

const Feed = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const searchInputRef = useRef('');
  const list = useSelector(selectIncidentList);
  const currentPage = useSelector(selectCurrentPage);
  const hasMoreItems = useSelector(selectHasMoreItems);
  const isFetching = useSelector(selectIncidentFetching);
  const hasActiveEvent = useSelector(selectHasActiveEvent);
  const allowMultipleOngoingEvents = useSelector(
    selectAllowMultipleOngoingEvents
  );
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 250);
  const showCreateButton = allowMultipleOngoingEvents ? true : !hasActiveEvent;

  useEffect(() => {
    if (!!debouncedSearchTerm.length && debouncedSearchTerm.length >= 3) {
      dispatch(Incidents.search(debouncedSearchTerm));
    } else {
      dispatch(Incidents.fetch());
    }
  }, [debouncedSearchTerm, dispatch]);

  const handleClickFeedItem = (item) => dispatch(Incidents.setItem(item));

  const handleClearSearch = () => {
    searchInputRef.current.value = '';
    setSearchTerm('');
  };

  const handleScroll = (e) => {
    const scrollDistance = e.target.scrollHeight - e.target.scrollTop;
    const atBottom = Math.abs(scrollDistance - e.target.clientHeight) < 5;

    if (atBottom && !isFetching && hasMoreItems) {
      dispatch(
        !!debouncedSearchTerm.length && debouncedSearchTerm.length >= 3
          ? Incidents.search(debouncedSearchTerm, currentPage + 1)
          : Incidents.fetch(currentPage + 1)
      );
    }
  };

  return (
    <>
      <Screen>
        <SearchBarContainer>
          <SearchInput
            aria-label="Find incident or tabletop exercise..."
            placeholder="Find incident or tabletop exercise..."
            inputRef={searchInputRef}
            onChange={setSearchTerm}
            onClear={handleClearSearch}
          />
        </SearchBarContainer>
        <FeedSection ref={scrollRef} onScroll={handleScroll}>
          <FeedContainer>
            <FeedItemGrid>
              {list.map((item) => (
                <FeedItem
                  key={item.id}
                  onClick={() => handleClickFeedItem(item)}
                  {...item}
                />
              ))}
            </FeedItemGrid>
            {isFetching && (
              <Stack alignItems="center" sx={{ my: 4 }}>
                <PulseLoader color="#042f42" />
              </Stack>
            )}
            {!hasMoreItems && (
              <Typography align="center" sx={{ my: 4 }}>
                You&apos;ve reached the end of the incident feed.
              </Typography>
            )}
          </FeedContainer>
        </FeedSection>
      </Screen>
      {showCreateButton && (
        <CreateButtonContainer>
          <Tooltip
            title="Create Incident"
            placement="left"
            disableInteractive
            arrow
          >
            <span>
              <CreateButton
                color="primary"
                component={Link}
                to="/incidents/create"
                aria-label="Create Incident"
              >
                <SvgIcon
                  component={Plus}
                  style={{
                    width: '2rem',
                    height: '2rem',
                    fontSize: '2rem',
                  }}
                />
              </CreateButton>
            </span>
          </Tooltip>
        </CreateButtonContainer>
      )}
    </>
  );
};

export default Feed;
