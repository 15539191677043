import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { Link, Navigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectHasConfig,
  selectHasRegistry,
} from '../../store/org/orgSelectors';
import { handlePaste } from '../../lib/input';
import Enterprise from '../../store/org/orgActions';
import { MAX_LENGTH } from '../../configs/validation';
import { resetAxiosConfig } from '../../lib/axiosConfig';
import Heartbeat from '../../store/heartbeat/heartbeatActions';
import { clearLocalStorage } from '../../utils/localStorageUtils';
import { promptForNotifications } from '../../utils/browserUtils';
import { resetStreamChatClient } from '../../lib/streamChatClient';

import UnauthenticatedLayout from '../UnauthenticatedLayout';
import GlobeIcon from '../../assets/icons/globe.svg?react';

export default function EnterpriseSetup() {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const hasConfig = useSelector(selectHasConfig);
  const hasRegistry = useSelector(selectHasRegistry);

  const code = watch('code');

  const handleSetCode = (data) => {
    const orgCode = data.code.trim().toLowerCase();

    promptForNotifications();

    setError('');
    dispatch(Enterprise.fetchRegistry(orgCode))
      .then((res) => {
        const errorMessage =
          'Unable to connect to enterprise. Try again or contact your administrator.';

        if (!res.value.data) {
          return setError(errorMessage);
        }
        dispatch(Enterprise.fetchConfig()).catch(() => setError(errorMessage));
      })
      .catch((err) =>
        setError(
          `${
            err.status === 404
              ? 'Unable to connect to enterprise.'
              : 'Error getting enterprise information.'
          } Try again or contact your administrator.`
        )
      );
  };

  useEffect(() => {
    // On logout, reset application state outside of Redux.
    if (!hasRegistry) {
      dispatch(Heartbeat.setIsReady(false));
      resetAxiosConfig();
      clearLocalStorage();
      resetStreamChatClient();
    }
  }, [hasRegistry, dispatch]);

  if (hasRegistry && hasConfig) {
    return <Navigate to="/login" />;
  }

  return (
    <UnauthenticatedLayout
      title="Enter your organization code"
      buttons={[
        {
          type: 'submit',
          disabled: !code,
          variant: 'contained',
          title: 'Set Organization Code',
        },
      ]}
      contentProps={{
        component: 'form',
        onSubmit: handleSubmit(handleSetCode),
      }}
    >
      <Controller
        name="code"
        control={control}
        rules={{
          required: 'Please enter an organization code.',
          validate: (value) =>
            value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              autoFocus
              fullWidth
              variant="standard"
              error={!!errors?.code}
              placeholder="Organization Code"
              helperText={errors?.code?.message}
              onPaste={(e) => handlePaste(e, onChange)}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon color="primary" component={GlobeIcon} />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
      <Stack alignItems="flex-start" alignSelf="flex-start" gap={0.5}>
        <Link to="/forgot-org-code">Forgot your organization code?</Link>
        <a
          href="https://www.shadowhq.io/contact"
          target="_blank"
          rel="noreferrer"
        >
          Don&apos;t have one?{' '}
          <span style={{ textDecoration: 'underline' }}>Contact us.</span>
        </a>
      </Stack>
      {!!error && (
        <Typography component="span" color="error">
          {error}
        </Typography>
      )}
    </UnauthenticatedLayout>
  );
}
