import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef } from 'react';

import Users from '../store/user/userActions';
import { getCachedProfileImageUrl } from '../utils/imageUtils';
import { selectAutocompleteUserList } from '../store/user/userSelectors';

import Avatar from './Avatar';

const ExternalLabel = styled.span`
  color: #777;
  line-height: 1;
  font-size: 0.75em;
`;

const Chip = styled(MuiChip)`
  height: 1.75rem;
  & .MuiChip-avatar {
    color: #fff;
  }
  & .MuiChip-label {
    margin-left: 0.1rem;
  }
`;

const UserAutocomplete = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      autoFocus,
      helperText,
      error = false,
      multiple = true,
      excludedUUIDs = [],
      excludeCurrentUser = false,
      textFieldProps = {
        size: 'small',
        variant: 'standard',
        InputProps: {
          disableUnderline: true,
        },
      },
      ...props
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const userList = useSelector((state) =>
      selectAutocompleteUserList(state, { excludedUUIDs, excludeCurrentUser })
    );

    const getOptionLabel = (option) => {
      if (!option?.firstName || !option?.lastName) {
        return option?.fullName;
      }
      return `${option.firstName} ${option.lastName}`;
    };

    useEffect(() => {
      setFetching(true);
      dispatch(Users.fetchUsers()).finally(() => setFetching(false));
    }, [dispatch]);

    return (
      <Autocomplete
        {...props}
        ref={ref}
        autoHighlight
        multiple={multiple}
        autoFocus={autoFocus}
        options={userList}
        value={value}
        disabled={disabled || fetching}
        getOptionLabel={getOptionLabel}
        onChange={(e, value) => onChange(value)}
        isOptionEqualToValue={(option, value) =>
          option.userProperties.uuid === value.userProperties.uuid
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Tooltip
              {...getTagProps({ index })}
              arrow
              disableInteractive
              key={option?.email}
              title={option?.email}
            >
              <Chip
                color="default"
                label={getOptionLabel(option)}
                avatar={
                  <Avatar
                    profileImageUrl={getCachedProfileImageUrl(option)}
                    firstName={option?.firstName}
                    lastName={option?.lastName}
                    fontSize="0.75rem"
                    size="smallest"
                  />
                }
              />
            </Tooltip>
          ))
        }
        renderOption={(props, option) => {
          const notFoundInValue = multiple
            ? !value.some(
                (v) => v.userProperties.uuid === option.userProperties.uuid
              )
            : true;
          const showOption = multiple ? notFoundInValue && option.active : true;

          return (
            showOption && (
              <Stack
                {...props}
                gap={1}
                component="li"
                direction="row"
                alignItems="center"
                key={option.userProperties.uuid}
              >
                <Avatar
                  profileImageUrl={getCachedProfileImageUrl(option)}
                  firstName={option?.firstName}
                  lastName={option?.lastName}
                  fontSize="0.75rem"
                  size="smallest"
                />
                {getOptionLabel(option)}
                {option?.userProperties.type !== 'internal' && (
                  <ExternalLabel>(External)</ExternalLabel>
                )}
              </Stack>
            )
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            error={error}
            autoFocus={autoFocus}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              ...textFieldProps?.InputProps,
            }}
            placeholder="Begin typing and press Enter to add..."
          />
        )}
      />
    );
  }
);

UserAutocomplete.displayName = 'UserAutocomplete';

export default UserAutocomplete;
