// Maximum number of characters for regular fields.
export const MAX_LENGTH = {
  value: 255,
  message: `Maximum length of 255 characters exceeded.`,
};

// Maximum number of characters for longer fields.
export const MAX_LONG_LENGTH = {
  value: 24000,
  message: `Maximum length of 24,000 characters exceeded.`,
};
