import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import useToast from '../../lib/hooks/useToast';
import Chats from '../../store/chat/chatActions';
import Messages from '../../store/chat/messageActions';
import { selectCurrentChat } from '../../store/chat/chatSelectors';
import { selectSendError } from '../../store/chat/messageSelectors';

import ChatMessages from './messages/Messages';
import TypingIndicator from './TypingIndicator';

const TextChatWindow = styled.div`
  position: relative;
  height: 100%;
`;

const MessagesWindow = styled.div`
  display: flex;
  flex-flow: column-reverse;
  padding: 0 1em;
  min-height: 100%;
  padding-bottom: 1.1rem;
`;

const ScrollButtonWrapper = styled.div`
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ScrollButton = styled.span`
  padding: 0.7em 1em;
  background: #000000a3;
  border-radius: 10px;
  color: white;
  cursor: pointer;
`;

export default function TextChat({ scrollRef }) {
  const dispatch = useDispatch();
  const windowRef = useRef();
  const observer = useRef(null);
  const currentChat = useSelector(selectCurrentChat);
  const sendError = useSelector(selectSendError);
  const { currentTop } = useSelector((state) => state.chats);
  const [observing, setObserving] = useState(false);
  const [toastElement, openToast] = useToast();

  const { cid: chatId } = currentChat;
  const { unreadCount } = currentChat.state;

  const baseObserver = new ResizeObserver(() => {
    if (!scrollRef.current) {
      return;
    }

    const values = scrollRef.current.getValues();
    if (values.top < 1) {
      scrollRef.current.scrollToBottom();
    }
  });

  const debounced = debounce((top) => dispatch(Chats.setCurrentTop(top)), 500);

  useEffect(() => {
    scrollRef.current.scrollToBottom();
    // This is a special case coming into a chat for the first
    // time AND the scroll wasn't at the bottom on the previous
    // chat.
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = baseObserver;

    const { current: currentObserver } = observer;
    currentObserver.observe(windowRef.current);

    setObserving(true);

    return () => {
      if (scrollRef.current) {
        scrollRef.current.scrollToBottom();
      }
      currentObserver.disconnect();

      setObserving(false);
    };
  }, [chatId]);

  // Effect to show sendError.
  useEffect(() => {
    if (sendError) {
      openToast(sendError, 'error', 4000);
    }
  }, [sendError, openToast]);

  const onScrollFrame = async (values) => {
    const { top, clientHeight, scrollHeight } = values;

    if (top === 0 && scrollRef.current) {
      const result = await dispatch(Messages.fetchMessages(currentChat, 25));
      if (!!result) {
        const newScrollHeight = scrollRef.current.getScrollHeight();
        const oldTop = scrollHeight - clientHeight;
        scrollRef.current.scrollTop(newScrollHeight - oldTop - clientHeight);
      }
      // Disconnecting listener stops chat from scrolling to bottom
    } else if (top < 0.98 && top > 0 && observer.current && observing) {
      setObserving(false);

      observer.current.disconnect();
    } else if (top >= 0.98 && !observing) {
      setObserving(true);

      observer.current.observe(windowRef.current);
    }

    debounced(clientHeight === scrollHeight ? 1 : top);
  };

  const scrollToBottom = () => {
    if (!observing) {
      observer.current = baseObserver;

      observer.current.observe(windowRef.current);

      setObserving(true);
    }

    scrollRef.current.scrollToBottom();

    currentChat.markRead();
  };

  return (
    <TextChatWindow>
      <Scrollbars
        ref={scrollRef}
        onScrollFrame={onScrollFrame}
        renderTrackHorizontal={() => <div />}
        autoHide
      >
        <MessagesWindow ref={windowRef}>
          <ChatMessages />
        </MessagesWindow>
      </Scrollbars>
      <TypingIndicator chatId={chatId} />
      {currentTop < 0.95 && unreadCount > 0 && (
        <ScrollButtonWrapper>
          <ScrollButton onClick={scrollToBottom}>
            New Message. Click to Read.
          </ScrollButton>
        </ScrollButtonWrapper>
      )}
      {toastElement}
    </TextChatWindow>
  );
}
