import React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  ACTIVITY_TYPES,
  TASK_PRIORITY,
  TASK_STATUS,
  TASK_STATUS_LABELS,
} from '../../../store/incident/incidentTypes';
import { getCachedProfileImageUrl } from '../../../utils/imageUtils';

import Layout from './Layout';
import Avatar from '../../Avatar';
import Progress from '../../Progress';
import ElapsedTime from '../ElapsedTime';
import { Priority } from '../../PrioritySelect';

const TaskStatus = styled(Chip)`
  width: 100px;
  height: 28px;
  .MuiChip-label {
    line-height: 1.25;
  }
`;

const InfoItem = styled(Stack)`
  span {
    line-height: 1;
    font-weight: 500;
    font-size: 0.6875rem;
    color: rgba(0, 0, 0, 0.6);
  }
  address,
  time {
    line-height: 1;
  }
`;

const Task = React.forwardRef(
  (
    {
      status,
      progress,
      priority = TASK_PRIORITY.MEDIUM,
      updateTimestamp,
      createTimestamp,
      assignedTo,
      createdBy,
      isResolved,
      ...props
    },
    ref
  ) => {
    const statusColor =
      status === TASK_STATUS.TO_DO
        ? 'error'
        : status === TASK_STATUS.IN_PROGRESS
          ? 'warning'
          : 'success';
    const showProgress = progress > 0;
    const timestamp = !!updateTimestamp ? updateTimestamp : createTimestamp;

    return (
      <Layout
        {...props}
        ref={ref}
        type={ACTIVITY_TYPES.TASK}
        borderColor={statusColor}
        topRightContent={
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
              '& .MuiTypography-caption': {
                lineHeight: 1,
                fontSize: '0.6rem',
                fontWeight: 500,
              },
            }}
          >
            <Priority faded level={priority} />
            {showProgress && (
              <Progress
                color={progress === 100 ? 'success' : 'warning'}
                value={progress}
                style={{
                  width: '2rem',
                  height: '2rem',
                  transform: 'rotate(-90deg)',
                }}
              />
            )}
            <TaskStatus
              color={statusColor}
              label={TASK_STATUS_LABELS[status]}
            />
          </Stack>
        }
        bottomLeftContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              profileImageUrl={getCachedProfileImageUrl(assignedTo)}
              firstName={assignedTo?.firstName}
              lastName={assignedTo?.lastName}
              showDefaultImage
              fontSize="1rem"
              sx={{ width: '2rem', height: '2rem' }}
            />
            <InfoItem spacing={0.75}>
              <span>Assigned to:</span>
              <Typography component="address">
                {!!assignedTo
                  ? `${assignedTo.firstName} ${assignedTo.lastName}`
                  : 'Unassigned'}
              </Typography>
            </InfoItem>
            <InfoItem spacing={0.75}>
              <span>Updated:</span>
              <ElapsedTime timestamp={timestamp} showTimestamp={isResolved} />
            </InfoItem>
          </Stack>
        }
        afterTitleContent={
          <Typography
            sx={{
              marginTop: '0.25rem',
              fontSize: '11px',
              lineHeight: 1,
              '& time': {
                fontSize: '11px',
              },
              '& span': {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            <span>Created by: </span>
            {createdBy.firstName} {createdBy.lastName}
            <span>
              {' '}
              &mdash;{' '}
              <ElapsedTime
                timestamp={createTimestamp}
                showTimestamp={isResolved}
              />
            </span>
          </Typography>
        }
      />
    );
  }
);

Task.displayName = 'Task';

export default Task;
