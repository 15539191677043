import axios from 'axios';
import { useEffect } from 'react';
import { read, utils } from 'xlsx';
import { CSVRenderer } from '@cyntler/react-doc-viewer';

import useToast from '../../../lib/hooks/useToast';

const XLSXRenderer = (props) => {
  const [toastElement, openToast] = useToast();

  useEffect(() => {
    openToast(
      'This document preview may be missing styling and formatting.',
      'warning',
      5000
    );
  }, [openToast]);

  return (
    <>
      <CSVRenderer {...props} />
      {toastElement}
    </>
  );
};

XLSXRenderer.fileLoader = ({ documentURI, fileLoaderComplete }) => {
  axios
    .get(documentURI, { responseType: 'arraybuffer' })
    .then(({ data: arrayBuffer }) => {
      const workbook = read(arrayBuffer);
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const rows = utils.sheet_to_json(worksheet, {
        raw: true,
      });

      if (!rows || !rows.length) {
        return fileLoaderComplete({
          result:
            'This file is either empty or has an irregular structure. Please download the file to view.',
        });
      }

      const header = Object.keys(rows[0]);
      let csv = rows.map((row) =>
        header
          .map((fieldName) =>
            JSON.stringify(row[fieldName], (key, value) =>
              value === null ? '' : value
            )
          )
          .join(',')
      );
      csv.unshift(header.join(','));
      csv = csv.join('\r\n');

      fileLoaderComplete({ result: csv });
    })
    .catch((error) => {
      console.error(error);
      fileLoaderComplete({
        result:
          'An error occurred parsing the document, please download the file to view.',
      });
    });
};
XLSXRenderer.fileTypes = [
  'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xls',
  'application/vnd.ms-excel',
];
XLSXRenderer.weight = 1;

export default XLSXRenderer;
