import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ContentMain,
  ContentTitleBar,
  ChatTitle,
  ChatActions,
} from './ChatContent';
import ChatActionButton from './ChatActionButton';
import TextChat from './TextChat';
import ChatMessageInput from './input/ChatMessageInput';
import ExportChat from './ExportChat';
import NoInput from './input/NoInput';
import {
  selectChatMemberNames,
  selectCurrentChat,
  selectChatMembers,
} from '../../store/chat/chatSelectors';
import JitsiCalls from '../../store/jitsi/jitsiActions';
import {
  selectJwt,
  selectOnCall,
  selectFetchingJwt,
  selectHasIncomingCall,
  selectHasCall,
} from '../../store/jitsi/jitsiSelectors';

import Phone from '../../assets/icons/phone-outline.svg?react';
import Video from '../../assets/icons/video-outline.svg?react';
import Clipboard from '../../assets/icons/clipboard-outline.svg?react';

export default function DirectChatContent() {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const currentChat = useSelector(selectCurrentChat);
  const members = useSelector(selectChatMembers);
  const names = useSelector(selectChatMemberNames);
  const jwt = useSelector(selectJwt);
  const fetching = useSelector(selectFetchingJwt);
  const onJitsiCall = useSelector(selectOnCall);
  const hasIncomingCall = useSelector(selectHasIncomingCall);
  const [exportOpen, setExportOpen] = useState(false);
  const { id, cid } = currentChat.data;
  const hasCallOngoing = useSelector((state) =>
    selectHasCall(state, { chatId: cid })
  );

  const allActive = members.filter((m) => m.active);
  const canCall =
    !jwt &&
    !fetching &&
    !onJitsiCall &&
    allActive.length > 0 &&
    !hasIncomingCall;

  useEffect(() => {
    if (hasCallOngoing) {
      dispatch(JitsiCalls.fetchDialInOptions());
    }
  }, [hasCallOngoing, dispatch]);

  useEffect(() => {
    setExportOpen(false);
  }, [id]);

  function makeCall(videoMuted) {
    dispatch(JitsiCalls.getJWT(currentChat.cid)).then(() =>
      dispatch(JitsiCalls.joinCall(videoMuted))
    );
  }

  return (
    <ContentMain>
      <ContentTitleBar>
        <ChatTitle>
          <small>Messenger</small>
          <span>
            Talking to <b>{names.join(', ')}</b>
          </span>
        </ChatTitle>
        <ChatActions>
          <ChatActionButton
            tooltip="Export Conversation"
            icon={<Clipboard />}
            action={() => setExportOpen(true)}
          />
          {canCall && (
            <ChatActionButton
              tooltip="Start audio call"
              disabled={!canCall}
              icon={<Phone />}
              action={() => makeCall(true)}
            />
          )}
          {canCall && (
            <ChatActionButton
              tooltip="Start video call"
              icon={<Video />}
              disabled={!canCall}
              action={() => makeCall(false)}
            />
          )}
        </ChatActions>
        <ExportChat open={exportOpen} onClose={() => setExportOpen(false)} />
      </ContentTitleBar>
      <TextChat scrollRef={scrollRef} />
      {allActive.length !== 0 ? (
        <ChatMessageInput
          scrollToBottom={() => scrollRef.current.scrollToBottom()}
        />
      ) : (
        <NoInput />
      )}
    </ContentMain>
  );
}
