import { v4 } from 'uuid';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { PLAYBOOK_ITEM_TEMPLATES } from '../../store/playbook/playbookTypes';

import PlusIcon from '../../assets/icons/plus.svg?react';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '5px',
    padding: 0,
  },
});

const AddItemButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.75rem 0;
  width: 100px;

  & > span {
    height: 2px;
    width: ${({ tooltip }) => (tooltip === 'open' ? '100%' : '50%')};
    border-radius: 4px;
    background-color: ${({ tooltip }) =>
      tooltip === 'open' ? '#ccc' : '#eee'};
    transition: all 0.2s ease-in;
  }

  & > svg {
    color: #ccc;
    background-color: #fff;
    position: absolute;
  }

  &&:hover {
    & > span {
      width: 100%;
      background-color: #ccc;
    }
  }
`;

const TooltipButton = styled(Button)`
  width: 100%;
  color: #fff;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  justify-content: flex-start;

  &&:hover {
    background-color: #333;
  }
`;

const AddItemControl = ({ index, onClickItem }) => {
  const [show, setShow] = useState(false);

  const handleClickHeading = (e) => {
    e.stopPropagation();
    setShow(false);
    onClickItem(index, { ...PLAYBOOK_ITEM_TEMPLATES.HEADING, id: v4() });
  };

  const handleClickTask = (e) => {
    e.stopPropagation();
    setShow(false);
    onClickItem(index, { ...PLAYBOOK_ITEM_TEMPLATES.TASK, id: v4() });
  };

  return (
    <StyledTooltip
      arrow
      open={show}
      placement="right"
      disableFocusListener
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
      title={
        <Stack alignItems="flex-start">
          <TooltipButton
            size="small"
            onClick={handleClickHeading}
            startIcon={<SvgIcon size="small" component={PlusIcon} />}
          >
            Add Heading
          </TooltipButton>
          <TooltipButton
            size="small"
            onClick={handleClickTask}
            startIcon={<SvgIcon size="small" component={PlusIcon} />}
          >
            Add Task
          </TooltipButton>
        </Stack>
      }
    >
      <AddItemButton tooltip={show ? 'open' : 'closed'}>
        <span aria-label="Add Item" />
        <SvgIcon size="small" component={PlusIcon} />
      </AddItemButton>
    </StyledTooltip>
  );
};

export default AddItemControl;
