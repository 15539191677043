import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import {
  SUBSCRIPTION_LEVELS,
  SUBSCRIPTION_LEVEL_OPTIONS,
} from '../../../configs/management';
import { emailRegex } from '../../../utils/formUtils';
import { MAX_LENGTH } from '../../../configs/validation';
import { managementApi } from '../../../configs/managementApi';
import { selectHasStreamCredsAvailable } from '../../../store/management/managementSelectors';

import BasicModal from '../../BasicModal';

const CreateOrgModal = ({ open, onClose }) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      enterpriseName: '',
      enterpriseDomain: '',
      adminFirstName: '',
      adminLastName: '',
      adminEmail: '',
      subscriptionMaxUsers: 0,
      subscriptionLevel: SUBSCRIPTION_LEVELS.BASIC,
      streamApiKey: '',
      streamSecret: '',
    },
  });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const hasStreamCredsAvailable = useSelector(selectHasStreamCredsAvailable);

  const handleClose = (isCancel = false) => {
    reset();
    setSuccess('');
    setErrorMessages([]);
    onClose(isCancel);
  };

  const handleCreate = (data) => {
    const adjustedData = {
      ...data,
      streamApiKey: !!data.streamApiKey ? data.streamApiKey : null,
      streamSecret: !!data.streamSecret ? data.streamSecret : null,
    };

    setLoading(true);
    return managementApi
      .post('/enterprise', JSON.stringify(adjustedData))
      .then(() => {
        setSuccess('Organization created!');
        setTimeout(handleClose, 1000);
      })
      .catch(({ response }) => setErrorMessages(response.data.errorMessages))
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      title="Create Organization"
      buttonText={success || 'Create'}
      onClose={() => handleClose(true)}
      buttonDisabled={!!success || loading}
      onClickButton={handleSubmit(handleCreate)}
      sx={{ minWidth: '40rem' }}
    >
      <Stack direction="row" gap={2}>
        <Controller
          name="enterpriseName"
          control={control}
          rules={{
            required: "Please enter the organization's name.",
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              size="small"
              label="Organization Name"
              error={!!errors.enterpriseName}
              disabled={!!success || loading}
              helperText={errors?.enterpriseName?.message}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Controller
          name="enterpriseDomain"
          control={control}
          rules={{
            required: "Please enter the organization's domain.",
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Organization Domain"
              disabled={!!success || loading}
              error={!!errors.enterpriseDomain}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              helperText={errors?.enterpriseDomain?.message}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Controller
          name="adminFirstName"
          control={control}
          rules={{
            required: "Please enter the admin's first name.",
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Admin First Name"
              error={!!errors.adminFirstName}
              helperText={errors?.adminFirstName?.message}
              disabled={!!success || loading}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Controller
          name="adminLastName"
          control={control}
          rules={{
            required: "Please enter the admin's last name.",
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Admin Last Name"
              error={!!errors.adminLastName}
              helperText={errors?.adminLastName?.message}
              disabled={!!success || loading}
              inputProps={{ maxLength: MAX_LENGTH.value }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
      </Stack>
      <Controller
        name="adminEmail"
        control={control}
        rules={{
          required: "Please enter the admin's email address.",
          pattern: {
            value: emailRegex,
            message: 'Email address is invalid.',
          },
          maxLength: MAX_LENGTH,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label="Admin Email Address"
            error={!!errors.adminEmail}
            disabled={!!success || loading}
            helperText={errors?.adminEmail?.message}
            inputProps={{
              autoComplete: 'off',
              maxLength: MAX_LENGTH.value,
            }}
          />
        )}
      />
      <Stack direction="row" gap={2}>
        <Controller
          name="subscriptionLevel"
          control={control}
          rules={{
            required: 'Please select a subscription level.',
          }}
          render={({ field }) => (
            <FormControl
              size="small"
              error={errors.subscriptionLevel}
              sx={{ flex: 1 }}
            >
              <InputLabel id="subscription-level-label">
                Subscription Level
              </InputLabel>
              <Select
                {...field}
                label="Subscription Level"
                disabled={!!success || loading}
                labelId="subscription-level-label"
              >
                {SUBSCRIPTION_LEVEL_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {errors?.subscriptionLevel?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="subscriptionMaxUsers"
          control={control}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: 'Please enter a valid number.',
            },
            maxLength: MAX_LENGTH,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Subscription Max Users"
              disabled={!!success || loading}
              error={!!errors.subscriptionMaxUsers}
              helperText={errors?.subscriptionMaxUsers?.message}
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                maxLength: MAX_LENGTH.value,
              }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Controller
          name="streamApiKey"
          control={control}
          rules={
            !hasStreamCredsAvailable
              ? {
                  required: 'Please enter a Stream API key.',
                  maxLength: MAX_LENGTH,
                }
              : null
          }
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Stream API Key"
              helperText={
                hasStreamCredsAvailable
                  ? 'This field will be completed for you.'
                  : errors?.streamApiKey?.message
              }
              error={!hasStreamCredsAvailable && !!errors.streamApiKey}
              disabled={!!success || loading || hasStreamCredsAvailable}
              inputProps={{
                autoComplete: 'off',
                maxLength: MAX_LENGTH.value,
              }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Controller
          name="streamSecret"
          control={control}
          rules={
            !hasStreamCredsAvailable
              ? {
                  required: 'Please enter a Stream API secret.',
                  maxLength: MAX_LENGTH,
                }
              : null
          }
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Stream API Secret"
              helperText={
                hasStreamCredsAvailable
                  ? 'This field will be completed for you.'
                  : errors?.streamSecret?.message
              }
              error={!hasStreamCredsAvailable && !!errors.streamSecret}
              disabled={!!success || loading || hasStreamCredsAvailable}
              inputProps={{
                autoComplete: 'off',
                maxLength: MAX_LENGTH.value,
              }}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
      </Stack>
      {!!errorMessages.length && (
        <ul style={{ margin: 0 }}>
          {errorMessages.map((error, index) => (
            <Typography
              key={index}
              color="error"
              component="li"
              fontSize="smaller"
            >
              {error}
            </Typography>
          ))}
        </ul>
      )}
    </BasicModal>
  );
};

export default CreateOrgModal;
