import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';

import { calculateTimeUntilExpiry } from '../utils/tokenUtils';
import ManagementConsole from '../store/management/managementActions';
import { selectAuthToken } from '../store/management/managementSelectors';

const useManagementTimeout = () => {
  const dispatch = useDispatch();
  const timeoutId = useRef(null);
  const authToken = useSelector(selectAuthToken);

  const clear = () => {
    if (timeoutId.current) {
      // console.log('Clearing auth timeout.');
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  };

  const schedule = useCallback(() => {
    clear();

    if (!!authToken) {
      const time = calculateTimeUntilExpiry(authToken);
      console.log(
        `ManagementTimeout: schedule sign out for ${Number(
          time / 1000 / 60
        ).toFixed(1)} minutes from now.`
      );
      timeoutId.current = setTimeout(() => {
        console.log('ManagementTimeout: Signing out...');
        dispatch(ManagementConsole.logout());
      }, time);
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    // console.log('ManagementTimeout mount');
    schedule();

    return () => {
      // console.log('ManagementTimeout unmount');
      clear();
    };
  }, [schedule]);
};

export default useManagementTimeout;
