import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { WELCOME_PACKAGE_ERROR_MESSAGES } from '../../configs/welcomePackage';

const PackageNotFound = () => (
  <Stack alignItems="center" justifyContent="center" gap={1} sx={{ flex: 1 }}>
    <Typography component="h2" variant="h3" align="center">
      Oops!
    </Typography>
    <Typography variant="h6" align="center">
      {WELCOME_PACKAGE_ERROR_MESSAGES.NOT_FOUND}
    </Typography>
  </Stack>
);

export default PackageNotFound;
