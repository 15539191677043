import SvgIcon from '@mui/material/SvgIcon';

const Lock = (props) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M1.714 13.714a3.429 3.429 0 0 1 3.429-3.428V6.857c0-9.143 13.714-9.143 13.714 0v3.429a3.429 3.429 0 0 1 3.429 3.428v6.857A3.429 3.429 0 0 1 18.857 24H5.143a3.429 3.429 0 0 1-3.429-3.429v-6.857ZM12 3.43A3.429 3.429 0 0 0 8.57 6.857v3.429h6.857V6.857A3.429 3.429 0 0 0 12 3.43Z"
    />
  </SvgIcon>
);

export default Lock;
