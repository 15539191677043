import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { PAGES } from '../../configs';
import {
  selectShowVerification,
  selectVerificationRequested,
  selectVerificationSent,
  selectVerificationError,
  selectVerificationWaiting,
  selectVerificationMsUntilNextAttempt,
  selectVerificationLoading,
} from '../../store/storage/storageSelectors';
import useCountdown from '../../lib/hooks/useCountdown';
import Storage from '../../store/storage/storageActions';

import Countdown from '../Countdown';
import BasicModal from '../BasicModal';

const TwoFactorVerificationModal = () => {
  const CODE_LENGTH = 4;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const inputsRef = useRef(Array(CODE_LENGTH).fill(null));
  const open = useSelector(selectShowVerification);
  const loading = useSelector(selectVerificationLoading);
  const requested = useSelector(selectVerificationRequested);
  const waiting = useSelector(selectVerificationWaiting);
  const sent = useSelector(selectVerificationSent);
  const error = useSelector(selectVerificationError);
  const msUntilNextAttempt = useSelector(selectVerificationMsUntilNextAttempt);
  const [nextRequestCountdown] = useCountdown(msUntilNextAttempt);
  const [nextVerifyCountdown, setNextVerifyCountdown] = useCountdown();

  const { isTicking: isNextRequestTicking } = nextRequestCountdown;
  const { isTicking: isNextVerifyTicking } = nextVerifyCountdown;

  const updateCode = () => {
    const code = inputsRef.current.map((input) => input.value).join('');
    setCode(code);
  };

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (index < CODE_LENGTH) {
      if (value.match('[0-9]{1}')) {
        if (inputsRef.current[index + 1]) {
          inputsRef.current[index + 1].focus();
        }
      } else {
        e.target.value = '';
      }
    }
    updateCode();
  };

  const handleKeyDown = (e, index) => {
    const { key } = e;
    if (key === 'Backspace') {
      if (e.target.value === '') {
        if (inputsRef.current[index - 1]) {
          const t = inputsRef.current[index - 1];
          t.value = '';
          t.focus();
          e.preventDefault();
        }
      } else {
        e.target.value = '';
      }
      updateCode();
    }
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text');

    let currentInput = 0;
    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i);
      const currentValue = inputsRef.current[currentInput].value;
      if (pastedCharacter.match('[0-9]{1}')) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter;
          if (inputsRef.current[currentInput + 1]) {
            inputsRef.current[currentInput + 1].focus();
            currentInput++;
          }
        }
      }
    }
    updateCode();

    e.preventDefault();
  };

  const handleVerify = () => {
    const { pathname } = location;
    dispatch(Storage.sendVerification(code))
      .then(
        () =>
          !pathname.includes(PAGES.incidents.pathname) &&
          navigate(PAGES.fileStorage.pathname)
      )
      .catch((e) => {
        const { response } = e;
        if (response.status === 429) {
          return setNextVerifyCountdown(response.data.msUntilNextAttempt);
        }
      });
  };

  const handleRequestNewCode = () => {
    dispatch(Storage.requestVerification());
  };

  const handleCancel = () => {
    dispatch(Storage.clearVerification());
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return event.preventDefault();
    }
  };

  const inputs = [];
  for (let i = 0; i < CODE_LENGTH; i++) {
    inputs.push(
      <TextField
        key={i}
        autoFocus={i === 0}
        inputRef={(element) => {
          inputsRef.current[i] = element;
        }}
        autoComplete="off"
        variant="outlined"
        color="primary"
        disabled={requested || sent}
        onChange={(e) => handleChange(e, i)}
        onKeyDown={(e) => handleKeyDown(e, i)}
        onPaste={handlePaste}
        inputProps={{
          maxLength: 1,
          inputMode: 'numeric',
          pattern: '[0-9]{1}',
          autoComplete: 'off',
        }}
        sx={{
          width: '40px',
          '& .MuiInputBase-input': {
            padding: '0.5rem 0.25rem',
            textAlign: 'center',
            fontSize: '2rem',
          },
        }}
      />
    );
  }

  return (
    <BasicModal
      open={open}
      onClose={handleClose}
      title="Two-Factor Verification"
      footerComponent={
        <Stack gap="0.5rem" direction="row" justifyContent="flex-end">
          <Button onClick={handleCancel} disabled={requested || sent}>
            Cancel
          </Button>
          {waiting ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleRequestNewCode}
              disabled={isNextRequestTicking}
            >
              Request Code
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={handleVerify}
              disabled={
                requested ||
                sent ||
                code.length < CODE_LENGTH ||
                isNextVerifyTicking
              }
            >
              Verify
            </Button>
          )}
        </Stack>
      }
    >
      {loading ? (
        <>
          <Typography align="center">
            {requested && 'Requesting verification...'}
            {sent && 'Verifying...'}
          </Typography>
          <CircularProgress size={36} sx={{ alignSelf: 'center', my: 2 }} />
        </>
      ) : waiting ? (
        <>
          {!isNextRequestTicking && (
            <Typography align="center">
              You can now request a new code.
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography align="center">
            For security reasons, we need to verify your identity.
          </Typography>
          <Typography align="center">
            A confirmation code has been sent to your device.
            <br />
            Please enter the code to continue.
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {inputs}
          </Stack>
          <Link
            onClick={handleRequestNewCode}
            sx={{ cursor: 'pointer', alignSelf: 'center' }}
          >
            Didn&apos;t get a code?
          </Link>
          {error && (
            <Typography color="error" align="center">
              The code entered was incorrect.
            </Typography>
          )}
        </>
      )}
      {!isNextVerifyTicking && (
        <Countdown
          align="center"
          color="error"
          values={nextRequestCountdown}
          templateString="Please wait %m minute%mp and %s second%sp before requesting another code."
        />
      )}
      <Countdown align="center" color="error" values={nextVerifyCountdown} />
    </BasicModal>
  );
};

export default TwoFactorVerificationModal;
