import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

import { formattedDate, formatTime } from '../../../lib/date';
import { getCachedProfileImageUrl } from '../../../utils/imageUtils';

import Avatar from '../../Avatar';
import MessageItem from './MessageItem';
import ClockOutline from '../../../assets/icons/clock-outline.svg?react';

const UserGroup = styled.div`
  padding: 0.7rem 0.5rem 0.15em;
  align-items: center;
  display: flex;
`;

const AvatarWrapper = styled.div`
  font-size: 1.6rem;
  display: inline-block;
  margin-right: 0.25rem;
  position: relative;
  align-self: flex-start;
`;

const UserName = styled.div`
  font-size: 0.95rem;
  padding-left: 4px;
  display: flex;
  align-items: center;
  algin-content: baseline;
  gap: 0.25em;
`;

const StyledIcon = styled(SvgIcon)`
  && {
    font-size: 0.9em;
    opacity: 0.5;
    min-height: 19px;
    height: 19px;
  }
`;

const Date = styled.small`
  color: #07091980;
`;

export default function MessageGroup({
  sender = { firstName: 'Former', lastName: 'Member' },
  messages = [],
}) {
  const [firstMessage] = messages;
  const { firstName, lastName, active, userProperties } = sender;

  if (!firstMessage) {
    return null;
  }

  const firstSent = firstMessage.created_at;
  const isExternal = userProperties && userProperties.type === 'external';

  return (
    <UserGroup>
      <AvatarWrapper>
        <Avatar
          firstName={firstName}
          lastName={lastName}
          profileImageUrl={getCachedProfileImageUrl(sender)}
        />
      </AvatarWrapper>
      <div style={{ width: '90%' }}>
        <UserName>
          <span style={{ fontWeight: 500 }}>
            {`${firstName} ${lastName}${!active ? ' (Inactive)' : ''}${
              isExternal ? ' (External)' : ''
            }`}
          </span>
          <StyledIcon>
            <ClockOutline />
          </StyledIcon>
          <Date>{`${formattedDate(firstSent)} ${formatTime(firstSent)}`}</Date>
        </UserName>
        <div>
          {messages.map((m, i) => {
            const key = m.id;
            return <MessageItem key={key} message={m} index={i} />;
          })}
        </div>
      </div>
    </UserGroup>
  );
}
