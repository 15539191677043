import { useState, useEffect, forwardRef } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MuiList from '@mui/material/List';
import SvgIcon from '@mui/material/SvgIcon';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import MuiListItemButton from '@mui/material/ListItemButton';

import { labelCompare } from '../../utils/sortUtils';

import CaretIcon from '../../assets/icons/caret.svg?react';
import PlaybookIcon from '../../assets/icons/book.svg?react';

const TextField = styled(MuiTextField)`
  & .MuiInputBase-input {
    padding: 0.5rem 0.65rem;
  }
`;

const List = styled(MuiList)`
  width: 100%;
  overflow: auto;
  height: 150px;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: 0;
  border-top: 1px solid #eee;
`;

const ListItemButton = styled(MuiListItemButton)`
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
`;

const ChipIcon = styled(SvgIcon)`
  width: 1em !important;
  height: 1em !important;
  font-size: 1rem !important;
  background: none !important;
  margin-left: 0.5em !important;
`;

const PlaybookSelect = forwardRef(
  ({ options, defaultValue, value, onChange }, ref) => {
    const optionForValue = options.find((o) => o.value === value);
    const [anchorEl, setAnchorEl] = useState(null);
    const [textFilter, setTextFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('All');

    const open = Boolean(anchorEl);
    const id = open ? 'playbook-picker' : undefined;
    const categoryNames = new Set(options.map((i) => i.categoryName));
    const filters = ['All', ...[...categoryNames.values()].sort()];
    const filteredOptions = [...options]
      .filter((o) =>
        categoryFilter === 'All' ? true : o.categoryName === categoryFilter
      )
      .filter((o) => o.label.toLowerCase().includes(textFilter.toLowerCase()))
      .sort(labelCompare);

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => {
      setAnchorEl(null);
      setTextFilter('');
      setCategoryFilter('All');
    };

    const handleClickOption = (value) => {
      onChange(value);
      handleClose();
    };

    const handleClear = () => onChange(defaultValue);

    useEffect(() => {
      if (open && !!optionForValue) {
        setTextFilter(optionForValue.label);
      }
    }, [open, optionForValue]);

    return (
      <>
        <Chip
          ref={ref}
          variant="contained"
          onClick={handleClick}
          aria-describedby={id}
          avatar={<ChipIcon component={PlaybookIcon} />}
          color={!!optionForValue ? 'primary' : 'default'}
          onDelete={!!optionForValue ? handleClear : undefined}
          label={
            <Stack direction="row" alignItems="center">
              {!!optionForValue ? optionForValue.label : 'Select a playbook...'}
              <SvgIcon component={CaretIcon} sx={{ mt: '-1px' }} />
            </Stack>
          }
          sx={{
            height: 26,
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper': {
              maxWidth: 350,
            },
          }}
        >
          <Stack gap={1.5}>
            <TextField
              autoFocus
              value={textFilter}
              autoComplete="off"
              placeholder="Filter playbooks..."
              onChange={(e) => setTextFilter(e.target.value)}
              sx={{ pt: 1.5, px: 1.5 }}
            />
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap={0.5}
              sx={{ px: 1.5 }}
            >
              <Typography lineHeight={1}>Filter:</Typography>
              {filters.map((f) => (
                <Chip
                  key={f}
                  label={f}
                  size="small"
                  color="primary"
                  variant={categoryFilter === f ? undefined : 'outlined'}
                  onClick={() => setCategoryFilter(f)}
                />
              ))}
            </Stack>
            <List>
              {!filteredOptions.length ? (
                <ListItem>
                  <Typography>No options.</Typography>
                </ListItem>
              ) : (
                filteredOptions.map((f) => (
                  <ListItemButton
                    key={f.value}
                    onClick={() => handleClickOption(f.value)}
                    sx={{ gap: 0.5, alignItems: 'center' }}
                  >
                    {f.label}{' '}
                    <Typography
                      sx={{ lineHeight: 1, fontSize: '11px', color: '#aaa' }}
                    >
                      {`(${f.categoryName})`}
                    </Typography>
                  </ListItemButton>
                ))
              )}
            </List>
          </Stack>
        </Popover>
      </>
    );
  }
);

PlaybookSelect.displayName = 'PlaybookSelect';

export default PlaybookSelect;
