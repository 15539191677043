import { AUTH_TYPES } from '../auth/authReducer';

export const GROUP_ADMIN_TYPES = {
  FETCH: '@@groupAdmin/FETCH',
  CREATE: '@@groupAdmin/CREATE',
  EDIT: '@@groupAdmin/EDIT',
  DELETE: '@@groupAdmin/DELETE',
  SET_GROUP_ID: '@@groupAdmin/SET_GROUP_ID',
};

const INITIAL_STATE = {
  list: [],
  groupId: null,
  fetching: false,
};

export default function groupAdmin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `${GROUP_ADMIN_TYPES.FETCH}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${GROUP_ADMIN_TYPES.FETCH}_REJECTED`:
      return {
        ...state,
        fetching: false,
      };
    case `${GROUP_ADMIN_TYPES.FETCH}_FULFILLED`:
      return {
        ...state,
        list: action.payload.data,
        fetching: false,
      };
    case `${GROUP_ADMIN_TYPES.CREATE}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        groupId: data.id,
        list: [...state.list, data],
      };
    }
    case `${GROUP_ADMIN_TYPES.EDIT}_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        list: state.list.map((g) => (g.id === data.id ? data : g)),
      };
    }
    case `${GROUP_ADMIN_TYPES.DELETE}_FULFILLED`: {
      const id = action.payload;
      return {
        ...state,
        groupId: null,
        list: state.list.filter((g) => g.id !== id),
      };
    }
    case GROUP_ADMIN_TYPES.SET_GROUP_ID:
      return {
        ...state,
        groupId: action.payload,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
