import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ACTIVITY_TYPES,
  TASK_PRIORITY,
  TASK_STATUS,
  TASK_STATUS_OPTIONS,
} from '../../../store/incident/incidentTypes';
import { handlePaste } from '../../../lib/input';
import Incidents from '../../../store/incident/incidentActions';
import { getCachedProfileImageUrl } from '../../../utils/imageUtils';
import { selectSortedUsers } from '../../../store/user/userSelectors';
import { MAX_LENGTH, MAX_LONG_LENGTH } from '../../../configs/validation';
import { selectCurrentUserUUID } from '../../../store/auth/authSelectors';

import Avatar from '../../Avatar';
import PrioritySelect from '../../PrioritySelect';
import UserAutocomplete from '../../UserAutocomplete';

const Container = styled(Stack)`
  color: #333;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  padding: 0.75rem;
`;

const StatusDropdown = styled(FormControl)`
  .MuiSelect-select {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border-radius: ${({ theme }) => theme.spacing(3)};
    background-color: ${({ theme, status }) =>
      status === TASK_STATUS.TO_DO
        ? theme.palette.error.main
        : status === TASK_STATUS.IN_PROGRESS
          ? theme.palette.warning.main
          : theme.palette.success.light};
    color: ${({ status }) =>
      status === TASK_STATUS.IN_PROGRESS ? '#000' : '#FFF'};
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const BottomContainer = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const ProgressContainer = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media only screen and (min-width: 1200px) {
    width: auto;
    margin-top: 0;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

const ProgressSlider = styled(Stack)`
  max-width: 230px;

  label {
    line-height: 1;
    font-size: 12px;
  }
  .MuiSlider-root {
    padding: 6px 0 10px;
  }
  .MuiSlider-markLabel {
    top: 28px;
    font-size: 11px;
  }

  @media only screen and (min-width: 1280px) {
    max-width: 250px;
  }
`;

const TaskForm = ({ task, onSubmit, ...props }) => {
  const isEditing = !!task;
  const dispatch = useDispatch();
  const userList = useSelector(selectSortedUsers);
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    title: isEditing ? task?.title : '',
    description: isEditing ? task?.description : '',
    status: isEditing ? task?.status : TASK_STATUS.TO_DO,
    priority: isEditing ? task?.priority : TASK_PRIORITY.MEDIUM,
    assignedTo: isEditing
      ? task?.assignedTo
      : userList.find((u) => u.userProperties.uuid === currentUserUUID),
    progress: isEditing ? task?.progress : 0,
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    values: initialValues,
    mode: 'onChange',
  });

  const handleChangeStatus = (e, onChange) => {
    const status = e.target.value;
    const { progress } = getValues();

    if (status === TASK_STATUS.TO_DO) {
      setValue('progress', 0);
    } else if (
      status === TASK_STATUS.IN_PROGRESS &&
      (progress === 0 || progress === 100)
    ) {
      setValue('progress', 25);
    } else if (status === TASK_STATUS.COMPLETE) {
      setValue('progress', 100);
    }

    onChange(status);
  };

  const handleChangeProgress = (e, onChange) => {
    const progress = e.target.value;

    if (progress === 0) {
      setValue('status', TASK_STATUS.TO_DO);
    } else if (progress === 100) {
      setValue('status', TASK_STATUS.COMPLETE);
    } else {
      setValue('status', TASK_STATUS.IN_PROGRESS);
    }

    onChange(progress);
  };

  const onFormSubmit = (formData) => {
    const { title, description, status, progress, priority, assignedTo } =
      formData;
    const data = {
      title,
      description,
      status,
      progress,
      priority,
      assignedTo: assignedTo?.email || '',
      type: ACTIVITY_TYPES.TASK,
    };

    setError('');
    setSubmitting(true);
    dispatch(
      isEditing
        ? Incidents.editActivity(task?.id, data)
        : Incidents.createActivity(data)
    )
      .then(() => {
        onSubmit();
        setSubmitting(false);
      })
      .catch(({ response }) => {
        setError(response?.data);
        setSubmitting(false);
      });
  };

  return (
    <Container
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
      noValidate
      gap={1}
      mb={3}
      {...props}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Controller
          name="title"
          control={control}
          rules={{
            required: 'Title is a required field.',
            validate: (value) =>
              value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                {...field}
                autoFocus
                variant="outlined"
                placeholder="Task Title"
                disabled={submitting}
                error={!!errors.title}
                helperText={errors?.title?.message}
                inputProps={{ maxLength: MAX_LENGTH.value }}
                onPaste={(e) => handlePaste(e, onChange, MAX_LENGTH.value)}
                sx={{
                  flexGrow: 1,
                  '& .MuiInputBase-input': {
                    py: 0,
                    px: 0,
                    lineHeight: 1,
                    fontSize: '1.25rem',
                  },
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none ' },
                  '& .Mui-error': {
                    mx: 0,
                  },
                }}
              />
            );
          }}
        />
        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <PrioritySelect {...field} disabled={submitting} />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <StatusDropdown
                status={value}
                sx={{ flexShrink: 0, '& .MuiInputBase-input': { py: 1 } }}
              >
                <Select
                  {...field}
                  disabled={submitting}
                  onChange={(e) => handleChangeStatus(e, onChange)}
                >
                  {TASK_STATUS_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </StatusDropdown>
            );
          }}
        />
      </Stack>
      <Controller
        name="description"
        control={control}
        rules={{
          required: 'Description is a required field.',
          validate: (value) =>
            value.length >= MAX_LONG_LENGTH.value
              ? MAX_LONG_LENGTH.message
              : true,
        }}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <TextField
              {...field}
              multiline
              minRows={3}
              size="large"
              variant="outlined"
              placeholder="Please provide a task description..."
              disabled={submitting}
              error={!!errors?.description}
              helperText={errors?.description?.message}
              inputProps={{ maxLength: MAX_LONG_LENGTH.value }}
              onPaste={(e) => handlePaste(e, onChange, MAX_LONG_LENGTH.value)}
              sx={{
                '& .MuiInputBase-root': {
                  px: 0,
                  py: 0,
                  fontSize: '0.875rem',
                },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                '& .Mui-error': {
                  mx: 0,
                },
              }}
            />
          );
        }}
      />
      <BottomContainer gap={1}>
        <Controller
          name="assignedTo"
          control={control}
          render={({ field }) => {
            const { value } = field;
            return (
              <UserAutocomplete
                {...field}
                multiple={false}
                disabled={submitting}
                textFieldProps={{
                  variant: 'standard',
                  label: !!value ? 'Assigned to:' : 'Unassigned',
                  InputProps: {
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ height: '100%', placeSelf: 'flex-end' }}
                      >
                        <Avatar
                          profileImageUrl={getCachedProfileImageUrl(value)}
                          firstName={value?.firstName}
                          lastName={value?.lastName}
                          showDefaultImage
                          fontSize="1.125rem"
                          sx={{
                            width: '2.25rem',
                            height: '2.25rem',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                  sx: {
                    'label + .MuiInput-root': {
                      marginTop: 0,
                    },
                    '.MuiFormLabel-root': {
                      top: '-4px',
                      left: '44px',
                      fontSize: '1rem',
                    },
                    '.MuiInputBase-input': {
                      marginBottom: '-14px',
                    },
                  },
                }}
                sx={{
                  minWidth: '250px',
                  '@media only screen and (min-width: 1200px)': {
                    alignSelf: 'center',
                  },
                }}
              />
            );
          }}
        />
        <ProgressContainer>
          <Controller
            name="progress"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Stack
                  spacing={0.25}
                  sx={{
                    width: 250,
                  }}
                >
                  <InputLabel
                    htmlFor="progress"
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    Progress:
                  </InputLabel>
                  <ProgressSlider
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <label style={{ minWidth: '2.5rem' }}>To Do</label>
                    <Slider
                      {...field}
                      marks
                      min={0}
                      max={100}
                      step={25}
                      id="progress"
                      aria-label="Progress"
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `${value}%`}
                      disabled={submitting}
                      onChange={(e) => handleChangeProgress(e, onChange)}
                    />
                    <label>Complete</label>
                  </ProgressSlider>
                </Stack>
              );
            }}
          />
          {submitting ? (
            <Stack
              position="end"
              sx={{ height: '100%', placeSelf: 'flex-end' }}
            >
              <CircularProgress size={24} color="primary" />
            </Stack>
          ) : (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={submitting}
              sx={{ alignSelf: 'center' }}
            >
              {isEditing ? 'Save' : 'Create'}
            </Button>
          )}
        </ProgressContainer>
      </BottomContainer>
      {!!error && (
        <Typography color="error" fontSize="12px">
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default TaskForm;
