import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const minHeight = '1.5em';

const Tabs = styled(MuiTabs)`
  flex-shrink: 0;
  min-height: ${minHeight};
`;

const Tab = styled(MuiTab)`
  min-width: auto;
  line-height: 1.1;
  min-height: ${minHeight};
  border-radius: ${minHeight};
  :link,
  :active,
  :visited,
  :hover {
    text-decoration: none;
  }
  :hover {
    color: ${({ theme, color }) => theme.palette[color].main};
  }
`;

const TabList = ({
  tabs,
  value,
  onChange,
  color = 'primary',
  tabProps = () => ({}),
  ...props
}) => (
  <Tabs
    {...props}
    value={value}
    role="navigation"
    onChange={(e, v) => onChange(v)}
  >
    {tabs
      .filter((t) => Boolean(t?.enabled))
      .map((t) => (
        <Tab
          {...tabProps(t)}
          key={t.label}
          color={color}
          label={t.label}
          iconPosition="start"
          icon={!!t?.icon ? <SvgIcon component={t.icon} /> : undefined}
        />
      ))}
  </Tabs>
);

const NavigationTabs = ({ color, tabs = [], ...props }) => {
  const location = useLocation();
  const currentTab = tabs.findIndex((t) => t.to === location.pathname);
  const [value, setValue] = useState(currentTab === -1 ? 0 : currentTab);

  // Active tab effect based on current pathname.
  useEffect(() => {
    setValue(currentTab === -1 ? 0 : currentTab);
  }, [currentTab]);

  if (tabs.some((t) => !t?.label || !t?.to)) {
    console.error(
      'LinkTabs: All tab objects must have label and to properties.'
    );
    return <Typography color="error">Check console.</Typography>;
  }

  return (
    <TabList
      {...props}
      tabs={tabs}
      value={value}
      color={color}
      onChange={(t) => setValue(t)}
      tabProps={(t) => ({ to: t.to, component: Link })}
    />
  );
};

export default NavigationTabs;
