import { useState, useEffect, useRef } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  KeyBindingUtil,
  CompositeDecorator,
  Modifier,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { useDispatch, useSelector } from 'react-redux';

import RichTextButtons from './RichTextButtons';
import ActionButtons from './ActionButtons';
import EmojiPicker from '../../EmojiPicker';
import Suggestions from './Suggestions';
import Messages from '../../../store/chat/messageActions';
import { selectCurrentChat } from '../../../store/chat/chatSelectors';
import { selectCurrentUserUUID } from '../../../store/auth/authSelectors';
import { selectUserList } from '../../../store/user/userSelectors';

// Including this fixes the placeholder styling (without having to
// manage it ourselves). Without this, the styles will not apply;
// the placeholder text would show up on it's own line and not hide
// when content was entered. This also fixes the context menu issue
// where the placeholder text was denying the menu when hovered over
// the (placeholder) text.
import 'draft-js/dist/Draft.css';
import { MAX_LONG_LENGTH } from '../../../configs/validation';

const DEFAULT_PLACEHOLDER = 'Write your message and hit enter to send...';
const MAX_MESSAGE_LENGTH = MAX_LONG_LENGTH.value;

// @@ streamio allows custom notifications, that can even send a message to the chat
// @@ this may remove the neccessity for tags
export const tags = {
  text: 'Text',
  file: 'File',
  image: 'Image',
  callMissed: 'CallMissed',
  busyCall: 'CallBusy',
  promote: 'Promote',
  demote: 'Demote',
  remove: 'Remove',
  join: 'Join',
  leave: 'Leave',
  subject: 'Subject',
  gap: 'Gap',
  shred: 'Shred',
  clear: 'Clear',
  admin: 'Admin',
  delete: 'Delete',
};

const InputWrapper = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  border: 1px solid #aaa;
  border-radius: 10px;
  background-color: #fff;
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const EditorWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  position: relative;
  width: 100%;
  min-height: 36px;
  word-break: break-word;
  z-index: 1;

  .public-DraftEditorPlaceholder-root {
    z-index: 0;
    height: 100%;
  }

  .DraftEditor-editorContainer {
    z-index: 0;
    height: 100%;
  }

  .DraftEditor-root {
    max-height: 120px;
    overflow-y: auto;
  }
`;

const Mention = styled.span`
  color: white;
  background: #5383ff;
  padding: 0 1px;
`;

const RichTextButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(238, 238, 238);
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #ddd;
  z-index: 3;
`;

function handleMentions(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'mention'
    );
  }, callback);
}

function HandleMention({ entityKey, offsetKey, children }) {
  return (
    <Mention data-entity-key={entityKey} data-offset-key={offsetKey}>
      {children}
    </Mention>
  );
}

const compositeDecorator = new CompositeDecorator([
  { strategy: handleMentions, component: HandleMention },
]);

function filterSuggestions(suggestions, text) {
  return suggestions.reduce(
    (acc, s) =>
      s.name.toLowerCase().includes(text.toLowerCase()) ? [...acc, s] : acc,
    []
  );
}

export default function UpdateMessageInput({
  editValue,
  editId,
  handleCloseEditor,
}) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const ref = useRef();
  const currentUserUUID = useSelector(selectCurrentUserUUID);
  const currentChat = useSelector(selectCurrentChat);
  const users = useSelector(selectUserList);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(markdownToDraft(editValue)))
  );
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  const [cursor, setCursor] = useState(0);
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState({});
  const [openEmojis, setOpenEmojis] = useState(false);
  const [formats, setFormats] = useState('');
  const [isMaxLengthMessage, setIsMaxLengthMessage] = useState(false);

  const { cid: chatId } = currentChat;
  const contentState = editorState.getCurrentContent();
  const testRegex = /\B@([\w]+)?/g;
  // If we don't have the allParticipants array for whatever reason,
  // default to all users in the organization.
  const suggestions = Object.keys(currentChat.state.members)
    .filter((m) => m !== currentUserUUID)
    .map((m) => {
      const defaultUser = {
        userProperties: { uuid: -1 },
        email: 'former.member@mailinator.ca',
        firstName: 'Former',
        lastName: 'Member',
        active: true,
      };
      const user =
        users.find((u) => m === u.userProperties.uuid) || defaultUser;

      return {
        id: user.userProperties.uuid,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        active: user.active,
      };
    })
    .filter((user) => user.active);
  const match = testRegex.exec(contentState.getPlainText());
  const filtered = filterSuggestions(
    suggestions,
    match ? match[0].replace('@', '') : ''
  );
  const canSend =
    editorState.getCurrentContent().getPlainText().length <=
      MAX_MESSAGE_LENGTH &&
    editorState.getCurrentContent().getPlainText() !== '';

  useEffect(() => {
    // Logic for controlling updates to the mention suggestions box
    const regex = /\B@([\w]+)?/g;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const selectedBlock = content.getBlockForKey(selection.getAnchorKey());
    const matches = regex.exec(selectedBlock.getText());
    const windowSelection = window.getSelection();
    const isBetweenSelection =
      matches &&
      selection.getFocusOffset() <= matches.index + matches[0].length &&
      selection.getFocusOffset() > matches.index;

    if (matches && isBetweenSelection && !open) {
      setOpen(true);

      if (windowSelection.focusNode && !offset) {
        const range = windowSelection.getRangeAt(0).cloneRange();
        range.collapse(true);

        // Bounndry element of the whole window
        const clientRect = range.getClientRects()[0];
        // Boundry for the input containing element
        const boundingRect = ref.current.getBoundingClientRect();

        setOffset(clientRect.left - boundingRect.left);
      }
    }

    if (!isBetweenSelection && open) {
      setOpen(false);
    }

    if (!matches && offset) {
      setOffset(null);
    }
  }, [editorState, offset, open]);

  useEffect(() => {
    setEditorState(EditorState.createEmpty(compositeDecorator));
    setPlaceholder(DEFAULT_PLACEHOLDER);
    inputRef.current.focus();
  }, [chatId]);

  useEffect(() => {
    if (placeholder !== DEFAULT_PLACEHOLDER) {
      setPlaceholder(DEFAULT_PLACEHOLDER);
    }
  }, [placeholder]);

  const onChange = (nextState) => {
    const nextContent = nextState.getCurrentContent();
    const oldContent = editorState.getCurrentContent();

    setEditorState(nextState);

    // Only fire this on actual key presses; not on focus,
    // blur, or selection as draftjs calls onChange for
    // selection events as well as key presses.
    if (nextContent !== oldContent) {
      currentChat.keystroke();
    }
  };

  const addMention = (idx) => {
    const regex = /\B@([\w]+)?/g;
    const text = contentState.getPlainText();
    const matches = regex.exec(text);
    const mention = filtered[idx];

    if (matches && mention?.active !== undefined && mention.active) {
      const selection = editorState.getSelection();
      const range = selection.merge({
        anchorOffset: matches.index,
        focusOffset: matches.index + matches[0].length,
      });

      const contentStateWithEntity = contentState.createEntity(
        'mention',
        'IMMUTABLE',
        { email: mention.email }
      );
      const key = contentStateWithEntity.getLastCreatedEntityKey();

      const contentWithRemovedText = Modifier.replaceText(
        contentStateWithEntity,
        range,
        mention.name,
        null,
        key
      );

      const contentStateWithData = Modifier.setBlockData(
        contentWithRemovedText,
        range,
        { type: 'mention', email: mention.email }
      );

      const nextEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithData,
      });

      setEditorState(EditorState.moveFocusToEnd(nextEditorState));

      const mentionRange = selection.merge({
        anchorOffset: matches.index + mention?.name.length,
        focusOffset: matches.index + mention?.name.length,
      });

      const addSpace = Modifier.insertText(
        contentWithRemovedText,
        mentionRange,
        ' '
      );

      const addSpaceEditorState = EditorState.push(
        editorState,
        addSpace,
        'insert-characters'
      );

      setEditorState(addSpaceEditorState);
    }
  };

  const handleEmojiSelect = (e, emoji) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      emoji.emoji
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );

    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );

    setOpenEmojis(false);
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength > MAX_MESSAGE_LENGTH) {
      return 'handled';
    }
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handlePastedText = (text, html, nextState) => {
    const currentContent = nextState.getCurrentContent();
    const currentSelection = nextState.getSelection();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (html) {
      const strippedText = text.replace(/(<([^>]+)>)/gi, '');
      const remainingSpace =
        MAX_MESSAGE_LENGTH + 1 - (currentContentLength - selectedTextLength);
      if (strippedText.length <= remainingSpace) {
        // If the pasted text fits within the remaining space, replace it
        const newContent = Modifier.replaceText(
          currentContent,
          currentSelection,
          strippedText
        );

        const newEditorState = EditorState.push(nextState, newContent);
        setEditorState(
          EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
          )
        );

        return 'handled';
      } else {
        const trimmedText = strippedText.slice(0, remainingSpace);

        const newContent = Modifier.replaceText(
          currentContent,
          currentSelection,
          trimmedText
        );

        const newEditorState = EditorState.push(nextState, newContent);
        setEditorState(
          EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
          )
        );
        return 'handled';
      }
      return 'not-handled';
    }
  };

  const updateStates = () => {
    currentChat.stopTyping();

    setEditorState(EditorState.createEmpty());
    setPlaceholder(DEFAULT_PLACEHOLDER);
    setEditorState(
      EditorState.moveFocusToEnd(EditorState.createEmpty(compositeDecorator))
    );
  };

  const textValidation = () => {
    const content = editorState.getCurrentContent();
    const rawState = convertToRaw(content);
    const rawMarkdown = draftToMarkdown(rawState, {
      escapeMarkdownCharacters: false,
      entityItems: {
        mention: { open: () => '@@', close: () => '@@' },
      },
    });
    const mentions = Object.values(rawState.entityMap)
      .filter((e) => e.type === 'mention')
      .map((e) => e.data.email);

    // Trim message so no extra space at start or end.
    let messageText = rawMarkdown.trim();

    if (mentions.length > 0) {
      const matches = rawMarkdown.match(/\B(@@[\w\s]+@@)\B/g);
      if (matches) {
        messageText = matches.reduce(
          (acc, m, i) => acc.replace(m, mentions[i]),
          rawMarkdown
        );
      }
    }

    // Check message Length
    if (content.getPlainText().length > MAX_MESSAGE_LENGTH) {
      return undefined;
    }
    // unicode for embedded obj (voice dictation found to add it to message)
    messageText = messageText.replaceAll(/([\uFFFC])/g, '');
    return messageText;
  };

  const updateMessage = async () => {
    const messageText = textValidation();

    // determines if message without spaces is not empty
    const validText = Boolean(messageText.replaceAll(/([ ])/g, '').length > 0);

    if (chatId) {
      if (messageText !== '' && validText) {
        dispatch(
          Messages.updateMessage({ set: { text: messageText } }, editId)
        );
      }

      updateStates();
    }

    handleCloseEditor();
  };

  const keyBinding = (e) => {
    let command = '';

    switch (e.keyCode) {
      case 13:
        // enter key
        if (!e.nativeEvent.shiftKey) {
          command = 'update-message';
        }

        if (open) {
          command = 'commit-selection';
        }

        break;
      case 66:
        // ctrl (cmd) + b
        if (KeyBindingUtil.hasCommandModifier(e)) {
          command = 'bold';
        }

        break;
      case 38:
        // arrow up
        if (open) {
          command = 'mention-up';
        }

        break;
      case 40:
        // arrow down
        if (open) {
          command = 'mention-down';
        }

        break;
      case 27:
        // esc
        command = 'exit';

        break;
      default:
        command = null;
    }

    return command || getDefaultKeyBinding(e);
  };

  const handleCommand = (command, state) => {
    switch (command) {
      case 'backspace': {
        const currentContent = state.getCurrentContent();
        const type = currentContent.getFirstBlock().getType();
        const isList =
          type === 'unordered-list-item' || type === 'ordered-list-item';
        const isEmpty = currentContent.getPlainText() === '';

        if (isList && isEmpty) {
          setEditorState(RichUtils.toggleBlockType(editorState, 'unstyled'));

          return 'handled';
        }

        return 'not-handled';
      }

      case 'update-message':
        updateMessage();

        return 'handled';
      case 'bold':
        setEditorState(RichUtils.toggleInlineStyle(state, 'BOLD'));

        return 'handled';
      case 'mention-up':
        setCursor((cursor - 1 + filtered.length) % filtered.length);

        return 'handled';
      case 'mention-down':
        setCursor((cursor + 1) % filtered.length);

        return 'handled';
      case 'commit-selection':
        addMention(cursor);
        setOffset(null);
        setOpen(false);

        return 'handled';
      case 'exit':
        dispatch(Messages.toggleEditing(null));

        return 'handled';
      default:
        return 'not-handled';
    }
  };

  const formatText = (type) => {
    const currentType = RichUtils.getCurrentBlockType(editorState);
    const isStyled = currentType === 'unstyled';

    if (type === 'UL') {
      setEditorState(
        RichUtils.toggleBlockType(editorState, 'unordered-list-item')
      );
      setPlaceholder(!isStyled ? DEFAULT_PLACEHOLDER : '');
    } else if (type === 'OL') {
      setEditorState(
        RichUtils.toggleBlockType(editorState, 'ordered-list-item')
      );
      setPlaceholder(!isStyled ? DEFAULT_PLACEHOLDER : '');
    } else {
      setEditorState(RichUtils.toggleInlineStyle(editorState, type));
    }
  };

  const handleFormatChange = (format) => {
    setFormats(format);
  };

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let hide = false;
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hide = true;
    }
  }

  useEffect(() => {
    if (
      editorState.getCurrentContent().getPlainText().length > MAX_MESSAGE_LENGTH
    ) {
      setIsMaxLengthMessage(true);
    } else {
      setIsMaxLengthMessage(false);
    }
  }, [editorState]);

  return (
    <InputWrapper editValue={editValue}>
      <InputContainer editValue={editValue}>
        <InputBox ref={ref}>
          <EditorWrapper onClick={() => inputRef.current.focus()} hide={hide}>
            <Editor
              ref={inputRef}
              placeholder={placeholder}
              editorState={editorState}
              onChange={onChange}
              keyBindingFn={keyBinding}
              handleKeyCommand={handleCommand}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              stripPastedStyles
              spellCheck
            />
          </EditorWrapper>
          <Suggestions
            list={filtered}
            open={open}
            offset={offset}
            cursor={cursor}
            handeleMention={addMention}
          />
        </InputBox>
        <RichTextButtonsContainer>
          {/* eslint-disable-next-line max-len */}
          <RichTextButtons
            handleFormat={formatText}
            formats={formats}
            handleFormatChange={handleFormatChange}
            aStyle={editorState.getCurrentInlineStyle()}
          />
          <ActionButtons
            handleEmoji={() => setOpenEmojis(!openEmojis)}
            isUpdate={editValue}
            handleSend={updateMessage}
            handleCloseEditor={handleCloseEditor}
            canSend={canSend}
            isEdit={Boolean(editId)}
          />
          <EmojiPicker
            open={openEmojis}
            onClose={() => setOpenEmojis(false)}
            handleSelection={handleEmojiSelect}
          />
        </RichTextButtonsContainer>
      </InputContainer>
      {isMaxLengthMessage && (
        <Box sx={{ fontSize: '13px', color: '#f83245' }}>
          {MAX_LONG_LENGTH.message}
        </Box>
      )}
    </InputWrapper>
  );
}
