import { validateEmailAddress } from '../../utils/formUtils';

class UserFinder {
  constructor(userList = []) {
    this.userList = userList;
    this.cache = new Map(userList.map((u) => [u.email, u]));
  }

  findByEmailOrFullName = (id) => {
    const cachedUser = this.cache.get(id);
    if (!!cachedUser) {
      return cachedUser;
    }

    const foundUser = this.userList.find((user) =>
      validateEmailAddress(id) ? user.email === id : user.fullName === id
    );

    if (!!foundUser) {
      this.cache.set(id, foundUser);
      return foundUser;
    }

    return {
      profileImageUrl: '',
      firstName: 'Former',
      lastName: 'Member',
    };
  };
}

export default UserFinder;
