import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import MuiChip from '@mui/material/Chip';
import MuiList from '@mui/material/List';
import SvgIcon from '@mui/material/SvgIcon';
import Divider from '@mui/material/Divider';
import MuiAvatar from '@mui/material/Avatar';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MuiListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';

import {
  formatFileSize,
  getItemTypeLabel,
  getFolderAccessLabel,
} from '../../lib/files';
import {
  fileSizeIsSupported,
  fileExtensionIsSupported,
} from '../file-viewer/FileViewer';
import {
  selectCanEditFolderAccess,
  selectInheritedFolderProperties,
} from '../../store/storage/storageSelectors';
import { FOLDER_TYPES } from '../../configs';
import Groups from '../../store/group/groupActions';
import { selectGroupList } from '../../store/group/groupSelectors';

import { FileIcon, FolderIcon } from './FileIcon';
import GroupIcon from '../../assets/icons/group.svg?react';
import CloseIcon from '../../assets/icons/close.svg?react';
import ViewIcon from '../../assets/icons/eye-outline.svg?react';
import LockIcon from '../../assets/icons/lock-outline.svg?react';
import TrashIcon from '../../assets/icons/trash-2-outline.svg?react';
import DownloadIcon from '../../assets/icons/download-outline.svg?react';

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.75rem;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  align-self: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.25rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
`;

const StyledIcon = styled(SvgIcon)`
  font-size: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
`;

const ItemName = styled(Typography)`
  flex-grow: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const List = styled(MuiList)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 1rem;
`;

const ListItem = styled(MuiListItem)`
  padding: 0.8125rem 0;
  & p {
    font-size: 13px;
    line-height: 1.5;
  }
`;

const InfoSubtitle = styled(Typography)`
  font-size: 13px;
  line-height: 1.5;
  min-width: 100px;
  padding-right: 5px;
`;

const ActionButtons = styled(ListItem)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 0;
  gap: 0.75rem;
`;

const Button = styled(MuiButton)`
  min-width: 160px;
  color: #fff;
`;

const Chip = styled(MuiChip)`
  height: 1.75rem;
  & .MuiChip-avatar {
    color: #fff;
  }
  & .MuiChip-label {
    margin-left: 0.1rem;
  }
`;

const Avatar = styled(MuiAvatar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const ItemInfo = ({
  item,
  isDownloading,
  onView,
  onEditAccess,
  onDownload,
  onDelete,
  onClose,
}) => {
  const dispatch = useDispatch();
  const inheritedFolderProperties = useSelector(
    selectInheritedFolderProperties
  );
  const groupList = useSelector(selectGroupList);
  const canEditFolderAccess = useSelector(selectCanEditFolderAccess);

  const canEdit = Boolean(item?.isEditable);
  const canView =
    !item?.isFolder &&
    fileExtensionIsSupported(item?.extension) &&
    fileSizeIsSupported(item?.extension, item?.size);

  const itemTypeLabel = getItemTypeLabel(item, inheritedFolderProperties);
  const folderType = !!inheritedFolderProperties
    ? inheritedFolderProperties.folderType
    : item.folderType;
  const folderAccessLabel = getFolderAccessLabel(folderType);

  const groupIds = !!inheritedFolderProperties
    ? inheritedFolderProperties.groupIds
    : item?.groupIds;
  const groups = groupList.filter((g) => (groupIds || []).includes(g.id));

  // Fetch groups.
  useEffect(() => {
    if (folderType === FOLDER_TYPES.PRIVATE) {
      dispatch(Groups.fetchAll());
    }
  }, [dispatch, folderType]);

  return (
    <>
      <Header>
        <TitleContainer>
          <StyledIcon>
            {item.isFolder ? (
              <FolderIcon type={folderType} />
            ) : (
              <FileIcon extension={item?.extension} />
            )}
          </StyledIcon>
          <ItemName variant="h5">{item.name}</ItemName>
        </TitleContainer>
        <CloseButton size="small" onClick={onClose}>
          <SvgIcon component={CloseIcon} />
        </CloseButton>
      </Header>
      <List>
        <Divider />
        <ListItem>
          <InfoSubtitle variant="subtitle2">Last Updated</InfoSubtitle>
          <Typography>{item.updatedAt}</Typography>
        </ListItem>
        <Divider />
        <ListItem>
          <InfoSubtitle variant="subtitle2">Type</InfoSubtitle>
          <Typography>{itemTypeLabel}</Typography>
        </ListItem>
        <Divider />
        <ListItem>
          <InfoSubtitle variant="subtitle2">Created By</InfoSubtitle>
          <Typography>{item.createdByName}</Typography>
        </ListItem>
        {item.isFolder && (
          <>
            <Divider />
            <ListItem>
              <InfoSubtitle
                variant="subtitle2"
                sx={{ alignSelf: 'flex-start' }}
              >
                Access
              </InfoSubtitle>
              {folderType === FOLDER_TYPES.PRIVATE ? (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  gap={0.5}
                  sx={{ width: '100%' }}
                >
                  {!!groups.length ? (
                    groups.map((g) => (
                      <Chip
                        key={g.id}
                        label={g.name}
                        avatar={
                          <Avatar>
                            <SvgIcon component={GroupIcon} fontSize="small" />
                          </Avatar>
                        }
                      />
                    ))
                  ) : (
                    <CircularProgress size="1rem" />
                  )}
                </Stack>
              ) : (
                <Typography>{folderAccessLabel}</Typography>
              )}
            </ListItem>
          </>
        )}
        {!item.isFolder && (
          <>
            <Divider />
            <ListItem>
              <InfoSubtitle variant="subtitle2">Size:</InfoSubtitle>
              <Typography>
                {item?.size ? formatFileSize(item.size, 2) : '\u2015'}
              </Typography>
            </ListItem>
          </>
        )}
        <Divider />
        <ActionButtons>
          {canView && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SvgIcon component={ViewIcon} />}
              onClick={onView}
            >
              View File
            </Button>
          )}
          {canEdit && canEditFolderAccess && item.isFolder && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<SvgIcon component={LockIcon} />}
              onClick={onEditAccess}
            >
              Edit Access
            </Button>
          )}
          {!item.isFolder && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SvgIcon component={DownloadIcon} />}
              onClick={onDownload}
              disabled={item.isFolder || isDownloading}
            >
              Download
            </Button>
          )}
          {canEdit && (
            <Button
              variant="contained"
              color="error"
              startIcon={<SvgIcon component={TrashIcon} />}
              onClick={onDelete}
              disabled={isDownloading}
            >
              Delete
            </Button>
          )}
        </ActionButtons>
      </List>
    </>
  );
};

export default ItemInfo;
