export const EVENT_TYPES = {
  INCIDENT: 'INCIDENT',
  TABLETOP_EXERCISE: 'TABLETOP_EXERCISE',
};

export const EVENT_TYPE_LABELS = {
  INCIDENT: 'Incident',
  TABLETOP_EXERCISE: 'Tabletop Exercise',
};

export const ACTIVITY_TYPES = {
  UPDATE: 'UPDATE',
  TASK: 'TASK',
};

export const ACTIVITY_TYPE_LABELS = {
  UPDATE: 'Update',
  TASK: 'Task',
};

export const TASK_STATUS = {
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const TASK_STATUS_LABELS = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

export const TASK_STATUS_OPTIONS = [
  {
    label: TASK_STATUS_LABELS.TO_DO,
    value: TASK_STATUS.TO_DO,
  },
  {
    label: TASK_STATUS_LABELS.IN_PROGRESS,
    value: TASK_STATUS.IN_PROGRESS,
  },
  {
    label: TASK_STATUS_LABELS.COMPLETE,
    value: TASK_STATUS.COMPLETE,
  },
];

export const TASK_PRIORITY = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const TASK_PRIORITY_LABELS = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
};

export const TASK_PRIORITY_OPTIONS = [
  {
    label: TASK_PRIORITY_LABELS.LOW,
    value: TASK_PRIORITY.LOW,
  },
  {
    label: TASK_PRIORITY_LABELS.MEDIUM,
    value: TASK_PRIORITY.MEDIUM,
  },
  {
    label: TASK_PRIORITY_LABELS.HIGH,
    value: TASK_PRIORITY.HIGH,
  },
];
