import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { MAX_LENGTH } from '../../configs/validation';
import { handlePaste } from '../../lib/input';
import useToast from '../../lib/hooks/useToast';
import { validatePassword } from '../../utils/formUtils';
import Authentication from '../../store/auth/authActions';

import BasicModal from '../BasicModal';
import PasswordInput from '../PasswordInput';

const ChangePasswordModal = ({ open, onClose }) => {
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      new: '',
      confirm: '',
    },
  });
  const dispatch = useDispatch();
  const [toastElement, openToast] = useToast();
  const [loading, setLoading] = useState(false);

  const newPassword = watch('new');
  const confirmPassword = watch('confirm');
  const buttonDisabled =
    !newPassword ||
    newPassword.length > MAX_LENGTH.value ||
    !confirmPassword ||
    confirmPassword.length > MAX_LENGTH.value;

  const handlePasswordValidation = (value, otherValue) => {
    const errors = validatePassword(value, otherValue);
    if (!!Object.keys(errors).length) {
      return Object.values(errors)?.[0]; // Display first error.
    }
    return true;
  };

  const handleClose = (showSuccess = false) => {
    onClose(showSuccess);
    reset();
  };

  const handleSave = (data) => {
    const password = data.new.trim();

    setLoading(true);
    return dispatch(Authentication.changePassword(password))
      .then(() => handleClose(true))
      .catch((e) => {
        console.error(e);
        openToast('Something went wrong.', 'error', 3000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <BasicModal
      open={open}
      onClose={() => handleClose()}
      title="Change Password"
      buttonDisabled={buttonDisabled}
      onClickButton={handleSubmit(handleSave)}
    >
      <Stack
        gap={2}
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ mb: '1.5px' }}
      >
        <Controller
          name="new"
          control={control}
          rules={{
            required: 'Please enter new password.',
            validate: (value) =>
              handlePasswordValidation(value, confirmPassword),
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PasswordInput
                {...field}
                autoFocus
                disabled={loading}
                label="New Password"
                error={!!errors?.new}
                helperText={errors?.new?.message}
                onPaste={(e) => handlePaste(e, onChange)}
              />
            );
          }}
        />
        <Controller
          name="confirm"
          control={control}
          rules={{
            required: 'Please confirm new password.',
            validate: (value) => handlePasswordValidation(value, newPassword),
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PasswordInput
                {...field}
                disabled={loading}
                label="Confirm Password"
                error={!!errors?.confirm}
                helperText={errors?.confirm?.message}
                onPaste={(e) => handlePaste(e, onChange)}
              />
            );
          }}
        />
      </Stack>
      {toastElement}
    </BasicModal>
  );
};

export default ChangePasswordModal;
