import unescape from 'lodash.unescape';
import { createSelector } from 'reselect';

import {
  WELCOME_PACKAGE_STATES,
  WELCOME_PACKAGE_ERRORS,
  WELCOME_PACKAGE_ERROR_MESSAGES,
} from '../../configs/welcomePackage';

export const selectWelcomeState = (state) => state.welcome;

export const selectUid = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.uid
);

export const selectMaxUsers = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.maxUsers
);

export const selectMainAdminUser = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.mainAdminUser
);

export const selectLastUpdateTimestamp = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.lastUpdateTimestamp
);

export const selectErrorCode = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.errorCode
);

export const selectPopulationError = createSelector(
  selectErrorCode,
  (errorCode) =>
    errorCode === WELCOME_PACKAGE_ERRORS.ERROR_DURING_POPULATION
      ? WELCOME_PACKAGE_ERROR_MESSAGES[errorCode]
      : ''
);

export const selectErrorToastMessage = createSelector(
  selectErrorCode,
  (errorCode) =>
    ![
      WELCOME_PACKAGE_ERRORS.NOT_FOUND,
      WELCOME_PACKAGE_ERRORS.ERROR_DURING_POPULATION,
    ].includes(errorCode)
      ? WELCOME_PACKAGE_ERROR_MESSAGES[errorCode]
      : ''
);

export const selectIsFetching = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.fetching
);

export const selectIsSaving = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.saving
);

export const selectWasModified = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.modified
);

export const selectIsSubmittedOrCompleted = createSelector(
  selectWelcomeState,
  (welcomeState) =>
    [
      WELCOME_PACKAGE_STATES.SUBMITTED,
      WELCOME_PACKAGE_STATES.COMPLETED,
    ].includes(welcomeState.status)
);

export const selectShouldPromptBeforeUnload = createSelector(
  selectIsSubmittedOrCompleted,
  selectWasModified,
  (submittedOrCompleted, wasModified) => !submittedOrCompleted && wasModified
);

export const selectOrgCode = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.orgCode
);

export const selectUsers = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.users
);

export const selectWarRooms = createSelector(
  selectWelcomeState,
  (welcomeState) => welcomeState.warRooms
);

export const selectWarRoomByKey = createSelector(
  selectWarRooms,
  (_, key) => key,
  (warRooms, warRoomKey) => {
    const warRoom = warRooms.find((w) => w.key === warRoomKey);
    return (
      warRoom || {
        label: '',
        admin: null,
        members: [],
      }
    );
  }
);

export const selectFileFolders = createSelector(
  selectWelcomeState,
  (welcomeState) =>
    welcomeState.fileFolders.map((f) => ({ ...f, label: unescape(f.label) }))
);
