import dayjs from 'dayjs';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { FILE_FORMAT } from '../../configs';
import { downloadFile } from '../../utils/networkUtils';
import Playbooks from '../../store/playbook/playbookActions';
import { selectPlaybooksWithCategory } from '../../store/playbook/playbookSelectors';

import BasicModal from '../BasicModal';
import TransferList from '../TransferList';

const Label = styled.label`
  font-size: 14px;
`;

const ALL_CATEGORIES = 'All';

const ExportPlaybooksModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const playbooks = useSelector(selectPlaybooksWithCategory);
  const [isPDF, setIsPDF] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedPlaybooks, setSelectedPlaybooks] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(ALL_CATEGORIES);

  const playbookCategories = Array.from(
    new Set(playbooks.map((item) => item.categoryName))
  ).sort();
  const exportDisabled = loading || !selectedPlaybooks.length;

  const handleChangeFileFormat = useCallback(
    () => setIsPDF((prev) => !prev),
    []
  );

  const handleChangeCategoryFilter = useCallback(
    (e) => setCategoryFilter(e.target.value),
    []
  );

  const handleSelectPlaybooks = useCallback(
    (value) => setSelectedPlaybooks(value),
    []
  );

  const itemFilter = useCallback(
    (item) =>
      categoryFilter === 'All' ? true : item.categoryName === categoryFilter,
    [categoryFilter]
  );

  const handleClose = useCallback(() => {
    setSelectedPlaybooks([]);
    setCategoryFilter(ALL_CATEGORIES);
    setIsPDF(true);
    onClose();
  }, [onClose]);

  const handleExport = useCallback(() => {
    const ids = selectedPlaybooks.map((p) => p.value);
    const fileFormat = isPDF ? FILE_FORMAT.PDF : FILE_FORMAT.DOCX;
    const timestamp = dayjs().format('MM-DD-YYYY_hh-mm-ss');

    setLoading(true);
    return dispatch(Playbooks.exportMultiplePlaybooks(ids, fileFormat))
      .then((res) => {
        downloadFile({
          name: `Playbooks_Export_${timestamp}.zip`,
          objectUrl: res.value,
        });
        handleClose();
      })
      .finally(() => setLoading(false));
  }, [dispatch, handleClose, isPDF, selectedPlaybooks]);

  return (
    <BasicModal
      open={open}
      buttonText="Export"
      onClose={handleClose}
      title="Export Playbooks"
      onClickButton={handleExport}
      buttonDisabled={exportDisabled}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1.5}
      >
        <Typography fontWeight="bold" sx={{ lineHeight: 1 }}>
          Filter categories:
        </Typography>
        <Select
          displayEmpty
          variant="standard"
          disabled={loading}
          value={categoryFilter}
          defaultValue={ALL_CATEGORIES}
          onChange={handleChangeCategoryFilter}
          sx={{ minWidth: 175, '& .MuiSelect-select': { py: 0.25 } }}
        >
          <MenuItem value={ALL_CATEGORIES}>{ALL_CATEGORIES}</MenuItem>
          {playbookCategories.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <TransferList
        items={playbooks}
        disabled={loading}
        value={selectedPlaybooks}
        leftListTitle="Available Playbooks"
        rightListTitle="Playbooks to Export"
        itemFilter={itemFilter}
        onChange={handleSelectPlaybooks}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1.5}
      >
        <Label>
          <b>Playbook file format:</b>&emsp;DOCX
        </Label>
        <FormControlLabel
          label="PDF"
          labelPlacement="end"
          control={
            <Switch
              size="small"
              color="primary"
              checked={isPDF}
              disabled={loading}
              onChange={handleChangeFileFormat}
            />
          }
          sx={{
            '& .MuiFormControlLabel-label': {
              ml: '0.25rem',
              fontSize: '14px',
            },
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: 'inherit',
            },
          }}
        />
      </Stack>
    </BasicModal>
  );
};

export default ExportPlaybooksModal;
