import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useRef, useCallback } from 'react';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

// Including this fixes the placeholder styling (without having to
// manage it ourselves). Without this, the styles will not apply;
// the placeholder text would show up on it's own line and not hide
// when content was entered. This also fixes the context menu issue
// where the placeholder text was denying the menu when hovered over
// the (placeholder) text.
import 'draft-js/dist/Draft.css';
import FormatToolbar from './FormatToolbar';
import { MAX_LONG_LENGTH } from '../configs/validation';

const InputWrapper = styled.div`
  position: relative;
  margin: 1.5em 0;
`;

const InputContainer = styled.div`
  border: ${({ border }) =>
    border === 'false' ? '1px dashed #eee' : '1px solid #aaa'};
  border-radius: 4px;
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const EditorWrapper = styled.div`
  position: relative;
  padding: 0.4em 1em;
  width: 100%;
  min-height: 36px;
  word-break: break-word;
  border-bottom: 1px solid #ddd;

  .public-DraftEditorPlaceholder-root {
    z-index: 0;
  }

  .DraftEditor-editorContainer {
    z-index: 0;
  }

  div[data-block='true'] {
    margin-bottom: 0.75em;
  }

  .public-DraftEditor-content {
    ${(p) => (p.height ? `height: ${p.height} !important;` : '')};
    ${(p) => (p.minHeight ? `minHeight: ${p.minHeight};` : '')};
    ${(p) => (p.maxHeight ? `maxHeight: ${p.maxHeight};` : '')};
    overflow: auto;
  }
`;

const RichTextButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(238, 238, 238);
  border-radius: 0 0 4px 4px;
`;

export default function RichTextEditor({
  placeholder,
  height,
  minHeight,
  maxHeight,
  onMount,
  onChange = null,
  initialContent,
  handleMaxChar,
  autoFocus = false,
  hasBorder = true,
  ...props
}) {
  const inputRef = useRef();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      convertFromRaw(markdownToDraft(initialContent))
    )
  );
  const contentState = editorState.getCurrentContent();
  const stateRef = useRef();
  stateRef.current = editorState;

  const handleChange = useCallback(
    (nextState) => {
      setEditorState(nextState);

      if (onChange) {
        onChange(); // Trigger parent call to getContents.
      }
    },
    [onChange]
  );

  useEffect(() => {
    const getContents = (plainText = false) => {
      const content = stateRef.current.getCurrentContent();
      if (plainText) {
        return content.getPlainText();
      }

      const rawState = convertToRaw(content);
      const rawMarkdown = draftToMarkdown(rawState, {
        escapeMarkdownCharacters: false,
      });
      return rawMarkdown;
    };

    const setContents = (content) => {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(markdownToDraft(content)))
      );
    };

    if (onMount) {
      onMount(getContents, setContents);
    }
  }, []);

  const handleCommand = useCallback(
    (command, state) => {
      switch (command) {
        case 'backspace': {
          const currentContent = state.getCurrentContent();
          const type = currentContent.getFirstBlock().getType();
          const isList =
            type === 'unordered-list-item' || type === 'ordered-list-item';
          const isEmpty = currentContent.getPlainText() === '';

          if (isList && isEmpty) {
            setEditorState(RichUtils.toggleBlockType(editorState, 'unstyled'));

            return 'handled';
          }

          return 'not-handled';
        }
        case 'bold':
          setEditorState(RichUtils.toggleInlineStyle(state, 'BOLD'));
          return 'handled';
        case 'italic':
          setEditorState(RichUtils.toggleInlineStyle(state, 'ITALIC'));
          return 'handled';
        default:
          return 'not-handled';
      }
    },
    [editorState]
  );

  const formatText = useCallback(
    (code) => {
      if (code.startsWith('B:')) {
        setEditorState(RichUtils.toggleBlockType(editorState, code.substr(2)));
      } else {
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, code.substr(2))
        );
      }
    },
    [editorState]
  );

  const checkFormat = useCallback(
    (code) => {
      if (!code) {
        return `B:${RichUtils.getCurrentBlockType(editorState)}`;
      }
      if (code.startsWith('S:')) {
        return editorState.getCurrentInlineStyle().has(code.substr(2));
      }
      if (code.startsWith('B:')) {
        return RichUtils.getCurrentBlockType(editorState) === code.substr(2);
      }
      return undefined;
    },
    [editorState]
  );

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength === MAX_LONG_LENGTH.value) {
      // handle toast message using callback
      handleMaxChar();
      return 'handled';
    }
    return 'not-handled';
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      currentContentLength - selectedTextLength + pastedText.length >
      MAX_LONG_LENGTH.value
    ) {
      // handle toast message
      handleMaxChar();
      return 'handled';
    }
    return 'not-handled';
  };

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let hide = false;
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hide = true;
    }
  }

  return (
    <InputWrapper {...props}>
      <InputContainer border={hasBorder.toString()}>
        <InputBox>
          <EditorWrapper
            onClick={() => inputRef.current.focus()}
            hide={hide}
            {...{ height, minHeight, maxHeight }}
          >
            <Editor
              autoFocus={autoFocus}
              ref={inputRef}
              placeholder={placeholder}
              editorState={editorState}
              onChange={handleChange}
              handleKeyCommand={handleCommand}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              spellCheck
            />
          </EditorWrapper>
        </InputBox>
        <RichTextButtonsContainer>
          <FormatToolbar handleFormat={formatText} checkFormat={checkFormat} />
        </RichTextButtonsContainer>
      </InputContainer>
    </InputWrapper>
  );
}
