import Box from '@mui/material/Box';
import { memo, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import {
  PLAYBOOK_ITEM_TYPES,
  validatePlaybookItem,
  shouldTrimProperty,
} from '../../store/playbook/playbookTypes';

import Task from './task/Task';
import TaskForm from './task/TaskForm';
import Heading from './heading/Heading';
import HeadingForm from './heading/HeadingForm';

export default memo(function DraggableItem({
  id,
  type,
  moveItem,
  findItem,
  editItem,
  removeItem,
  isNew = false,
  ...props
}) {
  const originalIndex = findItem(id).index;
  const [errors, setErrors] = useState(null);
  const [isEditing, setIsEditing] = useState(isNew);
  const [localValues, setLocalValues] = useState({ ...props });

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveItem]
  );

  const opacity = isDragging ? 0 : 1;
  const cursor = isDragging ? 'grabbing' : 'grab';

  const [, drop] = useDrop(
    () => ({
      accept: Object.values(PLAYBOOK_ITEM_TYPES),
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem]
  );

  const handleChange = (key, value) => {
    setErrors(null);
    setLocalValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleClickEdit = () => setIsEditing(true);

  const handleClickDelete = () => {
    removeItem(id);
    setIsEditing(false);
  };

  const handleClickSave = () => {
    const trimmedValues = Object.fromEntries(
      Object.entries(localValues).map(([key, value]) => [
        key,
        shouldTrimProperty(key, value) ? value.trim() : value,
      ])
    );

    const errors = validatePlaybookItem(type, trimmedValues);
    if (!!errors) {
      return setErrors(errors);
    }

    setLocalValues({ ...trimmedValues }); // Update local values with trimmed.
    editItem(id, { ...trimmedValues, isNew: false });
    setIsEditing(false);
  };

  const handleClickCancel = () => {
    if (isNew) {
      return handleClickDelete();
    }
    setLocalValues({ ...props }); // Revert to current values.
    setIsEditing(false);
  };

  return isEditing ? (
    <Box sx={{ width: '100%' }}>
      {type === PLAYBOOK_ITEM_TYPES.HEADING ? (
        <HeadingForm
          {...localValues}
          errors={errors}
          onChange={handleChange}
          onClickSave={handleClickSave}
          onClickCancel={handleClickCancel}
        />
      ) : type === PLAYBOOK_ITEM_TYPES.TASK ? (
        <TaskForm
          {...localValues}
          errors={errors}
          onChange={handleChange}
          onClickSave={handleClickSave}
          onClickCancel={handleClickCancel}
        />
      ) : null}
    </Box>
  ) : (
    <Box
      ref={(node) => drag(drop(node))}
      sx={{ width: '100%', cursor, opacity }}
    >
      {type === PLAYBOOK_ITEM_TYPES.HEADING ? (
        <Heading
          {...localValues}
          isEditable
          onClickEdit={handleClickEdit}
          onClickDelete={handleClickDelete}
        />
      ) : type === PLAYBOOK_ITEM_TYPES.TASK ? (
        <Task
          {...localValues}
          isEditable
          onClickEdit={handleClickEdit}
          onClickDelete={handleClickDelete}
        />
      ) : null}
    </Box>
  );
});
