import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import BasicModal from '../BasicModal';

const SavingModal = ({ open }) => (
  <BasicModal
    open={open}
    title="Saving..."
    footerComponent={
      <Stack alignItems="center">
        <CircularProgress size={36} color="primary" />
      </Stack>
    }
    sx={{ width: '20rem' }}
  />
);

export default SavingModal;
