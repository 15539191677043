import { useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import useToast from '../../../lib/hooks/useToast';
import {
  selectToast,
  selectUserList,
  selectFetchingUsers,
} from '../../../store/management/managementSelectors';
import { managementApi } from '../../../configs/managementApi';
import useConfirmAsync from '../../../lib/hooks/useConfirmAsync';
import { TWO_FACTOR_VERIFICATION_TYPES } from '../../../configs';
import ManagementConsole from '../../../store/management/managementActions';

import UserModal from '../../user-admin/UserModal';
import ImportUsersModal from '../../user-admin/ImportUsersModal';
import UserAdminDataGrid from '../../user-admin/UserAdminDataGrid';

const MODALS = {
  USER: 'USER',
  IMPORT_USERS: 'IMPORT_USERS',
};

const UserAdministration = () => {
  const dispatch = useDispatch();
  const { id: enterpriseId } = useParams();
  const [toastElement, openToast] = useToast();
  const [confirmElement, openConfirm] = useConfirmAsync();
  const toast = useSelector(selectToast);
  const userList = useSelector(selectUserList);
  const fetchingUsers = useSelector(selectFetchingUsers);
  const [isTOTPEnabled, setIsTOTPEnabled] = useState(false);
  const [modal, setModal] = useState('');
  const [maxUsers, setMaxUsers] = useState(0);
  const [selection, setSelection] = useState([]);

  const selectedUser = userList.find((u) => u.id == selection?.[0]);
  const selectedEmail = selectedUser?.email;
  const selectedUUID = selectedUser?.userProperties?.uuid;
  const userAllowance =
    maxUsers - Number(userList.filter((u) => u.active).length);

  const dispatchSuccessToast = useCallback(
    (message) =>
      dispatch(
        ManagementConsole.setToast({
          message,
          severity: 'success',
        })
      ),
    [dispatch]
  );

  const dispatchErrorToast = useCallback(
    (message) =>
      dispatch(
        ManagementConsole.setToast({
          message,
          severity: 'error',
        })
      ),
    [dispatch]
  );

  const handleAdd = useCallback(() => {
    setSelection([]);
    setModal(MODALS.USER);
  }, []);

  const handleEdit = useCallback((e, id) => {
    if (!!id) {
      setSelection([id]);
    }
    setModal(MODALS.USER);
  }, []);

  const handleImport = useCallback(() => {
    setSelection([]);
    setModal(MODALS.IMPORT_USERS);
  }, []);

  const handleDeactivate = useCallback(
    () =>
      openConfirm({
        title: 'Deactivate User',
        content: (
          <Typography align="center">
            Are you sure you want to deactivate {selectedEmail}?
          </Typography>
        ),
        buttonColor: 'error',
        buttonText: 'Deactivate',
        onConfirm: () =>
          dispatch(ManagementConsole.deactivateUser(enterpriseId, selectedUUID))
            .then(() => {
              dispatchSuccessToast(`${selectedEmail} has been deactivated.`);
              dispatch(ManagementConsole.fetchUsers(enterpriseId));
            })
            .catch(() =>
              dispatchErrorToast(`Error deactivating ${selectedEmail}.`)
            ),
      }),
    [
      dispatch,
      dispatchErrorToast,
      dispatchSuccessToast,
      openConfirm,
      selectedEmail,
      selectedUUID,
      enterpriseId,
    ]
  );

  const handleReactivate = useCallback(
    () =>
      openConfirm({
        title: 'Reactivate User',
        content: (
          <Typography align="center">
            Are you sure you want to reactivate {selectedEmail}?
          </Typography>
        ),
        buttonColor: 'secondary',
        buttonText: 'Reactivate',
        onConfirm: () =>
          dispatch(ManagementConsole.reactivateUser(enterpriseId, selectedUUID))
            .then(() => {
              dispatchSuccessToast(`${selectedEmail} has been reactivated.`);
              dispatch(ManagementConsole.fetchUsers(enterpriseId));
            })
            .catch(() =>
              dispatchErrorToast(`Error reactivating ${selectedEmail}.`)
            ),
      }),
    [
      dispatch,
      dispatchErrorToast,
      dispatchSuccessToast,
      openConfirm,
      selectedEmail,
      selectedUUID,
      enterpriseId,
    ]
  );

  const handleSendActivation = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Send Activation Email',
        content: (
          <Typography align="center">
            Are you sure you want to send an activation email to {email}?
          </Typography>
        ),
        buttonText: 'Send',
        onConfirm: () =>
          dispatch(ManagementConsole.sendActivationEmail(enterpriseId, uuid))
            .then(() =>
              dispatchSuccessToast(`Activation email sent to ${email}.`)
            )
            .catch((e) => dispatchErrorToast(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorToast,
      dispatchSuccessToast,
      openConfirm,
      selectedEmail,
      selectedUUID,
      enterpriseId,
    ]
  );

  const handleResetTOTPEnrolment = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Reset TOTP Enrolment',
        content: (
          <Typography align="center">
            Are you sure you want to reset the TOTP enrolment for {email}? They
            will need to complete the enrolment process again to login.
          </Typography>
        ),
        buttonText: 'Reset',
        onConfirm: () =>
          dispatch(ManagementConsole.resetTOTPEnrolment(enterpriseId, uuid))
            .then(() =>
              dispatchSuccessToast(
                `TOTP enrolment has been reset for ${email}.`
              )
            )
            .catch((e) => dispatchErrorToast(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorToast,
      dispatchSuccessToast,
      openConfirm,
      selectedEmail,
      selectedUUID,
      enterpriseId,
    ]
  );

  const handleResetPassword = useCallback(
    (user) => {
      const email = !!user ? user?.email : selectedEmail;
      const uuid = !!user ? user?.userProperties?.uuid : selectedUUID;

      openConfirm({
        title: 'Reset Password',
        content: (
          <Typography align="center">
            Are you sure you want to reset the password for {email}?
          </Typography>
        ),
        buttonText: 'Reset',
        onConfirm: () =>
          dispatch(ManagementConsole.sendPasswordResetEmail(enterpriseId, uuid))
            .then(() =>
              dispatchSuccessToast(`Password reset email sent to ${email}.`)
            )
            .catch((e) => dispatchErrorToast(e.message)),
      });
    },
    [
      dispatch,
      dispatchErrorToast,
      dispatchSuccessToast,
      openConfirm,
      selectedEmail,
      selectedUUID,
      enterpriseId,
    ]
  );

  const handleImportUsers = useCallback(
    (users) => dispatch(ManagementConsole.importUsers(enterpriseId, users)),
    [dispatch, enterpriseId]
  );

  const handleImportFulfilled = useCallback(
    () => dispatch(ManagementConsole.fetchUsers(enterpriseId)),
    [dispatch, enterpriseId]
  );

  const handleSaveUser = useCallback(
    (data) =>
      dispatch(
        !!selectedUser
          ? ManagementConsole.updateUser(
              enterpriseId,
              data,
              selectedUser?.userProperties?.uuid
            )
          : ManagementConsole.createUser(enterpriseId, data)
      ),
    [dispatch, selectedUser, enterpriseId]
  );

  const handleSaveUserFulfilled = useCallback(() => {
    dispatch(ManagementConsole.fetchUsers(enterpriseId));
    dispatch(
      ManagementConsole.setToast({
        message: `User ${!!selectedUser ? 'updated' : 'created'} successfully.`,
        severity: 'success',
      })
    );
  }, [dispatch, selectedUser, enterpriseId]);

  const handleSaveUserRejected = useCallback(
    (data) =>
      dispatch(
        ManagementConsole.setToast({
          message: !!data ? data : 'Something went wrong.',
          severity: 'error',
        })
      ),
    [dispatch]
  );

  const handleCloseModal = useCallback(() => setModal(''), []);

  const handleCloseImportUsersModal = useCallback(
    (showSuccess = false) => {
      if (showSuccess) {
        openToast('Imported users successfully.', 'success');
      }
      handleCloseModal();
    },
    [handleCloseModal, openToast]
  );

  // Initialization effect.
  useEffect(() => {
    managementApi
      .get(`/enterprise/${enterpriseId}`)
      .then(({ data }) => setMaxUsers(Number(data?.maxUsers)));

    // Fetch configs to determine if TOTP authentication is enabled.
    managementApi
      .get(`/config/enterpriseConfigDetails/${enterpriseId}`)
      .then(({ data }) => {
        const auth2fa = data.find(
          (c) => c?.databaseKey === 'auth2fa'
        )?.currentValue;
        const auth2faType = data.find(
          (c) => c?.databaseKey === 'auth2faType'
        )?.currentValue;

        setIsTOTPEnabled(
          auth2fa && auth2faType === TWO_FACTOR_VERIFICATION_TYPES.TOTP
        );
      });
    dispatch(ManagementConsole.fetchUsers(enterpriseId));
    dispatch(ManagementConsole.resetToast());

    return () => dispatch(ManagementConsole.resetToast());
  }, [dispatch, enterpriseId]);

  // Toast effect.
  useEffect(() => {
    if (!!toast.message) {
      openToast(toast.message, toast.severity);
    }
  }, [toast, openToast]);

  return (
    <>
      <UserAdminDataGrid
        rows={userList}
        loading={fetchingUsers}
        selection={selection}
        setSelection={setSelection}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onImport={handleImport}
        onDeactivate={handleDeactivate}
        onReactivate={handleReactivate}
        onResetPassword={handleResetPassword}
        onSendActivation={handleSendActivation}
        onResetTOTPEnrolment={handleResetTOTPEnrolment}
        isTOTPEnabled={isTOTPEnabled}
        toolbarSx={{
          px: 0,
          pb: 2,
          '& .MuiInputBase-root': {
            backgroundColor: '#fff',
          },
        }}
        sx={{
          '& .MuiDataGrid-main': {
            borderRadius: '0.5rem',
            backgroundColor: '#fff',
          },
        }}
      />
      <UserModal
        open={modal === MODALS.USER}
        onClose={handleCloseModal}
        user={selectedUser}
        onSave={handleSaveUser}
        onSaveFulfilled={handleSaveUserFulfilled}
        onSaveRejected={handleSaveUserRejected}
      />
      <ImportUsersModal
        open={modal === MODALS.IMPORT_USERS}
        onClose={handleCloseImportUsersModal}
        userAllowance={userAllowance}
        onImport={handleImportUsers}
        onImportFulfilled={handleImportFulfilled}
      />
      {confirmElement}
      {toastElement}
    </>
  );
};

export default UserAdministration;
