import { ORG_TYPES } from '../org/orgReducer';
import { AUTH_TYPES } from '../auth/authReducer';

const initState = {
  isInitialized: false,
  isConnected: false,
};

export const SDK_TYPES = {
  INITIALIZE: '@@stream.io/INITIALIZE',
  SET_CONNECTED: '@@stream.io/SET_CONNECTED',
};

export default function sdk(state = initState, action) {
  switch (action.type) {
    case SDK_TYPES.INITIALIZE:
      return {
        ...state,
        isInitialized: true,
      };
    case SDK_TYPES.SET_CONNECTED:
      return {
        ...state,
        isConnected: action.payload,
      };
    case AUTH_TYPES.CLEAR:
    case ORG_TYPES.RESET_ORG:
    case `${SDK_TYPES.DISCONNECT_USER}_FULFILLED`:
    case `${SDK_TYPES.DISCONNECT_USER}_REJECTED`:
      return initState;
    default:
      return state;
  }
}
