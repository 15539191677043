import SvgIcon from '@mui/material/SvgIcon';

const Plus = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3a.75.75 0 0 1 .75.75v7.5h7.5a.75.75 0 1 1 0 1.5h-7.5v7.5a.75.75 0 1 1-1.5 0v-7.5h-7.5a.75.75 0 1 1 0-1.5h7.5v-7.5A.75.75 0 0 1 12 3Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Plus;
