import { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const spacing = '0.65rem';

const Container = styled.article`
  display: flex;
  border-radius: 0.5rem;
  background-color: #fff;
  border: ${({ editable }) =>
    editable === 'true' ? '1px dashed #eee' : '1px solid transparent'};
  padding: ${({ editable }) => (editable === 'true' ? `${spacing} 0` : 0)};
  scroll-margin-bottom: 3rem;

  ${({ editable }) =>
    editable === 'true' &&
    `&&:hover {
      border: 1px solid #777;
      transition: all 0.2s ease-in;
    }`};
`;

const ContentLeft = styled(Stack)`
  flex-grow: 1;
  justify-content: center;
  padding: ${({ editable }) => (editable === 'true' ? `0 ${spacing} 0` : 0)};
`;

const ContentRight = styled(Stack)`
  flex-shrink: 0;
  justify-content: center;
  padding: 0 ${spacing};
  border-left: 1px solid #ddd;
`;

const HeadingLayout = forwardRef(
  ({ isEditable, rightComponent = null, children }, ref) => (
    <Container ref={ref} editable={isEditable.toString()}>
      <ContentLeft editable={isEditable.toString()}>{children}</ContentLeft>
      {!!rightComponent && isEditable && (
        <ContentRight>{rightComponent}</ContentRight>
      )}
    </Container>
  )
);

HeadingLayout.displayName = 'HeadingLayout';

export default HeadingLayout;
