import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectMaxPlaybooksAllowed,
  selectPlaybooksSharingEnabled,
} from '../../store/org/orgSelectors';
import useAccess from '../../hooks/useAccess';
import { formattedDate } from '../../lib/date';
import useToast from '../../lib/hooks/useToast';
import {
  selectPlaybookCount,
  selectActivePlaybook,
  selectPlaybooksFetching,
  selectPlaybooksByCategory,
} from '../../store/playbook/playbookSelectors';
import Playbooks from '../../store/playbook/playbookActions';
import { FEATURES, DEPENDENT_FEATURES } from '../../configs';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';

import Playbook from './playbook/Playbook';
import CategoryModal from './CategoryModal';
import { PulseLogoLoader } from '../loader';
import CollapsibleList from '../CollapsibleList';
import SuspenseLoading from '../SuspenseLoading';
import PlaybookEditor from './playbook/PlaybookEditor';
import RequestUpgradeLink from '../RequestUpgradeLink';
import SharePlaybooksModal from './SharePlaybooksModal';
import ExportPlaybooksModal from './ExportPlaybooksModal';
import PlaybookIcon from '../../assets/icons/book.svg?react';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #ddd;
`;

const Sidebar = styled.aside`
  flex-shrink: 0;
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
`;

const SidebarContents = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const SidebarFooter = styled(Stack)`
  flex-shrink: 0;
`;

const SidebarSection = styled(Stack)`
  border-top: 1px solid #ddd;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(1.75, 2)};
`;

const PlaybookManager = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(selectPlaybooksFetching);
  const playbookCount = useSelector(selectPlaybookCount);
  const activePlaybook = useSelector(selectActivePlaybook);
  const maxPlaybooksAllowed = useSelector(selectMaxPlaybooksAllowed);
  const playbooksByCategory = useSelector(selectPlaybooksByCategory);
  const playbooksSharingEnabled = useSelector(selectPlaybooksSharingEnabled);
  const [isEditing, setIsEditing] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [modal, setModal] = useState('');
  const canManage = useAccess({
    permission: FEATURES.playbookManager,
    dependentFeature: DEPENDENT_FEATURES.playbooks,
  });
  const [toastElement, openToast] = useToast();
  const [confirmCancelEditing, openConfirmCancelEditing] = useConfirmAsync();
  const [confirmDeleteCategoryElement, openConfirmDeleteCategory] =
    useConfirmAsync();

  const hasRemaining = playbookCount < maxPlaybooksAllowed;
  const playbooksRemaining = maxPlaybooksAllowed - playbookCount;
  const canShare = playbooksSharingEnabled && canManage;
  const createDisabled = !hasRemaining || !canManage || fetching;
  const loading = fetching && playbookCount === 0;

  const handleClickPlaybook = (p) => {
    if (isEditing && p.id !== activePlaybook?.id) {
      openConfirmCancelEditing({
        title: 'Discard Changes?',
        content: (
          <div style={{ textAlign: 'center' }}>
            Would you like to discard your changes and open this playbook?
          </div>
        ),
        buttonText: 'Discard',
        onConfirm: () => {
          setIsEditing(false);
          return dispatch(Playbooks.setPlaybookId(p.id));
        },
      });
    } else {
      dispatch(Playbooks.setPlaybookId(p.id));
    }
  };

  const handleCreatePlaybook = (category) => {
    dispatch(Playbooks.setCategoryId(category?.id));
    dispatch(Playbooks.setPlaybookId(null));
    setIsEditing(true);
  };

  const handleCreateCategory = () => {
    dispatch(Playbooks.setCategoryId(null));
    setModal('category');
  };

  const handleEditCategory = (category) => {
    dispatch(Playbooks.setCategoryId(category?.id));
    setModal('category');
  };

  const handleDeleteCategory = (category) => {
    openConfirmDeleteCategory({
      title: 'Delete Category?',
      content: (
        <div style={{ textAlign: 'center' }}>
          This will delete the <b>{category?.name}</b> category.
        </div>
      ),
      buttonText: 'Delete',
      buttonColor: 'error',
      onConfirm: () => dispatch(Playbooks.deleteCategory(category?.id)),
    });
  };

  const handleCloseCategoryModal = () => {
    dispatch(Playbooks.setCategoryId(null));
    setModal('');
  };

  const handleCloseShareModal = (result) => {
    if (!!result) {
      openToast(result.message, result.severity, 3000);
    }
    setModal('');
  };

  // Fetch effect.
  useEffect(() => {
    dispatch(Playbooks.fetchAll());
  }, [dispatch]);

  return (
    <Container>
      <Sidebar>
        <SidebarContents>
          {loading ? (
            <SuspenseLoading
              sx={{ height: '150px', mt: 2 }}
              component={<PulseLogoLoader />}
            />
          ) : !!playbooksByCategory.length ? (
            playbooksByCategory.map((c) => (
              <CollapsibleList
                key={c.id}
                icon={PlaybookIcon}
                title={c.name}
                items={c.playbooks}
                selectedItem={activePlaybook}
                showNewLabel={false}
                showEditButtons
                showExpandButton={c.playbooks.length > 0}
                showDeleteButton={c.playbooks.length === 0}
                newButtonDisabled={createDisabled}
                onClickNew={() => handleCreatePlaybook(c)}
                onClickItem={handleClickPlaybook}
                onClickEdit={() => handleEditCategory(c)}
                onClickDelete={() => handleDeleteCategory(c)}
                itemTitle={(item) => item.name}
                itemSubtitle={(item) =>
                  `Updated: ${formattedDate(item.updateTimestamp)}`
                }
                newButtonLabel={createDisabled ? '' : 'Create Playbook'}
                emptyMessage="No playbooks created yet."
              />
            ))
          ) : (
            <Typography align="center" sx={{ mt: 2 }}>
              Create a category to get started.
            </Typography>
          )}
        </SidebarContents>
        <SidebarFooter>
          {!fetching && canManage && (
            <SidebarSection direction="column" sx={{ py: 1, gap: 0 }}>
              <Typography
                textAlign="center"
                color={hasRemaining ? 'black' : 'error'}
                sx={{ fontSize: '15px' }}
              >
                {hasRemaining ? (
                  <>
                    {`There ${playbooksRemaining === 1 ? 'is' : 'are'} `}
                    <b>{playbooksRemaining}</b>
                    {` playbook${
                      playbooksRemaining === 1 ? '' : 's'
                    } remaining.`}
                  </>
                ) : (
                  'Maximum playbooks created.'
                )}
              </Typography>
              <RequestUpgradeLink
                name="Increase Playbook Limit"
                sx={{
                  fontSize: '13px',
                  textAlign: 'center',
                }}
              >
                Click here to get more.
              </RequestUpgradeLink>
            </SidebarSection>
          )}
          <SidebarSection
            flexWrap="wrap"
            direction="row"
            alignItems="center"
            justifyContent={canManage ? 'flex-start' : 'center'}
          >
            {canManage && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={fetching}
                onClick={handleCreateCategory}
              >
                Create Category
              </Button>
            )}
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={fetching}
              onClick={() => setModal('export')}
            >
              Export Playbooks
            </Button>
            {canShare && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={fetching}
                onClick={() => setModal('share')}
              >
                Share Playbooks
              </Button>
            )}
          </SidebarSection>
        </SidebarFooter>
      </Sidebar>
      {isEditing || isCloning ? (
        <PlaybookEditor
          openToast={openToast}
          playbook={activePlaybook}
          isCloning={isCloning}
          isEditing={isEditing}
          onClickSave={() => {
            isEditing ? setIsEditing(false) : setIsCloning(false);
          }}
          onClickCancel={() => {
            isEditing ? setIsEditing(false) : setIsCloning(false);
          }}
        />
      ) : !!activePlaybook ? (
        <Playbook
          {...activePlaybook}
          onClickEdit={() => setIsEditing(true)}
          onClickClone={() => setIsCloning(true)}
        />
      ) : null}
      <CategoryModal
        open={modal === 'category'}
        onClose={handleCloseCategoryModal}
      />
      <ExportPlaybooksModal
        open={modal === 'export'}
        onClose={() => setModal('')}
      />
      {playbooksSharingEnabled && (
        <SharePlaybooksModal
          open={modal === 'share'}
          onClose={handleCloseShareModal}
        />
      )}
      {toastElement}
      {confirmCancelEditing}
      {confirmDeleteCategoryElement}
    </Container>
  );
};

export default PlaybookManager;
