export const generateIdFromUuid = (uuid) => {
  const id = uuid.split('-')[0];
  return parseInt(id, 16);
};

/**
 * Accepts an array of objects parsed from a CSV file and transforms into an API-friendly format.
 * @param {Array} csvUsers - The raw array of users parsed from a CSV file.
 * @returns {Array} - The transformed array of users.
 */
export const mapUsersFromCsv = (csvUsers) => {
  try {
    const users = csvUsers.map((u) => {
      const adminValue = u?.['Admin'].toLowerCase();
      const externalValue = u?.['External'].toLowerCase();
      const phoneNumber = (u?.['Phone Number'] || '').replace(/[^0-9]/g, '');
      const admin =
        adminValue === 'yes' ||
        adminValue === 'y' ||
        adminValue === 'true' ||
        adminValue === 't';
      const external =
        externalValue === 'yes' ||
        externalValue === 'y' ||
        externalValue === 'true' ||
        externalValue === 't';

      return {
        id: u?.['Email Address'],
        firstName: u?.['First Name'],
        lastName: u?.['Last Name'],
        email: u?.['Email Address'],
        title: u?.['Title'],
        location: u?.['Location'],
        department: u?.['Department'],
        phoneNumber,
        role: admin ? 'admin' : 'user',
        type: external ? 'external' : 'internal',
      };
    });
    return users;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
