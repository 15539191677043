import { AUTH_TYPES } from '../auth/authReducer';

export const DRAFT_TYPES = {
  UPDATE: '@@drafts/UPDATE',
  SET_MESSAGE: '@@drafts/SET_MESSAGE',
  SET_FILE: '@@drafts/SET_FILE',
  DELETE: '@@drafts/DELETE',
  CLEAR: '@@drafts/CLEAR',
};

const INITIAL_DRAFT_STATE = {};

export default function drafts(state = INITIAL_DRAFT_STATE, action) {
  switch (action.type) {
    case DRAFT_TYPES.UPDATE: {
      const { channelId, data } = action.payload;
      return {
        ...state,
        [channelId]: data,
      };
    }
    case DRAFT_TYPES.SET_MESSAGE: {
      const { channelId, message } = action.payload;
      return {
        ...state,
        [channelId]: {
          ...state?.[channelId],
          message,
        },
      };
    }
    case DRAFT_TYPES.SET_FILE: {
      const { channelId, file } = action.payload;
      return {
        ...state,
        [channelId]: {
          ...state?.[channelId],
          file,
        },
      };
    }
    case DRAFT_TYPES.DELETE: {
      const channelId = action.payload;
      const nextState = { ...state };
      delete nextState[channelId];
      return nextState;
    }
    case AUTH_TYPES.CLEAR:
    case DRAFT_TYPES.CLEAR:
      return INITIAL_DRAFT_STATE;
    default:
      return state;
  }
}
