import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { handlePaste } from '../../../lib/input';
import Incidents from '../../../store/incident/incidentActions';
import { getCachedProfileImageUrl } from '../../../utils/imageUtils';
import { selectCurrentUser } from '../../../store/auth/authSelectors';
import { ACTIVITY_TYPES } from '../../../store/incident/incidentTypes';
import { MAX_LENGTH, MAX_LONG_LENGTH } from '../../../configs/validation';

import Avatar from '../../Avatar';
import PaperPlane from '../../../assets/icons/paper-plane-outline.svg?react';

const Container = styled(Stack)`
  color: #333;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  padding: 0.75rem;
`;

const UpdateForm = ({ update, onSubmit, ...props }) => {
  const isEditing = !!update;
  const dispatch = useDispatch();
  const { user } = useSelector(selectCurrentUser);
  const [submitting, setSubmitting] = useState(false);

  const initialValues = {
    title: isEditing ? update?.title : '',
    description: isEditing ? update?.description : '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    values: initialValues,
    mode: 'onChange',
  });

  const onFormSubmit = (formData) => {
    const { title, description } = formData;
    const data = {
      title,
      description,
      type: ACTIVITY_TYPES.UPDATE,
    };

    setSubmitting(true);
    dispatch(
      isEditing
        ? Incidents.editActivity(update?.id, data)
        : Incidents.createActivity(data)
    )
      .then(() => {
        onSubmit();
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Container
      component="form"
      direction="row"
      onSubmit={handleSubmit(onFormSubmit)}
      noValidate
      {...props}
    >
      <Avatar
        profileImageUrl={getCachedProfileImageUrl(user)}
        firstName={user.firstName}
        lastName={user.lastName}
        sx={{ width: '2rem', height: '2rem', fontSize: '1rem' }}
      />

      <Stack gap={1} sx={{ flexGrow: 1 }}>
        <Controller
          name="title"
          control={control}
          rules={{
            validate: (value) =>
              value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                {...field}
                autoFocus
                variant="outlined"
                placeholder="Title"
                disabled={submitting}
                error={!!errors.title}
                helperText={errors?.title?.message}
                inputProps={{ maxLength: MAX_LENGTH.value }}
                onPaste={(e) => handlePaste(e, onChange, MAX_LENGTH.value)}
                sx={{
                  '& .MuiInputBase-input': {
                    py: 0,
                    lineHeight: 1,
                    fontSize: '1.25rem',
                  },
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none ' },
                }}
              />
            );
          }}
        />
        <Controller
          name="description"
          control={control}
          rules={{
            required: 'Description is a required field.',
            validate: (value) =>
              value.length >= MAX_LONG_LENGTH.value
                ? MAX_LONG_LENGTH.message
                : true,
          }}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                rows={4}
                multiline
                disabled={submitting}
                error={!!errors?.description}
                helperText={errors?.description?.message}
                inputProps={{ maxLength: MAX_LONG_LENGTH.value }}
                onPaste={(e) => handlePaste(e, onChange, MAX_LONG_LENGTH.value)}
                placeholder="Please provide an update description..."
                sx={{
                  '& .MuiInputBase-root': {
                    py: 0,
                  },
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                }}
              />
            );
          }}
        />
      </Stack>
      <Stack position="end" sx={{ height: '100%', placeSelf: 'flex-end' }}>
        {submitting ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <IconButton
            type="submit"
            size="large"
            color="primary"
            disabled={submitting}
          >
            <SvgIcon component={PaperPlane} />
          </IconButton>
        )}
      </Stack>
    </Container>
  );
};

export default UpdateForm;
