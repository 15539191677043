import { createSelector } from 'reselect';

import { getElapsedTimeSinceTimestamp } from '../../lib/date';

export const selectDashboardState = (state) => state.dashboard;

export const selectFetchDashboard = createSelector(
  selectDashboardState,
  (dashboard) => !dashboard.fetchingDashboard && !dashboard.loadedDashboard
);

export const selectCurrentUsers = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.currentUsers
);

export const selectMaxUsers = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.maxUsers
);

export const selectRemainingUsers = createSelector(
  selectCurrentUsers,
  selectMaxUsers,
  (currentUsers, maxUsers) => maxUsers - currentUsers
);

export const selectActiveIncident = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.activeIncident
);

export const selectIncidentsCount = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.incidentsCount || 0
);

export const selectHasIncident = createSelector(
  selectActiveIncident,
  selectIncidentsCount,
  (activeIncident, incidentsCount) => activeIncident && incidentsCount > 0
);

export const selectHasMultipleIncidents = createSelector(
  selectActiveIncident,
  selectIncidentsCount,
  (activeIncident, incidentsCount) => activeIncident && incidentsCount > 1
);

export const selectDashboardLoaded = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.loadedDashboard
);

export const selectTimeSinceIncident = createSelector(
  selectDashboardState,
  (dashboard) => getElapsedTimeSinceTimestamp(dashboard.lastIncidentTimestamp)
);

export const selectLatestArticleId = createSelector(
  selectDashboardState,
  (dashboard) => dashboard.latestArticleId
);
