import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CONFIG_TYPES, translateConfigType } from '../../../configs/management';

import ChipListInput from '../ChipListInput';

const ConfigControl = ({ type, sx, ...props }) => {
  const { value, onChange, label, labelId } = props;
  const commonStyles = {
    borderRadius: '6px',
    '& .MuiInputBase-root': { bgcolor: '#fff' },
  };

  switch (translateConfigType(type)) {
    case CONFIG_TYPES.ENUM: {
      const selectProps = { ...props, labelId: null, items: null, sx: null };
      const items = type.map((i) => ({
        label: i,
        value: i,
      }));

      return (
        <FormControl
          size="small"
          sx={{
            ...sx,
            ...commonStyles,
          }}
        >
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            {...selectProps}
            placeholder={label}
            onChange={(e) => onChange(e.target.value)}
          >
            {items.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    case CONFIG_TYPES.NUMBER:
      return (
        <TextField
          {...props}
          size="small"
          type="number"
          onChange={(e) => onChange(Number(e.target.value))}
          sx={{
            ...sx,
            ...commonStyles,
          }}
        />
      );
    case CONFIG_TYPES.STRING:
      return (
        <TextField
          {...props}
          multiline
          minRows={1}
          maxRows={4}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          sx={{
            ...sx,
            ...commonStyles,
          }}
        />
      );
    case CONFIG_TYPES.BOOLEAN:
      return (
        <Stack direction="row" alignItems="center" sx={sx}>
          <Typography color={!value ? 'black' : 'grey'}>False</Typography>
          <FormControlLabel
            {...props}
            label={label}
            control={
              <Switch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            sx={{
              margin: '0 0.5rem',
            }}
          />
          <Typography color={value ? 'black' : 'grey'}>True</Typography>
        </Stack>
      );
    case CONFIG_TYPES.STRING_LIST:
      return <ChipListInput {...props} sx={sx} />;
    case CONFIG_TYPES.NUMBER_LIST:
      return (
        <ChipListInput
          {...props}
          type="number"
          value={value === null ? [] : value.map((v) => Number(v))}
          onChange={(newValue) => onChange(newValue.map((v) => Number(v)))}
          sx={sx}
        />
      );
    default:
      return 'Config type not supported.';
  }
};

export default ConfigControl;
