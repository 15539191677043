import axios from 'axios';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import * as mammoth from 'mammoth/mammoth.browser';

import useToast from '../../../lib/hooks/useToast';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  background-color: white;
  background-image: linear-gradient(45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(-45deg, #e0e0e0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #e0e0e0 75%),
    linear-gradient(-45deg, transparent 75%, #e0e0e0 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;

  .doc-container {
    width: 80%;
    height: 100%;
    padding: 3rem;
    margin: 0 auto;
    overflow-y: auto;
    overflow-wrap: break-word;
    background-color: white;
    box-shadow: 0px 2px 1rem rgba(0, 0, 0, 0.4);

    img,
    video,
    figure {
      max-width: 100%;
      height: auto;
    }

    table,
    th,
    td {
      border: 1px solid;
    }
    table {
      border-collapse: collapse;
    }
  }
`;

const DOCXRenderer = ({ mainState: { currentDocument } }) => {
  const [toastElement, openToast] = useToast();

  useEffect(() => {
    openToast(
      'This document preview may be missing styling and formatting.',
      'warning',
      5000
    );
  }, [openToast]);

  if (!currentDocument) return null;

  return (
    <>
      <Container
        dangerouslySetInnerHTML={{
          __html: `<div class='doc-container'>${currentDocument.fileData}</div>`,
        }}
      />
      {toastElement}
    </>
  );
};

DOCXRenderer.fileLoader = ({ documentURI, fileLoaderComplete }) => {
  axios
    .get(documentURI, { responseType: 'arraybuffer' })
    .then(({ data: arrayBuffer }) => {
      mammoth
        .convertToHtml(
          { arrayBuffer },
          {
            includeDefaultStyleMap: true,
            styleMap: ['u => u', 'strike => del', 'comment-reference => sup'],
          }
        )
        .then(({ value }) => {
          fileLoaderComplete({ result: value });
        })
        .catch((error) => {
          console.error(error);
          fileLoaderComplete({
            result:
              'An error occurred parsing the document, please download the file to view.',
          });
        })
        .done();
    });
};
DOCXRenderer.fileTypes = [
  'docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
DOCXRenderer.weight = 1;

export default DOCXRenderer;
