import { useDispatch } from 'react-redux';
import { useRouteError } from 'react-router';
import Typography from '@mui/material/Typography';

import { surfaceBehavior } from '../../lib/log';
import Authentication from '../../store/auth/authActions';

import UnauthenticatedLayout from '../UnauthenticatedLayout';

const ErrorScreen = () => {
  const dispatch = useDispatch();
  const { error } = useRouteError();

  // Ensure Sentry receives the error captured by this component.
  surfaceBehavior(error);

  const handleClickSignIn = () => {
    dispatch(Authentication.logout());
  };

  return (
    <UnauthenticatedLayout
      useBrandmark
      title="Oops!"
      contentProps={{ sx: { width: '32rem !important' } }}
    >
      <Typography align="center" fontSize="1rem">
        Something went wrong, but we were notified of the issue. For now,{' '}
        <b>please refresh your browser.</b>
      </Typography>
      <Typography align="center" fontSize="1rem">
        Still seeing this message?{' '}
        <a href="#" onClick={handleClickSignIn}>
          Sign back in.
        </a>
      </Typography>
    </UnauthenticatedLayout>
  );
};

export default ErrorScreen;
