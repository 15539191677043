import { capitalize } from '../utils/stringUtils';

export const API_URL = {
  DEVELOPMENT: 'https://api.shadowhq-dev.getcueapp.com',
  PRODUCTION: 'https://api.shadowhq.getcueapp.com',
  LOCAL: 'http://localhost:5005',
};

export const ENVIRONMENTS = [
  {
    label: 'Development',
    value: API_URL.DEVELOPMENT,
  },
  {
    label: 'Production',
    value: API_URL.PRODUCTION,
  },
  {
    label: 'Local',
    value: API_URL.LOCAL,
  },
];

export const APP_NAME = 'ShadowHQ';

const PAGES = [
  {
    title: 'Global Configuration - Management Console',
    pathname: '/management/config',
  },
  {
    title: 'Incident Dashboard - Management Console',
    pathname: '/management/incidents/',
  },
  {
    title: 'Incidents - Management Console',
    pathname: '/management/incidents',
  },
  {
    title: 'Organizations - Management Console',
    pathname: '/management/organizations',
  },
  {
    title: 'Login - Management Console',
    pathname: '/management/login',
  },
  {
    title: 'Management Console',
    pathname: '/management',
  },
  {
    title: 'Welcome Package',
    pathname: '/welcome',
  },
];

export const MANAGEMENT_NAVIGATION = [
  { title: 'Organizations', to: '/management/organizations' },
  { title: 'Incidents', to: '/management/incidents' },
  { title: 'Global Configuration', to: '/management/config' },
];

export const getDocumentTitle = (title) => `${title} - ${APP_NAME}`;

export const getDocumentTitleByPathName = (pathname) => {
  const page = PAGES.find((p) => pathname.includes(p.pathname));
  return page ? getDocumentTitle(page.title) : APP_NAME;
};

export const getCurrentUrl = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  return hostname === 'localhost'
    ? `${protocol}//${hostname}:${window.location.port}`
    : `${protocol}//${hostname}`;
};

export const MAX_STREAM_CREDS_AT_ONCE = 6;

export const SUBSCRIPTION_LEVELS = {
  BASIC: 'BASIC',
  SMALL_TEAM: 'SMALL_TEAM',
  LARGE_TEAM: 'LARGE_TEAM',
  ENTERPRISE: 'ENTERPRISE',
};

export const SUBSCRIPTION_LEVEL_OPTIONS = [
  {
    label: 'Basic',
    value: SUBSCRIPTION_LEVELS.BASIC,
  },
  {
    label: 'Small Team',
    value: SUBSCRIPTION_LEVELS.SMALL_TEAM,
  },
  {
    label: 'Large Team',
    value: SUBSCRIPTION_LEVELS.LARGE_TEAM,
  },
  {
    label: 'Enterprise',
    value: SUBSCRIPTION_LEVELS.ENTERPRISE,
  },
];

export const VISIBILITY_LABELS = {
  BACKEND_ONLY: 'Backend Only',
  FRONTEND_WITH_AUTH: 'Requires Auth',
  PUBLIC: 'Public',
};

export const MODIFY_ACCESS_LABELS = {
  DEVELOPMENT_TEAM_ONLY: 'Backend Only',
  ENVIRONMENT_ADMIN: 'Environment',
  ORGANIZATION_ADMIN: 'Organization',
};

export const CONFIG_TYPES = {
  ENUM: 'ENUM',
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  NUMBER_LIST: 'NUMBER_LIST',
  STRING_LIST: 'STRING_LIST',
};

export const translateConfigType = (serverType) => {
  if (Array.isArray(serverType)) {
    return CONFIG_TYPES.ENUM;
  }

  switch (serverType) {
    case 'java.lang.Long':
    case 'java.lang.Integer':
      return CONFIG_TYPES.NUMBER;
    case 'java.lang.String':
      return CONFIG_TYPES.STRING;
    case 'java.lang.Boolean':
      return CONFIG_TYPES.BOOLEAN;
    case 'java.lang.Long[]':
    case 'java.lang.Integer[]':
      return CONFIG_TYPES.NUMBER_LIST;
    case 'java.lang.String[]':
      return CONFIG_TYPES.STRING_LIST;
    default:
      return null;
  }
};

export const isListTypeConfig = (serverType) =>
  [CONFIG_TYPES.NUMBER_LIST, CONFIG_TYPES.STRING_LIST].includes(
    translateConfigType(serverType)
  );

export const isStringTypeConfig = (serverType) =>
  translateConfigType(serverType) === CONFIG_TYPES.STRING;

export const getConfigDisplayValue = (serverType, value) => {
  switch (translateConfigType(serverType)) {
    case CONFIG_TYPES.ENUM:
    case CONFIG_TYPES.NUMBER:
    case CONFIG_TYPES.STRING:
      return value;
    case CONFIG_TYPES.BOOLEAN:
      return capitalize(value.toString());
    case CONFIG_TYPES.NUMBER_LIST:
    case CONFIG_TYPES.STRING_LIST:
      return !!value.length ? value.join(', ') : 'Empty list.';
    default:
      return 'Config type not supported.';
  }
};
