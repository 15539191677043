import { axiosInstance } from '../../lib/axiosInstance';
import { selectChannel } from './messagingAdminSelectors';
import { MESSAGING_ADMIN_TYPES } from './messagingAdminReducer';

const MessagingAdmin = {
  fetch: () => ({
    type: MESSAGING_ADMIN_TYPES.FETCH_CHANNELS,
    payload: async () => {
      try {
        let page = 0;
        let offset = 0;
        const limit = 30;
        let channels = [];
        let queryResponse = [];

        do {
          const { data } = await axiosInstance.post('/streamadmin/channels', {
            filter_conditions: { type: 'team' },
            sort: [
              { field: 'isWarRoom', direction: -1 },
              { field: 'name', direction: 1 },
            ],
            state: true,
            message_limit: 0,
            limit,
            offset,
          });
          queryResponse = data.channels;
          channels = [...channels, ...queryResponse];
          page += 1;
          offset = page * limit;
        } while (queryResponse.length >= limit);

        return channels;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  }),
  updateChannel: (channel) => ({
    type: MESSAGING_ADMIN_TYPES.UPDATE_CHANNEL,
    payload: channel,
  }),
  editChannel: (cid, data) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.EDIT_CHANNEL,
      payload: axiosInstance.patch(`/streamadmin/channels/${type}/${id}`, {
        set: {
          ...data,
        },
      }),
    });
  },
  addMembers: (cid, uuids) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.ADD_MEMBERS,
      payload: axiosInstance.post(`/streamadmin/channels/${type}/${id}`, {
        add_members: uuids,
      }),
    });
  },
  removeMembers: (cid, uuids) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.REMOVE_MEMBERS,
      payload: axiosInstance.post(`/streamadmin/channels/${type}/${id}`, {
        remove_members: uuids,
      }),
    });
  },
  promoteMembers: (cid, uuids) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.PROMOTE_MEMBERS,
      payload: axiosInstance.post(`/streamadmin/channels/${type}/${id}`, {
        add_moderators: uuids,
      }),
    });
  },
  demoteMembers: (cid, uuids) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.DEMOTE_MEMBERS,
      payload: axiosInstance.post(`/streamadmin/channels/${type}/${id}`, {
        demote_moderators: uuids,
      }),
    });
  },
  deleteChannel: (cid) => (dispatch, getState) => {
    const { type, id } = selectChannel(getState(), cid);

    return dispatch({
      type: MESSAGING_ADMIN_TYPES.DELETE_CHANNEL,
      payload: axiosInstance.delete(`/streamadmin/channels/${type}/${id}`),
    });
  },
  setToastMessage: ({ message, severity }) => ({
    type: MESSAGING_ADMIN_TYPES.SET_TOAST_MESSAGE,
    payload: {
      message,
      severity,
    },
  }),
  resetToastMessage: () => ({
    type: MESSAGING_ADMIN_TYPES.RESET_TOAST_MESSAGE,
  }),
};

export default MessagingAdmin;
