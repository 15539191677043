import SvgIcon from '@mui/material/SvgIcon';

const Checkmark = (props) => (
  <SvgIcon {...props}>
    <path
      d="M19.104 5.955a1.1 1.1 0 0 1 1.57 0c.43.434.436 1.134.016 1.575l-8.87 10.485a1.099 1.099 0 0 1-1.597.03l-5.397-5.469a1.135 1.135 0 0 1 .36-1.834 1.1 1.1 0 0 1 1.21.244l4.578 4.64 8.1-9.638a.375.375 0 0 1 .03-.033Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Checkmark;
