import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

const BaseModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  width: 30em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalHeader = styled.h3`
  border-bottom: 1px solid #aaa;
  padding: 10px 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  & p {
    text-align: left;
    width: 100%;
  }
`;

const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  padding: 15px 0px;
`;

export default function ConfirmModal({
  headerText,
  open,
  confirmText,
  onClose,
  onConfirm,
  children,
  cancel,
}) {
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    await onConfirm();
    setDisabled(false);
  };

  useEffect(() => {
    if (open) {
      setDisabled(false);
    }
  }, [open]);

  return (
    <BaseModal open={open} onClose={onClose}>
      <ModalContent>
        {headerText && <ModalHeader>{headerText}</ModalHeader>}
        <ModalBody>
          {children}
          <ModalButtons>
            {onClose && (
              <Button onClick={onClose} disabled={disabled}>
                {cancel ? 'Cancel' : 'Close'}
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={handleClick}
              disabled={disabled}
            >
              {disabled ? (
                <ClipLoader size={24} color="white" />
              ) : (
                confirmText || 'Confirm'
              )}
            </Button>
          </ModalButtons>
        </ModalBody>
      </ModalContent>
    </BaseModal>
  );
}
