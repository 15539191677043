import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import useInterval from '../../../lib/hooks/useInterval';
import { managementApi } from '../../../configs/managementApi';

import OrgDetails from './OrgDetails';
import IncidentList from './IncidentList';
import IncidentDetails from './IncidentDetails';
import BackButton from '../../incidents/BackButton';

const Main = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ececec;
  padding-bottom: 50px;
  margin-top: -70px;
  overflow: hidden;
`;

const Loader = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const IncidentDashboard = () => {
  const { id } = useParams();
  const [org, setOrg] = useState({});
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const fetchData = useCallback(
    (shouldLoad = false) => {
      if (shouldLoad) {
        setLoading(true);
      }

      // Fetch org data.
      return managementApi
        .get(`/enterprise/${id}`)
        .then(({ data }) => {
          setOrg(data);

          // Fetch incidents for org.
          managementApi
            .get(`/enterprise/${id}/incidents?activityOnly=false`)
            .then(({ data }) => {
              setIncidents(data);
              setSelectedRow(data?.[0]);
            })
            .catch((e) => console.error('Error fetching incidents: ', e))
            .finally(() => {
              if (shouldLoad) {
                setLoading(false);
              }
            });
        })
        .catch((e) => console.error('Error fetching org: ', e));
    },
    [id]
  );

  // Fetch effect.
  useEffect(() => {
    if (!!id) {
      fetchData(true);
    }
  }, [id, fetchData]);

  // Fetch data once every minute.
  useInterval(fetchData, !!id ? 60 * 1000 : null);

  const handleSelectRow = useCallback((row) => {
    setSelectedRow(row);
  }, []);

  return (
    <>
      <BackButton component={Link} to="/management/incidents" sx={{ ml: -3 }} />
      <Main>
        {loading ? (
          <Loader>
            <CircularProgress size="4rem" />
          </Loader>
        ) : (
          <Box sx={{ width: '95%' }}>
            <OrgDetails orgData={org} incidents={incidents} />
            <IncidentList
              orgData={org}
              selectedRow={selectedRow}
              onSelectRow={handleSelectRow}
            />
            {incidents.length > 0 && (
              <IncidentDetails selectedRow={selectedRow} orgData={org} />
            )}
          </Box>
        )}
      </Main>
    </>
  );
};

export default IncidentDashboard;
