import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import SvgIcon from '@mui/material/SvgIcon';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';

import { getCachedProfileImageUrl } from '../../utils/imageUtils';

import Avatar from '../Avatar';
import ElapsedTime from './ElapsedTime';
import Checkmark from '../../assets/icons/success-circle.svg?react';
import Ellipse from '../../assets/icons/more-horizontal-outline.svg?react';

const BoldTableCell = styled(MuiTableCell)`
  font-weight: bold;
`;

const TableCell = styled(MuiTableCell)`
  padding: 0.25rem 0;
  border: none;
`;

const Name = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckInTable = ({ rows, showTimestamps, ...props }) => (
  <TableContainer sx={{ maxHeight: 400 }} {...props}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <BoldTableCell sx={{ pl: 6, py: 2 }}>User</BoldTableCell>
          <BoldTableCell align="center" sx={{ py: 2 }}>
            Last Active
          </BoldTableCell>
          <BoldTableCell align="center" sx={{ py: 2 }}>
            Checked In
          </BoldTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!!rows.length &&
          rows.map(({ user, lastActiveTimestamp, checkInTimestamp }, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Avatar
                    profileImageUrl={getCachedProfileImageUrl(user)}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    fontSize="1rem"
                    size="smaller"
                  />
                  <Name>
                    {user.firstName} {user.lastName}
                  </Name>
                </Stack>
              </TableCell>
              <TableCell align="center">
                {!!lastActiveTimestamp ? (
                  <ElapsedTime
                    timestamp={lastActiveTimestamp}
                    showTimestamp={showTimestamps}
                    tooltipPlacement="left"
                    sx={{ fontSize: showTimestamps ? '12px' : '14px' }}
                  />
                ) : (
                  <SvgIcon color="default" component={Ellipse} />
                )}
              </TableCell>
              <TableCell align="center">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  {!!checkInTimestamp && (
                    <ElapsedTime
                      timestamp={checkInTimestamp}
                      showTimestamp={showTimestamps}
                      sx={{ fontSize: showTimestamps ? '12px' : '14px' }}
                    />
                  )}
                  {!!checkInTimestamp ? (
                    <SvgIcon
                      color="success"
                      component={Checkmark}
                      style={{
                        fontSize: '1.25rem',
                        minWidth: '1.25rem',
                        minHeight: '1.25rem',
                      }}
                    />
                  ) : (
                    <SvgIcon color="default" component={Ellipse} />
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        {!rows.length && (
          <TableRow>
            <TableCell colSpan={3}>
              <Typography align="center" mt={2}>
                No team members to show.
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CheckInTable;
