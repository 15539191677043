import { v4 as uuidv4 } from 'uuid';

import { store, navigateHelper } from '../store';
import {
  selectCurrentChat,
  selectReadReceipt,
} from '../store/chat/chatSelectors';
import {
  selectOnCall,
  selectHasIncomingCall,
} from '../store/jitsi/jitsiSelectors';
import Users from '../store/user/userActions';
import Chats from '../store/chat/chatActions';
import Messages from '../store/chat/messageActions';
import JitsiCalls from '../store/jitsi/jitsiActions';
import { getStreamChatClient } from './streamChatClient';
import { selectUserList } from '../store/user/userSelectors';

import logo from '/apple-touch-icon.png?url';

// Selector for the members of a chat to parse when showing a notification.
const selectChatMembers = (chat, users) => {
  const memberIds = Object.keys(chat.state.members);

  return memberIds.map((p) => {
    const defaultUser = {
      userProperties: { uuid: -1 },
      firstName: 'Former',
      lastName: 'Member',
      active: false,
    };
    let user = users.find((u) => u.userProperties.uuid === p);

    if (user) {
      if (user.userProperties.type === 'external') {
        user = {
          ...user,
          lastName: `${user.lastName} (External)`,
        };
      }

      if (!user.active) {
        user = {
          ...user,
          lastName: `${user.lastName} (Inactive)`,
        };
      }
    }

    return user || defaultUser;
  });
};

const emailRegex =
  /(([^<>()[\]\\.,;:\s@"'/?=#{}%]+(?!png|jpg|jpeg|zvg)(\.[^<>()[\]\\.,;:\s@"/=?]+)*(?!png|jpg|jpeg|zvg)))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+((?!png|jpg|jpeg|zvg)[a-zA-Z]{2,})))/gi;

const showNotificationAdded = (channel, { text, id }) => {
  const bodyText = text;

  const n = new Notification('ShadowHQ - Notification', {
    tag: id,
    body: `${bodyText}`,
    icon: logo,
  });
  n.onclick = () => {
    const currentChat = selectCurrentChat(store.getState());

    if (currentChat.cid !== channel.cid) {
      store.dispatch(Chats.setCurrentChat(channel.type, channel.id));
    }

    if (!window.location.href.includes('/chat')) {
      navigateHelper('/chat');
    }

    if (document.visibilityState === 'hidden' || !document.hasFocus()) {
      window.focus();
    }

    n.close();
  };
};

// Notification Handler
const showNotification = (channel, { user, text, id }) => {
  const mentions = text.match(emailRegex);

  let bodyText = text;

  if (mentions) {
    const users = selectUserList(store.getState());
    const members = selectChatMembers(channel, users);

    mentions.forEach((m) => {
      const mentioned = members.find((u) => u.email === m);

      if (mentioned) {
        bodyText = bodyText.replace(m, `${mentioned.fullName}`);
      }
    });
  }

  const n = new Notification('ShadowHQ - New Message', {
    tag: id,
    body: `${user.name} - ${bodyText}`,
    icon: logo,
  });
  n.onclick = () => {
    const currentChat = selectCurrentChat(store.getState());

    if (currentChat.cid !== channel.cid) {
      store.dispatch(Chats.setCurrentChat(channel.type, channel.id));
    }

    if (!window.location.href.includes('/chat')) {
      navigateHelper('/chat');
    }

    if (document.visibilityState === 'hidden' || !document.hasFocus()) {
      window.focus();
    }

    n.close();
  };
};

export const onConnectionChanged = async (e) => {
  console.log('===CONNECTION CHANGED===', e);

  if (e.online) {
    console.log('@@SHQ/stream.io - Current user connected');

    try {
      await store.dispatch(Chats.fetchChats());
    } catch (e) {
      console.error(e);
    }
  }

  if (!e.online) {
    console.log('@@SHQ/stream.io - Current user disconnected');
  }
};

export const onChannelUpdated = (e) => {
  console.log('===CHANNEL UPDATED===', e);
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});
  const { currentUser } = store.getState().auth;
  const hasCurrentUser = Object.keys(channel.state.members).includes(
    currentUser.uuid
  );

  if (hasCurrentUser) {
    store.dispatch(Chats.updateChat(channel));
    store.dispatch(Chats.updateMembers(channel.state.members));
    store.dispatch(
      JitsiCalls.updateActiveCalls(channel.data.callDetails, channel.cid)
    );
  }
};

export const onChannelVisible = (e) => {
  console.log('===CHANNEL ADDED===', e);
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  store.dispatch(Chats.addChat(channel));
};

export const onChannelHidden = (e) => {
  console.log('===CHANNEL REMOVED===', e);
  store.dispatch(Chats.removeChat(e.cid));
};

export const onChannelDeleted = (e) => onChannelHidden(e);

export const onMemberAdded = (e) => {
  console.log('===MEMBER ADDED===', e);
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  store.dispatch(Chats.updateChat(channel));
  store.dispatch(Chats.updateMembers(channel.state.members));
};

export const onMemberRemoved = (e) => {
  console.log('===MEMBER REMOVED===', e);
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});
  const { currentUser } = store.getState().auth;
  const hasCurrentUser = Object.keys(channel.state.members).includes(
    currentUser.uuid
  );

  if (hasCurrentUser) {
    store.dispatch(Chats.updateChat(channel));
    store.dispatch(Chats.updateMembers(channel.state.members));
  }
};

export const onMessageNew = (e) => {
  console.log('===MESSAGE ADDED===', e);
  // Update the chat to get the latest activity time. This also
  // has the side effect of checking if the chat is hidden.
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});
  const currentChat = selectCurrentChat(store.getState());
  const readReceiptList = selectReadReceipt(store.getState());

  if (client.user.id !== e.message.user.id && e.message) {
    const hasIncoming = selectHasIncomingCall(store.getState());
    const onCall = selectOnCall(store.getState());

    let notificationBody = e.message;

    if (e.message.isCall && !hasIncoming && !onCall) {
      notificationBody = { ...e.message, text: 'Incoming call' };
    }

    showNotification(channel, notificationBody);
  }

  if (e.message.attachments && e.message.attachments.length > 0) {
    store.dispatch(
      Chats.addAttachment({
        ...e.message.attachments[0],
        id: uuidv4(),
        date: e.message.created_at,
      })
    );
  }

  store.dispatch(Messages.addMessage(e));
  store.dispatch(Chats.updateChat(channel));
  if (channel.cid === currentChat.cid) {
    if (!readReceiptList.includes(e.user.name)) {
      store.dispatch(Chats.removeReceiptList());
    }
  }
};

export const onMessageUpdated = (e) => {
  console.log('===MESSAGE UPDATED===', e);

  store.dispatch(Messages.setUpdatedMessage(e.message));
};

export const onMessageRead = (e) => {
  console.log('===MESSAGE READ===', e);
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});
  const currentChat = selectCurrentChat(store.getState());
  const readReceiptList = selectReadReceipt(store.getState());

  const nextRead = Object.keys(channel.state.read).reduce(
    (acc, key) => ({
      ...acc,
      [key]: new Date(channel.state.read[key].last_read),
    }),
    {}
  );

  store.dispatch(Messages.updateRead(e.cid, nextRead));
  store.dispatch(Chats.updateChat(channel));
  if (channel.cid === currentChat.cid) {
    if (!readReceiptList.includes(e.user.name)) {
      store.dispatch(Chats.readReceiptList(`${e.user.name}`));
    }
  }
};

export const onTypingStart = (e) => {
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  store.dispatch(Chats.startTyping(e.cid, channel.state.typing));
};

export const onTypingStop = (e) => {
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  store.dispatch(Chats.stopTyping(e.cid, channel.state.typing));
};

export const onNotificationAddedToChannel = async (e) => {
  console.log('===NOTIFICATION ADDED===', e);

  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  await channel.watch();

  if (e.channel_type === 'team' && e.channel.created_by.id !== client.user.id) {
    store.dispatch(Chats.addChat(channel));

    const notificationBody = { ...e.message, text: 'Added to New Group' };
    showNotificationAdded(channel, notificationBody);
  }
};

export const onNotificationRemovedFromChannel = (e) => {
  console.log('===NOTIFICATION REMOVED===', e);
  store.dispatch(Chats.removeChat(e.cid));
};

export const onNotificationMessageNew = async (e) => {
  console.log('===NOTIFICATION MESSAGE NEW===', e);
  // As opposed to the above event (message.add), this happens when a chat
  // isn't being watched by the current user. The effect is the same but
  // we must watch the chat.
  const client = getStreamChatClient();
  const channel = client.getChannelById(e.channel_type, e.channel_id, {});

  await channel.watch();

  if (client.user.id !== e.message.user.id) {
    showNotification(channel, e.message);
  }

  if (e.message.isCall) {
    store.dispatch(JitsiCalls.setMessageId(e.message.id));
  }

  store.dispatch(Messages.addMessage(e));
  store.dispatch(Chats.updateChat(channel));
};
