export const handlePaste = (e, onChange, limit = 255) => {
  const value = (e.clipboardData || window.clipboardData).getData('text');
  const existingValue = e.target.value;
  const selectionStart = e.target.selectionStart;
  const selectionEnd = e.target.selectionEnd;

  const newValue =
    existingValue.substring(0, selectionStart) +
    value.substring(0, limit - existingValue.length + selectionEnd) +
    existingValue.substring(selectionEnd);

  e.preventDefault();
  onChange(newValue);
};

export const preventFormSubmitOnEnter = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
