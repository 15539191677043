import { axiosInstance } from './axiosInstance';

export const setAxiosConfig = (baseURL, authToken, orgCode) => {
  axiosInstance.defaults.baseURL = baseURL;

  if (authToken) {
    axiosInstance.defaults.headers.common['Authorization'] =
      `Bearer ${authToken}`;
  }

  axiosInstance.defaults.headers.common['X-Enterprise-Code'] = orgCode;
};

export const updateAxiosConfig = (baseURL, authToken, orgCode) => {
  if (baseURL) {
    axiosInstance.defaults.baseURL = baseURL;
  }

  if (authToken) {
    axiosInstance.defaults.headers.common['Authorization'] =
      `Bearer ${authToken}`;
  }

  if (orgCode) {
    axiosInstance.defaults.headers.common['X-Enterprise-Code'] = orgCode;
  }
};

export const resetAxiosConfig = () => {
  // console.log('Resetting Axios config...');
  delete axiosInstance.defaults.baseURL;
  delete axiosInstance.defaults.headers.common['Authorization'];
  delete axiosInstance.defaults.headers.common['X-Enterprise-Code'];
};
