import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isExpired } from '../../utils/tokenUtils';
import { selectIsReady } from '../../store/heartbeat/heartbeatSelectors';
import { selectAuthenticationTokenExpiry } from '../../store/auth/authSelectors';

import Heartbeat from '../../store/heartbeat/heartbeatActions';
import Dashboard from '../../store/dashboard/dashboardActions';
import Authentication from '../../store/auth/authActions';
import Enterprise from '../../store/org/orgActions';
import Users from '../../store/user/userActions';

/**
 * Check the documentation for this component in the project README.
 */
const ReadinessManager = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(selectIsReady);
  const authenticationTokenExpiry = useSelector(
    selectAuthenticationTokenExpiry
  );

  useEffect(() => {
    // console.log('ReadinessManager mount');

    if (!isReady && authenticationTokenExpiry) {
      if (isExpired(authenticationTokenExpiry)) {
        console.log('ReadinessManager: refreshing token...');
        dispatch(Authentication.refreshToken({ source: 'ReadinessManager' }));
      } else {
        console.log('ReadinessManager: initializing...');

        // Fetch all necessary data.
        dispatch(Enterprise.fetchBadges());
        dispatch(Enterprise.fetchEnterprisePlatformConfig());
        dispatch(Authentication.updateUserProperties());
        dispatch(Dashboard.getDashboard());
        dispatch(Users.fetchUsers());
        dispatch(Users.fetchLastActiveTimestamps());
        dispatch(Heartbeat.setIsReady(true));
      }
    }

    // return () => console.log('ReadinessManager unmount');
  }, [isReady, authenticationTokenExpiry, dispatch]);

  return null;
};

export default ReadinessManager;
