import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { selectEnabledDialIn } from '../../store/org/orgSelectors';
import { selectDialInOptions } from '../../store/jitsi/jitsiSelectors';

import RequestUpgradeLink from '../RequestUpgradeLink';
import CopyIcon from '../../assets/icons/copy-outline.svg?react';

const DialInOptions = ({
  meetingId,
  textColor,
  showTitle = true,
  ...props
}) => {
  const dialInEnabled = useSelector(selectEnabledDialIn);
  const { phoneNumbers, viewMoreLink } = useSelector(selectDialInOptions);
  const [copyPromptText, copy] = useCopyToClipboard('Copy Details');

  const detailText = `${phoneNumbers.join('\n')}\nPIN: ${meetingId}#`;

  const handleClickCopy = useCallback(
    () => copy(detailText),
    [copy, detailText]
  );

  if (!dialInEnabled) {
    return (
      <RequestUpgradeLink
        name="Enable Meeting Dial-Ins"
        style={{ textAlign: 'center' }}
      >
        Click here to get access to meeting dial-ins.
      </RequestUpgradeLink>
    );
  }

  if (!phoneNumbers.length || !meetingId) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '80px' }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={2} {...props}>
      <Stack alignItems="flex-start">
        {showTitle && (
          <Typography color={textColor} fontWeight="bold">
            Dial-in Details
          </Typography>
        )}
        {phoneNumbers.map((p) => (
          <Typography key={p} color={textColor}>
            {p}
          </Typography>
        ))}
        <Typography color={textColor}>PIN: {meetingId}#</Typography>
        <a href={viewMoreLink} target="_blank" rel="noreferrer noopener">
          View more numbers
        </a>
      </Stack>
      <Tooltip
        arrow
        placement="right"
        disableInteractive
        title={copyPromptText}
      >
        <IconButton size="large" color="darkGrey" onClick={handleClickCopy}>
          <SvgIcon component={CopyIcon} fontSize="1em" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default DialInOptions;
