import axios from 'axios';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';

import { downloadFile } from '../../utils/networkUtils';

// Icons
import FileIcon from '../../assets/icons/file-text-outline.svg?react';
import DownloadOutline from '../../assets/icons/cloud-download-outline.svg?react';

const ListItem = styled.li`
  cursor: pointer;
  padding: 0.5em 1em;
  align-items: center;

  :hover {
    background: ${({ theme }) => theme.palette.secondary.hover};
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  .cancel-icon {
    display: none;
    text-align: right;
  }

  &:hover .cancel-icon {
    display: block;
  }
`;

const FileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  min-width: 3em;
  margin-right: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const Thumb = styled.img`
  width: 3em;
`;

const FileNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  small {
    color: #aaa;
  }
`;

const FileName = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
`;

function ActionButton({ title, background, onClick, className }) {
  const theme = useTheme();

  return (
    <Tooltip arrow title={title} className={className}>
      <IconButton
        onClick={onClick}
        style={{
          backgroundColor: background,
          margin: '0.2em 0.2em',
          height: '1.25em',
          width: '1.25em',
          display: 'flex',
        }}
        size="large"
      >
        <SvgIcon fill={theme.palette.white.main}>
          <DownloadOutline />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}

const DownloadIcon = styled(ActionButton)`
  position: absolute;
  right: 0;
  height: 1rem;
  width: 1rem;
  filter: drop-shadow(1px 1px 1px #000);
`;

const FileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;

  ${DownloadIcon} {
    display: none !important;
    position: absolute;
  }

  &:hover {
    ${DownloadIcon} {
      display: flex !important;
      width: 5rem;
    }
  }
`;

export default function Attachment({ file }) {
  const theme = useTheme();

  const date = new Date(file.date);

  const download = () => {
    // Create a hidden link to the blob from which to download.
    axios
      .get(file.asset_url, { responseType: 'arraybuffer' })
      .then(({ data, headers }) => {
        downloadFile({
          name: file.name || `image-${file.id}`,
          data,
          options: { type: headers['content-type'] },
        });
      });
  };

  return (
    <ListItem>
      <FileContent>
        <FileImage>
          {file.type === 'Image' ? (
            <Thumb src={file.thumb_url} />
          ) : (
            <SvgIcon
              sx={{
                color: theme.palette.primary.main,
                height: '40px',
                width: '40px',
              }}
            >
              <FileIcon />
            </SvgIcon>
          )}
        </FileImage>
        <Tooltip title={file.name} placement="left-end">
          <FileNameWrapper>
            <FileName>{file.name}</FileName>
            <small>{date.toDateString()}</small>
          </FileNameWrapper>
        </Tooltip>
        <DownloadIcon
          title="Download"
          background="#2B4C82"
          onClick={() => download()}
        />
      </FileContent>
    </ListItem>
  );
}
