import axios from 'axios';

export const welcomePackageApi = axios.create({
  headers: {
    common: {
      'Content-Type': 'application/json',
      'X-App-Version': window._env_.VITE_APP_VERSION,
    },
  },
});

// Notice that welcome has been added to the path for convenience.
export const updateWelcomePackageApiConfig = (baseURL) => {
  if (baseURL) {
    welcomePackageApi.defaults.baseURL = baseURL + 'welcome';
  }
};

export const resetWelcomePackageApiConfig = () => {
  // console.log('Resetting Axios config...');
  delete welcomePackageApi.defaults.baseURL;
};
