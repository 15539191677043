import GroupIcon from '../assets/icons/group.svg?react';
import UsersIcon from '../assets/icons/icTeam.svg?react';
import MessagingIcon from '../assets/icons/icMessage.svg?react';

// APPLICATION

export const ROLES = {
  user: 'user',
  admin: 'admin',
};

export const FEATURES = {
  userAdmin: 'userAdmin',
  groupAdmin: 'groupAdmin',
  messagingAdmin: 'messagingAdmin',
  playbookManager: 'managePlaybooks',
};

export const PERMISSIONS = {
  [FEATURES.userAdmin]: [ROLES.admin],
  [FEATURES.groupAdmin]: [ROLES.admin],
  [FEATURES.messagingAdmin]: [ROLES.admin],
  [FEATURES.playbookManager]: [ROLES.admin],
};

export const DEPENDENT_FEATURES = {
  playbooks: 'playbooks',
  groupAdmin: 'groupAdmin',
};

export const PAGES = {
  dashboard: {
    name: 'Dashboard',
    pathname: '/',
  },
  incidents: {
    name: 'Incidents',
    pathname: '/incidents',
  },
  chat: {
    name: 'Messaging',
    pathname: '/chat',
  },
  userAdmin: {
    name: 'User Administration',
    pathname: '/user-admin',
  },
  fileStorage: {
    name: 'Files',
    pathname: '/secure-file-storage',
  },
  playbooks: {
    name: 'Playbooks',
    pathname: '/playbooks',
  },
  groupAdmin: {
    name: 'Groups',
    pathname: '/groups',
  },
  messagingAdmin: {
    name: 'Messaging Administration',
    pathname: '/messaging-admin',
  },
};

export const TAB_LISTS = {
  admin: [
    {
      label: 'Users',
      to: PAGES.userAdmin.pathname,
      icon: UsersIcon,
      access: {
        roles: [ROLES.admin],
      },
    },
    {
      label: PAGES.groupAdmin.name,
      to: PAGES.groupAdmin.pathname,
      icon: GroupIcon,
      access: {
        permission: FEATURES.groupAdmin,
        dependentFeature: DEPENDENT_FEATURES.groupAdmin,
      },
    },
    {
      label: 'Messaging',
      to: PAGES.messagingAdmin.pathname,
      icon: MessagingIcon,
      access: {
        permission: FEATURES.messagingAdmin,
      },
    },
  ],
};

export const PAGES_WITH_TABS = {
  [PAGES.userAdmin.pathname]: TAB_LISTS.admin,
  [PAGES.groupAdmin.pathname]: TAB_LISTS.admin,
  [PAGES.messagingAdmin.pathname]: TAB_LISTS.admin,
};

export const TWO_FACTOR_VERIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  TOTP: 'TOPT_AUTHENTICATOR_APP', // This is the proper BE value.
};

// Time to wait before triggering a page reload
export const VERSION_CHANGE_WAIT_TIME = 1000 * 60 * 4;

// CHAT

// Messages can be edited this many minutes after being sent
export const DEFAULT_EDITABLE_TIME_LIMIT = 15;

// Dial-in country codes to include
export const DIAL_IN_COUNTRY_CODES = ['CA', 'US'];

// INCIDENTS

// Page size for incident feed
export const FEED_PAGE_SIZE = 10;

export const DEFAULT_INCIDENT_DESCRIPTION = `**Incident Overview**\n\n
Insert details about the Incident...\n\n
**Incident Action Plan**\n\n
Insert details about the next steps to be taken...`;

export const DEFAULT_TABLETOP_DESCRIPTION = `**Tabletop Exercise Overview**\n\n
Insert details about the Tabletop Exercise...\n\n
**Tabletop Exercise Action Plan**\n\n
Insert details about the next steps to be taken...`;

// SECURE FILE STORAGE

export const FOLDER_TYPES = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  ADMIN: 'ADMIN',
};

export const FOLDER_TYPE_LABELS = {
  [FOLDER_TYPES.ADMIN]: 'Admin Folder',
  [FOLDER_TYPES.PRIVATE]: 'Private Folder',
  [FOLDER_TYPES.PUBLIC]: 'Folder',
};

// PLAYBOOKS

export const DEFAULT_PLAYBOOK_DESCRIPTION = `**Incident Overview & Action Plan**\n\n
What do you need your team to know to kick off this incident, tabletop exercise, or investigation?
`;

export const FILE_FORMAT = {
  PDF: 'PDF',
  DOCX: 'DOCX',
};
