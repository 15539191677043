import { styled } from '@mui/material/styles';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(MuiDataGrid)`
  border: none;

  .MuiDataGrid-main {
    border-radius: 0.5rem;
    background-color: #fff;
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--alignCenter),
  .MuiDataGrid-cell--textLeft {
    padding: 0 1rem;
  }
`;

const ManagementDataGrid = (props) => <StyledDataGrid {...props} />;

export default ManagementDataGrid;
