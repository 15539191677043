import { createSelector } from 'reselect';
import { formattedDate } from '../../lib/date';
import { selectCurrentUser } from '../auth/authSelectors';
import { selectCurrentChat } from './chatSelectors';

// Helper functions
function groupByDay(messages) {
  const dayGroups = {};
  const pages = [...messages].reverse();

  let group = [];
  let lastMessage = pages[0];
  pages.forEach((message, idx) => {
    const lastDate = formattedDate(lastMessage.created_at);
    const currentDate = formattedDate(message.created_at);

    if (idx === 0) {
      group = [pages[idx]];
    } else if (lastDate === currentDate) {
      group = [...group, message];
    } else {
      dayGroups[lastDate] = group;
      group = [message];
    }

    if (idx === pages.length - 1) {
      dayGroups[currentDate] = group;
    }

    lastMessage = message;
  });

  return dayGroups;
}

function groupByUser(messages) {
  const messagesByDay = groupByDay(messages);
  const groupByDayAndUser = {};

  Object.keys(messagesByDay).forEach((day) => {
    const dayGroup = messagesByDay[day];

    let userGroups = [];
    let group = [];
    let lastMessage = null;
    dayGroup.forEach((message, idx) => {
      if (idx === 0) {
        group = [message];
      } else if (lastMessage.user.id === message.user.id) {
        group = [message, ...group];
      } else {
        userGroups = [...userGroups, group];
        group = [message];
      }

      if (idx === dayGroup.length - 1) {
        userGroups = [...userGroups, group];
      }

      lastMessage = message;
    });

    groupByDayAndUser[day] = userGroups;
  });

  return groupByDayAndUser;
}

const selectState = (state) => state.messages;

export const selectQueuedMessages = createSelector(
  selectState,
  (messageState) => messageState.queued
);

export const selectMessages = createSelector(
  selectState,
  selectQueuedMessages,
  selectCurrentChat,
  (messageState, queued, currentChat) => {
    const queuedMessages = queued[currentChat.cid] || [];

    return [...messageState.messages, ...queuedMessages];
  }
);

export const selectGroupMessagesByDay = createSelector(
  selectMessages,
  (messages) => groupByUser(messages)
);

export const selectLastOutgoing = createSelector(
  selectMessages,
  selectCurrentUser,
  (messages, currentUser) =>
    [...messages].reverse().find((m) => m.user.id === currentUser.uuid)
);

export const selectLastCallMessage = createSelector(
  selectMessages,
  (messages) => [...messages].reverse().find((m) => m.isCall)
);

export const selectSendError = createSelector(
  selectState,
  (state) => state.sendError
);

export const selectIsEditing = createSelector(
  selectState,
  (state) => state.isEditing
);
