import { styled } from '@mui/material/styles';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

import { MAX_LENGTH } from '../configs/validation';

const StyledQuickFilter = styled(GridToolbarQuickFilter)`
  .MuiInputBase-root {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .MuiSvgIcon-root {
    color: #aaa;
  }
`;

const DataGridFilter = ({
  size = 'small',
  debounceMs = 500,
  placeholder = 'Search...',
  sx = {},
  ...props
}) => (
  <StyledQuickFilter
    {...props}
    size={size}
    color="primary"
    variant="outlined"
    autoComplete="off"
    debounceMs={debounceMs}
    aria-label={placeholder}
    placeholder={placeholder}
    inputProps={{ maxLength: MAX_LENGTH.value }}
    sx={{ ...sx, pb: 0 }}
  />
);

export default DataGridFilter;
