import {
  localStorageKeys,
  scheduleLocalStorageRemoval,
} from '../../utils/localStorageUtils';
import { AUTH_TYPES } from '../auth/authReducer';

const updateInLocalStorage = (value) => {
  localStorage.setItem(
    localStorageKeys.REFRESHING_TOKEN,
    JSON.stringify(value)
  );

  // Set a timeout to delete refreshing flag from localStorage
  scheduleLocalStorageRemoval(localStorageKeys.REFRESHING_TOKEN);
};

const INITIAL_STATE = {
  isRefreshing: false,
};

export const TOKEN_TYPES = {
  SET_IS_REFRESHING: '@@token/SET_IS_REFRESHING',
};

export default function token(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOKEN_TYPES.SET_IS_REFRESHING:
      return {
        ...state,
        isRefreshing: action.payload,
      };
    case `${AUTH_TYPES.REFRESH_AUTH_TOKEN}_PENDING`:
      updateInLocalStorage(true);
      return {
        ...state,
        isRefreshing: true,
      };
    case `${AUTH_TYPES.REFRESH_AUTH_TOKEN}_REJECTED`:
    case `${AUTH_TYPES.REFRESH_AUTH_TOKEN}_FULFILLED`:
      updateInLocalStorage(false);
      return {
        ...state,
        isRefreshing: false,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
