import {
  managementApi,
  updateManagementApiConfig,
} from '../../configs/managementApi';
import { getTokenSub } from '../../utils/tokenUtils';
import { MANAGEMENT_TYPES } from './managementReducer';
import { trimPhoneNumber } from '../../utils/formUtils';

const ManagementConsole = {
  login: (id) => {
    try {
      const data = JSON.parse(atob(id));
      const { jwt, apiAddress } = data;

      updateManagementApiConfig(apiAddress, jwt);

      return {
        type: MANAGEMENT_TYPES.LOGIN,
        payload: {
          jwt,
          apiAddress,
          email: getTokenSub(jwt),
        },
      };
    } catch (e) {
      console.error(e);
      return { type: MANAGEMENT_TYPES.LOGOUT };
    }
  },
  fetchDashboard: () => ({
    type: MANAGEMENT_TYPES.FETCH_DASHBOARD,
    payload: managementApi.get('/dashboard'),
  }),
  fetchUsers: (enterpriseId) => ({
    type: MANAGEMENT_TYPES.FETCH_USERS,
    payload: managementApi.get(`/enterprise/${enterpriseId}/users`),
  }),
  createUser: (enterpriseId, userData) => {
    const body = {
      ...userData,
      active: true,
      passwordChangeRequired: false,
      sendWelcomeSetPasswordEmail: true,
      phoneNumber: trimPhoneNumber(userData.phoneNumber) || null,
    };

    return {
      type: MANAGEMENT_TYPES.CREATE_USER,
      payload: managementApi.post(`/enterprise/${enterpriseId}/user`, body),
    };
  },
  updateUser: (enterpriseId, userData, uuid) => (dispatch) => {
    const body = {
      ...userData,
      active: true,
      passwordChangeRequired: false,
      phoneNumber: trimPhoneNumber(userData.phoneNumber) || null,
    };

    return dispatch({
      type: MANAGEMENT_TYPES.UPDATE_USER,
      payload: managementApi.put(
        `/enterprise/${enterpriseId}/user/${uuid}`,
        body
      ),
    });
  },
  importUsers: (enterpriseId, users) => ({
    type: MANAGEMENT_TYPES.IMPORT_USERS,
    payload: managementApi.post(`/enterprise/${enterpriseId}/users`, users),
  }),
  deactivateUser: (enterpriseId, uuid) => ({
    type: MANAGEMENT_TYPES.DEACTIVATE_USER,
    payload: managementApi.delete(`/enterprise/${enterpriseId}/user/${uuid}`),
  }),
  reactivateUser: (enterpriseId, uuid) => ({
    type: MANAGEMENT_TYPES.REACTIVATE_USER,
    payload: managementApi.post(
      `/enterprise/${enterpriseId}/user/${uuid}`,
      undefined
    ),
  }),
  sendPasswordResetEmail: (enterpriseId, userId) => ({
    type: MANAGEMENT_TYPES.SEND_USER_EMAIL,
    payload: managementApi.get(
      `/enterprise/${enterpriseId}/user/forgotPassword/${userId}`
    ),
  }),
  sendActivationEmail: (enterpriseId, userId) => ({
    type: MANAGEMENT_TYPES.SEND_USER_EMAIL,
    payload: managementApi.get(
      `/enterprise/${enterpriseId}/user/sendWelcomeEmail/${userId}`
    ),
  }),
  resetTOTPEnrolment: (enterpriseId, userId) => ({
    type: MANAGEMENT_TYPES.RESET_TOTP_ENROLMENT,
    payload: managementApi.delete(
      `/enterprise/${enterpriseId}/user/removeAuthenticatorInitialization/${userId}`
    ),
  }),
  setToast: ({ message, severity }) => ({
    type: MANAGEMENT_TYPES.SET_TOAST,
    payload: {
      message,
      severity,
    },
  }),
  resetToast: () => ({
    type: MANAGEMENT_TYPES.RESET_TOAST,
  }),
  logout: () => ({
    type: MANAGEMENT_TYPES.LOGOUT,
  }),
};

export default ManagementConsole;
