import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipCustom = styled.div`
  position: absolute;
  left: 50%;
  top: calc(38px * -1);
  transform: translateX(-50%);
  padding: 8px;
  font-size: 14px;
  color: white;
  background: black;
  border-radius: 5px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
    top: 100%;
    border-top-color: black;
  }
`;

const Tooltip = ({ children, content, isMenuOpen }) => {
  const [active, setActive] = useState(false);

  const showTip = useCallback(() => {
    if (!isMenuOpen) {
      setActive(true);
    }
  }, [isMenuOpen]);

  const hideTip = useCallback(() => {
    setActive(false);
  }, []);

  const handleClick = () => {
    setActive(false);
  };

  useEffect(() => {
    if (active) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [active]);

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <TooltipCustom>{content}</TooltipCustom>}
    </Wrapper>
  );
};

export default Tooltip;
