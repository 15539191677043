import { createSelector } from 'reselect';

import { hasTokenExpired } from '../../utils/tokenUtils';

export const selectManagementState = (state) => state.management;

export const selectBaseURL = createSelector(
  selectManagementState,
  (management) => management.baseURL
);

export const selectAuthToken = createSelector(
  selectManagementState,
  (management) => management.authToken
);

export const selectIsAuthenticated = createSelector(
  selectAuthToken,
  (authToken) => !!authToken && !hasTokenExpired(authToken)
);

export const selectHasStreamCredsAvailable = createSelector(
  selectManagementState,
  (management) => management.streamCredsAvailable > 0
);

export const selectUserList = createSelector(
  selectManagementState,
  (management) => management.userList
);

export const selectFetchingUsers = createSelector(
  selectManagementState,
  (management) => management.fetchingUsers
);

export const selectToast = createSelector(
  selectManagementState,
  (management) => management.toast
);
