import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectUserList } from '../../store/user/userSelectors';
import { selectTypingEvent } from '../../store/chat/chatSelectors';

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 25px;
  width: 100%;
  right: 1em;
  padding-left: 1em;
  color: #555;
`;

const IndicatorText = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 1.5em;
  background: white;
  align-items: center;
`;

// @@ after some research, I think this should be fine if we maintain and manage typing "state"
// @@ via the typing.start and stop events
export default function TypingIndicator({ chatId }) {
  const typingEvents = useSelector((state) =>
    selectTypingEvent(state, { chatId })
  );
  const users = useSelector(selectUserList);

  const isTyping = Boolean(typingEvents) && typingEvents.length !== 0;

  let typingText;
  if (isTyping) {
    const typingUsers = typingEvents.map((id) => {
      const user = users.find((u) => u.userProperties.uuid === id);

      if (!user) {
        return { firstName: 'Former', lastName: 'Member' };
      }

      return user;
    });

    if (typingUsers.length > 2) {
      const [u1, u2] = typingUsers;
      typingText = `${u1.firstName} and ${u2.firstName} more are typing...`;
    } else if (typingUsers.length === 2) {
      const [u1, u2] = typingUsers;
      typingText = `${u1.firstName} and ${u2.firstName} are typing...`;
    } else if (typingUsers.length === 1) {
      const [u1] = typingUsers;
      typingText = `${u1.firstName} is typing...`;
    } else {
      typingText = '';
    }
  }

  return !isTyping ? null : (
    <IndicatorWrapper>
      <IndicatorText>{typingText}</IndicatorText>
    </IndicatorWrapper>
  );
}
