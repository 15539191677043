import axios from 'axios';
import Viewer from 'react-viewer';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';

import { downloadFile } from '../../../utils/networkUtils';

import DownloadOutline from '../../../assets/icons/cloud-download-outline.svg?react';

const MessageWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

const ImgPreview = styled.div`
  display: flex;
`;

const ThumbNail = styled.img`
  display: block;
  min-width: 90px;
  max-width: 400px;
  max-height: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  object-fit: contain;
  margin: 0.5em 0;
  cursor: pointer;
`;

const DownloadIcon = styled(Tooltip)`
  position: absolute;
  bottom: 10px;
  right: 0;
  height: 1rem;
  width: 1rem;
  filter: drop-shadow(1px 1px 1px #000);
`;

const ThumbNailWrapper = styled.div`
  position: relative;
  ${DownloadIcon} {
    display: none !important;
    position: absolute;
  }

  &:hover {
    ${DownloadIcon} {
      display: flex !important;
      width: 5rem;
    }
  }
`;

export default function ImageContent({ id, attachments }) {
  const [openPreview, setOpenPreview] = useState(false);
  const theme = useTheme();

  const [attachment] = attachments;

  const downloadToComputer = useCallback(
    (file) => {
      // Create a hidden link to the blob from which to download.
      axios
        .get(file.asset_url, { responseType: 'arraybuffer' })
        .then(({ data, headers }) => {
          downloadFile({
            name: file.name || `image-${id}`,
            data,
            options: { type: headers['content-type'] },
          });
        });
    },
    [id]
  );

  return (
    <MessageWrapper>
      {attachment.thumb_url && (
        <ImgPreview>
          <ThumbNailWrapper>
            <ThumbNail
              alt={attachment.thumb_url}
              src={attachment.thumb_url}
              onClick={() => setOpenPreview(true)}
            />
            <DownloadIcon arrow title="Download File">
              <IconButton
                onClick={() => downloadToComputer(attachment)}
                style={{
                  backgroundColor: '#2B4C82',
                  margin: '0.2em 0.2em',
                  height: '1.25em',
                  width: '1.25em',
                  display: 'flex',
                }}
                size="large"
              >
                <SvgIcon
                  component={DownloadOutline}
                  fill={theme.palette.white.main}
                  style={{ color: theme.palette.white.main }}
                />
              </IconButton>
            </DownloadIcon>
          </ThumbNailWrapper>
        </ImgPreview>
      )}
      {openPreview && (
        <Viewer
          visible={openPreview}
          zIndex={1500}
          images={[{ src: attachment.asset_url, alt: attachment.name || '' }]}
          onClose={() => setOpenPreview(false)}
          showTotal={false}
          noNavbar
        />
      )}
    </MessageWrapper>
  );
}
