import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import {
  PAGES,
  DEPENDENT_FEATURES,
  DEFAULT_INCIDENT_DESCRIPTION,
  DEFAULT_TABLETOP_DESCRIPTION,
} from '../../configs';
import useAccess from '../../hooks/useAccess';
import useToast from '../../lib/hooks/useToast';
import { MAX_LENGTH } from '../../configs/validation';
import { preventFormSubmitOnEnter } from '../../lib/input';
import Incidents from '../../store/incident/incidentActions';
import Playbooks from '../../store/playbook/playbookActions';
import useBeforeUnload from '../../lib/hooks/useBeforeUnload';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import { selectSortedUsers } from '../../store/user/userSelectors';
import { selectCurrentUser } from '../../store/auth/authSelectors';
import { selectPlaybooks } from '../../store/playbook/playbookSelectors';
import { selectCurrentItem } from '../../store/incident/incidentSelectors';

import BackButton from './BackButton';
import DetailList from './DetailList';
import PlaybookSelect from './PlaybookSelect';
import RichTextEditor from '../RichTextEditor';
import UserAutocomplete from '../UserAutocomplete';
import TaskPreview from '../incidents/activities/TaskPreview';

const Main = styled.main`
  position: relative;
  border-top: 1px solid #ddd;
  height: 100%;
  overflow: auto;
`;

const Container = styled.div`
  width: 65%;
  max-width: 1200px;
  margin: 1.25rem auto 0;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  z-index: 1;
  padding: 1.25rem;
`;

const Label = styled.label`
  font-size: 1rem;
`;

const Form = () => {
  const { id } = useParams();
  const isEditing = !!id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const item = useSelector(selectCurrentItem);
  const userList = useSelector(selectSortedUsers);
  const currentUser = useSelector(selectCurrentUser);
  const canAccessPlaybooks = useAccess({
    dependentFeature: DEPENDENT_FEATURES.playbooks,
  });

  const initialUser = userList.find(
    (u) => u.userProperties.uuid === currentUser.uuid
  );
  const initialValues = {
    title: isEditing ? item?.title : '',
    isTabletop: isEditing ? item?.isTabletop : false,
    isTargeted: isEditing ? !!item?.targetedUsers?.length : false,
    audience: isEditing
      ? item?.targetedUsers
      : !!initialUser
        ? [initialUser]
        : [],
    playbook: 0,
  };

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    values: initialValues,
  });
  const isTabletop = watch('isTabletop');
  const isTargeted = watch('isTargeted');

  const [toastElement, openToast] = useToast();
  const [confirmDiscardElement, openConfirmDiscard] = useConfirmAsync();
  const [submitting, setSubmitting] = useState(false);
  const [getContent, setContentGetter] = useState(null);
  const [setContent, setContentSetter] = useState(null);
  const playbooksList = useSelector(selectPlaybooks);
  const selectedPlaybookId = watch('playbook');
  const isPlaybookSelected = selectedPlaybookId !== 0;
  const [tasks, setTasks] = useState([]);

  const showPlaybookSelect = canAccessPlaybooks && !isEditing;
  const playbookOptions = playbooksList.map((playbook) => ({
    ...playbook,
    label: playbook.name,
    value: playbook.id,
  }));

  useBeforeUnload();

  // Playbook selection effect.
  useEffect(() => {
    if (!isEditing) {
      setTasks(
        playbooksList.find((i) => i.id === selectedPlaybookId)?.activities
      );

      if (selectedPlaybookId !== 0 && setContent) {
        setContent(
          playbooksList.find((i) => i.id === selectedPlaybookId)?.description
        );
      } else if (setContent) {
        setContent(
          isTabletop
            ? DEFAULT_TABLETOP_DESCRIPTION
            : DEFAULT_INCIDENT_DESCRIPTION
        );
      }
    }
  }, [selectedPlaybookId, playbooksList, isTabletop, setContent, isEditing]);

  // Error toast effect.
  useEffect(() => {
    if (!!errors?.title) {
      openToast(errors?.title?.message, 'error', 3000);
    }
  }, [errors?.title, openToast]);

  // Initialization effect.
  useEffect(() => {
    if (!isEditing && canAccessPlaybooks && setContent) {
      dispatch(Playbooks.fetchAll());

      setContent(
        isTabletop ? DEFAULT_TABLETOP_DESCRIPTION : DEFAULT_INCIDENT_DESCRIPTION
      );
    }
  }, [isEditing, canAccessPlaybooks, setContent, isTabletop, dispatch]);

  const handleChangePriority = (id, value) => {
    setTasks((tasks) =>
      tasks.map((t) => {
        if (t.id === id) {
          return {
            ...t,
            priority: value,
          };
        }
        return t;
      })
    );
  };

  const handleDeleteTask = (id) => {
    setTasks((tasks) => tasks.filter((t) => t.id !== id));
  };

  const hasUnsavedChanges = () => {
    const { title } = getValues();
    const content = getContent();

    if (isEditing) {
      return (
        item?.title !== title ||
        item?.fullText.replace(/\s/g, '') !== content.replace(/\s/g, '')
      );
    }
    return (
      title !== '' ||
      content.replace(/\s/g, '') !==
        (isTabletop
          ? DEFAULT_TABLETOP_DESCRIPTION.replace(/\s/g, '')
          : DEFAULT_INCIDENT_DESCRIPTION.replace(/\s/g, ''))
    );
  };

  const handleClickBack = () => {
    if (!hasUnsavedChanges()) {
      navigate(
        isEditing
          ? `${PAGES.incidents.pathname}/${id}`
          : PAGES.incidents.pathname
      );
    } else {
      openConfirmDiscard({
        title: 'Discard changes?',
        content: (
          <div style={{ textAlign: 'center' }}>
            You have unsaved changes. Are you sure you want to leave?
          </div>
        ),
        buttonText: 'Discard',
        onConfirm: () =>
          navigate(
            isEditing
              ? `${PAGES.incidents.pathname}/${id}`
              : PAGES.incidents.pathname
          ),
      });
    }
  };

  const handleChangeTargeted = (value, onChange) => {
    const { audience } = getValues();
    if (!audience.length) {
      setValue('audience', [initialUser]);
    }
    onChange(value);
  };

  const handleChangeAudience = (value, onChange) => {
    if (!value.length) {
      setValue('isTargeted', false);
    }
    onChange(value);
  };

  const handleMaxLengthReached = (show) => {
    if (show) {
      openToast('You have reached the maximum character limit.', 'error', 3000);
    }
  };

  const handleRichTextEditorMount = useCallback((getter, setter) => {
    setContentGetter(() => getter);
    setContentSetter(() => setter);
  }, []);

  const handleFormSubmit = (formData) => {
    setSubmitting(true);
    const { title, isTabletop, isTargeted, audience } = formData;
    const data = {
      title,
      isTabletop,
      fullText: getContent(),
      previewText: getContent(true).substr(0, 200),
      targetedUsers: isTargeted ? audience.map((user) => user.email) : [],
      activityCreateRequests: tasks,
    };

    dispatch(
      isEditing
        ? Incidents.editItem(id, data)
        : Incidents.createItem(data, isPlaybookSelected)
    )
      .then((response) => {
        navigate(
          `/incidents/${isEditing ? id : response.action.payload.data.id}`
        );
        setSubmitting(false);
      })
      .catch((err) => {
        const { response } = err;
        openToast(
          response.status === 403
            ? 'Error: multiple incidents are not enabled.'
            : `Something went wrong while ${
                isEditing ? 'updating' : 'creating'
              } the incident.`,
          'error',
          3000
        );
        setSubmitting(false);
      });
  };

  return (
    <Main>
      <StyledBackButton onClick={handleClickBack} />
      <Container>
        <Stack
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          onKeyDown={preventFormSubmitOnEnter}
          noValidate
          gap={1}
          mb={2}
        >
          <Controller
            name="title"
            control={control}
            rules={{
              required: 'A title is required.',
              validate: (value) =>
                value.length >= MAX_LENGTH.value ? MAX_LENGTH.message : true,
            }}
            render={({ field }) => (
              <Stack>
                <InputLabel>
                  {isTabletop ? 'Tabletop Exercise Title' : 'Incident Title'}
                </InputLabel>
                <TextField
                  {...field}
                  autoFocus
                  variant="outlined"
                  placeholder={
                    isTabletop ? 'New Tabletop Exercise' : 'New Incident'
                  }
                  error={!!errors.title}
                  sx={{
                    '& .MuiInputBase-input': {
                      py: 0,
                      px: 0,
                      lineHeight: 1,
                      fontSize: '1.5rem',
                    },
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  }}
                />
              </Stack>
            )}
          />
          <div>
            <DetailList
              isWaiting={!isEditing}
              isActive={item?.isActive || false}
              createdBy={currentUser.user}
              createTimestamp={isEditing ? item?.createTimestamp : Date.now()}
            />
          </div>
          <Divider />
          {showPlaybookSelect && (
            <Controller
              name="playbook"
              control={control}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Stack direction="row" alignItems="center">
                    <Label sx={{ minWidth: '11.5rem' }}>
                      Playbook Template:
                    </Label>
                    <PlaybookSelect
                      {...field}
                      value={value}
                      defaultValue={0}
                      onChange={onChange}
                      options={playbookOptions}
                    />
                  </Stack>
                );
              }}
            />
          )}
          <Controller
            name="isTabletop"
            control={control}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <FormControlLabel
                  label="Tabletop Exercise:"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      color="primary"
                      checked={value}
                      disabled={isEditing}
                      onChange={(e, value) => onChange(value)}
                    />
                  }
                  sx={{
                    '&&': {
                      justifyContent: 'start',
                      mt: 1,
                      ml: 0,
                      mb: 1,
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '1rem',
                      minWidth: '10.75rem',
                    },
                    '& .MuiFormControlLabel-label.Mui-disabled': {
                      color: 'inherit',
                    },
                    '& .MuiCheckbox-root': {
                      py: 0,
                    },
                  }}
                />
              );
            }}
          />
          <Controller
            name="isTargeted"
            control={control}
            render={({ field }) => {
              const { value, onChange } = field;
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  sx={{ marginBottom: '8px' }}
                >
                  <Label sx={{ minWidth: '11rem' }}>
                    Organization {isTabletop ? 'Exercise' : 'Incident'}
                  </Label>
                  <FormControlLabel
                    label={`Targeted ${isTabletop ? 'Exercise' : 'Incident'}`}
                    labelPlacement="end"
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={value}
                        disabled={isEditing}
                        onChange={(e, value) =>
                          handleChangeTargeted(value, onChange)
                        }
                      />
                    }
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        ml: '0.25rem',
                        fontSize: '1rem',
                      },
                      '& .MuiFormControlLabel-label.Mui-disabled': {
                        color: 'inherit',
                      },
                    }}
                  />
                </Stack>
              );
            }}
          />
          <Controller
            name="audience"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Stack sx={!isTargeted && { display: 'none' }}>
                  <Label>Audience:</Label>
                  <UserAutocomplete
                    {...field}
                    disabled={isEditing && isTabletop}
                    onChange={(value) => handleChangeAudience(value, onChange)}
                  />
                </Stack>
              );
            }}
          />
          <RichTextEditor
            height="275px"
            placeholder="Enter description..."
            onMount={handleRichTextEditorMount}
            handleMaxChar={handleMaxLengthReached}
            initialContent={
              isEditing ? item?.fullText : DEFAULT_INCIDENT_DESCRIPTION
            }
            style={{ margin: '0.5rem 0' }}
          />
          {isPlaybookSelected && tasks && tasks.length > 0 && (
            <>
              <Divider />
              <Typography fontSize="16px">Tasks</Typography>
              <Stack spacing={3.5} mb={2.5}>
                {tasks.map((task, index) => (
                  <TaskPreview
                    {...task}
                    key={index}
                    onClickDelete={() => handleDeleteTask(task.id)}
                    onChangePriority={(e) =>
                      handleChangePriority(task.id, e.target.value)
                    }
                  />
                ))}
              </Stack>
            </>
          )}
          <Button
            disabled={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {submitting ? 'Saving...' : isEditing ? 'Save' : 'Submit'}
          </Button>
        </Stack>
      </Container>
      {toastElement}
      {confirmDiscardElement}
    </Main>
  );
};

export default Form;
