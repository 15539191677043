import * as Sentry from '@sentry/react';

/**
 * Log a message to both the console and Sentry, with an optional level of severity.
 * @param {String} message - The message to be logged.
 * @param {String} [level] - Available levels are "fatal", "error", "warning", "log", "info", and "debug".
 */
export const logBehavior = (message, level = 'log') => {
  const consoleString = `@@SHQ/Log [${level}] - ${message}`;

  if (level === 'fatal' || level === 'error') {
    console.error(consoleString);
  } else if (level === 'warning') {
    console.warn(consoleString);
  } else if (level === 'info') {
    console.info(consoleString);
  } else {
    console.log(consoleString);
  }

  return Sentry.captureMessage(message, level);
};

/**
 * Captures an error event and sends it to Sentry.
 * @param {Error} error - The error that occurred.
 */
export const surfaceBehavior = (error) => {
  console.error(error);
  return Sentry.captureException(error);
};
