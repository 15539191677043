import { DRAFT_TYPES } from './draftReducer';

const Drafts = {
  update: (channelId, data) => ({
    type: DRAFT_TYPES.UPDATE,
    payload: {
      channelId,
      data,
    },
  }),
  setMessage: (channelId, message) => ({
    type: DRAFT_TYPES.SET_MESSAGE,
    payload: {
      channelId,
      message,
    },
  }),
  setFile: (channelId, file) => ({
    type: DRAFT_TYPES.SET_FILE,
    payload: {
      channelId,
      file,
    },
  }),
  delete: (channelId) => ({
    type: DRAFT_TYPES.DELETE,
    payload: channelId,
  }),
  clear: () => ({
    type: DRAFT_TYPES.CLEAR,
  }),
};

export default Drafts;
