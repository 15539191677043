import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import Users from '../../store/user/userActions';
import { selectCurrentUserProfileImage } from '../../store/auth/authSelectors';

import BasicModal from '../BasicModal';
import FileInputButton from '../FileInputButton';
import ClearIcon from '../../assets/icons/close.svg?react';
import defaultProfileImage from '../../assets/icons/default-profile.svg';

const AvatarImageContainer = styled(Box)`
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MAX_FILE_SIZE = 7340032;
const MAX_UPLOAD_TEXT = 'Max. Image Size: 7 MB';
const SIZE_ERROR_TEXT = 'The image must be less than 7 MB.';
const CONVERT_ERROR_TEXT = 'An unexpected error occurred.';
const UPDATE_ERROR_TEXT = 'An unexpected error occurred.';

const AvatarManager = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const currentUserProfileImage = useSelector(selectCurrentUserProfileImage);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [helperText, setHelperText] = useState(MAX_UPLOAD_TEXT);

  const avatarSrc = !!preview
    ? preview
    : !!currentUserProfileImage
      ? currentUserProfileImage
      : defaultProfileImage;

  const error = [
    SIZE_ERROR_TEXT,
    CONVERT_ERROR_TEXT,
    UPDATE_ERROR_TEXT,
  ].includes(helperText);

  const isValidSize = (file) => !!file && file.size <= MAX_FILE_SIZE;

  const handleChangeFile = (e) => {
    const newFile = e.target.files[0];

    if (!newFile || !isValidSize(newFile)) {
      return setHelperText(SIZE_ERROR_TEXT);
    }

    setFile(null);
    setPreview(null);
    setLoading(true);
    dispatch(Users.convertedProfileImg(newFile))
      .then((src) => {
        setFile(newFile);
        setPreview(src);
        setHelperText(`File: ${newFile.name}`);
      })
      .catch((error) => {
        setHelperText(CONVERT_ERROR_TEXT);
        console.error('Error while converting image:', error);
      })
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setFile(null);
    setPreview(null);
    setHelperText(MAX_UPLOAD_TEXT);
  };

  const handleClose = () => {
    handleClear();
    onClose();
  };

  const handleSave = () => {
    if (!file) {
      return handleClear();
    }
    setSaving(true);
    setHelperText('Saving changes...');
    return dispatch(Users.setProfileImg(file))
      .then(() => {
        setHelperText('Avatar changed successfully!');
        handleClose();
      })
      .catch(() => {
        setHelperText(CONVERT_ERROR_TEXT);
        console.error('Error saving image:', error);
      })
      .finally(() => setSaving(false));
  };

  return (
    <BasicModal
      open={open}
      title="Manage Avatar"
      cancelButtonText="Close"
      onClose={() => handleClose()}
      onClickButton={handleSave}
      buttonDisabled={!isValidSize(file)}
      bodyStyles={{ alignItems: 'center' }}
      sx={{ minWidth: '24rem' }}
    >
      <AvatarImageContainer>
        {loading ? (
          <CircularProgress size={100} />
        ) : (
          <AvatarImage alt="Profile Image" src={avatarSrc} />
        )}
      </AvatarImageContainer>
      {!!preview ? (
        <Button
          size="small"
          variant="contained"
          disabled={saving}
          onClick={handleClear}
          startIcon={<SvgIcon component={ClearIcon} />}
        >
          Clear
        </Button>
      ) : (
        <FileInputButton
          size="small"
          variant="contained"
          disabled={loading}
          onChange={handleChangeFile}
          inputProps={{ accept: 'image/jpeg,image/png,image/bmp' }}
        >
          Choose File
        </FileInputButton>
      )}
      {loading ? (
        <Typography color="primary">Uploading image...</Typography>
      ) : (
        <Typography color={!!error ? 'error' : 'primary'}>
          {helperText}
        </Typography>
      )}
    </BasicModal>
  );
};

export default AvatarManager;
