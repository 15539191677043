import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';

import Bold from '../../../assets/icons/bold.svg?react';
import Italic from '../../../assets/icons/italic.svg?react';
// import Strikethrough from '../../../assets/icons/strikethrough.svg?react';
import CodeSlash from '../../../assets/icons/code-s-slash-fill.svg?react';
import ListUnordered from '../../../assets/icons/list-unordered.svg?react';
import ListOrdered from '../../../assets/icons/list-ordered.svg?react';

const RichTextButton = styled(Button)`
  height: 38px;
  width: 38px;
  
  &.active {
    background-color: rgba(224,224,224,1);
  }

}

  svg {
    font-size: 1.15rem;
`;
export default function RichTextButtons({
  handleFormat,
  handleFormatChange,
  aStyle,
}) {
  return (
    <ButtonGroup size="small">
      <RichTextButton
        variant="text"
        onClick={() => {
          handleFormat('BOLD');
          handleFormatChange('bold');
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={`button ${aStyle.has('BOLD') ? 'active' : ''}`}
      >
        <SvgIcon>
          <Bold />
        </SvgIcon>
      </RichTextButton>
      <RichTextButton
        variant="text"
        onClick={() => {
          handleFormat('ITALIC');
          handleFormatChange('italic');
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={`button ${aStyle.has('ITALIC') ? 'active' : ''}`}
      >
        <SvgIcon fontSize="small">
          <Italic />
        </SvgIcon>
      </RichTextButton>
      {/*<RichTextButton*/}
      {/*  variant="text"*/}
      {/*  onClick={() => { handleFormat('STRIKETHROUGH'); handleFormatChange('strikethrough'); }}*/}
      {/*  onMouseDown={(e) => e.preventDefault()}*/}
      {/*  className={`button ${aStyle.has('STRIKETHROUGH') ? 'active' : ''}`}*/}
      {/*>*/}
      {/*  <SvgIcon fontSize="small">*/}
      {/*    <Strikethrough />*/}
      {/*  </SvgIcon>*/}
      {/*</RichTextButton>*/}
      <RichTextButton
        variant="text"
        onClick={() => {
          handleFormat('CODE');
          handleFormatChange('code');
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={`button ${aStyle.has('CODE') ? 'active' : ''}`}
      >
        <SvgIcon fontSize="small">
          <CodeSlash />
        </SvgIcon>
      </RichTextButton>
      <RichTextButton
        variant="text"
        onClick={() => {
          handleFormat('UL');
          handleFormatChange('ul');
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={`button ${aStyle.has('UL') ? 'active' : ''}`}
      >
        <SvgIcon fontSize="small">
          <ListUnordered />
        </SvgIcon>
      </RichTextButton>
      <RichTextButton
        variant="text"
        onClick={() => {
          handleFormat('OL');
          handleFormatChange('ol');
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={`button ${aStyle.has('OL') ? 'active' : ''}`}
      >
        <SvgIcon fontSize="small">
          <ListOrdered />
        </SvgIcon>
      </RichTextButton>
    </ButtonGroup>
  );
}
