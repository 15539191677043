import {
  useLocation,
  useBeforeUnload,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectErrorCode,
  selectIsFetching,
  selectLastUpdateTimestamp,
  selectShouldPromptBeforeUnload,
} from '../../store/welcome/welcomeSelectors';
import Welcome from '../../store/welcome/welcomeActions';
import { WELCOME_PACKAGE_ERRORS } from '../../configs/welcomePackage';
import { getDocumentTitleByPathName } from '../../configs/management';

import FormWithSteps from './FormWithSteps';
import { PulseLogoLoader } from '../loader';
import PackageNotFound from './PackageNotFound';
import LogoImage from '../../assets/images/logo.svg?react';

const Page = styled(Stack)`
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

const Container = styled(Stack)`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1130px;
  max-height: 725px;
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

const Logo = styled(LogoImage)`
  width: 200px;
  height: auto;
`;

const Body = styled(Stack)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;
`;

const LastUpdatedTimestamp = ({ hidden }) => {
  const timestamp = useSelector(selectLastUpdateTimestamp);
  const formattedTimestamp = dayjs(timestamp).format('LLL');

  if (!timestamp || hidden) {
    return null;
  }

  return (
    <Typography>
      <b>Last Updated:</b> {formattedTimestamp}
    </Typography>
  );
};

const WelcomePackage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const errorCode = useSelector(selectErrorCode);
  const isFetching = useSelector(selectIsFetching);
  const shouldPrompt = useSelector(selectShouldPromptBeforeUnload);

  const token = searchParams.get('id');
  const notFound =
    !isFetching && (errorCode === WELCOME_PACKAGE_ERRORS.NOT_FOUND || !token);

  const handleBeforeUnload = useCallback(
    (e) => {
      if (shouldPrompt) {
        e.preventDefault();
      }
    },
    [shouldPrompt]
  );

  // Prompt user if the form is not marked complete and was modified.
  useBeforeUnload(handleBeforeUnload);

  // Initialization effect.
  useEffect(() => {
    document.title = getDocumentTitleByPathName(pathname);

    if (!!token) {
      dispatch(Welcome.initialize(token));
      dispatch(Welcome.fetch());
    }
  }, [pathname, token, dispatch]);

  return (
    <Page>
      <Container gap={2}>
        <Stack
          component="header"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexShrink: 0, px: 4 }}
        >
          <Logo />
          <Typography component="h1" variant="h3">
            Welcome Package
          </Typography>
        </Stack>
        <Body direction="row" sx={{ flexGrow: 1 }}>
          {isFetching ? (
            <PulseLogoLoader sx={{ flex: 1 }} />
          ) : notFound ? (
            <PackageNotFound />
          ) : (
            <FormWithSteps />
          )}
        </Body>
        <Stack
          component="footer"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexShrink: 0, px: 4 }}
        >
          <Typography>
            Having issues with the form?{' '}
            <a href="mailto:support@shadowhq.io">Contact us.</a>
          </Typography>
          <LastUpdatedTimestamp hidden={notFound} />
        </Stack>
      </Container>
    </Page>
  );
};

export default WelcomePackage;
