import {
  WELCOME_PACKAGE_ERRORS,
  WELCOME_PACKAGE_STATES,
} from '../../configs/welcomePackage';

export const WELCOME_TYPES = {
  INITIALIZE: '@@welcome/INITIALIZE',
  FETCH: '@@welcome/FETCH',
  VALIDATE: '@@welcome/VALIDATE',
  SAVE: '@@welcome/SAVE',
  IMPORT_USERS: '@@welcome/IMPORT_USERS',
  ADD_USER: '@@welcome/ADD_USER',
  EDIT_USER: '@@welcome/EDIT_USER',
  REMOVE_USER: '@@welcome/REMOVE_USER',
  ADD_WAR_ROOM: '@@welcome/ADD_WAR_ROOM',
  EDIT_WAR_ROOM: '@@welcome/EDIT_WAR_ROOM',
  REMOVE_WAR_ROOM: '@@welcome/REMOVE_WAR_ROOM',
  ADD_FILE_FOLDER: '@@welcome/ADD_FILE_FOLDER',
  EDIT_FILE_FOLDER: '@@welcome/EDIT_FILE_FOLDER',
  REMOVE_FILE_FOLDER: '@@welcome/REMOVE_FILE_FOLDER',
  MARK_COMPLETE: '@@welcome/MARK_COMPLETE',
  CLEAR_ERROR: '@@welcome/CLEAR_ERROR',
};

const INITIAL_STATE = {
  uid: '',
  apiAddress: '',
  status: WELCOME_PACKAGE_STATES.CREATED,
  maxUsers: 50,
  createTimestamp: 0,
  lastUpdateTimestamp: 0,
  mainAdminUser: null,
  orgCode: '',
  users: [],
  warRooms: [],
  fileFolders: [],
  errorCode: '',
  modified: false,
  fetching: false,
  saving: false,
};

const welcomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WELCOME_TYPES.INITIALIZE: {
      const { uid, apiAddress } = action.payload;
      return {
        ...state,
        uid,
        apiAddress,
      };
    }
    case `${WELCOME_TYPES.FETCH}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${WELCOME_TYPES.SAVE}_PENDING`:
      return {
        ...state,
        saving: true,
      };
    case `${WELCOME_TYPES.FETCH}_REJECTED`:
      return {
        ...INITIAL_STATE,
        fetching: false,
        errorCode: WELCOME_PACKAGE_ERRORS.NOT_FOUND,
      };
    case `${WELCOME_TYPES.SAVE}_REJECTED`: {
      const { data } = action.payload.response;
      return {
        ...state,
        saving: false,
        errorCode: data.errorCode,
      };
    }
    case `${WELCOME_TYPES.FETCH}_FULFILLED`:
    case `${WELCOME_TYPES.SAVE}_FULFILLED`: {
      const { data } = action.payload;

      const mainAdminUser = {
        ...data.mainAdminUser,
        id: data.mainAdminUser.email,
        isMainAdminUser: true,
      };
      const users = [mainAdminUser, ...data.users].map((u) => ({
        ...u,
        id: u.email,
      }));

      return {
        ...state,
        status: data.status,
        maxUsers: data.maxUsers,
        createTimestamp: data.createTimestamp,
        lastUpdateTimestamp: data.lastUpdateTimestamp,
        mainAdminUser,
        orgCode: data.enterpriseCode,
        users,
        warRooms: data.warRooms.map((w, i) => ({
          key: i,
          label: w.name,
          admin: users.find((u) => u.id === w.usersEmails?.[0]) || null,
          members:
            w.usersEmails.length > 1
              ? users.filter((u) => w.usersEmails.slice(1).includes(u.id))
              : [],
          emails: w.usersEmails,
        })),
        fileFolders: data.fileFolders.map((f, i) => ({
          key: i,
          label: f.name,
          adminOnly: Boolean(f?.admin),
        })),
        errorCode: data.errorCode,
        saving: false,
        fetching: false,
        modified: false,
      };
    }
    case WELCOME_TYPES.VALIDATE:
      return {
        ...state,
        ...action.payload,
      };
    case WELCOME_TYPES.IMPORT_USERS:
      return {
        ...state,
        modified: true,
        users: [...action.payload],
      };
    case WELCOME_TYPES.ADD_USER:
      return {
        ...state,
        modified: true,
        users: [
          ...state.users,
          { ...action.payload, id: action.payload.email },
        ],
      };
    case WELCOME_TYPES.EDIT_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };
    case WELCOME_TYPES.REMOVE_USER:
      return {
        ...state,
        modified: true,
        users: state.users.filter((user) => user.id !== action.payload.id),
        warRooms: state.warRooms.map((warRoom) => {
          if (warRoom.members.find((user) => user.id === action.payload.id)) {
            return {
              ...warRoom,
              members: warRoom.members.filter(
                (user) => user.id !== action.payload.id
              ),
            };
          }
          return warRoom;
        }),
      };
    case WELCOME_TYPES.ADD_WAR_ROOM:
      return {
        ...state,
        errorCode: '',
        modified: true,
        warRooms: [...state.warRooms, action.payload],
      };
    case WELCOME_TYPES.EDIT_WAR_ROOM:
      return {
        ...state,
        errorCode: '',
        modified: true,
        warRooms: state.warRooms.map((warRoom) =>
          warRoom.key === action.payload.key
            ? { ...warRoom, ...action.payload }
            : warRoom
        ),
      };
    case WELCOME_TYPES.REMOVE_WAR_ROOM:
      return {
        ...state,
        errorCode: '',
        modified: true,
        warRooms: state.warRooms.filter(
          (warRoom) => warRoom.key !== action.payload.key
        ),
      };
    case WELCOME_TYPES.ADD_FILE_FOLDER:
      return {
        ...state,
        modified: true,
        fileFolders: [...state.fileFolders, action.payload],
      };
    case WELCOME_TYPES.EDIT_FILE_FOLDER:
      return {
        ...state,
        modified: true,
        fileFolders: state.fileFolders.map((fileFolder) =>
          fileFolder.key === action.payload.key ? action.payload : fileFolder
        ),
      };
    case WELCOME_TYPES.REMOVE_FILE_FOLDER:
      return {
        ...state,
        modified: true,
        fileFolders: state.fileFolders.filter(
          (fileFolder) => fileFolder.key !== action.payload.key
        ),
      };
    case WELCOME_TYPES.MARK_COMPLETE:
      return {
        ...state,
        modified: true,
        status: WELCOME_PACKAGE_STATES.SUBMITTED,
      };
    case WELCOME_TYPES.CLEAR_ERROR:
      return {
        ...state,
        errorCode: '',
      };
    default:
      return state;
  }
};

export default welcomeReducer;
