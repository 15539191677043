import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectIsReady,
  selectIsActiveTab,
} from '../../store/heartbeat/heartbeatSelectors';
import Authentication from '../../store/auth/authActions';
import { selectIsRefreshing } from '../../store/token/tokenSelectors';
import { calculateRefreshTimeFromExpiry } from '../../utils/tokenUtils';
import { selectAuthenticationTokenExpiry } from '../../store/auth/authSelectors';

/**
 * Check the documentation for this component in the project README.
 */
const TokenRefreshScheduler = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(selectIsReady);
  const isActiveTab = useSelector(selectIsActiveTab);
  const isRefreshing = useSelector(selectIsRefreshing);
  const authenticationTokenExpiry = useSelector(
    selectAuthenticationTokenExpiry
  );
  const refreshTimeoutId = useRef(null);

  const clearScheduledTokenRefresh = () => {
    if (refreshTimeoutId.current) {
      if (isActiveTab) {
        console.log('Clearing scheduled token refresh.');
      }
      clearTimeout(refreshTimeoutId.current);
      refreshTimeoutId.current = null;
    }
  };

  const scheduleTokenRefresh = () => {
    clearScheduledTokenRefresh();

    // Set a timeout to refresh the auth token
    if (authenticationTokenExpiry && !isRefreshing && isActiveTab && isReady) {
      const refreshTime = calculateRefreshTimeFromExpiry(
        authenticationTokenExpiry
      );
      console.log(
        `TokenRefreshScheduler: scheduling token refresh (${Number(
          refreshTime / 1000 / 60
        ).toFixed(1)})...`
      );
      refreshTimeoutId.current = setTimeout(() => {
        console.log('TokenRefreshScheduler: Refreshing token...');
        dispatch(
          Authentication.refreshToken({ source: 'TokenRefreshScheduler' })
        );
      }, refreshTime);
    }
  };

  useEffect(() => {
    // console.log('TokenRefreshScheduler mount');

    // Online/offline event handlers
    const handleOnline = () => {
      console.log('Browser is online, scheduling token refresh in active tab.');
      if (isActiveTab && isReady) {
        scheduleTokenRefresh();
      }
    };

    const handleOffline = () => {
      console.log('Browser is offline, clearing scheduled token refresh.');
      clearScheduledTokenRefresh();
    };

    scheduleTokenRefresh();
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      // console.log('TokenRefreshScheduler unmount');
      clearScheduledTokenRefresh();
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationTokenExpiry, isRefreshing, isActiveTab, isReady]);

  return null;
};

export default TokenRefreshScheduler;
