import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Heartbeat from '../../store/heartbeat/heartbeatActions';
import { isActiveTab, updateHeartbeat } from '../../utils/tabUtils';

/**
 * Check the documentation for this component in the project README.
 */
const HeartbeatManager = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log('HeartbeatManager mount');

    const heartbeatInterval = setInterval(() => {
      const active = isActiveTab();
      dispatch(Heartbeat.setIsActiveTab(active));
      if (active) {
        updateHeartbeat();
      }
    }, 5000); // Check every 5 seconds.

    return () => {
      // console.log('HeartbeatManager unmount');
      clearInterval(heartbeatInterval);
    };
  }, [dispatch]);

  return null;
};

export default HeartbeatManager;
