import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const spacing = '0.65rem';

const Container = styled.article`
  display: flex;
  padding: ${spacing} 0;
  border-radius: 0.5rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left: 0.4rem solid ${({ theme }) => theme.palette.secondary.main};
  scroll-margin-bottom: 6rem;

  ${({ theme, editable }) =>
    editable === 'true' &&
    `&&:hover {
      border: 1px solid #777;
      transition: all 0.2s ease-in;
      border-left: 0.4rem solid ${theme.palette.secondary.main};
    }`}
`;

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${spacing};
`;

const ContentLeft = styled(Stack)`
  flex-grow: 1;
  gap: 0.5rem;
  padding: 0 ${spacing};
`;

const ContentRight = styled(Stack)`
  flex-shrink: 0;
  padding: 0 ${spacing};
  justify-content: center;
  border-left: 1px solid #ddd;
`;

const TaskLayout = forwardRef(
  (
    {
      isEditable,
      headerComponent,
      headerStyles,
      bodyComponent,
      rightComponent = null,
      sx,
    },
    ref
  ) => (
    <Container ref={ref} editable={isEditable.toString()} sx={sx}>
      <ContentLeft>
        <HeaderContainer sx={headerStyles}>{headerComponent}</HeaderContainer>
        <Divider />
        {bodyComponent}
      </ContentLeft>
      {!!rightComponent && isEditable && (
        <ContentRight>{rightComponent}</ContentRight>
      )}
    </Container>
  )
);

TaskLayout.displayName = 'TaskLayout';

export default TaskLayout;
