import * as mime from 'mime';
import unescape from 'lodash.unescape';

import {
  getFileExt,
  getFilenameWithoutExt,
  getBreadcrumbsFromId,
} from '../../lib/files';
import {
  selectTree,
  selectCurrentFolderId,
  selectCurrentFolderIsRoot,
} from './storageSelectors';
import { FOLDER_TYPES } from '../../configs';
import { axiosInstance } from '../../lib/axiosInstance';
import { STORAGE_FILTERS, STORAGE_TYPES } from './storageReducer';

const Storage = {
  fetchTree: () => ({
    type: STORAGE_TYPES.FETCH_TREE,
    payload: axiosInstance
      .get('/sfs?hideIncidentsData=true')
      .then(({ data }) => ({
        ...data,
        tree: !!data?.tree
          ? data.tree.map((item) => ({
              ...item,
              name: unescape(item.name),
            }))
          : [],
      })),
  }),
  fetchFolder: (parentId) => ({
    type: STORAGE_TYPES.FETCH_FOLDER,
    payload: axiosInstance.get(`/sfs/folder/${parentId}`),
  }),
  createFolder: (data) => (dispatch, getState) => {
    const currentFolderId = selectCurrentFolderId(getState());
    const isCurrentFolderRoot = selectCurrentFolderIsRoot(getState());

    const { name, folderType, groupIds } = data;
    const folderData = isCurrentFolderRoot
      ? {
          name,
          folderType,
          groupIds: folderType === FOLDER_TYPES.PRIVATE ? groupIds : [],
          parentFolderId: currentFolderId,
        }
      : {
          name,
          parentFolderId: currentFolderId,
        };

    return dispatch({
      type: STORAGE_TYPES.CREATE_FOLDER,
      payload: axiosInstance
        .post('/sfs/folder', folderData)
        .then(({ data }) => ({
          ...data,
          name: unescape(data.name),
        })),
    });
  },
  uploadFile: (file, folderId) => (dispatch) => {
    const body = new FormData();
    body.append('file', file);
    body.append('name', getFilenameWithoutExt(file.name));
    body.append('extension', getFileExt(file.name) || '');
    body.append('parentFolderId', folderId);

    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return dispatch({
      type: STORAGE_TYPES.UPLOAD_FILE,
      payload: axiosInstance.post('/sfs', body, options).then(({ data }) => ({
        ...data,
        name: unescape(data.name),
      })),
    });
  },
  downloadFile: (file, onDownloadProgress) => ({
    type: STORAGE_TYPES.DOWNLOAD_FILE,
    payload: axiosInstance
      .get(`/sfs/${file.id}`, { responseType: 'blob', onDownloadProgress })
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: mime.getType(file.extension.toLowerCase()),
        });
        return window.URL.createObjectURL(blob);
      }),
  }),
  renameFile: (item, name) => ({
    type: STORAGE_TYPES.RENAME_ITEM,
    payload: axiosInstance
      .patch(`/sfs/${item.id}`, {
        name,
      })
      .then(({ data }) => ({
        ...data,
        name: unescape(data.name),
      })),
  }),
  editFolder: (id, data) => ({
    type: STORAGE_TYPES.EDIT_ITEM,
    payload: axiosInstance.patch(`/sfs/${id}`, data).then(({ data }) => ({
      ...data,
      name: unescape(data.name),
    })),
  }),
  deleteItem: (item) => ({
    type: STORAGE_TYPES.DELETE_ITEM,
    payload: axiosInstance.delete(`/sfs/${item.id}`).then(() => item.id),
  }),
  updateBreadcrumbs: (folderId) => (dispatch, getState) => {
    const tree = selectTree(getState());
    const breadcrumbs = getBreadcrumbsFromId(tree, folderId);

    return dispatch({
      type: STORAGE_TYPES.UPDATE_BREADCRUMBS,
      payload: breadcrumbs,
    });
  },
  setFilter: (value) => ({
    type: STORAGE_TYPES.SET_FILTER,
    payload: value || STORAGE_FILTERS.ALL,
  }),
  search: (searchTerm) => ({
    type: STORAGE_TYPES.SEARCH,
    payload: axiosInstance.get(`/sfs/search?query=${searchTerm}`),
  }),
  requestVerification: () => ({
    type: STORAGE_TYPES.REQUEST_VERIFICATION,
    payload: axiosInstance.post('/sfs/2fa', undefined),
  }),
  sendVerification: (confirmationCode) => ({
    type: STORAGE_TYPES.SEND_VERIFICATION,
    payload: axiosInstance.post('/sfs/2fa/confirm', {
      confirmationCode,
    }),
  }),
  clearVerification: () => ({
    type: STORAGE_TYPES.CLEAR_VERIFICATION,
  }),
};

export default Storage;
