import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { formatTimestamp, timeFromNow } from '../../lib/date';

const DetailItem = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0.25rem 0;
  justify-content: flex-start;
  & > h6:first-of-type {
    width: 11.5rem;
  }
`;

const StatusChip = styled(Chip)`
  height: 24px;
  .MuiChip-label {
    font-size: 0.9375rem;
  }
`;

const DetailList = ({
  isActive,
  createdBy,
  createTimestamp,
  resolvedBy,
  resolveTimestamp,
  isWaiting = false,
}) => {
  const isResolved = !isActive;
  const durationString = !isResolved
    ? timeFromNow(dayjs().diff(createTimestamp)) // Time from when created.
    : timeFromNow(dayjs(resolveTimestamp).diff(createTimestamp)); // Time between created and resolved.
  const statusColor = isWaiting ? 'warning' : isResolved ? 'success' : 'error';
  const statusString = isWaiting
    ? 'Waiting'
    : isResolved
      ? 'Resolved'
      : 'Ongoing';

  return (
    <>
      <DetailItem>
        <Typography variant="h6">Created by:</Typography>
        <Typography variant="h6" fontWeight={400}>
          {createdBy.firstName} {createdBy.lastName}
        </Typography>
      </DetailItem>
      <DetailItem>
        <Typography variant="h6">Started:</Typography>
        <Typography variant="h6" fontWeight={400}>
          {formatTimestamp(createTimestamp)}
        </Typography>
      </DetailItem>
      {isResolved && !!resolveTimestamp && (
        <DetailItem>
          <Typography variant="h6">Resolved:</Typography>
          <Typography variant="h6" fontWeight={400}>
            {formatTimestamp(resolveTimestamp)}
          </Typography>
        </DetailItem>
      )}
      <DetailItem>
        <Typography variant="h6">Duration:</Typography>
        <Typography variant="h6" fontWeight={400}>
          {durationString}
        </Typography>
      </DetailItem>
      <DetailItem>
        <Typography variant="h6">Status:</Typography>
        <StatusChip color={statusColor} label={statusString} />
      </DetailItem>
      {isResolved && !!resolvedBy && (
        <DetailItem>
          <Typography variant="h6">Resolved by:</Typography>
          <Typography variant="h6" fontWeight={400}>
            {resolvedBy.firstName} {resolvedBy.lastName}
          </Typography>
        </DetailItem>
      )}
    </>
  );
};

export default DetailList;
