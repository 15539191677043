import { styled } from '@mui/material/styles';

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 3em 0;
`;

const LoadingComponent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 4em;
`;

const LoadingText = styled.div`
  text-align: center;
  margin-top: 1em;
  font-size: 1rem;
  color: #d1d2db;
`;

export default function SuspenseLoading({ style, component, sx }) {
  return (
    <LoadingWrapper style={{ ...style }} sx={sx}>
      <LoadingComponent>{component}</LoadingComponent>
      <LoadingText>Loading...</LoadingText>
    </LoadingWrapper>
  );
}
