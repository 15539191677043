import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';

import { selectSDKConnected } from '../../store/sdk/sdkSelectors';
import { selectLastLogin } from '../../store/auth/authSelectors';
import { minutesSinceTimestamp } from '../../lib/date';
import Dashboard from '../../store/dashboard/dashboardActions';
import Incidents from '../../store/incident/incidentActions';
import Storage from '../../store/storage/storageActions';
import { selectVerified } from '../../store/storage/storageSelectors';
import { selectAllowMultipleOngoingEvents } from '../../store/org/orgSelectors';
import {
  selectHasIncident,
  selectDashboardLoaded,
  selectTimeSinceIncident,
  selectCurrentUsers,
  selectMaxUsers,
  selectLatestArticleId,
  selectHasMultipleIncidents,
  selectIncidentsCount,
} from '../../store/dashboard/dashboardSelectors';
import { PAGES, ROLES, FEATURES, DEPENDENT_FEATURES } from '../../configs';
import { selectUnresolvedTabletops } from '../../store/incident/incidentSelectors';
import DashBadge from './DashBadge';
import DashPanel from './DashPanel';
import { PulseLogoLoader } from '../loader/Loader';
import Access from '../Access';

// Icons
import CreateIcon from '../../assets/icons/icCreateInc.svg?react';
import UpdateIcon from '../../assets/icons/icClock.svg?react';
import UserAdminIcon from '../../assets/icons/icUsers.svg?react';
import DocIcon from '../../assets/icons/archive-outline.svg?react';
import MessageIcon from '../../assets/icons/icMessaging.svg?react';
import WarRoomsIcon from '../../assets/icons/icWarRooms.svg?react';
import PlaybookIcon from '../../assets/icons/book.svg?react';

import ShieldGreenBadge from '../../assets/icons/db_ShieldGreen.svg';
import ShieldRedBadge from '../../assets/icons/DB_ShieldRed.svg';
import ShieldYellowBadge from '../../assets/icons/DB_ShieldYellow.svg';
import TimeBadge from '../../assets/icons/DB_time.svg';
import UserBadge from '../../assets/icons/UserBadge.svg';

const Screen = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  margin: auto 5rem;
`;

const RowBadge = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const ColumnBadge = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const Column = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.25em;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

function LoadingDashboard() {
  return (
    <LoadingWrapper>
      <PulseLogoLoader />
      <div>Loading...</div>
    </LoadingWrapper>
  );
}

export default function DashboardScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUsers = useSelector(selectCurrentUsers);
  const lastLogin = useSelector(selectLastLogin);
  const maxUsers = useSelector(selectMaxUsers);
  const allowMultipleOngoingEvents = useSelector(
    selectAllowMultipleOngoingEvents
  );
  const hasMultipleIncidents = useSelector(selectHasMultipleIncidents);
  const incidentsCount = useSelector(selectIncidentsCount);
  const hasIncident = useSelector(selectHasIncident);
  const unresolvedTabletops = useSelector(selectUnresolvedTabletops);
  const dashboardLoaded = useSelector(selectDashboardLoaded);
  const isConnected = useSelector(selectSDKConnected);
  const timeSinceIncident = useSelector(selectTimeSinceIncident);
  const latestArticleId = useSelector(selectLatestArticleId);
  const verified = useSelector(selectVerified);

  const hasMultipleTabletops = unresolvedTabletops.length > 1;
  const hasTabletop = unresolvedTabletops.length === 1;

  const incidentBadge =
    hasMultipleIncidents || hasIncident
      ? ShieldRedBadge
      : hasMultipleTabletops || hasTabletop
        ? ShieldYellowBadge
        : ShieldGreenBadge;

  const incidentBadgeColor =
    hasMultipleIncidents || hasIncident
      ? theme.palette.error.main
      : hasMultipleTabletops || hasTabletop
        ? theme.palette.warning.main
        : theme.palette.success.main;

  const incidentBadgeStatus = hasMultipleIncidents
    ? `${incidentsCount} Incidents In Progress`
    : hasIncident
      ? 'Incident In Progress'
      : hasMultipleTabletops
        ? `${unresolvedTabletops.length} Exercises In Progress`
        : hasTabletop
          ? 'Exercise In Progress'
          : 'Safe';

  const hasButton = incidentBadgeStatus !== 'Safe';

  const isOngoing =
    hasMultipleIncidents || hasIncident || hasMultipleTabletops || hasTabletop;
  const isIncidentOngoing = hasMultipleIncidents || hasIncident;
  const disableCreateIncident = allowMultipleOngoingEvents ? false : isOngoing;
  const disableUpdateIncident = allowMultipleOngoingEvents
    ? !isIncidentOngoing
    : !hasIncident;

  const handleClickIncidentButton = () => {
    if (hasMultipleIncidents || hasMultipleTabletops) {
      navigate(PAGES.incidents.pathname);
    } else if (hasIncident) {
      dispatch(Incidents.getItem(latestArticleId));
      navigate(`${PAGES.incidents.pathname}/${latestArticleId}`);
    } else if (hasTabletop) {
      dispatch(Incidents.getItem(unresolvedTabletops[0].id));
      navigate(`${PAGES.incidents.pathname}/${unresolvedTabletops[0].id}`);
    }
  };

  const handleClickFileStorage = (e) => {
    if (!verified) {
      e.preventDefault();
      dispatch(Storage.requestVerification());
    }
  };

  // Initial Dashboard load
  useEffect(() => {
    if (isConnected) {
      dispatch(Incidents.fetch());
      if (minutesSinceTimestamp(lastLogin) > 1) {
        dispatch(Dashboard.getDashboard());
      }
    }
  }, [isConnected, lastLogin, dispatch]);

  return dashboardLoaded ? (
    <Screen>
      <Container>
        <RowBadge>
          <ColumnBadge>
            <DashBadge
              icon={incidentBadge}
              color={incidentBadgeColor}
              status={incidentBadgeStatus}
              title="Current State"
              button={isOngoing}
              onClick={handleClickIncidentButton}
              titleVariant="h3"
              width="auto"
              height="180px"
              marginBottom={0}
              hasButton={hasButton}
            />
          </ColumnBadge>
          <ColumnBadge>
            <DashBadge
              icon={TimeBadge}
              color={theme.palette.purple.main}
              status={timeSinceIncident}
              title={hasIncident ? 'Time In Incident' : 'Time Incident-Free'}
              titleVariant="h3"
              width="auto"
              height="180px"
              marginBottom={0}
              hasButton={hasButton}
            />
          </ColumnBadge>
          <ColumnBadge>
            <DashBadge
              icon={UserBadge}
              color={theme.palette.secondary.main}
              status={`${currentUsers} of ${maxUsers}`}
              title="Number of Users"
              titleVariant="h3"
              width="auto"
              height="180px"
              marginBottom={0}
              hasButton={hasButton}
            />
          </ColumnBadge>
        </RowBadge>
        <Row>
          <Column>
            <DashPanel
              icon={<CreateIcon />}
              title="Create Incident"
              size="large"
              route={
                disableCreateIncident
                  ? undefined
                  : `${PAGES.incidents.pathname}/create`
              }
              disabled={disableCreateIncident}
            />
          </Column>
          <Column>
            <DashPanel
              icon={<UpdateIcon />}
              title={`Update Incident${incidentsCount > 1 ? '(s)' : ''}`}
              size="large"
              route={
                disableUpdateIncident
                  ? undefined
                  : hasMultipleIncidents
                    ? PAGES.incidents.pathname
                    : `${PAGES.incidents.pathname}/${latestArticleId}`
              }
              state={
                disableUpdateIncident ? undefined : { focusNewActivity: true }
              }
              disabled={disableUpdateIncident}
            />
          </Column>
          <Column>
            <DashPanel
              icon={<WarRoomsIcon />}
              title="War Rooms"
              route="/chat"
              size="large"
            />
          </Column>
          <Column>
            <DashPanel
              icon={<DocIcon />}
              title="Secure File Storage"
              route={PAGES.fileStorage.pathname}
              size="large"
              onClick={handleClickFileStorage}
            />
          </Column>
          <Access roles={[ROLES.user]}>
            <Column>
              <DashPanel
                icon={<MessageIcon />}
                title="Messaging"
                route="/chat"
                size="large"
              />
            </Column>
          </Access>
          <Access permission={FEATURES.userAdmin}>
            <Column>
              <DashPanel
                icon={<UserAdminIcon />}
                title="User Administration"
                route="/user-admin"
                size="large"
              />
            </Column>
          </Access>
          <Access dependentFeature={DEPENDENT_FEATURES.playbooks}>
            <Column>
              <DashPanel
                icon={<PlaybookIcon />}
                title="Playbooks"
                route="/playbooks"
                size="large"
              />
            </Column>
          </Access>
        </Row>
      </Container>
    </Screen>
  ) : (
    <LoadingDashboard />
  );
}
