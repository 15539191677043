import { NAVIGATION_TYPES } from './navigationReducer';

const Navigation = {
  navigate: (to, options = undefined) => ({
    type: NAVIGATION_TYPES.NAVIGATE,
    payload: {
      to,
      options,
    },
  }),
  clear: () => ({
    type: NAVIGATION_TYPES.CLEAR,
  }),
};

export default Navigation;
