import { SDK_TYPES } from './sdkReducer';

const SDK = {
  initialize: () => ({
    type: SDK_TYPES.INITIALIZE,
  }),
  setConnected: (status) => ({
    type: SDK_TYPES.SET_CONNECTED,
    payload: status,
  }),
};

export default SDK;
