import { createSelector } from 'reselect';

import { selectOrgCode } from '../org/orgSelectors';
import { DIAL_IN_COUNTRY_CODES } from '../../configs';
import { selectCurrentChat } from '../chat/chatSelectors';

export const selectJitsiState = (state) => state.jitsi;

export const selectJwt = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.jwt
);

export const selectCallStatus = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.status
);

export const selectFetchingJwt = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.fetchingJwt
);

export const selectOnCall = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.onJitsiCall
);

export const selectChatId = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.cid || ''
);

export const selectParticipants = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.jitsiParticipants
);

export const selectCallDetails = createSelector(
  selectJitsiState,
  (_, props) => props.cid,
  (state, cid) => state.callDetails[cid] || { participants: [] }
);

export const selectCallTitle = createSelector(
  selectJitsiState,
  (state) => state.callTitle
);

export const selectHasCall = createSelector(
  selectJitsiState,
  (_, props) => props?.chatId || -1,
  (jitsiState, chatId) => jitsiState.activeCalls.indexOf(chatId) !== -1
);

export const selectIncomingCall = createSelector(
  selectJitsiState,
  (jitsiState) => jitsiState.incomingCall
);

export const selectHasIncomingCall = createSelector(
  selectIncomingCall,
  (incomingCall) => Object.keys(incomingCall).length !== 0
);

export const selectDialInOptions = createSelector(
  selectOrgCode,
  selectCurrentChat,
  selectJitsiState,
  (orgCode, currentChat, jitsiState) => {
    const phoneNumbers = jitsiState.dialInOptions
      .filter(
        (o) =>
          DIAL_IN_COUNTRY_CODES.includes(o.countryCode) && o.tollFree === true
      )
      .map((o) => `${o.countryCode}: ${o.formattedNumber}`);

    return {
      phoneNumbers,
      viewMoreLink: `https://8x8.vc/vpaas-magic-cookie-fc5dd46e90314c97b472972d80152bd9/static/dialInInfo.html?room=${orgCode}-${currentChat.id}`,
    };
  }
);
