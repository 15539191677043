import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { FOLDER_TYPES } from '../../configs';
import { selectIsCurrentUserAdmin } from '../../store/auth/authSelectors';

const FolderTypeSelect = forwardRef(
  ({ value, onChange, disabled, ...props }, ref) => {
    const isCurrentUserAdmin = useSelector(selectIsCurrentUserAdmin);

    const userOptions = [
      {
        label: 'Public',
        value: FOLDER_TYPES.PUBLIC,
      },
      {
        label: 'Private',
        value: FOLDER_TYPES.PRIVATE,
      },
    ];
    const adminUserOptions = [
      {
        label: 'Admin Only',
        value: FOLDER_TYPES.ADMIN,
      },
    ];
    const options = isCurrentUserAdmin
      ? [...userOptions, ...adminUserOptions]
      : userOptions;

    return (
      <FormControl size="small" fullWidth {...props}>
        <InputLabel id="folder-type-select-label">Folder Type</InputLabel>
        <Select
          labelId="folder-type-select-label"
          id="folder-type-select"
          label="Folder Type"
          ref={ref}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

FolderTypeSelect.displayName = 'FolderTypeSelect';

export default FolderTypeSelect;
