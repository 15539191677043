import SvgIcon from '@mui/material/SvgIcon';

const Mail = (props) => (
  <SvgIcon {...props}>
    <path
      d="M.075 5.332A3 3 0 0 1 3 3h18a3 3 0 0 1 2.925 2.332L12 12.621.075 5.332ZM0 7.045v10.656l8.704-5.337L0 7.045Zm10.142 6.2-9.855 6.04A3 3 0 0 0 3 21h18a3 3 0 0 0 2.712-1.716l-9.855-6.04L12 14.378l-1.858-1.134Zm5.154-.879L24 17.701V7.046l-8.704 5.318v.002Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Mail;
