import {
  localStorageKeys,
  scheduleLocalStorageRemoval,
} from '../../utils/localStorageUtils';
import { ORG_TYPES } from '../org/orgReducer';
import { updateAxiosConfig } from '../../lib/axiosConfig';

const INIT_AUTH_STATE = {
  currentUser: null,
  changePassword: {
    passCode: null,
  },
  twoFactorData: null,
  valiating2FA: false,
  updatingUserProperties: null,
  lastLogin: null,
  error: null,
  qrCode: null,
  secret: null,
  loadingQrCode: false,
};

export const AUTH_TYPES = {
  LOGIN: '@@auth/LOGIN',
  VALIDATE_2FA: '@@auth/VALIDATE_2FA',
  RESEND_CODE: '@@auth/RESEND_CODE',
  CANCEL_LOGIN: '@@auth/CANCEL_LOGIN',
  CLEAR: '@@auth/CLEAR',
  LOGOUT: '@@auth/LOGOUT',
  SEND_RESET_EMAIL: '@@auth/SEND_RESET_EMAIL',
  CHANGE_PASSWORD: '@@auth/CHANGE_PASSWORD',
  SET_TOKEN: '@@auth/SET_TOKEN',
  REFRESH_AUTH_TOKEN: '@@auth/REFRESH_AUTH_TOKEN',
  UPDATE_CURRENT_USER: '@@auth/UPDATE_CURRENT_USER',
  UPDATE_CURRENT_USER_PROPERTIES: '@@auth/UPDATE_CURRENT_USER_PROPERTIES',
  SET_QR_CODE_IMAGE: '@@auth/SET_QR_CODE_IMAGE',
};

export default function auth(state = INIT_AUTH_STATE, action) {
  switch (action.type) {
    case `${AUTH_TYPES.LOGIN}_PENDING`:
    case AUTH_TYPES.CLEAR:
    case ORG_TYPES.RESET_ORG:
    case AUTH_TYPES.CANCEL_LOGIN:
      return INIT_AUTH_STATE;
    case `${AUTH_TYPES.LOGIN}_REJECTED`: {
      const { response } = action.payload;
      return {
        ...state,
        error:
          response?.data || 'An unexpected error occurred, please try again.',
      };
    }
    case `${AUTH_TYPES.LOGIN}_FULFILLED`: {
      const { data } = action.payload;

      if (!data.isSuccess) {
        return {
          ...state,
          twoFactorData: data,
        };
      }

      updateAxiosConfig(null, data.authenticationToken, null);

      return {
        ...state,
        currentUser: data,
        lastLogin: Date.now(),
        error: null,
      };
    }
    case `${AUTH_TYPES.VALIDATE_2FA}_PENDING`: {
      return {
        ...state,
        valiating2FA: true,
      };
    }
    case `${AUTH_TYPES.VALIDATE_2FA}_FULFILLED`: {
      const { data } = action.payload;

      updateAxiosConfig(null, data.authenticationToken, null);

      return {
        ...state,
        currentUser: { ...data },
        twoFactorData: null,
        valiating2FA: false,
        lastLogin: Date.now(),
        error: null,
      };
    }
    case `${AUTH_TYPES.VALIDATE_2FA}_REJECTED`: {
      const error = action.payload;

      let message;

      switch (error.response.status) {
        case 401:
          message =
            'The confirmation code you entered is invalid or has expired.';
          break;
        default:
          message = 'An error occurred while validating your identity.';
      }

      return {
        ...state,
        valiating2FA: false,
        error: message,
      };
    }
    case `${AUTH_TYPES.RESEND_CODE}_FULFILLED`: {
      const { newConfirmationCodeExpiryTimestamp } = action.payload.data;
      return {
        ...state,
        twoFactorData: {
          ...state.twoFactorData,
          confirmationCodeExpiryTimestamp: newConfirmationCodeExpiryTimestamp,
        },
        error: null,
      };
    }
    case `${AUTH_TYPES.CHANGE_PASSWORD}_PENDING`:
      return {
        ...state,
        changePassword: action.payload,
      };
    case `${AUTH_TYPES.CHANGE_PASSWORD}_FULFILLED`:
      return {
        ...state,
        changePassword: INIT_AUTH_STATE.changePassword,
      };
    case `${AUTH_TYPES.REFRESH_AUTH_TOKEN}_FULFILLED`: {
      let { lastLogin, currentUser } = state;

      // If action is resolved without a payload, meaning a refresh is already
      // taking place, or currentUser is null, we return state unmodified.
      if (!action.payload || Object.keys(currentUser || {}).length === 0) {
        return state;
      }

      const { refreshToken, expiresAt } = action.payload.data;

      currentUser = {
        ...currentUser,
        authenticationToken: refreshToken,
        authenticationTokenExpiry: expiresAt,
      };

      if (!lastLogin) {
        lastLogin = Date.now();
      }

      localStorage.setItem(
        localStorageKeys.CURRENT_USER,
        JSON.stringify({
          authenticationToken: refreshToken,
          authenticationTokenExpiry: expiresAt,
        })
      );

      updateAxiosConfig(null, refreshToken, null);

      // Set a timeout to delete updated current user data from localStorage
      scheduleLocalStorageRemoval(localStorageKeys.CURRENT_USER);

      return {
        ...state,
        currentUser,
        lastLogin,
        error: null,
      };
    }
    case AUTH_TYPES.SET_TOKEN: {
      const { authenticationToken, authenticationTokenExpiry } = action.payload;

      updateAxiosConfig(null, authenticationToken, null);

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          authenticationToken,
          authenticationTokenExpiry,
        },
      };
    }
    case AUTH_TYPES.UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case `${AUTH_TYPES.UPDATE_CURRENT_USER_PROPERTIES}_PENDING`:
      return {
        ...state,
        updatingUserProperties: true,
      };
    case `${AUTH_TYPES.UPDATE_CURRENT_USER_PROPERTIES}_REJECTED`:
      return {
        ...state,
        updatingUserProperties: false,
      };
    case `${AUTH_TYPES.UPDATE_CURRENT_USER_PROPERTIES}_FULFILLED`: {
      const { currentUser } = state;
      if (!currentUser) {
        return {
          ...state,
          updatingUserProperties: false,
        };
      }

      const { role, type } = action.payload.data.user.userProperties;

      const updatedCurrentUser = {
        ...currentUser,
        user: {
          ...currentUser.user,
          userProperties: {
            ...currentUser.user.userProperties,
            role,
            type,
          },
        },
      };

      return {
        ...state,
        updatingUserProperties: false,
        currentUser: updatedCurrentUser,
      };
    }
    case `${AUTH_TYPES.SET_QR_CODE_IMAGE}_PENDING`:
      return {
        ...state,
        loadingQrCode: false,
      };
    case `${AUTH_TYPES.SET_QR_CODE_IMAGE}_FULFILLED`: {
      const { secret, qrCode } = action.payload.data;
      const imageSrc = `data:image/png;base64,${qrCode}`;
      return {
        ...state,
        qrCode: imageSrc,
        secret,
        loadingQrCode: true,
      };
    }
    case `${AUTH_TYPES.SET_QR_CODE_IMAGE}_REJECTED`:
      return {
        ...state,
        loadingQrCode: true,
      };
    default:
      return state;
  }
}
