import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { PAGES } from '../../configs';
import {
  EVENT_TYPES,
  EVENT_TYPE_LABELS,
} from '../../store/incident/incidentTypes';
import {
  selectVerified,
  selectVerificationWaiting,
} from '../../store/storage/storageSelectors';
import Users from '../../store/user/userActions';
import useConfirmAsync from '../../lib/hooks/useConfirmAsync';
import useInterval from '../../lib/hooks/useInterval';
import { downloadFile } from '../../utils/networkUtils';
import Storage from '../../store/storage/storageActions';
import Incidents from '../../store/incident/incidentActions';
import { selectEnable2faOnSfs } from '../../store/org/orgSelectors';
import { selectCurrentItem } from '../../store/incident/incidentSelectors';

import { PulseLogoLoader } from '../loader';
import BackButton from './BackButton';
import PageContent from './PageContent';
import AttachmentsModal from './AttachmentsModal';
import DownloadModal from '../file-storage/DownloadModal';
import FileUploadModal from '../file-storage/FileUploadModal';
import Edit from '../../assets/icons/edit-outline.svg?react';
import PaperClip from '../../assets/icons/attach-outline.svg?react';
import Download from '../../assets/icons/cloud-download-outline.svg?react';

const Main = styled.main`
  height: 100%;
  overflow: auto;
  border-top: 1px solid #ddd;
`;

const Container = styled.div`
  width: 60%;
  max-width: 1200px;
  margin: 1.25rem 0 0 4.5rem;

  @media only screen and (min-width: 825px) {
    width: 64%;
  }
  @media only screen and (min-width: 1024px) {
    width: 55%;
    margin: 1.25rem auto 0;
  }
`;

const FloatingBackButton = styled(BackButton)`
  position: absolute;
  z-index: 1;
  padding: 1.25rem;
`;

const TopRightContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  padding: 1.25rem;

  @media only screen and (min-width: 1024px) {
    padding: 1.25rem 2rem;
  }
`;

const FloatingButtonContainer = styled(Stack)`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 1;
`;

const Page = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectCurrentItem);
  const enable2faOnSfs = useSelector(selectEnable2faOnSfs);
  const verified = useSelector(selectVerified);
  const waiting = useSelector(selectVerificationWaiting);
  const [confirmResolveElement, openConfirmResolve] = useConfirmAsync();
  const [modal, setModal] = useState('');
  const [resolving, setResolving] = useState(false);
  const [reportDownloadProgress, setReportDownloadProgress] = useState(0);
  const [intentToOpenAttachments, setIntentToOpenAttachments] = useState(false);

  const hasItem = !!data;
  const type = data?.type;
  const isResolved = !data?.isActive;
  const isIncident = type === EVENT_TYPES.INCIDENT;
  const attachmentsFolderId = data?.attachmentsFolderId;
  const hasCurrentUserCheckedIn = data?.hasCurrentUserCheckedIn;
  const hasIncompleteTasks = data?.hasIncompleteTasks;
  const hasPostIncidentAnalysis = !!data?.postmortemDetails;
  const buttonsDisabled = !hasItem || resolving;

  const ONGOING_REFRESH_INTERVAL = 1000 * 5;
  const RESOLVED_REFRESH_INTERVAL = 1000 * 60 * 5;

  // Initialization effect.
  useEffect(() => {
    dispatch(Users.fetchLastActiveTimestamps());
    if (!hasItem) {
      dispatch(Incidents.getItem(id)).catch(({ response }) => {
        if (response?.status !== 401) {
          navigate(PAGES.incidents.pathname, { replace: true });
        }
      });
    }
  }, [hasItem, id, dispatch, navigate]);

  // Attachments modal effect.
  useEffect(() => {
    if (enable2faOnSfs && verified && intentToOpenAttachments) {
      setModal('attachments');
      setIntentToOpenAttachments(false);
    }
  }, [enable2faOnSfs, verified, intentToOpenAttachments]);

  // Check-in effect.
  useEffect(() => {
    if (hasItem && !hasCurrentUserCheckedIn && !isResolved) {
      dispatch(Incidents.checkIn(id));
    }
  }, [hasItem, hasCurrentUserCheckedIn, id, isResolved, dispatch]);

  // Refresh interval.
  useInterval(
    () =>
      dispatch(Incidents.getItem(id)).catch(() =>
        navigate(PAGES.incidents.pathname, { replace: true })
      ),
    hasItem
      ? !isResolved
        ? ONGOING_REFRESH_INTERVAL
        : RESOLVED_REFRESH_INTERVAL
      : undefined
  );

  const handleResolve = () => {
    setResolving(true);
    return dispatch(Incidents.resolveItem(id))
      .then(() => navigate('/', { replace: true }))
      .finally(() => setResolving(false));
  };

  const handleClickResolve = () => {
    const eventTypeLabel =
      EVENT_TYPE_LABELS?.[type] || EVENT_TYPE_LABELS.INCIDENT;
    openConfirmResolve({
      title: `Resolve ${eventTypeLabel}?`,
      content: hasIncompleteTasks
        ? `Warning: There are remaining incomplete tasks. Once an ${eventTypeLabel.toLowerCase()} is resolved, tasks can no longer be updated.`
        : `This will mark the ${eventTypeLabel.toLowerCase()} as resolved in users' feeds.`,
      buttonText: 'Resolve',
      onConfirm: handleResolve,
    });
  };

  const handleOpenAttachments = () => {
    if (!verified || waiting) {
      setIntentToOpenAttachments(true);
      return dispatch(Storage.requestVerification());
    }
    setModal('attachments');
  };

  const handleOpenUpload = () => setModal('upload');

  const handleCloseUpload = () => setModal('attachments');

  const handleDownloadPdf = () => {
    setModal('download');

    dispatch(
      Incidents.getPdf(id, (progressEvent) => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setReportDownloadProgress(percentCompleted);
      })
    ).then((res) => {
      downloadFile({
        name: `${data?.title}.pdf`,
        objectUrl: res.value,
      });
      setModal('');
      setReportDownloadProgress(0);
    });
  };

  return (
    <>
      <Main>
        <FloatingBackButton component={Link} to="/incidents" />
        {!hasItem ? (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%' }}
          >
            <PulseLogoLoader />
            <Box mt={2}>Loading...</Box>
          </Stack>
        ) : (
          <>
            <TopRightContainer>
              {!isResolved && (
                <Stack gap={2}>
                  <Button
                    onClick={handleClickResolve}
                    color="success"
                    variant="contained"
                    disabled={buttonsDisabled}
                  >
                    Resolve {EVENT_TYPE_LABELS[type]}
                  </Button>
                  {isIncident && (
                    <Button
                      component={Link}
                      to="/chat"
                      color="primary"
                      variant="contained"
                      sx={{ '&:hover, &:link': { color: '#fff' } }}
                    >
                      Go to War Rooms
                    </Button>
                  )}
                </Stack>
              )}
              {isIncident && isResolved && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    navigate(`/incidents/${id}/post-incident-analysis`)
                  }
                  disabled={buttonsDisabled}
                >
                  {hasPostIncidentAnalysis ? 'Edit' : 'Add'} Post-Incident
                  Analysis
                </Button>
              )}
            </TopRightContainer>
            <Container>
              <PageContent {...data} />
            </Container>
            <FloatingButtonContainer spacing={1.5}>
              {isIncident && (
                <Tooltip
                  placement="left"
                  title="Attachments"
                  disableInteractive
                  arrow
                >
                  <Fab
                    color="primary"
                    onClick={handleOpenAttachments}
                    disabled={buttonsDisabled}
                  >
                    <SvgIcon
                      component={PaperClip}
                      style={{
                        width: '2rem',
                        height: '2rem',
                        fontSize: '2rem',
                      }}
                    />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip
                placement="left"
                title={`Download ${EVENT_TYPE_LABELS[type]} Report`}
                disableInteractive
                arrow
              >
                <Fab
                  color="primary"
                  onClick={handleDownloadPdf}
                  disabled={buttonsDisabled}
                >
                  <SvgIcon
                    component={Download}
                    style={{
                      width: '2rem',
                      height: '2rem',
                      fontSize: '2rem',
                    }}
                  />
                </Fab>
              </Tooltip>
              {!isResolved && (
                <Tooltip
                  placement="left"
                  title={`Edit This ${EVENT_TYPE_LABELS[type]}`}
                  disableInteractive
                  arrow
                >
                  <Fab
                    color="primary"
                    component={Link}
                    to={`/incidents/${id}/edit`}
                    onClick={(e) => buttonsDisabled && e.preventDefault()}
                    disabled={buttonsDisabled}
                    sx={{ '&:hover': { color: '#fff' } }}
                  >
                    <SvgIcon
                      component={Edit}
                      style={{
                        width: '2rem',
                        height: '2rem',
                        fontSize: '2rem',
                      }}
                    />
                  </Fab>
                </Tooltip>
              )}
            </FloatingButtonContainer>
          </>
        )}
      </Main>
      <AttachmentsModal
        open={modal === 'attachments'}
        onClose={() => setModal('')}
        folderId={attachmentsFolderId}
        openAdd={handleOpenUpload}
      />
      <FileUploadModal
        open={modal === 'upload'}
        onClose={handleCloseUpload}
        folderId={attachmentsFolderId}
      />
      <DownloadModal
        open={modal === 'download'}
        onClose={() => setModal('')}
        label={`Downloading ${EVENT_TYPE_LABELS[type]} Report...`}
        progress={reportDownloadProgress}
      />
      {confirmResolveElement}
    </>
  );
};

export default Page;
