import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import DataGridFilter from '../DataGridFilter';

const UserAdminToolbar = ({
  selection,
  onClickAdd,
  onClickEdit,
  onClickImport,
  onClickDeactivate,
  onClickReactivate,
  showInactiveUsers,
  onToggleShowInactiveUsers,
  sx,
}) => {
  const hasSelection = !!selection;
  const isSelectedUserActive = Boolean(selection?.active);
  const showEditUser = hasSelection && isSelectedUserActive;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={1}
      sx={{ px: 1.25, ...sx }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <DataGridFilter autoFocus placeholder="Search users…" />
        <FormControlLabel
          label="Show Inactive Users"
          control={
            <Switch
              size="small"
              name="showInactiveUsers"
              checked={showInactiveUsers}
              onChange={onToggleShowInactiveUsers}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ mr: 0.5 }}
            />
          }
          sx={{ m: 0 }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        {hasSelection && (
          <Button
            variant="outlined"
            color={isSelectedUserActive ? 'error' : 'secondary'}
            onClick={
              isSelectedUserActive ? onClickDeactivate : onClickReactivate
            }
          >
            {isSelectedUserActive ? 'Deactivate' : 'Reactivate'}
          </Button>
        )}
        {showEditUser && (
          <Button variant="outlined" onClick={onClickEdit}>
            Edit User
          </Button>
        )}
        <Button variant="contained" onClick={onClickAdd}>
          Add User
        </Button>
        <Button variant="contained" onClick={onClickImport}>
          Import Users
        </Button>
      </Stack>
    </Stack>
  );
};

export default UserAdminToolbar;
