import { useCallback } from 'react';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PAGES } from '../../configs';
import {
  selectBreadcrumbLinks,
  selectCurrentFolderIsRoot,
} from '../../store/storage/storageSelectors';
import Storage from '../../store/storage/storageActions';

import FolderIcon from '../../assets/icons/filetype-folder.svg?react';

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  flex-shrink: 0;
  padding: 0.5rem 2rem;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: rgba(4, 47, 66, 0.08);

  & .MuiBreadcrumbs-ol {
    line-height: 1;
  }

  & a,
  & span {
    color: rgba(0, 0, 0, 0.87);
  }
  & a {
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  & a:first-of-type,
  & span:first-of-type {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
`;

const Breadcrumbs = ({ searchTerm = '', previewOpen }) => {
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(selectBreadcrumbLinks);
  const isRootFolder = useSelector(selectCurrentFolderIsRoot);

  const maxItems = previewOpen ? 5 : 8;
  const allLabel = 'All';

  const handleClick = useCallback(
    (b) => {
      dispatch(Storage.updateBreadcrumbs(b));
    },
    [dispatch]
  );

  return !!searchTerm ? (
    <StyledBreadcrumbs>
      <span style={{ display: 'inline' }}>
        Search results for <b>{searchTerm}</b>:
      </span>
    </StyledBreadcrumbs>
  ) : (
    <StyledBreadcrumbs maxItems={maxItems} aria-label="breadcrumb">
      {isRootFolder ? (
        <span>
          <SvgIcon fontSize="smaller">
            <FolderIcon />
          </SvgIcon>{' '}
          {allLabel}
        </span>
      ) : (
        <Link
          component={RouterLink}
          underline="hover"
          title={allLabel}
          to={PAGES.fileStorage.pathname}
        >
          <SvgIcon fontSize="smaller">
            <FolderIcon />
          </SvgIcon>{' '}
          {allLabel}
        </Link>
      )}
      {breadcrumbs.map((b, index, array) => {
        return index === array.length - 1 ? (
          <span key={b.title} title={b.title}>
            {b.title}
          </span>
        ) : (
          <Link
            key={b.title}
            component={RouterLink}
            underline="hover"
            title={b.title}
            to={b.to}
            onClick={() => handleClick(b)}
          >
            {b.title}
          </Link>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
