import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

import { formatPhoneNumber } from '../utils/formUtils';

const PhoneInput = forwardRef(
  (
    {
      value,
      onChange,
      inputProps = {},
      InputProps = {},
      variant = 'standard',
      ...props
    },
    ref
  ) => {
    const maskedValue = formatPhoneNumber(value);

    return (
      <TextField
        {...props}
        ref={ref}
        type="text"
        inputProps={{
          ...inputProps,
          maxLength: 14,
        }}
        autoComplete="off"
        InputProps={{
          ...InputProps,
          autoComplete: 'off',
        }}
        variant={variant}
        value={maskedValue}
        placeholder="(XXX) XXX-XXXX"
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
