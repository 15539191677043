import { AUTH_TYPES } from '../auth/authReducer';

export const GROUP_TYPES = {
  FETCH_ALL: '@@group/FETCH_ALL',
  FETCH_MEMBER_OF: '@@group/FETCH_MEMBER_OF',
  FETCH_ACCESSIBLE: '@@group/FETCH_ACCESSIBLE',
};

const INITIAL_STATE = {
  all: [],
  memberOfList: [],
  accessibleList: [],
};

export default function groups(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `${GROUP_TYPES.FETCH_ALL}_FULFILLED`:
      return {
        ...state,
        all: action.payload.data,
      };
    case `${GROUP_TYPES.FETCH_MEMBER_OF}_FULFILLED`:
      return {
        ...state,
        memberOfList: action.payload.data,
      };
    case `${GROUP_TYPES.FETCH_ACCESSIBLE}_FULFILLED`:
      return {
        ...state,
        accessibleList: action.payload.data,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STATE;
    default:
      return state;
  }
}
