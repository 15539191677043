import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';

import BackIcon from '../../assets/icons/arrow-back.svg?react';

const BackButton = ({ sx = {}, ...props }) => (
  <IconButton
    color="secondary"
    size="large"
    sx={{ '& .MuiSvgIcon-root': { fontSize: '2rem' }, ...sx }}
    {...props}
  >
    <SvgIcon component={BackIcon} htmlColor="black" />
  </IconButton>
);

export default BackButton;
