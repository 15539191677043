import { createTheme } from '@mui/material/styles';
import { fontFamily } from './typography';

export default createTheme({
  palette: {
    primary: {
      main: '#1a4f6c',
      dark: '#143b51',
      contrastText: '#fff',
    },
    darkTeal: {
      main: '#1a4f6c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#47a6b6',
      dark: '#3e8793',
      contrastText: '#fdfdfd',
    },
    teal: {
      main: '#47a6b6',
      contrastText: '#fdfdfd',
    },
    navyBlue: {
      main: '#141f3d',
      contrastText: '#fff',
    },
    purple: {
      main: '#65489c',
      contrastText: '#fdfdfd',
    },
    orange: {
      main: '#ff6f07',
      contrastText: '#fff',
    },
    red: {
      main: '#ff403f',
      contrastText: '#fdfdfd',
    },
    success: {
      main: '#3cb694',
      light: '#62c4a9',
      dark: '#36a385',
      contrastText: '#fff',
    },
    error: {
      main: '#f83245',
      light: '#f95a6a',
      dark: '#df2d3e',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffc107',
      dark: '#eeb50b',
      contrastText: '#1a1a1a',
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.26)',
      contrastText: '#1a1a1a',
    },
    grey: {
      main: '#efefef',
      contrastText: '#1a1a1a',
    },
    white: {
      main: '#fdfdfd',
      contrastText: '#1a1a1a',
    },
    black: {
      main: '#1a1a1a',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '48px',
      lineHeight: 'normal',
    },
    h2: {
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '47px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: 'normal',
    },
    h4: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '30px',
    },
    h5: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '36px',
    },
    button: {
      fontSize: '14px',
      lineHeight: 'normal',
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    fontFamily,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 14px',
          borderRadius: '5px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#1a1a1a',
          fontSize: '12px',
          padding: '8px',
        },
        arrow: {
          color: '#1a1a1a',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          width: '25px',
          height: '25px',
        },
      },
    },
  },
});
