import { styled } from '@mui/material/styles';

const InputWrapper = styled.div`
  position: relative;
  padding: 1.5em;
  border-top: 1px solid #ddd;
`;

export default function NoInput() {
  return (
    <InputWrapper>
      <p>
        You are viewing an archive of this chat. You cannot send messages in
        archived chats.
      </p>
    </InputWrapper>
  );
}
