import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import useUserRole from '../hooks/useUserRole';
import { determineAccess } from '../utils/configUtils';
import { selectEnabledFeatures } from '../store/org/orgSelectors';

import LoadingScreen from './LoadingScreen';

/**
 * This component will render children is the user can access the defined resource.
 */
const Access = ({
  roles,
  permission,
  dependentFeature,
  isPage = false,
  loaderProps = {},
  redirectPath = '/',
  debug = '',
  children,
}) => {
  const { role, isLoading } = useUserRole();
  const enabledFeatures = useSelector(selectEnabledFeatures);

  const isAllowed = determineAccess(role, enabledFeatures, {
    roles,
    permission,
    dependentFeature,
  });

  if (!!debug) {
    console.log({
      title: debug,
      role,
      isLoading,
      isAllowed,
    });
  }

  if (isLoading) {
    return isPage ? (
      <LoadingScreen {...loaderProps} />
    ) : (
      <CircularProgress {...loaderProps} />
    );
  }

  if (!isAllowed) {
    return isPage ? <Navigate to={redirectPath} replace /> : null;
  }

  return children;
};

export default Access;
