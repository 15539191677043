export const getServiceRegistryUrl = (orgCode) =>
  `https://service-registry.shadowhq.io/${orgCode}.json`;

export const downloadFile = ({ name, data, options, objectUrl }) => {
  if (!name) {
    throw new Error('A name must be provided to downloadFile.');
  }

  if (!objectUrl && (!data || !options)) {
    throw new Error(
      'To use downloadFile, you must provide either data and options to create a Blob, or an objectUrl for a Blob that already exists.'
    );
  }

  // Setup the link containing the file object.
  const a = document.createElement('a');
  const href = !!objectUrl
    ? objectUrl
    : URL.createObjectURL(new Blob([data], options));
  a.href = href;
  a.download = name;
  a.style = 'display:none;';
  document.body.appendChild(a);

  // Download the file.
  a.click();

  // Clean up.
  document.body.removeChild(a);
  URL.revokeObjectURL(objectUrl);
};
