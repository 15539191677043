import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import {
  MAX_WAR_ROOMS,
  SUGGESTED_WAR_ROOMS,
} from '../../../configs/welcomePackage';
import {
  selectWarRooms,
  selectMainAdminUser,
} from '../../../store/welcome/welcomeSelectors';
import WelcomePackage from '../../../store/welcome/welcomeActions';

import Chip from './Chip';
import EditWarRoomModal from '../modals/EditWarRoomModal';

const WarRoomsStep = () => {
  const dispatch = useDispatch();
  const warRooms = useSelector(selectWarRooms);
  const mainAdminUser = useSelector(selectMainAdminUser);
  const [warRoomName, setWarRoomName] = useState('');
  const [warRoomInFocus, setWarRoomInFocus] = useState(null);
  const [examples, setExamples] = useState(
    SUGGESTED_WAR_ROOMS.filter((e) => !warRooms.find((w) => w.label === e))
  );

  const handleChangeName = (e) => setWarRoomName(e.target.value);

  const handleClearWarRoom = () => setWarRoomInFocus(null);

  const handleEditWarRoom = (item) => () => setWarRoomInFocus(item);

  const handleAddAsCustom = (e) => {
    e.preventDefault();
    let warRoomToEdit = null;
    const warRoom = warRoomName.trim();
    if (!warRooms.find((w) => w.label === warRoom)) {
      const { payload } = dispatch(
        WelcomePackage.addWarRoom({
          key: warRooms.length,
          label: warRoom,
          members: [],
          admin: mainAdminUser,
          isCustom: true,
        })
      );
      warRoomToEdit = payload;
    }
    setWarRoomName('');
    setExamples((examples) => examples.filter((e) => e !== warRoom));
    handleEditWarRoom(warRoomToEdit)();
  };

  const handleAddFromExample = (item) => () => {
    let warRoomToEdit = null;
    if (!warRooms.find((w) => w.label === item)) {
      const { payload } = dispatch(
        WelcomePackage.addWarRoom({
          key: warRooms.length,
          label: item,
          members: [],
          admin: mainAdminUser,
        })
      );
      warRoomToEdit = payload;
    }
    setExamples((examples) => examples.filter((e) => e !== item));
    handleEditWarRoom(warRoomToEdit)();
  };

  const handleDelete = (item) => () => {
    dispatch(WelcomePackage.removeWarRoom(item));
    if (!item.isCustom) {
      setExamples((items) => [...items, item.label].sort());
    }
  };

  return (
    <>
      <Typography paragraph>
        War Rooms are your virtual meeting rooms which may reflect the structure
        of your organization or response team. We recommend creating between 5 –
        10 War Rooms, up to a maximum of {MAX_WAR_ROOMS}.
      </Typography>
      <Typography paragraph>You can click on each to add members.</Typography>
      <Stack gap={2} sx={{ mt: 2 }}>
        <Box>
          <b>Quick Add: </b>
          {examples.map((value, index) => (
            <Chip
              key={index}
              label={value}
              variant="outlined"
              onClick={handleAddFromExample(value)}
              disabled={warRooms.length >= MAX_WAR_ROOMS}
            />
          ))}
        </Box>
        <Stack
          component="form"
          direction="row"
          alignItems="center"
          onSubmit={handleAddAsCustom}
          gap={1}
        >
          <TextField
            autoFocus
            size="small"
            autoComplete="off"
            value={warRoomName}
            label="New War Room"
            onChange={handleChangeName}
            placeholder="Press Enter to add..."
            disabled={warRooms.length >= MAX_WAR_ROOMS}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={handleAddAsCustom}
            disabled={!warRoomName || warRooms.length >= MAX_WAR_ROOMS}
            sx={{ alignSelf: 'center' }}
          >
            Add
          </Button>
        </Stack>
        <Box>
          <b>Include: </b>
          <Tooltip
            disableInteractive
            title="&ldquo;Headquarters&rdquo; is the main War Room accessed by
          every active user in your organization."
          >
            <Chip color="primary" label="Headquarters" />
          </Tooltip>
          {warRooms.map((warRoom, index) => {
            const count = !!warRoom.admin
              ? warRoom.members.length + 1
              : warRoom.members.length;
            const invalid = count === 0 || !warRoom.label;

            return (
              <Chip
                key={index}
                invalid={invalid.toString()}
                avatar={<Avatar>{count}</Avatar>}
                label={warRoom.label || 'Untitled'}
                color={invalid ? 'error' : 'primary'}
                onDelete={handleDelete(warRoom)}
                onClick={handleEditWarRoom(warRoom)}
              />
            );
          })}
        </Box>
      </Stack>
      <EditWarRoomModal
        open={Boolean(warRoomInFocus)}
        onClose={handleClearWarRoom}
        warRoom={warRoomInFocus}
      />
    </>
  );
};

export default WarRoomsStep;
