import groupBy from 'lodash.groupby';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  isListTypeConfig,
  getDocumentTitleByPathName,
} from '../../../configs/management';
import { managementApi } from '../../../configs/managementApi';

import ConfigGroup from './ConfigGroup';
import { PulseLogoLoader } from '../../loader';

const GlobalConfigs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(false);

  const groupedConfigs = groupBy(configs, 'groupTag');
  const groups = Object.keys(groupedConfigs).sort();

  const fetchConfigs = useCallback(() => {
    setLoading(true);
    managementApi
      .get('/config/globalOverriddenConfigDetails')
      .then(({ data }) => setConfigs(data))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, []);

  const handleSaveConfig = (key, type, value) => {
    const shouldEscape = isListTypeConfig(type);
    return new Promise((resolve, reject) => {
      managementApi
        .put('/config/globalOverriddenConfigDetails', {
          databaseKey: key,
          valueJson: shouldEscape ? JSON.stringify(value) : value,
        })
        .then(({ data: updatedConfig }) => {
          setConfigs((prev) =>
            prev.map((c) => (c.databaseKey === key ? updatedConfig : c))
          );
          resolve(updatedConfig);
        })
        .catch((error) => reject(error));
    });
  };

  // Initialization effect.
  useEffect(() => {
    document.title = getDocumentTitleByPathName(location.pathname);

    fetchConfigs();
  }, [location.pathname, fetchConfigs]);

  if (loading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <PulseLogoLoader />
      </Stack>
    );
  }

  return (
    <Stack gap={3} sx={{ mb: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography component="h1" variant="h4">
          Global Configurations
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/management/organizations')}
        >
          Manage Enterprise Configurations
        </Button>
      </Stack>
      {!!groups?.length &&
        groups.map((group) => (
          <ConfigGroup
            key={group}
            name={group}
            configs={groupedConfigs[group]}
            onSave={handleSaveConfig}
          />
        ))}
    </Stack>
  );
};

export default GlobalConfigs;
