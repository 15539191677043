import Box from '@mui/material/Box';
import MuiBadge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

export const ContentMain = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  width: 80%;
`;

export const ContentTitleBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5em;
  border-bottom: 1px solid #ddd;
  min-height: 61px;
`;

export const ChatTitle = styled.div`
  display: flex;
  flex-direction: column;

  > small {
    color: #777;
  }
`;

export const ChatActions = styled.div`
  display: flex;
  justify-content: space-evenly;

  button {
    border-left: 1px solid #ddd;
    border-radius: 0;
  }
`;

export const ChatItem = styled(ListItem)`
  && {
    margin-right: 1px;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 0px;
    border-left: ${({ active, theme }) =>
      active === 'true'
        ? `4px solid ${theme.palette.primary.main}`
        : '4px solid transparent'};
    background-color: ${({ active, theme }) =>
      active === 'true' && theme.palette.action.selected};

    &&:hover {
      background-color: ${({ theme, active }) =>
        active ? theme.palette.action.selected : theme.palette.action.hover};
    }

    .cancel-icon {
      font-size: 1em;
      display: none;
    }

    &:hover .cancel-icon {
      display: block;
    }
  }
`;

export const Badge = styled(MuiBadge)`
  & .MuiBadge-anchorOriginTopRight {
    top: 2px;
    right: 2px;
  }
  & .MuiBadge-anchorOriginBottomRight {
    bottom: 2px;
    right: 2px;
  }
  & .MuiBadge-colorGrey {
    background-color: #ccc;
    padding: 0;
  }
  & .MuiSvgIcon-root {
    font-size: 14px;
  }
`;
