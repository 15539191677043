import {
  localStorageKeys,
  scheduleLocalStorageRemoval,
} from '../../utils/localStorageUtils';
import { AUTH_TYPES } from '../auth/authReducer';

const INIT_DASH_STATE = {
  lastIncidentTimestamp: '',
  activeIncident: false,
  incidentsCount: 0,
  maxUsers: 0,
  currentUsers: 0,
  subscriptionLevel: '',
  fetchingDashboard: false,
  loadedDashboard: false,
  error: [],
};

export const DASH_TYPES = {
  FETCH_DASHBOARD: '@@dashboard/FETCH_DASHBOARD',
  SET_DASHBOARD: '@@dashboard/SET_DASHBOARD',
  RESET_DASHBOARD: '@@dashboard/RESET_DASHBOARD',
};

export default function dashboard(state = INIT_DASH_STATE, action) {
  switch (action.type) {
    case `${DASH_TYPES.FETCH_DASHBOARD}_PENDING`:
      return {
        ...state,
        fetchingDashboard: true,
      };
    case `${DASH_TYPES.FETCH_DASHBOARD}_FULFILLED`: {
      const { data } = action.payload;

      // Store the dashboard data in localStorage.
      localStorage.setItem(localStorageKeys.DASHBOARD, JSON.stringify(data));

      // Set a timeout to delete dashboard data from localStorage
      scheduleLocalStorageRemoval(localStorageKeys.DASHBOARD);

      return {
        ...state,
        ...data,
        fetchingDashboard: false,
        loadedDashboard: true,
        error: [],
      };
    }
    case `${DASH_TYPES.FETCH_DASHBOARD}_REJECTED`:
      return {
        ...state,
        fetchingDashboard: false,
      };
    case DASH_TYPES.SET_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_TYPES.CLEAR:
      return INIT_DASH_STATE;
    default:
      return state;
  }
}
