import React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { getCachedProfileImageUrl } from '../../../utils/imageUtils';
import { ACTIVITY_TYPES } from '../../../store/incident/incidentTypes';

import Layout from './Layout';
import Avatar from '../../Avatar';
import ElapsedTime from '../ElapsedTime';

const InfoItem = styled(Stack)`
  span {
    line-height: 1;
    font-weight: 500;
    font-size: 0.6875rem;
    color: rgba(0, 0, 0, 0.6);
  }
  address,
  time {
    line-height: 1;
  }
  time {
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Update = React.forwardRef(
  ({ createdBy, createTimestamp, isResolved, ...props }, ref) => {
    return (
      <Layout
        {...props}
        ref={ref}
        type={ACTIVITY_TYPES.UPDATE}
        borderColor="secondary"
        bottomLeftContent={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              profileImageUrl={getCachedProfileImageUrl(createdBy)}
              firstName={createdBy.firstName}
              lastName={createdBy.lastName}
              fontSize="1rem"
              sx={{ width: '2rem', height: '2rem' }}
            />
            <InfoItem spacing={0.75}>
              <Typography component="address">
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </InfoItem>
            <InfoItem spacing={0.75}>
              <ElapsedTime
                timestamp={createTimestamp}
                showTimestamp={isResolved}
              />
            </InfoItem>
          </Stack>
        }
      />
    );
  }
);

Update.displayName = 'Update';

export default Update;
