import axios from 'axios';

import { store } from '../store';
import { logBehavior } from './log';
import Authentication from '../store/auth/authActions';
import Storage from '../store/storage/storageActions';
import { selectVerificationWaiting } from '../store/storage/storageSelectors';

export const axiosInstance = axios.create({
  headers: {
    common: {
      'Content-Type': 'application/json',
      'X-App-Version': window._env_.VITE_APP_VERSION,
    },
  },
});

// Store interceptor IDs
const axiosInterceptors = {
  request: null,
  response: null,
};

let refreshSubscriberQueue = [];

const addRefreshSubscriberToQueue = (callback) => {
  refreshSubscriberQueue.push(callback);
};

const processTokenRefreshSubscriberQueue = (authToken) => {
  if (!!refreshSubscriberQueue.length) {
    console.log(`Processing ${refreshSubscriberQueue.length} requests...`);
  }
  refreshSubscriberQueue.forEach((callback) => callback(authToken));
  refreshSubscriberQueue = [];
};

export const setupAxiosInterceptors = () => {
  // console.log('Setup Axios interceptors.');

  const IGNORED_ENDPOINTS = ['/authentication/logout'];

  const CUSTOM_THROTTLED_ENDPOINTS = [
    '/sfs/2fa',
    '/authentication/forgotPassword',
    '/authentication/resend',
    '/feature/upgrade',
  ];

  axiosInterceptors.response = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { config, response } = error;
      const status = response?.status;
      const originalRequest = config;

      // If the request produces no response or response status, try again in a second,
      // otherwise return a rejected Promise.
      if ((!response || !status) && !originalRequest?._networkRetry) {
        originalRequest._networkRetry = true;
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(axiosInstance(originalRequest));
          }, 1000);
        });
      } else if (!status) {
        return Promise.reject(error);
      }

      // If the error comes from an ignored endpoint, return a rejected Promise.
      if (IGNORED_ENDPOINTS.some((e) => originalRequest.url.includes(e))) {
        return Promise.reject(error);
      }

      // If the response status is 500, then throw the error, as we want to surface it.
      if (status === 500) {
        console.error(error.response);
        throw error;
      }

      // If the response status is 400, 403, or 404, we simply return a rejected Promise.
      if ([400, 403, 404].includes(status)) {
        return Promise.reject(error);
      }

      // If the response status is 429, this is an error resulting from API throttling.
      if (status === 429) {
        // If the source is one of the specifically-throttled endpoints, return a rejected
        // Promise and forward the error for component-specific handling.
        if (
          CUSTOM_THROTTLED_ENDPOINTS.some((e) =>
            originalRequest.url.includes(e)
          )
        ) {
          return Promise.reject(error);
        }

        logBehavior(
          '@@SHQ/ApiInstance - Too many requests at once, so logging out.',
          'error'
        );
        store.dispatch(Authentication.logout());
        return Promise.reject(error);
      }

      if (status === 401 && !originalRequest._retry) {
        const isRefreshing = store.getState().token.isRefreshing;

        if (isRefreshing) {
          // If already refreshing, push request to queue.
          return new Promise((resolve) => {
            addRefreshSubscriberToQueue((token) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(axiosInstance(originalRequest));
            });
          });
        }

        originalRequest._retry = true;

        return store
          .dispatch(Authentication.refreshToken({ source: 'Interceptor' }))
          .then(({ value: response }) => {
            const authenticationToken = response?.data?.refreshToken;

            processTokenRefreshSubscriberQueue(authenticationToken); // Process queue of requests.

            // If waiting for the verification timeout to resolve, re-request after
            // refresh to properly update any countdown values.
            const verificationWaiting = selectVerificationWaiting(
              store.getState()
            );
            if (verificationWaiting) {
              store.dispatch(Storage.requestVerification());
            }

            // Retry the original request with new token.
            originalRequest.headers['Authorization'] =
              `Bearer ${authenticationToken}`;
            return axiosInstance(originalRequest);
          })
          .catch((refreshError) => {
            console.error('@@SHQ/ApiInstance - Refresh error:', refreshError);
            return Promise.reject(refreshError);
          });
      }

      return Promise.reject(error);
    }
  );
};

export const ejectAxiosInterceptors = () => {
  if (axiosInterceptors.response !== null) {
    // console.log('Eject Axios interceptors.');
    axiosInstance.interceptors.response.eject(axiosInterceptors.response);
    axiosInterceptors.response = null;
  }
};
