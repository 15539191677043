import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import AvatarGroup from '@mui/material/AvatarGroup';
import { styled, useTheme } from '@mui/material/styles';

import {
  selectOnCall,
  selectChatId,
  selectParticipants,
  selectCallDetails,
  selectCallStatus,
} from '../../../store/jitsi/jitsiSelectors';
import { nameCompare } from '../../../utils/sortUtils';
import JitsiCalls from '../../../store/jitsi/jitsiActions';
import { selectLastCallMessage } from '../../../store/chat/messageSelectors';

import DialInOptions from '../../calling/DialInOptions';
import CallSvg from '../../../assets/icons/phone-call-outline.svg?react';

const Call = styled.div`
  border: 1px solid #555;
  border-radius: 5px;
  margin: 0.5em 0;
  width: 32.5em;
  max-width: 600px;
`;

const CallText = styled.div`
  margin: 15px 10px 5px;
  font-size: 18px;
  color: #444;
  display: flex;
`;

const Icon = styled.span`
  border: 1px solid #333;
  border-radius: 5px;
  padding: 7px;
  padding-bottom: 9px !important;
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  fill: white;
  height: 40px;
  width: 40px;
  margin-right: 15px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const CallStatus = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #555;
  padding: 10px 5px;
  background: #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 57px;
  flex-direction: column;

  p {
    font-size: 14px;
  }
`;

const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Grouped = styled(AvatarGroup)`
  .MuiAvatar-colorDefault {
    background: #aaa;
  }

  .MuiAvatarGroup-avatar {
    background: #aaa;
  }
`;

const callStateText = {
  STARTING: 'Call starting...',
  CONNECTED: 'Call started',
  DISCONNECTED: 'Call ended',
};

const CallStatusContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function CallContent({ id, cid, created_at: created }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const lastCallMessage = useSelector(selectLastCallMessage);
  const onCall = useSelector(selectOnCall);
  const chatId = useSelector(selectChatId);
  const status = useSelector(selectCallStatus);
  const callDetails = useSelector((state) => selectCallDetails(state, { cid }));
  const jitsiParticipants = useSelector(selectParticipants);

  const { isActive, participants, meetingId } = callDetails;

  const showButton =
    lastCallMessage.id === id &&
    ((isActive && !onCall) || (onCall && cid === chatId));
  const createDate = new Date(created);

  let chatParticipants = [];
  let stateMsg = callStateText.DISCONNECTED;

  if (lastCallMessage.id === id) {
    if (onCall && cid === chatId) {
      stateMsg = callStateText[status];
      chatParticipants = jitsiParticipants.map((p) => ({
        avatar: p.avatarURL,
        name: p.displayName,
        id: p.participantId,
      }));
    }

    if (isActive && cid !== chatId) {
      const activeParticipants = participants || [];

      stateMsg = callStateText.CONNECTED;
      chatParticipants = activeParticipants.reduce(
        (acc, p) => (acc.find((i) => i.id === p.id) ? acc : [...acc, p]),
        []
      );
    }

    chatParticipants.sort(nameCompare);
  }

  const joinCall = useCallback(() => {
    dispatch(JitsiCalls.getJWT(cid)).then(() =>
      dispatch(JitsiCalls.joinCall(true))
    );
  }, []);

  return (
    <Call>
      <CallText>
        <Icon theme={theme}>
          <CallSvg />
        </Icon>
        <MessageText>
          <span>{stateMsg}</span>
          <small>{`Started @ ${createDate.toLocaleTimeString()}`}</small>
        </MessageText>
      </CallText>
      {/* show call partcipants dynamically eventually */}
      <CallStatus>
        <Box>
          <CallStatusContainer>
            <Grouped max={4}>
              {chatParticipants.map((p) => (
                <Avatar key={p.id} src={p.avatar}>
                  {!p.avatar && p.name.substring(0, 1)}
                </Avatar>
              ))}
            </Grouped>
            {showButton &&
              (onCall ? (
                <Button
                  variant="contained"
                  onClick={() => dispatch(JitsiCalls.toggleCallWindow())}
                >
                  Return
                </Button>
              ) : (
                <Button variant="contained" onClick={joinCall}>
                  Join
                </Button>
              ))}
          </CallStatusContainer>
        </Box>
        <Stack direction="row" justifyContent="space-around">
          {showButton && <DialInOptions meetingId={meetingId} color="grey" />}
        </Stack>
      </CallStatus>
    </Call>
  );
}
