import { AUTH_TYPES } from '../auth/authReducer';

export const STORAGE_TYPES = {
  FETCH_TREE: '@@storage/FETCH_TREE',
  FETCH_FOLDER: '@@storage/FETCH_FOLDER',
  CREATE_FOLDER: '@@storage/CREATE_FOLDER',
  UPLOAD_FILE: '@@storage/UPLOAD_FILE',
  DOWNLOAD_FILE: '@@storage/DOWNLOAD_FILE',
  EDIT_ITEM: '@@storage/EDIT_ITEM',
  RENAME_ITEM: '@@storage/RENAME_ITEM',
  DELETE_ITEM: '@@storage/DELETE_ITEM',
  ADD_BREADCRUMB: '@@storage/ADD_BREADCRUMB',
  UPDATE_BREADCRUMBS: '@@storage/UPDATE_BREADCRUMBS',
  SET_FILTER: '@@storage/SET_FILTER',
  SEARCH: '@@storage/SEARCH',
  REQUEST_VERIFICATION: '@@storage/REQUEST_VERIFICATION',
  SEND_VERIFICATION: '@@storage/SEND_VERIFICATION',
  CLEAR_VERIFICATION: '@@storage/CLEAR_VERIFICATION',
};

export const VERIFICATION_STATUSES = {
  REQUESTED: 'REQUESTED',
  WAITING: 'WAITING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const STORAGE_FILTERS = {
  ALL: 'ALL',
  CREATED_BY_ME: 'CREATED_BY_ME',
};

const INITIAL_STORAGE_STATE = {
  fetching: false,
  error: null,
  tree: [],
  breadcrumbs: [],
  filter: STORAGE_FILTERS.ALL,
  verification: {
    loading: false,
    status: '',
    expiry: 0,
    msUntilNextAttempt: 0,
  },
};

export default function storage(state = INITIAL_STORAGE_STATE, action) {
  switch (action.type) {
    case `${STORAGE_TYPES.FETCH_TREE}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${STORAGE_TYPES.FETCH_TREE}_REJECTED`:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case `${STORAGE_TYPES.FETCH_TREE}_FULFILLED`: {
      const { tree } = action.payload;
      return {
        ...state,
        fetching: false,
        tree: tree || [],
      };
    }
    case `${STORAGE_TYPES.CREATE_FOLDER}_FULFILLED`: {
      const newFolder = action.payload;
      return {
        ...state,
        tree: [...state.tree, newFolder],
      };
    }
    case `${STORAGE_TYPES.UPLOAD_FILE}_FULFILLED`: {
      const newFile = action.payload;
      return {
        ...state,
        tree: [...state.tree, newFile],
      };
    }
    case `${STORAGE_TYPES.EDIT_ITEM}_FULFILLED`:
    case `${STORAGE_TYPES.RENAME_ITEM}_FULFILLED`: {
      const editedItem = action.payload;
      return {
        ...state,
        tree: state.tree.map((item) => {
          if (item.id === editedItem.id) {
            return editedItem;
          }
          return item;
        }),
      };
    }
    case `${STORAGE_TYPES.DELETE_ITEM}_FULFILLED`: {
      const itemId = action.payload;
      return {
        ...state,
        tree: state.tree.filter((item) => item.id !== itemId),
      };
    }
    case STORAGE_TYPES.ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs, action.payload],
      };
    case STORAGE_TYPES.UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case STORAGE_TYPES.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case `${STORAGE_TYPES.SEARCH}_PENDING`:
      return {
        ...state,
        fetching: true,
      };
    case `${STORAGE_TYPES.SEARCH}_REJECTED`:
      return {
        ...state,
        fetching: false,
      };
    case `${STORAGE_TYPES.SEARCH}_FULFILLED`: {
      const { data: tree } = action.payload;
      return {
        ...state,
        fetching: false,
        tree: tree || [],
        breadcrumbs: [],
      };
    }
    case `${STORAGE_TYPES.REQUEST_VERIFICATION}_PENDING`:
      return {
        ...state,
        verification: {
          ...INITIAL_STORAGE_STATE.verification,
          loading: true,
        },
      };
    case `${STORAGE_TYPES.REQUEST_VERIFICATION}_REJECTED`: {
      if (action.payload?.response?.status === 429) {
        const { msUntilNextAttempt } = action.payload.response.data;
        return {
          ...state,
          verification: {
            ...state.verification,
            loading: false,
            status: VERIFICATION_STATUSES.WAITING,
            msUntilNextAttempt,
          },
        };
      }

      return {
        ...state,
        verification: INITIAL_STORAGE_STATE.verification,
      };
    }
    case `${STORAGE_TYPES.REQUEST_VERIFICATION}_FULFILLED`:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          status: VERIFICATION_STATUSES.REQUESTED,
          msUntilNextAttempt: 0,
        },
      };
    case `${STORAGE_TYPES.SEND_VERIFICATION}_PENDING`:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: true,
          status: VERIFICATION_STATUSES.SENT,
        },
      };
    case `${STORAGE_TYPES.SEND_VERIFICATION}_REJECTED`:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          status: VERIFICATION_STATUSES.ERROR,
        },
      };
    case `${STORAGE_TYPES.SEND_VERIFICATION}_FULFILLED`:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          status: VERIFICATION_STATUSES.SUCCESS,
          expiry: Number(action.payload.data),
        },
      };
    case STORAGE_TYPES.CLEAR_VERIFICATION:
      return {
        ...state,
        verification: INITIAL_STORAGE_STATE.verification,
      };
    case AUTH_TYPES.CLEAR:
      return INITIAL_STORAGE_STATE;
    default:
      return state;
  }
}
