import { axiosInstance } from '../../lib/axiosInstance';
import { GROUP_ADMIN_TYPES } from './groupAdminReducer';

const GroupAdmin = {
  fetch: () => ({
    type: GROUP_ADMIN_TYPES.FETCH,
    payload: axiosInstance.get('/useradmin/groups'),
  }),
  create: (data) => ({
    type: GROUP_ADMIN_TYPES.CREATE,
    payload: axiosInstance.post('/useradmin/group', data),
  }),
  edit: (id, data) => ({
    type: GROUP_ADMIN_TYPES.EDIT,
    payload: axiosInstance.put(`/useradmin/group/${id}`, data),
  }),
  delete: (id) => ({
    type: GROUP_ADMIN_TYPES.DELETE,
    payload: axiosInstance.delete(`/useradmin/group/${id}`).then(() => id),
  }),
  setGroupId: (id) => ({
    type: GROUP_ADMIN_TYPES.SET_GROUP_ID,
    payload: id,
  }),
  clearGroupId: () => ({
    type: GROUP_ADMIN_TYPES.SET_GROUP_ID,
    payload: null,
  }),
};

export default GroupAdmin;
